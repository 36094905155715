import React from 'react';
// covert SVG here - https://react-svgr.com/

const QRCodeIcon = ({color, width, height, opacity, ...rest}) => {
  // console.log(`HeartIcon started...`);

  return (
    <svg
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width={width || "1em"}
      height={height || "1em"}
      opacity={opacity || 1}
      {...rest}
    >
      <path
        d="M9.226 2.29A2.29 2.29 0 006.936.003H2.285A2.29 2.29 0 00-.005 2.29v4.652a2.29 2.29 0 002.29 2.29h4.652a2.29 2.29 0 002.289-2.29V2.291zM6.918 3.455c0-.632-.512-1.145-1.144-1.145H3.447c-.632 0-1.144.513-1.144 1.145V5.78c0 .63.512 1.145 1.144 1.145h2.327c.632 0 1.144-.514 1.144-1.145V3.454zM19.996 2.29a2.29 2.29 0 00-2.29-2.288h-4.652a2.29 2.29 0 00-2.289 2.289v4.652a2.29 2.29 0 002.29 2.29h4.652a2.29 2.29 0 002.289-2.29V2.291zm-2.308 1.164c0-.632-.512-1.145-1.144-1.145h-2.327c-.632 0-1.144.513-1.144 1.145V5.78c0 .63.512 1.145 1.144 1.145h2.327c.632 0 1.144-.514 1.144-1.145V3.454zM9.226 13.09a2.29 2.29 0 00-2.29-2.288H2.285A2.29 2.29 0 00-.005 13.09v4.652a2.29 2.29 0 002.29 2.29h4.652a2.29 2.29 0 002.289-2.29v-4.652zm-2.308 1.164c0-.632-.512-1.145-1.144-1.145H3.447c-.632 0-1.144.513-1.144 1.145v2.326c0 .63.512 1.145 1.144 1.145h2.327c.632 0 1.144-.514 1.144-1.145v-2.326zM19.998 11.898c0-.63-.514-1.144-1.145-1.144h-2.326c-.631 0-1.145.513-1.145 1.144v2.327c0 .631.514 1.144 1.145 1.144h2.326c.631 0 1.145-.513 1.145-1.144v-2.327zM15.388 16.498c0-.63-.514-1.144-1.145-1.144h-2.326c-.631 0-1.145.513-1.145 1.144v2.327c0 .631.514 1.144 1.145 1.144h2.326c.631 0 1.145-.513 1.145-1.144v-2.327zM19.994 18.25a.573.573 0 00-.573-.573h-1.163a.573.573 0 00-.572.572v1.163c0 .316.257.573.572.573h1.163a.573.573 0 00.573-.573V18.25z"
        fill={color || "#000000"}
      />
      <path
        d="M5.764 4.05a.573.573 0 00-.573-.573H4.028a.573.573 0 00-.572.572v1.163c0 .316.257.573.572.573h1.163a.573.573 0 00.573-.573V4.05z"
        fill={color || "#000000"}
      />
    </svg>

  );
}

export default QRCodeIcon;