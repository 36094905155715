import { Fragment, useEffect, useCallback, useState, useRef} from 'react';

import { Link, generatePath, useRouteMatch } from 'react-router-dom';
import { Heading, Strong, Text, Button, Pane } from 'evergreen-ui';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Footer from '../components/Footer';

import { useSelector } from 'react-redux';


const Sitemap = () => {

  let match = useRouteMatch();
  // const userState = useSelector(state => state.userState);
  // const localeState = useSelector(state => state.localeState);
  

  return (
    <Pane >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Heading size={700} marginTop={16} marginLeft={10} color="#283655">{I18n.get('Sitemap')}</Heading>
        <Pane marginTop={10} marginBottom={10}>

        </Pane>
      </Pane>
      <Footer/>
    </Pane>
  );
}

export default Sitemap;