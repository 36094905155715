/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFace = /* GraphQL */ `
  subscription OnCreateFace {
    onCreateFace {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicFaceId
      aliasPublicFaceIds
      redirectToPublicFaceId
      sub
      status
      username
      usernameUpdatedAtUNIX
      name
      picture {
        bucket
        region
        key
        orderIndex
      }
      pictureUrl
      faceIds
      locale
      email
      emailVerifiedUNIX
      bio
      website
      description
      note
      phone
      phoneDialCode
      phoneVerifiedUNIX
      suffixes
      verifiedUNIX
      balance {
        currency
        available
        transferable
        approved
        paidOut
        beingSent
        pending
        earned
        topUpsMade
        topUpsReturned
        topUpsBeingReturned
        donationsMade
        donationsReceived
        giftAidMade
        giftAidReceived
        giftAidOffset
        paymentsMade
        provisionsReceived
        provisionsMade
        matchFundingDue
        matchFundingMade
        matchFundingReceived
      }
      currency
      settings {
        matchDonations
        neodr
        neondp
        neodt
        neodui
        faceLinked
        posMode
      }
      bankAccounts {
        id
        type
        createdAtUNIX
        updatedAtUNIX
        last
        next
        status
        linkedPublicTransactionId
        role
        currency
        holderName
        iban
        sortCode
        accountNumber
        engine
        engineMethodId
        futureUsage
        fingerprint
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
      }
      rights {
        adminPanel
        balanceTransfer
        provideExternally
        provideInternally
        balancePay
        provideExternallyLimitFactor
        provideInternallyLimitFactor
        acceptGiftAid
        verifyRecipients
        makeAllocations
        anonymiseRecipients
        curateFeedback
      }
      tags
      customerId
      records {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      title
      firstName
      lastName
      postcode
      town
      address
      objectDetails
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onUpdateFace = /* GraphQL */ `
  subscription OnUpdateFace {
    onUpdateFace {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicFaceId
      aliasPublicFaceIds
      redirectToPublicFaceId
      sub
      status
      username
      usernameUpdatedAtUNIX
      name
      picture {
        bucket
        region
        key
        orderIndex
      }
      pictureUrl
      faceIds
      locale
      email
      emailVerifiedUNIX
      bio
      website
      description
      note
      phone
      phoneDialCode
      phoneVerifiedUNIX
      suffixes
      verifiedUNIX
      balance {
        currency
        available
        transferable
        approved
        paidOut
        beingSent
        pending
        earned
        topUpsMade
        topUpsReturned
        topUpsBeingReturned
        donationsMade
        donationsReceived
        giftAidMade
        giftAidReceived
        giftAidOffset
        paymentsMade
        provisionsReceived
        provisionsMade
        matchFundingDue
        matchFundingMade
        matchFundingReceived
      }
      currency
      settings {
        matchDonations
        neodr
        neondp
        neodt
        neodui
        faceLinked
        posMode
      }
      bankAccounts {
        id
        type
        createdAtUNIX
        updatedAtUNIX
        last
        next
        status
        linkedPublicTransactionId
        role
        currency
        holderName
        iban
        sortCode
        accountNumber
        engine
        engineMethodId
        futureUsage
        fingerprint
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
      }
      rights {
        adminPanel
        balanceTransfer
        provideExternally
        provideInternally
        balancePay
        provideExternallyLimitFactor
        provideInternallyLimitFactor
        acceptGiftAid
        verifyRecipients
        makeAllocations
        anonymiseRecipients
        curateFeedback
      }
      tags
      customerId
      records {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      title
      firstName
      lastName
      postcode
      town
      address
      objectDetails
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onDeleteFace = /* GraphQL */ `
  subscription OnDeleteFace {
    onDeleteFace {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicFaceId
      aliasPublicFaceIds
      redirectToPublicFaceId
      sub
      status
      username
      usernameUpdatedAtUNIX
      name
      picture {
        bucket
        region
        key
        orderIndex
      }
      pictureUrl
      faceIds
      locale
      email
      emailVerifiedUNIX
      bio
      website
      description
      note
      phone
      phoneDialCode
      phoneVerifiedUNIX
      suffixes
      verifiedUNIX
      balance {
        currency
        available
        transferable
        approved
        paidOut
        beingSent
        pending
        earned
        topUpsMade
        topUpsReturned
        topUpsBeingReturned
        donationsMade
        donationsReceived
        giftAidMade
        giftAidReceived
        giftAidOffset
        paymentsMade
        provisionsReceived
        provisionsMade
        matchFundingDue
        matchFundingMade
        matchFundingReceived
      }
      currency
      settings {
        matchDonations
        neodr
        neondp
        neodt
        neodui
        faceLinked
        posMode
      }
      bankAccounts {
        id
        type
        createdAtUNIX
        updatedAtUNIX
        last
        next
        status
        linkedPublicTransactionId
        role
        currency
        holderName
        iban
        sortCode
        accountNumber
        engine
        engineMethodId
        futureUsage
        fingerprint
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
      }
      rights {
        adminPanel
        balanceTransfer
        provideExternally
        provideInternally
        balancePay
        provideExternallyLimitFactor
        provideInternallyLimitFactor
        acceptGiftAid
        verifyRecipients
        makeAllocations
        anonymiseRecipients
        curateFeedback
      }
      tags
      customerId
      records {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      title
      firstName
      lastName
      postcode
      town
      address
      objectDetails
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction {
    onCreateTransaction {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicTransactionId
      linkedPublicTransactionId
      status
      type
      frequency
      role
      roleUNIX
      orderIndex
      locale
      description
      note
      caption
      peopleImpacted
      accessCode
      accessExpiryUNIX
      fromPublicFaceId
      toPublicFaceId
      viaPublicFaceId
      toName
      toFaceImages {
        bucket
        region
        key
        orderIndex
      }
      proofImages {
        bucket
        region
        key
        orderIndex
      }
      proofImagesUrls
      proofImagesCount
      faceIds
      rate
      count
      amount
      currency
      amountAvailable
      amountRequired
      amountExpected
      amountSubjected
      amountOffset
      relatedAmount
      relatedAmountRequired
      relatedAmountExpected
      relatedAmountSubjected
      relatedCurrency
      currencyExchangeUNIX
      partialUse
      mode
      amounts
      history {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      objectPIntent
      objectDetails
      paymentMethod
      paymentMethodId
      impact {
        orderIndex
        action
        effect
        unit
        durationDays
        quantityDurationDays
        quantity
        references
        chartData
      }
      onEvents
      conditions
      chartData
      unit
      liveMode
      isDefault
      isBeingEdited
      mayBeAccepted
      mayBeAdded
      isToBeDeclared
      hasBeenDeclared
      reference
      regNumber
      regName
      email
      phone
      phoneDialCode
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction {
    onUpdateTransaction {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicTransactionId
      linkedPublicTransactionId
      status
      type
      frequency
      role
      roleUNIX
      orderIndex
      locale
      description
      note
      caption
      peopleImpacted
      accessCode
      accessExpiryUNIX
      fromPublicFaceId
      toPublicFaceId
      viaPublicFaceId
      toName
      toFaceImages {
        bucket
        region
        key
        orderIndex
      }
      proofImages {
        bucket
        region
        key
        orderIndex
      }
      proofImagesUrls
      proofImagesCount
      faceIds
      rate
      count
      amount
      currency
      amountAvailable
      amountRequired
      amountExpected
      amountSubjected
      amountOffset
      relatedAmount
      relatedAmountRequired
      relatedAmountExpected
      relatedAmountSubjected
      relatedCurrency
      currencyExchangeUNIX
      partialUse
      mode
      amounts
      history {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      objectPIntent
      objectDetails
      paymentMethod
      paymentMethodId
      impact {
        orderIndex
        action
        effect
        unit
        durationDays
        quantityDurationDays
        quantity
        references
        chartData
      }
      onEvents
      conditions
      chartData
      unit
      liveMode
      isDefault
      isBeingEdited
      mayBeAccepted
      mayBeAdded
      isToBeDeclared
      hasBeenDeclared
      reference
      regNumber
      regName
      email
      phone
      phoneDialCode
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction {
    onDeleteTransaction {
      id
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicTransactionId
      linkedPublicTransactionId
      status
      type
      frequency
      role
      roleUNIX
      orderIndex
      locale
      description
      note
      caption
      peopleImpacted
      accessCode
      accessExpiryUNIX
      fromPublicFaceId
      toPublicFaceId
      viaPublicFaceId
      toName
      toFaceImages {
        bucket
        region
        key
        orderIndex
      }
      proofImages {
        bucket
        region
        key
        orderIndex
      }
      proofImagesUrls
      proofImagesCount
      faceIds
      rate
      count
      amount
      currency
      amountAvailable
      amountRequired
      amountExpected
      amountSubjected
      amountOffset
      relatedAmount
      relatedAmountRequired
      relatedAmountExpected
      relatedAmountSubjected
      relatedCurrency
      currencyExchangeUNIX
      partialUse
      mode
      amounts
      history {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      objectPIntent
      objectDetails
      paymentMethod
      paymentMethodId
      impact {
        orderIndex
        action
        effect
        unit
        durationDays
        quantityDurationDays
        quantity
        references
        chartData
      }
      onEvents
      conditions
      chartData
      unit
      liveMode
      isDefault
      isBeingEdited
      mayBeAccepted
      mayBeAdded
      isToBeDeclared
      hasBeenDeclared
      reference
      regNumber
      regName
      email
      phone
      phoneDialCode
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onTransactionUpdate = /* GraphQL */ `
  subscription OnTransactionUpdate($publicTransactionId: String) {
    onTransactionUpdate(publicTransactionId: $publicTransactionId) {
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicTransactionId
      linkedPublicTransactionId
      linkedTransaction {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      status
      type
      frequency
      role
      roleUNIX
      orderIndex
      locale
      description
      note
      caption
      peopleImpacted
      accessCode
      accessExpiryUNIX
      fromFace {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicFaceId
        aliasPublicFaceIds
        redirectToPublicFaceId
        sub
        status
        username
        usernameUpdatedAtUNIX
        name
        picture {
          bucket
          region
          key
          orderIndex
        }
        pictureUrl
        faceIds
        locale
        email
        emailVerifiedUNIX
        bio
        website
        description
        note
        phone
        phoneDialCode
        phoneVerifiedUNIX
        suffixes
        verifiedUNIX
        balance {
          currency
          available
          transferable
          approved
          paidOut
          beingSent
          pending
          earned
          topUpsMade
          topUpsReturned
          topUpsBeingReturned
          donationsMade
          donationsReceived
          giftAidMade
          giftAidReceived
          giftAidOffset
          paymentsMade
          provisionsReceived
          provisionsMade
          matchFundingDue
          matchFundingMade
          matchFundingReceived
        }
        currency
        settings {
          matchDonations
          neodr
          neondp
          neodt
          neodui
          faceLinked
          posMode
        }
        bankAccounts {
          id
          type
          createdAtUNIX
          updatedAtUNIX
          last
          next
          status
          linkedPublicTransactionId
          role
          currency
          holderName
          iban
          sortCode
          accountNumber
          engine
          engineMethodId
          futureUsage
          fingerprint
          cardBrand
          cardLast4
          cardExpMonth
          cardExpYear
        }
        rights {
          adminPanel
          balanceTransfer
          provideExternally
          provideInternally
          balancePay
          provideExternallyLimitFactor
          provideInternallyLimitFactor
          acceptGiftAid
          verifyRecipients
          makeAllocations
          anonymiseRecipients
          curateFeedback
        }
        tags
        customerId
        records {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        history {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        following {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        followers {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        dashboard {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        title
        firstName
        lastName
        postcode
        town
        address
        objectDetails
        error {
          name
          code
          type
          message
        }
      }
      fromPublicFaceId
      toFace {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicFaceId
        aliasPublicFaceIds
        redirectToPublicFaceId
        sub
        status
        username
        usernameUpdatedAtUNIX
        name
        picture {
          bucket
          region
          key
          orderIndex
        }
        pictureUrl
        faceIds
        locale
        email
        emailVerifiedUNIX
        bio
        website
        description
        note
        phone
        phoneDialCode
        phoneVerifiedUNIX
        suffixes
        verifiedUNIX
        balance {
          currency
          available
          transferable
          approved
          paidOut
          beingSent
          pending
          earned
          topUpsMade
          topUpsReturned
          topUpsBeingReturned
          donationsMade
          donationsReceived
          giftAidMade
          giftAidReceived
          giftAidOffset
          paymentsMade
          provisionsReceived
          provisionsMade
          matchFundingDue
          matchFundingMade
          matchFundingReceived
        }
        currency
        settings {
          matchDonations
          neodr
          neondp
          neodt
          neodui
          faceLinked
          posMode
        }
        bankAccounts {
          id
          type
          createdAtUNIX
          updatedAtUNIX
          last
          next
          status
          linkedPublicTransactionId
          role
          currency
          holderName
          iban
          sortCode
          accountNumber
          engine
          engineMethodId
          futureUsage
          fingerprint
          cardBrand
          cardLast4
          cardExpMonth
          cardExpYear
        }
        rights {
          adminPanel
          balanceTransfer
          provideExternally
          provideInternally
          balancePay
          provideExternallyLimitFactor
          provideInternallyLimitFactor
          acceptGiftAid
          verifyRecipients
          makeAllocations
          anonymiseRecipients
          curateFeedback
        }
        tags
        customerId
        records {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        history {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        following {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        followers {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        dashboard {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        title
        firstName
        lastName
        postcode
        town
        address
        objectDetails
        error {
          name
          code
          type
          message
        }
      }
      toPublicFaceId
      viaPublicFaceId
      viaFace {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicFaceId
        aliasPublicFaceIds
        redirectToPublicFaceId
        sub
        status
        username
        usernameUpdatedAtUNIX
        name
        picture {
          bucket
          region
          key
          orderIndex
        }
        pictureUrl
        faceIds
        locale
        email
        emailVerifiedUNIX
        bio
        website
        description
        note
        phone
        phoneDialCode
        phoneVerifiedUNIX
        suffixes
        verifiedUNIX
        balance {
          currency
          available
          transferable
          approved
          paidOut
          beingSent
          pending
          earned
          topUpsMade
          topUpsReturned
          topUpsBeingReturned
          donationsMade
          donationsReceived
          giftAidMade
          giftAidReceived
          giftAidOffset
          paymentsMade
          provisionsReceived
          provisionsMade
          matchFundingDue
          matchFundingMade
          matchFundingReceived
        }
        currency
        settings {
          matchDonations
          neodr
          neondp
          neodt
          neodui
          faceLinked
          posMode
        }
        bankAccounts {
          id
          type
          createdAtUNIX
          updatedAtUNIX
          last
          next
          status
          linkedPublicTransactionId
          role
          currency
          holderName
          iban
          sortCode
          accountNumber
          engine
          engineMethodId
          futureUsage
          fingerprint
          cardBrand
          cardLast4
          cardExpMonth
          cardExpYear
        }
        rights {
          adminPanel
          balanceTransfer
          provideExternally
          provideInternally
          balancePay
          provideExternallyLimitFactor
          provideInternallyLimitFactor
          acceptGiftAid
          verifyRecipients
          makeAllocations
          anonymiseRecipients
          curateFeedback
        }
        tags
        customerId
        records {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        history {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        following {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        followers {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        dashboard {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        title
        firstName
        lastName
        postcode
        town
        address
        objectDetails
        error {
          name
          code
          type
          message
        }
      }
      toName
      toFaceImages {
        bucket
        region
        key
        orderIndex
      }
      proofImages {
        bucket
        region
        key
        orderIndex
      }
      proofImagesUrls
      proofImagesCount
      faceIds
      rate
      count
      amount
      currency
      amountAvailable
      amountRequired
      amountExpected
      amountSubjected
      amountOffset
      relatedAmount
      relatedAmountRequired
      relatedAmountExpected
      relatedAmountSubjected
      relatedCurrency
      currencyExchangeUNIX
      partialUse
      mode
      amounts
      history {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      relatedRecords {
        results {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        total
        nextToken
        status
      }
      objectPIntent
      objectDetails
      paymentMethod
      paymentMethodId
      impact {
        orderIndex
        action
        effect
        unit
        durationDays
        quantityDurationDays
        quantity
        references
        chartData
      }
      onEvents
      conditions
      chartData
      unit
      liveMode
      isDefault
      isBeingEdited
      mayBeAccepted
      mayBeAdded
      isToBeDeclared
      hasBeenDeclared
      reference
      regNumber
      regName
      email
      phone
      phoneDialCode
      error {
        name
        code
        type
        message
      }
    }
  }
`;
export const onFaceUpdate = /* GraphQL */ `
  subscription OnFaceUpdate($publicFaceId: String) {
    onFaceUpdate(publicFaceId: $publicFaceId) {
      createdAt
      updatedAt
      createdAtUNIX
      updatedAtUNIX
      last
      next
      publicFaceId
      aliasPublicFaceIds
      redirectToPublicFaceId
      sub
      status
      username
      usernameUpdatedAtUNIX
      name
      picture {
        bucket
        region
        key
        orderIndex
      }
      pictureUrl
      faceIds
      locale
      email
      emailVerifiedUNIX
      bio
      website
      description
      note
      phone
      phoneDialCode
      phoneVerifiedUNIX
      suffixes
      verifiedUNIX
      balance {
        currency
        available
        transferable
        approved
        paidOut
        beingSent
        pending
        earned
        topUpsMade
        topUpsReturned
        topUpsBeingReturned
        donationsMade
        donationsReceived
        giftAidMade
        giftAidReceived
        giftAidOffset
        paymentsMade
        provisionsReceived
        provisionsMade
        matchFundingDue
        matchFundingMade
        matchFundingReceived
      }
      currency
      settings {
        matchDonations
        neodr
        neondp
        neodt
        neodui
        faceLinked
        posMode
      }
      bankAccounts {
        id
        type
        createdAtUNIX
        updatedAtUNIX
        last
        next
        status
        linkedPublicTransactionId
        role
        currency
        holderName
        iban
        sortCode
        accountNumber
        engine
        engineMethodId
        futureUsage
        fingerprint
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
      }
      rights {
        adminPanel
        balanceTransfer
        provideExternally
        provideInternally
        balancePay
        provideExternallyLimitFactor
        provideInternallyLimitFactor
        acceptGiftAid
        verifyRecipients
        makeAllocations
        anonymiseRecipients
        curateFeedback
      }
      tags
      customerId
      records {
        createdAt
        updatedAt
        createdAtUNIX
        updatedAtUNIX
        last
        next
        publicTransactionId
        linkedPublicTransactionId
        linkedTransaction {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        status
        type
        frequency
        role
        roleUNIX
        orderIndex
        locale
        description
        note
        caption
        peopleImpacted
        accessCode
        accessExpiryUNIX
        fromFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        fromPublicFaceId
        toFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toPublicFaceId
        viaPublicFaceId
        viaFace {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicFaceId
          aliasPublicFaceIds
          redirectToPublicFaceId
          sub
          status
          username
          usernameUpdatedAtUNIX
          name
          picture {
            bucket
            region
            key
            orderIndex
          }
          pictureUrl
          faceIds
          locale
          email
          emailVerifiedUNIX
          bio
          website
          description
          note
          phone
          phoneDialCode
          phoneVerifiedUNIX
          suffixes
          verifiedUNIX
          balance {
            currency
            available
            transferable
            approved
            paidOut
            beingSent
            pending
            earned
            topUpsMade
            topUpsReturned
            topUpsBeingReturned
            donationsMade
            donationsReceived
            giftAidMade
            giftAidReceived
            giftAidOffset
            paymentsMade
            provisionsReceived
            provisionsMade
            matchFundingDue
            matchFundingMade
            matchFundingReceived
          }
          currency
          settings {
            matchDonations
            neodr
            neondp
            neodt
            neodui
            faceLinked
            posMode
          }
          bankAccounts {
            id
            type
            createdAtUNIX
            updatedAtUNIX
            last
            next
            status
            linkedPublicTransactionId
            role
            currency
            holderName
            iban
            sortCode
            accountNumber
            engine
            engineMethodId
            futureUsage
            fingerprint
            cardBrand
            cardLast4
            cardExpMonth
            cardExpYear
          }
          rights {
            adminPanel
            balanceTransfer
            provideExternally
            provideInternally
            balancePay
            provideExternallyLimitFactor
            provideInternallyLimitFactor
            acceptGiftAid
            verifyRecipients
            makeAllocations
            anonymiseRecipients
            curateFeedback
          }
          tags
          customerId
          records {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          history {
            total
            nextToken
            status
          }
          following {
            total
            nextToken
            status
          }
          followers {
            total
            nextToken
            status
          }
          dashboard {
            total
            nextToken
            status
          }
          title
          firstName
          lastName
          postcode
          town
          address
          objectDetails
          error {
            name
            code
            type
            message
          }
        }
        toName
        toFaceImages {
          bucket
          region
          key
          orderIndex
        }
        proofImages {
          bucket
          region
          key
          orderIndex
        }
        proofImagesUrls
        proofImagesCount
        faceIds
        rate
        count
        amount
        currency
        amountAvailable
        amountRequired
        amountExpected
        amountSubjected
        amountOffset
        relatedAmount
        relatedAmountRequired
        relatedAmountExpected
        relatedAmountSubjected
        relatedCurrency
        currencyExchangeUNIX
        partialUse
        mode
        amounts
        history {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        relatedRecords {
          results {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          total
          nextToken
          status
        }
        objectPIntent
        objectDetails
        paymentMethod
        paymentMethodId
        impact {
          orderIndex
          action
          effect
          unit
          durationDays
          quantityDurationDays
          quantity
          references
          chartData
        }
        onEvents
        conditions
        chartData
        unit
        liveMode
        isDefault
        isBeingEdited
        mayBeAccepted
        mayBeAdded
        isToBeDeclared
        hasBeenDeclared
        reference
        regNumber
        regName
        email
        phone
        phoneDialCode
        error {
          name
          code
          type
          message
        }
      }
      history {
        results {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        total
        nextToken
        status
      }
      following {
        results {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        total
        nextToken
        status
      }
      followers {
        results {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        total
        nextToken
        status
      }
      dashboard {
        results {
          createdAt
          updatedAt
          createdAtUNIX
          updatedAtUNIX
          last
          next
          publicTransactionId
          linkedPublicTransactionId
          linkedTransaction {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          status
          type
          frequency
          role
          roleUNIX
          orderIndex
          locale
          description
          note
          caption
          peopleImpacted
          accessCode
          accessExpiryUNIX
          fromFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          fromPublicFaceId
          toFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toPublicFaceId
          viaPublicFaceId
          viaFace {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicFaceId
            aliasPublicFaceIds
            redirectToPublicFaceId
            sub
            status
            username
            usernameUpdatedAtUNIX
            name
            pictureUrl
            faceIds
            locale
            email
            emailVerifiedUNIX
            bio
            website
            description
            note
            phone
            phoneDialCode
            phoneVerifiedUNIX
            suffixes
            verifiedUNIX
            currency
            tags
            customerId
            title
            firstName
            lastName
            postcode
            town
            address
            objectDetails
          }
          toName
          toFaceImages {
            bucket
            region
            key
            orderIndex
          }
          proofImages {
            bucket
            region
            key
            orderIndex
          }
          proofImagesUrls
          proofImagesCount
          faceIds
          rate
          count
          amount
          currency
          amountAvailable
          amountRequired
          amountExpected
          amountSubjected
          amountOffset
          relatedAmount
          relatedAmountRequired
          relatedAmountExpected
          relatedAmountSubjected
          relatedCurrency
          currencyExchangeUNIX
          partialUse
          mode
          amounts
          history {
            createdAt
            updatedAt
            createdAtUNIX
            updatedAtUNIX
            last
            next
            publicTransactionId
            linkedPublicTransactionId
            status
            type
            frequency
            role
            roleUNIX
            orderIndex
            locale
            description
            note
            caption
            peopleImpacted
            accessCode
            accessExpiryUNIX
            fromPublicFaceId
            toPublicFaceId
            viaPublicFaceId
            toName
            proofImagesUrls
            proofImagesCount
            faceIds
            rate
            count
            amount
            currency
            amountAvailable
            amountRequired
            amountExpected
            amountSubjected
            amountOffset
            relatedAmount
            relatedAmountRequired
            relatedAmountExpected
            relatedAmountSubjected
            relatedCurrency
            currencyExchangeUNIX
            partialUse
            mode
            amounts
            objectPIntent
            objectDetails
            paymentMethod
            paymentMethodId
            onEvents
            conditions
            chartData
            unit
            liveMode
            isDefault
            isBeingEdited
            mayBeAccepted
            mayBeAdded
            isToBeDeclared
            hasBeenDeclared
            reference
            regNumber
            regName
            email
            phone
            phoneDialCode
          }
          relatedRecords {
            total
            nextToken
            status
          }
          objectPIntent
          objectDetails
          paymentMethod
          paymentMethodId
          impact {
            orderIndex
            action
            effect
            unit
            durationDays
            quantityDurationDays
            quantity
            references
            chartData
          }
          onEvents
          conditions
          chartData
          unit
          liveMode
          isDefault
          isBeingEdited
          mayBeAccepted
          mayBeAdded
          isToBeDeclared
          hasBeenDeclared
          reference
          regNumber
          regName
          email
          phone
          phoneDialCode
          error {
            name
            code
            type
            message
          }
        }
        total
        nextToken
        status
      }
      title
      firstName
      lastName
      postcode
      town
      address
      objectDetails
      error {
        name
        code
        type
        message
      }
    }
  }
`;
