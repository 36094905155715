import { useEffect, useState, useRef, useCallback, useContext} from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath, Redirect} from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Radio, CrossIcon, TickIcon, IconButton, Spinner} from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FormattedCurrencyNumber from './FormattedCurrencyNumber';

import { useSelector, useDispatch } from 'react-redux';
import { updateUserState } from '../services/actions';


const DialogAddFundsToBalance = ({ children, isShown, onClose, ...rest }) => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("DialogAddFundsToBalance cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  const dispatch = useDispatch();
  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const [localTransaction, setLocalTransaction] = useState();

  const handleTransactionChange = useCallback(async (updatedTransaction) => {
    console.log("updatedTransaction in handleTransactionChange", updatedTransaction);
    setLocalTransaction(updatedTransaction);
    // update user balance and settings to reflect changes
  }, []);

  let tDialogHeader = "Add funds";
  let tAmount = "Amount";
  let tClose = "CLOSE";
  let tThankYou = "Thank you!";
  let tExplainer = "The funds will now be added to your balance."
  let tSomethingWentWrong = "Something went wrong...";
  let tTryAgainOnError= "Please, could you try again?";
  let tTryAgainButton = "TRY AGAIN";
  
  switch (localeState.language) {
    case "it":
      tClose = "CHIUDI";
      tThankYou = "Grazie!";
      // tExplainer = "Per favore, controlla la mail. Ti abbiamo inviato un esempio sul possibile uso ed impatto di una donazione."
      tSomethingWentWrong = "Qualcosa non ha funzionato...";
      tTryAgainOnError= "Please, could you try again?";
      break;

    default:
      break;
  }

  return (
    <Dialog
    isShown={isShown}
    // onOpenComplete={() => {}}
    onCloseComplete={() => {
      setLocalTransaction();
		}}
    // onCancel={(close) => close()}
    title='Demo Is Sent'
    hasHeader={false}
    hasFooter={false}
    preventBodyScrolling
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEscapePress={false}
    width={350}
    containerProps={{
			"backgroundColor": "transparent",
      "maxHeight": "100%",
      "margin": "auto"
		}}
		contentContainerProps={{
			"padding": "0px"
		}}
    >
			
			{({ close }) => (
				<Pane >
          
          
          {!localTransaction ?
            <Pane background="tint1" borderRadius={10} padding={10}>

              <Pane alignItems="center" height={40} display="flex" flexWrap="wrap">
                <Pane flex={1} width="100%" height="auto" >
                  <Heading size={800} color="#283655" >{tDialogHeader}</Heading>
                </Pane>
                <Pane flex="none" >
                  <IconButton icon={CrossIcon} iconSize={18}
                  onClick={() => {
                    onClose();
                  }}/>
                </Pane>
              </Pane>

              <Pane>
                <Pane alignItems="center" display="flex" flexWrap="wrap">
                  <Pane flex={1} marginTop={10} marginBottom={4} >
                    <Strong fontSize={16} color="#7B8B9A" >{tAmount}</Strong>
                  </Pane>
                  {/*<Pane flex={1} textAlign="right" marginTop={8} marginBottom={4}>
                    <PickCurrency fontColor="#7B8B9A" fontSize={400} />
                  </Pane>*/}
                </Pane>
                {userState.isLoading ?
                  <Pane marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
                :
                  <FormattedCurrencyNumber number={userState.user.balance.matchFundingDue} currency={userState.user.balance.currency} mainFontSize={32} />
                }

              </Pane>

              {/* <XXX
                type={"matchFunding"}
                toPublicFaceId={userState.user.publicFaceId}
                amount={userState.user.balance.matchFundingDue} currency={userState.user.balance.currency} locale={match.params.locale}
                // onProcessing={setProcessingTransaction}
                onTransactionChange={(transaction) => {handleTransactionChange(transaction)}}
              /> */}

              </Pane>
          :

            localTransaction.status === "error" ?
              <Pane background="tint1" borderRadius={10} >
              
                <Pane height={150} borderTopLeftRadius={10} borderTopRightRadius={10} style={{background: "linear-gradient(0deg, rgba(249,249,251,1) 10%, rgba(236,76,71,1) 95%)"}} >
                  <Pane textAlign="center" >
                    <Pane marginTop={16} background="#f6a6a3" padding={10} borderRadius="50%" display="inline-block" >
                      <Pane background="#f49491" padding={10} borderRadius="50%" display="flex">
                        <Pane background="#f0706c" padding={10} borderRadius="50%" display="flex" >
                          <CrossIcon size={36} color="#FFFFFF" />
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane padding={10}>
                  <Pane height={40} textAlign="center" >
                    <Heading size={800} color="#EC4C47" >{tSomethingWentWrong}</Heading>
                  </Pane>
                  <Pane width="100%" height="auto" textAlign="center" >
                    <Paragraph fontSize={18} lineHeight={1.3} color="#EC4C47" >
                      {localTransaction.error ? localTransaction.error.message : tTryAgainOnError}
                    </Paragraph>
                  </Pane>

                  <Pane marginTop={16} marginBottom={10} textAlign="center" >
                    <Button fontSize={16} paddingX={24} height={40} minWidth={140} justifyContent="center" 
                      onClick={() => {
                        setLocalTransaction();
                      }}
                    >
                    {tTryAgainButton}
                    </Button>
                  </Pane>
                </Pane>
              </Pane>
              
            :
              <Pane background="tint1" borderRadius={10} >

                <Pane height={150} borderTopLeftRadius={10} borderTopRightRadius={10} style={{background: "linear-gradient(0deg, rgba(249,249,251,1) 10%, rgba(71,184,129,1) 95%)"}} >
                  <Pane textAlign="center" >
                    <Pane marginTop={16} background="#91d4b3" padding={10} borderRadius="50%" display="inline-block" >
                      <Pane background="#7ecda7" padding={10} borderRadius="50%" display="flex">
                        <Pane background="#6cc69a" padding={10} borderRadius="50%" display="flex" >
                          <TickIcon size={36} color="#FFFFFF" />
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane padding={10}>
                  <Pane height={40} textAlign="center" >
                    <Heading size={800} color="#283655" >{tThankYou}</Heading>
                  </Pane>
                  <Pane width="100%" height="auto" textAlign="center" >
                    <Paragraph fontSize={18} lineHeight={1.3} >
                      {tExplainer}
                    </Paragraph>
                  </Pane>

                  <Pane marginTop={16} marginBottom={10} textAlign="center" >
                    <Button fontSize={16} paddingX={24} height={40} minWidth={140} justifyContent="center" 
                      onClick={() => {
                        dispatch(updateUserState({
                          user: {
                            ...userState.user,
                            balance: {
                              ...userState.user.balance,
                              balance: userState.user.balance.available + userState.user.balance.matchFundingDue,
                              matchFundingDue: 0,
                            },
                          },
                        }));
                        close();
                      }}
                    >
                    {tClose}
                    </Button>
                  </Pane>
                </Pane>
              </Pane>
            
          }
        </Pane>
			)}
    </Dialog>
  );
}

export default DialogAddFundsToBalance;