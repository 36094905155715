import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';

import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Table, EyeOffIcon, Select, EditIcon, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';
import FacePH from '../images/FacePH';

import DialogHeader from './DialogHeader';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';
import GiftAidLogo from '../assets/icons/GiftAidLogo';
import FaceAvatar from './FaceAvatar';

import InfiniteScroll from "react-infinite-scroll-component";

import PaymentComponent from './PaymentComponent';
import NumberPlusMinusPicker from './NumberPlusMinusPicker';
import DynamicTickBox from './DynamicTickBox';
import CurrencyNumberInput from './CurrencyNumberInput';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PickCurrency from './PickCurrency';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, updateProfileDetails, setTransactionDetails, updateProfileState, updateTransactionDetails, setDialogState, updateDialogState } from '../services/actions';

import styled, { StyledFunction } from "styled-components";
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import CountUp, { useCountUp } from 'react-countup';

import { useAuthCheckUserDetails, useGetUserDetails, useAuthUpdateUserDetails } from "../services/useAmplifyAuth";

import useHover from "../services/useHover";
import useAccessTransaction from "../services/useAccessTransaction";
import { currencies } from '../services/localeDictionary';

import { useGetTransactions } from "../services/useGetTransactions";


import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

const GiftAidRequestForm = ({processing = null, }) => {

	const dispatch = useDispatch();
	
	const transactionDetails = useSelector(state => state.transactionDetails);

	const [giftAidRequest, setGiftAidRequest] = useState(transactionDetails.history?.find((historyTransaction, index) => {
		return (historyTransaction.type === "giftAidRequest")
	}));

	useEffect(() => {
		// why not useMemo ?
		let currentIndex = null;
		transactionDetails.history?.find((historyTransaction, index) => {
			if (historyTransaction.type === "giftAidRequest") {
				currentIndex = index;
				return historyTransaction;
			}
			else {
				return false;
			}
		});
		
		const updatedHistory = transactionDetails.history || [];
		updatedHistory.splice(currentIndex, currentIndex !== null ? 1 : 0, giftAidRequest);
		dispatch(updateTransactionDetails({
			history: updatedHistory
		}));
	}, [dispatch, giftAidRequest, transactionDetails.history]);

	return (
		<Pane marginTop={10} padding={10} borderWidth={2} borderStyle="dashed" borderColor="#7B8B9A" >

			{/* <GiftAidLogo color="#283655" width={18} height={"auto"} />  */}

			<Pane marginTop={4} textAlign="center" className="noselect" >
				<Strong fontSize={20} color="#283655">{I18n.get('Gift Aid Declaration')}</Strong>
			</Pane>

			<Pane marginTop={4} className="noselect" >
				<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.")}</Text>
			</Pane>

			<Pane display="flex" flexWrap="wrap" >
				<Pane flex={0.4} marginRight={10} maxWidth={100} >
					<Pane marginTop={10} className="noselect" >
						<Strong htmlFor="required-field" marginRight={2} fontSize={16} color={"#EC4C47"} >{'*'}</Strong>
						<Strong htmlFor="honorific-prefix" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Title')}</Strong>
					</Pane>
					<Select 
						disabled={processing}
						height={40}
						width={"100%"}
						margin={0}
						marginTop={4}
						
						defaultValue={`   `}
						name={"honorific-prefix"}
						autoComplete={"honorific-prefix"}
						type={"text"}

						placeholder={`${I18n.get('Title')}...`}
						value={giftAidRequest?.fromFace?.title}
						onChange={(e) => {
							// up to 4 characters ???

							setGiftAidRequest({
								...giftAidRequest,
								fromFace: {
									...giftAidRequest?.fromFace,
									title: e.target.value,
								}
							});
						}}
					>
						<option disabled selected value="   " >{I18n.get(`   `)}</option>
						<option value="Mr">{I18n.get('Mr')}</option>
						<option value="Mrs">{I18n.get('Mrs')}</option>
						<option value="Miss">{I18n.get('Miss')}</option>
						<option value="Ms">{I18n.get('Ms')}</option>
						<option value="Dr">{I18n.get('Dr')}</option>
					</Select>
				</Pane>

				<Pane flex={1} >
					<Pane marginTop={10} className="noselect" >
						<Strong htmlFor="required-field" marginRight={2} fontSize={16} color={"#EC4C47"} >{'*'}</Strong>
						<Strong htmlFor="given-name" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('First name')}</Strong>
					</Pane>
					<Pane marginTop={4} >
						<TextInput
							disabled={processing}
							name={"given-name"}
							autoComplete={"given-name"}
							type={"text"}
							margin={0}
							width={"100%"}
							height={40}
							placeholder={`${I18n.get('First name')}...`}
							value={giftAidRequest?.fromFace?.firstName}
							onChange={(e) => {
								// up to 35 characters with no spaces, or just enter an initial

								setGiftAidRequest({
									...giftAidRequest,
									fromFace: {
										...giftAidRequest?.fromFace,
										firstName: e.target.value,
									}
								});

							}}
							onFocus={(e) => {
								//
							}}
						/>
					</Pane>
				</Pane>

			</Pane>

			<Pane marginTop={10} className="noselect" >
				<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
				<Strong htmlFor="family-name" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Surname')}</Strong>
			</Pane>
			<Pane marginTop={4} >
				<TextInput
					disabled={processing}
					name={"family-name"}
					autoComplete={"family-name"}
					type={"text"}
					margin={0}
					width={"100%"}
					height={40}
					placeholder={`${I18n.get('Surname')}...`}
					value={giftAidRequest?.fromFace?.lastName}
					onChange={(e) => {
						// Last name, up to 35 characters

						setGiftAidRequest({
							...giftAidRequest,
							fromFace: {
								...giftAidRequest?.fromFace,
								lastName: e.target.value,
							}
						});

					}}
					onFocus={(e) => {
						//
					}}
				/>
			</Pane>

			<Pane display="flex" flexWrap="wrap" >

				<Pane flex={1} marginRight={10} >
					<Pane marginTop={10} className="noselect" >
						<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
						<Strong htmlFor="postal-code" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Postcode')}</Strong>
					</Pane>
					<Pane marginTop={4} >
						<TextInput
							disabled={processing}
							name={"postal-code"}
							autoComplete={"postal-code"}
							type={"text"}
							margin={0}
							width={"100%"}
							height={40}
							placeholder={`${I18n.get('Postcode')}...`}
							value={giftAidRequest?.fromFace?.postcode}
							onChange={(e) => {
								// Postcode, UPPER CASE and include a space
								

								setGiftAidRequest({
									...giftAidRequest,
									fromFace: {
										...giftAidRequest?.fromFace,
										postcode: e.target.value,
									}
								});

							}}
							onFocus={(e) => {
								//
							}}
						/>
					</Pane>
				</Pane>

				<Pane>
					<Pane marginTop={10} className="noselect" >
						<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
						<Strong htmlFor="address-level2" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Town')}</Strong>
					</Pane>
					<Pane marginTop={4} >
						<TextInput
							disabled={processing}
							name={"address-level2"}
							autoComplete={"address-level2"}
							type={"text"}
							margin={0}
							width={"100%"}
							height={40}
							placeholder={`${I18n.get('Town')}...`}
							value={giftAidRequest?.fromFace?.town}
							onChange={(e) => {

								setGiftAidRequest({
									...giftAidRequest,
									fromFace: {
										...giftAidRequest?.fromFace,
										town: e.target.value,
									}
								});

							}}
							onFocus={(e) => {
								//
							}}
						/>
					</Pane>
				</Pane>
			</Pane>

			<Pane marginTop={10} className="noselect" >
				<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
				<Strong htmlFor="street-address" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Full home address')}</Strong>
			</Pane>
			<Pane marginTop={4} >
				<TextInput
					disabled={processing}
					name={"street-address"}
					autoComplete={"street-address"}
					type={"text"}
					margin={0}
					width={"100%"}
					height={40}
					placeholder={`${I18n.get('Full home address')}...`}
					value={giftAidRequest?.fromFace?.address}
					onChange={(e) => {
						// House name or number, up to 40 characters

						setGiftAidRequest({
							...giftAidRequest,
							fromFace: {
								...giftAidRequest?.fromFace,
								address: e.target.value,
							}
						});
					}}
					onFocus={(e) => {
						//
					}}
				/>
			</Pane>

			<Pane marginTop={10}>
				<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >
					{I18n.get("Gift Aid is a government scheme for UK charities to reclaim the tax you have paid and gain 25% more at no cost to you. Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your details and address are needed to identify you as a current UK taxpayer.")}
					{" "}
					<Strong fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This information will only be visible to the charity when claiming Gift Aid.")}</Strong>
				</Text>
				<Pane>
					<Strong fontSize={12} fontStyle="italic" color={"#283655"} className="blue-link"
						is={Link}
						target="_blank"
						to={{
							pathname: generatePath("/:page/:mode", {page: "legal", mode: "gift_aid"}),
							// state: {},
						}}
					>
						{I18n.get('Find out more about Gift Aid.')}
					</Strong>
				</Pane>
			</Pane>

			<Pane display="flex" alignItems="center" justifyContent="center" marginTop={16} className="noselect" gap={10}>
				<Button fontSize={16} paddingX={8} height={40} width="80%" justifyContent="center" className='button-blue'
					disabled={processing || !giftAidRequest?.fromFace?.title || !giftAidRequest?.fromFace?.firstName || !giftAidRequest?.fromFace?.lastName || !giftAidRequest?.fromFace?.postcode || !giftAidRequest?.fromFace?.town || !giftAidRequest?.fromFace?.address}
					onClick={() => {

						setGiftAidRequest({
							...giftAidRequest,
							isBeingEdited: undefined,
							hasBeenDeclared: true,
						});

					}}
				>
					{I18n.get('Save')}
				</Button>
				<Button fontSize={14} style={{color: "#EC4C47"}} paddingX={12} height={40} width="80%" justifyContent="center"
					disabled={processing}
					onClick={() => {

						setGiftAidRequest({
							...giftAidRequest,
							isBeingEdited: undefined,
							isToBeDeclared: undefined,
							hasBeenDeclared: false,
						});

					}}
				>
					{I18n.get(`Cancel`)}
				</Button>
			</Pane>

		</Pane>
	);
}

const MatchFundingRequestForm = ({processing = null, ...rest }) => {

	const dispatch = useDispatch();
	
	const userState = useSelector(state => state.userState);
	const transactionDetails = useSelector(state => state.transactionDetails);

	const [matchFundingRequest, setMatchFundingRequest] = useState(transactionDetails.history?.find((historyTransaction, index) => {
		return (historyTransaction.type === "matchFundingRequest")
	}));

	
	useEffect(() => {
		// why not useMemo ?
		let currentIndex = null;
		transactionDetails.history?.find((historyTransaction, index) => {
			if (historyTransaction.type === "matchFundingRequest") {
				currentIndex = index;
				return historyTransaction;
			}
			else {
				return false;
			}
		});
		
		const updatedHistory = transactionDetails.history || [];
		updatedHistory.splice(currentIndex, currentIndex !== null ? 1 : 0, matchFundingRequest);
		dispatch(updateTransactionDetails({
			history: updatedHistory
		}));
	}, [dispatch, matchFundingRequest, transactionDetails.history]);

	function isValidEmail(email) {
		if (email) {
			const trimmedValue = email.toLowerCase().trim();
			// eslint-disable-next-line no-useless-escape
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(trimmedValue).toLowerCase());
		}
		else {
			return null;
		}
	};

	function getDomainNameFromEmail(email) {
		if (!email) {
			return null;
		}
		let emailParts = email.split("@");
		let domain = emailParts.length === 2 ? emailParts[1] : null;
		return domain;
	}

	return (
		<Pane marginTop={10} padding={10} borderWidth={2} borderStyle="dashed" borderColor="#7B8B9A" >

		<Pane marginTop={4} textAlign="center" className="noselect" >
			<Strong fontSize={20} color="#283655">{I18n.get('Match Funding Request Form')}</Strong>
		</Pane>

		<Pane marginTop={4} className="noselect" lineHeight={1} >
			<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("Socially responsible companies support charitable efforts by matching their employees' donations and boosting the positive impact.")}</Text>
		</Pane>

		{/* <Pane marginTop={10} className="noselect" >
			<Strong htmlFor="family-name" marginLeft={2} fontSize={14} color="#283655" >{I18n.get(`Request format`)}</Strong>
		</Pane>
		<Pane marginTop={4} height="auto" alignItems="center" display="flex" justifyContent="center" flexWrap="wrap">
			<Pane flex={1} width="100%" height="auto" className="noselect" >
				<Button width={"100%"} height={40} padding={8} disabled={processing} justifyContent="center"
					borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
					isActive={matchFundingRequest?.description !== "anonymous"}
					onClick={() => {
						setMatchFundingRequest({
							...matchFundingRequest,
							description: "public",
						});
					}}
				>
					<Strong fontSize={14} color={matchFundingRequest?.description !== "anonymous" ? "#283655" : "#7B8B9A"}>{I18n.get('Public')}</Strong>
				</Button>
			</Pane>
			<Pane flex={1} width="100%" height="auto" className="noselect" >
				<Button marginLeft={-1} width={"100%"} height={40} padding={8} disabled={processing} justifyContent="center"
					borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
					isActive={matchFundingRequest?.description === "anonymous"}
					onClick={() => {
						setMatchFundingRequest({
							...matchFundingRequest,
							description: "anonymous",
						});
					}}
				>
					<Strong fontSize={14} color={matchFundingRequest?.description === "anonymous" ? "#283655" : "#7B8B9A"}>{I18n.get('Anonymous')}</Strong>
				</Button>
			</Pane>
		</Pane> */}

		{/* <Pane marginTop={4} marginLeft={2} lineHeight={1} >
			<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >
				{matchFundingRequest?.description !== "anonymous" ?
					I18n.get("Your employer will know that this request comes from you. Their match funding will also be shown on your page.")
				:
					I18n.get("Although your employer will be asked to make an alike donation, they will never know who made this request.")
				}
			</Text>
		</Pane> */}
		

		<Pane marginTop={10} className="noselect" >
			<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
			<Strong htmlFor="work-email" marginLeft={2} fontSize={14} color="#283655" >{I18n.get(`Your work email`)}</Strong>
		</Pane>
		<Pane marginTop={4} >
			<TextInput
				disabled={processing}
				name={"work-email"}
				autoComplete={"work-email"}
				type={"email"}
				// maxLength={40}
				margin={0}
				width={"100%"}
				height={40}
				placeholder={`${I18n.get('Work email')}...`}
				value={matchFundingRequest?.fromFace?.email}
				onChange={(e) => {
					// console.log(e.target.validity);
					let value = e.target.value;
					let temp = value?.trim();
					// let temp = value?.replace(/\s/g, "");
					// console.log(temp);
					if (temp?.length > 150) {
						setMatchFundingRequest({
							...matchFundingRequest,
							fromFace: {
								...matchFundingRequest?.fromFace,
								emailErrorMessage: I18n.get('Too long.'),
								email: value,
							}
						});
					}
					else {
						setMatchFundingRequest({
							...matchFundingRequest,
							fromFace: {
								...matchFundingRequest?.fromFace,
								emailErrorMessage: undefined,
								email: value,
							}
						});
					}
				}}
				onFocus={(e) => {}}
				// onKeyDown={(e) => {
				// 	console.log(e.which);
				// 	if (e.which === 32)
				// 		return false;
				// }}
				onBlur={(e) => {
					// console.log(e.target.value);
					let value = e.target.value;
					// console.log(getDomainNameFromEmail(value));
					// dispatch(updateTransactionDetails({
						// localOfferToAuth: !isValidEmail(value) ? false : offerToSignInBasedOnEmail(value),
					// }));
					setMatchFundingRequest({
						...matchFundingRequest,
						fromFace: {
							...matchFundingRequest?.fromFace,
							emailErrorMessage: matchFundingRequest?.fromFace?.emailErrorMessage || (!isValidEmail(value) && value ? I18n.get('Email is not valid.') : undefined),
							email: value?.trim() || undefined,
						}
					});
					
				}}
			/>
		</Pane>
		<Pane marginTop={2} marginLeft={2} className="noselect" lineHeight={1} >
			<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("This will not be visible to others, needed for us to verify your association with your employer.")}</Text>
		</Pane>


		<Pane marginTop={10} className="noselect" >
			<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
			<Strong htmlFor="employer-email" marginLeft={2} fontSize={14} color="#283655" >{I18n.get(`Your employer's email`)}</Strong>
		</Pane>

		<Pane marginTop={4} >
			<TextInput
				disabled={processing}
				name={"employer-email"}
				autoComplete={"employer-email"}
				type={"email"}
				// maxLength={40}
				margin={0}
				width={"100%"}
				height={40}
				placeholder={`${I18n.get(`Employer's email`)}...`}
				value={matchFundingRequest?.toFace?.email}
				onChange={(e) => {
					// console.log(e.target.validity);
					let value = e.target.value;
					let temp = value?.trim();
					// let temp = value?.replace(/\s/g, "");
					// console.log(temp);
					if (temp?.length > 150) {
							setMatchFundingRequest({
								...matchFundingRequest,
								toFace: {
									...matchFundingRequest?.toFace,
									emailErrorMessage: I18n.get('Too long.'),
									email: value,
								}
							});
					}
					else {
						setMatchFundingRequest({
							...matchFundingRequest,
							toFace: {
								...matchFundingRequest?.toFace,
								emailErrorMessage: undefined,
								email: value,
							}
						});
					}
				}}
				onFocus={(e) => {}}
				// onKeyDown={(e) => {
				// 	console.log(e.which);
				// 	if (e.which === 32)
				// 		return false;
				// }}
				onBlur={(e) => {
					// console.log(e.target.value);
					let value = e.target.value;
					// console.log(getDomainNameFromEmail(value));
					// dispatch(updateTransactionDetails({
						// localOfferToAuth: !isValidEmail(value) ? false : offerToSignInBasedOnEmail(value),
					// }));
					setMatchFundingRequest({
						...matchFundingRequest,
						toFace: {
							...matchFundingRequest?.toFace,
							emailErrorMessage: matchFundingRequest?.toFace?.emailErrorMessage || (!isValidEmail(value) && value ? I18n.get('Email is not valid.') : undefined),
							email: value?.trim() || undefined,
						}
					});
				}}
			/>
		</Pane>
		<Pane marginTop={2} marginLeft={2} className="noselect" lineHeight={1} >
			<Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("This will not be visible to others, needed for us to send a request to your employer.")}</Text>
		</Pane>
		
		<Pane marginTop={10} className="noselect" >
			<Strong htmlFor="what-happens-next" fontSize={14} color="#283655" >
				{I18n.get(`What happens next:`)}{" "}
			</Strong>
			
			<Text fontSize={14} color="#283655" >{I18n.get("we will notify your employer of this request, and we will let you know once your donation is match-funded.")}</Text>
		</Pane>
				

		<Pane display="flex" alignItems="center" justifyContent="center" marginTop={16} className="noselect" gap={10}>
			<Button fontSize={16} paddingX={8} height={40} width="80%" justifyContent="center" className='button-blue'
				disabled={processing
					|| isValidEmail(matchFundingRequest?.toFace?.email) !== true || matchFundingRequest?.toFace?.emailErrorMessage
					|| isValidEmail(matchFundingRequest?.fromFace?.email) !== true || matchFundingRequest?.fromFace?.emailErrorMessage
				}
				onClick={() => {
					setMatchFundingRequest({
						...matchFundingRequest,
						isBeingEdited: undefined,
						description: matchFundingRequest?.description || "public", // ???
						isToBeDeclared: true,
						hasBeenDeclared: true,
					});
				}}
			>
				{I18n.get('Save')}
			</Button>
			<Button fontSize={14} style={{color: "#EC4C47"}} paddingX={12} height={40} width="80%" justifyContent="center"
				disabled={processing}
				onClick={() => {
					setMatchFundingRequest({
						...matchFundingRequest,
						isBeingEdited: undefined,
						isToBeDeclared: undefined,
						hasBeenDeclared: false,
					});
					// submit as cancelled ???
				}}
			>
				{I18n.get(`Cancel`)}
			</Button>
		</Pane>

		</Pane>
	);
}

const VoluntaryContribution = ({processing = null, }) => {

	const dispatch = useDispatch();
	
	const userState = useSelector(state => state.userState);
	const profileDetails = useSelector(state => state.profileDetails);
	const transactionDetails = useSelector(state => state.transactionDetails);
	const localeState = useSelector(state => state.localeState);

	const [voluntaryContribution, setVoluntaryContribution] = useState(transactionDetails.history?.find((historyTransaction) => (historyTransaction?.type === "voluntaryContribution")));
	const voluntaryContributionAmountRef = useRef(voluntaryContribution?.amount);
	
	useMemo(() => {
		let currentIndex = null;
		transactionDetails.history?.find((historyTransaction, index) => {
			if (historyTransaction?.type === "voluntaryContribution") {
				currentIndex = index;
				return historyTransaction;
			}
			else {
				return false;
			}
		});
		const updatedHistory = transactionDetails.history || [];
		updatedHistory.splice(currentIndex, currentIndex !== null ? 1 : 0, voluntaryContribution);
		dispatch(updateTransactionDetails({
			history: updatedHistory
		}));
	}, [dispatch, transactionDetails.history, voluntaryContribution]);


  const editContributionAmountInputRef = useRef();
  const voluntaryContributionRate = 0.15; // rate should come form state or user...
  const minSuggestedContributionAmount = Math.round((Number(currencies[localeState.currency].minValue * 0.4) + Number.EPSILON) * 100) / 100;

  useEffect(() => {
		// useEffect as visual rerender is needed
		let suggestedContributionAmount = Math.round((Number(transactionDetails?.amount * voluntaryContributionRate) + Number.EPSILON) * 100) / 100;
		let maxContributionAmount = Math.max(suggestedContributionAmount, minSuggestedContributionAmount);
		if (voluntaryContributionAmountRef.current < maxContributionAmount) {
			voluntaryContributionAmountRef.current = maxContributionAmount;
		}
		else {
			maxContributionAmount = voluntaryContributionAmountRef.current;
		}

		setVoluntaryContribution((current) => ({
			...current,
			amount: current?.amount ? maxContributionAmount : null,
		}));
		
  }, [minSuggestedContributionAmount, transactionDetails.amount]);

	return (
		!voluntaryContribution ? null :
			<Fragment>
				<Pane height={20} display="flex" alignItems="center" className="noselect"
					paddingTop={20} paddingBottom={10} marginTop={10} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"> 
					<Pane flex={1} display="flex" marginLeft={2} marginRight={12}>
						<Strong fontSize={14} color={"#283655"} >{`${I18n.get('Magic contribution')} `}</Strong>
					</Pane>
					{/* <Pane display="flex" flex={"none"} marginRight={2} >
						<Switch
							height={24}
							disabled={processing}
							checked={voluntaryContribution?.hasBeenDeclared}
							onChange={(e) => {
								let newAmount = Math.max(Math.ceil(Number(transactionDetails.amount * voluntaryContributionRate)), voluntaryContribution?.amount)
								voluntaryContributionAmountRef.current = newAmount;
								setVoluntaryContribution({
									...voluntaryContribution,
									hasBeenDeclared: e.target.checked ? true : false,
									amount: newAmount,
								});
							}}
						/>
					</Pane> */}
					
				</Pane>

				{false ?  null :

					<Fragment>

						<CurrencyNumberInput
							marginTop={4}

							getInputRef={(el) => {editContributionAmountInputRef.current = el}}
							disabled={processing}
							// placeholder={`${I18n.get('Amount')}...`}
							value={voluntaryContribution?.amount}
							currency={voluntaryContribution.currency}
							onChange={(values) => {
								
								// console.log("values.value", values.value);
								let temp = values.value ? Number(values.value) : null;
								// console.log("temp", temp);
								if (isNaN(temp)) {
									temp = null;
								}
								
								let newAmount = temp === null ? null : Math.round((temp || 0 + Number.EPSILON) * 100) / 100;
								if (newAmount !== voluntaryContribution?.amount) {
									voluntaryContributionAmountRef.current = newAmount;
									setVoluntaryContribution({
										...voluntaryContribution,
										amount: newAmount,
									});
								}
								
							}}
							onBlur={(e) => {
								// console.log("e", e.target.value);
								let newAmount = Math.round((voluntaryContribution?.amount || 0 + Number.EPSILON) * 100) / 100;
								voluntaryContributionAmountRef.current = voluntaryContribution?.amount;
								setVoluntaryContribution({
									...voluntaryContribution,
									hasBeenDeclared: newAmount > 0 ? true : false,
									amount: newAmount,
								});
							}}
						/>

						<Pane padding={2} className="noselect" >
							<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Support our platform")}</Text>
						</Pane>
					</Fragment>
				}
			</Fragment>
		
	);
}

const TransactionRecipient = ({processing = null}) => {

	let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);
	const transactionDetails = useSelector(state => state.transactionDetails);

	const handleGetUserDetails = useGetUserDetails();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);


	const [showSelectRecipient, setShowSelectRecipient] = useState(transactionDetails.toPublicFaceId ? false : true);

	useEffect(() => {
		setShowSelectRecipient(transactionDetails.toPublicFaceId ? false : true);
	}, [transactionDetails.toPublicFaceId]);
	
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(false);

  const fetchMoreData = () => {
    setTriggerLoad(true);
  };

	// on initial render, get toFace
	useEffect(() => {
		if (transactionDetails?.toPublicFaceId && !transactionDetails.toFace) {
			handleGetUserDetails({
        publicFaceId: transactionDetails.toPublicFaceId,
        currency: localeState.currency,
        locale: localeState.locale,

				// publicTransactionId: publicTransactionId, // ???
				// indicate to include historyTransactions or not ? (adjust API) // ???

        onSuccess: (userDetails) => {
          dispatch(updateTransactionDetails({
						toFace: userDetails,
					}));
        },
        onError: (error) => {
          console.error("within handleGetUserDetails", error);
        },
      });
		}
	}, [dispatch, handleGetUserDetails, localeState.currency, localeState.locale, transactionDetails.toFace, transactionDetails.toPublicFaceId]);

	// on initial render, get viaFace
	useEffect(() => {
		if (transactionDetails?.viaPublicFaceId && !transactionDetails.viaFace) {

			if (transactionDetails.viaPublicFaceId === profileDetails?.publicFaceId) {
				dispatch(updateTransactionDetails({
					viaFace: profileDetails,
				}));
			}
			else {
				handleGetUserDetails({
					publicFaceId: transactionDetails.viaPublicFaceId,
					currency: localeState.currency,
					locale: localeState.locale,
	
					// publicTransactionId: publicTransactionId, // ???
					// indicate to include historyTransactions or not ? (adjust API) // ???
	
					onSuccess: (userDetails) => {
						dispatch(updateTransactionDetails({
							viaFace: userDetails,
						}));
					},
					onError: (error) => {
						console.error("within handleGetUserDetails", error);
					},
				});
			}
			
		}
	}, [dispatch, handleGetUserDetails, localeState.currency, localeState.locale, profileDetails, transactionDetails.viaFace, transactionDetails.viaPublicFaceId]);

	// this needs fixing ???
  useEffect(() => {

    if (triggerLoad && profileDetails?.publicFaceId && !profileDetails?.following?.error) {
      setTriggerLoad(false);
      handleGetTransactions({
				// toPublicFaceId: null,
        fromPublicFaceId: transactionDetails?.viaPublicFaceId,
        // viaPublicFaceId: null, // ???
        type: "follow",
        currency: localeState.currency,
        locale: localeState.locale,
        // nextToken: localLoadState.nextToken,
				// somehow indicate only those who can be recipients, i.e. can accept donations ???

        onSuccess: (searchResult) => {
					let updatedResults = [...profileDetails.following?.results || [], ...searchResult.results];
					dispatch(updateProfileDetails({
						following: {
							...profileDetails.following,
							results: updatedResults,
							total: searchResult.total,
							nextToken: updatedResults.length === searchResult.total ? null : searchResult.nextToken,
						}
					}));
        },
        onError: (error) => {
          // handle ???
				},
      });
    }
    
  }, [dispatch, handleGetTransactions, localeState.currency, localeState.locale, profileDetails.following, profileDetails?.publicFaceId, transactionDetails?.viaPublicFaceId, triggerLoad]);

	
	const handleRecipientSelection = useCallback((selectedFollowingTransaction) => {

		const toFaceGiftAidAcceptance = selectedFollowingTransaction.toFace?.records?.find((recordTransaction) => (recordTransaction.type === "giftAidAcceptance"));

		const tempUpdatedHistory = transactionDetails.history || [];
    let tempCurrentIndex = null;
		let newGiftAidRequest = {
			type: "giftAidRequest",
			mayBeAdded: toFaceGiftAidAcceptance?.mayBeAccepted, // should depend on the current fromFace
			mayBeAccepted: toFaceGiftAidAcceptance?.mayBeAccepted, // should depend on the current toFace, not viaFace
			isToBeDeclared: undefined,
			rate: toFaceGiftAidAcceptance?.rate,
			description: toFaceGiftAidAcceptance?.description,
			currency: localeState.currency,
			// amountRequired will be added at the backend
		};
    tempUpdatedHistory?.find((historyTransaction, index) => {
      if (historyTransaction.type === "giftAidRequest") {
        tempCurrentIndex = index;
        return historyTransaction;
      }
      else {
        return false;
      }
    });
    tempUpdatedHistory.splice(tempCurrentIndex, tempCurrentIndex !== null ? 1 : 0, newGiftAidRequest);
    let updatedTransactionHistory = tempUpdatedHistory;

		dispatch(updateTransactionDetails({
			toPublicFaceId: selectedFollowingTransaction.toPublicFaceId,
			toFace: {
				...selectedFollowingTransaction.toFace
			},
			history: updatedTransactionHistory,
		}));

		setShowSelectRecipient(false);


	}, [dispatch, localeState.currency, transactionDetails.history]);

	useEffect(() => {
		if (transactionDetails.toPublicFaceId && (!transactionDetails.toFace || transactionDetails.toPublicFaceId !== transactionDetails.toFace?.publicFaceId)) {
			console.log("fetch toFace details");

			const fetchUserDetails = async () => {
				try {
					const response = await API.graphql({
						query: queries.getUserDetails,
						variables: {
							handle: transactionDetails.toPublicFaceId,
							currency: localeState.currency,
							locale: localeState.locale,
							// publicTransactionId: publicTransactionId,
							// indicate to include history and other list or not ??? (adjust API)
						},
						authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
					});
					const faceDetails = response.data.getUserDetails;
					// console.log("faceDetails", faceDetails);
					dispatch(updateTransactionDetails({
						// toPublicFaceId: transactionDetails.toPublicFaceId,
						toFace: faceDetails,
						// history: updatedTransactionHistory, // ???
					}));
				
				}
				catch (error) {
					console.error("within getUserDetails:", error);
				}
			}

			fetchUserDetails();

		}
	}, [dispatch, localeState.currency, localeState.locale, transactionDetails.toFace, transactionDetails.toPublicFaceId, userState.user]);

	const userDetailsAnonymiseAllocationRecipientsRecord = userDetails?.records?.find(r => r.type === "anonymiseAllocationRecipients");
	const anonymiseRecipientOnAllocationInFeedbackPreset = transactionDetails?.history?.find((r) => (r.type === "feedbackPreset" && r.role !== "cover" && r.conditions?.includes("anonymiseRecipientOnAllocation"))); // ???

	const anonymiseRecipient = (userDetailsAnonymiseAllocationRecipientsRecord?.isDefault || anonymiseRecipientOnAllocationInFeedbackPreset); // ???

  return (
    <Fragment>

			{!showSelectRecipient ? null :

				!transactionDetails.viaPublicFaceId ?

					<Fragment>
						<Pane textAlign="center" marginTop={10} >
							<Button fontSize={16} padding={8} height={40} justifyContent="center" width="100%" marginLeft="auto" marginRight="auto" className="noselect"
								// disabled={}
								onClick={() => {
									queryParams.set('camera', "qr_code");
                  history.push({
                    search: `?${queryParams.toString()}`,
                  });
								}}
							>
								{I18n.get('Open camera')}
							</Button>
						</Pane>
					</Fragment>

				:
					<Fragment>

						<Pane marginTop={10} alignItems="center" display="flex" flexWrap="wrap">
							<Pane flex={1} ></Pane>
							<Pane textAlign="center" className="noselect" >
								<Strong fontSize={16} color="#283655">{I18n.get('Select a recipient')}</Strong>
								<Pane textAlign="center" padding={2} className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Choose who you wish to support")}</Text>
								</Pane>
							</Pane>
							<Pane flex={1} ></Pane>
						</Pane>

						{/* {!transactionDetails.viaPublicFaceId ? null :
						"via ..."} */}

						<Table is={"table"} width={"100%"} >

							{!transactionDetails.viaFace?.following?.total || transactionDetails.viaFace?.following?.error ?
								transactionDetails.viaFace?.following?.error ?
									<Pane display="flex" alignItems="center" justifyContent="center" minHeight={50}>
										<Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
									</Pane>
								:
									transactionDetails.viaFace?.following?.total === 0 ?
										<Pane display="flex" alignItems="center" justifyContent="center" minHeight={50}>
											<Text fontSize={14} color="#7B8B9A">{
												I18n.get("No charities yet") // ???
											}</Text>
										</Pane>
									:
										<Pane display="flex" alignItems="center" justifyContent="center" minHeight={50}>
											<Spinner size={24} />
										</Pane>
							:
								<InfiniteScroll
									dataLength={transactionDetails.viaFace?.following?.results?.length}
									next={fetchMoreData}
									hasMore={transactionDetails.viaFace?.following?.results?.length < transactionDetails.viaFace?.following?.total}
									scrollThreshold={0.6}
									// scrollableTarget="scrollableDiv"
									height={"auto"}
									maxHeight={240}
									// loader={<h4>Loading...</h4>}
								>
									<Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
									
										{transactionDetails.viaFace?.following?.results?.map((transaction) => (
											<Table.Row is={"tr"}
												key={transaction.publicTransactionId}
												width={"100%"} 
												height={"auto"}
												paddingY={5}
												isSelectable
												onSelect={() => {
													handleRecipientSelection(transaction);
												}}
												borderStyle="none"
											>
											
												<Table.TextCell
													is={"td"}
													padding={0}
													paddingRight={0}
												>{
													<FaceAvatar
														height={48}
														fontSize={16}
														faceDetails={transaction.toFace}
													/>
												}</Table.TextCell>

												<Table.TextCell
													is={"td"}
													paddingX={0}
													flex={"none"}
												>{
													<Button fontSize={14} paddingX={8} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
														margin={6}
														className="button-blue"
														disabled={false}
														onClick={() => {
															handleRecipientSelection(transaction);
														}}
													>
														{I18n.get('Select')}
													</Button>
												}</Table.TextCell>

											</Table.Row>
										))}
										
										{transactionDetails.viaFace?.following?.results?.length < transactionDetails.viaFace?.following?.total ?
											<Pane display="flex" alignItems="center" justifyContent="center" height={50}>
												<Spinner size={24} />
											</Pane>
										: null }
									</Table.Body>
								</InfiniteScroll>
							}

						</Table>
						
					</Fragment>
			}
			

			{showSelectRecipient ? null :
				<Fragment>
					<Pane marginTop={10} alignItems="center" display="flex" flexWrap="wrap">
						<Pane flex={1} ></Pane>

						<Pane textAlign="center" className="noselect" >
							<Strong fontSize={16} color="#283655">
								{I18n.get('Recipient')}
							</Strong>
						</Pane>

						{transactionDetails?.mode === "allocation" || !transactionDetails?.viaPublicFaceId ?
							<Pane flex={1} ></Pane>
						:
							<Pane flex={1} height={20}>

								<Pane marginLeft="auto" width="fit-content" >
									<Button marginLeft={4} paddingX={4} fontSize={14} height={20} justifyContent="center" appearance="minimal"
										disabled={processing}
										onClick={() => {
											setShowSelectRecipient(true);
										}}
									>
										{I18n.get('Edit')}
									</Button>
								</Pane>

							</Pane>
						}

					</Pane>

					<Pane width={"100%"} height={"auto"} marginTop={10} >
						<Pane width={60} height={60} marginTop={5} marginLeft="auto" marginRight="auto" >
							<AspectRatio ratio={1/1} >
								{transactionDetails.toFace?.pictureUrl ?
									<img width="100%" height="auto" src={transactionDetails.toFace?.pictureUrl} alt="" style={{borderRadius: "2%"}}/>
								:
									<Pane
										padding={5}
										display="flex"
										border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB"
										borderRadius={"50%"}
										// is border needed, it doesn't seem to work here ???
									>
										<FacePH color={true ? "#7B8B9A" : "#E4E7EB"} width="100%" height="100%" />
									</Pane>
								}
							</AspectRatio>
						</Pane>

						<Pane paddingY={8} textAlign="center" marginLeft="auto" marginRight="auto" >
							<Strong display="block" lineHeight={1.1} fontSize={14} color="#283655"
								whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
							>
								{transactionDetails.toFace?.name || transactionDetails.toPublicFaceId}
							</Strong>
							<Text display="block" lineHeight={1.1} fontSize={12} color="#7B8B9A"
								whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
							>
								{transactionDetails.toPublicFaceId}
							</Text>
						</Pane>
						
					</Pane>

				</Fragment>
			}

			{ transactionDetails.toPublicFaceId  ?
				<Pane marginTop={5} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" />
			: null }


    </Fragment>
    
  );
}

function isValidEmail(email) {
	if (email) {
		const trimmedValue = email.toLowerCase().trim();
		// eslint-disable-next-line no-useless-escape
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(trimmedValue).toLowerCase());
	}
	else {
		return null;
	}
};

const ContinueButton = ({disabled = false, onClick, ...rest}) => {

	return (
		<Pane display="flex" alignItems="center" justifyContent="center" marginTop={20} className="noselect">
			<Button fontSize={16} padding={8} height={48} className='button-blue' width="100%" justifyContent="center"
				disabled={disabled}
				onClick={onClick}
			>
				{I18n.get('Continue')}
			</Button>
		</Pane>
	);
}

const NewDonationView = ({ onClose = null, ...rest }) => {

	const countRef = useRef(0);
	const isCurrent = useRef(true);
	useEffect(() => {
		countRef.current = countRef.current + 1;
		console.log(`NewDonationView - ${countRef.current}`);
		return () => {
			console.log("NewDonationView - cleaned up");
			isCurrent.current = false;
		}
	}, []);

	let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	const profileDetails = useSelector(state => state.profileDetails);
	const transactionDetails = useSelector(state => state.transactionDetails);
	const localeState = useSelector(state => state.localeState);

	const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

	const [processing, setProcessing] = useState(false);
	
	const [editAmountToDonate, setEditAmountToDonate] = useState(false);
	const editAmountToDonateInputRef = useRef();
	const availableEmailAddressesRef = useRef([]);
	const takenEmailAddressesRef = useRef([]);

	const handleCheckUserDetails = useAuthCheckUserDetails();

	const offerToSignInBasedOnEmail = useCallback((email) => {

		if (availableEmailAddressesRef.current.includes(email)) {
			return (false);
		}
		if (takenEmailAddressesRef.current.includes(email)) {
			return (true);
		}
    if (email) {
      handleCheckUserDetails({
        email: email,
        onSuccess: (faceDetails) => {
          // console.log("faceDetails", faceDetails);
					if (faceDetails.status === "available") {
						availableEmailAddressesRef.current = [...availableEmailAddressesRef.current, email];
						return (false);
					}
					else {
						takenEmailAddressesRef.current = [...takenEmailAddressesRef.current, email];
						return (true);
					}
        },
        onError: (error) => {
					return (false);
				},
      });
    }
  }, [handleCheckUserDetails]);
	
	const voluntaryContributionRate = 0.15; // rate should come form state or user...

	useEffect(() => {
		if (!transactionDetails?.fromFace && transactionDetails?.fromPublicFaceId) {
			if (userDetails && transactionDetails?.fromPublicFaceId === userDetails?.publicFaceId) {
				dispatch(updateTransactionDetails({
					fromFace: userDetails,
				}));
			}
		}
	}, [dispatch, transactionDetails?.fromFace, transactionDetails?.fromPublicFaceId, userDetails])

	// shape transactionDetails according to type
	useEffect(() => {
		if (transactionDetails?.type === "donation") {

			const toFaceGiftAidAcceptance = transactionDetails?.toFace?.records?.find((recordTransaction) => (recordTransaction.type === "giftAidAcceptance"));

			let transactionHistory = [];
			if (true) {
				transactionHistory.push({
					type: "voluntaryContribution",
					mayBeAdded: true || true, // should depend on the current fromFace settings ???
					hasBeenDeclared: true,
					amount: currencies[localeState.currency].initialValue * voluntaryContributionRate, 
					currency: localeState.currency,
				});
			}
			if (toFaceGiftAidAcceptance?.mayBeAccepted) {
				transactionHistory.push({
					type: "giftAidRequest",
					mayBeAdded: toFaceGiftAidAcceptance?.mayBeAccepted, // should depend on the current fromFace
					mayBeAccepted: toFaceGiftAidAcceptance?.mayBeAccepted, // should depend on the current toFace, not viaFace
					isToBeDeclared: undefined,
					rate: toFaceGiftAidAcceptance?.rate,
					description: toFaceGiftAidAcceptance?.description,
					currency: localeState.currency,
				});
			}
			if (true) {
				transactionHistory.push({
					type: "matchFundingRequest",
					mayBeAdded: true || true, // this should depend on the current fromFace
					isToBeDeclared: undefined,
					currency: localeState.currency,
				});
			}

			dispatch(updateTransactionDetails({
				localFormStep: transactionDetails?.localFormStep || transactionDetails?.mode === "allocation" ? 3 : 0,
				paymentMethod: transactionDetails?.mode === "allocation" ? "balance" : undefined,
				frequency: undefined,
				history: transactionDetails?.mode === "allocation" ? undefined : transactionHistory, 
			}));

		}
		else if (transactionDetails?.type === "topUp") {
			dispatch(updateTransactionDetails({
				localFormStep: 3,
				paymentMethod: undefined,
				frequency: undefined,
				history: undefined,
			}));
		}
		
		
	}, [dispatch, localeState.currency, transactionDetails?.mode, transactionDetails.localUserOwnPage, transactionDetails?.toFace?.records, transactionDetails?.type, transactionDetails?.localFormStep]);

	const giftAidRequestIndex = useMemo(() => {
		let currentIndex = null;
		transactionDetails.history?.find((historyTransaction, index) => {
			if (historyTransaction?.type === "giftAidRequest") {
				currentIndex = index;
				return historyTransaction;
			}
			else {
				return false;
			}
		});
		return currentIndex;
	}, [transactionDetails]);

	const matchFundingRequestIndex = useMemo(() => {
		let currentIndex = null; 
		transactionDetails.history?.find((historyTransaction, index) => {
			if (historyTransaction?.type === "matchFundingRequest") {
				currentIndex = index;
				return historyTransaction;
			}
			else {
				return false;
			}
		});
		return currentIndex;
	}, [transactionDetails]);

	const allocationAmountsRecord = useMemo(() => {

    const allocationAmountsRecords = userDetails?.records?.filter(record => record.type === "allocationAmounts" && record.currency === localeState.currency) || [];

		if (allocationAmountsRecords?.[0]) {
			return {
				...allocationAmountsRecords?.[0],
				type: "allocationAmounts",
				amounts: allocationAmountsRecords?.[0]?.amounts,
				currency: allocationAmountsRecords?.[0]?.currency,
			};
		}
		else {
			return null;
		}
    
  }, [localeState.currency, userDetails?.records]);

	const feedbackPresetsOnAllocation = useMemo(() => {

    // const feedbackPresetRecordCover = userDetails?.records?.find(r => (r.type === "feedbackPreset" && r.role === "cover"));
		const feedbackPresets = userDetails?.records?.filter(r => (r.type === "feedbackPreset" && r.role !== "cover"));

		const feedbackPresetsOnAllocation = feedbackPresets?.filter((r) => {
			if (r.onEvents?.includes("allocation")) {
				return r;
			}
			else {
				return false;
			}
		}) || [];

		if (feedbackPresetsOnAllocation.length > 0) {
			return feedbackPresetsOnAllocation.sort((a, b) => a.amount - b.amount);
		}
		else {
			return null;
		}
    
  }, [userDetails?.records]);

	const handleSelectedFeedbackPresetOnAllocation = (feedbackPreset) => {

		// console.log("... feedbackPreset:", feedbackPreset);

		let toUpdateTransactionDetailsHistory = transactionDetails.history || [];
		let currentIndex = null;
		transactionDetails.history?.find((transaction, index) => {
			if (transaction.publicTransactionId === feedbackPreset.publicTransactionId) {
				currentIndex = index;
				return transaction;
			}
			else {
				return false;
			}
		});

		// console.log("currentIndex:", currentIndex);

		for (let key in feedbackPreset) {
			if (feedbackPreset[key] === null || feedbackPreset[key] === undefined) {
				delete feedbackPreset[key];
			}
		}

		if (currentIndex === null) {

			let newHistoryRecord = {
				type: feedbackPreset.type,
				publicTransactionId: feedbackPreset.publicTransactionId,
				...feedbackPreset,
				fromFace: undefined,
				viaFace: undefined,
				toFace: undefined,
			}

			let newAmount = newHistoryRecord.amount;

			// clear off any other previously added feedbackPresets with amount
			// improve ???
			if (newAmount) {
				toUpdateTransactionDetailsHistory = toUpdateTransactionDetailsHistory.filter((r) => (!(r.type === "feedbackPreset" && r.amount)))
			}

			toUpdateTransactionDetailsHistory.splice(currentIndex, currentIndex !== null ? 1 : 0, newHistoryRecord);
			dispatch(updateTransactionDetails({
				amount: newAmount,
				history: toUpdateTransactionDetailsHistory,
			}));
		}
		else {
			// remove from history
			toUpdateTransactionDetailsHistory.splice(currentIndex, 1);
			dispatch(updateTransactionDetails({
				amount: undefined,
				history: toUpdateTransactionDetailsHistory,
			}));
		}
	};


	const handlePaymentOnComplete = (newTransactionDetails) => {

		console.log("newTransactionDetails on handlePaymentOnComplete", newTransactionDetails);

		dispatch(updateTransactionDetails({
			...newTransactionDetails,
			localSuccessView: true,
		}));

		
		if (newTransactionDetails?.status === "succeeded") {
			batch(() => {

				// add transaction to page's history ???
				// should this just go to a success view offering o see transaction details ?
		
				history.replace({
					pathname: generatePath("/:page?/:publicTransactionId", {...match.params, page: "t", publicTransactionId: newTransactionDetails.publicTransactionId}),
					search: "",
				});
	
			});
		}
		else if (newTransactionDetails?.status === "pending") {
			// handle ???
			
		}
		else if (newTransactionDetails?.error || newTransactionDetails?.status === "error") {
			// handle ???
		}

		setProcessing(false);

	};

	const handlePaymentOnError = (error) => {
		console.error("handlePaymentOnError", error);
		dispatch(updateTransactionDetails({
			error: error,
		}));
	};

	const handleOpenDialogAuth = () => {
		dispatch(updateProfileState({
			localStage: "DialogAuth",
			mode: null,
		}));
	}

	const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

	useEffect(() => {
		if (editAmountToDonate && editAmountToDonateInputRef.current) {
			editAmountToDonateInputRef.current.focus();
		}
	}, [editAmountToDonate]);

	const giftAidRequestRef = useRef(null);
	const matchFundingRequestRef = useRef(null);

	const userDetailsAnonymiseAllocationRecipientsRecord = userDetails?.records?.find(record => record.type === "anonymiseAllocationRecipients");
	const anonymiseRecipientOnAllocationInFeedbackPreset = transactionDetails?.history?.find((r) => (r.type === "feedbackPreset" && r.conditions?.includes("anonymiseRecipientOnAllocation")));

	const anonymiseRecipient = (userDetailsAnonymiseAllocationRecipientsRecord?.isDefault || anonymiseRecipientOnAllocationInFeedbackPreset);

	const voluntaryContribution = transactionDetails.history?.find((historyTransaction) => (historyTransaction?.type === "voluntaryContribution"));

	const giftAidRequest = transactionDetails.history?.find((historyTransaction) => (historyTransaction?.type === "giftAidRequest"));

	const visibleDonationAmount = useMemo(() => {
		
    // const voluntaryContributionAmount = voluntaryContribution?.hasBeenDeclared ? (voluntaryContribution?.amount || 0) : 0;

		const giftAidRequestAmount = giftAidRequest?.isToBeDeclared ? (transactionDetails.amount || 0) * giftAidRequest?.rate : 0;

    const result = Math.round(((
			transactionDetails.amount
			// + voluntaryContributionAmount
			+ giftAidRequestAmount
			+ Number.EPSILON) * 100)) / 100;

		// console.log("visibleDonationAmount", result);
    return result;

	}, [giftAidRequest?.isToBeDeclared, giftAidRequest?.rate, transactionDetails.amount]);

	const transactionFee = useMemo(() => {

		const transactionFeeVariablePartFactor = 0.039; // should depend on currency ???
		const transactionFeeVariablePartAmount = 0.2; // should depend on currency ???

    const result = Math.round(((transactionDetails.amount * transactionFeeVariablePartFactor +transactionFeeVariablePartAmount + Number.EPSILON) * 100)) / 100;

    return result;

	}, [transactionDetails.amount]);

	const [localErrorMessageName, setLocalErrorMessageName] = useState();
	

	return (
	
		<Fragment>
		
			{transactionDetails.type !== "topUp" ? null :
				<Pane paddingTop={4} textAlign="center" className="noselect" >
					<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Add funds to your balance to use later.")}</Text>
				</Pane>
			}

			{match.params.page !== "new" ? null :
				<TransactionRecipient processing={processing} />
			}

			{!transactionDetails.toPublicFaceId ? null :

				<Fragment>
					{!transactionDetails.amount || transactionDetails.mode === "allocation" ? null :
						<Fragment>
							<Pane marginTop={10} alignItems="center" display="flex" flexWrap="wrap">
								<Pane flex={1} ></Pane>
								<Pane textAlign="center" className="noselect" >
									<Strong fontSize={16} color="#425A70" >{I18n.get('Amount')}</Strong>
								</Pane>

								{transactionDetails.mode === "allocation" ?
									<Pane flex={1} ></Pane>
								:
									<Pane flex={1} textAlign="right" >
										<PickCurrency fontColor="#7B8B9A" fontSize={14} hideLabel={true} />
									</Pane>
								}

							</Pane>
							
							<Pane marginTop={10} marginBottom={10} >
								{editAmountToDonate ?
									<Pane marginTop={0} marginBottom={0} textAlign="center" width="100%" alignItems="baseline" display="flex" flexWrap="wrap" justifyContent="center" >
										<Pane marginRight={6} textAlign="right" flex={0.3} height="auto" className="noselect" >
											{currencies[localeState.currency].symbolRight ? null :
												<Strong fontSize={20} lineHeight={1} display={"block"} color={"#283655"} >{currencies[localeState.currency].symbol}</Strong>
											}
										</Pane>
										<Pane flex={0.6} >
											<NumericFormat
												getInputRef={(el) => {editAmountToDonateInputRef.current = el}}
												customInput={TextInput}
												min="0" step="1"
												// format={"#############"}
												thousandSeparator={true}
												// prefix={"£"}
												// suffix={"lev"}
												id={"donation-amount"}
												type={"text"}
												pattern={"[0-9]*"} // to bring up the numeric keypad
												inputMode="numeric" // to bring up the numeric keypad
												// autoComplete="off" // doesn't seem to work
												
												autoComplete={"donation-amount"}
												name={"donation-amount"}
												// disabled={false}
												// isInvalid={localIsInvalid}
												marginTop={0}
												marginBottom={0}
												width="100%"
												height={40}
												fontSize={20}
												color={"#283655"}
												placeholder={`${I18n.get('Amount')}...`}
												value={transactionDetails.amount}
												onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
													
													//improve visual editing ???
													
													let temp = values.value;
													if (temp && temp > 0 && temp !== "") {
														dispatch(updateTransactionDetails({
															amount: Number(temp),
														}));
													}
												}}
												onBlur={(e) => {
													// console.log("onBlur e", e);
													if (!transactionDetails.amount || transactionDetails.amount < currencies[localeState.currency].minValue) {
														dispatch(updateTransactionDetails({
															amount: currencies[localeState.currency].minValue,
														}));
													}
													else {
														dispatch(updateTransactionDetails({
															amount: Math.round(transactionDetails.amount), // a number rounded to the nearest integer
														}));
													}
													setEditAmountToDonate(false);
												}}
											/>
										</Pane>
										<Pane marginLeft={6} textAlign="left" flex={0.3} height="auto" className="noselect" >
											{!currencies[localeState.currency].symbolRight ? null :
												<Strong fontSize={20} lineHeight={1} display={"block"} color={"#283655"} >{currencies[localeState.currency].symbol}</Strong>
											}
										</Pane>
									</Pane>
								:
									<NumberPlusMinusPicker
										step={currencies[localeState.currency].stepValue}
										value={transactionDetails.amount}
										onChange={(value) => {
											// console.log("NumberPlusMinusPicker onChange", value);
											dispatch(updateTransactionDetails({
												amount: value
											}))
										}}
										minValue={currencies[localeState.currency].minValue}
										maxValue={null}
										disabled={processing}
										disabledMinus={(transactionDetails.amount <= currencies[localeState.currency].minValue)}
										disabledPlus={false}
										hideButtons={false}
									>
										{({ value }) => (
											<Button appearance="minimal" isActive={false}
												disabled={processing}
												onClick={() => {
													setEditAmountToDonate(true);
												}}
											>
												<FormattedCurrencyNumber isRound={true} textAlign="center" number={value} currency={localeState.currency} fontSize={30} />
											</Button>
										)}
									</NumberPlusMinusPicker>
								}
							</Pane>

							{transactionDetails.amount > currencies[localeState.currency].minValue ? null :
								<Pane textAlign="center" marginTop={4} >
									<Text fontSize={14} color={transactionDetails.amount < currencies[localeState.currency].minValue ? "#EC4C47" : "#7B8B9A"} >{`${handleFormattedCurrencyNumber(currencies[localeState.currency].minValue, localeState.currency, false, true)} ${I18n.get("min.")}`}</Text>
								</Pane>
							}

							{!transactionDetails.amount || transactionDetails.amount / currencies[localeState.currency].initialValue < 100 ? null :
								<Pane textAlign="center" marginTop={4} >
									<Text fontSize={14} color={"#EC4C47"} >{`${I18n.get("Please contact us for a more cost effective payment.")}`}</Text>
								</Pane>
							}

							{transactionDetails.mode === "allocation" ? null :
								<Pane marginTop={20} height="auto" alignItems="center" display="flex" justifyContent="center" flexWrap="wrap">

									<Button width={60} height={32} padding={8} disabled={processing} justifyContent="center" className="noselect"
										borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
										onClick={() => {
											dispatch(updateTransactionDetails({
												amount: transactionDetails.amount * 2,
											}));
										}}
									>
										<Text fontSize={14} color={"#283655"}>{"×2"}</Text>
									</Button>
										
									<Button marginLeft={-1} width={60} height={32} padding={8} disabled={processing} justifyContent="center" className="noselect"
										borderTopLeftRadius={0} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={0}
										onClick={() => {
											dispatch(updateTransactionDetails({
												amount: transactionDetails.amount * 5,
											}));
										}}
									>
										<Text fontSize={14} color={"#283655"}>{"×5"}</Text>
									</Button>
										
									<Button marginLeft={-1} width={60} height={32} padding={8} disabled={processing} justifyContent="center" className="noselect"
										borderTopLeftRadius={0} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={0}
										onClick={() => {
											dispatch(updateTransactionDetails({
												amount: transactionDetails.amount * 10,
											}));
										}}
									>
										<Text fontSize={14} color={"#283655"}>{"×10"}</Text>
									</Button>
									
									<Button marginLeft={-1} width={60} height={32} padding={8} disabled={processing} justifyContent="center" className="noselect"
										borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
										onClick={() => {
											setEditAmountToDonate(true);
										}}
									>
										<EditIcon size={20} color="#283655" />
									</Button>

								</Pane>
							}


							{profileDetails.settings?.matchDonations === "on" ?	// ???
								<Pane marginLeft={27} marginTop={10} className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{`${profileDetails.name} ${I18n.get("will match fund your donation")}.`}</Text>
								</Pane>
							: null }


						</Fragment>
					}

					{/* {!transactionDetails.amount || !allocationAmountsRecord || transactionDetails.mode !== "allocation" ? null :
						<Fragment>
							<Pane
								paddingTop={20}
								display={"flex"}
								flexWrap={"wrap"}
								gap={10}
							>
								{allocationAmountsRecord?.amounts?.map((amount) => (
									<Button
										key={amount}
										display={"flex"}
										flex={"1 0 21%"}
										// width={32}
										minWidth={70}
										height={32}
										disabled={processing}
										paddingX={8}
										justifyContent="center" className="noselect"
										onClick={() => {
											dispatch(updateTransactionDetails({
												amount: amount,
											}));
										}}
									>
										<Text fontSize={14} color={"#283655"}>{handleFormattedCurrencyNumber(amount, allocationAmountsRecord?.currency, false, false, true)}</Text>
									</Button>
								))}
							</Pane>
						</Fragment>
					} */}

					{!feedbackPresetsOnAllocation || transactionDetails.mode !== "allocation" ? null :
						<Fragment>

							<Pane marginTop={10} marginBottom={10} className="noselect" >
								<Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
								<Strong htmlFor="given-name" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Allocation option')}</Strong>
							</Pane>

							<Pane
								display={"flex"}
								flexWrap={"wrap"}
								gap={10}
							>
								

								{feedbackPresetsOnAllocation.map((feedbackPreset) => (
									<Button
										key={feedbackPreset.publicTransactionId}
										flex={"1 0 49%"}
										isActive={transactionDetails.history?.find((t) => (t.publicTransactionId === feedbackPreset.publicTransactionId))}
										minWidth={70}
										minHeight={50}
										disabled={processing || feedbackPreset?.amount > userState?.user?.balance?.available}
										padding={8}
										height={"auto"}
										justifyContent="center"
										className="noselect"
										onClick={() => {
											handleSelectedFeedbackPresetOnAllocation(feedbackPreset);
										}}
									>
										<Pane
											display={"block"}
											lineHeight={"inherit"}
										>
											<Strong fontSize={16} >{handleFormattedCurrencyNumber(feedbackPreset.amount, feedbackPreset.currency, false, false, true)}</Strong>
											<Paragraph>
												<Text >{feedbackPreset.description}</Text>
											</Paragraph>
											{!feedbackPreset.conditions?.includes("anonymiseRecipientOnAllocation") ? null :
												<Pane padding={4} width={"fit-content"} textAlign="center" alignItems="center" display="flex" flexWrap="wrap" columnGap={10} marginLeft="auto" marginRight="auto" >
													<EyeOffIcon iconSize={22} color="#7B8B9A" marginLeft="auto" marginRight="auto" />
													<Text fontStyle="italic" fontSize={12} color="#7B8B9A" marginLeft="auto" marginRight="auto">
														{I18n.get('Recipient will be anonymised')}
													</Text>
												</Pane>
											}
										</Pane>
									</Button>
								))}
							</Pane>
						</Fragment>
					}


					{!transactionDetails.amount || transactionDetails.localFormStep > 0 ? null : 
						<Fragment>
							<ContinueButton 
								onClick={() => {
									let tempStep = 1;
									if (userDetails) {
										tempStep = 2;
									}
									else if (!transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.mayBeAccepted) {
										tempStep = 2;
									}
									dispatch(updateTransactionDetails({
										localFormStep: transactionDetails.localFormStep + tempStep,
									}));
								}}
							/>

							{userDetails ? null :
								<Fragment>
									<Pane paddingX={"25%"} marginTop={16} marginLeft="auto" marginRight="auto" width={"100%"} alignItems="center" display="flex" flexWrap="wrap" >
										<Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
										<Text flex={"none"} marginX={5} fontSize={16} height="1em" lineHeight={1} color="#7B8B9A" className="noselect" >{I18n.get('or')}</Text>
										<Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
									</Pane>

									<Pane textAlign="center" marginTop={16} className="noselect" >
										<Button fontSize={16} padding={8} height={40} justifyContent="center" width="100%" marginLeft="auto" marginRight="auto"
											// disabled={}
											onClick={handleOpenDialogAuth}
										>
											{I18n.get('Log in')}
										</Button>
									</Pane>
								</Fragment>
							}
						</Fragment>
					}

					{transactionDetails.localFormStep < 1 || transactionDetails.mode === "allocation" ?
						null
					:
						<Fragment>

							{!transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.mayBeAccepted ? null :
								<Fragment>
								
									<Pane ref={giftAidRequestRef} height={30} marginTop={16} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"> 
										<Pane marginTop={12} display="flex" alignItems="center" className="noselect" >

											<DynamicTickBox
												disabled={processing}
												completed={transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared && transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.hasBeenDeclared}
												checked={transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared}
												onClick={() => {
													const updatedHistory = transactionDetails.history || [];
													updatedHistory.splice(giftAidRequestIndex, giftAidRequestIndex !== null ? 1 : 0, {
														...transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest")),
														isBeingEdited: undefined,
														isToBeDeclared: transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared ? undefined : true,
													})
													dispatch(updateTransactionDetails({
														localFormStep: transactionDetails.localFormStep === 1 ? 2 : transactionDetails.localFormStep,
														history: updatedHistory
													}));
												}}
											/>

											<Strong marginLeft={10} fontSize={14} color="#283655">{`${I18n.get('Add')} ${handleFormattedCurrencyNumber((transactionDetails.amount * transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.rate), localeState.currency, false, false, true)} ${I18n.get('for free')}`}</Strong>

											{transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isBeingEdited || !transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared ? null :

												<Pane marginLeft="auto" alignItems="center" display="flex" className="noselect" >
													<Button marginLeft={4} paddingX={4} fontSize={14} height={20} justifyContent="center" appearance="minimal"
														disabled={processing}
														onClick={() => {
															const updatedHistory = transactionDetails.history || [];
															updatedHistory.splice(giftAidRequestIndex, giftAidRequestIndex !== null ? 1 : 0, {
																...transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest")),
																isBeingEdited: true,
															})
															dispatch(updateTransactionDetails({
																history: updatedHistory
															}));
														}}
													>
														{transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.hasBeenDeclared ?
															I18n.get('View declaration')
														:
															I18n.get('Fill in declaration')
														}
													</Button>
												</Pane>
											}

										</Pane>
									</Pane>

									{!transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isBeingEdited ?
										<Fragment>
											{transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared && transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.hasBeenDeclared ? null
											:
												<Pane marginLeft={27} marginTop={4} >
													<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Tick this box to add")}</Text>
													<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{" "}</Text>
													<Strong fontSize={12} fontStyle="italic" color={"#283655"} className="blue-link"
														is={Link}
														target="_blank"
														to={{
															pathname: generatePath("/:page/:mode", {page: "legal", mode: "gift_aid"}),
															// state: {},
														}}
													>
														{I18n.get('Gift Aid')}
													</Strong>
												</Pane>
											}
										</Fragment>
									:
										<GiftAidRequestForm processing={processing} />
									}

								</Fragment>
							}

							{true ? null :
								<Fragment>
									<Pane marginTop={8} className="noselect" >
										<Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Frequency')}</Strong>
										{/* <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{` (${I18n.get('optional')})`}</Text> */}
									</Pane>
								
									<Pane marginTop={4} height="auto" alignItems="center" display="flex" justifyContent="center" flexWrap="wrap">
										<Pane flex={1} width="100%" height="auto" className="noselect" >
											<Button width={"100%"} height={40} padding={8} disabled={processing} justifyContent="center"
												borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
												isActive={!transactionDetails.frequency}
												onClick={() => {
													dispatch(updateTransactionDetails({
														frequency: null,
													}));
												}}
											>
												<Strong fontSize={14} color={!transactionDetails.frequency ? "#283655" : "#7B8B9A"}>{I18n.get('One-off')}</Strong>
											</Button>
										</Pane>
										<Pane flex={1} width="100%" height="auto" className="noselect" >
											<Button marginLeft={-1} width={"100%"} height={40} padding={8} disabled={processing} justifyContent="center"
												borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
												isActive={transactionDetails.frequency === "monthly"}
												onClick={() => {
													dispatch(updateTransactionDetails({
														frequency: "monthly",
													}));
												}}
											>
												<Strong fontSize={14} color={transactionDetails.frequency === "monthly" ? "#283655" : "#7B8B9A"}>{I18n.get('Monthly')}</Strong>
											</Button>
										</Pane>
									</Pane>
								</Fragment>
							}
							
						</Fragment>
					}

					{transactionDetails.localFormStep !== 1 ? null : 
						<ContinueButton
							onClick={() => {
								dispatch(updateTransactionDetails({
									localFormStep: transactionDetails.localFormStep + 1,
								}));
							}}
						/>
					}
					
					{transactionDetails.localFormStep < 2 ? null :

						<Fragment>
							{userDetails ? null :
								<Fragment>
									<Pane marginTop={12} className="noselect" >
										<Strong htmlFor="given-name" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Name')}</Strong>
										<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{` (${I18n.get('optional')})`}</Text>
									</Pane>
									<Pane marginTop={4} >
										<TextInput
											disabled={processing}
											name={"given-name"}
											autoComplete={"given-name"}
											type={"text"}
											margin={0}
											width={"100%"}
											height={40}
											placeholder={`${I18n.get('Name')}...`}
											value={transactionDetails.fromFace?.name}
											onChange={(e) => {
												let value = e.target.value;
												let temp = value?.trim();
												if (temp?.length > 30) {
													dispatch(updateTransactionDetails({
														fromFace: {
															...transactionDetails.fromFace,
															name: value.length < 50 ? value : transactionDetails.fromFace.name,
														}
													}));
													setLocalErrorMessageName(I18n.get('Too long.'));
												}
												else {
													dispatch(updateTransactionDetails({
														fromFace: {
															...transactionDetails.fromFace,
															name: value
														}
													}));
													setLocalErrorMessageName();
												}
											}}
											onBlur={(e) => {
												let value = e.target.value;
												dispatch(updateTransactionDetails({
													fromFace: {
														...transactionDetails.fromFace,
														name: value?.trim() || undefined,
													}
												}));
											}}
											onFocus={(e) => {
												// 
											}}
										/>
									</Pane>
									
									{localErrorMessageName ?
										<Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
											<Text size={400} color="#EC4C47">{localErrorMessageName}</Text>
										</Pane>
									:
										<Pane padding={2} className="noselect" >
											<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This will be visible to others.")}</Text>
										</Pane>
									}

								</Fragment>
							}

							{transactionDetails.mode === "allocation" ? null :	
								<Fragment>					
									<Fragment>
										<Pane marginTop={12} className="noselect" >
											<Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#425A70" >{I18n.get('Caption')}</Strong>
											<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{` (${I18n.get('optional')})`}</Text>
										</Pane>

										<Pane marginTop={4} >
											<textarea
												className="input-standard"
												style={{
													"resize": "vertical",
													"display": "block",
													"width": "100%",
													"height": "80px",
													"minHeight": "80px",
													"maxHeight": "160px",
												}}
												disabled={processing}
												placeholder={`${I18n.get('Write a caption')}...`}
												value={transactionDetails.caption?.replace(/<br\/>/g,'\r\n') || ""}
												onChange={(e) => {
													let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
													if (temp?.length > 2200) {
														dispatch(updateTransactionDetails({
															captionLocalErrorMessage: I18n.get('Too long.'),
														}));
													}
													else {
														dispatch(updateTransactionDetails({
															captionLocalErrorMessage: undefined,
															caption: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
														}));
													}
												}}
												onBlur={(e) => {
													dispatch(updateTransactionDetails({
														caption: transactionDetails.caption?.trim() || undefined,
													}));
												}}
											/>
											{(transactionDetails.captionLocalErrorMessage) ?
												<Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
													<Text size={400} color="#EC4C47">{transactionDetails.captionLocalErrorMessage}</Text>
												</Pane>
											:
												<Pane padding={2} className="noselect" >
													<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This caption will be visible to others.")}</Text>
												</Pane>
											}
										</Pane>
									</Fragment>

									
								</Fragment>	
							}

						</Fragment>
					}

					{transactionDetails.localFormStep !== 2 ? null : 
						<ContinueButton 
							onClick={() => {
									dispatch(updateTransactionDetails({
										localFormStep: transactionDetails.localFormStep + 1,
									}));
								}}
						/>
					}

					{transactionDetails.localFormStep < 3 || userDetails ? null :
						<Fragment>

							<Pane marginTop={12} className="noselect" alignItems="center" display="flex" >
								<Pane className="noselect" >
									<Strong htmlFor="required-field" marginRight={2} fontSize={16}
										color={!transactionDetails.fromFace?.email || transactionDetails.fromFace?.email?.length > 150 ? "#EC4C47" : "#7B8B9A"}
									>
										{'*'}
									</Strong>
									<Strong htmlFor="email" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Email')}</Strong>
								</Pane>
								<Pane marginLeft="auto" alignItems="center" display="flex" className="noselect" >
									<Text fontSize={14} color="#7B8B9A" >{I18n.get('Have an account?')}</Text>
									<Button marginLeft={4} paddingX={4} fontSize={14} height={20} justifyContent="center" appearance="minimal"
										disabled={processing}
										onClick={handleOpenDialogAuth}
									>
										{I18n.get('Log in')}
									</Button>
								</Pane>
							</Pane>
							<Pane marginTop={4} >
								<TextInput
									disabled={processing}
									name={"email"}
									autoComplete={"email"}
									type={"email"}
									// maxLength={40}
									margin={0}
									width={"100%"}
									height={40}
									placeholder={`${I18n.get('Email')}...`}
									value={transactionDetails.fromFace?.email}
									onChange={(e) => {
										// console.log(e.target.validity);
										let value = e.target.value;
										let temp = value?.trim();
										// let temp = value?.replace(/\s/g, "");
										// console.log(temp);
										if (temp?.length > 150) {
											dispatch(updateTransactionDetails({
												fromFace: {
													...transactionDetails.fromFace,
													emailErrorMessage: I18n.get('Too long.'),
													email: value,
												}
											}));
										}
										else {
											dispatch(updateTransactionDetails({
												fromFace: {
													...transactionDetails.fromFace,
													emailErrorMessage: undefined,
													email: value,
												}
											}));
										}
										
									}}
									onFocus={(e) => {}}
									// onKeyDown={(e) => {
									// 	console.log(e.which);
									// 	if (e.which === 32)
									// 		return false;
									// }}
									onBlur={(e) => {
										// console.log(e.target.value);
										let value = e.target.value;
										dispatch(updateTransactionDetails({
											localOfferToAuth: !isValidEmail(value) ? false : offerToSignInBasedOnEmail(value),
											fromFace: {
												...transactionDetails.fromFace,
												emailErrorMessage: transactionDetails.fromFace?.emailErrorMessage || (!isValidEmail(value) && value ? I18n.get('Email is not valid.') : undefined),
												email: value?.trim() || undefined,
											}
										}));
									}}
								/>
							</Pane>

							{transactionDetails?.fromFace?.emailErrorMessage ?
								<Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
									<Text size={400} color="#EC4C47">{transactionDetails?.fromFace?.emailErrorMessage}</Text>
								</Pane>
							:
								<Pane padding={2} className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("We will email you the receipt and feedback")}</Text>
								</Pane>
							}

							{transactionDetails?.localOfferToAuth ?
								<Pane padding={2} className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Looks like you already have an account")}</Text>
								</Pane>
							:
								null
							}

						</Fragment>
					}
							

					{transactionDetails.localFormStep < 3 || !transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.mayBeAdded || transactionDetails?.mode === "allocation" ? null :
						<Fragment>
							<Pane ref={matchFundingRequestRef} minHeight={30} marginTop={10} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"> 
								<Pane marginTop={12} display="flex" alignItems="center" className="noselect" >

									<DynamicTickBox
										disabled={processing}
										completed={transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared && transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.hasBeenDeclared}
										checked={transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared}
										onClick={() => {
											const updatedHistory = transactionDetails.history || [];
											updatedHistory.splice(matchFundingRequestIndex, matchFundingRequestIndex !== null ? 1 : 0, {
												...transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest")),
												isBeingEdited: undefined,
												isToBeDeclared: transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared ? undefined : true,
											});
											dispatch(updateTransactionDetails({
												history: updatedHistory
											}));
										}}
									/>

									<Strong marginLeft={10} fontSize={14} color="#283655">{`${I18n.get('Request match funding')}`}</Strong>

									{transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isBeingEdited || !transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared ? null :

										<Pane marginLeft="auto" alignItems="center" display="flex" className="noselect" >
											<Button marginLeft={4} paddingX={4} fontSize={14} height={20} justifyContent="center" appearance="minimal"
												disabled={processing}
												onClick={() => {
													const updatedHistory = transactionDetails.history || [];
													updatedHistory.splice(matchFundingRequestIndex, matchFundingRequestIndex !== null ? 1 : 0, {
														...transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest")),
														isBeingEdited: true,
													})
													dispatch(updateTransactionDetails({
														history: updatedHistory
													}));
												}}
											>
												{transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.hasBeenDeclared ?
													I18n.get('View form')
												:
													I18n.get('Fill in form')
												}
											</Button>
										</Pane>
									}

								</Pane>
							</Pane>

							{!transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isBeingEdited ?
								<Fragment>
									{transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared && transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.hasBeenDeclared ? null
									:
										<Fragment>
											<Pane marginLeft={27} marginTop={4} className="noselect" >
												<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Invite your employer to amplify your impact")}</Text>
											</Pane>
										{profileDetails.settings?.matchDonations === "on" ?	
											<Pane marginLeft={27} marginTop={4} className="noselect" >
												<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{`${profileDetails.name} ${I18n.get("will match fund your donation")}.`}</Text>
											</Pane>
										: null }
										</Fragment>
									}
								</Fragment>
							:
								<MatchFundingRequestForm processing={processing} />
							}

						</Fragment>
					}

					{transactionDetails.localFormStep < 3 || transactionDetails?.mode === "allocation" ? null :
						<Fragment>
							<VoluntaryContribution processing={processing} />
						</Fragment>
					}

					{transactionDetails.mode !== "allocation" ? null :
						<Fragment>
							<Pane marginTop={16} className="noselect" >
								<Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Internal note')}</Strong>
								<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{` (${I18n.get('optional')})`}</Text>
							</Pane>

							<Pane marginTop={4} >
								<textarea
									className="input-standard"
									style={{
										"resize": "vertical",
										"display": "block",
										"width": "100%",
										"height": "60px",
										"minHeight": "60px",
										"maxHeight": "160px",
									}}
									disabled={processing}
									placeholder={`${I18n.get('Note')}...`}
									value={transactionDetails.note?.replace(/<br\/>/g,'\r\n') || ""}
									onChange={(e) => {
										let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
										if (temp?.length > 2200) {
											// dispatch(updateTransactionDetails({
											// 	captionLocalErrorMessage: I18n.get('Too long.'),
											// }));
										}
										else {
											// captionLocalErrorMessage: undefined,
											dispatch(updateTransactionDetails({
												note: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
											}));
										}
									}}
									onBlur={(e) => {
										dispatch(updateTransactionDetails({
											note: transactionDetails.note?.trim() || undefined,
										}));
									}}
								/>
								{/* {(transactionDetails.captionLocalErrorMessage) ?
									<Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
										<Text size={400} color="#EC4C47">{transactionDetails.captionLocalErrorMessage}</Text>
									</Pane>
								:
									<Pane padding={2} className="noselect" >
										<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This note will not be visible to others.")}</Text>
									</Pane>
								} */}
								<Pane padding={2} className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This note will not be visible to others")}</Text>
								</Pane>
							</Pane>
							
						</Fragment>
					}

					{transactionDetails.localFormStep < 3 ? null : 
						<Fragment>
							<PaymentComponent
								buttonText={
									transactionDetails.mode === "topUp" ? I18n.get('Top up') // ???
									: transactionDetails.mode === "allocation" ? I18n.get('Allocate')
										: I18n.get('Donate')
								}
								suffixAmount={visibleDonationAmount || false}
								disabled={processing
									|| (transactionDetails.mode !== "allocation" && transactionDetails.amount < currencies[localeState.currency].minValue)
									|| (!userDetails && !isValidEmail(transactionDetails.fromFace?.email))
									|| (transactionDetails.mode === "allocation" && !transactionDetails.history?.find(r => (r.type === "feedbackPreset" && r.role !== "cover")))
								}
								allowToUseUserBallance={true}
								onProcessing={() => {
									setProcessing(true); // boolean
								}}
								onComplete={handlePaymentOnComplete}
								onError={handlePaymentOnError}
								validationProcess={() => {
									if (transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.isToBeDeclared && !transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest"))?.hasBeenDeclared) {
										const updatedHistory = transactionDetails.history || [];
										updatedHistory.splice(giftAidRequestIndex, giftAidRequestIndex !== null ? 1 : 0, {
											...transactionDetails.history?.find((transaction) => (transaction?.type === "giftAidRequest")),
											isBeingEdited: true,
										})
										dispatch(updateTransactionDetails({
											history: updatedHistory
										}));
										window.scrollTo({left:0, top: giftAidRequestRef.current.offsetTop - 20, behavior: 'smooth'});
										return false;
									}
									if (transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.isToBeDeclared && !transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest"))?.hasBeenDeclared) {
										const updatedHistory = transactionDetails.history || [];
										updatedHistory.splice(matchFundingRequestIndex, matchFundingRequestIndex !== null ? 1 : 0, {
											...transactionDetails.history?.find((transaction) => (transaction?.type === "matchFundingRequest")),
											isBeingEdited: true,
										})
										dispatch(updateTransactionDetails({
											history: updatedHistory
										}));
										window.scrollTo({left:0, top: matchFundingRequestRef.current.offsetTop - 20, behavior: 'smooth'});
										return false;
									}
									return true;
								}}
							/>
							
							{transactionDetails.mode === "allocation" || voluntaryContribution?.amount < transactionFee ? null :
								<Pane paddingTop={10} textAlign="center" className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >
										{I18n.get("100% of your donation will go to")}
										{" "}
										{transactionDetails.toFace?.name || transactionDetails.toPublicFaceId}
										{"."}
									</Text>
								</Pane>
							}

							{transactionDetails.mode !== "allocation" ? null :
								<Pane paddingTop={10} textAlign="center" className="noselect" >
									<Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{`${I18n.get("Your current balance")}: ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency, false, false, true)}`}</Text>
								</Pane>
							}

							{!anonymiseRecipient ? null :
								<Pane padding={4} width={"fit-content"} textAlign="center" alignItems="center" display="flex" flexWrap="wrap" columnGap={10} marginLeft="auto" marginRight="auto" >
									<EyeOffIcon iconSize={22} color="#7B8B9A" marginLeft="auto" marginRight="auto" />
									<Text fontStyle="italic" fontSize={12} color="#7B8B9A" marginLeft="auto" marginRight="auto">
										{I18n.get('The recipient will be anonymised')}
									</Text>
								</Pane>
							}

						</Fragment>
					}
				</Fragment>
			}
			
		</Fragment>

	);
}

export default NewDonationView;