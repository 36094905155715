import { useEffect, useState, useMemo, useCallback, useContext, useRef } from 'react';
import { Link, useHistory, useRouteMatch} from 'react-router-dom';
import { API, I18n } from 'aws-amplify';
import { Text, Checkbox, Paragraph, Pane, Popover, Menu, Position, Button, Heading, Strong, TextInputField } from 'evergreen-ui';
import { currencies } from '../services/localeDictionary';

import { ReactComponent as EUFlag } from '../assets/flags/eu.svg';
import { ReactComponent as GBFlag } from '../assets/flags/gb.svg';
import { ReactComponent as BGFlag } from '../assets/flags/bg.svg';
import { ReactComponent as CHFlag } from '../assets/flags/ch.svg';
import { ReactComponent as ZAFlag } from '../assets/flags/za.svg';
import { ReactComponent as RUFlag } from '../assets/flags/ru.svg';
import { ReactComponent as USFlag } from '../assets/flags/us.svg';
import { ReactComponent as AMFlag } from '../assets/flags/am.svg';
import { ReactComponent as UAFlag } from '../assets/flags/ua.svg';
import { ReactComponent as AUFlag } from '../assets/flags/au.svg';
import { ReactComponent as CAFlag } from '../assets/flags/ca.svg';
import { ReactComponent as AEFlag } from '../assets/flags/ae.svg';
import { ReactComponent as NOFlag } from '../assets/flags/no.svg';
import { ReactComponent as SEFlag } from '../assets/flags/se.svg';
import { ReactComponent as PLFlag } from '../assets/flags/pl.svg';
import { ReactComponent as DKFlag } from '../assets/flags/dk.svg';
import { ReactComponent as CZFlag } from '../assets/flags/cz.svg';
import { ReactComponent as CNFlag } from '../assets/flags/cn.svg';

import { useHandleLocaleSelection } from './PickLanguage';

import { useSelector } from 'react-redux';

const FlagByCurrency = ({ currency }) => {

  switch (currency) {
    case 'eur':
      return (
        <EUFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'bgn':
      return (
        <BGFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'chf':
      return (
        <CHFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'nok':
      return (
        <NOFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'sek':
      return (
        <SEFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'gbp':
      return (
        <GBFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'pln':
      return (
        <PLFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'dkk':
      return (
        <DKFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'czk':
      return (
        <CZFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'zar':
      return (
        <ZAFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'rub':
      return (
        <RUFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'usd':
      return (
        <USFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'amd':
      return (
        <AMFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'uah':
      return (
        <UAFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'aud':
      return (
        <AUFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'cad':
      return (
        <CAFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'aed':
      return (
        <AEFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'cny':
      return (
        <CNFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
      
    default:
      return (
        <GBFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
  }
};

const CurrencyRows = ({ onSelect }) => {

  // const localeState = useSelector(state => state.localeState);
  
  const renderPickCurrencyRow = useCallback((item) => {
    return (
      <Menu.Item
        key={item.code}
        onSelect={() => {
          onSelect(item.code);
        }}
      >
        <Pane alignItems="center" display="flex" flexWrap="wrap" marginTop={3} >
          <Pane padding={2} width={28} height="100%" borderRadius={3} >
            <FlagByCurrency currency={item.code} />
          </Pane>
          <Text flex={"none"} size={300} marginLeft={8} >{`${item.name} (${item.symbol})`}</Text>
        </Pane>
      </Menu.Item>
    );
  }, [onSelect]);

  return (
    Object.values(currencies).map((value) => (renderPickCurrencyRow(value)))
  );
}

const PickCurrency = ({ fontColor = "#283655", fontSize = 14, hideLabel = false, width, onSelect = null, disabled = false, selectedCurrency = null, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PickCurrency - ${countRef.current}`);
    return () => {
      console.log("PickCurrency - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const localeState = useSelector(state => state.localeState);
  const localeStateCurrencyRef = useRef(localeState.currency);
  const [currentSelectedCurrency, setCurrentSelectedCurrency] = useState(selectedCurrency || localeStateCurrencyRef.current);

  // useEffect(() => {
  //   localeStateCurrencyRef.current = localeState.currency;
  //   setCurrentSelectedCurrency(localeState.currency);
  // }, [localeState.currency]);

  
  const handleLocaleSelection = useHandleLocaleSelection();

  const handleSelectCurrency = useCallback((selectedItem, close) => {

    setCurrentSelectedCurrency(selectedItem);
    if (typeof onSelect === "function") {
      onSelect(selectedItem);
    }
    else {
      if (selectedItem !== localeStateCurrencyRef.current) {
        handleLocaleSelection(null, selectedItem);        
      }
    }

    localeStateCurrencyRef.current = selectedItem;
    close();

  }, [handleLocaleSelection, onSelect]);



  return (
    <Pane alignItems="center" display="inline-flex" flexWrap="wrap" >
      {hideLabel === true ? null :
        <Pane flex={"none"} marginRight={4} >
          <Text color={fontColor} fontSize={fontSize} >{I18n.get('Currency')}</Text>
        </Pane>
      }
      <Popover
        minWidth={100}
        width={100}
        position={Position.TOP}
        statelessProps={{
          maxHeight: "100%",
          overflow: "scroll",
        }}
        content={({ close }) => (
          <Menu >
            <Menu.Group >
              <CurrencyRows onSelect={(selectedItem) => {
                handleSelectCurrency(selectedItem, close);
              }} />
            </Menu.Group>
          </Menu>
        )}
      >
        <Button margin={0} padding={2} width={width || 24} height="100%" disabled={disabled}>
          <FlagByCurrency currency={currentSelectedCurrency} />
        </Button>
      </Popover>
    </Pane>
  );
}

export default PickCurrency;
export { FlagByCurrency };