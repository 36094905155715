import React from 'react';
// covert SVG here - https://react-svgr.com/

const TwitterIcon = (props) => {
  // fill={props.color || "#000000"}

  return (
    <svg
      viewBox="0 0 160 131"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M50.317 130.008c60.378 0 93.402-50.022 93.402-93.401 0-1.421 0-2.836-.096-4.244A66.788 66.788 0 00160 15.371a65.53 65.53 0 01-18.854 5.165A32.947 32.947 0 00155.578 2.38a65.796 65.796 0 01-20.845 7.968A32.87 32.87 0 00110.798 0C92.772 0 77.94 14.833 77.94 32.858c0 2.5.286 4.993.85 7.429A93.24 93.24 0 0111.137 5.989C2.473 20.904 6.956 40.232 21.3 49.81a32.584 32.584 0 01-14.9-4.108v.416c.005 15.567 11.078 29.097 26.336 32.18a32.782 32.782 0 01-14.822.562c4.29 13.34 16.66 22.534 30.669 22.797A65.884 65.884 0 010 115.263a92.946 92.946 0 0050.317 14.72"
        fill={props.color || "#000000"}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default TwitterIcon;