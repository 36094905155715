import { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';

import { Link, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Paragraph, Table, Button, Pane, Spinner, TextInput } from 'evergreen-ui';

import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

import { useSearch, useSearchForPageDetails } from "../services/useSearch";

import Footer from '../components/Footer';

import FaceAvatar from '../components/FaceAvatar';

import { useSelector } from 'react-redux';

import { I18n } from 'aws-amplify';

const Search = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("Search", countRef.current)
    return () => {
      console.log("Search cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();


  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const handleSearch = useSearch();
  const handleSearchForPageDetails = useSearchForPageDetails();

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const [searchQuery, setSearchQuery] = useState();
  const [tempSearchQuery, setTempSearchQuery] = useState();
  const [isSearchProcessing, setIsSearchProcessing] = useState(false);

  const [isSearchForPageDetailsProcessing, setIsSearchForPageDetailsProcessing] = useState(false);

  const searchQueryRef = useRef();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  

  const searchData = useCallback(({query = null, nextToken = null}) => {

    // how to abort a call ???

    if (!query) {
      return null;
    }

    console.log("searchData...");

    setIsSearchProcessing(true);

    handleSearch({
      query: query,
      // toPublicFaceId: userDetails?.publicFaceId,
      // fromPublicFaceId: null,
      // viaPublicFaceId: null,
      // type: "donation",
      // currency: localeState.currency,
      // locale: localeState.locale,
      nextToken: nextToken || localLoadState.nextToken,
      onSuccess: (searchResult) => {
        console.log("searchResult", searchResult);
        setLocalLoadState((prev) => {

          // let updatedResultsList = [...prev?.results || [], ...searchResult.results]; // add
          let updatedResultsList = searchResult.results; // replace

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          setIsSearchProcessing(false);

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });

  }, [handleSearch, localLoadState.nextToken]);

  const searchForPageDetails = useCallback(({faceDetails = null}) => {

    if (!faceDetails) {
      return null;
    }

    console.log("searchForPageDetails...");

    setIsSearchForPageDetailsProcessing(true);

    handleSearchForPageDetails({
      charityRegNumber: faceDetails.records?.find(r => r.type === "charityDetails")?.regNumber,
      cicRegNumber: faceDetails.records?.find(r => r.type === "cicDetails")?.regNumber,
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: null,
      // type: "donation",
      // currency: localeState.currency,
      // locale: localeState.locale,
      onSuccess: (faceDetails) => {
        console.log("faceDetails", faceDetails);
        setIsSearchForPageDetailsProcessing(false);
      },
      onError: (error) => {
        // handle ???
        setIsSearchForPageDetailsProcessing(false);
      },
    });

  }, [handleSearchForPageDetails, userDetails?.publicFaceId]);


  // trigger searchData
  useEffect(() => {
    if (searchQueryRef.current !== searchQuery) {
      searchQueryRef.current = searchQuery;
      searchData({
        query: searchQuery,
      });
    }
  }, [searchQuery, searchData]);

  const handleChangeSearchQuery = (event) => {
    let value = event.target.value;
    // console.log("setSearchQuery", value);
    setTempSearchQuery(value);
  };

  useEffect(() => {

    let delay = 0.8 // in seconds
    let timerToTriggeringSearchData = null;

    if (tempSearchQuery) {
      timerToTriggeringSearchData = setTimeout(() => {
        console.log(`trigger search after ${delay} sec:`, tempSearchQuery);
        setSearchQuery(tempSearchQuery);
      }, delay * 1000);
    }
    else {
      setSearchQuery();
      clearTimeout(timerToTriggeringSearchData);
    }
    
    return () => {
      clearTimeout(timerToTriggeringSearchData);
    };

  }, [tempSearchQuery]);

  const clearSearchResults = () => {
    setLocalLoadState({
      results: null,
      total: null,
      nextToken: null,
      error: null,
    });
  };

  // clear no result sna d error on empty query
  useEffect(() => {
    if (localLoadState.total === 0 && !searchQuery) {
      clearSearchResults();
    }
  }, [localLoadState.total, searchQuery]);


  return (
    <Fragment >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>


        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Search"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane width="100%" marginTop={36} >

          <Pane maxWidth={350} marginX={"auto"} >
          
            <Pane marginTop={10} marginBottom={4} >
              <Strong marginX={2} htmlFor="search_facedonate" fontSize={14} color="#425A70" >{I18n.get('Search')}</Strong>
            </Pane>
            
            <TextInput
              ref={null}
              disabled={false}
              name={"search_facedonate"}
              // autoComplete={""}
              type={"text"} //
              // margin={0}
              width={"100%"}
              height={40}
              placeholder={`${I18n.get('Search')}...`}
              // value={}
              onChange={handleChangeSearchQuery}
              onFocus={(e) => {
                console.log("search onFocus");
              }}
              onBlur={(e) => {
                console.log("search onBlur");
              }}
            />

            <Pane marginX={2} marginY={2} >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Search across over 200,000 charities.")}</Text>
            </Pane>

          </Pane>

          {!isSearchProcessing ?
            null
          :
            <Pane display="flex" alignItems="center" justifyContent="center" height={40}>
              <Spinner size={24} />
            </Pane>
          }


          <Pane maxWidth={350} marginX={"auto"} marginTop={10} >

            <Table
              is={"table"}
              width={"100%"}
              style={{
                borderCollapse: "collapse",
              }}
            >

              {!localLoadState.total || localLoadState?.error ?
                localLoadState?.error ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                    <Text fontSize={14} color="#7B8B9A">{I18n.get("Something went wrong. Please try again.")}</Text>
                  </Pane>
                :
                  localLoadState.total === 0 ?
                    <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                      <Text fontSize={14} color="#7B8B9A">{I18n.get("No results")}</Text>
                    </Pane>
                  :
                    null
              :
                <InfiniteScroll
                  dataLength={localLoadState.results?.length}
                  next={searchData}
                  hasMore={localLoadState.results?.length < localLoadState.total}
                  scrollThreshold={0.6}
                  // scrollableTarget="scrollableDiv"
                  // height={240}
                  // loader={<h4>Loading...</h4>}
                >
                  <Table.Body
                    is={"tbody"}
                    id="scrollableDiv"
                    overflow={"scroll"}
                    display={"block"}
                  >
                    {localLoadState.results?.map((faceDetails) => (

                      <Table.Row is={"tr"} key={faceDetails.publicFaceId}
                        display={"grid"}
                        // width={"100%"}
                        paddingX={0}
                        isSelectable
                        onSelect={() => {
                          if (faceDetails.status === "n") {
                            // alert(faceDetails.publicFaceId);
                            searchForPageDetails({faceDetails});
                          }
                          else {
                            // alert(faceDetails.publicFaceId);
                            const tempUrl = generatePath("/:handle", {handle: faceDetails.publicFaceId});
                            window.open(tempUrl, '_blank');
                          }
                        }}
                      >

                        <Pane
                          paddingX={4}
                          display="flex" alignItems={"center"}
                          overflow={"hidden"}
                        >
                          <FaceAvatar
                            pictureOnly={true}
                            faceDetails={faceDetails}
                          />
                          <Pane
                            marginLeft={8}
                            overflow={"hidden"}
                          >
                            <Strong
                              display="block" textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}
                              lineHeight={1.3} fontSize={14} color="#283655"
                            >
                              {faceDetails.name || faceDetails.username}
                            </Strong>
                          
                            <Text
                              display="block" textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} 
                              lineHeight={1.1} fontSize={12} color="#7B8B9A"
                            >
                              {`${faceDetails.username} ${faceDetails?.records?.find(r => r.type === "charityDetails")?.regNumber ? ` · ${faceDetails?.records?.find(r => r.type === "charityDetails")?.regNumber}` : ""}`}
                            </Text>
                          </Pane>
                          
                        </Pane>
                        
                      </Table.Row>
                    ))}

                    {localLoadState.results?.length < localLoadState.total ?
                      <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                        <Spinner size={24} />
                      </Pane>
                    : null }

                  </Table.Body>
                </InfiniteScroll>
              }
              
            </Table>
          </Pane>

        </Pane>

      </Pane>
      <Footer/>
    </Fragment>
  );
}

export default Search;