// covert SVG here - https://react-svgr.com/

const FacePH = (props) => {

  return (
    <svg
      viewBox="0 0 357 357"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="100%"
      height="100%"
      {...props}
    >
      <path fill="none" d="M0 0h356.867v356.867H0z" />
      <g fill={props.color || "#000000"} >
        <path d="M108.575 275.903c4.266 0 7.982 3.263 7.981 7.655.001 4.392-3.715 7.655-7.982 7.655-17.955 0-32.252 12.555-32.252 27.676 0 4.392-3.716 7.655-7.982 7.655-4.267 0-7.982-3.263-7.982-7.655 0-23.905 21.729-42.986 48.216-42.986h.001z" />
        <path d="M108.574 281.202c1.482 0 2.683 1.055 2.683 2.356s-1.201 2.355-2.683 2.355c-20.739 0-37.552 14.764-37.552 32.976 0 1.301-1.201 2.356-2.683 2.356-1.481 0-2.682-1.055-2.682-2.356 0-20.814 19.215-37.687 42.917-37.687z" />
        <path d="M108.574 281.202c1.482 0 2.683 1.055 2.683 2.356s-1.201 2.355-2.683 2.355c-20.739 0-37.552 14.764-37.552 32.976 0 1.301-1.201 2.356-2.683 2.356-1.481 0-2.682-1.055-2.682-2.356 0-20.814 19.215-37.687 42.917-37.687zM150.192 249.782c-3.222-2.797-3.889-7.697-1.01-11.013 2.879-3.317 7.824-3.346 11.046-.55 13.559 11.77 32.587 11.66 42.498.241 2.879-3.318 7.825-3.346 11.046-.55 3.222 2.797 3.89 7.696 1.011 11.014-15.67 18.053-44.588 18.22-64.591.858zM248.292 275.903c26.487 0 48.217 19.081 48.217 42.986 0 4.392-3.716 7.655-7.982 7.655s-7.982-3.263-7.982-7.655c0-15.121-14.298-27.676-32.253-27.676-4.266 0-7.982-3.263-7.982-7.655s3.716-7.655 7.982-7.655z" />
        <path d="M248.292 281.202c-1.481 0-2.682 1.055-2.682 2.356s1.201 2.355 2.682 2.355c20.74 0 37.553 14.764 37.553 32.976 0 1.301 1.201 2.356 2.682 2.356 1.481 0 2.682-1.055 2.682-2.356 0-20.814-19.214-37.687-42.917-37.687z" />
        <path d="M248.292 281.202c-1.481 0-2.682 1.055-2.682 2.356s1.201 2.355 2.682 2.355c20.74 0 37.553 14.764 37.553 32.976 0 1.301 1.201 2.356 2.682 2.356 1.481 0 2.682-1.055 2.682-2.356 0-20.814-19.214-37.687-42.917-37.687zM209.484 275.764c.779.028 1.558.033 2.338.035 2.663.436 4.56 1.885 6.322 3.805l.166.207.145.223c2.904 4.473 1.632 10.455-2.841 13.357a40.577 40.577 0 01-6.761 3.528 9.663 9.663 0 01-12.593-5.27c-2.024-4.932.331-10.575 5.263-12.6a21.257 21.257 0 003.568-1.853l.226-.146 1.458-.751.468-.14c.806-.2 1.407-.354 2.241-.395z" />
        <path d="M208.766 281.235c2.138-.642 3.872-.024 5.244 1.685a4.358 4.358 0 01-1.282 6.027 35.417 35.417 0 01-5.877 3.065 4.362 4.362 0 01-5.688-2.375 4.357 4.357 0 012.374-5.685 26.65 26.65 0 004.444-2.313l.785-.404z" />
        <path d="M208.766 281.235c2.138-.642 3.872-.024 5.244 1.685a4.358 4.358 0 01-1.282 6.027 35.417 35.417 0 01-5.877 3.065 4.362 4.362 0 01-5.688-2.375 4.357 4.357 0 012.374-5.685 26.65 26.65 0 004.444-2.313l.785-.404zM177.962 283.342c5.085.279 8.785 3.786 9.491 8.796a9.659 9.659 0 01-8.761 10.479 40.651 40.651 0 01-7.621-.017c-5.307-.521-9.186-5.245-8.67-10.551l.026-.22c.434-3.107 2.196-5.835 5.009-7.345a8.14 8.14 0 011.457-.603c.292-.091.485-.141.87-.234.168-.04.218-.053.226-.056l.759-.235 1.944-.005.264.026a21.3 21.3 0 004.018.003l.988-.038z" />
        <path d="M171.557 288.654l.883-.003a26.65 26.65 0 005.007.008 4.356 4.356 0 011.388 8.579 4.378 4.378 0 01-.613.1c-2.288.203-4.459.2-6.633-.013a4.36 4.36 0 01-3.913-4.763c.2-1.431.958-2.706 2.266-3.408.497-.266 1.077-.333 1.615-.5z" />
        <path d="M171.557 288.654l.883-.003a26.65 26.65 0 005.007.008 4.356 4.356 0 011.388 8.579 4.378 4.378 0 01-.613.1c-2.288.203-4.459.2-6.633-.013a4.36 4.36 0 01-3.913-4.763c.2-1.431.958-2.706 2.266-3.408.497-.266 1.077-.333 1.615-.5zM142.477 269.826c2.608-.005 4.438.805 6.675 2.382l.357.252.311.306a259.643 259.643 0 004.169 4.018c4.201 3.954 3.826 10.412-.73 13.926-1.863 1.442-4.172 2.186-6.517 2.186-2.681 0-5.304-.972-7.247-2.809a267.6 267.6 0 01-4.495-4.325c-4.108-4.048-3.564-10.49 1.069-13.909l.203-.15.217-.13c.155-.093.597-.364.696-.423a6.656 6.656 0 011.374-.634c.308-.102 1.376-.359 1.845-.49l.671-.186 1.402-.014z" />
        <path d="M142.487 275.126c1.46-.003 2.543.661 3.612 1.414a270.82 270.82 0 004.258 4.103c1.809 1.704 1.657 4.335-.341 5.876-.933.722-2.107 1.077-3.274 1.077-1.329 0-2.656-.461-3.617-1.37a252.95 252.95 0 01-4.405-4.239c-1.764-1.738-1.543-4.365.496-5.87 1.324-.794.495-.397 2.607-.985l.664-.006z" />
        <path d="M142.487 275.126c1.46-.003 2.543.661 3.612 1.414a270.82 270.82 0 004.258 4.103c1.809 1.704 1.657 4.335-.341 5.876-.933.722-2.107 1.077-3.274 1.077-1.329 0-2.656-.461-3.617-1.37a252.95 252.95 0 01-4.405-4.239c-1.764-1.738-1.543-4.365.496-5.87 1.324-.794.495-.397 2.607-.985l.664-.006zM120.841 248.039c2.677-.006 4.472.926 6.614 2.696l.342.283.29.335a253.576 253.576 0 003.721 4.205c3.581 3.954 3.279 10.063-.671 13.636a9.634 9.634 0 01-6.484 2.503 9.625 9.625 0 01-7.156-3.174 257.375 257.375 0 01-4.015-4.531c-3.49-4.034-3.052-10.132.982-13.622l.201-.174.217-.153c.134-.094.531-.38.625-.445a6.4 6.4 0 011.408-.759c.332-.129 1.328-.41 1.703-.532l.771-.251 1.452-.017z" />
        <path d="M120.853 253.339c1.303-.003 2.271.692 3.225 1.481a257.04 257.04 0 003.802 4.296 4.356 4.356 0 11-6.457 5.847 248.426 248.426 0 01-3.934-4.439 4.358 4.358 0 01.443-6.147c1.182-.832.442-.416 2.328-1.031l.593-.007z" />
        <path d="M120.853 253.339c1.303-.003 2.271.692 3.225 1.481a257.04 257.04 0 003.802 4.296 4.356 4.356 0 11-6.457 5.847 248.426 248.426 0 01-3.934-4.439 4.358 4.358 0 01.443-6.147c1.182-.832.442-.416 2.328-1.031l.593-.007zM254.032 227.323c.393.037 1.426.094 1.778.147a6.426 6.426 0 011.54.432c.107.044.556.236.708.299l.244.101.232.124c4.699 2.521 6.465 8.375 3.943 13.077a114.53 114.53 0 01-3.163 5.507 9.648 9.648 0 01-8.248 4.632 9.614 9.614 0 01-5.026-1.413c-4.551-2.776-5.991-8.715-3.214-13.276a92.772 92.772 0 002.633-4.581l.057-.104c1.391-2.461 3.11-3.685 5.662-4.492.216-.068.438-.135.824-.251l.913-.19 1.117-.012z" />
        <path d="M253.532 232.599c1.975.187 1.162-.056 2.497.497a4.358 4.358 0 011.779 5.899 108.643 108.643 0 01-3.021 5.259 4.35 4.35 0 01-3.722 2.088 4.359 4.359 0 01-3.718-6.625 97.835 97.835 0 002.783-4.841c.891-1.577 1.786-1.79 3.402-2.277z" />
        <path d="M253.532 232.599c1.975.187 1.162-.056 2.497.497a4.358 4.358 0 011.779 5.899 108.643 108.643 0 01-3.021 5.259 4.35 4.35 0 01-3.722 2.088 4.359 4.359 0 01-3.718-6.625 97.835 97.835 0 002.783-4.841c.891-1.577 1.786-1.79 3.402-2.277zM234.952 255.045l.933.003 1.096.218c.376.118 1.375.386 1.709.51.548.205.967.437 1.418.741.095.064.496.345.631.437l.218.149.201.17c4.079 3.436 4.6 9.528 1.164 13.609a115.472 115.472 0 01-4.23 4.737 9.645 9.645 0 01-9.026 2.832 9.61 9.61 0 01-4.627-2.418c-3.881-3.654-4.066-9.763-.409-13.653a92.405 92.405 0 003.521-3.94l.077-.09a9.98 9.98 0 011.956-1.737c1.589-1.061 2.832-1.351 4.965-1.534l.403-.034z" />
        <path d="M235.403 260.326c1.894.59 1.149.184 2.341 1.001a4.358 4.358 0 01.526 6.139 109.593 109.593 0 01-4.04 4.523 4.347 4.347 0 01-4.073 1.276 4.36 4.36 0 01-2.272-7.249 97.38 97.38 0 003.72-4.163c1.198-1.36 2.117-1.383 3.798-1.527z" />
        <path d="M235.403 260.326c1.894.59 1.149.184 2.341 1.001a4.358 4.358 0 01.526 6.139 109.593 109.593 0 01-4.04 4.523 4.347 4.347 0 01-4.073 1.276 4.36 4.36 0 01-2.272-7.249 97.38 97.38 0 003.72-4.163c1.198-1.36 2.117-1.383 3.798-1.527zM102.601 222.612l2.523.361c3.061.437 5.347 2.042 7.046 4.572l.15.222.126.237a94.313 94.313 0 002.628 4.59c2.769 4.559 1.321 10.496-3.243 13.272a9.66 9.66 0 01-13.263-3.241 113.463 113.463 0 01-3.157-5.52c-2.514-4.706-.738-10.557 3.968-13.069l.234-.125.245-.101c.152-.062.603-.253.709-.296.994-.346.485-.204 1.528-.426l.506-.476z" />
        <path d="M104.374 228.219c1.559.223 2.552 1.023 3.397 2.281a99.205 99.205 0 002.773 4.846 4.357 4.357 0 11-7.446 4.528 108.009 108.009 0 01-3.012-5.266 4.356 4.356 0 011.788-5.897c1.338-.55.524-.308 2.5-.492z" />
        <path d="M104.374 228.219c1.559.223 2.552 1.023 3.397 2.281a99.205 99.205 0 002.773 4.846 4.357 4.357 0 11-7.446 4.528 108.009 108.009 0 01-3.012-5.266 4.356 4.356 0 011.788-5.897c1.338-.55.524-.308 2.5-.492zM264.162 196.268l1.942.081.266.038c5.281.76 8.945 5.66 8.182 10.933a253.927 253.927 0 01-.931 5.988 9.655 9.655 0 01-11.121 7.919c-5.26-.881-8.807-5.861-7.925-11.131.286-1.689.574-3.538.863-5.532l.036-.248.059-.242c1.103-4.547 3.389-6.437 7.861-7.606l.768-.2z" />
        <path d="M264.734 201.596l.881.037a4.354 4.354 0 013.69 4.934 241.353 241.353 0 01-.91 5.86 4.356 4.356 0 01-5.02 3.573 4.357 4.357 0 01-3.575-5.018c.302-1.782.597-3.684.883-5.659.644-2.652 1.64-3.097 4.051-3.727z" />
        <path d="M264.734 201.596l.881.037a4.354 4.354 0 013.69 4.934 241.353 241.353 0 01-.91 5.86 4.356 4.356 0 01-5.02 3.573 4.357 4.357 0 01-3.575-5.018c.302-1.782.597-3.684.883-5.659.644-2.652 1.64-3.097 4.051-3.727zM94.062 193.118l.484.07c4.343.625 7.393 3.48 8.439 7.705l.064.258.037.263c.275 1.917.564 3.781.857 5.535.881 5.26-2.668 10.239-7.923 11.118a9.657 9.657 0 01-11.123-7.928 249.709 249.709 0 01-.927-5.983c-.76-5.279 2.907-10.173 8.186-10.933l.268-.038 1.638-.067z" />
        <path d="M93.791 198.433c2.209.318 3.523 1.607 4.049 3.733.282 1.964.577 3.868.876 5.659a4.356 4.356 0 11-8.592 1.439 245.142 245.142 0 01-.908-5.863 4.357 4.357 0 013.695-4.932l.88-.036z" />
        <path d="M93.791 198.433c2.209.318 3.523 1.607 4.049 3.733.282 1.964.577 3.868.876 5.659a4.356 4.356 0 11-8.592 1.439 245.142 245.142 0 01-.908-5.863 4.357 4.357 0 013.695-4.932l.88-.036zM96.672 75.936l1.89.136c.311.023 1.266.051 1.633.099a6.197 6.197 0 011.575.42c.103.041.51.212.64.265l.258.105.245.131c4.433 2.374 6.155 7.916 4.009 12.468-8.722 18.481-11.689 44.35-8.875 78.979l.012.199.402 9.229-.315.935c-1.139 3.383-4.071 5.893-7.66 6.286a8.83 8.83 0 01-.997.055c-3.965 0-7.405-2.605-8.66-6.347l-.187-.557-.91-8.827-.011-.125c-2.974-37.534.478-66.383 10.703-88.053l.051-.106c1.206-2.416 2.806-3.713 5.162-4.589l.083-.03.952-.673z" />
        <path d="M98.182 81.358c1.758.127 1.03-.082 2.229.406 1.908 1.022 2.678 3.499 1.718 5.534-9.177 19.446-12.248 46.19-9.364 81.67l.358 8.243c-.485 1.443-1.704 2.544-3.234 2.711a3.575 3.575 0 01-.4.022c-1.647 0-3.093-1.116-3.635-2.733l-.85-8.243c-2.908-36.697.441-64.663 10.213-85.373.754-1.512 1.544-1.735 2.965-2.237z" />
        <path d="M98.182 81.358c1.758.127 1.03-.082 2.229.406 1.908 1.022 2.678 3.499 1.718 5.534-9.177 19.446-12.248 46.19-9.364 81.67l.358 8.243c-.485 1.443-1.704 2.544-3.234 2.711a3.575 3.575 0 01-.4.022c-1.647 0-3.093-1.116-3.635-2.733l-.85-8.243c-2.908-36.697.441-64.663 10.213-85.373.754-1.512 1.544-1.735 2.965-2.237zM271.667 139.463l.019.001v.001c5.453.164 9.313 4.472 9.507 9.784-.094 6.983-.479 14.481-1.142 22.361l-.013.136-1.033 9.376-.233.622c-1.585 4.22-4.86 6.326-9.054 6.326a9.648 9.648 0 01-9.083-6.393l-.357-.991.461-9.872.008-.105c.663-7.664 1.043-14.954 1.134-21.725.163-5.413 4.416-9.339 9.725-9.518l.061-.003z" />
        <path d="M271.597 144.763a4.354 4.354 0 014.297 4.414c-.094 6.896-.475 14.276-1.124 21.989l-.96 8.715c-.845 2.252-2.238 2.889-4.092 2.889a4.35 4.35 0 01-4.097-2.889l.406-8.715c.672-7.776 1.06-15.196 1.153-22.107a4.336 4.336 0 014.417-4.296z" />
        <path d="M271.597 144.763a4.354 4.354 0 014.297 4.414c-.094 6.896-.475 14.276-1.124 21.989l-.96 8.715c-.845 2.252-2.238 2.889-4.092 2.889a4.35 4.35 0 01-4.097-2.889l.406-8.715c.672-7.776 1.06-15.196 1.153-22.107a4.336 4.336 0 014.417-4.296zM183.306 28.045c60.35 0 93.806 31.397 97.867 91.822.352 5.193-3.702 9.555-8.843 9.867a8.016 8.016 0 01-.635.021c-4.936.001-9.139-3.724-9.473-8.668-3.405-50.679-28.646-74.49-78.916-74.49-27.653 0-47.962 7.488-60.715 22.05-3.346 3.815-9.181 4.331-13.174 1.192-4.157-3.272-4.746-9.271-1.258-13.255 18.29-20.897 46.32-28.278 73.207-28.521l.021-.018h1.919z" />
        <path d="M183.306 33.345c57.557 0 88.705 29.231 92.579 86.881.149 2.192-1.609 4.081-3.922 4.222a4.007 4.007 0 01-.269.008c-2.197 0-4.042-1.618-4.184-3.725-3.592-53.451-31.135-79.434-84.204-79.434-29.067 0-50.837 8.027-64.7 23.856-1.482 1.69-4.127 1.924-5.911.522-1.782-1.403-2.029-3.909-.549-5.6 15.535-17.738 39.478-26.73 71.16-26.73z" />
        <path d="M183.306 33.345c57.557 0 88.705 29.231 92.579 86.881.149 2.192-1.609 4.081-3.922 4.222a4.007 4.007 0 01-.269.008c-2.197 0-4.042-1.618-4.184-3.725-3.592-53.451-31.135-79.434-84.204-79.434-29.067 0-50.837 8.027-64.7 23.856-1.482 1.69-4.127 1.924-5.911.522-1.782-1.403-2.029-3.909-.549-5.6 15.535-17.738 39.478-26.73 71.16-26.73z" />
      </g>
    </svg>
  )
}

export default FacePH;

