import { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, CrossIcon, IconButton, Spinner } from 'evergreen-ui';

import { I18n } from 'aws-amplify';

interface Props {
  headerText: string,
  hideLeftButton: any,
  hideRightButton: any,
  leftButtonDisabled: any,
  rightButtonDisabled: any,
  leftButtonIcon: any,
  onLeftButtonClick: any,
  onRightButtonClick: any,
  isProcessing: any,
  rightButtonText: any,
}

const DialogHeader = ({
  headerText = "",
  hideLeftButton = false,
  hideRightButton = false,
  leftButtonDisabled = false,
  rightButtonDisabled = false,
  leftButtonIcon = CrossIcon,
  onLeftButtonClick,
  onRightButtonClick,
  isProcessing = false,
  rightButtonText = I18n.get('Next'),
  ...rest
}: Props) => {

  

  return (
    <Pane alignItems="center" minHeight={40} display="flex" flexWrap="wrap" {...rest} >
      <Pane width={70} textAlign="left" flex="none" className="noselect" >
        {hideLeftButton ? null :
          <IconButton icon={leftButtonIcon || CrossIcon} iconSize={18}
          disabled={leftButtonDisabled}
          onClick={() => {
            if (typeof onLeftButtonClick === "function") {
              onLeftButtonClick();
            }
          }}
        />}
      </Pane>
      <Pane textAlign="center" flex={1} width="100%" height="auto" className="noselect" >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{headerText}</Strong>
      </Pane>
      <Pane width={70} textAlign="right" flex="none" className="noselect" >
        {(hideRightButton || isProcessing) ? null :
          <Button fontSize={16} paddingX={12} height={32} minWidth={0} justifyContent="center"
            disabled={rightButtonDisabled}
            onClick={() => {
              if (typeof onRightButtonClick === "function") {
                onRightButtonClick();
              }
            }}
          >
            {rightButtonText}
          </Button>
        }
        {!isProcessing ? null :
          <Spinner marginLeft="auto" marginRight="auto" flex="none" size={32} />
        }
      </Pane>
    </Pane>
  );
}

export default DialogHeader;