
export const setProfileState = (payload) => {
	return {
		type: 'SET_PROFILE_STATE',
		payload: payload,
	}
};

export const updateProfileState = (payload) => {
	return {
		type: 'UPDATE_PROFILE_STATE',
		payload: payload,
	}
};

export const setProfileDetails = (payload) => {
	return {
		type: 'SET_PROFILE_DETAILS',
		payload: payload,
	}
};

export const updateProfileDetails = (payload) => {
	return {
		type: 'UPDATE_PROFILE_DETAILS',
		payload: payload,
	}
};

export const setTransactionDetails = (payload) => {
	return {
		type: 'SET_TRANSACTION_DETAILS',
		payload: payload,
	}
};

export const updateTransactionDetails = (payload) => {
	return {
		type: 'UPDATE_TRANSACTION_DETAILS',
		payload: payload,
	}
};

export const setLocaleState = (payload) => {
	return {
		type: 'SET_LOCALE_STATE',
		payload: payload,
	}
};

export const updateLocaleState = (payload) => {
	return {
		type: 'UPDATE_LOCALE_STATE',
		payload: payload,
	}
};


export const setUserState = (payload) => {
	return {
		type: 'SET_USER_STATE',
		payload: payload,
	}
};

export const updateUserState = (payload) => {
	return {
		type: 'UPDATE_USER_STATE',
		payload: payload,
	}
};



export const setBasicStats = (payload) => {
	return {
		type: 'SET_BASIC_STATS',
		payload: payload,
	}
};

export const setDialogState = (payload) => {
	return {
		type: 'SET_DIALOG_STATE',
		payload: payload,
	}
};

export const updateDialogState = (payload) => {
	return {
		type: 'UPDATE_DIALOG_STATE',
		payload: payload,
	}
};

export const setUploadState = (payload) => {
	return {
		type: 'SET_UPLOAD_STATE',
		payload: payload,
	}
};

export const updateUploadState = (payload) => {
	return {
		type: 'UPDATE_UPLOAD_STATE',
		payload: payload,
	}
};
