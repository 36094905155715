import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, Checkbox, Table, SmallCrossIcon, SidebarTab, Radio, RadioGroup, Spinner } from 'evergreen-ui';
import _, { includes, sortedLastIndex } from 'lodash';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import { NumericFormat } from 'react-number-format';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { I18n } from 'aws-amplify';

const FeedbackImpactForm = ({
  feedbackRecord,
  disabled = false,
  leadingHeading = I18n.get('Meaningfully impacting'),
  onChange = () => {},
  onValidation = (isValid) => {}
}) => {

  const handleCheckValidity = (boolean) => {
    if (boolean) {
      return true;
    }
    else {
      return false;
    }
  }

  const isValidRef = useRef(null);

  useEffect(() => {

    // needs improvements ???

    let oneInvalidField = feedbackRecord?.impact?.filter(breakdown => breakdown.action === "impact")?.find((breakdown) => ( (!breakdown.quantity && breakdown.durationDays) || (breakdown.quantity && !breakdown.durationDays) ));

    let oneFilledField = feedbackRecord?.impact?.find((breakdown) => (breakdown.quantity || breakdown.durationDays || breakdown.references?.length > 0));
    // console.log("oneFilledField", oneFilledField);

    let newValidState = null;

    if (oneInvalidField) {
      newValidState = false;
    }
    else if (!oneFilledField) {
      newValidState = null;
    }
    else {
      newValidState = true;
    }

    if (isValidRef.current !== newValidState) {
      isValidRef.current = newValidState;
      onValidation(newValidState);
    }
    

  }, [feedbackRecord?.impact, onValidation]);

  return (
    <Fragment>

      {false ? null :
        <Fragment>

          <Pane marginTop={10} marginBottom={4} >
            <Strong fontSize={14} color="#425A70" >{leadingHeading}</Strong>
          </Pane>

          <Pane >
            {feedbackRecord?.impact?.filter(breakdown => breakdown.action === "impact")?.map((breakdown, breakdownLocalIndex) => {

              return (
                <Pane key={breakdown.orderIndex}>
                  {breakdownLocalIndex === 0 ? null :
                    <Pane marginTop={4} marginBottom={4} >
                      <Text fontSize={14} color="#425A70" >{I18n.get("and")}</Text>
                    </Pane>
                  }
                  <Pane  maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" >

                    <Pane
                      paddingX={4}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                      display={"flex"}
                      alignItems="center"
                    >
                      {/* <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{"~"}</Strong> */}
                    </Pane>
                    
                    <NumericFormat
                      // getInputRef={(el) => {allocationAmountInputRef.current = el}}
                      customInput={TextInput}
                      min="0" step="1"
                      format={"#############"}
                      thousandSeparator={true}
                      // id={"allocation-amount"}
                      type={"text"}
                      pattern={"[0-9]*"} // to bring up the numeric keypad
                      inputMode="numeric" // to bring up the numeric keypad
                      // autoComplete="off" // doesn't seem to work
                      
                      // autoComplete={"allocation-amount"}
                      // name={"allocation-amount"}
                      disabled={disabled} 
                      isInvalid={handleCheckValidity(!breakdown.quantity && breakdown.durationDays)}
                      marginTop={0}
                      marginBottom={0}
                      width={70}
                      height={32}
                      fontSize={14}
                      color={"#283655"}
                      placeholder={"0"}
                      paddingY={0}
                      marginLeft={-1}
                      marginRight={0}
                      
                      borderRadius={0}
                      // style={{
                      //   boxShadow: "none",
                      //   border: "solid",
                      //   borderWidth: "1px",
                      //   borderColor: "#C7CED4",
                      //   borderRight: "0px",
                      // }}
                      
                      // border={"solid"}
                      // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}

                      value={breakdown.quantity || ""}
                      defaultValue={null}
                      onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                        let temp = values.value;
                        let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                        onChange(breakdown, {
                          quantityDurationDays: Math.round((newAmount * breakdown.durationDays  + Number.EPSILON) * 100) / 100,
                          quantity: newAmount
                        });
                      }}
                      // onBlur={(e) => {}}
                    />
                    

                    <Pane
                      minWidth={75}
                      paddingX={8}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={0} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={0}
                      display={"flex"}
                      alignItems="center"
                      borderLeftStyle={"none"}
                    >
                      <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{I18n.get(`${breakdown.unit}_plural`)}</Strong>
                    </Pane>


                    <Pane
                      paddingX={8}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={0} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={0}
                      display={"flex"}
                      alignItems="center"
                      marginLeft={-1}
                    >
                      <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{I18n.get('for')}</Strong>
                    </Pane>

                    <NumericFormat
                      // getInputRef={(el) => {allocationAmountInputRef.current = el}}
                      customInput={TextInput}
                      min="0" step="1"
                      format={"#############"}
                      thousandSeparator={true}
                      // id={"allocation-amount"}
                      type={"text"}
                      pattern={"[0-9]*"} // to bring up the numeric keypad
                      inputMode="numeric" // to bring up the numeric keypad
                      // autoComplete="off" // doesn't seem to work
                      
                      // autoComplete={"allocation-amount"}
                      // name={"allocation-amount"}
                      disabled={disabled} 
                      isInvalid={handleCheckValidity(breakdown.quantity && !breakdown.durationDays)}
                      marginTop={0}
                      marginBottom={0}
                      width={50}
                      height={32}
                      fontSize={14}
                      color={"#283655"}
                      placeholder={"0"}

                      paddingY={0}
                      marginLeft={-1}
                      marginRight={0}
                      
                      borderRadius={0}
                      // style={{
                      //   boxShadow: "none",
                      //   border: "solid",
                      //   borderWidth: "1px",
                      //   borderColor: "#C7CED4",
                      //   borderRight: "0px",
                      // }}
                      
                      // border={"solid"}
                      // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}

                      value={breakdown.durationDays || ""}
                      defaultValue={null}
                      onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                        let temp = values.value;
                        let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                        onChange(breakdown, {
                          quantityDurationDays: Math.round((breakdown.quantity * newAmount + Number.EPSILON) * 100) / 100, // this is done at the backend, remove ???
                          durationDays: newAmount,
                        });
                      }}
                      // onBlur={(e) => {}}
                    />

                    <Pane
                      paddingX={8}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                      display={"flex"}
                      alignItems="center"
                      borderLeftStyle={"none"}
                    >
                      <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{I18n.get('days')}</Strong>
                    </Pane>

                    

                  </Pane>

                  {/* {!breakdown.quantityDurationDays ? null :
                    <Pane marginTop={2} marginLeft={2}  >
                      <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{`${breakdown.quantityDurationDays} ${I18n.get(breakdown.unit)}/${I18n.get("days")}`}</Text>
                    </Pane>
                  } */}


                  

                </Pane>
              );
            })}
          </Pane>

        </Fragment>
      }

      {false ? null :
        <Fragment>

          <Pane marginTop={12} marginBottom={4} >
            <Strong fontSize={14} color="#425A70" >{I18n.get('By providing')}</Strong>
          </Pane>

          <Pane >
            {feedbackRecord?.impact?.filter(breakdown => breakdown.action === "provide")?.map((breakdown, breakdownLocalIndex) => {
              return (
                <Pane key={breakdown.orderIndex}>
                  {breakdownLocalIndex === 0 ? null :
                    <Pane marginTop={4} marginBottom={4} >
                      <Text fontSize={14} color="#425A70" >{I18n.get("and")}</Text>
                    </Pane>
                  }
                  <Pane  maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" >

                    <Pane
                      paddingX={4}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                      display={"flex"}
                      alignItems="center"
                    >
                      {/* <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{"~"}</Strong> */}
                    </Pane>
                    
                    <NumericFormat
                      // getInputRef={(el) => {allocationAmountInputRef.current = el}}
                      customInput={TextInput}
                      min="0" step="1"
                      format={"#############"}
                      thousandSeparator={true}
                      // id={"allocation-amount"}
                      type={"text"}
                      pattern={"[0-9]*"} // to bring up the numeric keypad
                      inputMode="numeric" // to bring up the numeric keypad
                      // autoComplete="off" // doesn't seem to work
                      
                      // autoComplete={"allocation-amount"}
                      // name={"allocation-amount"}
                      disabled={disabled} 
                      // isInvalid={localIsInvalid}
                      marginTop={0}
                      marginBottom={0}
                      width={70}
                      height={32}
                      fontSize={14}
                      color={"#283655"}
                      placeholder={"0"}

                      paddingY={0}
                      marginLeft={-1}
                      marginRight={-1}
                      
                      borderRadius={0}
                      // style={{
                      //   boxShadow: "none",
                      //   border: "solid",
                      //   borderWidth: "1px",
                      //   borderColor: "#C7CED4",
                      //   borderRight: "0px",
                      // }}
                      
                      // border={"solid"}
                      // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}

                      value={breakdown.quantity || ""}
                      defaultValue={null}
                      onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                        let temp = values.value;
                        let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                        onChange(breakdown, {
                          quantity: newAmount
                        });
                      }}
                      // onBlur={(e) => {}}
                    />
                    
                    <Pane
                      paddingX={8}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                      display={"flex"}
                      alignItems="center"
                    >
                      <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{I18n.get(`${breakdown.unit}_plural`)}</Strong>
                    </Pane>

                  </Pane>
                </Pane>
              );
            })}
          </Pane>

        </Fragment>
      }

      {false ? null :
        <Fragment>

          <Pane marginTop={12} marginBottom={4} >
            <Strong fontSize={14} color="#425A70" >{I18n.get('While saving')}</Strong>
          </Pane>

          <Pane >
            {feedbackRecord?.impact?.filter(breakdown => breakdown.action === "save")?.map((breakdown, breakdownLocalIndex) => {
              return (
                <Pane key={breakdown.orderIndex}>
                  {breakdownLocalIndex === 0 ? null :
                    <Pane marginTop={4} marginBottom={4} >
                      <Text fontSize={14} color="#425A70" >{I18n.get("and")}</Text>
                    </Pane>
                  }
                  <Pane  maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" >

                    <Pane
                      paddingX={4}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                      display={"flex"}
                      alignItems="center"
                    >
                      {/* <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{"~"}</Strong> */}
                    </Pane>
                    
                    <NumericFormat
                      // getInputRef={(el) => {allocationAmountInputRef.current = el}}
                      customInput={TextInput}
                      min="0" step="0.001"
                      // format={"#############"}
                      thousandSeparator={true}
                      // id={"allocation-amount"}
                      type={"text"}
                      pattern={"[0-9]*"} // to bring up the numeric keypad
                      inputMode="numeric" // to bring up the numeric keypad
                      // autoComplete="off" // doesn't seem to work
                      
                      // autoComplete={"allocation-amount"}
                      // name={"allocation-amount"}
                      disabled={disabled} 
                      // isInvalid={localIsInvalid}
                      marginTop={0}
                      marginBottom={0}
                      width={70}
                      height={32}
                      fontSize={14}
                      color={"#283655"}
                      placeholder={`0.000`}

                      paddingY={0}
                      marginLeft={-1}
                      marginRight={-1}
                      
                      borderRadius={0}
                      // style={{
                      //   boxShadow: "none",
                      //   border: "solid",
                      //   borderWidth: "1px",
                      //   borderColor: "#C7CED4",
                      //   borderRight: "0px",
                      // }}
                      
                      // border={"solid"}
                      // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}
                      allowNegative={false}
                      value={breakdown.quantity ?? ""}
                      defaultValue={null}
                      onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                        // console.log("values.value", values.value);
                        let newAmount = values.value ? Math.round((Number(values.value) + Number.EPSILON) * 1000) / 1000 : null;
                        // console.log("newAmount", newAmount);
                        onChange(breakdown, {
                          quantity: newAmount
                        });
                      }}
                      // onBlur={(e) => {}}
                    />
                    
                    <Pane
                      paddingX={8}
                      height={32}
                      border
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="#C7CED4"
                      justifyContent="center"
                      background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                      borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                      display={"flex"}
                      alignItems="center"
                    >
                      <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{I18n.get(`${breakdown.unit}_plural`)}</Strong>
                    </Pane>

                  </Pane>
                </Pane>
              );
            })}
          </Pane>

        </Fragment>
      }

      
      {false ? null :
        <Fragment>

          <Pane marginTop={20} marginBottom={8} >
            <Strong fontSize={14} color="#425A70" >{I18n.get('Overall, contributing to UN SDGs')}</Strong>
          </Pane>

          <Pane marginTop={10} >
            {feedbackRecord?.impact?.filter(breakdown => breakdown.action === "contribute")?.map((breakdown, breakdownLocalIndex) => {
              return (
                <Pane key={breakdown.orderIndex}>
                  {breakdownLocalIndex === 0 ? null :
                    <Pane marginTop={4} marginBottom={4} >
                      <Text fontSize={14} color="#425A70" >{I18n.get("and")}</Text>
                    </Pane>
                  }
                  <Pane  maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" >

                    <Pane display="flex" flexWrap="wrap" gap={10} >
                      {[...Array(17).keys()].map((i) => {
                        return (
                          <Button
                            key={i} width={32} height={32} padding={0}
                            disabled={disabled}
                            justifyContent="center"
                            className="noselect"
                            borderRadius={5}
                            isActive={breakdown.references?.includes(`${i + 1}`)}
                            style={{
                              boxShadow: "none",
                              border: "solid",
                              borderWidth: "1px",
                              borderColor: "#C7CED4",
                            }}
                            onClick={() => {

                              let newReferences = breakdown.references || [];

                              if (breakdown.references?.includes(`${i + 1}`)) {
                                newReferences = _.remove(breakdown.references, (n) => {
                                  return n !== `${i + 1}`;
                                })
                              }
                              else {
                                newReferences.push(`${i + 1}`)
                              }
                              
                              onChange(breakdown, {
                                references: newReferences,
                              });

                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    </Pane>
                    
                  </Pane>
                </Pane>
              );
            })}
          </Pane>
          

          <Pane marginTop={8} >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >
              {I18n.get("Select related")}
              {" "}
            </Text>

            <Strong fontSize={12} fontStyle="italic" className="blue-link"
              is={Link}
              target="_blank"
              to={{
                pathname: generatePath("/:page/:goalNumber?", {
                  page: "goals",
                  goalNumber: null,
                }),
                // state: {},
              }}
            >
              {I18n.get('UN SDGs')}
            </Strong>

            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >
              {"."}
            </Text>
              
          </Pane>

        </Fragment>
      }

    </Fragment>
  );
}

const newFeedbackImpactBreakdown = () => {
  let impact = [
    { 
      orderIndex: 1,
      action: "impact",
      effect: "life",
      unit: "adult",
      durationDays: null,
    },
    { 
      orderIndex: 2,
      action: "impact",
      effect: "life",
      unit: "child",
      durationDays: null,
    },
    { 
      orderIndex: 3,
      action: "impact",
      effect: "life",
      unit: "animal",
      durationDays: null,
    },
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: null,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: null,
    },
    // { 
    //   orderIndex: 4,
    //   action: "provide",
    //   effect: "thing",
    //   unit: "shopping trip",
    //   quantity: null,
    // },
    { 
      orderIndex: 1,
      action: "save",
      effect: "environment",
      unit: "kg of CO2",
      quantity: null,
    },
    { 
      orderIndex: 1,
      action: "contribute",
      effect: "standard",
      unit: "unsdgs",
      references: [],
    },
  ];

  return impact;
}

export default FeedbackImpactForm;
export { newFeedbackImpactBreakdown };