import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';

import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const useAccessTransaction = () => {

  const user = useSelector(state => state.userState.user);

  const handleAccessTransaction = useCallback(async(params = {}) => {

    try {
      const response = await API.graphql({
        query: mutations.accessTransaction,
        variables: {
          publicTransactionId: params.publicTransactionId,
          accessCode: params.accessCode,
          invalidate: params.invalidate,
          faceB46: params.faceB46,
        },
        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      const accessedTransaction = response.data.accessTransaction;
      console.log("accessedTransaction", accessedTransaction);

      return {
        data: accessedTransaction, // same is pushed in subscriptions ! 
        error: null,
      }
    }
    catch (error) {
      console.error(`within handleAccessTransaction:`, error);
      return {
        data: null,
        error: error,
      }
    }

  }, [user]);

  return handleAccessTransaction;
}

export default useAccessTransaction;