import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Table, Button, Pane, Icon, CircleArrowLeftIcon, CircleArrowRightIcon } from 'evergreen-ui';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { I18n } from 'aws-amplify';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import FaceAvatar from './FaceAvatar';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, updateProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';


const TransactionHistoryCarousel = ({ transactionDetails }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionHistoryCarousel - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("TransactionHistoryCarousel - cleaned up");
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	const profileDetails = useSelector(state => state.profileDetails);
	const localeState = useSelector(state => state.localeState);



  const handleDragStart = (e) => e.preventDefault();
  const carouselResponsiveParams = {
    0: { items: 1.1 },
    320: { items: 1.35 },
    425: { items: 2.15 },
  };

  const carouselRef = useRef(null);

  const fundedTransactions = useMemo(() => {

    const transactionDetailsFundedHistory = transactionDetails?.history?.filter(transaction => transaction.role === "fundedTransaction") || [];

		if (transactionDetailsFundedHistory.length > 0) {
			return transactionDetailsFundedHistory;
		}
		else {
			return null;
		}

  }, [transactionDetails?.history]);

  const anonymiseRecipient = transactionDetails?.history?.find(transaction => transaction.type === "anonymiseRecipient") || null;

  const carouselItems = fundedTransactions?.map((fundedTransaction) => (

    <Pane padding={4} width={"auto"} maxWidth={260}
      // className="thumb"
      className="item"
      key={fundedTransaction.publicTransactionId}
    >
      <Button
        display="block"
        width="100%"
        height="auto"
        textAlign="inherit"
        padding={0}
        appearance="minimal"
        onClick={() => {
          let newUrl = `https://facedonate.org${generatePath("/:page?/:publicTransactionId", {...match.params, page: "t", publicTransactionId: fundedTransaction.publicTransactionId})}`;
          window.open(newUrl, '_blank', 'noopener,noreferrer');
        }}
        className="noselect"
      >

        <Pane 
          // minWidth={210}
          borderWidth={1} borderRadius={5} borderStyle="solid" borderColor="#435A6F20" >

          <Pane paddingTop={6} paddingX={6} >
            
            <FaceAvatar
              height={24}
              fontSize={12}
              faceDetails={fundedTransaction.fromFace}
            />
          </Pane>

          <Pane paddingBottom={4} paddingTop={4} paddingX={6} >

            <FormattedCurrencyNumber removerDecimalZeros={true} number={fundedTransaction.amount} currency={fundedTransaction.currency} fontSize={16} />

            <Text fontSize={12} color="#7B8B9A" >
              {" "}{fundedTransaction.type}
            </Text>

            {/* {" • "} */}
            
          </Pane>

          {/* top cap */}
          <Pane display="flex" alignItems="stretch" >
            <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
              borderTopLeftRadius={"50%"}
              borderTopRightRadius={"50%"}
            />
          </Pane>


          <Pane display="flex" flexWrap="wrap" alignItems={"center"} >
            <Pane>
              <Pane marginLeft={14} height={22} width={20}
                borderBottomLeftRadius={"50%"}
                borderLeftWidth={3}  borderLeftStyle="solid"
                borderBottomWidth={3} borderBottomStyle="solid" borderColor="#E6E8F0"
              />
              <Pane height={17} width={20} />
            </Pane>

            <Pane>
              <Pane height={19} width={3} marginRight={4} />
              <Pane height={3} width={3} marginRight={4}
                background="#E6E8F0"
                borderTopRightRadius={"50%"}
                borderBottomRightRadius={"50%"}
              />
              <Pane height={17} width={3} marginRight={4} />
            </Pane>

            <Pane marginLeft={4} >
              <FaceAvatar
                height={24}
                fontSize={12}
                anonymisedFaceDetails={anonymiseRecipient}
                faceDetails={fundedTransaction.toFace}
              />
            </Pane>

          </Pane>



        </Pane>
      </Button>
    </Pane>
  ));

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isPrevSlideDisabled, setIsPrevSlideDisabled] = useState(true);
  const [isNextSlideDisabled, setIsNextSlideDisabled] = useState(true);

  return (
    <Fragment>

      <Pane position="relative" maxWidth={590} marginLeft={"auto"} marginRight={"auto"} >

        <Pane marginLeft={27} marginRight={30} >
          <AliceCarousel
            ref={(el) => (carouselRef.current = el)}
            activeIndex={activeSlideIndex}
            items={carouselItems}
            responsive={carouselResponsiveParams}
            // autoWidth
            disableButtonsControls={true}
            disableDotsControls={true}
            // autoPlay={true}
            // autoPlayInterval={1800}
            // infinite={false}
            mouseTracking
            // paddingLeft={0}
            // paddingRight={0}
            
            onInitialized={(event) => {
              console.log("onInitialized event:", event);
              setIsPrevSlideDisabled(event.isPrevSlideDisabled);
              setIsNextSlideDisabled(event.isNextSlideDisabled);
            }}

            onSlideChange={(event) => {
              // console.log("onSlideChange event:", event);
              
            }}
            onSlideChanged={(event) => {
              console.log("onSlideChanged event:", event);
              setIsPrevSlideDisabled(event.isPrevSlideDisabled);
              setIsNextSlideDisabled(event.isNextSlideDisabled);
            }}
            // onResizeEvent={(event) => {
            //   console.log("onResizeEvent event:", event)
            // }}
            // onResized={(event) => {
            //   console.log("onResized event:", event)
            // }}
            
            // touchTracking={!thumbAnimation}
          />
        </Pane>

        {false ? null :
          <Pane position="absolute" top={0} left={0} width={"auto"} height={"100%"} alignItems="center" display="flex" >
            <Button
              disabled={isPrevSlideDisabled}
              height={"auto"}
              padding={3}
              borderRadius={"50%"}
              // appearance="minimal"
              onClick={() => {
                carouselRef.current.slidePrev();
                setActiveSlideIndex((current) => (current - 1));
              }}
            >
              <Icon icon={CircleArrowLeftIcon} size={18} color="#425A70" />
            </Button>
          </Pane>
        }

        <Pane position="absolute" top={0} right={0} width={"auto"} height={"100%"} alignItems="center" display="flex" >
          <Button
            disabled={isNextSlideDisabled}
            height={"auto"}
            padding={3}
            borderRadius={"50%"}
            // appearance="minimal"
            onClick={() => {
              carouselRef.current.slideNext();
              setActiveSlideIndex((current) => (current + 1));
            }}
          >
            <Icon icon={CircleArrowRightIcon} size={18} color="#425A70" />
          </Button>
        </Pane>

      </Pane>
          
    </Fragment>
  );


}


const TransactionUseStructure = ({ transactionDetails, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionUseStructure - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("TransactionUseStructure - cleaned up");
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	const profileDetails = useSelector(state => state.profileDetails);
	const localeState = useSelector(state => state.localeState);

  const fundingTransactions = useMemo(() => {

    const transactionDetailsFundingHistory = transactionDetails?.history?.filter(transaction => transaction.role === "fundingTransaction") || [];

		if (transactionDetailsFundingHistory.length > 0) {
			return transactionDetailsFundingHistory;
		}
		else {
			return null;
		}

  }, [transactionDetails?.history]);

  const fundedTransactions = useMemo(() => {

    const transactionDetailsFundedHistory = transactionDetails?.history?.filter(transaction => transaction.role === "fundedTransaction") || [];

		if (transactionDetailsFundedHistory.length > 0) {
			return transactionDetailsFundedHistory;
		}
		else {
			return null;
		}

  }, [transactionDetails?.history]);

  const anonymiseRecipient = transactionDetails?.history?.find(transaction => transaction.type === "anonymiseRecipient") || null;

  return (
    <Fragment>

      {!fundedTransactions ? null :
        <Pane {...rest}>

          <Pane display="flex" alignItems={"end"} >
          
            <Strong fontSize={14} color="#283655">{I18n.get('Use of funds')}</Strong>

            {/* <Button marginLeft={6} paddingX={6} paddingY={4} height={18} fontSize={12} justifyContent="center" appearance="minimal"
              // disabled={processing}
              onClick={() => {
                // dispatch(updateTransactionDetails({}));
              }}
            >
              {I18n.get('Hide')}
            </Button> */}

          </Pane>

          <TransactionHistoryCarousel transactionDetails={transactionDetails} />

          {/* <Text fontSize={14} color="#283655">{I18n.get('Table here ...')}</Text> */}


        </Pane>
      }

    </Fragment>

  );

}

export default TransactionUseStructure;