import { useEffect, useState, useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch, batch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary'
import {Portal, Heading, Button, Pane} from 'evergreen-ui';

import { useCookies } from 'react-cookie';

// https://www.npmjs.com/package/react-error-boundary

const ErrorFallback = ({ error, resetErrorBoundary, ...rest }) => {
  
  const [hide, setHide] = useState(false);

  return(
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default ErrorFallback;