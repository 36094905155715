const PersonIcon = ({size="inherit", style={}, color, width="1em", height="1em", opacity=1, ...rest}) => {

  return (

    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fontSize={size}
      width={width}
      height={height}
      opacity={opacity}
      style={style}
      {...rest}
    >
    
      <path
        d="M9.343 9.943c2.763 0 5-2.237 5-5 0-2.762-2.237-5-5-5a5 5 0 00-5 5c0 2.763 2.238 5 5 5zM9.343 11.371c1.953 0 5.13.608 7.215 1.818.851.867 1.356 1.812 1.356 2.754v4H.057v-4c0-3.04 6.187-4.572 9.286-4.572z"
        fillRule="nonzero"
        fill={color || "#000000"}
      />

    </svg>

  );
}

export default PersonIcon;