import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';

import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Tooltip } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PickCurrency from './PickCurrency';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails, setDialogState, updateDialogState } from '../services/actions';

import styled, { StyledFunction } from "styled-components";

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import CountUp, { useCountUp } from 'react-countup';

import useProvideToFace from "../services/useProvideToFace";

import { I18n } from 'aws-amplify';


const ProvisionFundingOptions = ({ disabled, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`SelectProvisionFundingOption - ${countRef.current}`);
    return () => {
      console.log("SelectProvisionFundingOption - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  const dialogState = useSelector(state => state.dialogState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  const [readyToComplete, setReadyToComplete] = useState(false);
  const [cancelToComplete, setCancelToComplete] = useState(false);

  const timeUntilAccessExpiry = transactionDetails.accessExpiryUNIX && transactionDetails.amountAvailable > 0 ? Math.round(transactionDetails.accessExpiryUNIX - Math.round(new Date().getTime()/1000)) : 0;
  const [timeToDisplay, setTimeToDisplay] = useState(timeUntilAccessExpiry > 300 ? 300 : timeUntilAccessExpiry);
  
  const handleIntentExpired = () => {
    setTimeToDisplay(0);
  }

  const handleProvideToFace = useProvideToFace();

  const handleScannedPaymentQrCode = useCallback(async({
    publicTransactionId,
    accessCode,
  }) => {

    // console.log("handleScannedPaymentQrCode");

    if (!publicTransactionId && !accessCode) {
      return null;
    }

    dispatch(updateTransactionDetails({
      localIsProcessing: true,
    }));

    // async function wait(ms) {
    //   return new Promise(resolve => {
    //     setTimeout(resolve, ms);
    //   });
    // }

    // console.log("wait", 0);
    // await wait(3000);
    // console.log("wait", "3000");

    // dispatch(updateTransactionDetails({
    //   localError: {
    //     message: "XXX.",
    //   },
    // }));

    queryParams.delete('payment_code');
    queryParams.delete('access_code');
    
    // console.log("amount in scanned QR code", params.amount);
    // console.log("currency in scanned QR code", params.currency);

    handleProvideToFace({
      publicTransactionId: publicTransactionId,
      accessCode: accessCode, // remove globally ???
      onSuccess: (freshTransactionDetails) => {
        batch(() => {
          dispatch(updateTransactionDetails({
            ...freshTransactionDetails,
          }));
          
          // update user history and balance
          if (userDetails) {
            let newHistoryResults = [
              freshTransactionDetails,
              ...userDetails.history?.results || [],
            ];

            dispatch(updateUserState({
              [userState.actAsUser ? "actAsUser" : "user"]: {
                ...userDetails,
                history: {
                  results: newHistoryResults,
                  total: newHistoryResults.length,
                  nextToken: userDetails.history?.nextToken,
                },
                balance: {
                  ...userDetails.balance,
                  approved: Math.round(((userDetails.balance?.earned + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
                  earned: Math.round(((userDetails.balance?.earned + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
                }
              },
            }));
          }
          
          // add view for new provision or charge ??? - when accepting a payment feedback returned is not complete... need to mask this ???
          dispatch(setDialogState({
            componentName: "ViewSuccessfulTransaction",
            params: {
              header: I18n.get('Thank you!'),
              body: `${I18n.get('We have added')} ${handleFormattedCurrencyNumber(transactionDetails.amount || 0, transactionDetails.currency, false, false, false)} ${I18n.get('to your revenue balance.')} ${I18n.get('Your new revenue balance is')} ${handleFormattedCurrencyNumber(Math.round(((userDetails?.balance?.earned + transactionDetails.amount) + Number.EPSILON) * 100) / 100 || 0, transactionDetails.currency, false, false, false)}.`,
            }
          }));
          
          history.replace({
            // pathname: generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: freshTransactionDetails.publicTransactionId}),
            pathname: generatePath("/:handle", {...match.params, handle: userDetails?.username}),
            search: "",
          });

          dispatch(setTransactionDetails());

        });
      },
      onError: (error) => {

        history.replace({
          search: `?${queryParams.toString()}`,
        });

        dispatch(updateTransactionDetails({
          localError: error,
          localIsProcessing: false,
        }));

      }
    });
    
  }, [dispatch, handleFormattedCurrencyNumber, handleProvideToFace, history, match.params, queryParams, transactionDetails.amount, transactionDetails.currency, userDetails, userState.actAsUser]);


  // trigger
  useEffect((() => {
    if (queryParams.get('payment_code') && queryParams.get('access_code')) {
      handleScannedPaymentQrCode({
        publicTransactionId: queryParams.get('payment_code'),
        accessCode: queryParams.get('access_code'),
      });
    }
  }), [handleScannedPaymentQrCode, queryParams]);

  const handleCompletePost = () => {
    
    dispatch(updateTransactionDetails({
      localIsProcessing: true,
    }));

    handleProvideToFace({
      onSuccess: (freshTransactionDetails) => {

        console.log("freshTransactionDetails", freshTransactionDetails);
        
        batch(() => {
          dispatch(updateTransactionDetails({
            ...freshTransactionDetails,
            // proofImagesUrls: transactionDetails.proofImages.map((item, index, array) => (item.src)), // ???
          }));
          
          // update user history and balance
          if (userDetails) {
            let newHistoryResults = [
              freshTransactionDetails,
              ...userDetails.history?.results || [],
            ];

            dispatch(updateUserState({
              [userState.actAsUser ? "actAsUser" : "user"]: {
                ...userDetails,
                history: {
                  results: newHistoryResults,
                  total: newHistoryResults.length,
                  nextToken: userDetails.history?.nextToken,
                },
                balance: {
                  ...userDetails.balance,
                  approved: Math.round(((userDetails.balance?.earned + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
                }
              },
            }));
          }
          
          // show a view to start a new post ??? // After completing a post, funding history is still processing ...

          dispatch(setDialogState({
            componentName: "ViewSuccessfulTransaction",
            params: {
              header: I18n.get('Thank you!'),
              body: `${I18n.get('We have added')} ${handleFormattedCurrencyNumber(transactionDetails.amount || 0, transactionDetails.currency, false, false, false)} ${I18n.get('to your revenue balance.')} ${I18n.get('Your new revenue balance is')} ${handleFormattedCurrencyNumber(Math.round(((userDetails?.balance?.earned + transactionDetails.amount) + Number.EPSILON) * 100) / 100 || 0, transactionDetails.currency, false, false, false)}.`,
            }
          }));
         
          history.replace({
            pathname: generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: freshTransactionDetails.publicTransactionId}),
            search: "",
          });

          // ???
          setReadyToComplete(false);
          
          dispatch(updateTransactionDetails({
            localIsProcessing: false,
          }));

        });
        
      },
      onError: (error) => {
        // handle ???
        setReadyToComplete(false);
        
        
        dispatch(updateTransactionDetails({
          localError: error,
          localIsProcessing: false,
        }));
      },
    });
  };

  useEffect(() => {
    if (userDetails?.rights?.provideInternally && !userDetails?.rights?.provideExternally) {
      dispatch(updateTransactionDetails({
        paymentMethod: "balance",
      }));
    }
    else {
      dispatch(updateTransactionDetails({
        paymentMethod: null,
      }));
    }
  }, [dispatch, userDetails?.rights?.provideExternally, userDetails?.rights?.provideInternally]);

  const CancelToComplete = ({ onProceed, onCancel}) => {

    return (
      !cancelToComplete ? null :
        <Fragment>
          <Pane textAlign="center" marginTop={12} className="noselect" >
            <Strong display="contents" flex="none" fontSize={14} color="#283655" >{I18n.get('Are you sure?')}</Strong>
          </Pane>
          <Pane marginTop={12} alignItems="center" display="flex" flexWrap="wrap" gap={8} >
            <Button flex={1} fontSize={16} padding={8} height={48} className='button-red' justifyContent="center"
              onClick={() => {
                onProceed();
              }}
            >
              {I18n.get('Yes, cancel')}
            </Button>
            <Button flex={1} fontSize={16} style={{color: "#283655"}} justifyContent="center" paddingX={12} height={48} 
              onClick={() => {
                onCancel();
              }}
            >
              {I18n.get('No, go back')}
            </Button>
          </Pane>
        </Fragment>
    );
  }

  const ProceedToComplete = () => {

    return (
      <Fragment>

        <Pane marginTop={12} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"></Pane>

        {(!cancelToComplete && !readyToComplete) ?
          <Pane marginTop={12} alignItems="center" display="flex" flexWrap="wrap" gap={8}>
            <Button flex="auto" fontSize={16} padding={8} height={48} className='button-green' justifyContent="center"
              disabled={ (transactionDetails.paymentMethod === "balance" && transactionDetails.amount > userDetails.balance.available)
              || (transactionDetails.paymentMethod !== "balance" && (timeToDisplay <= 0 || transactionDetails.amount > transactionDetails.amountAvailable))
              || disabled
              }
              onClick={() => {
                setReadyToComplete(true);
              }}
            >
              {I18n.get('Complete')}
            </Button>
            <Button fontSize={16} style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={40} marginLeft="auto" marginRight="auto"
              onClick={() => {
                setCancelToComplete(true);
              }}
            >
              {I18n.get('Cancel')}
            </Button>
          </Pane>
        : null }

        {!readyToComplete ? null :
          !transactionDetails.localIsProcessing ?
            <Fragment>
              <Pane textAlign="center" marginTop={12} className="noselect" >
                <Strong display="contents" flex="none" fontSize={14} color="#283655" >{I18n.get('Are you sure?')}</Strong>
              </Pane>
              <Pane Pane marginTop={12} alignItems="center" display="flex" flexWrap="wrap" gap={8}  >
                <Button flex={1} fontSize={16} padding={8} height={48} className='button-green' width="100%" justifyContent="center"
                  onClick={() => {
                    handleCompletePost();
                  }}
                >
                  {I18n.get('Yes, complete')}
                </Button>
                <Button flex={1} fontSize={16} style={{color: "#283655"}} height={48} justifyContent="center" paddingX={12} marginLeft="auto" marginRight="auto"
                  onClick={() => {
                    setReadyToComplete(false);
                  }}
                >
                  {I18n.get('No, go back')}
                </Button>
              </Pane>
            </Fragment>
          :
            <Fragment>
              <Pane width="100%" marginTop={16} marginBottom={8} paddingY={21}>
                <Pane width="75%" height={16} marginTop={-8} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                {!transactionDetails.localProcessingTime ? null :
                  <Pane textAlign="center" marginTop={4} marginBottom={-24} className="noselect" >
                    <Text fontSize={14} color="#7B8B9A" >{I18n.get("Hmm... This is slow...")}</Text>
                  </Pane>
                }
              </Pane>
            </Fragment>
        }

        <CancelToComplete
          onProceed={() => {
            // handle ???
            history.push(generatePath("/:locale?/:handle", {...match.params, handle: userDetails.username}));
          }}
          onCancel={() => {
            setCancelToComplete(false);
          }}  
        />

      </Fragment>
    );
  }

  const DirectlyImpactedRecipient = () => {
    return(
      <Fragment>
        <Pane textAlign="center" marginTop={10} className="noselect" >
          <Strong fontSize={16} color="#283655">{I18n.get('Directly impacted recipient')}</Strong>
        </Pane>
        <Pane textAlign="center" marginTop={4} className="noselect" >
          <Strong fontSize={30} lineHeight={1} color="#283655" >{transactionDetails?.toFace?.name || "recipient's name ..."}</Strong>
        </Pane>
      </Fragment>
    );
  }

  return (
    <Fragment>
    
      {transactionDetails.mode !== "pos" ?
        <Fragment>

          {userDetails?.rights?.provideInternally && !userDetails?.rights?.provideExternally ? 
            null
          : null}

          {userDetails?.rights?.provideInternally && userDetails?.rights?.provideExternally ?
            <Fragment>
              <Pane marginTop={16} className="noselect" >
                <Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#283655" >{I18n.get('Use funds from')}</Strong>
              </Pane>
              <Pane marginTop={4} height="auto" alignItems="center" display="flex" flexWrap="wrap">

                <Button flex={1} width={"100%"} height={50} padding={8} disabled={(transactionDetails.localIsProcessing || readyToComplete || cancelToComplete)} justifyContent="center"
                  borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                  isActive={transactionDetails.paymentMethod !== "balance"}
                  onClick={() => {
                    dispatch(updateTransactionDetails({
                      paymentMethod: undefined,
                    }));
                  }}
                  className="noselect"
                  >
                  <Strong fontSize={16} color={transactionDetails.paymentMethod !== "balance" ? "#283655" : "#7B8B9A"}>{I18n.get('Recipient')}</Strong>
                </Button>
                
                <Button flex={1} marginLeft={-1} width={"100%"} height={50} padding={8} disabled={(transactionDetails.localIsProcessing || readyToComplete || cancelToComplete)} justifyContent="center"
                  borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                  isActive={transactionDetails.paymentMethod === "balance"}
                  onClick={() => {
                    dispatch(updateTransactionDetails({
                      paymentMethod: "balance",
                    }));
                  }}
                  className="noselect"
                >
                  <Strong fontSize={16} color={transactionDetails.paymentMethod === "balance" ? "#283655" : "#7B8B9A"} >{I18n.get('My balance')}</Strong>
                </Button>

              </Pane>
            </Fragment>
          : null }
          
        </Fragment>

      : null }
        
      {(transactionDetails.paymentMethod !== "balance" && transactionDetails.toPublicFaceId) ?
        <Fragment>
          {transactionDetails.amount > transactionDetails.amountAvailable ?
            <Pane textAlign="center" marginTop={10} className="noselect" >
              <Text fontSize={14} color="#EC4C47" >{
                `${I18n.get("Insufficient funds. Available:")} ${handleFormattedCurrencyNumber(transactionDetails.amountAvailable, transactionDetails.currency)}.`
              }</Text>
            </Pane>
          :
            timeToDisplay > 0 ?
              <Pane textAlign="center" marginTop={8} className="noselect" >
                <Text fontSize={14} color="#7B8B9A" >{
                  `${handleFormattedCurrencyNumber(transactionDetails.amount, transactionDetails.currency)} ${I18n.get("will be deducted from the recipient's available balance of")} ${handleFormattedCurrencyNumber(transactionDetails.amountAvailable, transactionDetails.currency)} ${I18n.get("and added to your revenue")}.`
                }</Text>
                <Text fontSize={14} color="#7B8B9A" >{` ${I18n.get('Expires in')} `}</Text>
                <Text fontSize={14} color="#7B8B9A" >
                  <CountUp 
                    useEasing={false}
                    start={timeToDisplay} // 
                    end={0}
                    delay={0}
                    duration={timeToDisplay}
                    formattingFn={(currentNumber) => {
                      let formattedValue = formatDateFns(new Date(currentNumber * 1000), "mm:ss");
                      return formattedValue;
                    }}
                    onEnd={({ pauseResume }) => {
                      handleIntentExpired();
                    }}
                  />
                </Text>
                <Text fontSize={14} color="#7B8B9A" >{"."}</Text>
              </Pane>
            :
              <Pane textAlign="center" marginTop={8} className="noselect" >
                <Text fontSize={14} color="#EC4C47" >{I18n.get("Access expired. Please use your balance or start again if you want to use the recipient's funds.")}</Text>
              </Pane>
          }

          <DirectlyImpactedRecipient/>

        </Fragment>
      : null }

      {transactionDetails.paymentMethod !== "balance" && !transactionDetails.toPublicFaceId ? 
        <Fragment>
          {cancelToComplete || transactionDetails.localIsProcessing ?
            "Processing..."
          :
            <Fragment>
            
              <Button marginTop={20} fontSize={16} width={"100%"} height={50} paddingX={8} justifyContent="center" className="noselect" 
                borderRadius={5}
                disabled={
                  !(transactionDetails.mode === "pos" && transactionDetails.history?.find((t) => (t.type === "provisionReceipt"))?.proofImages?.length > 0)
                }
                onClick={() => {

                  queryParams.set('camera', "payment_qr_code");
                  history.push({
                    search: `?${queryParams.toString()}`,
                  });

                }}
                >
                {I18n.get("Scan payment QR code")}
              </Button>

              {/* <Pane paddingX={"25%"} marginTop={8} marginLeft="auto" marginRight="auto" width={"100%"} alignItems="center" display="flex" flexWrap="wrap" >
                <Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
                <Text flex={"none"} marginX={5} fontSize={16} height="1em" lineHeight={1} color="#7B8B9A" className="noselect" >{I18n.get('or')}</Text>
                <Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
              </Pane>

              <Pane marginTop={8} marginBottom={4} className="noselect" >
                <Button width={"100%"} height={50} padding={8} justifyContent="center"
                  borderRadius={5}
                  onClick={() => {
                    dispatch(updateTransactionDetails({
                      paymentMethod: undefined,
                      providerContribution: null,
                      amountRequired: transactionDetails.amount,
                    }));
                    // open GenerateTransactionQrcode ???
                  }}
                >
                  <Strong fontSize={16} color={"#283655"} >{I18n.get('Generate QR code for payment')}</Strong>
                </Button>
              </Pane> */}

              <Button marginTop={20} fontSize={16} paddingX={8} height={40} className="noselect" width={"100%"} justifyContent="center"
                borderRadius={5}
                disabled={false}
                onClick={() => {
                  // to be improved ???
                  dispatch(setTransactionDetails());
                  history.push(`/${userDetails.username}`);
                }}
                >
                {I18n.get("Discard")}
              </Button>

            </Fragment>
          }

        </Fragment>
      : null }

      {transactionDetails.paymentMethod !== "balance" ? null :
        <Fragment>

          <ProceedToComplete />

          {transactionDetails.amount > userDetails.balance.available ?
            <Pane textAlign="center" marginTop={8} className="noselect" >
              <Text fontSize={12} fontStyle="italic"  color="#EC4C47" >{
                `${I18n.get("Insufficient funds. Your balance:")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)}`
              }</Text>
            </Pane>
          :
            <Fragment>
              {transactionDetails.amount > 0 && !cancelToComplete && !readyToComplete ?
                <Pane textAlign="center" marginTop={8} className="noselect" >
                  <Text fontSize={12} fontStyle="italic"  color="#7B8B9A" >{
                    `${handleFormattedCurrencyNumber(transactionDetails.amount, transactionDetails.currency)} ${I18n.get("will be deducted from your current balance of")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)} ${I18n.get("and added to your revenue")}.`
                  }</Text>
                </Pane>
              : null }
            </Fragment>
          }

        </Fragment>
      }


    </Fragment>
  );
}

export default ProvisionFundingOptions;