import { I18n } from 'aws-amplify';
import { Cookies } from 'react-cookie';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { detect as detectBrowser } from 'detect-browser';
import localeDictionary, { localePathOptions, langLocaleOptions, areaServedOptions, langOptions, supportedLanguages, languages, countryData, currencies } from '../localeDictionary';

const universalCookies = new Cookies();

function getMatchDetails(path, uri) {
  // start: false makes parsing optional params work
  const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
  const matchDetails = matchPath(uri);
  return matchDetails;
}

if (process.env.NODE_ENV === "development") {
	// console.log("process.env", process.env);
	let d = new Date();
	d.setMonth(d.getMonth() + 6);
	universalCookies.set("_vc", "GB", { path: '/', expires: d });
}

// console.log("window.location", window.location);
let matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:rest(.*)?`, window.location.pathname);
// console.log("matchDetails", matchDetails);


// locale in URL are converted to ?hl at edge
const requestedLocale =  matchDetails.params ? matchDetails.params.locale : "";
// console.log("requestedLocale", requestedLocale);
const requestedLangCountry = localeDictionary[requestedLocale] || "";
const requestedLang = requestedLangCountry.substring(0, 2) || "";
const requestedCountry = requestedLangCountry.substring(3, 5) || "";
// console.log("requestedLangCountry", requestedLangCountry);
// console.log("requestedLang", requestedLang);
// console.log("requestedCountry", requestedCountry);

let queryParams = new URLSearchParams(window.location.search);
// console.log(`queryParams`, queryParams);
// for (let p of queryParams) {
//   console.log(p);
// }
const queryLangCountry = queryParams.get('hl') || "";
const queryLang = queryLangCountry.substring(0, 2) || "";
const queryCountry = queryLangCountry.substring(3, 5) ? queryLangCountry.substring(3, 5).toUpperCase() : "";
// console.log("queryLangCountry", queryLangCountry);
// console.log("queryLang", queryLang);
// console.log("queryCountry", queryCountry);

const systemLangCountry = navigator.language || "";
const systemLang = systemLangCountry.substring(0, 2) || "";
const systemCountry = systemLangCountry.substring(3, 5) ? systemLangCountry.substring(3, 5).toUpperCase() : "";
// console.log("systemLangCountry", systemLangCountry);
// console.log("systemLang", systemLang);
// console.log("systemCountry", systemCountry);

// "hl" queryParam take priority over url locale param
const viewerCountryCode =  systemCountry || queryCountry || requestedCountry || universalCookies.get("_vc") || "";

const initialCountryCode =  queryCountry || requestedCountry || viewerCountryCode;

let langByViewerCountryCode = "en";
if (countryData[viewerCountryCode]) {
  let temp = countryData[viewerCountryCode].defaultLanguage;
  if (!supportedLanguages.includes(temp)) {
    langByViewerCountryCode = temp;
  }
}
let viewerLanguage = universalCookies.get("_plang") || systemLang || queryLang || requestedLang || langByViewerCountryCode || "en";
if (!supportedLanguages.includes(viewerLanguage)) {
  viewerLanguage = "en";
}

let initialLanguage = queryLang || requestedLang || viewerLanguage;
if (!supportedLanguages.includes(initialLanguage)) {
  initialLanguage = "en";
}


let viewerLocale = universalCookies.get("_pl") || "";
if (!viewerLocale && viewerCountryCode) {
  let temp = viewerCountryCode.toLowerCase();
  if (temp === "gb") {
    temp = "uk"
  }
  if (localeDictionary[temp]) {
    viewerLocale = temp;
  }
}
if (viewerLocale !== universalCookies.get("_pl")) { // how does this get reset ? is it already covered by reset in CookiesConsent
  universalCookies.remove("_pl");
}

let initialLocal = viewerLocale;
if (initialCountryCode) {
  let temp = initialCountryCode;
  if (temp === "gb") {
    temp = "uk";
  }
  if (localeDictionary[temp]) {
    initialLocal = temp;
  }
}


// currencies are set by cookie or initially by country code
let viewerCurrency = "gbp";
if (universalCookies.get("_pc")) {
  viewerCurrency = universalCookies.get("_pc");
}
else if (countryData[viewerCountryCode]) {
  viewerCurrency = countryData[viewerCountryCode].defaultCurrency || "gbp";
}

let initialCurrency = "gbp";
if (universalCookies.get("_pc")) {
  initialCurrency = universalCookies.get("_pc");
}
else if (countryData[initialCountryCode]) {
  initialCurrency = countryData[initialCountryCode].defaultCurrency || "gbp";
}

// tempX remove this
// let tempX = viewerCountryCode.toLowerCase();
// if (tempX === "gb" && (initialCurrency === "gbp" || viewerCurrency === "gbp") ) {
//   initialCurrency = "gbp";
//   viewerCurrency = "gbp";
// }
// console.log("tempX", tempX);

const initialState = {
	locale: initialLocal,
  viewerLocale: viewerLocale,
	language: initialLanguage,
  viewerLanguage: viewerLanguage,
  country: initialCountryCode,
	viewerCountry: viewerCountryCode,
  currency: initialCurrency,
	viewerCurrency: viewerCurrency,
	browserInfo: detectBrowser(),
  systemState: null,
};
// console.log("----- INITIAL LOCALE STATE:", initialState);
I18n.setLanguage(initialState.language);

const localeStateReducer = (
	state = initialState,
	action) => {
	switch(action.type){
		case 'SET_LOCALE_STATE':
			if (!action.payload) {
				return initialState;
			}
			return action.payload;
			
		case 'UPDATE_LOCALE_STATE':
			// console.log("----- UPDATE_LOCALE_STATE to", action.payload);
			if (!action.payload) {
				return state;
			}
			else {
        
        if (!action.payload.currency || !currencies[action.payload.currency]) {
          action.payload.currency = state.currency || "gbp";
        }

        if (!action.payload.language || !supportedLanguages.includes(action.payload.language)) {
          action.payload.language = state.language || "en";
        }
        action.payload.viewerLanguage = action.payload.language;
        if (state.language !== action.payload.language) {
          I18n.setLanguage(action.payload.language);
        }

				return {
					...state,
					...action.payload,
				};
			}
			
		default:
			return state;
	}
}

export default localeStateReducer;