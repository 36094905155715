import { Fragment, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';

import FacePH from '../images/FacePH';
import AspectRatio from './AspectRatio';
import { SelectLanguageDropdown } from './PickLanguage';
import PickCurrency from './PickCurrency';
import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';


import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { Storage, I18n } from 'aws-amplify';

const useGetUserPictureUrl = (userDetails) => {
  
  const [pictureUrl, setPictureUrl] = useState();

  useEffect(() => {
    if (userDetails.pictureUrl) {
      setPictureUrl(userDetails.pictureUrl);
    }
    else if (userDetails.picture?.key) {
      async function getUserPictureUrl() {

        try {

          const config = {
            customPrefix: { // access to these is set in Storage 
              public: "", // profiles/
              // protected: 'myProtectedPrefix/',
              // private: 'myPrivatePrefix/'
            },
            level: "public", // private | protected | public, // defaults to `public`
            // identityId: essentials.identityId, // id of another user, if `level: protected`
            // download: true, // defaults to false
            expires: 60, // validity of the URL, in seconds. defaults to 900 (15 minutes)
            contentType: "image/png", // set return content type, eg "text/html"
            // download: false,
            //   progressCallback(progress) {
            //   console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
            // }
          };

          // console.log(`userDetails.picture.key`, userDetails.picture.key);
          const fileURL = await Storage.get(userDetails.picture.key, config);
          // console.log(`fileURL from Storage.get`, fileURL);
          setPictureUrl(fileURL);
        }
        catch (error) {
          console.error("within getUserPictureUrl", error);
        }
      }
      getUserPictureUrl();
    }
    // else {
    //   setPictureUrl(); // no cleaning required
    // }
    
  }, [userDetails.picture, userDetails.pictureUrl]);

  return [pictureUrl, setPictureUrl];
}

const AccountEditProfile = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountEditProfile - ${countRef.current}`);
    return () => {
      console.log("AccountEditProfile - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const is425PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });

  const inputWebsiteRef = useRef(true);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const userPublicDetails = useMemo(() => {
    return({
      name: userDetails.name,
      username: userDetails.username,
      bio: userDetails.bio,
      description: userDetails.description,
      website: userDetails.website,
    });
  }, [userDetails.bio, userDetails.description, userDetails.name, userDetails.username, userDetails.website]);

  const [updatedUserPublicDetails, setUpdatedUserPublicDetails] = useState(_.cloneDeep(userPublicDetails));

  useEffect(() => {
    setUpdatedUserPublicDetails(_.cloneDeep(userPublicDetails));
  }, [userPublicDetails]);

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);

  const [userPictureUrl, setUserPictureUrl] = useGetUserPictureUrl(userDetails);
  const [changeProfilePhotoDialog, setChangeProfilePhotoDialog] = useState(false);

  const [accountUpdateError, setAccountUpdateError] = useState();

  const [accountNameInvalidMessage, setAccountNameInvalidMessage] = useState();
  const [accountUsernameInvalidMessage, setAccountUsernameInvalidMessage] = useState();
  const [accountBioInvalidMessage, setAccountBioInvalidMessage] = useState();
  const [accountDescriptionInvalidMessage, setAccountDescriptionInvalidMessage] = useState();
  const [accountWebsiteInvalidMessage, setAccountWebsiteInvalidMessage] = useState();
  const [accountEmailInvalidMessage, setAccountEmailInvalidMessage] = useState();

  const invalidChanges = useMemo(() => {
    if (accountNameInvalidMessage || accountUsernameInvalidMessage
      || accountBioInvalidMessage || accountDescriptionInvalidMessage || accountWebsiteInvalidMessage
      || accountEmailInvalidMessage
    ) {
      return true;
    }
    return false;
  }, [accountBioInvalidMessage, accountDescriptionInvalidMessage, accountEmailInvalidMessage, accountNameInvalidMessage, accountUsernameInvalidMessage, accountWebsiteInvalidMessage]);

  const [localListOfTakenUserNames, setLocalListOfTakenUserNames] = useState([]);

  const imageInputRef = useRef(null);
  const imageCanvasRef = useRef(null);

  useEffect (() => {
    
    switch(accountUpdateError?.code) {
      case "x_username_is_too_long":
        setAccountUsernameInvalidMessage(I18n.get('Username is too long.'));
        break;
      case "x_username_is_too_short":
        setAccountUsernameInvalidMessage(I18n.get('Username is too short.'));
        break;
      case "x_username_is_invalid":
        setAccountUsernameInvalidMessage(I18n.get('Username is invalid.'));
        break;
      case "x_username_is_taken":
        setAccountUsernameInvalidMessage(I18n.get('Username is taken.'));
        break;
      case  "x_username_is_required":
        setAccountUsernameInvalidMessage(I18n.get('Username is required.'));
        break;
      default:
        // code block
    }

    setAccountUpdateError();
        
  }, [accountUpdateError?.code]);

  // validate
  const validateUserPublicDetails = useCallback((e) => {

    let errorCount = 0;
    
    if (updatedUserPublicDetails.name?.length > 30) {
      setAccountNameInvalidMessage(I18n.get('Name is too long.'));
      errorCount++;
    }
    else {
      setAccountNameInvalidMessage();
    }

    // website

    // eslint-disable-next-line no-useless-escape
    const reWebsite = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    if (updatedUserPublicDetails.website && !reWebsite.test(updatedUserPublicDetails.website)
      && (inputWebsiteRef.current?.input !== document.activeElement || e?.type === "blur") ) {
      setAccountWebsiteInvalidMessage(I18n.get('Website is not a valid.'));
      errorCount++;
    }
    else if (updatedUserPublicDetails.website?.length > 150) {
      setAccountWebsiteInvalidMessage(I18n.get('Website is too long.'));
      inputWebsiteRef.isInvalid = true;
      errorCount++;
    }
    else {
      setAccountWebsiteInvalidMessage();
    }

    if (errorCount > 0) {
      return false;
    }
    return true;

  }, [updatedUserPublicDetails.name?.length, updatedUserPublicDetails.website]);

  const saveImage = async (imageFile) => {
    // console.log("imageFile", imageFile);
    setProcessing(true);
    setChangeProfilePhotoDialog(false);
    function getFileExtension(filename) {
      return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

    const fileExtension = getFileExtension(imageFile.name);
    if (fileExtension !== "png" && fileExtension !== "jpeg") {
      // ???
    }

    try {
      
      let image = new Image();
      let ctx = imageCanvasRef.current.getContext('2d');

      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      const imageDataURL = await toBase64(imageFile).catch(e => Error(e));

      if(imageDataURL instanceof Error) {
         console.error('with imageDataURL', imageDataURL.message);
         return;
      }

      image.src = imageDataURL;

      let newImageBuffer = null;
      image.onload = async function(){
        // console.log(image.width, image.height);
        let imageRatio = image.width / image.height;
        let dWidth;
        let dHeight;
        let sWidth;
        let sHeight;
        let sx;
        let sy;
        let dx;
        let dy;
        //if horizontal
        if(imageRatio > 1){
          sWidth = image.height;
          sHeight = image.height;
          dWidth = image.height < 1350 ? image.height : 1350;
          dHeight = image.height < 1350 ? image.height : 1350;
          sx = (image.width - image.height) / 2;
          sy = 0;
          dx = 0;
          dy = 0;
        }
        //if vertical
        else{
          sWidth = image.width;
          sHeight = image.width;
          dWidth = image.width < 1350 ? image.width : 1350;
          dHeight = image.width < 1350 ? image.width : 1350;
          sx = 0;
          sy = (image.height - image.width) / 2;
          dx = 0;
          dy = 0; 
        }
        imageCanvasRef.current.width = dWidth;
        imageCanvasRef.current.height = dHeight;
        ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
        let newImageBase64 = imageCanvasRef.current.toDataURL("image/png", 1);

        setUserPictureUrl(newImageBase64); // to temp fill picture

        newImageBuffer = Buffer.from(newImageBase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), 'base64');
        
        const fileKey = Math.round(new Date().getTime()/1000) + "-" + userDetails?.publicFaceId + ".png";
        const config = {
          customPrefix: { // access to these is set in Storage 
            public: 'profiles/',
            // protected: 'myProtectedPrefix/',
            // private: 'myPrivatePrefix/'
          },
          level: "public", // private | protected | public, // defaults to `public`
          contentType: "image/png",
        };
        const uploadedFileKey = await Storage.put(fileKey, newImageBuffer, config); // returns key
        
        handleUpdateUserDetails({
          userDetailsToUpdate: {
            picture: {
              key: fileKey,
            },
          },
          onSuccess: (freshUserDetails) => {
            // console.log("freshUserDetails after saveImage", freshUserDetails); // returns freshUserDetails.picture.key: fileKey, and triggers ...
            setProcessing(false);
          },
          onError: (error) => {
            console.error("after handleUpdateUserDetails within saveImage", error);
            setProcessing(false);
          },
        });
      }
    }
    catch (error) {
      // handle ???
      console.error("within saveImage:", error);
    }

  }

  const handleUpdateUserPublicDetails = () => {
    setProcessing(true);
    if (!validateUserPublicDetails()) {
      setProcessing(false);
      return;
    }
    handleUpdateUserDetails({
      userDetailsToUpdate: updatedUserPublicDetails,
      onSuccess: (freshUserDetails) => {
        setProcessing(false);
      },
      onError: (error) => {
        console.error("after handleUpdateUserPublicDetails", error);
        setAccountUpdateError(error);
        if (error.code === "x_username_is_taken") {
          setLocalListOfTakenUserNames([...localListOfTakenUserNames, updatedUserPublicDetails.username]);
        }
        setProcessing(false);
      },
    });
  }

  const handleDiscardUserPublicDetails = () => {
    setUpdatedUserPublicDetails(_.cloneDeep(userPublicDetails));
    validateUserPublicDetails();
  }


  return (
    <Fragment>

      <Pane marginTop={10} marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Pane maxWidth={190} marginLeft="auto" marginRight="auto" >
              <AspectRatio ratio={1/1} >
                {userPictureUrl ?
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={8} >
                    <img src={userPictureUrl} alt=""
                      onError={() => {
                        console.error("within img src userPictureUrl");
                      }}
                      style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit"}} />
                  </Pane>
                :
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={8} >
                    <FacePH color={userDetails.picture?.key ? "#E4E7EB" : "#7B8B9A"} width="100%" height="100%" />
                  </Pane>
                }
              </AspectRatio>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} width="100%" textAlign="center" >
          <Pane maxWidth={380} >
            <input
              type="file"
              accept="image/png, image/jpeg" // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
              ref={imageInputRef}
              style={{display: 'none'}}
              // onClick={(event) => {}}
              onChange={(event) => {
                // console.log("imageInputRef event", event);
                let selectedImage = event.target.files[0];
                imageInputRef.current.value = null;
                if (selectedImage) {
                  saveImage(selectedImage);
                }
                else {
                  console.warn("No file was selected..."); // doesn't work ???
                }
              }}
            />
            <canvas
              ref={imageCanvasRef}
              style={{ "display": "none"}}>
            </canvas>
            <Button fontSize={14} paddingX={8} height={30} appearance="minimal"
              disabled={processing}
              onClick={(event) => {
                if (userPictureUrl) {
                  setChangeProfilePhotoDialog(true);
                }
                else {
                  event.stopPropagation();
                  event.preventDefault();
                  imageInputRef.current.click();
                }
              }}
            >
              {I18n.get('Change Picture')}
            </Button>
            <Dialog
              isShown={changeProfilePhotoDialog}
              onCloseComplete={() => {
                setChangeProfilePhotoDialog(false);
              }}
              // onCancel={(close) => close()}
              title='Change Picture'
              hasHeader={false}
              hasFooter={false}
              preventBodyScrolling
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEscapePress={false}
              width={350}
              containerProps={{
                // "backgroundColor": "transparent",
                "maxHeight": "100%",
                "margin": "auto"
              }}
              contentContainerProps={{
                "padding": "0px"
              }}
            >
              {({ close }) => (
                <Pane padding={16} >
                  <Pane background="tint1" borderRadius={5} >
                    
                    <Pane borderBottom borderWidth={1} borderColor="#E4E7EB" >
                      <Button justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" borderRadius={5}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        imageInputRef.current.click();
                      }} >
                        <Strong fontSize={16} color={"#283655"} >{I18n.get('Upload Photo')}</Strong>
                      </Button>
                    </Pane>
                    <Pane >
                      <Button justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" borderRadius={5}
                      onClick={() => {
                        close();
                        setUserPictureUrl();
                        handleUpdateUserDetails({
                          userDetailsToUpdate: {
                            picture: {
                              key: null, // removes current picture
                            },
                          },
                          onSuccess: (freshUserDetails) => {
                            // console.log("freshUserDetails after Remove Current Photo", freshUserDetails);
                          },
                          onError: (error) => {
                            console.error("after handleUpdateUserDetails within removing image", error);
                          },
                        });
                      }} >
                        <Strong fontSize={16} color={"#EC4C47"} >{I18n.get('Remove Current Photo')}</Strong>
                      </Button>
                    </Pane>
                  </Pane>
          
                  <Pane marginTop={16} background="tint1" borderRadius={5}>
                    <Button justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" borderRadius={5}
                    onClick={close} >
                      <Strong fontSize={16} color={"#283655"} >{I18n.get('Cancel')}</Strong>
                    </Button>
                  </Pane>
                </Pane>
              )}
              </Dialog>
          </Pane>
        </Pane>
      </Pane>
      
      {/* <Pane marginBottom={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{"Permanent Link"}</Strong> 
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Pane height="auto" alignItems="center" display="inline-flex" flexWrap="wrap">
              <Pane flex={"none"} border borderColor="#283655" borderWidth={2} borderTopLeftRadius={5} borderBottomLeftRadius={5}>
                <Heading marginLeft={4} marginRight={4} size={400} color="#283655">{"facedonate.org/"}</Heading>
              </Pane>
              <Pane flex={"none"} background="#283655" border borderColor="#283655" borderWidth={2} borderTopRightRadius={5} borderBottomRightRadius={5} >
                <Heading marginX={4} size={400} color="#FFFFFF">{"publicFaceId"}</Heading>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        
      </Pane> */}

      <Pane height={10} ></Pane>
      <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Public information')}</Strong>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This will be visible to others as part of your page.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      <DynamicInputField label={I18n.get('Name')} inputName="given-name"
        type="text"
        disabled={processing}
        description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('Name')}...`}
        value={updatedUserPublicDetails.name}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              setAccountNameInvalidMessage(I18n.get('Name is too long.'));
            }
            else {
              setAccountNameInvalidMessage();
            }
          }
          setUpdatedUserPublicDetails({
            ...updatedUserPublicDetails,
            name: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserPublicDetails({
            ...updatedUserPublicDetails,
            name: updatedUserPublicDetails.name?.trim(),
          });
        }}
        messageWhenInvalid={accountNameInvalidMessage}
      />

      <DynamicInputField label={I18n.get('Username')} inputName="username" type="text" disabled={processing}
        placeholder={`${I18n.get('Username')}...`}
        description={`${I18n.get("Your page's address")}: facedonate.org/${updatedUserPublicDetails.username || userDetails.username}`}
        value={updatedUserPublicDetails.username}
        onChange={(value) => {
          // console.log("value", value);
          const re = /^[a-zA-Z0-9._]{6,30}$/;
          let temp = value?.trim();
          if (!re.test(String(temp))) {
            if (temp.length < 1) {
              setAccountUpdateError({
                code: "x_username_is_required"
              });
            }
            else if (temp.length < 6) {
              setAccountUpdateError({
                code: "x_username_is_too_short"
              });
            }
            else if (temp.length > 30) {
              setAccountUpdateError({
                code: "x_username_is_too_long"
              });
            }
            else {
              setAccountUpdateError({
                code: "x_username_is_invalid"
              });
            }
          }
          else if (localListOfTakenUserNames.includes(temp)) {
            setAccountUpdateError({
              code: "x_username_is_taken"
            });
          }
          else  {
            setAccountUsernameInvalidMessage();
          }
          setUpdatedUserPublicDetails({
            ...updatedUserPublicDetails,
            username: value?.trim(),
          });
        }}
        // onBlur={(e) => {}}
        messageWhenInvalid={accountUsernameInvalidMessage}
      />
      
      <Pane marginBottom={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Bio')}</Strong> 
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <textarea
              className="input-standard"
              style={{
                "resize": "vertical",
                "display": "block",
                "width": "100%",
                "height": "80px",
                "minHeight": "80px",
                "maxHeight": "160px",
              }}
              disabled={processing}
              placeholder={`${I18n.get('Bio')}...`}
              value={updatedUserPublicDetails.bio?.replace(/<br\/>/g,'\r\n') || ""}
              onChange={(e) => {
                let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                if (temp?.length > 2200) { // used to be 150
                  setAccountBioInvalidMessage(I18n.get('Too long.'));
                }
                else {
                  setAccountBioInvalidMessage();
                  setUpdatedUserPublicDetails({
                    ...updatedUserPublicDetails,
                    bio: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
                  });
                }
              }}
              onBlur={(e) => {
                setUpdatedUserPublicDetails({
                  ...updatedUserPublicDetails,
                  bio: updatedUserPublicDetails.bio?.trim(),
                });
              }}
            />
            {(accountBioInvalidMessage) ?
              <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                <Text size={400} color="#EC4C47">{accountBioInvalidMessage}</Text>
              </Pane>
            :
              <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get('Describe your page.')}</Text>
              </Pane>
            }
          </Pane>
        </Pane>
        
      </Pane>

      {/* Description */}
      {/* <Pane marginBottom={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Description')}</Strong> 
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <textarea
              className="input-standard"
              style={{
                "resize": "vertical",
                "display": "block",
                "width": "100%",
                "height": "80px",
                "minHeight": "80px",
                "maxHeight": "160px",
              }}
              disabled={processing}
              placeholder={`${I18n.get('Description')}...`}
              value={updatedUserPublicDetails.description?.replace(/<br\/>/g,'\r\n') || ""}
              onChange={(e) => {
                let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                if (temp?.length > 2200) {
                  setAccountDescriptionInvalidMessage(I18n.get('Too long.'));
                }
                else {
                  setAccountDescriptionInvalidMessage();
                  setUpdatedUserPublicDetails({
                    ...updatedUserPublicDetails,
                    description: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
                  });
                }
              }}
              onBlur={(e) => {
                setUpdatedUserPublicDetails({
                  ...updatedUserPublicDetails,
                  description: updatedUserPublicDetails.description?.trim(),
                });
              }}
            />
            {(accountDescriptionInvalidMessage) ?
              <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                <Text size={400} color="#EC4C47">{accountDescriptionInvalidMessage}</Text>
              </Pane>
            :
              <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{"Use this field to describe your page at length."}</Text>
              </Pane>
            }
          </Pane>
        </Pane>
        
      </Pane> */}

      {/* Website */}
      <DynamicInputField
        ref={inputWebsiteRef}
        label={I18n.get('Website')} inputName="website" type="text" disabled={processing} placeholder={`${I18n.get('Website')}...`}
        value={updatedUserPublicDetails.website}
        onChange={(value) => {
          setUpdatedUserPublicDetails({
            ...updatedUserPublicDetails,
            website: value?.trim(),
          });
        }}
        messageWhenInvalid={accountWebsiteInvalidMessage}
        // maxLength={5}
        // pattern={"/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm"}
        isInvalid={(e) => {
          // console.log("isInvalid e", e);
          validateUserPublicDetails();
        }}
        onBlur={(e) => {
          // console.log("onBlur e", e);
          validateUserPublicDetails(e);
        }}
      />
      
      
      <Pane paddingTop={10} marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        
        <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" gap={10} >
              <Pane flex="none" >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                  disabled={processing || invalidChanges || _.isEqual(userPublicDetails, updatedUserPublicDetails)}
                  onClick={handleUpdateUserPublicDetails}
                >
                  {I18n.get('Submit')}
                </Button>
              </Pane>
              
              
              {_.isEqual(userPublicDetails, updatedUserPublicDetails) ? null :
                <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                  disabled={false}
                  onClick={handleDiscardUserPublicDetails}
                >
                  {I18n.get('Discard')}
                </Button>
              }

              {/* <Pane flex={1} textAlign="center" >
                <Button style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={30} appearance="minimal"
                  disabled={true}
                  onClick={() => {}}
                >
                  {I18n.get('Temporarily disable my account')}
                </Button>
              </Pane> */}

            </Pane>
          }
        </Pane>
      </Pane>

    </Fragment>
  );
}

export default AccountEditProfile;