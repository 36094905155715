import { useEffect, useState, Fragment } from 'react';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import { Heading, Text, Button, Pane } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { I18n } from 'aws-amplify';

import Footer from '../components/Footer';
import FacePH from '../images/FacePH';
import AspectRatio from '../components/AspectRatio';


const PageNotFound = () => {

  const is575PlusVH = useMediaQuery({ query: '(min-height: 575px)' });

  let history = useHistory();
  let pageParams = useParams();
  const pathPattern = "/:locale?";

  return (
    <Fragment >
    
      <Pane clearfix minWidth={320} maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Pane marginTop={is575PlusVH ? "10vh" : null} >
          <Pane textAlign="center" >
            <Pane maxWidth={190} marginLeft="auto" marginRight="auto" >
              <AspectRatio ratio={1/1} >
                <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto" >
                  <FacePH color="#7B8B9A" width="100%" height="100%" />
                </Pane>
              </AspectRatio>
            </Pane>
          </Pane>
          <Pane textAlign="center" marginTop={16}>
            <Heading size={700} color="#283655">{I18n.get("Sorry, this page isn't available.")}</Heading>
          </Pane>
          <Pane textAlign="center" marginTop={16}>
            <Text size={500} color="#283655">{I18n.get("The link you followed may be broken, or the page may have been removed.")}</Text>
          </Pane>
          <Pane textAlign="center" marginTop={20}>
            <Button fontSize={16} paddingX={18} height={40} appearance="minimal"
              onClick={() => {
                history.replace({
                  pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
                  // search: "",
                  // state: state,
                });
              }}
            >
              {I18n.get("Go back to home page")}
            </Button>
          </Pane>
        </Pane>
        
      </Pane>
      <Footer />
    </Fragment>
  );
}

export default PageNotFound;