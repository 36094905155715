import { Fragment, useEffect, useState, useMemo, useRef, useCallback, useContext } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Popover, Menu, Position, ChevronDownIcon, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/ErrorFallback';

import Footer from '../components/Footer';


import AccountMenu from '../components/AccountMenu';
import AccountVerify from '../components/AccountVerify';

import AccountEditProfile from '../components/AccountEditProfile';
import AccountChangePassword from '../components/AccountChangePassword';
import AccountPersonalDetails from '../components/AccountPersonalDetails';
import AccountManageBalance from '../components/AccountManageBalance';
import AccountManagePayments from '../components/AccountManagePayments';
import AccountManageNotifications from '../components/AccountManageNotifications';
import AccountPrivacyAndSecurity from '../components/AccountPrivacyAndSecurity';

import AccountAdminPanel from '../components/AccountAdminPanel';
import AccountProfessionalSettings from '../components/AccountProfessionalSettings';


import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'aws-amplify';

const Account = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Account - ${countRef.current}`);
    return () => {
      console.log("Account - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const is735PlusScreen = useMediaQuery({ query: '(min-width: 735px)' });

  const tabs = useMemo(() => {
    let initTabs = [
      {
        id: "editProfile",
        heading: I18n.get('Edit profile'),
        mode: "edit",
        component: <AccountEditProfile />,
      },
      {
        id: "personalDetails",
        heading: I18n.get('Personal details'),
        mode: "personal",
        component: <AccountPersonalDetails />,
      },
      // {
      //   id: "professionalSettings",
      //   heading: I18n.get('Professional account'),
      //   mode: "professional",
      //   component: <AccountProfessionalSettings />,
      // },
      {
        id: "changePassword",
        heading: I18n.get('Change password'),
        mode: "password",
        component: <AccountChangePassword />,
      },
      {
        id: "manageBalance",
        heading: I18n.get('Manage balance'),
        mode: "balance",
        component: <AccountManageBalance />,
      },
      {
        id: "managePayments",
        heading: I18n.get('Payments'),
        mode: "payments",
        component: <AccountManagePayments />,
      },
      {
        id: "manageNotifications",
        heading: I18n.get('Notifications'),
        mode: "notifications",
        component: <AccountManageNotifications />,
      },
      // {
      //   id: "privacyAndSecurity",
      //   heading: I18n.get('Privacy and Security'),
      //   mode: "privacy-and-security",
      //   component: <AccountPrivacyAndSecurity />,
      // },
      // add 
      // add "other" to include legal details.
      {
        id: "menu",
        heading: I18n.get('Menu'),
        mode: "menu",
        component: <AccountMenu />,
        hideFromMenu: true,
      },
      {
        id: "verify",
        heading: I18n.get('Verify'),
        mode: "verify",
        component: <AccountMenu />,
        hideFromMenu: true,
      },

      
    ];

    // depends on the signed-in user
    if (userState.user?.rights?.adminPanel) {
      initTabs.push({
        id: "admin",
        heading: I18n.get('Admin'),
        mode: "admin",
        component: <AccountAdminPanel />,
      });
    }

    // userDetails.tags.filter(tag => ["charity", "corporate", "retailer"].includes(tag)).length > 0
    
    // unless is a recipient with FaceId enabled, always ???
    if (!(userDetails?.rights?.balancePay && userDetails?.settings?.faceLinked)) {
      initTabs.splice(2, 0, {
        id: "professionalSettings",
        heading: I18n.get('Professional account'),
        mode: "professional",
        component: <AccountProfessionalSettings />,
      });
    }
    
    return initTabs;
  
  }, [userDetails?.rights?.balancePay, userDetails?.settings?.faceLinked, userState.user?.rights?.adminPanel]);

  useEffect(() => {
    if (!match.params.mode) {
      history.replace(generatePath(match.path, {...match.params, mode: "edit"}));
    }
  }, [history, match.params, match.path]);

  const selectedTab = useMemo(() => {
    let initTab = tabs.find(tab => tab.mode === match.params.mode);
    if (!initTab) {
      initTab = tabs.find(tab => tab.mode === "edit")
    }
    return initTab;
  }, [match.params.mode, tabs]);

  const previousTabRef = useRef(selectedTab);

  const selectTab = (tab) => {
    if (!tab ) {
      history.goBack();
    }
    else {
      previousTabRef.current = selectedTab;
      history.push(generatePath(match.path, {...match.params, mode: tab?.mode}));
    }
    
  }

  useEffect(() => {
    if (match.params?.mode !== "verify" && userDetails?.records?.find((record) => (record.type === "emailVerification"))?.status === "required" ) {
      // trigger required verification
      history.push(generatePath(match.path, {...match.params, mode: "verify"}));
    }
  }, [history, match.params, match.path, userDetails?.records]);


  return (

    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >

    {selectedTab.mode === "menu" ?
      <AccountMenu

      >
        <Menu >
          <Menu.Group >
          {tabs.map((tab) => (tab.hideFromMenu ? false :
            <Menu.Item
              key={tab.is}
              height={40}
              onSelect={() => {
                selectTab(tab);
              }}
            >
              <Pane alignItems="center" display="inline-flex" flexWrap="wrap" >
                <Text flex={"none"} color="#283655" fontSize={16} >{tab.heading}</Text>
              </Pane>
            </Menu.Item>
          ))}
          </Menu.Group>
          <Menu.Divider />
          <Pane >
            <Menu.Item height={40}
              onSelect={() => {
                console.log("previousTabRef.current", previousTabRef.current);
                selectTab();
              }}
            >
              <Pane justifyContent="left" display="flex" >
                <Text color="#EC4C47" size={400}>{I18n.get('Cancel')}</Text>
              </Pane>
            </Menu.Item>            
          </Pane>
        </Menu>
      </AccountMenu>
    :
      selectedTab.mode === "verify" ?
        <AccountVerify />
      : 

        <Fragment >
        
          <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

            <Pane marginTop={is735PlusScreen ? 36 : 20} display="flex" height="auto" flexWrap="wrap" gap={8} >

              {is735PlusScreen ? 
                <Pane height="fit-content" padding={8} background="tint1" borderRadius={5} flexBasis={200} marginRight={24} display={"block"} >
                  <Tablist >
                    {tabs.map((tab, index) => (tab.hideFromMenu ? false :
                      <SidebarTab
                        key={tab.id}
                        id={tab.id}
                        onSelect={() => {
                          selectTab(tab);
                        }}
                        isSelected={tab.mode === selectedTab.mode}
                        aria-controls={`panel-${tab.id}`}
                        fontSize={16}
                        height={40}
                        borderRadius={5}
                        marginTop={4}
                        marginBottom={4}
                      >
                        {tab.heading}
                      </SidebarTab>
                    ))}
                  </Tablist>
                  
                </Pane>
              :
                <Fragment>
                  <Pane width="100%" height="fit-content" display={"block"} >
                    <SidebarTab
                      key={selectedTab.id}
                      id={selectedTab.id}
                      onSelect={() => {}}
                      isSelected={true}
                      aria-controls={`panel-${selectedTab.id}`}
                      fontSize={16}
                      height={40}
                      borderRadius={5}
                      marginTop={4}
                      marginBottom={4}
                      onClick={() => {
                        history.push(generatePath(match.path, {...match.params, mode: "menu"}));
                      }}
                    >
                      <Pane alignItems="center" height={40} display="flex" flexWrap="wrap">
                        <Pane flex={1} width="100%" height="auto" >
                          {selectedTab.heading}
                        </Pane>
                        <Pane flex="none" >
                          <ChevronDownIcon size={24} marginX={8} marginTop={8} />
                        </Pane>
                      </Pane>
                    </SidebarTab>
                  </Pane>
                </Fragment>
              }

              <Pane flex={1} >
                {tabs.map((tab, index) => (
                  <Pane
                    key={tab.id}
                    id={`panel-${tab}`}
                    role="tabpanel"
                    aria-labelledby={tab.id}
                    aria-hidden={tab.mode !== selectedTab.mode}
                    display={tab.mode === selectedTab.mode ? 'block' : 'none'}
                  >
                    {tab.component}
                  </Pane>
                ))}
              </Pane>


            </Pane>
          </Pane>
          <Footer/>
        </Fragment>
    }

    </ErrorBoundary>
  );
}

export default Account;