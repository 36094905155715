import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Pane } from 'evergreen-ui';

import AspectRatio from './AspectRatio';
import QRCodeStyling from "qr-code-styling"; // https://qr-code-styling.com/

import FDLogoSvg from '../assets/icons/FDLogoT.svg';

const QRCodeStyledComponent = ({
	textToEncode,
	frontColor = "#283655",
	backColor = "#0000", // Transparent background
	fileExt = "png",
	onChange = () => {},
	...rest
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`DialogAuth - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("QRCodeStyledComponent - cleaned up");
    }
  }, []);

  const [qrCodeImageSrc, setQrCodeImageSrc] = useState();
	const [loading, setLoading] = useState(true);
	
  // const qrcodeImageRef = useRef(null);

  const qrCode = useMemo(() => {
    return (new QRCodeStyling({
      // type: "svg",
      width: 512,
      height: 512,
      data: textToEncode, /// e.g. "https://facedonate.org/xexwkw?utm_source=qr",
      margin: 50,
      image: FDLogoSvg,
      qrOptions: {
        typeNumber: "5",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 15,
      },
      dotsOptions: {
        type: "extra-rounded",
        color: "#000000",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      dotsOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#000000",
      },
      cornersSquareOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      cornersDotOptions: {
        type: "",
        color: "#000000",
      },
      cornersDotOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    }));
  }, [textToEncode]);

  // useEffect(() => {
  //   qrCode.append(qrcodeImageRef.current);
  // }, [qrCode]);

  // useEffect(() => {
  //   qrCode.update({
  //     data: textToEncode,
  //   });
  // }, [qrCode, textToEncode]);

  useEffect(() => {
		const generateQR = async() => {
			try {
				setLoading(true);
	
				const qrCodeBlob = await qrCode.getRawData(fileExt);
				// console.log(qrCodeBlob);
				let reader = new FileReader();
				reader.readAsDataURL(qrCodeBlob);

				reader.onloadend = function() {
					let base64data = reader.result;          
					// console.log(base64data);
					setQrCodeImageSrc(base64data);
					setLoading(false);
					onChange({
						isLoaded: true,
						qrcodeBase64: base64data,
					});
				}
			}
			catch (error) {
				console.error("with generateQR", error);
				setLoading(false);
				onChange({
					isLoaded: false
				});
			}
		}

    if (isCurrent.current) {
      generateQR();
    }

	}, [fileExt, onChange, qrCode]);
  
  // const onDownloadClick = () => {
  //   qrCode.download({
	// 		name: "test-name",
  //     extension: fileExt,
  //   });
  // };
	
  return (
		<AspectRatio ratio={1/1} >
			{loading ? 
				<Pane width="100%" paddingY={"50%"} alignItems="center" background="inherit" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit" >
					<Pane width="75%" height={16} marginTop={-8} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
				</Pane>
			:
				<img src={qrCodeImageSrc} alt="QR code" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit",}} />
			}
			{/* <div style={{
				"font-family": "sans-serif",
				"text-align": "center",
			}}>
				<div style={styles.inputWrapper}>
					<select onChange={onExtensionChange} value={fileExt}>
						<option value="png">PNG</option>
						<option value="jpeg">JPEG</option>
						<option value="webp">WEBP</option>
					</select>
					<button onClick={onDownloadClick}>Download</button>
				</div>
				
			</div> */}
			{/* <div ref={qrcodeImageRef} /> */}
				
	  </AspectRatio>
  );
}

export default QRCodeStyledComponent;