import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';

import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const useGetTransactions = () => {

  const userState = useSelector(state => state.userState);

  const handleGetTransactions = useCallback(async({ onSuccess = null, onError = null, ...variables }) => {
    try {

      // validate ???
      
      const response = await API.graphql({
        query: queries.getTransactions,
        variables: {
          ...variables,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });

      let tempTransaction = response.data.getTransactions;
      // let searchResult = {};
      let searchResult = tempTransaction;
      
      if (typeof onSuccess === "function") {
        onSuccess(searchResult);
      }

    }
    catch(error) {
      console.error("with getTransactions", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }, [userState.user]);

  return handleGetTransactions;
}

export { useGetTransactions };