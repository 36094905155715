const EducationIcon = ({size="inherit", style={}, color, width="1em", height="1em", opacity=1, ...rest}) => {

  return (
    <svg
      viewBox="0 0 25 20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fontSize={size}
      width={width}
      height={height}
      opacity={opacity}
      style={style}
      {...rest}
    >

      <path
        d="M9.511 5.823l-7.84-4.54V15.97l7.84 2.372V5.823zM10.393 5.884v12.492l7.89-2.378V1.315l-7.89 4.569zM22.8 15.714h-.23l.928 3.251.926-3.25h-.23.23V2.648h-1.855v13.065h.231z"
        fillRule="nonzero"
        fill={color || "#000000"}
      />
      <path
        d="M20 1.899l-.84-.666v15.118l-8.712 2.823h-.885L.89 16.744V1.234l-.84.666v15.437l9.473 2.63h.965l8.74-2.783.772-.228V1.9zM24.5.928a.929.929 0 00-.927-.93.929.929 0 00-.928.93v1.003H24.5V.928z"
        fillRule="nonzero"
        fill={color || "#000000"}
      />
      
    </svg>

  );
}

export default EducationIcon;