import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Table, RepeatIcon, CircleArrowRightIcon, IconButton, CircleIcon, Radio, Spinner, UpdatedIcon, ArrowTopRightIcon, ArrowBottomRightIcon } from 'evergreen-ui';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import _ from 'lodash';

import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';
import DialogAddFundsToBalance from './DialogAddFundsToBalance';
import PickCurrency from './PickCurrency';
import CurrencyNumberInput from './CurrencyNumberInput';
import DynamicTickBox from './DynamicTickBox';
import DialogHeader from './DialogHeader';
import ModalResponsive from './ModalResponsive';

import PaymentComponent from './PaymentComponent';
import NumberPlusMinusPicker from './NumberPlusMinusPicker';

import { currencies } from '../services/localeDictionary';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useGetTransactions } from "../services/useGetTransactions";
import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, updateUserState, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import useMatchFundTransactions from "../services/useMatchFundTransactions";
import { useAdminTopUp } from "../services/useAdminTransactions";

import useProcessGiftAidTransactions from "../services/useProcessGiftAidTransactions";

import { I18n } from 'aws-amplify';


const ResponsivePane = styled(Pane)`
  text-align: right;
  @media only screen and (max-width: ${425}px) {
    text-align: left;
  }
`;

const AccountGiftAidRequestsIncoming = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountGiftAidRequestsIncoming - ${countRef.current}`);
    return () => {
      console.log("AccountGiftAidRequestsIncoming - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const [processing, setProcessing] = useState(false);
  const [selectedTransactionsById, setSelectedTransactionsById] = useState({});
  const [isOpenDoProcessGiftAidModal, setIsOpenDoProcessGiftAidModal] = useState(false);

  const handleProcessGiftAidTransactions = useProcessGiftAidTransactions();

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: null,
      viaPublicFaceId: null,
      type: "giftAid",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {

        console.log("giftAid searchResult", searchResult);

        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);

  const totalOfSelectedTransactions = useMemo(() => {

    const getTotalOfSelectedTransactions = () => {
      let total = 0;
      for (let i = 0; i < localLoadState.results?.length; i++) {
        if (Object.keys(selectedTransactionsById)?.includes(localLoadState.results[i].publicTransactionId)) {
          total = total + localLoadState.results[i].amountExpected;
        }
      }
      return (Math.round((total + Number.EPSILON) * 100) / 100);
    }

    let temp = getTotalOfSelectedTransactions();

    return temp;
    
  }, [localLoadState.results, selectedTransactionsById]);


  if (!localLoadState.results || localLoadState.results?.length < 1) {
    return null;
  }

  return (
    <Pane {...rest}>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Gift Aid declarations received')}</Strong>
        {/* {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null } */}
      </Pane>

      {/* https://www.gov.uk/guidance/claim-tax-back-on-donations-using-charities-online */}

      <Pane marginTop={10} >

        <Table 
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
          
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >

              {/* <Table.SearchHeaderCell /> */}

              <Table.TextHeaderCell
                is={"th"}
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
                width={30}
              >{
                I18n.get("Selected")
              }</Table.TextHeaderCell>
              
              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />  

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Donor")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Title")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("First name")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Last name")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("House name or number")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Postcode")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Aggregated donations")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Sponsored event")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Donation date") // keep
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Time")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
              >{
                I18n.get("Status")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                {localLoadState.results?.map((transaction) => (
                  <Table.Row is={"tr"} key={transaction.publicTransactionId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    // isSelectable
                    // onSelect={() => alert(transaction.name)}
                  >


                    <Table.TextCell
                      is={"td"}
                      flex="none"
                      textAlign="right"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                      
                        <DynamicTickBox
                          disabled={transaction.status === "succeeded" // more ???
                          
                          }
                          completed={false}
                          checked={selectedTransactionsById[transaction.publicTransactionId] ? true : false}
                          onClick={() => {
                            if (selectedTransactionsById[transaction.publicTransactionId] === undefined) {
                              setSelectedTransactionsById((prev) => {
                                let updatedObj = {
                                  ...prev
                                };
                                updatedObj[transaction.publicTransactionId] = {
                                  publicTransactionId: transaction.publicTransactionId
                                };
                                return updatedObj;
                              });
                            }
                            else {
                              setSelectedTransactionsById((prev) => {
                                let updatedObj = {
                                  ...prev
                                };
                                delete updatedObj[transaction.publicTransactionId];
                                return updatedObj;
                              });
                            }
                          }}
                        />
                        
                        <Text
                          is={"td"}
                          height={0}
                          width={0}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={0}
                          padding={0}
                        >{
                          transaction.publicTransactionId
                        }</Text>

                        <Text
                          is={"td"}
                          height={0}
                          width={0}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={0}
                          padding={0}
                        >{
                          "" // transaction.fromPublicFaceId // "donor" ??? anonymise ???
                        }</Text>

                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell
                      is={"td"}
                      // textAlign="right"
                      paddingX={0}
                      marginX={4}
                      textProps={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "baseline",
                        // justifyContent: "end",
                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655"
                          display={"flex"}
                          gap={2}
                          lineHeight={"inherit"}
                          alignItems={"baseline"}
                        >
                        
                          <Strong
                            // is={"td"}
                            fontSize={14}
                            color="#283655"
                            display={"block"}
                            lineHeight={"inherit"}
                            // textAlign="left"
                            // color={"#7B8B9A"}
                            // fontSize={12}
                            // padding={0}
                          >
                            {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.title || ""}
                          </Strong>

                          <Strong
                            is={"td"}
                            fontSize={14}
                            color="#283655"
                            display={"block"}
                            lineHeight={"inherit"}
                            // textAlign="left"
                            // color={"#7B8B9A"}
                            // fontSize={12}
                            // padding={0}
                          >
                            {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.firstName || ""}
                          </Strong>
                          <Strong
                            is={"td"}
                            fontSize={14}
                            color="#283655"
                            display={"block"}
                            lineHeight={"inherit"}
                            // textAlign="left"
                            // color={"#7B8B9A"}
                            // fontSize={12}
                            // padding={0}
                          >
                            {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.lastName || ""}
                          </Strong>
                        </Strong>

                        <Text
                          is={"td"}
                          display={"flex"}
                          gap={8}
                          order={3}
                          lineHeight={"inherit"}
                          // textAlign="right"
                          width={"100%"}
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >
                          {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.address || ""}
                          {", "}{transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.town || ""}
                          <Text
                            is={"td"}
                            display={"block"}
                            lineHeight={"inherit"}
                            textAlign="left"
                            color={"#7B8B9A"}
                            fontSize={12}
                            padding={0}
                          >
                            {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "giftAidRequest"))?.fromFace?.postcode || ""}
                          </Text>
                        </Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >{
                      "" // Aggregated donations
                    }</Text>

                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >{
                      "" // Sponsored event
                    }</Text>


                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell
                      is={"td"}
                      textAlign="right"
                      paddingX={0}
                      marginX={4}
                      textProps={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "baseline",
                        justifyContent: "end",
                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655" order={2} >
                          {handleFormattedCurrencyNumber(transaction.amountSubjected, transaction.currency || localeState.currency, false, false)}
                        </Strong>
                      
                        {/* <Text
                          is={"td"}
                          // display={"flex"}
                          // flex={1}
                          order={1}
                          lineHeight={"inherit"}
                          textAlign="right"
                          color={"#7B8B9A"}
                          fontSize={transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ? 12 : 0}
                          padding={0}
                          marginRight={6}
                          marginLeft="auto"
                        >
                          <Fragment>
                            {transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ?
                              handleFormattedCurrencyNumber(transaction.relatedAmountExpected, transaction.relatedCurrency || localeState.currency, false, false)
                            :
                              handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)
                            }
                          </Fragment>
                        </Text> */}

                        {transaction.status === "succeeded" ?
                          <Strong
                            is={"td"}
                            // display={"flex"}
                            order={3}
                            lineHeight={"inherit"}
                            textAlign="right"
                            width={"100%"}
                            color={"#47B881"}
                            fontSize={12}
                            padding={0}
                          >
                            {handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)} {I18n.get('claimed')}
                          </Strong>
                        :
                          <Text
                            is={"td"}
                            // display={"flex"}
                            order={3}
                            lineHeight={"inherit"}
                            textAlign="right"
                            width={"100%"}
                            color={"#7B8B9A"}
                            fontSize={12}
                            padding={0}
                          >
                            {
                              I18n.get(
                                transaction.status === "pending" ?
                                  <Fragment>
                                    {handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)} {I18n.get('to claim')}
                                  </Fragment>
                                :
                                transaction.status === "processing" ? "Processing" : "..."
                              )
                            }
                          </Text>
                        }
                      </Fragment>
                    }</Table.TextCell>

                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}

            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}

          </Table.Head>

        </Table>

        <Pane marginTop={8}>
          <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
            disabled={Object.keys(selectedTransactionsById)?.length > 0 ? false : true}
            onClick={() => {
              setIsOpenDoProcessGiftAidModal(true);
            }}
          >
            <Pane>
              {I18n.get("Claim Gift Aid")}
              {totalOfSelectedTransactions ?
                <Fragment>
                  {" "}
                  <FormattedCurrencyNumber removerDecimalZeros={false} number={totalOfSelectedTransactions} currency={localeState.currency} fontSize={20} fontColor={"#FFFFFF"} />
                </Fragment>
                
              : null }
            </Pane>

          </Button>
        </Pane>

        <ModalResponsive
          isShown={isOpenDoProcessGiftAidModal}
          onOpenComplete={() => {}}
          onCloseComplete = {() => {
            setIsOpenDoProcessGiftAidModal(false);
          }}
          // onCancel={(close) => close()}
          title='DialogDoProcessGiftAid'
          // hasHeader={false}
          // header={}
          hasFooter={false}
          // footer={}
          preventBodyScrolling={true}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
          modalStyleProps={{
            // "minHeight": "200px",
          }}
          // width={"auto"} // 350 // "100%"
          // height={"calc(100% - 36px * 2)%"}
          // containerProps={{
          //   "backgroundColor": "transparent",
          //   // "maxWidth": "100%",
          //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
          //   "margin": "auto", // auto
          //   // "overflow": 'hidden',
          // }}
          // contentContainerProps={{
          //   "padding": "0px",
          //   "maxHeight": "75hv",
          // }}
          // styles={{
          //   overlay: {
          //     "background": "#7B8B9A10",
          //     "backdrop-filter": "blur(1px)",
          //   },
          //   // modalContainer: {},
          //   modal: {
          //     "width": "100%",
          //     "max-width": "100%",
          //     "box-shadow": null,
          //     "margin": 0,
          //     "padding": 0,
          //   },
          // }}
          
        >
          
          {({ close }) => (
            <Pane width="100%" height="100%" background="#FFFFFF" borderRadius={10} >

              <DialogHeader
                headerText={I18n.get('Claim Gift Aid')}
                hideLeftButton={false}
                hideRightButton={true}
                leftButtonDisabled={false}
                rightButtonDisabled={false}
                leftButtonIcon={null}
                onLeftButtonClick={() => {
                  close();
                }}
                // rightButtonText={I18n.get('Next')}
                onRightButtonClick={() => {}}
                paddingX={10}
                paddingTop={10}
              />

              <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
                <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                  
                  <Pane paddingX={10} marginTop={12} textAlign="center" >
                    <Text fontSize={14} color="#283655" >{I18n.get('After claiming Gift Aid, the donors will be notified that you have now claimed Gift Aid on their donations.')}</Text>
                  </Pane>

                  <Pane paddingX={10} marginTop={12} >
                  <Button fontSize={16} padding={8} height={48} className={processing ? null : 'button-green'} width="100%" justifyContent="center"
                      disabled={processing}
                      onClick={() => {
                        setProcessing(true);
                        handleProcessGiftAidTransactions({
                          fromPublicFaceId: userDetails.publicFaceId,
                          transactionsToProcess: Object.values(selectedTransactionsById).map((o) => (o)),
                          currency: localeState.currency,
                          onSuccess: (transactionSearchResult) => {
                            
                            // add some animation here ???

                            // do something with the buttons and ticks ???

                            console.log("transactionSearchResult", transactionSearchResult);
                            
                            batch(() => {
                              // adjust localLoadState.results
                              let currentResults = localLoadState.results;
                              for (let i = 0; i < transactionSearchResult?.results.length; i++) {
                                const updatedTransaction = transactionSearchResult?.results[i];
                                const tempIndex = _.findIndex(localLoadState.results, { 'publicTransactionId': updatedTransaction.publicTransactionId });
                                const transactionToAdjust = currentResults[tempIndex];

                                transactionToAdjust.status = updatedTransaction.status;
                                transactionToAdjust.amount = updatedTransaction.amount;
                                transactionToAdjust.amountExpected = updatedTransaction.amountExpected;
                                transactionToAdjust.amountSubjected = updatedTransaction.amountSubjected;

                                transactionToAdjust.relatedAmount = updatedTransaction.relatedAmount;
                                transactionToAdjust.relatedAmountExpected = updatedTransaction.relatedAmountExpected;
                                transactionToAdjust.relatedAmountSubjected = updatedTransaction.relatedAmountSubjected;

                              }
                              setLocalLoadState((prev) => {
                                return ({
                                  ...prev,
                                  results: currentResults,
                                  total: currentResults.length,
                                });
                              });

                              // clear selection...
                              setSelectedTransactionsById({});

                              // adjust user balances
                              dispatch(updateUserState({
                                [userState.actAsUser ? "actAsUser" : "user"]: {
                                  ...userDetails,
                                  // history: {
                                  //   results: newHistoryResults,
                                  //   total: newHistoryResults.length,
                                  //   nextToken: userDetails?.history?.nextToken,
                                  // },
                                  balance: {
                                    ...userDetails.balance,
                                    available: Math.round(((userState?.user?.balance?.available + totalOfSelectedTransactions) + Number.EPSILON) * 100) / 100,
                                    giftAidReceived: Math.round(((userState?.user?.balance?.giftAidReceived + totalOfSelectedTransactions) + Number.EPSILON) * 100) / 100,
                                  }
                                },
                              }));

                              
                            });

                            setProcessing(false);
                            close();
                          },
                          onError: (error) => {
                            // handle ???
                            setProcessing(false);
                          }
                        });
                        
                      }}
                    >
                      {!processing ?
                        <Pane>
                          {I18n.get("Claim Gift Aid")}
                          {totalOfSelectedTransactions ?
                            <Fragment>
                              {" "}
                              <FormattedCurrencyNumber removerDecimalZeros={false} number={totalOfSelectedTransactions} currency={localeState.currency} fontSize={20} fontColor={"#FFFFFF"} />
                            </Fragment>
                            
                          : null }
                        </Pane>
                      :
                        <Fragment >
                          <Spinner marginRight={8} flex="none" size={24} />
                          <Pane >
                            {`${I18n.get('Processing')}...`}
                          </Pane>
                        </Fragment>
                      }
                    </Button>
                  </Pane>
                  

                  <Pane paddingX={10} paddingBottom={10} marginTop={12} >
                    {false ?
                      null
                    :
                      <Fragment>
                        <Pane textAlign="center" marginTop={8} className="noselect" >
                          <Text fontSize={12} fontStyle="italic"  color="#7B8B9A" >{
                            `${handleFormattedCurrencyNumber(totalOfSelectedTransactions, localeState.currency)} ${I18n.get("will be added to your balance of")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)}.`
                          }</Text>
                        </Pane>
                      </Fragment>
                    }
                  </Pane>

                </Pane>
              </Pane>

              <Pane padding={0} height={0} >
                {/* <button style={{fontSize: "1rem"}}
                  onClick={() => {
                    close();
                  }}
                >Close modal</button> */}
              </Pane>

            </Pane>
          )}
          
        </ModalResponsive>

      </Pane>
    </Pane>
    
  );
}

const AccountMatchFundingRequests = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountMatchFundingRequests - ${countRef.current}`);
    return () => {
      console.log("AccountMatchFundingRequests - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const [processing, setProcessing] = useState(false);

  const [selectedTransactionsById, setSelectedTransactionsById] = useState({});
  const [isOpenDoMatchFundingModal, setIsOpenDoMatchFundingModal] = useState(false);

  const handleMatchFundTransactions = useMatchFundTransactions();

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: null, // ???
      type: "matchFunding",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          // console.log("matchFunding updatedResultsList", updatedResultsList);

          // dispatch(updateProfileDetails({
          //   history: updatedProfileHistory,
          // }));

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);


  const totalOfSelectedTransactions = useMemo(() => {

    const getTotalOfSelectedTransactions = () => {
      let total = 0;
      for (let i = 0; i < localLoadState.results?.length; i++) {
        if (Object.keys(selectedTransactionsById)?.includes(localLoadState.results[i].publicTransactionId)) {
          total = total + localLoadState.results[i].amountExpected;
        }
      }
      return (Math.round((total + Number.EPSILON) * 100) / 100);
    }

    let temp = getTotalOfSelectedTransactions();

    return temp;
    
  }, [localLoadState.results, selectedTransactionsById]);


  if (!localLoadState.results || localLoadState.results?.length === 0) {
    return null;
  }

  return (
    <Pane {...rest} >

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Match funding requests')}</Strong>
        {/* {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null } */}
        
      </Pane>
      <Pane marginTop={4} >
        <Text fontSize={12} color="#7B8B9A">{I18n.get('These requests have been addressed to your page.')}</Text>
      </Pane>

      <Pane marginTop={8} >
        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >

            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >

              {/* <Table.SearchHeaderCell /> */}

              <Table.TextHeaderCell
                is={"th"}
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
                width={30}
              >{
                I18n.get("Selected")
              }</Table.TextHeaderCell>
              
              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />  

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Date")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                }}
                padding={0}
              >{
                I18n.get("Time")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("From")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
              >{I18n.get("From username")}</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("To")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
              >{
                I18n.get("To username")
              }</Table.TextHeaderCell>
              
              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
              >{
                I18n.get("Amount in donation currency")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
              >{
                I18n.get("Status")
              }</Table.TextHeaderCell>
              
              {/* <Table.TextHeaderCell is={"th"}
                textAlign="left"
                flex="none"
                textProps={{
                  color: "#425A70",
                  fontSize: 0,
                  width: 0,
                }}
                padding={0}
                width={40}
              >{
                I18n.get("Action")
              }</Table.TextHeaderCell> */}
              
            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body
                is={"tbody"}
                id="scrollableDiv"
                overflow={"scroll"}
                display={"block"}
              >
                {localLoadState.results?.map((transaction) => (
                  <Table.Row
                    is={"tr"}
                    width={"100%"}
                    key={transaction.publicTransactionId}
                    isSelectable
                    onSelect={() => {
                      // alert(transaction.publicTransactionId);
                      let newUrl = generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: transaction.linkedPublicTransactionId});
                      window.open(newUrl, '_blank', 'noopener,noreferrer'); // ???
                    }}
                  >

                    <Table.TextCell
                      is={"td"}
                      flex="none"
                      textAlign="right"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                      
                        <DynamicTickBox
                          disabled={transaction.status === "succeeded"
                            || transaction.amount >= transaction.amountExpected // ???
                          }
                          completed={false}
                          checked={selectedTransactionsById[transaction.publicTransactionId] ? true : false}
                          onClick={() => {
                            if (selectedTransactionsById[transaction.publicTransactionId] === undefined) {
                              setSelectedTransactionsById((prev) => {
                                let updatedObj = {
                                  ...prev
                                };
                                updatedObj[transaction.publicTransactionId] = {
                                  publicTransactionId: transaction.publicTransactionId
                                };
                                return updatedObj;
                              });
                            }
                            else {
                              setSelectedTransactionsById((prev) => {
                                let updatedObj = {
                                  ...prev
                                };
                                delete updatedObj[transaction.publicTransactionId];
                                return updatedObj;
                              });
                            }
                          }}
                        />
                        
                        <Text
                          is={"td"}
                          height={0}
                          width={0}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={0}
                          padding={0}
                        >{
                          transaction.publicTransactionId
                        }</Text>

                      </Fragment>
                    }</Table.TextCell>
                    
                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{transaction.viaFace?.name || transaction.viaPublicFaceId}</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          transaction.viaPublicFaceId
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          transaction.toPublicFaceId
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell
                      is={"td"}
                      textAlign="right"
                      paddingX={0}
                      marginX={4}
                      textProps={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "baseline",
                        justifyContent: "end",
                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655" order={2} >
                          {handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)}
                        </Strong>
                      
                        <Text
                          is={"td"}
                          // display={"flex"}
                          // flex={1}
                          order={1}
                          lineHeight={"inherit"}
                          textAlign="right"
                          color={"#7B8B9A"}
                          fontSize={transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ? 12 : 0}
                          padding={0}
                          marginRight={6}
                          marginLeft="auto"
                        >
                          <Fragment>
                            {transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ?
                              handleFormattedCurrencyNumber(transaction.relatedAmountExpected, transaction.relatedCurrency || localeState.currency, false, false)
                            :
                              handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)
                            }
                          </Fragment>
                        </Text>

                        {transaction.status === "succeeded" ?
                          <Strong
                            is={"td"}
                            // display={"flex"}
                            order={3}
                            lineHeight={"inherit"}
                            textAlign="right"
                            width={"100%"}
                            color={"#47B881"}
                            fontSize={12}
                            padding={0}
                          >
                            {Math.round(((transaction.amount / transaction.amountExpected) + Number.EPSILON) * 100) / 1}{"%"} {I18n.get('match-funded')}
                          </Strong>
                        :
                          <Text
                            is={"td"}
                            // display={"flex"}
                            order={3}
                            lineHeight={"inherit"}
                            textAlign="right"
                            width={"100%"}
                            color={"#7B8B9A"}
                            fontSize={12}
                            padding={0}
                          >
                            {
                              I18n.get(
                                transaction.status === "pending" ? "Pending" :
                                transaction.status === "processing" ? "Processing" : "..."
                              )
                            }
                          </Text>
                        }
                      </Fragment>
                    }</Table.TextCell>

                    {/* <Table.TextCell
                      is={"td"}
                      textAlign="right"
                      paddingX={5}
                      flex="none"
                    >{
                      <Fragment>
                        <IconButton height={30} icon={CircleArrowRightIcon} iconSize={16}
                          onClick={(event) => {
                            event.stopPropagation();
                            alert(transaction.linkedPublicTransactionId);
                            // ???
                          }}
                        />
                      </Fragment>

                    }</Table.TextCell> */}

                  </Table.Row>
                ))}

                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }

              </Table.Body>
            </InfiniteScroll>
          }

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >

            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}

            <Pane paddingY={4} >

              {Object.keys(selectedTransactionsById)?.length > 0 ?
                <Text fontSize={12} color={"#7B8B9A"}>
                  {Object.keys(selectedTransactionsById).length || 0} {I18n.get("selected")} {" | "}
                </Text>
              : null }

              {!localLoadState.total ? null :
              <Text fontSize={12} color={"#7B8B9A"}>
                {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
              </Text>}

            </Pane>
          </Table.Head>

        </Table>

        <Pane marginTop={8}>
          <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
            disabled={Object.keys(selectedTransactionsById)?.length > 0 ? false : true}
            onClick={() => {
              setIsOpenDoMatchFundingModal(true);
            }}
          >
            <Pane>
              {I18n.get("Match fund")}
              {totalOfSelectedTransactions ?
                <Fragment>
                  {" "}
                  <FormattedCurrencyNumber removerDecimalZeros={false} number={totalOfSelectedTransactions} currency={localeState.currency} fontSize={20} fontColor={"#FFFFFF"} />
                </Fragment>
                
              : null }
            </Pane>

          </Button>
        </Pane>

        <ModalResponsive
          isShown={isOpenDoMatchFundingModal}
          onOpenComplete={() => {}}
          onCloseComplete = {() => {
            setIsOpenDoMatchFundingModal(false);
          }}
          // onCancel={(close) => close()}
          title='DialogDoMatchFund'
          // hasHeader={false}
          // header={}
          hasFooter={false}
          // footer={}
          preventBodyScrolling={true}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
          modalStyleProps={{
            // "minHeight": "200px",
          }}
          // width={"auto"} // 350 // "100%"
          // height={"calc(100% - 36px * 2)%"}
          // containerProps={{
          //   "backgroundColor": "transparent",
          //   // "maxWidth": "100%",
          //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
          //   "margin": "auto", // auto
          //   // "overflow": 'hidden',
          // }}
          // contentContainerProps={{
          //   "padding": "0px",
          //   "maxHeight": "75hv",
          // }}
          // styles={{
          //   overlay: {
          //     "background": "#7B8B9A10",
          //     "backdrop-filter": "blur(1px)",
          //   },
          //   // modalContainer: {},
          //   modal: {
          //     "width": "100%",
          //     "max-width": "100%",
          //     "box-shadow": null,
          //     "margin": 0,
          //     "padding": 0,
          //   },
          // }}
          
        >
          
          {({ close }) => (
            <Pane width="100%" height="100%" background="#FFFFFF" borderRadius={10} >

              <DialogHeader
                headerText={I18n.get('Match fund')}
                hideLeftButton={false}
                hideRightButton={true}
                leftButtonDisabled={false}
                rightButtonDisabled={false}
                leftButtonIcon={null}
                onLeftButtonClick={() => {
                  close();
                }}
                // rightButtonText={I18n.get('Next')}
                onRightButtonClick={() => {}}
                paddingX={10}
                paddingTop={10}
              />

              <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
                <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                  
                  <Pane paddingX={10} marginTop={12} textAlign="center" >
                    <Text fontSize={14} color="#283655" >{I18n.get('Show your support and amplify the impact.')}</Text>
                  </Pane>

                  <Pane paddingX={10} marginTop={12} >
                  <Button fontSize={16} padding={8} height={48} className={processing ? null : 'button-green'} width="100%" justifyContent="center"
                      disabled={processing
                        || totalOfSelectedTransactions > userDetails.balance.available
                      }
                      onClick={() => {
                        setProcessing(true);
                        handleMatchFundTransactions({
                          fromPublicFaceId: userDetails.publicFaceId,
                          transactionsToProcess: Object.values(selectedTransactionsById).map((o) => (o)),
                          currency: localeState.currency,
                          onSuccess: (transactionSearchResult) => {
                            
                            // add some animation here ???

                            // do something with the buttons and ticks ???

                            console.log("transactionSearchResult", transactionSearchResult);
                            
                            batch(() => {
                              // adjust localLoadState.results
                              let currentResults = localLoadState.results;
                              for (let i = 0; i < transactionSearchResult?.results.length; i++) {
                                const updatedTransaction = transactionSearchResult?.results[i];
                                const tempIndex = _.findIndex(localLoadState.results, { 'publicTransactionId': updatedTransaction.publicTransactionId });
                                const transactionToAdjust = currentResults[tempIndex];

                                transactionToAdjust.status = updatedTransaction.status;
                                transactionToAdjust.amount = updatedTransaction.amount;
                                transactionToAdjust.relatedAmount = updatedTransaction.relatedAmount;
                                transactionToAdjust.amountExpected = updatedTransaction.amountExpected;
                                transactionToAdjust.relatedAmountExpected = updatedTransaction.relatedAmountExpected;

                              }
                              setLocalLoadState((prev) => {
                                return ({
                                  ...prev,
                                  results: currentResults,
                                  total: currentResults.length,
                                });
                              });

                              // clear selection...
                              setSelectedTransactionsById({});

                              // adjust user balances
                              dispatch(updateUserState({
                                [userState.actAsUser ? "actAsUser" : "user"]: {
                                  ...userDetails,
                                  // history: {
                                  //   results: newHistoryResults,
                                  //   total: newHistoryResults.length,
                                  //   nextToken: userDetails?.history?.nextToken,
                                  // },
                                  balance: {
                                    ...userDetails.balance,
                                    available: Math.round(((userState?.user?.balance?.available - totalOfSelectedTransactions) + Number.EPSILON) * 100) / 100,
                                    matchFundingMade: Math.round(((userState?.user?.balance?.matchFundingMade + totalOfSelectedTransactions) + Number.EPSILON) * 100) / 100,
                                  }
                                },
                              }));

                              
                            });

                            setProcessing(false);
                            close();
                          },
                          onError: (error) => {
                            // handle ???
                            setProcessing(false);
                          }
                        });
                        
                      }}
                    >
                      {!processing ?
                        <Pane>
                          {I18n.get("Match fund")}
                          {totalOfSelectedTransactions ?
                            <Fragment>
                              {" "}
                              <FormattedCurrencyNumber removerDecimalZeros={false} number={totalOfSelectedTransactions} currency={localeState.currency} fontSize={20} fontColor={"#FFFFFF"} />
                            </Fragment>
                            
                          : null }
                        </Pane>
                      :
                        <Fragment >
                          <Spinner marginRight={8} flex="none" size={24} />
                          <Pane >
                            {`${I18n.get('Processing')}...`}
                          </Pane>
                        </Fragment>
                      }
                    </Button>
                  </Pane>
                  

                  <Pane paddingX={10} paddingBottom={10} marginTop={12} >
                    {totalOfSelectedTransactions > userDetails.balance.available ?
                      <Pane textAlign="center" marginTop={8} className="noselect" >
                        <Text fontSize={12} fontStyle="italic"  color="#EC4C47" >{
                          `${I18n.get("Insufficient funds. Your balance:")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)}`
                        }</Text>
                      </Pane>
                    :
                      <Fragment>
                        <Pane textAlign="center" marginTop={8} className="noselect" >
                          <Text fontSize={12} fontStyle="italic"  color="#7B8B9A" >{
                            `${handleFormattedCurrencyNumber(totalOfSelectedTransactions, localeState.currency)} ${I18n.get("will be deducted from your balance of")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)}.`
                          }</Text>
                        </Pane>
                      </Fragment>
                    }
                  </Pane>

                </Pane>
              </Pane>

              <Pane padding={0} height={0} >
                {/* <button style={{fontSize: "1rem"}}
                  onClick={() => {
                    close();
                  }}
                >Close modal</button> */}
              </Pane>

            </Pane>
          )}
          
        </ModalResponsive>
        

      </Pane>
    </Pane>
  );
}

const AccountRecurringDonations = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountRecurringDonations - ${countRef.current}`);
    return () => {
      console.log("AccountRecurringDonations - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: userDetails?.publicFaceId, // ???
      type: "donationInitiation",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          // console.log("matchFunding updatedResultsList", updatedResultsList);

          // dispatch(updateProfileDetails({
          //   history: updatedProfileHistory,
          // }));

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);


  if (!localLoadState.results || localLoadState.results?.length === 0) {
    return null;
  }

  return (
    <Pane {...rest}>
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Recurring donations')}</Strong>
        {/* {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null } */}
      </Pane>
      <Pane marginTop={10} >
      <Table is={"table"} width={"100%"} >

        <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >

          <Table.Row is={"tr"} width={"100%"} style={{border: "none"}} >
            {/* <Table.SearchHeaderCell /> */}

            <Table.TextHeaderCell is={"th"} textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
              }}
              paddingX={5}
              flex="none"
              width={26}
            >{I18n.get("frequency")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"} textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("Date")}</Table.TextHeaderCell>
            
            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              flex="none"
              textProps={{
                color: "#425A70",
                fontSize: 0,
              }}
              padding={0}
            >{
              I18n.get("Time")
            }</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("Recipient")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              flex="none"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
                width: 0,
              }}
              padding={0}
            >{I18n.get("username")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="right"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("Amount")}</Table.TextHeaderCell>
            
            <Table.TextHeaderCell is={"th"}
              flex="none"
              textAlign="right"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
                width: 0,
              }}
              padding={0}
            >{
              I18n.get("Gift Aid")
            }</Table.TextHeaderCell>

          </Table.Row>
        </Table.Head>

        {!localLoadState.total || localLoadState?.error ?
          localLoadState?.error ?
            <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
              <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
            </Pane>
          :
            localLoadState.total === 0 ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
              </Pane>
            :
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Spinner size={24} />
              </Pane>
        :
          <InfiniteScroll
            dataLength={localLoadState.results?.length}
            next={fetchMoreData}
            hasMore={localLoadState.results?.length < localLoadState.total}
            scrollThreshold={0.6}
            // scrollableTarget="scrollableDiv"
            height={240}
            // loader={<h4>Loading...</h4>}
          >
            <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
              {localLoadState.results?.map((transaction) => (
                <Table.Row is={"tr"} key={transaction.publicTransactionId}
                  width={"100%"} 
                  paddingX={8}
                  isSelectable
                  // isSelectable
                  // onSelect={() => alert(transaction.name)}
                >

                  <Table.TextCell is={"td"} textAlign="left"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1,
                    }}
                    flex="none"
                    paddingX={5}
                  >{
                    <Fragment>
                      <Text height={0} display="flex" fontSize={0} color="#7B8B9A">{I18n.get(transaction.frequency)}</Text>
                      <Pane display="flex" alignItems="center" >
                        {transaction.frequency === "monthly" ? <UpdatedIcon size={16} /> : <ArrowTopRightIcon size={16} />}
                      </Pane>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell
                    is={"td"}
                    textAlign="left"
                    paddingX={0}
                    marginX={4}
                  >{
                    <Fragment>
                      <Strong fontSize={14} color="#283655" >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                      }</Strong>
                      <Text
                        is={"td"}
                        display={"block"}
                        lineHeight={"inherit"}
                        textAlign="left"
                        color={"#7B8B9A"}
                        fontSize={12}
                        padding={0}
                      >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                      }</Text>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell is={"td"} textAlign="left"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1.1,
                    }}
                    paddingX={5}
                  >{
                    <Fragment>
                      <Strong fontSize={16} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                      <Table.TextCell is={"td"}
                        textAlign="left"
                        textProps={{
                          color: "#7B8B9A",
                          fontSize: 14,
                          lineHeight: 1.3,
                        }}
                        paddingX={0}
                      >{transaction.toPublicFaceId}</Table.TextCell>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell is={"td"} textAlign="right"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1.1,
                    }}
                    paddingX={5}
                  >{
                    <Fragment>

                      <Strong fontSize={16} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>

                      {transaction?.giftAidDetails?.hasBeenDeclared ?

                        <Pane display="flex" justifyContent="right" >
                          <Table.TextCell is={"td"} 
                            textProps={{
                              color: "#7B8B9A",
                              fontSize: 14,
                              lineHeight: 1.3,
                            }}
                            paddingX={0}
                            flex="none"
                          >{
                            <Fragment>
                              <Text fontSize={14} color="#7B8B9A">{"+"}</Text>
                              {handleFormattedCurrencyNumber(transaction?.giftAidDetails?.amount || 0, transaction?.giftAidDetails?.currency || transaction.currency || localeState.currency, false, false)}
                            </Fragment>
                          }</Table.TextCell>
                          
                          <Table.TextCell is={"td"} 
                            textProps={{
                              color: "#7B8B9A",
                              fontSize: 14,
                              lineHeight: 1.3,
                            }}
                            marginLeft={4}
                            paddingX={0}
                            flex="none"
                          >{
                            <Fragment>
                              <Text fontSize={14} color="#7B8B9A">{`${I18n.get("Gift Aid")}`}</Text>
                            </Fragment>
                          }</Table.TextCell>
                        </Pane>

                      :

                      null // addGift Aid retrospectively ???

                      }

                    </Fragment>

                  }</Table.TextCell>

                </Table.Row>
              ))}
              {localLoadState.results?.length < localLoadState.total ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                  <Spinner size={24} />
                </Pane>
              : null }
            </Table.Body>
          </InfiniteScroll>
        }

        <Table.Head is={"tfoot"}
          height={"auto"}
          style={{
            border: "none",
            background: "none",
          }}
        >

          {/* <Pane textAlign="center" marginTop={16} className="noselect" >
            <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
              disabled={false}
              onClick={() => {
                console.log('Load more...');
                console.log("localLoadState", localLoadState);
                setTriggerLoad(true);
              }}
            >
              {`${I18n.get('Load more')}...`}
            </Button>
          </Pane> */}

          <Pane paddingY={5} >
            <Text fontSize={14} color="#7B8B9A">{`${localLoadState.results?.length}/${localLoadState.total} results`}</Text>
          </Pane>
        </Table.Head>

        </Table>
      </Pane>
    </Pane>
  );
}

const AccountDonationsMade = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountDonationsMade - ${countRef.current}`);
    return () => {
      console.log("AccountDonationsMade - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: userDetails?.publicFaceId, // ???
      type: "donation",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };
          
          return updatedLocalLoadState;

        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();

  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);

  if (!localLoadState.results || localLoadState.results?.length === 0) {
    return null;
  }

  return (
    <Pane {...rest} >
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Donations made')}</Strong>
      </Pane>
      <Pane marginTop={10} >

        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
              {/* <Table.SearchHeaderCell /> */}

              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Date")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Time")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("To")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Username")} />

              <InvisibleTextHeaderCell text={I18n.get("User ID")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                {localLoadState.results?.map((transaction) => (
                  <Table.Row is={"tr"} key={transaction.publicTransactionId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    // isSelectable
                    // onSelect={() => alert(transaction.name)}
                  >
                    
                    <InvisibleTextCell text={transaction.publicTransactionId} />

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell is={"td"} textAlign="left"
                      paddingX={4}
                      textProps={{
                        
                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                        <Table.TextCell is={"td"} textAlign="left" paddingX={0}
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                        >{transaction.toPublicFaceId}</Table.TextCell>
                      </Fragment>
                    }</Table.TextCell>

                    <InvisibleTextCell text={transaction.toPublicFaceId} />

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>

                        {transaction?.giftAidDetails?.hasBeenDeclared ?

                          <Pane display="flex" justifyContent="right" >
                            <Table.TextCell is={"td"} 
                              textProps={{
                                color: "#7B8B9A",
                                fontSize: 14,
                                lineHeight: 1.3,
                              }}
                              paddingX={0}
                              flex="none"
                            >{
                              <Fragment>
                                <Text fontSize={14} color="#7B8B9A">{"+"}</Text>
                                {handleFormattedCurrencyNumber(transaction?.giftAidDetails?.amount || 0, transaction?.giftAidDetails?.currency || transaction.currency || localeState.currency, false, false)}
                              </Fragment>
                            }</Table.TextCell>
                            
                            <Table.TextCell is={"td"} 
                              textProps={{
                                color: "#7B8B9A",
                                fontSize: 14,
                                lineHeight: 1.3,
                              }}
                              marginLeft={4}
                              paddingX={0}
                              flex="none"
                            >{
                              <Fragment>
                                <Text fontSize={14} color="#7B8B9A">{`${I18n.get("Gift Aid")}`}</Text>
                              </Fragment>
                            }</Table.TextCell>
                          </Pane>

                        :

                        null // addGift Aid retrospectively ???

                        }

                      </Fragment>

                    }</Table.TextCell>

                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}

            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}
          </Table.Head>

        </Table>
        
      </Pane>
    </Pane>
  );
}

const AccountDonationRecipients = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountDonationRecipients - ${countRef.current}`);
    return () => {
      console.log("AccountDonationRecipients - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: userDetails?.publicFaceId, // ???
      type: "donationRecipients",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };
          
          return updatedLocalLoadState;

        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();

  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);


  // check of rights ???
  if ((!localLoadState.results || localLoadState.results?.length < 1)
    && userDetails.tags.filter(tag => ["charity"].includes(tag)).length === 0
  ) {
    return null;
  }

  return (
    <Pane {...rest} >
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Recipients')}</Strong>
      </Pane>
      <Pane marginTop={10} >

        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
              {/* <Table.SearchHeaderCell /> */}

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Recipient")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Username")} />

              <InvisibleTextHeaderCell text={I18n.get("User ID")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Donations")
              }</Table.TextHeaderCell>

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Total")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                {localLoadState.results?.map((transaction) => (
                  <Table.Row is={"tr"} key={transaction.toPublicFaceId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    // isSelectable
                    // onSelect={() => alert(transaction.name)}
                  >

                    <Table.TextCell is={"td"} textAlign="left"
                      paddingX={4}
                      textProps={{
                        
                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                        <Table.TextCell is={"td"} textAlign="left" paddingX={0}
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                        >{transaction.toPublicFaceId}</Table.TextCell>
                      </Fragment>
                    }</Table.TextCell>

                    <InvisibleTextCell text={transaction.toPublicFaceId} />

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655">{transaction.count}</Strong>

                      </Fragment>

                    }</Table.TextCell>

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>

                        <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>

                        {false ?

                          null

                        : null }

                      </Fragment>

                    }</Table.TextCell>

                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}

            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}
          </Table.Head>

        </Table>
        
      </Pane>
    </Pane>
  );
}

const AccountDonationsReceived = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountDonationsReceived - ${countRef.current}`);
    return () => {
      console.log("AccountDonationsReceived - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: null,
      viaPublicFaceId: null,
      type: "donation",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {

          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });

  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);

  if (!localLoadState.results || localLoadState.results?.length < 1) {
    return null;
  }

  return (
    <Pane {...rest}>
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Donations received')}</Strong>
      </Pane>
      <Pane marginTop={10} >

        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
              {/* <Table.SearchHeaderCell /> */}

              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Date")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Time")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("From")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Username")} />

              <InvisibleTextHeaderCell text={I18n.get("User ID")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body
                is={"tbody"}
                id="scrollableDiv"
                overflow={"scroll"}
                display={"block"}
              >
                {localLoadState.results?.map((transaction) => (

                  <Table.Row is={"tr"} key={transaction.publicTransactionId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    onSelect={() => {
                      // alert(transaction.publicTransactionId);
                      const tempUrl = generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: transaction.publicTransactionId});
                      window.open(tempUrl, '_blank');
                    }}
                  >

                    <InvisibleTextCell text={transaction.publicTransactionId} />

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell is={"td"} textAlign="left"
                      paddingX={4}
                      textProps={{
                        
                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{transaction.fromFace?.name || transaction.fromPublicFaceId}</Strong>
                        <Table.TextCell is={"td"} textAlign="left" paddingX={0}
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                        >{transaction.fromPublicFaceId}</Table.TextCell>
                      </Fragment>
                    }</Table.TextCell>

                    <InvisibleTextCell text={transaction.fromPublicFaceId} />

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>
                        {/* <Table.TextCell is={"td"}
                          textAlign="left"
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                          paddingX={0}
                        >{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")}</Table.TextCell> */}
                      </Fragment>
                    }</Table.TextCell>
                    
                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }
          

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}
            
            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}

          </Table.Head>

        </Table>
      </Pane>
    </Pane>
  );
}

const InvisibleTextHeaderCell = ({text}) => {

  return (
    <Table.TextHeaderCell
      is={"th"}
      flex="none"
      padding={0}
      width={0}
      textProps={{
        color: "#7B8B9A",
        fontSize: 0,
      }}
    >{
      text
    }</Table.TextHeaderCell>
  );
}

const InvisibleTextCell = ({text}) => {

  return (
    <Table.TextCell
      is={"td"}
      overflow={"visible"}
      flex="none"
      padding={0}
      width={0}
      textProps={{
        overflow: "visible",
        color: "#7B8B9A",
        fontSize: 0,
      }}
    >{
      text
    }</Table.TextCell>
  );
}

const AccountProvisionsMade = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountProvisionsMade - ${countRef.current}`);
    return () => {
      console.log("AccountProvisionsMade - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();

  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      // toPublicFaceId: userDetails?.publicFaceId,
      fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: userDetails?.publicFaceId, // ???
      type: "provision", // "provisionByProvider..." ???
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        console.log("provision searchResult", searchResult);
        setLocalLoadState((prev) => {

          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
    
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);


  if ((!localLoadState.results || localLoadState.results?.length < 1)
    && userDetails.tags.filter(tag => ["charity", "retailer"].includes(tag)).length === 0
  ) {
    return null;
  }


  return (
    <Pane {...rest} >
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Provisions made')}</Strong>
        {/* {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null } */}
      </Pane>
      <Pane marginTop={10} >

        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
              {/* <Table.SearchHeaderCell /> */}

              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Date")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Time")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("To")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Username")} />

              <InvisibleTextHeaderCell text={I18n.get("User ID")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body
                is={"tbody"}
                id="scrollableDiv"
                overflow={"scroll"}
                display={"block"}
              >
                {localLoadState.results?.map((transaction) => (

                  <Table.Row is={"tr"} key={transaction.publicTransactionId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    onSelect={() => {
                      // alert(transaction.publicTransactionId);
                      const tempUrl = generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: transaction.publicTransactionId});
                      window.open(tempUrl, '_blank');
                    }}
                  >

                    <InvisibleTextCell text={transaction.publicTransactionId} />

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell is={"td"} textAlign="left"
                      paddingX={4}
                      textProps={{
                        
                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                        <Table.TextCell is={"td"} textAlign="left" paddingX={0}
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                        >{transaction.toPublicFaceId}</Table.TextCell>
                      </Fragment>
                    }</Table.TextCell>

                    <InvisibleTextCell text={transaction.toPublicFaceId} />

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>
                        {/* <Table.TextCell is={"td"}
                          textAlign="left"
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                          paddingX={0}
                        >{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")}</Table.TextCell> */}
                      </Fragment>
                    }</Table.TextCell>
                    
                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }
          

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}
            
            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}

          </Table.Head>

        </Table>
      </Pane>
    </Pane>
    
    
  );
}

const AccountMatchFundingSettings = ({ ...rest }) => {

  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  // default value is "off" at face.settings.matchDonations
  const [stateMatchDonations, setStateMatchDonations] = useState({
    options: [
      { label: I18n.get('Off'), value: "off"},
      { label: I18n.get('On'), value: "on"},
    ],
    value: userDetails.settings.matchDonations || "off",
  });
  const [processingMatchDonations, setProcessingMatchDonations] = useState(false);

 
  const handleToggleMatchDonations = (e) => {
    // console.log("Radio e", e);
    setProcessingMatchDonations(true);
    setStateMatchDonations({...stateMatchDonations, value: e.target.value});

    handleUpdateUserDetails({
      userDetailsToUpdate: {
        settings: {
          [e.target.name]: e.target.value,
        },
      },
      onSuccess: () => {
        setProcessingMatchDonations(false);
      },
      onError: (error) => {
        console.error("within handleUpdateUserDetails", error);
        // setAccountUpdateError(error);
        setProcessingMatchDonations(false);
      },
    });
  }

  const [showDialogAddFundToBalance, setShowDialogAddFundToBalance] = useState(false);

  return (
    <Pane {...rest}>
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Match donations')}</Strong>
        {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
      </Pane>
      <Pane marginTop={10} >
        {stateMatchDonations.options.map((option, index) => (
          <DynamicRadioButton name={"matchDonations"}
            key={index}
            disabled={processingMatchDonations}
            checked={option.value === stateMatchDonations.value}
            value={option.value}
            onChange={(e) => {handleToggleMatchDonations(e)}}
            label={option.label}
          />
        ))}
        <Pane marginTop={8} >
          <Paragraph fontSize={14} color="#7B8B9A">{I18n.get('When a donation is made via your page to your a page that you follow, it will be displayed as "matched"')}</Paragraph>
        </Pane>

        <Pane marginTop={16}>
          <Pane >
            <Strong fontSize={16} color="#283655" >{I18n.get('Match funding due')}</Strong>
          </Pane>
          <FormattedCurrencyNumber number={userDetails.balance.matchFundingDue} currency={userDetails.balance.currency} fontSize={24} />
          <Pane marginTop={0} >
            <Paragraph fontSize={14} color="#7B8B9A">{I18n.get('Total of matched donations yet to be funded')}</Paragraph>
          </Pane>
        </Pane>

        <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
          <Pane flex="none" marginRight={36} >
            <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
              disabled={userDetails.balance.matchFundingDue <= 0}
              onClick={() => {
                // refresh to get accurate matchFundingDue, needs refactoring
                // ???
                setShowDialogAddFundToBalance(true);
              }}
            >
              {I18n.get('Add funds')}
            </Button>
          </Pane>

          {/*
          <Pane flex={1} textAlign="center" >
            <Button style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={30} appearance="minimal"
              disabled={true}
              onClick={() => {}}
            >
              {"tXXX"}
            </Button>
          </Pane>
          */}

          <DialogAddFundsToBalance isShown={showDialogAddFundToBalance} onClose={() => {setShowDialogAddFundToBalance(false)}} />

        </Pane>
        
      </Pane>
      
    </Pane>
  );
}

const AccountProvisionsReceived = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountProvisionsReceived - ${countRef.current}`);
    return () => {
      console.log("AccountProvisionsReceived - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();

  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      toPublicFaceId: userDetails?.publicFaceId,
      // fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: userDetails?.publicFaceId, // ???
      type: "provision", // "provisionByProvider..." ???
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        
        setLocalLoadState((prev) => {

          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });

  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);


  // not a recipient
  if (!localLoadState.results || localLoadState.results?.length < 1) {
    if (userDetails?.rights?.balancePay === true && userDetails?.settings?.faceLinked === true) {
      // a recipient
    }
    else {
      return null;
    }
  }
  


  return (

    <Pane {...rest} >
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Payments made')}</Strong>
        {/* {processingMatchDonations ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null } */}
      </Pane>
      <Pane marginTop={10} >

        <Table
          is={"table"}
          width={"100%"}
          style={{
            borderCollapse: "collapse",
          }}
        >

          <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
            <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
              {/* <Table.SearchHeaderCell /> */}

              <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />

              <Table.TextHeaderCell
                is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Date")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Time")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="left"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("To")
              }</Table.TextHeaderCell>

              <InvisibleTextHeaderCell text={I18n.get("Username")} />

              <InvisibleTextHeaderCell text={I18n.get("User ID")} />

              <Table.TextHeaderCell is={"th"}
                textAlign="right"
                textProps={{
                  color: "#425A70",
                  fontSize: 14,
                }}
                paddingX={0}
                marginX={4}
              >{
                I18n.get("Amount")
              }</Table.TextHeaderCell>

            </Table.Row>
          </Table.Head>

          {!localLoadState.total || localLoadState?.error ?
            localLoadState?.error ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
              </Pane>
            :
              localLoadState.total === 0 ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
                </Pane>
              :
                <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                  <Spinner size={24} />
                </Pane>
          :
            <InfiniteScroll
              dataLength={localLoadState.results?.length}
              next={fetchMoreData}
              hasMore={localLoadState.results?.length < localLoadState.total}
              scrollThreshold={0.6}
              // scrollableTarget="scrollableDiv"
              height={240}
              // loader={<h4>Loading...</h4>}
            >
              <Table.Body
                is={"tbody"}
                id="scrollableDiv"
                overflow={"scroll"}
                display={"block"}
              >
                {localLoadState.results?.map((transaction) => (

                  <Table.Row is={"tr"} key={transaction.publicTransactionId}
                    width={"100%"} 
                    paddingX={8}
                    isSelectable
                    onSelect={() => {
                      // alert(transaction.publicTransactionId);
                      const tempUrl = generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: transaction.publicTransactionId});
                      window.open(tempUrl, '_blank');
                    }}
                  >

                    <InvisibleTextCell text={transaction.publicTransactionId} />

                    <Table.TextCell
                      is={"td"}
                      textAlign="left"
                      paddingX={0}
                      marginX={4}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655" >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                        }</Strong>
                        <Text
                          is={"td"}
                          display={"block"}
                          lineHeight={"inherit"}
                          textAlign="left"
                          color={"#7B8B9A"}
                          fontSize={12}
                          padding={0}
                        >{
                          formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                        }</Text>
                      </Fragment>
                    }</Table.TextCell>

                    <Table.TextCell is={"td"} textAlign="left"
                      paddingX={4}
                      textProps={{
                        
                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{transaction.fromFace?.name || transaction.fromPublicFaceId}</Strong>
                        <Table.TextCell is={"td"} textAlign="left" paddingX={0}
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                        >{transaction.fromPublicFaceId}</Table.TextCell>
                      </Fragment>
                    }</Table.TextCell>

                    <InvisibleTextCell text={transaction.fromPublicFaceId} />

                    <Table.TextCell is={"td"}
                      textAlign="right"
                      paddingX={4}
                      textProps={{

                      }}
                    >{
                      <Fragment>
                        <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>
                        {/* <Table.TextCell is={"td"}
                          textAlign="left"
                          textProps={{
                            color: "#7B8B9A",
                            fontSize: 14,
                          }}
                          paddingX={0}
                        >{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")}</Table.TextCell> */}
                      </Fragment>
                    }</Table.TextCell>
                    
                  </Table.Row>
                ))}
                {localLoadState.results?.length < localLoadState.total ?
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                    <Spinner size={24} />
                  </Pane>
                : null }
              </Table.Body>
            </InfiniteScroll>
          }
          

          <Table.Head is={"tfoot"}
            height={"auto"}
            style={{
              border: "none",
              background: "none",
            }}
          >
          
            {/* <Pane textAlign="center" marginTop={16} className="noselect" >
              <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={false}
                onClick={() => {
                  console.log('Load more...');
                  console.log("localLoadState", localLoadState);
                  setTriggerLoad(true);
                }}
              >
                {`${I18n.get('Load more')}...`}
              </Button>
            </Pane> */}
            
            {!localLoadState.total ? null :
            <Text fontSize={12} color={"#7B8B9A"}>
              {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
            </Text>}

          </Table.Head>

        </Table>
      </Pane>
    </Pane>
    
    
  );
}

const AccountPayouts = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountPayouts - ${countRef.current}`);
    return () => {
      console.log("AccountPayouts - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      toPublicFaceId: userDetails?.publicFaceId,
      // fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: null, // ???
      type: "payout",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          // console.log("matchFunding updatedResultsList", updatedResultsList);

          // dispatch(updateProfileDetails({
          //   history: updatedProfileHistory,
          // }));

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);

  const [addNewPayout, setAddNewPayout] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [payoutToPublicFaceId, setPayoutToPublicFaceId] = useState();
  const [payoutAmount, setPayoutAmount] = useState();
  const [doNotNotify, setDoNotNotify] = useState(false);

  const handleRequestPayout = async() => {

    setProcessing(true);

    // try {
    //   const response = await API.graphql({
    //     query: mutations.createPayout,
    //     variables: {
    //       // toPublicFaceId: payoutToPublicFaceId,
    //       amount: payoutAmount,
    //       currency: localeState.currency,
    //       notify: !doNotNotify,
    //     },
    //     authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
    //   });

    //   let newTransaction = response.data.createPayout;
      
    //   setTransactionList((current) => ([...current, newTransaction]));
    //   setLocalLoadState({
    //     ...localLoadState,
    //     total: localLoadState.total + 1,
    //   });

    //   setAddNewPayout(false);
    //   setProcessing(false);

    // }
    // catch(error) {
    //   console.error("within createPayout", error);
    //   // handle ???
    //   setProcessing(false);
    // }
  };

  if ((!localLoadState.results || localLoadState.results?.length === 0) && !userDetails.tags?.includes("retailer") && !userDetails.tags?.includes("charity") ) {
    return null;
  }

  return (
    <Pane {...rest} >
    
      <Pane alignItems="center" display="flex" marginTop={20} minHeight={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Payouts')}</Strong>
        {processing ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
        {/* {addNewPayout ? null :
          <Button flex="none" marginLeft={"auto"} marginRight={0} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" className="noselect"
            disabled={processing}
            onClick={() => {
              setAddNewPayout(true);
            }}
          >
            {I18n.get('Pay out funds')}
          </Button>
        } */}
      </Pane>

      <Text fontSize={12} color={"#7B8B9A"}>
        {I18n.get("Funds paid to your bank account")}
      </Text>

      {!addNewPayout ? null :
        <Fragment>
          <Pane height={20} ></Pane>

          <DynamicInputField
            label={I18n.get('To publicFaceId')}
            inputName="charity-number"
            type="text"
            disabled={processing}
            placeholder={`${I18n.get('publicFaceId')}...`}
            value={null}
            onChange={(value) => {
              if (value) {
                let temp = value.trim();
                if (temp.length > 30) {
                  // setAccountCharityNoInvalidMessage(I18n.get('Too long.'));
                }
                else {
                  // setAccountCharityNoInvalidMessage();
                }
              }
              setPayoutToPublicFaceId(value);
            }}
            onBlur={(e) => {}}
            // messageWhenInvalid={""}
          />



          <Fragment>
            <Pane marginBottom={20} alignItems="baseline" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
                <Strong fontWeight={500} fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Amount')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <CurrencyNumberInput
                  disabled={processing}
                  placeholder={`${I18n.get('Amount')}...`}
                  value={payoutAmount}
                  currency={localeState.currency}
                  onChange={(values) => {
                    let temp = values.value;
                    let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                    setPayoutAmount(newAmount);
                  }}
                />
              </Pane>
            </Pane>
          </Fragment>

          <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        
            <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

            <Pane flex={1} flexBasis={242} maxWidth={390} >
              {processing ?
                <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
                  <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                </Pane>
              :
                <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap">
                  <Pane flex="none" marginRight={36} >
                    <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                      disabled={processing}
                      onClick={() => {
                        handleRequestPayout();
                      }}
                    >
                      {I18n.get('Create')}
                    </Button>
                  </Pane>

                </Pane>
              }
            </Pane>
          </Pane>
        </Fragment>
      }


      <Table
        marginTop={8}
        is={"table"}
        width={"100%"}
        style={{
          borderCollapse: "collapse",
        }}
      >
        <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
          <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
            {/* <Table.SearchHeaderCell /> */}

            <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />

            <Table.TextHeaderCell
              is={"th"}
              textAlign="left"
              textProps={{
                color: "#425A70",
                fontSize: 14,
              }}
              paddingX={0}
              marginX={4}
            >{
              I18n.get("Date")
            }</Table.TextHeaderCell>

            <InvisibleTextHeaderCell text={I18n.get("Time")} />

            <Table.TextHeaderCell is={"th"}
              textAlign="right"
              textProps={{
                color: "#425A70",
                fontSize: 14,
              }}
              paddingX={0}
              marginX={4}
            >{
              I18n.get("Amount")
            }</Table.TextHeaderCell>

          </Table.Row>
        </Table.Head>

        {!localLoadState.total || localLoadState?.error ?
          localLoadState?.error ?
            <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
              <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
            </Pane>
          :
            localLoadState.total === 0 ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
              </Pane>
            :
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Spinner size={24} />
              </Pane>
        :
          <InfiniteScroll
            dataLength={localLoadState.results?.length}
            next={fetchMoreData}
            hasMore={localLoadState.results?.length < localLoadState.total}
            scrollThreshold={0.6}
            // scrollableTarget="scrollableDiv"
            height={240}
            // loader={<h4>Loading...</h4>}
          >
            <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
              {localLoadState.results?.map((transaction) => (
                <Table.Row is={"tr"} key={transaction.publicTransactionId}
                  width={"100%"} 
                  paddingX={8}
                  isSelectable
                  // isSelectable
                  // onSelect={() => alert(transaction.name)}
                >
                
                
                  <InvisibleTextCell text={transaction.publicTransactionId} />

                  <Table.TextCell
                    is={"td"}
                    textAlign="left"
                    paddingX={0}
                    marginX={4}
                  >{
                    <Fragment>
                      <Strong fontSize={14} color="#283655" >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                      }</Strong>
                      <Text
                        is={"td"}
                        display={"block"}
                        lineHeight={"inherit"}
                        textAlign="left"
                        color={"#7B8B9A"}
                        fontSize={12}
                        padding={0}
                      >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                      }</Text>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell
                    is={"td"}
                    textAlign="right"
                    paddingX={4}
                    textProps={{
                      
                    }}
                  >{
                    <Fragment>

                      <Strong fontSize={14} color="#283655" >
                        {handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}
                      </Strong>
                    </Fragment>
                  }</Table.TextCell>

                </Table.Row>
              ))}
              {localLoadState.results?.length < localLoadState.total ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                  <Spinner size={24} />
                </Pane>
              : null }
            </Table.Body>
          </InfiniteScroll>
        }

        <Table.Head is={"tfoot"}
          height={"auto"}
          style={{
            border: "none",
            background: "none",
          }}
        >

          {/* <Pane textAlign="center" marginTop={16} className="noselect" >
            <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
              disabled={false}
              onClick={() => {
                console.log('Load more...');
                console.log("localLoadState", localLoadState);
                setTriggerLoad(true);
              }}
            >
              {`${I18n.get('Load more')}...`}
            </Button>
          </Pane> */}

          {!localLoadState.total ? null :
          <Text fontSize={12} color={"#7B8B9A"}>
            {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
          </Text>}

        </Table.Head>

      </Table>
    </Pane>
    
  );
}

const AccountTopups = ({ ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountTopups - ${countRef.current}`);
    return () => {
      console.log("AccountTopups - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  
  const handleGetTransactions = useGetTransactions();
  
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const fetchMoreData = useCallback(() => {
    
    handleGetTransactions({
      toPublicFaceId: userDetails?.publicFaceId,
      // fromPublicFaceId: userDetails?.publicFaceId,
      // viaPublicFaceId: null, // ???
      type: "topUp",
      currency: localeState.currency,
      locale: localeState.locale,
      nextToken: localLoadState.nextToken,
      onSuccess: (searchResult) => {
        setLocalLoadState((prev) => {
          let updatedResultsList = [...prev?.results || [], ...searchResult.results];

          let updatedLocalLoadState = {
            ...prev,
            results: updatedResultsList,
            total: searchResult.total,
            nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
            error: null,
          };

          // console.log("matchFunding updatedResultsList", updatedResultsList);

          // dispatch(updateProfileDetails({
          //   history: updatedProfileHistory,
          // }));

          return updatedLocalLoadState;
        });
      },
      onError: (error) => {
        setLocalLoadState((prev) => ({
          ...prev,
          error: error,
        }));
      },
    });
  }, [handleGetTransactions, localLoadState.nextToken, localeState.currency, localeState.locale, userDetails?.publicFaceId]);
  
  const publicFaceIdRef = useRef();
  
  useEffect(() => {
    // refresh localLoadState
    if (userDetails?.publicFaceId && publicFaceIdRef.current !== userDetails?.publicFaceId) {
      setLocalLoadState({
        results: null,
        total: null,
        nextToken: null,
        error: null,
      });
      publicFaceIdRef.current = userDetails?.publicFaceId;
      fetchMoreData();
    }
  }, [fetchMoreData, userDetails?.publicFaceId]);
  

  const [processing, setProcessing] = useState(false);

  if (!localLoadState.results || localLoadState.results?.length === 0) {
    return null;
  }

  return (
    <Pane {...rest} >
    
      <Pane alignItems="center" display="flex" marginTop={20} minHeight={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Topups')}</Strong>
        {processing ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
        {/* {addNewPayout ? null :
          <Button flex="none" marginLeft={"auto"} marginRight={0} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" className="noselect"
            disabled={processing}
            onClick={() => {
              setAddNewPayout(true);
            }}
          >
            {I18n.get('Pay out funds')}
          </Button>
        } */}
      </Pane>
      
      <Table
        marginTop={8}
        is={"table"}
        width={"100%"}
        style={{
          borderCollapse: "collapse",
        }}
      >
        <Table.Head is={"thead"} height={"auto"} paddingX={8} style={{border: "none"}} >
          <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >
            {/* <Table.SearchHeaderCell /> */}

            <InvisibleTextHeaderCell text={I18n.get("Transaction ID")} />            

            <Table.TextHeaderCell
              is={"th"}
              textAlign="left"
              textProps={{
                color: "#425A70",
                fontSize: 14,
              }}
              paddingX={0}
              marginX={4}
            >{
              I18n.get("Date")
            }</Table.TextHeaderCell>


            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              flex="none"
              textProps={{
                color: "#425A70",
                fontSize: 0,
              }}
              padding={0}
            >{
              I18n.get("Time")
            }</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="right"
              textProps={{
                color: "#425A70",
                fontSize: 14,
              }}
              paddingX={0}
              marginX={4}
            >{
              I18n.get("Amount")
            }</Table.TextHeaderCell>

          </Table.Row>
        </Table.Head>

        {!localLoadState.total || localLoadState?.error ?
          localLoadState?.error ?
            <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
              <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
            </Pane>
          :
            localLoadState.total === 0 ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
              </Pane>
            :
              <Pane display="flex" alignItems="center" justifyContent="center" height={120}>
                <Spinner size={24} />
              </Pane>
        :
          <InfiniteScroll
            dataLength={localLoadState.results?.length}
            next={fetchMoreData}
            hasMore={localLoadState.results?.length < localLoadState.total}
            scrollThreshold={0.6}
            // scrollableTarget="scrollableDiv"
            height={240}
            // loader={<h4>Loading...</h4>}
          >
            <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
              {localLoadState.results?.map((transaction) => (
                <Table.Row is={"tr"} key={transaction.publicTransactionId}
                  width={"100%"} 
                  paddingX={8}
                  isSelectable
                  // isSelectable
                  // onSelect={() => alert(transaction.name)}
                >

                  <Table.TextCell
                    is={"td"}
                    flex="none"
                    textAlign="right"
                    paddingX={0}
                    marginX={0}
                  >{
                    <Fragment>
                      
                      <Text
                        is={"td"}
                        height={0}
                        width={0}
                        display={"block"}
                        lineHeight={"inherit"}
                        textAlign="left"
                        color={"#7B8B9A"}
                        fontSize={0}
                        padding={0}
                      >{
                        transaction.publicTransactionId
                      }</Text>

                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell
                    is={"td"}
                    textAlign="left"
                    paddingX={0}
                    marginX={4}
                  >{
                    <Fragment>
                      <Strong fontSize={14} color="#283655" >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                      }</Strong>
                      <Text
                        is={"td"}
                        display={"block"}
                        lineHeight={"inherit"}
                        textAlign="left"
                        color={"#7B8B9A"}
                        fontSize={12}
                        padding={0}
                      >{
                        formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                      }</Text>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell
                    is={"td"}
                    textAlign="right"
                    paddingX={0}
                    marginX={4}
                    textProps={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      justifyContent: "end",
                    }}
                  >{
                    <Fragment>

                      <Strong fontSize={14} color="#283655" >
                        {handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}
                      </Strong>
                    </Fragment>
                  }</Table.TextCell>

                </Table.Row>
              ))}
              {localLoadState.results?.length < localLoadState.total ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                  <Spinner size={24} />
                </Pane>
              : null }
            </Table.Body>
          </InfiniteScroll>
        }

        <Table.Head is={"tfoot"}
          height={"auto"}
          style={{
            border: "none",
            background: "none",
          }}
        >

          {/* <Pane textAlign="center" marginTop={16} className="noselect" >
            <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
              disabled={false}
              onClick={() => {
                console.log('Load more...');
                console.log("localLoadState", localLoadState);
                setTriggerLoad(true);
              }}
            >
              {`${I18n.get('Load more')}...`}
            </Button>
          </Pane> */}

          {!localLoadState.total ? null :
          <Text fontSize={12} color={"#7B8B9A"}>
            {I18n.get("Showing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
          </Text>}

        </Table.Head>

      </Table>
    </Pane>
    
  );
}

const AccountManageBalance = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountManageBalance - ${countRef.current}`);
    return () => {
      console.log("AccountManageBalance - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  
  const userState = useSelector(state => state.userState);
  const localeState = useSelector(state => state.localeState);
	const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const [processing, setProcessing] = useState(false);
  const [isOpenBalanceTopUpModal, setIsOpenBalanceTopUpModal] = useState(false);

  const minTopupValue = useMemo(() => {
    return (currencies[localeState.currency].minValue * 20);
  }, [localeState.currency]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  const handleTopUpOnComplete = (transactionDetails) => {

    console.log("transactionDetails", transactionDetails);

		batch(() => {
      
      let newHistoryResults = [
        transactionDetails,
        ...userDetails?.history?.results || [],
      ];
  
      dispatch(updateUserState({
        [userState.actAsUser ? "actAsUser" : "user"]: {
          ...userDetails,
          history: {
            results: newHistoryResults,
            total: newHistoryResults.length,
            nextToken: userDetails?.history?.nextToken,
          },
          balance: {
            ...userDetails.balance,
            available: Math.round(((userDetails.balance?.available + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
            topUpsMade: Math.round(((userDetails.balance?.topUpsMade + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
          }
        },
      }));

			dispatch(updateTransactionDetails({
				...transactionDetails,
				localSuccessView: true,
			}));
      
		});
		
	};

	const handleTopupOnError = (error) => {
		console.error("handleTopupOnError", error);
		dispatch(updateTransactionDetails({
			localError: null,
		}));
		setProcessing(false);
	};

  const handleAdminTopUp = useAdminTopUp();

  return (
    <Fragment>

      <Pane marginTop={16} >

        <Pane marginBottom={4} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex="none" >
            <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Balance')}</Strong>
          </Pane>

          <Button flex="none" marginLeft={10} fontSize={14} paddingX={8} height={24} textAlign="center" justifyContent="center" marginRight="auto" className="noselect"
            disabled={false}
            onClick={() => {
              // set new Transaction
              dispatch(setTransactionDetails({
                type: "topUp",
                amount: minTopupValue,
                toPublicFaceId: userDetails?.publicFaceId,
              }));
              setIsOpenBalanceTopUpModal(true);
            }}
          >
            {I18n.get('Top up')}
          </Button>

          <ModalResponsive
            isShown={isOpenBalanceTopUpModal}
            onOpenComplete={() => {}}
            onCloseComplete = {() => {
              setIsOpenBalanceTopUpModal(false);
              // clear Transaction...
              dispatch(setTransactionDetails(null));
              setProcessing(false);
            }}
            // onCancel={(close) => close()}
            title='DialogBalanceTopUp'
            // hasHeader={false}
            // header={}
            hasFooter={false}
            // footer={}
            preventBodyScrolling={true}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            modalStyleProps={{
              // "minHeight": "200px",
            }}
            // width={"auto"} // 350 // "100%"
            // height={"calc(100% - 36px * 2)%"}
            // containerProps={{
            //   "backgroundColor": "transparent",
            //   // "maxWidth": "100%",
            //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
            //   "margin": "auto", // auto
            //   // "overflow": 'hidden',
            // }}
            // contentContainerProps={{
            //   "padding": "0px",
            //   "maxHeight": "75hv",
            // }}
            // styles={{
            //   overlay: {
            //     "background": "#7B8B9A10",
            //     "backdrop-filter": "blur(1px)",
            //   },
            //   // modalContainer: {},
            //   modal: {
            //     "width": "100%",
            //     "max-width": "100%",
            //     "box-shadow": null,
            //     "margin": 0,
            //     "padding": 0,
            //   },
            // }}
            
          >
            
            {({ close }) => (
              <Pane width="100%" height="100%" background="#FFFFFF" borderRadius={10} >

                <DialogHeader
                  headerText={I18n.get('Balance top up')}
                  hideLeftButton={false}
                  hideRightButton={true}
                  leftButtonDisabled={false}
                  rightButtonDisabled={false}
                  leftButtonIcon={null}
                  onLeftButtonClick={() => {
                    close();
                  }}
                  // rightButtonText={I18n.get('Next')}
                  onRightButtonClick={() => {}}
                  paddingX={10}
                  paddingTop={10}
                />

                <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
                  <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                    
                    <Pane paddingX={10} marginTop={12} textAlign="center" >
                      <Text fontSize={14} color="#283655" >{I18n.get('Add funds to your balance.')}</Text>
                    </Pane>

                    <Pane paddingX={10} marginTop={12} textAlign="center" className="noselect">
                      <Strong fontSize={16} color="#425A70" >{I18n.get('Amount')}</Strong>
                    </Pane>

                    <Pane marginTop={10} marginBottom={10} >
                      <NumberPlusMinusPicker
                        step={currencies[localeState.currency].stepValue * 100}
                        value={transactionDetails.amount}
                        onChange={(value) => {
                          dispatch(updateTransactionDetails({
                            amount: value
                          }));
                        }}
                        minValue={minTopupValue}
                        maxValue={null}
                        disabled={processing}
                        disabledMinus={(transactionDetails.amount <= minTopupValue)}
                        disabledPlus={false}
                        hideButtons={false}
                      >
                        {({ value }) => (
                          <FormattedCurrencyNumber isRound={true} textAlign="center" number={value} currency={localeState.currency} fontSize={30} />
                        )}
                      </NumberPlusMinusPicker>
                    </Pane>

                    <Pane paddingX={10} marginTop={16}>
                      <PaymentComponent
                        buttonText={I18n.get('Top up')}
                        suffixAmount={transactionDetails.amount ? true : false}
                        disabled={processing
                          || transactionDetails.amount < minTopupValue || !userDetails
                        }
                        allowToUseUserBallance={false}
                        onProcessing={() => {
                          setProcessing(true);
                        }}
                        onComplete={(transactionDetails) => {
                          handleTopUpOnComplete(transactionDetails);
                          close();
                        }}
                        onError={handleTopupOnError}
                        validationProcess={() => {

                        }}
                      />
                    </Pane>

                    <Pane paddingX={10} paddingBottom={10} marginTop={12} >
                        <Pane textAlign="center" marginTop={8} >
                          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{
                            `${handleFormattedCurrencyNumber(transactionDetails.amount, localeState.currency)} ${I18n.get("will be added to your balance of")} ${handleFormattedCurrencyNumber(userDetails.balance.available, userDetails.balance.currency)}.`
                          }</Text>
                        </Pane>
                    </Pane>

                    {userDetails && userState.user?.rights?.adminPanel && userState.actAsUser ?
                    
                      <Fragment>
                        <Pane marginTop={16} paddingX={10} paddingBottom={10} >

                          <Pane width="100%"  borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />

                          <Button marginTop={10} fontSize={16} padding={8} height={48} className={processing ? null : 'button-green'} width="100%" justifyContent="center"
                            disabled={processing}
                            onClick={() => {
                              setProcessing(true);
                              handleAdminTopUp({
                                toPublicFaceId: userState.actAsUser?.publicFaceId,
                                fromPublicFaceId: userState.user?.publicFaceId,
                                amount: transactionDetails.amount,
                                currency: localeState.currency,
                                onSuccess: (transactionDetails) => {
                                  console.log("transactionDetails after handleAdminTopUp", transactionDetails);
                                  setProcessing(false);
                                  handleTopUpOnComplete(transactionDetails);
                                  close();
                                },
                                onError: (error) => {
                                  setProcessing(false);
                                  // handle ???
                                },
                              });
                            }}
                          >
                            {!processing ?
                              <Pane>
                                {`${I18n.get('Admin proceed')}`}
                              </Pane>
                            :
                              <Fragment >
                                <Spinner marginRight={8} flex="none" size={24} />
                                <Pane >
                                  {`${I18n.get('Processing')}...`}
                                </Pane>
                              </Fragment>
                            }
                          </Button>
                          
                        </Pane>
                      </Fragment>
                    : null }

                  </Pane>
                </Pane>

                <Pane padding={0} height={0} >
                  {/* <button style={{fontSize: "1rem"}}
                    onClick={() => {
                      close();
                    }}
                  >Close modal</button> */}
                </Pane>

              </Pane>
            )}
            
          </ModalResponsive>



        </Pane>

        <FormattedCurrencyNumber number={userDetails.balance.available} currency={userDetails.balance.currency} fontSize={30} />

        <Pane marginTop={10} alignItems="center" display="flex" flexWrap="wrap" >
          <Strong marginRight={10} fontSize={14} color="#283655" >{I18n.get('Currency')}</Strong>
          <PickCurrency hideLabel={true} />
        </Pane>

      </Pane>

      <Fragment>

        <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />

        {userDetails.rights?.adminPanel || userDetails.tags?.filter(tag => ["charity", "retailer"].includes(tag)).length > 0 ?
          <Fragment>
            <Pane marginTop={10}>
              <Strong fontSize={16} color="#283655" >{I18n.get('Funds')}</Strong>
            </Pane>
            <Pane marginTop={2} display="flex" alignItems="flex-end">
              <Pane flex={"auto"}>
                <Text fontSize={16} color="#7B8B9A" >{I18n.get('Available to pay out to your bank account')}</Text>
              </Pane>
              <Pane marginLeft="auto" textAlign="right" >
                <FormattedCurrencyNumber number={userDetails.balance.approved} currency={userDetails.balance.currency} fontSize={18} />
              </Pane>
            </Pane>
            <Pane marginTop={2} display="flex" alignItems="flex-end">
              <Pane flex={"auto"}>
                <Text fontSize={16} color="#7B8B9A" >{I18n.get('Currently on the way to your bank account')}</Text>
              </Pane>
              <Pane marginLeft="auto" textAlign="right" >
                <FormattedCurrencyNumber number={userDetails.balance.beingSent} currency={userDetails.balance.currency} fontSize={18} />
              </Pane>
            </Pane>
            <Pane marginTop={2} display="flex" alignItems="flex-end">
              <Pane >
                <Text fontSize={16} color="#7B8B9A" >{I18n.get('Will be available soon')}</Text>
              </Pane>
              <Pane marginLeft="auto" >
                <FormattedCurrencyNumber number={userDetails.balance.pending} currency={userDetails.balance.currency} fontSize={18} />
              </Pane>
            </Pane>
            <Pane marginTop={4} display="flex" alignItems="flex-end">
              <Pane >
                <Strong fontSize={16} color="#7B8B9A" >{I18n.get('Total')}</Strong>
              </Pane>
              <Pane marginLeft="auto" >
                <FormattedCurrencyNumber number={Math.round(((userDetails.balance.earned) + Number.EPSILON) * 100) / 100} currency={userDetails.balance.currency} fontSize={18} />
              </Pane>
            </Pane>
            <Pane marginTop={4} display="flex" alignItems="flex-end">
              <Pane >
                <Text fontSize={14} color="#7B8B9A" >{I18n.get('Total paid to your bank account')}</Text>
              </Pane>
              <Pane marginLeft="auto" >
                <FormattedCurrencyNumber number={userDetails.balance.paidOut} currency={userDetails.balance.currency} fontSize={16} />
              </Pane>
            </Pane>
          </Fragment>
        : null }
          
        <Pane marginTop={10}>
          <Strong fontSize={16} color="#283655" >{I18n.get('Funds received from')}</Strong>
        </Pane>
        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Donations')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.donationsReceived} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>

        {userDetails.rights?.adminPanel || userDetails.tags.filter(tag => ["charity", "retailer"].includes(tag)).length > 0 ?
          <Pane marginTop={2} display="flex" alignItems="flex-end">
            <Pane >
              <Text fontSize={16} color="#7B8B9A" >{I18n.get('Provisions')}</Text>
            </Pane>
            <Pane marginLeft="auto" >
              <FormattedCurrencyNumber number={userDetails.balance.provisionsReceived} currency={userDetails.balance.currency} fontSize={18} />
            </Pane>
          </Pane>
        : null }

        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Match funding')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.matchFundingReceived} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>

        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Gift Aid')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.giftAidReceived} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>

        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Topups')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.topUpsMade} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>
          

        <Pane marginTop={10}>
          <Strong fontSize={16} color="#283655" >{I18n.get('Funds used on')}</Strong>
        </Pane>
          
        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Donations')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.donationsMade} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>
        
        {userDetails.rights?.adminPanel || userDetails.tags.filter(tag => ["charity", "retailer"].includes(tag)).length > 0 ?
          <Pane marginTop={2} display="flex" alignItems="flex-end">
            <Pane >
              <Text fontSize={16} color="#7B8B9A" >{I18n.get('Provisions')}</Text>
            </Pane>
            <Pane marginLeft="auto" >
              <FormattedCurrencyNumber number={userDetails.balance.provisionsMade} currency={userDetails.balance.currency} fontSize={18} />
            </Pane>
          </Pane>
        : null }

        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Match funding')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.matchFundingMade} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>

        <Pane marginTop={2} display="flex" alignItems="flex-end">
          <Pane >
            <Text fontSize={16} color="#7B8B9A" >{I18n.get('Payments')}</Text>
          </Pane>
          <Pane marginLeft="auto" >
            <FormattedCurrencyNumber number={userDetails.balance.paymentsMade} currency={userDetails.balance.currency} fontSize={18} />
          </Pane>
        </Pane>

      </Fragment>
      
      {/* {userDetails.tags.filter(tag => ["charity", "corporate", "retailer"].includes(tag)).length === 0 ? null :
        <Fragment>
          <AccountMatchFundingSettings marginTop={16} />
        </Fragment>
      } */}

      <AccountTopups marginTop={16} />

      <AccountMatchFundingRequests marginTop={16} />
      
      <AccountRecurringDonations marginTop={16} />

      <AccountDonationsReceived marginTop={16} />

      <AccountGiftAidRequestsIncoming marginTop={16} />

      <AccountDonationsMade marginTop={16} />

      <AccountDonationRecipients marginTop={16} />

      <AccountProvisionsMade marginTop={16} />

      <AccountProvisionsReceived marginTop={16} />

      <AccountPayouts marginTop={16} />

    </Fragment>
  );

}

export default AccountManageBalance;