import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, Redirect, generatePath, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Button, IconButton, CrossIcon, Icon, Paragraph, LockIcon, Strong, Pane } from 'evergreen-ui';

import { I18n } from 'aws-amplify';


import Footer from '../components/Footer';
import ProfileDetails from '../components/ProfileDetails';

import ProfileTabButtons from '../components/ProfileTabButtons';
import ProfileHistoryTab from '../components/ProfileHistoryTab';
import ProfileDashboardTab from '../components/ProfileDashboardTab';


import { useSelector, useDispatch } from 'react-redux';
import { setProfileDetails, setTransactionDetails } from '../services/actions';

const ProfilePrivatePage = () => {

  return (
    <Pane paddingX={10} >
      
      <Pane paddingY={10} >
        <LockIcon display="block"  marginLeft={"auto"} marginRight={"auto"} size={24} color="#7B8B9A" />
      </Pane>
      <Pane textAlign="center" className="noselect" >
        <Strong fontSize={14} color="#7B8B9A">{I18n.get("The account is private")}</Strong>
      </Pane>
      <Pane paddingX={10} lineHeight={1.1} textAlign="center" >
        <Text fontSize={14} fontStyle="italic" color="#7B8B9A" >{I18n.get("Follow this account to see their impact")}</Text>
      </Pane>
    </Pane>
  );
};


const Profile = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Profile - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("Profile - cleaned up");
    }
  }, []);

  const profileDetails = useSelector(state => state.profileDetails);

  return (
    <Fragment >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <ProfileDetails />

        {!profileDetails?.publicFaceId ? null :

          <Fragment >

            <Pane paddingTop={4} marginLeft="auto" marginRight="auto" maxWidth={350} alignItems="baseline" display="flex" flexWrap="wrap" background="tint1">
              <Pane flex="none" marginLeft="auto" width={10} height={20} borderTopRightRadius={10} borderBottomRightRadius={10} background="#FFFFFF" ></Pane>
              <Pane flex={1} width="100%" height={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A"></Pane>
              <Pane flex="none" marginRight="auto" width={10} height={20} borderTopLeftRadius={10} borderBottomLeftRadius={10} background="#FFFFFF" ></Pane>
            </Pane>


            {false ?
              <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingX={10} >
                <ProfilePrivatePage />
              </Pane>
              :
              <Fragment>

                <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingX={10} >
                  <ProfileTabButtons />
                </Pane>

                <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingX={5} >
                  <ProfileHistoryTab />
                </Pane>

                <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingX={10} >
                  <ProfileDashboardTab />
                </Pane>

              </Fragment>
            }
            
          </Fragment>
        }

        <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingTop={10}
          borderBottomLeftRadius={10} borderBottomRightRadius={10} >
        </Pane>

      </Pane>
      <Footer />
    </Fragment>
  );
}

export default Profile;