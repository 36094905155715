import { Fragment, cloneElement, useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tooltip, InfoSignIcon, WidgetButtonIcon, EditIcon, IconButton, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import DialogHeader from './DialogHeader';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setDialogState } from '../services/actions';

import { I18n } from 'aws-amplify';

const ShareOptions = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ShareOptions - ${countRef.current}`);
    return () => {
      console.log("ShareOptions - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  // const dialogState = useSelector(state => state.dialogState);

  return(
    <Pane padding={10}>

      <DialogHeader
        headerText={I18n.get('Share')}
        hideLeftButton={false}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        // leftButtonIcon={ChevronLeftIcon}
        onLeftButtonClick={() => {
          dispatch(setDialogState(null)); // this will close the dialog
        }}
        // rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      <Pane paddingY={12}>
        Share options...
      </Pane>
      

    </Pane>
  );
}

export default ShareOptions;