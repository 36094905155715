import React from 'react';
// covert SVG here - https://react-svgr.com/

const ShareIcon = ({
  filled,
  color,
  ...props
}) => {
  // console.log(`HeartIcon started...`);


  if (filled) {
    return (
      <svg
        viewBox="0 0 24 20"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        width="1em"
        height="1em"
        {...props}
      >
        <path
          d="M11.762 20a1.176 1.176 0 01-.836-.341L1.78 10.5c-2.372-2.397-2.372-6.315 0-8.711 2.392-2.385 6.32-2.385 8.712 0l1.271 1.271 1.271-1.271c2.392-2.385 6.32-2.385 8.712 0 2.372 2.396 2.372 6.314 0 8.711l-9.147 9.159c-.223.22-.523.343-.836.341zM6.135 2.342a3.769 3.769 0 00-2.684 1.106 3.832 3.832 0 000 5.38l8.311 8.323 8.311-8.323a3.832 3.832 0 000-5.38 3.925 3.925 0 00-5.368 0l-2.107 2.12a1.179 1.179 0 01-1.672 0L8.82 3.447a3.769 3.769 0 00-2.684-1.106z"
          fill={color || "#000000"}
          fillRule="nonzero"
        />
      </svg>
    );
  }

  return (
    <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 3.73c-1.12.07-2 1-2 2.14v2.12h-.02a9.9 9.9 0 0 0-7.83 10.72.9.9 0 0 0 1.61.46l.19-.24a9.08 9.08 0 0 1 5.84-3.26l.2-.03.01 2.5a2.15 2.15 0 0 0 3.48 1.69l7.82-6.14a2.15 2.15 0 0 0 0-3.38l-7.82-6.13c-.38-.3-.85-.46-1.33-.46zm.15 1.79c.08 0 .15.03.22.07l7.82 6.14a.35.35 0 0 1 0 .55l-7.82 6.13a.35.35 0 0 1-.57-.28V14.7a.9.9 0 0 0-.92-.9h-.23l-.34.02c-2.28.14-4.4.98-6.12 2.36l-.17.15.02-.14a8.1 8.1 0 0 1 6.97-6.53.9.9 0 0 0 .79-.9V5.87c0-.2.16-.35.35-.35z"
          fill={color || "#000000"}
          fillRule="nonzero"
        />
      </g>
    </svg>
    
  )
}

export default ShareIcon;

