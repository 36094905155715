import { Fragment, useEffect, useState, useMemo, useRef, forwardRef, useImperativeHandle, useCallback } from "react";

import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

// import { Pane } from 'evergreen-ui';

import { API } from 'aws-amplify';

import * as mutations from '../graphql/mutations';

let STRIPE_PUBLISHABLE_KEY = "";
if (process.env.NODE_ENV === "development") {
  STRIPE_PUBLISHABLE_KEY = "pk_test_LQeXKPtwj5zckUb1OSTxjC9E";
}
else if (process.env.NODE_ENV === "production") {
  STRIPE_PUBLISHABLE_KEY = "pk_live_519L06QEgkWH2fa2fI8u4jXTdWg5z8Ro38qQd425Ns1byzMfIgCbn2LrP2ftsrlaPcqYp3Me1utrirAis9M3E8RRO00xWi8NXRe";
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const handlePayment = async ({
  stripe,
  elements,
  customerData = {}, // comes from Payment Button
  confirmCardPaymentData = {},
  confirmCardPaymentOptions = {
    handleActions: false
  },
  user = null,
  publicTransactionId = null,
  type = null,
  mode = null,
  fromFace,
  locale,
  fromPublicFaceId,
  toPublicFaceId,
  viaPublicFaceId,
  amount,
  currency,
  frequency = null,
  caption = null,
  note = null,
  paymentMethod,
  paymentMethodId,
  history = null,

  onProcessing = () => {},
  onComplete = () => {},
  onError = () => {},
  complete = () => {},

}) => {
  
  console.log("customerData", customerData);
  console.log("confirmCardPaymentData", confirmCardPaymentData);
  console.log("confirmCardPaymentOptions", confirmCardPaymentOptions);

  onProcessing();

  let newTransactionDetails = null;

  const definedProps = obj => Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v)
  );
  
  // what to do with error status coming ???

  // create paymentIntent (objectPIntent) and publicTransactionId (if required, may come with an intent transaction from camera)
  try {

    let objectPIntent = {
      paymentIntent: {
        status: null,
      },
      error: null,
    }
    
    const response = await API.graphql({
      query: mutations.createPaymentIntent,
      variables: {
        publicTransactionId: publicTransactionId,
        fromPublicFaceId: fromPublicFaceId,
        toPublicFaceId: toPublicFaceId,
        viaPublicFaceId: viaPublicFaceId,
        type: type,
        mode: mode,
        fromFace: {
          name: fromFace?.name || undefined,
          email: fromFace?.email || undefined,
        },
        amount: amount,
        currency: currency,
        frequency: frequency,
        caption: caption,
        note: note,
        locale: locale,
        paymentMethod: paymentMethod,
        paymentMethodId: paymentMethodId,
        objectPIntent: JSON.stringify(objectPIntent),
        history: history,
      },
      authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
    });
  
    // console.log("response from createPaymentIntent:", response);
  
    newTransactionDetails = response.data.createPaymentIntent;
  
    if (newTransactionDetails.objectPIntent) {
      newTransactionDetails.objectPIntent = JSON.parse(newTransactionDetails.objectPIntent);
    }

  }
  catch (error) {
    console.error("within createPaymentIntent", error);
    // handle ???
    throw error;
  }

  // deal with pending or error
  if (newTransactionDetails.status === "pending") { // newly created transaction may return with status succeeded

    const paymentIntent = newTransactionDetails.objectPIntent?.paymentIntent;
    // console.log("paymentIntent", paymentIntent);
    if (!paymentIntent) {
      console.error("paymentIntent has not been created:", newTransactionDetails);
      // handle ???
      throw Error("paymentIntent has not been created");
    }

    let objectPIntentConfirmed = null;
    // stripe.confirmPayment
    try {
      // https://stripe.com/docs/js/payment_intents/confirm_card_payment
      if (elements) {
        // https://stripe.com/docs/stripe-js/react
        objectPIntentConfirmed = await stripe.confirmPayment({
          elements,
          clientSecret: paymentIntent.client_secret,
          redirect: "if_required", // https://stripe.com/docs/js/payment_intents/confirm_payment#confirm_payment_intent-options-redirect
          confirmParams: {
            return_url: `https://facedonate.org/t/${publicTransactionId}` || `https://facedonate.org`, // urgent ??? // return in paymentIntent ???
          },
        });
      }
      else {
        objectPIntentConfirmed = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          confirmCardPaymentData,
          confirmCardPaymentOptions,
        );
      }
      // console.log("objectPIntentConfirmed", objectPIntentConfirmed);
    }
    catch (error) {
      // https://stripe.com/docs/api/errors
      console.error("within stripe.confirmCardPayment", error);
      objectPIntentConfirmed = {
        paymentIntent: null,
        error: error,
      }
    }

    if (!objectPIntentConfirmed) {
      console.error("objectPIntent has not been created");
      objectPIntentConfirmed = {
        paymentIntent: null,
        error: {
          message: "Fail to confirm payment intent.",
        },
      }
    }

    // for info
    if (objectPIntentConfirmed?.error) {
      console.error("with objectPIntentConfirmed", objectPIntentConfirmed?.error);
    }

    try {

      const response = await API.graphql({
        query: mutations.confirmPaymentIntent,
        variables: {
          publicTransactionId: newTransactionDetails.publicTransactionId,
          objectPIntent: JSON.stringify(objectPIntentConfirmed),
        },
        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
    
      const confirmedTransactionDetails = response.data.confirmPaymentIntent;
    
      if (confirmedTransactionDetails.objectPIntent) {
        confirmedTransactionDetails.objectPIntent = JSON.parse(confirmedTransactionDetails.objectPIntent);
      }

      // console.log("newTransactionDetails before confirmation", {...newTransactionDetails});
      newTransactionDetails = {
        ...newTransactionDetails,
        ...definedProps(confirmedTransactionDetails),
      };
      console.log("newTransactionDetails after confirmation", {...newTransactionDetails});

      if (typeof complete === "function") {
        complete('success');
      }

    }
    catch (error) {
      if (typeof complete === "function") {
        complete('fail');
      }
      console.error("within confirmPaymentIntent", error);
      if (typeof onError === "function") {
        onError(Array.isArray(error?.errors) ? error?.errors[0] : error);
      }
    }  

  }
  
  onComplete(newTransactionDetails); // this should not move up: hiding Stripe elements causes an error with confirmCardPayment

};

const PaymentElementDetails = ({triggerRefresh, onAmountRefresh, ...props}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PaymentElementDetails - ${countRef.current}`);
    return () => {
      console.log("PaymentElementDetails - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitPayment = useCallback(async (event) => {
    event.preventDefault();

    if (props.paymentMethod !== "balance") {
      if (!stripe) {
        // Stripe.js has not yet loaded.
        console.error("Stripe.js has not yet loaded.");
        // Make sure to disable form submission until Stripe.js has loaded.
        if (typeof props.onError === "function") {
          props.onError({message: "Stripe.js has not yet loaded."});
        }
        return null;
      }
  
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
  
      if (submitError) {
        console.error("with elements.submit", submitError); // ??? for when balance is required...

        // submitError.code: 'incomplete' // when ApplyPay is cancelled

        if (typeof props.onError === "function") {
          props.onError(Array.isArray(submitError?.errors) ? submitError?.errors[0] : submitError);
        }
        return null;
      }
    }
    
    await handlePayment({
      stripe: stripe,
      elements: elements,
      ...props,
    });

  }, [elements, props, stripe]);

  const [detailsComplete, setDetailsComplete] = useState(false);

  useEffect(() => {
    if (detailsComplete) {
      onAmountRefresh();
    }
  }, [detailsComplete, onAmountRefresh]);

  useEffect(() => {
    if (triggerRefresh) {
      onAmountRefresh();
    }
  }, [onAmountRefresh, triggerRefresh]);

  return (
    <form id="payment-form" >

      <PaymentElement
        // https://stripe.com/docs/stripe-js/react
        // https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options
        layout={{
          type: 'tabs',
          defaultCollapsed: false
        }}
        business={{
          name: "FaceDonate.org"
        }}
        readOnly={props.disabled}
        paymentMethodOrder={['apple_pay', 'google_pay', 'card']}
        terms={{
          card: "always"
        }}
        onLoadError={(element) => {
          // https://stripe.com/docs/js/element/events/on_loaderror
          console.error("PaymentElement onLoadError element", element);
          if (typeof props.onError === "function") {
            props.onError(element.error);
          }
        }}
        onReady={(element) => {
          console.log("PaymentElement onReady element", element);
          if (typeof props.onReady === "function") {
            props.onReady(true);
          }
        }}
        // onClick={(event) => {
        //   console.log("PaymentElement onClick event", event);
        // }}
        onChange={(event) => {
          console.log("PaymentElement onChange event", event);
          // if (event?.value?.type === "card") { // 'apple_pay', 'google_pay'
          //   console.log("event?.value?.type", event?.value?.type);
          // }
          props?.onDetailsComplete(event.complete);
          setDetailsComplete(event.complete);
        }}
        // onBlur={(event) => {
        //   console.log("PaymentElement onBlur event", event);
        // }}
        // onFocus={(event) => {
        //   console.log("PaymentElement onFocus event", event);
        // }}
      />

      <button
        id="submit"
        ref={props.forwardedRef}
        style={{display: 'none'}}
        onClick={handleSubmitPayment}
      >
        {"Submit"}
      </button>
      
    </form>
  );

};

const PaymentInput = forwardRef(({...props}, ref) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PaymentInput - ${countRef.current}`);
    return () => {
      console.log("PaymentInput - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const paymentElementRef = useRef();
  
  const [readyToProceed, setReadyToProceed] = useState();
  const [triggerPayment, setTriggerPayment] = useState();
  const [amount, setAmount] = useState();
  
  useImperativeHandle(ref, () => ({
    makePayment: () => {
      if (!triggerPayment) {
        console.log("makePayment");
        // setAmount(props.totalPaymentAmount * 100);
        setTriggerPayment(true);
      }
      // paymentElementRef.current.click();
    },
  }));
  
  // ??? https://stripe.com/docs/payments/external-payment-methods
  // using props causes unnecessary loading of Stripe
  const options = {
    // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    // https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options
    // https://stripe.com/docs/payments/accept-a-payment-deferred?platform=web&type=payment#additional-options
    mode: 'payment',
    currency: props.currency || "gbp",
    amount: amount || 100, // min. 100 // https://stripe.com/docs/payments/accept-a-payment-deferred?platform=web&type=payment#dynamic-updates
    // https://stripe.com/docs/payments/payment-element/migration?integration-path=one-time#additional-options
    // setupFutureUsage: 'off_session' | 'on_session',
    loader: 'auto', // 'auto' | 'always' | 'never'
    appearance: { // https://stripe.com/docs/elements/appearance-api
      theme: 'stripe',
      variables: {
        // paddingTop: '40px',
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#425A70',
        colorDanger: '#EC4C47',
        fontSizeBase: '14px',
        fontFamily: '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        fontSmooth: "antialiased",
        spacingUnit: '2px',
        borderRadius: '3px',
        // See all possible variables below
      },
      rules: {
        '.Input': {
          padding: '11px',
          color: "#425A70"
        },
        '.Input::placeholder': {
          color: "rgba(67, 90, 111, 0.3)",
        },
        '.Label': {
          color: "#425A70",
          fontSize: '14px',
          marginLeft: '2px',
          marginBottom: '4px',
          fontWeight: 600,
        }
      }
    }
  };


  useEffect(() => {
    if (triggerPayment && readyToProceed) {
      console.log("triggerPayment");
      paymentElementRef.current.click();
      setTriggerPayment(false);
      setReadyToProceed(false);
    }
  }, [readyToProceed, triggerPayment]);

  return (
    <Elements stripe={stripePromise} options={options} >
      <PaymentElementDetails forwardedRef={paymentElementRef} {...props} triggerRefresh={triggerPayment}
        onAmountRefresh={() => {
          if (triggerPayment) {
            setAmount(props.totalPaymentAmount * 100);
            setReadyToProceed(true);
          }
        }}
      />
    </Elements>
  );
  
});

export default PaymentInput;