import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Radio, Spinner } from 'evergreen-ui';

import DynamicRadioButton from './DynamicRadioButton';

import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useSelector } from 'react-redux';

import { I18n } from 'aws-amplify';

const AccountManageNotifications = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("AccountManageNotifications cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let match = useRouteMatch();

  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const [updatedUserSettings, setUpdatedUserSettings] = useState({
    ...userDetails.settings
  });

  useEffect(() => {
    setUpdatedUserSettings({
      ...userDetails.settings
    });
  }, [userDetails.settings]);

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const optionsOnAndOff = [
    { label: I18n.get('Off'), value: "off"},
    { label: I18n.get('On'), value: "on"},
  ];

  // NEODR - notifyEmailOwnerDonationReceipt // default value is "on" at settings.neodr
  const [processingNEODR, setProcessingNEODR] = useState(false);

  // NEONDP - notifyEmailOwnerNewDonationPage // default value is "on" at settings.neondp
  const [processingNEONDP, setProcessingNEONDP] = useState(false);

  // NEODT - notifyEmailOwnerDonationTransfers // default value is "on" at settings.neodt
  const [processingNEODT, setProcessingNEODT] = useState(false);

  // NEODUI - notifyEmailOwnerDonationUseImpact // default value is "on" at settings.neodt
  const [processingNEODUI, setProcessingNEODUI] = useState(false);

  const handleToggleNotificationSetting = useCallback((event, setSettingProcessing) => {
    // console.log("event", event);
    if (!event || !event?.target) {
      console.error("event value is no valid in handleToggleNotificationSetting");
      return null;
    }
    
    setSettingProcessing(true);

    setUpdatedUserSettings((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

    setSettingProcessing(false);

    handleUpdateUserDetails({
      // userSettings:
      userDetailsToUpdate: {
        settings: {
          [event.target.name]: event.target.value
        },
      },
      onSuccess: (freshUserDetails) => {
        // setSettingProcessing(false);
      },
      onError: (error) => {
        console.error('within handleToggleNotificationSetting:', error);
        // handle ???
        // setSettingProcessing(false);
      },
    });

  }, [handleUpdateUserDetails]);

  return (
    <Pane marginTop={20}>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={10} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Donation receipts')}</Strong>
        {processingNEODR ?
          <Spinner marginLeft={8} flex="none" size={20} />
        : null }
      </Pane>
      <Pane marginBottom={10} >
        <Pane marginBottom={4} >
          {optionsOnAndOff.map((option, index) => (
            <DynamicRadioButton
              name={"neodr"}
              key={index}
              disabled={processingNEODR}
              checked={option.value === (updatedUserSettings.neodr || "on")}
              value={option.value}
              onChange={(event) => {
                handleToggleNotificationSetting(event, setProcessingNEODR);
              }}
              label={option.label}
            />
          ))}
        </Pane>
        <Pane marginBottom={16} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get('You will receive an email receipt confirming the allocation of your donation when topping up your balance and donating to other pages.')}</Text>
        </Pane>
      </Pane>

      <Pane width="100%" marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={10} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('New donations')}</Strong>
        {processingNEONDP ?
          <Spinner marginLeft={8} flex="none" size={20} />
        : null }
      </Pane>
      <Pane marginBottom={10} >
        <Pane marginBottom={4} >
          {optionsOnAndOff.map((option, index) => (
            <DynamicRadioButton name={"neondp"}
              key={index}
              disabled={processingNEONDP}
              checked={option.value === (updatedUserSettings.neondp || "on")}
              value={option.value}
              onChange={(event) => {
                handleToggleNotificationSetting(event, setProcessingNEODR);
              }}
              label={option.label}
            />
          ))}
        </Pane>
        <Pane marginBottom={16} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get('You will be notified when a new donation is made to your page.')}</Text>
        </Pane>
      </Pane>

      <Pane width="100%" marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={10} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Donation transfers')}</Strong>
        {processingNEODT ?
          <Spinner marginLeft={8} flex="none" size={20} />
        : null }
      </Pane>
      <Pane marginBottom={10} >
        <Pane marginBottom={4} >
          {optionsOnAndOff.map((option, index) => (
            <DynamicRadioButton name={"neodt"}
              key={index}
              disabled={processingNEODT}
              checked={option.value === (updatedUserSettings.neodt || "on")}
              value={option.value}
              onChange={(event) => {
                handleToggleNotificationSetting(event, setProcessingNEODR);
              }}
              label={option.label}
            />
          ))}
        </Pane>
        <Pane marginBottom={16} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get('You will be notified when the recipient of your donation transfers it to another page.')}</Text>
        </Pane>
      </Pane>

      <Pane width="100%" marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={10} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Donation use and impact')}</Strong>
        {processingNEODUI ?
          <Spinner marginLeft={8} flex="none" size={20} />
        : null }
      </Pane>
      <Pane marginBottom={10} >
        <Pane marginBottom={4} >
          {optionsOnAndOff.map((option, index) => (
            <DynamicRadioButton name={"neodui"}
              key={index}
              disabled={processingNEODUI}
              checked={option.value === (updatedUserSettings.neodui || "on")}
              value={option.value}
              onChange={(event) => {
                handleToggleNotificationSetting(event, setProcessingNEODR);
              }}
              label={option.label}
            />
          ))}
        </Pane>
        <Pane marginBottom={16} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get('You will be notified on the use and impact of your donation.')}</Text>
        </Pane>
      </Pane>

      <Pane width="100%" marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

    </Pane>
  );

}

export default AccountManageNotifications;