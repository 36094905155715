import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo, useContext} from 'react';

import { Redirect, generatePath, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import { Heading, Text, Button, Table, Dialog, IconButton, CrossIcon, Icon, Paragraph, Strong, Popover, LockIcon, Spinner, Menu, Position, Tablist, Tab, InfoSignIcon, Tooltip, AddIcon, ShopIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon, MoreIcon, Pill, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';

import FacePH from '../images/FacePH';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import numeral from 'numeral';
import QRCode from 'qrcode';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import InfiniteScroll from "react-infinite-scroll-component";

import { G2, TinyArea, RingProgress, Column, Bar, Histogram, Bullet, RadialBar, DualAxes, Area, CirclePacking, Treemap } from '@ant-design/plots';

import HeartIcon from '../assets/icons/HeartIcon';
import QRCodeIcon from '../assets/icons/QRCodeIcon';

import PaperPlaneIcon from '../assets/icons/PaperPlaneIcon';
import CommentIcon from '../assets/icons/CommentIcon';
import ProfileIcon from '../assets/icons/ProfileIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';

import AspectRatio from './AspectRatio';

import { useGetTransactions } from "../services/useGetTransactions";

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, updateProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

const ImpactLivesTile = ({key, quantity, unit}) => {

  if (!quantity) {
    return null;
  }

  return (
    <Pane
        key={key}
        // display={"flex"}
        textAlign="center"
        flex={"1 0 25%"}
        // width={32}
        minWidth={70}
        maxHeight={32}
        paddingX={8}
        justifyContent="center"
      >

        <Pane flex="none" >
          <Strong display="contents" flex="none" fontSize={24} color="#283655">{quantity}</Strong>
        </Pane>
        <Pane paddingTop={2} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get(unit)}</Text>
        </Pane>

      </Pane>
  );
}

const ProfileDashboardTab = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileDashboardTab - ${countRef.current}`);
    return () => {
      console.log("ProfileDashboardTab - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);
	const transactionDetails = useSelector(state => state.transactionDetails);

  const handleGetTransactions = useGetTransactions();
  
  if (match.params.mode !== "dashboard" && location.state?.prevMode !== "dashboard") {
    return null;
  }

  if (!profileDetails?.publicFaceId) {
    return (
      null
    );
  }


  

  if (!profileDetails?.dashboard?.results) {
    return (
      <Pane paddingX={5} >
        <Fragment>
          <Pane textAlign="center" >
            <Strong fontSize={14} color="#7B8B9A">{I18n.get("Dashboard is being built...")}</Strong>
          </Pane>
          <Pane textAlign="center" >
            <Text fontSize={14} fontStyle="italic" color="#7B8B9A" >{I18n.get("Please come back later.")}</Text>
          </Pane>
        </Fragment>
      </Pane>
    );
  }

  const mergedFeedback = profileDetails?.dashboard?.results?.find((feedback) => (feedback.role === "merged"));
  const mergedImpactAdults = mergedFeedback?.impact?.find((impact) => (impact.unit === "adult"));
  const mergedImpactChildren = mergedFeedback?.impact?.find((impact) => (impact.unit === "child"));
  const mergedImpactAnimals = mergedFeedback?.impact?.find((impact) => (impact.unit === "animal"));

  const mergedProvideThings = [
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: mergedFeedback?.impact?.find((impact) => (impact.unit === "meal"))?.quantity || 0,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: mergedFeedback?.impact?.find((impact) => (impact.unit === "class"))?.quantity || 0,
    },
    { 
      orderIndex: 4,
      action: "provide",
      effect: "thing",
      unit: "shopping trip",
      unitName: "Shopping trips",
      quantity: mergedFeedback?.impact?.find((impact) => (impact.unit === "shopping trip"))?.quantity || 0,
    },
  ];

  const fundedFeedback = profileDetails?.dashboard?.results?.find((feedback) => (feedback.role === "funded"));
  const fundedImpactAdults = fundedFeedback?.impact?.find((impact) => (impact.unit === "adult"));
  const fundedImpactChildren = fundedFeedback?.impact?.find((impact) => (impact.unit === "child"));
  const fundedImpactAnimals = fundedFeedback?.impact?.find((impact) => (impact.unit === "animal"));

  const fundedProvideThings = [
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: fundedFeedback?.impact?.find((impact) => (impact.unit === "meal"))?.quantity || 0,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: null,
    },
    { 
      orderIndex: 4,
      action: "provide",
      effect: "thing",
      unit: "shopping trip",
      quantity: fundedFeedback?.impact?.find((impact) => (impact.unit === "shopping trip"))?.quantity || 0,
    },
  ];

  const releasedFeedback = profileDetails?.dashboard?.results?.find((feedback) => (feedback.role === "released"));

  const releasedProvideThings = [
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: releasedFeedback?.impact?.find((impact) => (impact.unit === "meal"))?.quantity || 0,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: null,
    },
    { 
      orderIndex: 4,
      action: "provide",
      effect: "thing",
      unit: "shopping trip",
      quantity: releasedFeedback?.impact?.find((impact) => (impact.unit === "shopping trip"))?.quantity || 0,
    },
  ];

  const spentFeedback = profileDetails?.dashboard?.results?.find((feedback) => (feedback.role === "spent"));

  const spentProvideThings = [
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: spentFeedback?.impact?.find((impact) => (impact.unit === "meal"))?.quantity || 0,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: null,
    },
    { 
      orderIndex: 4,
      action: "provide",
      effect: "thing",
      unit: "shopping trip",
      quantity: spentFeedback?.impact?.find((impact) => (impact.unit === "shopping trip"))?.quantity || 0,
    },
  ];

  const allocatedFeedback = profileDetails?.dashboard?.results?.find((feedback) => (feedback.role === "allocated"));

  const chartData = profileDetails?.dashboard?.results?.find((r) => (r.type === "chartData" && r.unit === "shopping trip"));
  
  const allocatedProvideThings = [
    { 
      orderIndex: 1,
      action: "provide",
      effect: "thing",
      unit: "meal",
      quantity: allocatedFeedback?.impact?.find((impact) => (impact.unit === "meal"))?.quantity || 0,
    },
    { 
      orderIndex: 2,
      action: "provide",
      effect: "thing",
      unit: "class",
      quantity: null,
    },
    { 
      orderIndex: 4,
      action: "provide",
      effect: "thing",
      unit: "shopping trip",
      quantity: allocatedFeedback?.impact?.find((impact) => (impact.unit === "shopping trip"))?.quantity || 0,
    },
  ];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const DemoDualAxes = () => {

    const unformattedChartData = JSON.parse(mergedFeedback?.chartData);

    let data = [];
    let datesArray = [];
    let tempLength = 30; // days
    let fromDate = new Date();
    fromDate.setHours(0,0,0,0);
    fromDate.setDate(fromDate.getDate() - tempLength);

    let tempRound = 0;

    let currentUnit = "shopping trip";

    // console.log("unformattedChartData", unformattedChartData);

    do {
      let currentDate = formatDateFns(fromDate, "d MMM");
      let currentDateUNIX  = Math.round(fromDate.getTime() / 1000);
      // console.log("currentDateUNIX", currentDateUNIX);
      let currentChartDataByDate = unformattedChartData.find((t) => (currentDateUNIX === Number(t.key)));
      let currentImpactByDate = currentChartDataByDate?.impact?.find((impact) => (impact.unit === currentUnit));
      // console.log("currentChartDataByDate", currentChartDataByDate);

      datesArray.push({
        date: currentDate,
        dateUNIX: currentDateUNIX,
        value: currentImpactByDate?.quantity || 0, 
        type: capitalizeFirstLetter(I18n.get(`${currentUnit}_plural`)),
      });
      tempRound++;
      fromDate.setDate(fromDate.getDate() + 1);
    }
    while (tempRound < tempLength);

    data = datesArray;

    // console.log("data", data);


    // const data = [
    //   {
    //     date: '01',
    //     value: 270,
    //     type: 'Adults',
    //   },
    //   {
    //     date: '02',
    //     value: 320,
    //     type: 'Adults',
    //   },
    //   {
    //     date: '03',
    //     value: 450,
    //     type: 'Adults',
    //   },
    // ];

    const transformData = [
      // {
      //   date: 'Jan.',
      //   count: 55,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Feb.',
      //   count: 57,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Mar.',
      //   count: 60,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Jan.',
      //   count: 67,
      //   name: 'Your score',
      // },
      // {
      //   date: 'Feb.',
      //   count: 75,
      //   name: 'Your score',
      // },
      // {
      //   date: 'Mar.',
      //   count: 73,
      //   name: 'Your score',
      // },
    ];

    const config = {
      data: [data, transformData],
      xField: 'date',
      yField: ['value', 'count'],
      
      tooltip: {
        formatter: (datum) => {
          // console.log("datum", datum);
          return {
            title: datum.date, // + " month XX",
            name: datum.type,
            value: datum.value };
        },
        title: "X",
        showMarkers: false,
        position: 'top',
      },
      legend: {
        layout: 'horizontal',
        position: 'top'
      },
      geometryOptions: [
        {
          geometry: 'line',
          seriesField: 'type',
          lineStyle: {
            lineWidth: 2,
            // lineDash: [5, 5],
            // stroke: '#7B8B9A',
          },
          smooth: true,
          // colorField: 'color',
          // color: (e) => {
          //   // console.log("e", e);
          //   return(e.color || '#7B8B9A');
          // },
          color: ["#1070CA", '#7B8B9A',], // #283655
        },
        {
          geometry: 'line',
          seriesField: 'name',
          point: {},
        },
      ],
      yAxis: {
        value: {
          tickCount: 0, // hide axis
          min: 0,
          // line: null,
          // label: null,
        }
      },
      xAxis: {
        tickCount: 2,
        // min: 0,
        // line: null,
        // label: null,
      },
      // xAxis: {
      //   grid: {
      //     line: {
      //       style: {
      //         stroke: 'black',
      //         lineWidth: 2,
      //         lineDash: [4, 5],
      //         strokeOpacity: 0.7,
      //         shadowColor: 'black',
      //         shadowBlur: 10,
      //         shadowOffsetX: 5,
      //         shadowOffsetY: 5,
      //         cursor: 'pointer'
      //       }
      //     }
      //   }
      // }
    };
    return <DualAxes {...config} />;
  };

  const DemoDualAxes2 = () => {

    const unformattedChartData = JSON.parse(mergedFeedback?.chartData);

    let data = [];
    let datesArray = [];
    let tempLength = 30; // days
    let fromDate = new Date();
    fromDate.setHours(0,0,0,0);
    fromDate.setDate(fromDate.getDate() - tempLength);

    let tempRound = 0;

    let currentUnit = "adult";
    let currentUnit2 = "child";

    // console.log("unformattedChartData", unformattedChartData);

    do {
      let currentDate = formatDateFns(fromDate, "d MMM");
      let currentDateUNIX  = Math.round(fromDate.getTime() / 1000);
      // console.log("currentDateUNIX", currentDateUNIX);
      let currentChartDataByDate = unformattedChartData.find((t) => (currentDateUNIX === Number(t.key)));
      let currentImpactByDate = currentChartDataByDate?.impact?.find((impact) => (impact.unit === currentUnit));
      // console.log("currentChartDataByDate", currentChartDataByDate);

      datesArray.push({
        date: currentDate,
        dateUNIX: currentDateUNIX,
        value: currentImpactByDate?.quantity || 0, 
        type: capitalizeFirstLetter(I18n.get(`${currentUnit}_plural`)),
      });
      tempRound++;
      fromDate.setDate(fromDate.getDate() + 1);
    }
    while (tempRound < tempLength);

    data = datesArray;

    // console.log("data", data);


    // const data = [
    //   {
    //     date: '01',
    //     value: 270,
    //     type: 'Adults',
    //   },
    //   {
    //     date: '02',
    //     value: 320,
    //     type: 'Adults',
    //   },
    //   {
    //     date: '03',
    //     value: 450,
    //     type: 'Adults',
    //   },
    // ];

    const transformData = [
      // {
      //   date: 'Jan.',
      //   count: 55,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Feb.',
      //   count: 57,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Mar.',
      //   count: 60,
      //   name: 'Average score',
      // },
      // {
      //   date: 'Jan.',
      //   count: 67,
      //   name: 'Your score',
      // },
      // {
      //   date: 'Feb.',
      //   count: 75,
      //   name: 'Your score',
      // },
      // {
      //   date: 'Mar.',
      //   count: 73,
      //   name: 'Your score',
      // },
    ];

    const config = {
      data: [data, transformData],
      xField: 'date',
      yField: ['value', 'count'],
      
      tooltip: {
        formatter: (datum) => {
          // console.log("datum", datum);
          return {
            title: datum.date, // + " month XX",
            name: datum.type,
            value: datum.value };
        },
        title: "X",
        showMarkers: false,
        position: 'top',
      },
      legend: {
        layout: 'horizontal',
        position: 'top'
      },
      geometryOptions: [
        {
          geometry: 'line',
          seriesField: 'type',
          lineStyle: {
            lineWidth: 2,
            // lineDash: [5, 5],
            // stroke: '#7B8B9A',
          },
          smooth: true,
          // colorField: 'color',
          // color: (e) => {
          //   // console.log("e", e);
          //   return(e.color || '#7B8B9A');
          // },
          color: ["#1070CA", '#7B8B9A',], // #283655
        },
        {
          geometry: 'line',
          seriesField: 'name',
          point: {},
        },
      ],
      yAxis: {
        value: {
          tickCount: 0, // hide axis
          min: 0,
          // line: null,
          // label: null,
        }
      },
      xAxis: {
        tickCount: 2,
        // min: 0,
        // line: null,
        // label: null,
      },
      // xAxis: {
      //   grid: {
      //     line: {
      //       style: {
      //         stroke: 'black',
      //         lineWidth: 2,
      //         lineDash: [4, 5],
      //         strokeOpacity: 0.7,
      //         shadowColor: 'black',
      //         shadowBlur: 10,
      //         shadowOffsetX: 5,
      //         shadowOffsetY: 5,
      //         cursor: 'pointer'
      //       }
      //     }
      //   }
      // }
    };
    return <DualAxes {...config} />;
  };
  

  return (
    <Fragment >

      <Fragment>

        {/* <Pane paddingTop={10} textAlign="center" >
          <Text flex="none" fontSize={14} color="#283655">{profileDetails?.tags}</Text>
        </Pane> */}

        <Pane paddingTop={12} textAlign="center" >
          <Strong display="contents" flex="none" fontSize={20} color="#283655">{I18n.get("Impact")}</Strong>
        </Pane>

        <Pane
          paddingTop={12}
          display={"flex"}
          flexWrap={"wrap"}
          gap={10}
        >
            
          <ImpactLivesTile key={"adult days"} quantity={mergedImpactAdults?.quantityDurationDays || 0} unit={I18n.get("adult days")} />
          <ImpactLivesTile key={"child days"} quantity={mergedImpactChildren?.quantityDurationDays || 0} unit={I18n.get("child days")} />
          <ImpactLivesTile key={"animal days"} quantity={mergedImpactAnimals?.quantityDurationDays || 0} unit={I18n.get("animal days")} />

        </Pane>

        {true ?
          <Fragment>

            {/* <Pane paddingTop={10} >
              <Text display="contents" flex="none" fontSize={14} color="#283655">{I18n.get("...")}</Text>
            </Pane> */}

            <Table paddingTop={12} is={"table"} width={"100%"} >

              <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                {mergedProvideThings.map((impact) => (
                  impact?.quantity ?
                    <Fragment>
                      <Table.Row is={"tr"} width={"100%"} key={impact?.orderIndex}
                        height={"auto"}
                        minHeight={40}
                        // isSelectable
                        // onSelect={() => alert(transaction.name)}
                      >

                        <Table.TextCell is={"td"} textAlign="left"
                          paddingLeft={0}
                          paddingRight={5}
                          paddingY={0}
                        >{
                          <Fragment>
                            <Strong fontSize={16} color="#283655">{capitalizeFirstLetter(I18n.get(`${impact?.unit}_plural`))}</Strong>
                          </Fragment>
                        }</Table.TextCell>

                        <Table.TextCell is={"td"} textAlign="right"
                          paddingX={5}
                          paddingY={0}
                        >{
                          <Fragment>

                            <Strong fontSize={16} color="#283655">{impact?.quantity || 0}</Strong>

                          </Fragment>

                        }</Table.TextCell>

                      </Table.Row>
                    </Fragment>
                  :
                  null
                ))}
              </Table.Body>

            </Table>

          </Fragment>
        : null }


      </Fragment>

      {/* <Pane marginTop={12} >

        <Strong fontSize={16} color="#283655">{capitalizeFirstLetter(I18n.get(`People supported`))}</Strong>

        <Pane marginTop={12} >
          <Pane height={260} justifyContent="center" >
            <DemoDualAxes2 />
          </Pane>
        </Pane>

      </Pane> */}
      

      {/* <Pane marginTop={12} >

        <Strong fontSize={16} color="#283655">{capitalizeFirstLetter(I18n.get(`People supported`))}</Strong>

        <Pane marginTop={12} >
          <Pane height={260} justifyContent="center" >
            <DemoDualAxes />
          </Pane>
        </Pane>

      </Pane> */}

    </Fragment>
  );
};


export default ProfileDashboardTab;