import { Fragment, useEffect, useState, useCallback, useRef } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Heading, Strong, Text, Table, Button, Pane, SearchInput, Popover, Position, Menu } from 'evergreen-ui';



import { useMediaQuery } from 'react-responsive';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import Footer from '../components/Footer';
import ProfileCard from '../components/ProfileCard';
import ModalResponsive from '../components/ModalResponsive';
import PageDrawer from '../components/PageDrawer';

import SearchTypeahead from '../components/SearchTypeahead';

import TakeFacePicturePic from '../assets/images/TakeFacePicturePic';

import { useSelector } from 'react-redux';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move
} from "react-grid-dnd";

const Testing = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("Testing", countRef.current)
    return () => {
      console.log("Testing cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  // let location = useLocation();

  const userState = useSelector(state => state.userState);
  const [profilesListLoaded, setProfilesListLoaded] = useState(false);
  const [profilesList, setProfilesList] = useState([null, null, null, null, null, null,]);
  // const [level, setLevel] = useState();

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const searchInputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState();
  const [searchPopoverIsShown, setSearchPopoverIsShown] = useState(false);
  const [defaultSearchResults, setDefaultSearchResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = useCallback(async(queryTerm) => {
    const response = await API.graphql({
      query: queries.search,
      variables: {
        term: queryTerm,
      },
      authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
    });
    
    setSearchResults(response.data.search);
    
  }, [userState.user])

  useEffect(() => {
    if (searchQuery?.length > 2) {
      console.log("try searching for", searchQuery);
      handleSearch(searchQuery);
    }
  }, [handleSearch, searchQuery]);

  const [items, setItems] = useState({
    left: [
      { id: 1, name: "ben" },
      { id: 2, name: "joe" },
      { id: 3, name: "jason" },
      { id: 4, name: "chris" },
      { id: 5, name: "heather" },
      { id: 6, name: "Richard" }
    ],
    right: [
      { id: 7, name: "george" },
      { id: 8, name: "rupert" },
      { id: 9, name: "alice" },
      { id: 10, name: "katherine" },
      { id: 11, name: "pam" },
      { id: 12, name: "katie" }
    ]
  });

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1]
      });
    }

    const result = swap(items[sourceId], sourceIndex, targetIndex);
    return setItems({
      ...items,
      [sourceId]: result
    });
  }


  return (
    <Pane >

      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>


        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          {/* <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Recipients"}</Strong> */}
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Testing Page"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">

          <button onClick={onOpenModal}>Open modal</button>

          <ModalResponsive
            isShown={open}
            onOpenComplete={() => {}}
            onCloseComplete = {() => {
              onCloseModal();
            }}
            // onCancel={(close) => close()}
            title='DialogAuth'
            // hasHeader={false}
            // header={}
            hasFooter={false}
            // footer={}
            preventBodyScrolling={true}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            width={350} // 350 // "100%"
            height={"100%"}
            containerProps={{
              "backgroundColor": "transparent",
              // "maxWidth": "100%",
              "maxHeight": "100%", // "calc(100% - 36px * 2)",
              "margin": "auto", // auto
              // "overflow": 'hidden',
            }}
            contentContainerProps={{
              "padding": "0px",
              "maxHeight": "75hv",
            }}
            styles={{
              overlay: {
                "background": "#7B8B9A10",
                "backdrop-filter": "blur(1px)",
              },
              // modalContainer: {},
              modal: {
                "width": "100%",
                "max-width": "100%",
                "box-shadow": null,
                "margin": 0,
                "padding": 0,
              },
            }}
            
          >
            
            {({ close }) => (
              <Pane width="100%" height="100%" background="#FFFFFF" padding={10} borderRadius={10} overflow={"hidden"} >
                
                  <Pane position={"relative"} height={"calc(100% - 44px)"} overflow={"scroll"} background="red" borderRadius={5}>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                    <h2>Simple centered modal</h2>
                  </Pane>
                  <Pane padding={10} >
                    <button style={{fontSize: "1rem"}}
                      onClick={() => {
                        close();
                      }}
                    >Close modal</button>
                  </Pane>
              </Pane>
            )}
            
          </ModalResponsive>

        </Pane>

        <Pane  marginTop={36}>
          Search
          <SearchTypeahead />
        </Pane>

        <Pane marginTop={36} display="inline-block">

          <SearchInput
            ref={searchInputRef}
            display="block"
            placeholder={`${I18n.get('Search')}...`}
            height={40}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            
            onFocus={(e) => {
              // console.log("onFocus", e);
              setSearchPopoverIsShown(true);
            }}
            onBlur={(e) => {
              // console.log("onBlur", e);
              setSearchPopoverIsShown(false);
            }}
          
          />
        
          <Popover
            isShown={searchPopoverIsShown}
            minWidth={100}
            width={100}
            
            position={Position.BOTTOM}
            statelessProps={{
              width: "375px",
              maxWidth: "100%",
              height: "350px",
              maxHeight: "100%",
              overflow: "scroll",
            }}
            content={({ close }) => (
              <Menu >
                <Menu.Group >
                  {searchResults.map((faceDetails) => (
                    <Menu.Item key={faceDetails.name} height={40}>
                      <Pane justifyContent="left" display="flex" className="noselect">
                        <Text color="#283655" fontSize={14}>{faceDetails.name}</Text>
                      </Pane>
                    </Menu.Item>
                  ))}
                </Menu.Group>
              </Menu>
            )}
          >
            <Pane height={0} width={0} marginLeft="auto" marginRight="auto" background="#47B881" />
          </Popover>

        </Pane>


        <Pane>
        <GridContextProvider onChange={onChange}>
          <div
            style={{
              display: "flex",
              touchAction: "none",
              // width: "800px",
              // margin: "1rem auto",
            }}
          >
            <GridDropZone
              style={{
                flex: 1,
                height: "400px",
                // border: "1px solid rgba(0, 0, 0, 0.1)",
                // borderRadius: "1rem",
              }}
              id="left"
              boxesPerRow={4}
              rowHeight={70}
            >
              {items.left.map(item => (
                <GridItem key={item.name}>
                  <div >
                    <div >
                      {item.name[0].toUpperCase()}
                    </div>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </GridContextProvider>
        </Pane>

        

      </Pane>
      <Footer/>
    </Pane>
  );
}

export default Testing;