const initialState = {
	localStage: null,
  localMode: null,
};

const profileStateReducer = (
	state = initialState,
	action) => {
	switch(action.type){
		case 'SET_PROFILE_STATE':
			if (!action.payload) {
				return initialState;
			}
			return action.payload;
		case 'UPDATE_PROFILE_STATE':
			if (!action.payload) {
				return initialState;
			}
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export default profileStateReducer;