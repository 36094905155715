import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, generatePath, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Button, Table, Dialog, IconButton, CrossIcon, Icon, Spinner, Paragraph, Strong, CleanIcon, MenuOpenIcon, LayersIcon, GroupObjectsIcon, TrendingUpIcon, CaretRightIcon, Popover, Position, ArrowRightIcon, ChevronRightIcon, ArrowTopRightIcon, EndorsedIcon, InfoSignIcon, Tooltip, AddIcon, ShopIcon, ChevronUpIcon, ChevronDownIcon, MoreIcon, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';

import { I18n } from 'aws-amplify';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import numeral from 'numeral';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import InfiniteScroll from "react-infinite-scroll-component";

import CarouselIcon from '../assets/icons/CarouselIcon';

import styled, { StyledFunction } from "styled-components";

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import FaceAvatar from './FaceAvatar';
import TransactionFundingStructure from './TransactionFundingStructure';

import { useGetTransactions } from "../services/useGetTransactions";



import AspectRatio from './AspectRatio';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserState, setProfileDetails, updateProfileDetails,setTransactionDetails, updateTransactionDetails } from '../services/actions';


const ImageOverlayBlock = styled.div`
  background-color: #28365565; // 0.65 opacity
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  border-radius: inherit;
  &:hover {
    opacity: 1;
  }
`;

const FeedbackTree = ({ transaction = null, history = null, depth = 0, ...rest}) => {

  const profilePublicFaceId = useSelector(state => state.profileDetails.publicFaceId);

  const transactionHistory = history || (transaction && depth === 0 ? [transaction] : []);
  // const parentTransaction = !transaction ? transaction : null;

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  // const [isShowBranchToggle, setIsShowBranchToggle] = useState(depth === 0 ? false : true);
  // const [isShowBranch, setIsShowBranch] = useState(true);

  const hideAllProofImages = false;


  return(

    !Array.isArray(transactionHistory) || transactionHistory.length === 0 ? null :

    <Fragment>
      {transactionHistory.map((historyTransaction, index) => {

        let transactionWithFace = historyTransaction.toFace; // depends of action type
        let relativePublicFaceId = depth > 0 ? transaction.toPublicFaceId : profilePublicFaceId;
        let actionType = null;

        if (historyTransaction.toPublicFaceId === relativePublicFaceId) {
          if (historyTransaction.type === "provision" || historyTransaction.type === "provisionByProvider") {
            transactionWithFace = historyTransaction.fromFace;
          }
          if (historyTransaction.fromPublicFaceId === relativePublicFaceId) {
            // what happens to linked-to-face provision of transaction.type === "provisionByProvider" ???
            actionType = "own provision";
          }
        }
        else if (historyTransaction.fromPublicFaceId === relativePublicFaceId) {
          // ???
        }

        const color = depth === 0 ? "#47B881" : "#283655";
        let fundingTransactions = historyTransaction.history?.filter(t => t.role === "fundingTransaction") || []; // what Transactions fund this T

        const showProofImages = (!hideAllProofImages && historyTransaction.proofImagesCount > 0) ? true : false; 


        if (historyTransaction.role === "fundingTransaction") {
          return null;
        }

        return(
          <Pane width="100%" marginTop={4} key={historyTransaction.publicTransactionId} >
              
            <Pane flex="auto" display="flex" alignItems="center" width="100%" minWidth={150} height={"auto"} >

              <Pane flex="none" display="flex" alignItems="center" >
                <FormattedCurrencyNumber number={historyTransaction.amount} currency={historyTransaction.currency} fontSize={depth === 0 ? 24 : 14} isRound={true} fontColor={color} />
              </Pane>

              {actionType === "own provision" ? null : 
              <Fragment>
                {/* <Pane flex="none" marginRight={4} >
                  <ArrowTopRightIcon color="#7B8B9A" size={14} />
                </Pane> */}
                {/* <Pane flex="none" marginX={4} >
                  <ArrowRightIcon color="#7B8B9A" size={14} />
                </Pane> */}
                <Pane flex="none" marginX={depth === 0 ? 2 : 0} height={"100%"} >
                  <ChevronRightIcon color="#7B8B9A" size={depth === 0 ? 14 : 12} />
                </Pane>
                
                <Pane >
                  {transactionWithFace?.publicFaceId ? 
                    <Link target="_blank" className='text-link'
                      to={{
                        pathname: `/${transactionWithFace.publicFaceId}`,
                        // search: "?sort=name",
                        // hash: "#the-hash",
                        // state: { fromDashboard: true }
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Strong fontSize={depth === 0 ? 16 : 14} color="#283655">{transactionWithFace.name || I18n.get('anonymous')}</Strong>
                    </Link>
                  :
                    <Strong fontSize={depth === 0 ? 16 : 14} color="#283655">{transactionWithFace?.name || I18n.get('anonymous')}</Strong>
                  }
                </Pane>

                {transactionWithFace?.verifiedUNIX ?
                <Pane flex="none" marginLeft={depth === 0 ? 4 : 2} height={depth === 0 ? 20 : 18} >
                  <EndorsedIcon color="#1070CA" size={depth === 0 ? 14 : 12} />
                </Pane>
                : null}

              </Fragment>}

            </Pane>

            {depth > 0 && fundingTransactions?.length > 1 ?
              <Pane display="flex" alignItems="center" marginBottom={4} >
                <Pane flex="none" marginRight={depth === 0 ? 4 : 4} height={depth === 0 ? 14 : 12} lineHeight={1}>
                  <GroupObjectsIcon color="#7B8B9A" size={depth === 0 ? 14 : 12} />
                </Pane>
                <Text flex={"none"} fontSize={depth === 0 ? 12 : 11} lineHeight={1} color="#7B8B9A">{`${fundingTransactions?.length} ${I18n.get("contributors")}`}</Text>
              </Pane>
            : null }

            {historyTransaction.matched ?
              <Pane display="flex" alignItems="center">
                <Pane flex="none" marginRight={2} >
                  <CleanIcon color="#FFB020" size={depth === 0 ? 14 : 12} />
                </Pane>
                <Text flex={"none"} fontSize={depth === 0 ? 12 : 11} color="#7B8B9A">{`+${handleFormattedCurrencyNumber(historyTransaction.amount, historyTransaction.currency, false, true)} ${I18n.get("match-funding")}`}</Text>
              </Pane>
            : null }
            
            {historyTransaction.history?.length > 0 || showProofImages ?
              <Pane marginTop={2} display="flex" alignItems="stretch" >

                <Pane flex="none" background="#E6E8F0" borderRadius={2} width={4} height={"auto"} marginRight={4} ></Pane>
                
                <Pane width={"100%"}>
                  {showProofImages ?
                    <Pane background="#E6E8F0" borderRadius={15} marginTop={4} width={"100%"} >
                    
                      <AspectRatio ratio={1/1} >
                        {historyTransaction.proofImagesCount > 1 ?
                          <Pane position="absolute" top={0} right={0} padding={6} width={32} height={32} >
                            <CarouselIcon color="#FFFFFF" width={18} height={"auto"} />
                          </Pane>
                        : null }
                        
                        <Pane width="100%" height="100%" marginLeft="auto" marginRight="auto" borderRadius={15}
                          // border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderTopLeftRadius={10} borderTopRightRadius={10}
                        >
                          <img src={historyTransaction.proofImagesUrls[0]} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit",}} />
                        </Pane>

                        <ImageOverlayBlock >
                          <Pane position="absolute" top={"50%"} left={"50%"} width="100%" transform={"translate(-50%, -50%)"} textAlign="center" >
                            <Strong marginLeft="auto" marginRight="auto" fontSize={16} color="#FFFFFF">{I18n.get("Click to see more")}</Strong>
                          </Pane>
                        </ImageOverlayBlock>

                      </AspectRatio>
                      
                    </Pane>
                  : null}

                  <FeedbackTree transaction={historyTransaction} history={historyTransaction.history} depth={depth + 1} />

                </Pane>
                
              </Pane>
            : null}
            
          </Pane>
        );
      })}
    </Fragment>
  );
}

{/* <Pane width="100%" marginX={5} marginBottom={5} >
  {!formattedDate || !userState.user?.rights?.adminPanel ? null :
    <Pane textAlign="center" marginY={4}>
      <Text flex={"none"} size={300} color="#7B8B9A">{formattedDate}</Text>
    </Pane>
  } 
    
  <Button height="auto" width="100%" paddingY={0} paddingX={5} appearance="minimal" intent="none"
    is={Link} to={{
      pathname: generatePath("/:locale?/t/:publicTransactionId", {...match.params, publicTransactionId: transactionDetails.publicTransactionId}),
      state: {
        isModal: true,
      },
    }}
  >
    <Fragment>
      {actionType === "donation to" ?
        <FeedbackTree transaction={transactionDetails} depth={0} />
      : null}

      {actionType === "donation from" ?
        <Fragment>
          <Pane alignItems="center" display="contents" flexWrap="wrap">
            <Pane flex="auto" width="100%" minWidth={150} height={"auto"} >
              <Pane height={16}>
                <Text flex={"none"} size={300} color="#7B8B9A">{action}</Text>
              </Pane>
              <Pane >
                {transactionWithFace.publicFaceId ? 
                  <Link target="_blank" className='text-link'
                    to={{
                      pathname: `/${transactionWithFace.publicFaceId}`,
                      // search: "?sort=name",
                      // hash: "#the-hash",
                      // state: { fromDashboard: true }
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Strong fontSize={16} color="#283655">{transactionWithFace.name || I18n.get('anonymous')}</Strong>
                  </Link>
                :
                  <Strong fontSize={16} color="#283655">{transactionWithFace.name || I18n.get('anonymous')}</Strong>
                }
                
              </Pane>
            </Pane>

            <Pane flex={1} width="100%" height={"auto"} textAlign="right" display="flex" alignItems="center" >
              <Pane flex="none" display="flex" alignItems="center" >
                <FormattedCurrencyNumber number={transactionDetails.amount} currency={transactionDetails.currency} isRound={true} fontSize={24} fontColor={color} />
                {transactionDetails.matched ?
                  <Strong marginLeft={4} display="inline-flex" height={20} fontSize={12} color={color} >{ "×2" }</Strong>
                : null }
              </Pane>
            </Pane>
          </Pane>
        </Fragment>
      : null}
      

      {actionType === "own provision" ?
        <Fragment>
          {transactionDetails.proofImagesUrls ?
            <Pane alignItems="center" display="contents" flexWrap="wrap">
              <Pane marginTop={5} marginBottom={5} width="100%" height="100%" >
                <AspectRatio ratio={1/1} >
                  {transactionDetails.proofImagesCount > 1 ?
                    <Pane position="absolute" top={0} right={0} padding={6} width={32} height={32} >
                      <CarouselIcon color="#FFFFFF" width={18} height={"auto"} />
                    </Pane>
                  : null }
                  
                  <Pane width="100%" height="100%" marginLeft="auto" marginRight="auto"
                    // border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderTopLeftRadius={10} borderTopRightRadius={10}
                  >
                    <img src={transactionDetails.proofImagesUrls[0]} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit",}} />
                  </Pane>

                  <ImageOverlayBlock >
                    <Pane position="absolute" top={"50%"} left={"50%"} width="100%" transform={"translate(-50%, -50%)"} textAlign="center" >
                      <Strong marginLeft="auto" marginRight="auto" fontSize={16} color="#FFFFFF">{I18n.get("Click to see more")}</Strong>
                    </Pane>
                  </ImageOverlayBlock>

                </AspectRatio>
              </Pane>
            </Pane>
          : null }
        </Fragment>
      : null}

      {actionType === "provision to" ?
        null
      : null}

      {actionType === "provision to" || actionType === "provision from" ?
        <Fragment>
          <Pane alignItems="center" display="contents" flexWrap="wrap">
            <Pane flex="auto" width="100%" minWidth={150} height={"auto"} >
              <Pane height={16}>
                <Text flex={"none"} size={300} color="#7B8B9A">{action}</Text>
              </Pane>
              <Pane >
                {transactionWithFace.publicFaceId ? 
                  <Link target="_blank" className='text-link'
                    to={{
                      pathname: `/${transactionWithFace.publicFaceId}`,
                      // search: "?sort=name",
                      // hash: "#the-hash",
                      // state: { fromDashboard: true }
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Strong fontSize={16} color="#283655">{transactionWithFace.name || I18n.get('anonymous')}</Strong>
                  </Link>
                :
                  <Strong fontSize={16} color="#283655">{transactionWithFace.name || I18n.get('anonymous')}</Strong>
                }
                
              </Pane>
            </Pane>

            <Pane flex={1} width="100%" height={"auto"} textAlign="right" display="flex" alignItems="center" >
              {((transactionDetails.type === "provision" || transactionDetails.type === "provisionByProvider") && transactionDetails.proofImagesUrls) ?
                <Pane marginTop={4} marginBottom={6} flex={1} width="auto" height="auto" >
                  <AspectRatio ratio={1/1} >
                    <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto"
                      border borderWidth={0} borderStyle="solid" borderColor="#E4E7EB" borderTopLeftRadius={3} borderTopRightRadius={3}
                    >
                      <img src={transactionDetails.proofImagesUrls[0]} alt="" style={{"width": "100%", "height": "100%", "cover": "scale-down", "borderRadius": "inherit",}} />
                    </Pane>
                  </AspectRatio>
                  <Pane paddingX={8} paddingY={2} background={color} borderBottomLeftRadius={5} borderBottomRightRadius={5} textAlign="center" >
                    <FormattedCurrencyNumber number={transactionDetails.amount} currency={transactionDetails.currency} fontSize={16} fontColor={"#FFFFFF"} />
                  </Pane>
                </Pane>
              : null }
              
            </Pane>
          </Pane>
        </Fragment>
      : null}

      
    </Fragment>
  </Button>
</Pane> */}

const ProfileHistoryTableRow = ({ transactionDetails, index, profileHistory, profilePublicFaceId }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    // console.log(`ProfileHistoryTableRow - ${countRef.current}`);
    return () => {
      // console.log("ProfileHistoryTableRow - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  let location = useLocation();
  const userState = useSelector(state => state.userState);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();


  // if (transactionDetails.toPublicFaceId === profilePublicFaceId) {
  //   if (transactionDetails.type === "provision" || transactionDetails.type === "provisionByProvider") {
  //     transactionWithFace = transactionDetails.fromFace; 
  //     action = I18n.get('provision from');
  //     actionType = "provision from";
  //     color = "#EC4C47";
  //     if (transactionDetails.fromPublicFaceId === profilePublicFaceId) {
  //       // what happens to linked-to-face provision of transaction.type === "provisionByProvider" ???
  //       actionType = "own provision";
  //     }
  //   }
  // }
  // else if (transactionDetails.fromPublicFaceId === profilePublicFaceId) {
  //   action = I18n.get('donation to');
  //   actionType = "donation to";
  //   transactionWithFace = transactionDetails.toFace; 
  //   if (transactionDetails.type === "provision" || transactionDetails.type === "provisionByProvider") {
  //     action = I18n.get('provision to');
  //     actionType = "provision to";
  //     color = "#735DD0";
  //   }
  // }

  // if (transactionDetails.createdAtUNIX) {
  //   if (index === 0) {
  //     formattedDate = formatDateFns(new Date(transactionDetails.createdAtUNIX * 1000), "d/M/yyyy");
  //   }
  //   else {
  //     if (!isSameDay(new Date(transactionDetails.createdAtUNIX * 1000), new Date(profileHistory[index-1].createdAtUNIX * 1000))) {
  //       formattedDate = formatDateFns(new Date(transactionDetails.createdAtUNIX * 1000), "d/M/yyyy");
  //     }
  //   }
  // }

  if (transactionDetails.status === "restricted") {
    return null;
  }
  
  return (

    <Fragment>
      <Table.Row is={"tr"}
        key={transactionDetails.publicTransactionId}
        height="auto" 
        minHeight={40}
        width={"100%"}
        style={{border: "none"}}
        paddingBottom={0}
      >
        <Table.TextCell is={"td"} padding={0}>{
          <Pane margin={5}>
            <Button
              // display="block"
              width="100%"
              height="auto"
              textAlign="inherit"
              padding={0}
              appearance="minimal"
              // disabled={transactionDetails.publicTransactionId ? false : true}
              onClick={() => {
                if (transactionDetails.publicTransactionId) {
                  let newUrl = generatePath("/:page?/:publicTransactionId", {...match.params, page: "t", publicTransactionId: transactionDetails.publicTransactionId});
                  window.open(newUrl, '_blank', 'noopener,noreferrer');
                }
              }}
              style={{
                borderRadius: "5px"
              }}
              // margin={6}
              // className="noselect"
            >
              <Pane width={"100%"} borderWidth={1} borderRadius={5} borderStyle="solid" borderColor="#435A6F20">

                <Pane paddingY={8} paddingX={8} >
                  <FaceAvatar
                    faceDetails={transactionDetails.fromFace}
                  />
                  {!transactionDetails.viaPublicFaceId ? null : 
                    <Pane >

                      <Pane paddingTop={2} >
                        <Text fontSize={12} color="#283655" >
                          {I18n.get("via")}{" "}
                        </Text>

                        <Strong fontSize={12} color="#283655" >
                          {transactionDetails.viaFace?.name}
                        </Strong>
                      </Pane>

                    </Pane>
                  }
                </Pane>
                
                {transactionDetails.type === "provisionByProvider" ? null :
                  <Pane
                    borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"
                    paddingTop={8}
                    paddingX={8}
                  >
                    <TransactionFundingStructure transactionDetails={transactionDetails} hideFundingHistoryButton={true} />
                  </Pane>
                }

                {!transactionDetails.history?.find((t) => (t.type === "feedback" && t.role === "released"))?.proofImagesUrls?.length ?
                  null :
                  <Pane
                    // borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"
                    display="flex"
                    border={"inherit"}
                    // marginTop={8}
                    // paddingTop={8}
                    // paddingX={8}
                  >
                    <img src={transactionDetails.history?.find((t) => (t.type === "feedback" && t.role === "released"))?.proofImagesUrls[0]} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "border": "inherit",}} />
                  </Pane>
                }


                {process.env.NODE_ENV !== "production" && false ?
                  <Fragment>
                    <Pane paddingTop={2} paddingX={8} >
                      <Text lineHeight={1} fontSize={10} fontStyle="italic" color="#7B8B9A" >{I18n.get("Visible only in dev")}</Text>
                    </Pane>
                      
                    <Pane paddingTop={2} paddingX={8} >
                      <Text lineHeight={1} fontSize={10} fontStyle="italic" color="#7B8B9A" >{I18n.get(transactionDetails.type)}</Text>
                    </Pane>

                    <Pane paddingTop={2} paddingX={8} >
                      <Text lineHeight={1} fontSize={10} fontStyle="italic" color="#7B8B9A" >{I18n.get(transactionDetails.mode)}</Text>
                    </Pane>

                    <Pane paddingTop={2} paddingX={8} >
                      <Text lineHeight={1} fontSize={10} fontStyle="italic" color="#7B8B9A" >{I18n.get(transactionDetails.status)}</Text>
                    </Pane>

                  </Fragment>
                : null }
                

              </Pane>
            </Button>
            
          </Pane>
        }</Table.TextCell>
      </Table.Row>
    </Fragment>

  );

}

const ProfileHistoryTab = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileHistoryTab - ${countRef.current}`);
    return () => {
      console.log("ProfileHistoryTab - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(false);
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  useEffect(() => {
    setLocalLoadState({
      results: profileDetails?.history?.results || [],
      total: profileDetails?.history?.total,
      nextToken: profileDetails?.history?.nextToken || null,
      error: null,
    })
  }, [profileDetails?.history?.nextToken, profileDetails?.history?.results, profileDetails?.history?.total]);


  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };

  // ???
  // useEffect(() => {
  //   if (profileDetails?.publicFaceId) {
  //     // setTransactionList([]);
  //     setTriggerLoad(true);
  //   }
  // }, [profileDetails?.publicFaceId]);
  
  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        fromPublicFaceId: profileDetails.publicFaceId,
        toPublicFaceId: profileDetails.publicFaceId,
        viaPublicFaceId: profileDetails.publicFaceId,
        type: "pageHistory",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          setLocalLoadState((prev) => {
            let updatedResultsList = [...prev?.results || [], ...searchResult.results];

            let updatedProfileHistory = {
              ...prev,
              results: updatedResultsList,
              total: searchResult.total,
              nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
              error: null,
            };
            dispatch(updateProfileDetails({
              history: updatedProfileHistory,
            }));

            return updatedProfileHistory;
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [dispatch, handleGetTransactions, localLoadState, localeState.currency, localeState.locale, profileDetails.publicFaceId, triggerLoad]);


  // clear "history" mode
  useEffect(() => {
    if (match.params.mode === "history") {
      history.replace({
        pathname: generatePath(match.path, {...match.params, mode: null}),
      });
    }
  }, [history, match.params, match.path]);

  if (match.params.mode && location.state?.prevMode !== null) {
    return null;
  }

  if (!profileDetails?.history?.results) {
    return null
  }

  return (

    <Fragment>
      <Table is={"table"} width={"100%"} display={"block"}
        style={{
          borderCollapse: "collapse"
        }}
      >

        {!localLoadState.total ?
          localLoadState.total === 0 ?
            <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={120}>
              <Pane textAlign="center" >
                <Pane >
                  <Strong fontSize={14} color="#7B8B9A">{I18n.get("No history yet")}</Strong>
                </Pane>
                <Pane >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Transactions will be displayed here.")}</Text>
                </Pane>
              </Pane>
            </Pane>
          :
            <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={120}>
              <Spinner size={24} />
            </Pane>
        :
          <InfiniteScroll
            dataLength={localLoadState?.results?.length}
            next={fetchMoreData}
            hasMore={localLoadState?.results?.length < localLoadState.total}
            scrollThreshold={0.6}
            // scrollableTarget="scrollableDiv"
            // height={240}
            // loader={<h4>Loading...</h4>}
          >
          
            <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
              {localLoadState?.results?.map((item, index, array) => (
                <ProfileHistoryTableRow transactionDetails={item} index={index} profileHistory={profileDetails?.history?.results} profilePublicFaceId={profileDetails.publicFaceId} />
              ))}

              {localLoadState?.results?.length < localLoadState.total ?
                <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={50}>
                  <Spinner size={24} />
                </Pane>
              : null }

            </Table.Body>

          </InfiniteScroll>
        }
      </Table>
    </Fragment>
  );
}

export default ProfileHistoryTab;