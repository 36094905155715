import { Fragment, useEffect, useState, useCallback } from 'react';
import { ReactComponent as FdCookie } from '../images/fd_img_cookie96.svg';
import { Link, useRouteMatch, useHistory, useLocation, generatePath } from 'react-router-dom';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Portal, Strong, Button, Pane } from 'evergreen-ui';
import { I18n } from 'aws-amplify';
import { useCookies } from 'react-cookie';

const CookiesConsent = () => {
  
  const localeState = useSelector(state => state.localeState);
  
  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  // console.log("CookiesConsent location", location);
  // console.log("CookiesConsent history", history);
  // console.log("CookiesConsent match", match);

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']); // https://www.npmjs.com/package/react-cookie

  const [hide, setHide] = useState((cookies._cc === "1"));
  useEffect(() => {
    setHide((cookies._cc === "1"));
  }, [cookies._cc]);
  
  const handleSetCookiesConsent = () => {
    let d = new Date();
    d.setMonth(d.getMonth() + 6);
    setCookie('_cc', 1, { path: '/', expires: d });
    setHide(true);
  }

  useEffect(() => {
    if (cookies._cc === "1" && localeState.viewerLanguage === localeState.language) {
      let d = new Date();

      d.setMonth(d.getMonth() + 12000);
      setCookie("_pl", localeState.locale, { path: '/', expires: d });

      d = new Date();
      d.setMonth(d.getMonth() + 12000);
      setCookie("_plang", localeState.language, { path: '/', expires: d });

      d = new Date();
      d.setMonth(d.getMonth() + 12000);
      setCookie("_pc", localeState.currency, { path: '/', expires: d });
    }
    else if (cookies._cc !== "1") {
      // _cc may be removed in RemoveCookiesConsentButton below
      removeCookie("_cc");
      removeCookie("_vc");
      removeCookie("_pl");
      removeCookie("_plang");
      removeCookie("_pc");
    }
  }, [cookies._cc, localeState.currency, localeState.language, localeState.locale, localeState.viewerLanguage, removeCookie, setCookie]);

  // manipulate URL
  useEffect(() => {
    if (localeState.viewerLanguage === localeState.language) {
      let queryParams = new URLSearchParams(location.search);
      let adjustedQuerystring = "";
      let currentHl = queryParams.get('hl');
      let currentLang = "";
      if (currentHl) {
        currentLang = currentHl.substring(0, 2) || "";
      }

      if (currentLang) {
        console.log("clear locale in URL");
        queryParams.delete('hl');
        if (queryParams.toString()) {
          adjustedQuerystring = `?${queryParams.toString()}`;
        }
        history.replace({
          // pathname: generatePath(match.path, {...match.params, locale: null}),
          search: adjustedQuerystring,
        });
      }
    }
  }, [history, localeState.language, localeState.viewerLanguage, location.search]);

  if (hide) {
    return null;
  }


  return (
    <Portal>
      <Pane position="fixed" bottom={0} width="100%" zIndex={9999} >
        <Pane
          marginLeft="auto" marginRight="auto" justifyContent="center"
          display="flex"
          alignItems="center"
        >

          <Pane
            justifyContent="center"
            elevation={3}
            paddingX={18}
            paddingTop={18}
            paddingBottom={8}
            margin={24}
            background="tint2"
            borderRadius={10}
          >
            <Pane display="flex" width="fit-content"  alignItems="center" flexWrap="wrap" justifyContent="center" >
              

              <Pane marginRight={18} marginBottom={10} display="flex" alignItems="center" flexWrap="wrap">
                <Pane flex="none" width={24} height={24} marginRight={18} >
                  <FdCookie width="100%" height="100%" />
                </Pane>
                <Pane flex={1} >
                  <Strong fontSize={16} color="#283655" > {I18n.get('We use essential cookies.')} <Link to={generatePath("/:locale?/:page", {...match.params, page: "legal"})} target="_blank" className='blue-link'>{`${I18n.get('Learn more')}...`}</Link> </Strong>
                </Pane>
              </Pane>

              <Pane textAlign="center" marginBottom={10} >
                <Button paddingX={18} height={40} iconAfter={"arrow-right"} className='button-green'
                  onClick={handleSetCookiesConsent}
                  >
                  {I18n.get('Continue').toUpperCase()}
                </Button>
              </Pane>
            </Pane>
          </Pane>

        </Pane>
      </Pane>
    </Portal>
  );
}

const RemoveCookiesConsentButton = () => {
  
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  return (
    <Fragment>
      <Button fontSize={16} padding={8} height={50} className='button-green' width="100%" justifyContent="center"
        disabled={false}
        onClick={() => {
          // removing _cc clears other cookies ??? no, it doesn't trigger re-render
          removeCookie("_cc");
        }}
      >
        {I18n.get('Stop accepting cookies')}
      </Button>
    </Fragment>
  );
}

export default CookiesConsent;
export { RemoveCookiesConsentButton };