import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';

import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import CameraComponent from './CameraComponent';
import DialogHeader from './DialogHeader';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

import { I18n } from 'aws-amplify';


const TakePicture = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TakePicture - ${countRef.current}`);
    return () => {
      console.log("TakePicture - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  const dialogState = useSelector(state => state.dialogState);

  const [processing, setProcessing] = useState(false);

  const handleOnChange = useCallback(({ pictureB64, qrCodeData }) => {
    dispatch(updateDialogState({
      qrCodeDataToProcess: qrCodeData,
      pictureB64ToProgress: pictureB64
    }));
  }, [dispatch]);


  return(
    <Pane padding={10}>

      {/* <DialogHeader
        headerText={I18n.get('New picture')}
        hideLeftButton={false}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        // leftButtonIcon={ChevronLeftIcon}
        onLeftButtonClick={onClose}
        // rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      /> */}

      <Pane borderRadius={5} background="#E4E7EB" >
        <CameraComponent
          // ref={cameraRef}
          isOn={true}
          isUserFacing={dialogState.params?.localCameraIsUserFacing}
          scanQrCodes={false}
          // pictureB64={}
          // placeholder={}
          // overlay={}
          ratio={4/5} // 1/1 , 4/5, 9/16 // https://later.com/blog/instagram-image-size/
          dict={{
            'Open camera': I18n.get('Open camera'),
            'Retake': I18n.get('Retake'),
            'Use photo': I18n.get('Use photo'),
          }}
          placeholderIsShown={false}
          showButtons={true}
          showTakenPicture={true}
          isProcessing={processing}
          showRetakeButton={true}
          onChange={handleOnChange} // exposes value - .pictureB64 or .qrCodeData
          pictureQuality={0.98}
          maxPictureWidth={1920} // 1350
          maxPictureHeight={1920} // 1350
          onClose={() => {
            dispatch(setDialogState(null)); // this will close the dialog
          }}
        />
      </Pane>
      

    </Pane>
  );
}

export default TakePicture;