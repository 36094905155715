import { useEffect, useCallback, useState} from 'react';

import { Link, generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import { Heading, Strong, Text, Button, Pane } from 'evergreen-ui';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Footer from '../components/Footer';

import { useSelector } from 'react-redux';

const Directory = () => {

  let match = useRouteMatch();
  // console.log("match", match);
  let history = useHistory();
  // console.log("history", history);

  const userState = useSelector(state => state.userState);
  // const localeState = useSelector(state => state.localeState);
  
  const [levelList, setLevelList] = useState();
  const [level, setLevel] = useState();

  useEffect(() => {
    if (!match.params.mode) {
      history.replace({
        pathname: generatePath(match.path, {...match.params, mode: "profiles"}),
      });
    }
  }, [history, match.params, match.path]);


  const handleSetListOfUsernames = useCallback(async(nextToken) => {
    if (!userState.isLoading) {
      try {
        const response = await API.graphql({
          query: queries.listUsernames,
          variables: {
            nextToken: nextToken,
          },
          authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        });
        let listOfUsernames = response.data.listUsernames;
        // console.log("listOfUsernames", listOfUsernames);
        setLevelList(listOfUsernames);
      }
      catch (error) {
        console.error("Error when listUsernames", error);
      }
    }
  }, [userState.isLoading, userState.user]);

  useEffect(() => {
    let tempArray = [];
    if (match.params.nextToken) {
      let a = match.params.nextToken.split(/[ -]+/);
      if (a.length === 1) {
        for (let i = 0; i < 5; i++) { // should be 100
          tempArray.push(i);
        }
        setLevel(1);
        setLevelList(tempArray);
      }
      else if (a.length === 2) {
        setLevel(2);
        // console.log("FETCH PROFILES using:", match.params.nextToken);
        handleSetListOfUsernames(match.params.nextToken);
      }
    }
    else {
      for (let i = 0; i < 1; i++) { // should be 100
        tempArray.push(i);
      }
      setLevel(0);
      setLevelList(tempArray);
    }
  }, [handleSetListOfUsernames, match.params.nextToken]);

  return (
    <Pane >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>
        <Heading size={700} marginTop={16} marginLeft={10} color="#283655">{I18n.get('Profiles directory')}</Heading>
        <Pane marginTop={10} marginBottom={10}>
          <ul style={{"display": "flex", "flexDirection": "row", "flexWrap": "wrap", "columnCount": 4, "padding": "10px", "margin": "0px"}}>
          {levelList ?
            levelList.map((item, index) => (
              <li key={item} style={{"display": "block", "marginTop": "0px", "marginBottom": "8px"}}>
                <Link
                  to={{
                    pathname: level === 2 ? generatePath("/:locale?/:handle", {...match.params, handle: item }) : generatePath(match.path, {...match.params, nextToken: match.params.nextToken ? match.params.nextToken + "-" + item : item }),
                    // state: {},
                  }}
                  tabIndex="0" className="button-link"
                >
                  <Strong style={{"display": "block", "width":"200px", "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": "nowrap"}} size={400} color="#283655" >{item}</Strong>
                </Link>
              </li>
            ))
          : null
          }
          </ul>
        </Pane>
      </Pane>
      <Footer/>
    </Pane>
  );
}

export default Directory;