const HealthIcon = ({size="inherit", style={}, color, width="1em", height="1em", opacity=1, ...rest}) => {

  return (
    <svg
      viewBox="0 0 29 20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fontSize={size}
      width={width}
      height={height}
      opacity={opacity}
      style={style}
      {...rest}
    >

      <path
        d="M28.6 10.643c0-1.2-.77-2.172-1.936-2.172-.583 0-1.286.638-1.668 1.03-.382-.392-1.039-1.032-1.622-1.032-1.166 0-1.984.973-1.984 2.174 0 .6.237 1.143.62 1.536l2.988 2.995 2.985-2.992-.002-.002c.383-.393.619-.937.619-1.537"
        fillRule="nonzero"
        fill={color || "#000000"}
      />
      <path
        d="M15.9 20.014l-.03-.001a.49.49 0 01-.454-.407l-1.518-8.843-2.3 4.264a.491.491 0 01-.918-.163L9.704 8.04l-1.787 4.013a.491.491 0 01-.448.291H.526a.49.49 0 110-.982H7.15L9.5 6.084a.492.492 0 01.934.13l1.007 7.037 2.268-4.206a.49.49 0 01.916.15l1.406 8.19L21.155.363a.49.49 0 01.912-.072l2.697 5.59a.49.49 0 01-.884.426L21.73 1.854l-5.361 17.81a.49.49 0 01-.47.35"
        fillRule="nonzero"
        fill={color || "#000000"}
      />

    </svg>

  );
}

export default HealthIcon;