interface UploadState {
	completed: [],
	failed: [],
	progress: {},
}

const initialState: UploadState = {
	completed: [],
	failed: [],
	progress: {},
};


const uploadStateReducer = (
	state: UploadState = initialState,
	action: any,
) => {

	switch(action.type){
		case 'SET_UPLOAD_STATE':
			if (!action.payload) {
				return initialState;
			}
			return action.payload;
		case 'UPDATE_UPLOAD_STATE':
			if (!action.payload) {
				return initialState;
			}
			return {
				...state,
				...action.payload,
			};
			
		default:
			return state;
	}

}

export default uploadStateReducer;