import {Fragment, cloneElement, useEffect, useState, useRef, useMemo, useCallback} from 'react';

import {Link, Redirect, useHistory, useParams, generatePath, useLocation, useRouteMatch} from 'react-router-dom';
import {Text, majorScale, minorScale, TextInput, Dialog, TextInputField, CrossIcon, Paragraph, Button, Checkbox, IconButton, Avatar, Pane, ListItem, UnorderedList, Strong } from 'evergreen-ui';

import 'react-responsive-modal/styles.css';
import { Modal as RespModal } from 'react-responsive-modal';

const ModalResponsive = ({
  isShown = false,
  onOpenComplete = () => {},
  onCloseComplete = () => {},
  children,
  modalStyleProps = {},
  ...rest
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ModalResponsive - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("ModalResponsive - cleaned up");
    }
  }, []);

  const isShownRef = useRef(isShown);

  const handleOpenModal = useCallback(() => {
    // console.log("Opening...");
    document.body.style["overflow"] = "hidden"; // keep
    // document.body.style["-webkit-overflow-scrolling"] = "auto"; // ???
    isShownRef.current = true;
    onOpenComplete();
  }, [onOpenComplete]);

  const handleCloseModal = useCallback(() => {
    // console.log("Closing...");
    document.body.style["overflow"] = "auto"; // keep
    onCloseComplete();
    isShownRef.current = false;
  }, [onCloseComplete]);


  useEffect(() => {
    if (isShown !== true && isShownRef.current === true ) {
      handleCloseModal();
    }
  }, [handleCloseModal, isShown]);

  if (isShown !== true) {
    return null;
  }

  return (
    <Fragment >
      <RespModal
        open={isShown === true} // https://react-responsive-modal.leopradel.com/#props
        center
        showCloseIcon={false}
        blockScroll={false} // Whether to block scrolling when dialog is open.
        // onClose={onCloseModal}
        onAnimationEnd={() => {
          if (isShown) {
            handleOpenModal();
          }
          // else {
          //   handleCloseModal();
          // }
        }}
        closeOnEsc={false}
        onEscKeyDown={(e) => {}}
        closeOnOverlayClick={false}
        onOverlayClick={(e) => {}}
        focusTrapped={false}
        styles={{
          overlay: {
            "background": "#435a6fb3", // #435a6fb3 // #7B8B9A10
            "backdropFilter": "blur(1px)",
            "WebkitBackdropFilter": "blur(1px)",
          },
          // modalContainer: {},
          modal: {
            "width": "100%",
            "maxWidth": "350px",
            "height": "auto",
            "maxHeight": "calc(100vh - 36px * 2)",
            // "minHeight": "200px",

            "background": "#FFFFFF00",
            "boxShadow": "none",
            "margin": 0,
            "padding": 0,
            "borderRadius": 10,

            "overflow": "scroll",
            "overscrollBehavior": "auto !important",
            "WebkitOverflowScrolling": "touch", // -webkit-overflow-scrolling
            ...modalStyleProps
          },
        }}
        classNames={{
          // root: "string",
          // overlay: "string",
          // overlayAnimationIn: "string",
          // overlayAnimationOut: "string",
          // modal: "string",
          // modalAnimationIn: "string",
          // modalAnimationOut: "string",
          // closeButton: "string",
          // closeIcon: "string",
        }}
      >
        {children({ close: handleCloseModal })}
      </RespModal>
    </Fragment>
  );
}

export default ModalResponsive;