import { useEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Table, Button, Pane } from 'evergreen-ui';

import { useAuthUserSignOut } from "../services/useAmplifyAuth";

import { I18n } from 'aws-amplify';

import Footer from '../components/Footer';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

const AuthPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("AuthPage", countRef.current)
    return () => {
      console.log("AuthPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const browserInfo = useSelector(state => state.localeState.browserInfo);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const handleUserSignOut = useAuthUserSignOut();

  useEffect(() => {
    if (!userState.isLoading && !userDetails) {
      history.replace({
        pathname: "/log-in", // generatePath(match.path, {...match.params, page: ""}),
        // search: "",
        // state: state,
      });
    }
  }, [handleUserSignOut, history, match.params.page, userDetails, userState.isLoading]);


  return (
    <Fragment >
    
      <Pane clearfix minWidth={320} maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        {userState.isLoading || !userDetails ?
          "Loading..."
        : null}

        {userDetails ?
          <Pane
            // marginTop={is575PlusVH ? "10vh" : null}
            maxWidth={630} marginLeft="auto" marginRight="auto"
          >

            <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
              <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
              <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{I18n.get("You are already logged-in")}</Strong>
              <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
            </Pane>

            {/* <Pane textAlign="center" marginTop={16}>
              <Text size={500} color="#283655">{I18n.get("XXX.")}</Text>
            </Pane> */}

            <Pane marginTop={16}></Pane>
          

            <Fragment>

              <Button marginTop={12} width={"100%"} height={50} padding={8} justifyContent="center" className="noselect"
                borderRadius={5}
                disabled={false}
                onClick={() => {
                  history.replace({
                    pathname: generatePath("/:handle", {...match.params, handle: userDetails?.username}),
                    // search: "",
                    // state: state,
                  });
                }}
              >
                <Strong fontSize={16} color={"#283655"} >{I18n.get("Go to your page")}</Strong>
              </Button>

              <Pane paddingX={"25%"} marginTop={12} marginLeft="auto" marginRight="auto" width={"100%"} alignItems="center" display="flex" flexWrap="wrap" >
                <Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
                <Text flex={"none"} marginX={5} fontSize={16} height="1em" lineHeight={1} color="#7B8B9A" className="noselect" >{I18n.get('or')}</Text>
                <Pane flex={1} marginTop={5} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
              </Pane>

              <Button marginTop={12} width={"100%"} height={40} paddingX={8} justifyContent="center" className="noselect" 
                borderRadius={5}
                disabled={false}
                onClick={() => {
                  handleUserSignOut({
                    isGlobal: false,
                    onSuccess: () => {
                      history.replace({
                        pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
                        // search: "",
                        // state: state,
                      });
                    },
                    onError: (error) => {
                      // handle ???
                    },
                  });
                }}
                >
                  <Strong fontSize={16} color={"#EC4C47"} >{I18n.get("Sign out")}</Strong>
              </Button>

            </Fragment>

          </Pane>
        : null }

      </Pane>
      <Footer/>

    </Fragment>
  );
}

export default AuthPage;