import {useEffect, useState, useRef} from 'react';
import {Link, Redirect, NavLink, generatePath, useRouteMatch, useLocation, useHistory} from 'react-router-dom';
import {Heading, Text, Menu, Link as A, Paragraph, Icon, IconButton, LinkIcon, UnorderedList, ListItem, ChevronDownIcon, ChevronRightIcon, Button, Pane} from 'evergreen-ui';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Markdown from 'markdown-to-jsx';

import Footer from '../components/Footer';

import { useSelector } from 'react-redux';

const markdownOptions = {
  overrides: {
    h1: {
      component: Heading,
      props: {
        size: 700,
        marginTop: 24,
        color: "#283655",
      },
    },
    h2: {
      component: Heading,
      props: {
        size: 600,
        marginTop: 12,
        color: "#283655",
      },
    },
    p: {
      component: Paragraph,
      props: {
        marginTop: "default",
      },
    },
    ul: {
      component: UnorderedList,
      props: {
        marginTop: "default",
      },
    },
    li: {
      component: ListItem,
      props: {
        marginTop: "default",
      },
    },
    a: {
      component: A,
      props: {
        className: "blue-link",
      },
    },
  },
};


const Questions = () => {

  let match = useRouteMatch();
  let location = useLocation();
  let history = useHistory();
  const localeState = useSelector(state => state.localeState);
  
  const currentIdSum = useRef(0);

  const [preExpandedQIds, setPreExpandedQIds] = useState([]);

  let faqItems = [];
  
  switch (localeState.language) {
    case "it": {
      // notice these extra curly braces
      faqItems = [
        {
          id: 1,
          uri: "how_do_i_donate_to_someone_i_want_to_help",
          heading: "Come faccio a donare a qualcuno che voglio aiutare?",
          content: `
          Scatta una foto al destinatario della tua donazione: per registrarsi è sufficiente un'immagine del viso di una persona che chiede il tuo aiuto. Scansiona il codice QR del destinatario, se si trova già su FACE DONATE e scopri la sua storia.
          `,
        },
        {
          id: 2,
          uri: "how_to_donate_to_someone_without_seeing_them_in_person",
          heading: "How to donate to someone without seeing them in person? How to donate to someone without seeing them in person?",
          content: `
          If you know the recipient or the provider you would like to support with your donation you can simply donate to them via their personal page on our website.
          
          It is very easy: when anyone donates to a person (after they or someone else has registered the recipient), we ask for an email address to send the donor a 'Thank you' message (and the receipt for the donation they just made). In this email, the donor will receive the unique ID of the recipient.
          
          This unique ID is like an @handler which if clicked will bring on the recipient's personal page, where further donations can be made without taking new images.
          `,
        }
      ];
      break;
    }
    default: {
      faqItems = [
        {
          id: 1,
          uri: "how_do_i_donate_to_someone_i_want_to_help",
          heading: "How do I donate to someone I want to help?",
          content: `
          Take a picture of the donation recipient - just one face image of a person that asks your help is enough for you to register them. Scan the recipient’s QR code, if they already are on FACE DONATE and learn about them.
          `,
        },
        {
          id: 2,
          uri: "how_to_donate_to_someone_without_seeing_them_in_person",
          heading: "How to donate to someone without seeing them in person?",
          content: `
          If you know the recipient or the provider you would like to support with your donation you can simply donate to them via their personal page on our website.
          
          It is very easy: when anyone donates to a person (after they or someone else has registered the recipient), we ask for an email address to send the donor a 'Thank you' message (and the receipt for the donation they just made). In this email, the donor will receive the unique ID of the recipient.
          
          This unique ID is like an @handler which if clicked will bring on the recipient's personal page, where further donations can be made without taking new images.
          `,
        }
      ];
    }
  };

  return (
    <Pane >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>
        <Pane >


          <Pane>

            <Accordion
            preExpanded={preExpandedQIds}
            onChange={(uuids) => {
              let difference = 0;
              let oldCurrentIdSum = currentIdSum.current;
              if (uuids.length > 0) {
                currentIdSum.current = uuids.reduce((a, c) => a + c);
                difference = currentIdSum.current - oldCurrentIdSum;
              }
              else {
                currentIdSum.current = 0;
                difference = 0;
              }
              let uri = null
              if (difference > 0) {
                let currentItem = faqItems.filter((item) => item.id === difference);
                uri = currentItem[0].uri;
              }
              history.push({
                pathname: generatePath(match.path, {...match.params, uri: uri}),
                search: location.search,
              });
            }}
            allowZeroExpanded
            allowMultipleExpanded
            >
              {faqItems.map((item) => (
                <AccordionItem key={item.id} uuid={item.id} >
                  <AccordionItemHeading>
                      <AccordionItemButton style={{outline: "none"}}>
                          <Pane marginTop={16} minHeight={40} height="auto" justifyContent="left" display="flex" alignItems="center" >
                            <AccordionItemState>
                            {({ expanded }) => (
                              expanded ?
                              <Icon flex="none" icon={ChevronDownIcon} color="#283655" size={20} />
                              : 
                              <Icon flex="none" icon={ChevronRightIcon} color="#283655" size={20} />
                              )}
                            </AccordionItemState>
                            <Heading marginLeft={12} width="100%" size={700} color="#283655">{item.heading}</Heading>
                            <Link to={generatePath(match.path, {...match.params, uri: item.uri})} target="_blank" style={{"textDecoration": "none"}} >
                              <IconButton marginLeft={12} height={28} icon={LinkIcon} appearance="minimal"
                                onClick={() => {}}
                              />
                            </Link>
                          </Pane>
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Markdown
                      children={item.content.replace(/^[ ]{1,}/gm, "\n\n")}
                      options={markdownOptions}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>

          </Pane>
        </Pane>
      </Pane>
      <Footer />
    </Pane>
  );
}

export default Questions;