import { useEffect, useMemo, useState, useCallback, useRef, Fragment } from 'react';

import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Button, Pane } from 'evergreen-ui';

import { I18n } from 'aws-amplify';
import Footer from '../components/Footer';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

const SystemPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("SystemPage", countRef.current)
    return () => {
      console.log("SystemPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const browserInfo = useSelector(state => state.localeState.browserInfo);
  // const dialogState = useSelector(state => state.dialogState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);


  return (
    <Fragment >

      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>


        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"System"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane marginTop={20} borderRadius={10} padding={8} background="#FAFBFF" >
            <Pane >
              <Strong fontSize={14} color="#425A70" >{I18n.get('System parameters')}</Strong>
            </Pane>
            
            <Pane >
              <Text fontSize={14} color="#283655" >{
                `${"User"}: ${userDetails?.publicFaceId || "n.a."}`
              }</Text>
            </Pane>
            
            {Object.entries(browserInfo).map(([key,value], i) => (
              <Pane key={i} >
                <Text fontSize={14} color="#283655" >{
                  `${key}: ${value}`
                }</Text>
              </Pane>
            ))}
          </Pane>

      </Pane>
      <Footer/>
    </Fragment>
  );
}

export default SystemPage;