import { useEffect, useState, useCallback, useRef } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Heading, Strong, Text, Table, Button, Pane } from 'evergreen-ui';

import { useMediaQuery } from 'react-responsive';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Footer from '../components/Footer';

import { useSelector } from 'react-redux';

const WidgetDemoPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("WidgetDemoPage", countRef.current)
    return () => {
      console.log("WidgetDemoPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

  // const userState = useSelector(state => state.userState);

  return (
    <Pane >
      
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>


        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Widget Demo"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">

          <Pane width="100%">

          <iframe
            height="600"
            width="100%"
            // style="width: 100%;"
            scrolling="no"
            title="FaceDonate HTML Widget"
            src="https://codepen.io/face_donate/embed/MWOYQmN?default-tab=html%2Cresult"
            frameborder="no"
            loading="lazy"
            allowtransparency="true"
            allowfullscreen="true"
          >
            See the Pen
            <a href="https://codepen.io/face_donate/pen/MWOYQmN"> FaceDonate HTML Widget </a>
            by FACE DONATE (<a href="https://codepen.io/face_donate">@face_donate</a>)
            on
            <a href="https://codepen.io">CodePen</a>.
          </iframe>

          </Pane>

        </Pane>

      </Pane>
      <Footer/>
    </Pane>
  );
}

export default WidgetDemoPage;