import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo, useContext} from 'react';

import { Link, Redirect, generatePath, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import { Text, Button, Table, Dialog, IconButton, CrossIcon, Icon, Paragraph, Strong, Popover, Menu, Position, InfoSignIcon, Tooltip, AddIcon, ShopIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon, MoreIcon, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import numeral from 'numeral';
import QRCode from 'qrcode';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import FormattedCurrencyNumber from './FormattedCurrencyNumber';


import QRCodeIcon from '../assets/icons/QRCodeIcon';

import PaperPlaneIcon from '../assets/icons/PaperPlaneIcon';
import CommentIcon from '../assets/icons/CommentIcon';
import ProfileIcon from '../assets/icons/ProfileIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';

import AspectRatio from './AspectRatio';
import { useSelector, useDispatch } from 'react-redux';

const ProfileHistoryRow = ({ transaction, transactionIndex, array, modalClose }) => {

  let match = useRouteMatch();
  let location = useLocation();

  const localeState = useSelector(state => state.localeState);

  console.log(`renderHistoryRow runs...`);
  
    let action = I18n.get('donation from');
    let color = "#47B881"; // green
    let transactionWithPublicFaceId = null;
    let transactionWithName = null;
    let formattedDate = null;
    let tDemoSupporter = "Demo Supporter";
    let tDemoProvider = "Demo Provider";
    let tDemoRecipient = "Demo Recipient";

    let tHidden = "Hidden";
    let tAnonymous = "Anonymous";
    // De Rigo - Tappa #1

    switch (localeState.language) {
      case "it":
        tHidden = "Privato";
        tAnonymous = "Anonymous";
        tDemoSupporter = "Demo Supporter";
        tDemoProvider = "Demo Provider";
        tDemoRecipient = "Demo Recipient";
        break;
  
      default:
        break;
    }

    let currentAmount = transaction.amount;
    let currentCurrency  = transaction.currency;
    let relatedAmount =  null;
    let relatedCurrency = null;

    transactionWithName = tAnonymous;
  
    if (transaction.createdAtUNIX) {
      
      // show supporting AND reducing transactions
      
      if (transaction.role === "fundingTransaction") {
        // fundingTransaction supports (green) the amount
        color = "#47B881"; // green
        // show who from
        transactionWithPublicFaceId = transaction.fromPublicFaceId;
        transactionWithName = transaction.fromFace.name || tAnonymous;
        if (transaction.type === "matchFunding") {
          action = I18n.get('match donation from');
        }
      }
      else if (transaction.role === "fundedTransaction") {
        // fundedTransaction reduces (red) the amount
        color = "#EC4C47"; // red
        // show who to
        action = I18n.get('donation to');
        transactionWithPublicFaceId = transaction.toPublicFaceId;
        transactionWithName = transaction.toFace.name || tAnonymous;

        // provision related transactions may only be reducing the amount
        if (transaction.type === "provision" || transaction.type === "provisionByProvider" || transaction.type === "demoProvision") {
          action = I18n.get('provision from');
          transactionWithPublicFaceId = transaction.fromPublicFaceId;
          transactionWithName = transaction.fromFace.name || tAnonymous;
        }

        if (transaction.type === "matchFunding" && transaction.type === "donation") {
          action = I18n.get('matching donation of');;
          transactionWithPublicFaceId = transaction.fromPublicFaceId;
          transactionWithName = transaction.fromFace.name || tAnonymous;
          if (transaction.relatedAmount) {
            currentAmount = transaction.relatedAmount;
            currentCurrency  = transaction.relatedCurrency;
          }
        }
      }

      // if (transaction.type === "donation"|| transaction.type === "transfer" || transaction.type === "demoDonation") {
        
      // }
      // else if (transaction.type === "provision" || transaction.type === "provisionByProvider" || transaction.type === "demoProvision") {

      // }

      // color = "#735DD0"; // purple

      if (transaction.type === "demoDonation") {
        transactionWithName = tDemoSupporter;
        transactionWithPublicFaceId = null;
      }
      if (transaction.type === "demoProvision") {
        transactionWithName = tDemoProvider;
        transactionWithPublicFaceId = null;
      }
  
      if (transactionIndex === 0) {
        formattedDate = formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d/M/yyyy");
      }
      else {
        if (!isSameDay(new Date(transaction.createdAtUNIX * 1000), new Date(array[transactionIndex-1].createdAtUNIX * 1000))) {
          formattedDate = formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d/M/yyyy");
        }
      }
    }
    else {
      return null;
    }
  
    return (
      <Fragment>
        <Table.Row key={transaction.publicTransactionId} minHeight={40} height="auto" style={{border: "none"}} >


          <Pane width="100%" marginX={5} marginBottom={5} >
            {!formattedDate ? null :
              <Pane textAlign="center" marginY={4}>
                <Text flex={"none"} size={300} color="#7B8B9A">{formattedDate}</Text>
              </Pane>
            } 

              <Button height="auto" width="100%" paddingY={0} paddingX={5} appearance="minimal" intent="none"
                is={Link} to={{
                  pathname: generatePath("/:locale?/t/:publicTransactionId", {...match.params, publicTransactionId: transaction.publicTransactionId}),
                  state: {
                    isModal: modalClose ? false : true,
                  },
                }}
              >
              
                <Pane alignItems="center" display="contents" flexWrap="wrap">
                  <Pane flex="auto" width="100%" minWidth={150} height={"auto"} >
                    <Pane height={16}>
                      <Text flex={"none"} size={300} color="#7B8B9A">{action}</Text>
                    </Pane>
                    <Pane >
                      {transactionWithPublicFaceId ? 
                        <Link target="_blank" className='text-link'
                          to={{
                            pathname: `/${transactionWithPublicFaceId}`,
                            // search: "?sort=name",
                            // hash: "#the-hash",
                            // state: { fromDashboard: true }
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          
                          {transactionWithPublicFaceId === 'rehmpu' ? 
                            <Strong fontSize={16} color="#283655">{`De Rigo - Tappa
                            ${match.params.publicTransactionId === "tturmwhwee" ? " №8" : ""}
                            ${match.params.publicTransactionId === "tckpduxhut" ? " №7" : ""}
                            ${match.params.publicTransactionId === "tetrmwytey" ? " №6" : ""}
                            ${match.params.publicTransactionId === "tehuxpruup" ? " №5" : ""}
                            ${match.params.publicTransactionId === "tehtwtmrhm" ? " №4" : ""}
                            ${match.params.publicTransactionId === "tmwmcxwpxt" ? " №3" : ""}
                            ${match.params.publicTransactionId === "tpyxtxepce" ? " №2" : ""}
                            ${match.params.publicTransactionId === "ttwhukecxe" ? " №1" : ""}
                            `}</Strong>
                            
                            :
                            <Strong fontSize={16} color="#283655">{transactionWithName.toUpperCase()}</Strong>
                          }
                        </Link>
                      :
                        <Strong fontSize={16} color="#283655">{transactionWithName.toUpperCase()}</Strong>
                      }
                      
                    </Pane>
                  </Pane>

                  <Pane flex={1} width="100%" height={"auto"} textAlign="right" display="flex" alignItems="center">
                    {((transaction.type === "provision" || transaction.type === "provisionByProvider" || transaction.type === "demoProvision") && transaction.proofImagesUrls) ?
                      <Pane marginTop={4} marginBottom={6} flex={1} width="100%" height="auto" >
                        <AspectRatio ratio={1/1} >
                          <Pane display="flex" borderTopLeftRadius={10} borderTopRightRadius={10} >
                            <img style={{width: "100%", height: "100%", borderRadius: "inherit"}} src={transaction.proofImagesUrls[0]} alt="" />
                          </Pane>
                        </AspectRatio>
                        <Pane paddingX={8} paddingY={2} background={color} borderBottomLeftRadius={10} borderBottomRightRadius={10} textAlign="center" >
                          <FormattedCurrencyNumber number={currentAmount} currency={currentCurrency} fontSize={16} fontColor={"#FFFFFF"} />
                        </Pane>
                      </Pane>
                    :
                      <Pane flex="none" display="flex" alignItems="center" >
                        <FormattedCurrencyNumber number={currentAmount} currency={currentCurrency} fontSize={24} fontColor={color} />
                      </Pane>
                    }
                    
                  </Pane>
                </Pane>
              </Button>
          </Pane>
        </Table.Row>
      </Fragment>
    );
}

const TransactionHistoryTable = ({ modalClose, transactionDetails }) => {

  const countRef = useRef(0);
  useEffect(() => {
    countRef.current = countRef.current + 1;
  }, []);
  console.log(`TransactionHistoryTable ${countRef.current}`);

  let match = useRouteMatch();
  let location = useLocation();

  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  return (
    <Table >
      <Table.Body >
        {/* {transaction.history?.map((transaction, index, array) => (
          <ProfileHistoryRow
            transaction={transaction}
            transactionIndex={index}
            array={array}
          />
        )} */}
      </Table.Body>
    </Table>
  );

}

export default TransactionHistoryTable;