import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Button, Pane } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FacePH from '../images/FacePH';

import { I18n } from 'aws-amplify';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, updateProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

const FaceAvatar = ({
  faceDetails,
  height = 32,
  fontSize = 14,
  pictureOnly = false,
  anonymisedFaceDetails,
}) => {

  // console.log("anonymisedFaceDetails", anonymisedFaceDetails);

  return (
    <Fragment>
      <Pane display="flex" alignItems={"center"} >
      
        <Pane display="flex" >
          {faceDetails?.pictureUrl ?
            <img
              width={height}
              height={height}
              src={faceDetails?.pictureUrl}
              alt=""
              style={{
                borderRadius: "50%",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#E4E7EB",
              }}
              objectFit={"scale-down"}
            />
          :
            <Pane
              width={height}
              height={height}
              padding={"10%"}
              display="flex"
              border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB"
              borderRadius={"50%"}
            >
              <FacePH color={true ? "#7B8B9A" : "#E4E7EB"} width="100%" height="100%" />
            </Pane>
          }
        </Pane>

        {pictureOnly ? null :
        
          <Pane marginLeft={fontSize - 6} overflow={"hidden"} >

            {!anonymisedFaceDetails ? 
              <Fragment>
                <Strong display="block" lineHeight={1.3} fontSize={fontSize} color="#283655"
                  whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
                >
                  {faceDetails?.name}
                </Strong>

                <Text display="block" lineHeight={1.1} fontSize={fontSize - 2} color="#7B8B9A"
                  whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
                >
                  {faceDetails?.username}
                </Text>
              </Fragment>
            :
              <Fragment>
                <Text display="block" lineHeight={1.1} fontSize={fontSize} color="#7B8B9A"
                  whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
                >
                  {I18n.get('Anonymised recipient')}
                </Text>

                {/* <Text display="block" lineHeight={1.1} fontSize={fontSize - 2} color="#7B8B9A"
                  whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"
                >
                  {"by username"}
                </Text> */}
              </Fragment>
            }

          </Pane>
        }

      </Pane>

    </Fragment>
    
  );
}

export default FaceAvatar;