import { useEffect, useState, useRef, useCallback, useMemo}  from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { Text, Pane, Popover, Menu, Position, Strong, Button, Heading, Dialog, IconButton, CrossIcon, ChevronUpIcon, ChevronDownIcon } from 'evergreen-ui';

import styled, { StyledFunction } from "styled-components"

import { ReactComponent as EUFlag } from '../assets/flags/eu.svg';
import { ReactComponent as GBFlag } from '../assets/flags/gb.svg';
import { ReactComponent as ITFlag } from '../assets/flags/it.svg';
import { ReactComponent as ZAFlag } from '../assets/flags/za.svg';
import { ReactComponent as BGFlag } from '../assets/flags/bg.svg';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateLocaleState } from '../services/actions';
import localeDictionary, { localePathOptions, langLocaleOptions, areaServedOptions, langOptions, supportedLanguages, languages, countryData, currencies } from '../services/localeDictionary';
import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";


const FlagByLanguage = ({ language }) => {
  switch (language) {
    case 'en':
      return (
        <GBFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'it':
      return (
        <ITFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'bg':
      return (
        <BGFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    case 'za':
      return (
        <ZAFlag style={{"borderRadius": "inherit"}} width="100%" height="100%" />
      );
    default:
      return null;
  }
};

const LanguageRows = ({ onSelect }) => {

  const renderPickLanguageRow = useCallback((item) => {
    if (item.hide) {
      return null;
    }
    return (
      <Menu.Item
        key={item.language}
        onSelect={() => {
          onSelect(item);
        }}
      >
        <Pane alignItems="center" display="flex" flexWrap="wrap" marginTop={3} >
          <Pane padding={2} width={28} height="100%" borderRadius={3} >
            <FlagByLanguage language={item.language} />
          </Pane>
          <Text flex={"none"} size={300} marginLeft={8} >{item.name}</Text>
        </Pane>
      </Menu.Item>
    );
  }, [onSelect]);

  return (
    Object.values(languages).map((value) => (renderPickLanguageRow(value)))
  );
}

const useHandleLocaleSelection = () => {

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  // const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const handleLocaleSelection = useCallback((language = null, currency = null) => {
    console.log("handleLocaleSelection runs...");
    console.log("language", language);
    console.log("currency", currency);

    dispatch(updateLocaleState({
      language: language,
      currency: currency,
    }));

    // adjust user settings ??? more away to call after updateLocaleState completed
    // batch(() => {
    //   if (userDetails) {
    //     if (userDetails.locale !== locale || userDetails.currency !== currentCurrency) {
    //       handleUpdateUserDetails({
    //         userDetailsToUpdate: {
    //           // locale: userDetails.locale !== locale ? locale : null,
    //           language: "",
    //           currency: userDetails.currency !== currentCurrency ? currentCurrency : null,
    //         },
    //         // onSuccess: () => {},
    //         onError: (error) => {
    //           console.error('within handleUpdateUserDetails, within handleLocaleSelection', error);
    //         },
    //       });
    //     }
    //   }
    // });

  }, [dispatch]);

  return handleLocaleSelection;
}

const PickLanguage = ({fontColor, fontSize, hideLabel = false, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PickLanguage - ${countRef.current}`);
    return () => {
      console.log("PickLanguage - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);

  const currentSelectedLanguageRef = useRef(localeState.language);
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(currentSelectedLanguageRef.current);
  useEffect(() => {
    currentSelectedLanguageRef.current = localeState.language;
    setCurrentSelectedLanguage(currentSelectedLanguageRef.current);
  }, [localeState.language]);

  const handleLocaleSelection = useHandleLocaleSelection();

  return (
    <Pane alignItems="center" display="inline-flex" flexWrap="wrap" >
      {hideLabel ? null :
        <Pane flex={"none"} marginRight={4} >
          <Text color={fontColor || "#283655"} fontSize={fontSize || 14} >{I18n.get('Language')}</Text>
        </Pane>
      }
      <Pane flex={"none"} >
        <Popover minWidth={100} width={100}
          position={Position.TOP}
          onCloseComplete={() => {
            if (currentSelectedLanguageRef.current !== localeState.language) {
              handleLocaleSelection(currentSelectedLanguageRef.current, null)
            };
          }}
          content={({ close }) => (
            <Menu >
              <Menu.Group >
                <LanguageRows onSelect={(selectedItem) => {
                  close();
                  currentSelectedLanguageRef.current = selectedItem.language;
                  setCurrentSelectedLanguage(selectedItem.language);
                }} />
              </Menu.Group>
            </Menu>
          )}
        >
          <Button margin={0} padding={2} width={24} height="auto" >
            <FlagByLanguage language={currentSelectedLanguage}/>
          </Button>
        </Popover>
      </Pane>

    </Pane>
  );
}

const CssOrderMinusOne = styled.div`
  @media only screen and (max-width: ${425}px) {
      order: -1;
  }
`;

const SelectLanguageDefault = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`SelectLanguageDefault - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("SelectLanguageDefault - cleaned up");
    }
  }, []);

  const handleLocaleSelection = useHandleLocaleSelection();

  const localeState = useSelector(state => state.localeState);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();


  const [isShown, setIsShown] = useState(localeState.viewerLanguage !== localeState.language ? true : false);
  const selectedLanguageRef = useRef(localeState.viewerLanguage || localeState.language);
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageRef.current);
  const [isMenuShown, setIsMenuShown] = useState(false);

  
  
  // this is needed to replace depreciated .getByLanguage()
  let viewerDict = {
    'Choose language': null,
    'Continue': null,
  };
  if (isShown) {
    I18n.setLanguage(localeState.viewerLanguage);
    viewerDict = {
      'Choose language': I18n.get('Choose language'),
      'Continue': I18n.get('Continue'),
    };
    I18n.setLanguage(localeState.language);
  }

  if (!isShown) {
    return null;
  }

  return (
    <Pane background="tint1" >
      <Pane clearfix minWidth={320} maxWidth={960} paddingX={12} marginLeft="auto" marginRight="auto" >
        <Pane marginTop={10} padding={8} alignItems="center" display="flex" flexWrap="wrap" justifyContent="center">

          <Pane flex="auto" display="flex"marginRight={16} order={-2} height={40} alignItems="center" >
            <Pane>
              <Strong fontSize={16} color="#283655">{viewerDict['Choose language'] || I18n.get('Choose language')}</Strong>
              {viewerDict['Choose language'] === I18n.get('Choose language') ? null : 
                <Pane >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get('Choose language')}</Text>
                </Pane>
              }
            </Pane>
          </Pane>

          <Pane flex="auto" gap={16} alignItems="center" display="flex" flexWrap="wrap" justifyContent="right" >
            <Pane flex="auto" maxWidth={275} >
              <Popover
                width={200}
                position={Position.Bottom}
                onOpen={() => {
                  // setIsMenuShown(true);
                }}
                onClose={() => {
                  setIsMenuShown(false);
                }}
                content={({ close }) => (
                  <Menu >
                    <Menu.Group >
                      <LanguageRows onSelect={(selectedItem) => {
                        setSelectedLanguage(selectedItem.language);
                        close();
                      }} />
                    </Menu.Group>
                  </Menu>
                )}
              >
                <Button height={40} width="100%" paddingX={12} >
                  <Pane display="flex" flex="auto" flexWrap="wrap" alignItems="center" >
                    <Pane flex={1} width="100%" height="auto" display="flex" flexWrap="wrap" alignItems="center" >
                      <Pane width={24} display="flex" borderRadius={3} >
                        <FlagByLanguage language={selectedLanguage} />
                      </Pane>
                      <Text marginLeft={8} fontSize={16} color="#283655" height={"fit-content"} >{languages[selectedLanguage].name}</Text>
                    </Pane>
                    <Pane marginLeft={8} flex="none" display="flex" >
                      {isMenuShown ? <ChevronUpIcon size={20} color="#283655" /> : <ChevronDownIcon size={20} color="#283655" />}
                    </Pane>
                  </Pane>
                </Button>
              </Popover>
            </Pane>
          

            <Pane className="noselect" >
              <Button fontSize={16} padding={12} height={40} className='button-green' width="100%" justifyContent="center"
                onClick={() => {
                  handleLocaleSelection(selectedLanguage, null);
                  setIsShown(false);
                }}
              >
                {viewerDict['Continue'] ? viewerDict['Continue'].toUpperCase() : I18n.get('Continue').toUpperCase()}
              </Button>
            </Pane>
          </Pane>
          <CssOrderMinusOne>
            <Pane marginLeft={12} padding={8} className="noselect" >
              <IconButton icon={CrossIcon} iconSize={18}
                onClick={() => {
                  handleLocaleSelection(localeState.language, null);
                  setIsShown(false);
                }}
              />
            </Pane>
          </CssOrderMinusOne>

        </Pane>
      </Pane>
    </Pane>
  );
}

const SelectLanguageDropdown = ({fontColor, fontSize, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`SelectLanguageDropdown - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("SelectLanguageDropdown - cleaned up");
    }
  }, []);

  const handleLocaleSelection = useHandleLocaleSelection();

  const localeState = useSelector(state => state.localeState);

  const selectedLanguageRef = useRef(localeState.viewerLanguage || localeState.language);
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageRef.current);
  const [isMenuShown, setIsMenuShown] = useState(false);


  return (
    <Pane flex="auto" alignItems="center" display="flex" flexWrap="wrap" maxWidth={200} >
      <Popover
        width={200}
        position={Position.Bottom}
        onOpen={() => {
          // setIsMenuShown(true);
        }}
        onClose={() => {
          setIsMenuShown(false);
        }}
        content={({ close }) => (
          <Menu >
            <Menu.Group >
              <LanguageRows onSelect={(selectedItem) => {
                setSelectedLanguage(selectedItem.language);
                handleLocaleSelection(selectedItem.language, null);
                close();
              }} />
            </Menu.Group>
          </Menu>
        )}
      >
        <Button width="100%" padding={8} >
          <Pane display="flex" flex="auto" flexWrap="wrap" alignItems="center" >
            <Pane flex={1} width="100%" height="auto" display="flex" flexWrap="wrap" alignItems="center" >
              <Pane width={24} display="flex" borderRadius={3} >
                <FlagByLanguage language={selectedLanguage} />
              </Pane>
              <Text marginLeft={8} color={fontColor || "#283655"} fontSize={fontSize || 14} height={"fit-content"} >{languages[selectedLanguage].name}</Text>
            </Pane>
            <Pane marginLeft={8} flex="none" display="flex" >
              {isMenuShown ? <ChevronUpIcon size={20} color="#283655" /> : <ChevronDownIcon size={20} color="#283655" />}
            </Pane>
          </Pane>
        </Button>
      </Popover>
    </Pane>
  );
}

export default PickLanguage;
export { SelectLanguageDefault, SelectLanguageDropdown, useHandleLocaleSelection, FlagByLanguage};