import React from 'react';
// covert SVG here - https://react-svgr.com/

const FacebookIcon = (props) => {
  // fill={props.color || "#000000"}

  return (
    <svg
      viewBox="0 0 160 160"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M160 80c0-44.183-35.817-80-80-80C35.819 0 0 35.817 0 80c0 39.93 29.255 73.027 67.5 79.028v-55.903H47.189V80h20.313V62.375c0-20.05 11.943-31.125 30.217-31.125 8.752 0 17.908 1.562 17.908 1.562V52.5h-10.088C95.6 52.5 92.5 58.667 92.5 64.993V80h22.187l-3.547 23.125h-18.64v55.903C130.746 153.027 160 119.93 160 80z"
        fill={props.color || "#000000"}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default FacebookIcon;