import {Fragment, cloneElement, useEffect, useState, useRef, useMemo, useCallback} from 'react';

import {Link, Redirect, useHistory, useParams, generatePath, useLocation, useRouteMatch} from 'react-router-dom';

const DynamicIconButton = ({ children, size, color, onHoverColor, onClickColor, onClick, href, target,
	padding,
	...rest
}) => {

	const CustomTag = href ? "a" : "button";

	const [currentColor, setCurrentColor] = useState(color);

  return (
    <CustomTag href={href} target={target} style={{"fontSize": size || 18, "padding": padding, "border": 0, "display": "inline-flex", "background": "none",}}
			// https://www.w3schools.com/jsref/event_onmouseenter.asp
			onMouseOver={() => {setCurrentColor(onHoverColor || color)}}
			onMouseOut={() => {setCurrentColor(color)}}
			// onMouseLeave={() => {}}
			// onMouseDown={() => {}}

			onClick={() => {
				setCurrentColor(onClickColor || color);
				onClick();
			}}
		>
			{cloneElement(children, {
				color: currentColor || "#000000",
			})}
    </CustomTag>
  );
}

export default DynamicIconButton;