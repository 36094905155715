import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { setProfileDetails, setTransactionDetails, updateTransactionDetails } from './actions';

import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const useAdminTopUp = () => {
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  
  const localeState = useSelector(state => state.localeState);

  const handleAdminTopUp = useCallback(async({
    toPublicFaceId = null,
    fromPublicFaceId = null,
    amount = null,
    currency = localeState.currency,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    
    try {
      const response = await API.graphql({
        query: mutations.createAdminTransaction,
        variables: {
          toPublicFaceId: toPublicFaceId,
          fromPublicFaceId: fromPublicFaceId,
          type: "topUp",
          amount: amount,
          currency: currency,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const transactionDetails = response.data.createAdminTransaction;

      if (typeof onSuccess === "function") {
        onSuccess(transactionDetails);
      }
          
    }
    catch (error) {
      console.error(`within handleProcessGiftAidTransactions`, error);

      if (typeof onError === "function") {
        onError(error);
      }
      
      dispatch(updateTransactionDetails({
        localError: error,
      }));
      
    }

  }, [dispatch, localeState.currency, userState.user]);

  return handleAdminTopUp;
}




// export default useAdminTransactions;
export {
  useAdminTopUp,
};