import React, { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { CookiesProvider } from 'react-cookie';
import { createStore } from 'redux';
import allReducers from './services/reducers';
import { Provider } from 'react-redux';

const store = createStore(
	allReducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// https://stackoverflow.com/questions/71685441/react-testing-library-gives-console-error-for-reactdom-render-in-react-18

root.render(
	<StrictMode>
		<Provider store={store}>
			<CookiesProvider>
				<App />
			</CookiesProvider> 
		</Provider>
	</StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
