import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Button, Pane } from 'evergreen-ui';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import { I18n } from 'aws-amplify';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateTransactionDetails } from '../services/actions';

const TransactionTimestamp = ({ transactionDetails }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionTimestamp - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("TransactionTimestamp - cleaned up");
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	const profileDetails = useSelector(state => state.profileDetails);
	const localeState = useSelector(state => state.localeState);

  if (!transactionDetails.createdAtUNIX) {
    return null;
  }

  return (
    <Pane paddingTop={4} paddingBottom={4} display="flex" alignItems={"center"} >

      <Text fontSize={12} color="#7B8B9A" >
        {formatDateFns(new Date(transactionDetails.createdAtUNIX * 1000), "d MMM yyyy, HH:mm")}
      </Text>

    </Pane>
  );

}

export default TransactionTimestamp;