interface DialogState {
	isProcessing: boolean,
	componentName?: string | null | undefined,
	qrCodeDataToProcess?: string | null,
	pictureB64ToProgress?: string | null,
	// status?: string | null | undefined,
	// mode: null, 
	params?: {
		localCameraIsUserFacing: boolean,
		header: string | null,
		body: string | null,
	},
	onChange?: any, // should go, no passing functions
	onClose?: any, // should go, no passing functions
}

const initialState: DialogState = {
	isProcessing: false,
	componentName: null,
	qrCodeDataToProcess: null,
	pictureB64ToProgress: null,
	// status: null,
	// mode: null, 
	params: {
		localCameraIsUserFacing: false,
		header: null,
		body: null,
	},
	onChange: null, // should go, no passing functions
	onClose: null, // should go, no passing functions
};


const dialogStateReducer = (
	state: DialogState = initialState,
	action: any,
) => {

	switch(action.type){
		case 'SET_DIALOG_STATE':
			if (!action.payload) {
				return initialState;
			}
			return {
				...initialState,
				...action.payload,
			};
		case 'UPDATE_DIALOG_STATE':
			if (!action.payload) {
				return initialState;
			}
			return {
				...initialState,
				...state,
				...action.payload,
			};
			
		default:
			return state;
	}

}

export default dialogStateReducer;