import { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';

import { ReactComponent as FdIngCap } from '../images/fd_img_cap.svg';

import { Link, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

import { TextInput, IconButton, Heading, Pill, toaster, Icon, LinkIcon, Tooltip, InfoSignIcon, Text, Strong, OrderedList, ListItem, TextInputField, Paragraph, Table, Button, Checkbox, Pane } from 'evergreen-ui';
import CountUp, { useCountUp } from 'react-countup';
import { useMediaQuery } from 'react-responsive';
import { currencies } from '../services/localeDictionary';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';

import GalleryOfSupport from './GalleryOfSupport';

import { useSelector, useDispatch, batch } from 'react-redux';

const AchievementsSection = () => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AchievementsSection - ${countRef.current}`);
    return () => {
      console.log("AchievementsSection - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();

  const basicStats = useSelector(state => state.basicStats);
  const localeState = useSelector(state => state.localeState);

  const howToHelpRef = useRef(null);

  const is426PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });

  useEffect(() => {
    if (match.params.mode) {
      let currentMode = match.params.mode.toLowerCase();
      if (currentMode === "contribute") {
        window.scrollTo({left:0, top: howToHelpRef.current.offsetTop - 20, behavior: 'smooth'});
      }
      // else if (currentMode === "institutions") {
      //   window.scrollTo({left:0, top: weWorkWithInstitutions.current.offsetTop - 20, behavior: 'smooth'});
      // }
    }
  }, [match.params.mode]);
  

  let tAchievements = "Our achievements";
  let tDonors = "Donors";
  let tDonations = "Donations";
  let tRecipients = "Recipients";
  let tDonationsValueReceived = "Total"; // "Spent";
  let tDonationsValueReceivedTT = "The total amount all our recipients have received.";
  let tProviders = "Providers";
  let tProvisions = "Provisions";

  switch (localeState.language) {
    case "it":
      tAchievements = "I nostri risultati";
      tDonors = "Donatori";
      tDonations = "Donazioni";
      tRecipients = "Beneficiari";
      tDonationsValueReceived = "Totale"; // "Erogati";
      tDonationsValueReceivedTT = "L’ammontare totale erogato ai nostri beneficiari.";
      tProviders = "Fornitori";
      tProvisions = "Forniture";
      break;

    default:
      break;
  }

  const { statsLoading, allDonorsCount, recipientsCount, allDonationsCount, allDonationsSum, providersCount, allProvisionsCount} = basicStats;

  const CountUpDonorsCount = () => {

    return (
      statsLoading ?
        <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
      :
        <Heading size={800} color="#283655" textAlign="center" >
          <CountUp
            start={1}
            end={allDonorsCount || 0}
            delay={0}
            duration={5}
            separator={","}
            decimals={0}
            decimal={"."}
            prefix={""}
            suffix={""}
            // onEnd={() => console.log('Ended! 👏')}
            // onStart={() => console.log('Started! 💨')}
            // onReset={() => console.log('Resetted!')}
            // onUpdate={() => console.log('Updated!')}
            // onPauseResume={() => console.log('Paused or resumed!')}
          >
            {({ countUpRef, start, pauseResume, reset, update }) => (
              <div>
                <span ref={countUpRef} />
                {/* <button onClick={start}>Start</button> */}
              </div>
            )}
          </CountUp>
        </Heading>
    );
  };

  const CountUpRecipientsCount = () => {
    const countUpRef = useRef();
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: 1,
      end: recipientsCount || 0,
      delay: 0,
      duration: 5,
      separator: ",",
      decimals: 0,
      decimal: ".",
      prefix: "",
      suffix: "",
      // onReset: () => console.log('Resetted!'),
      // onUpdate: () => console.log('Updated!'),
      // onPauseResume: () => console.log('Paused or resumed!'),
      // onStart: ({ pauseResume }) => console.log(pauseResume),
      // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
      <Heading size={800} color="#283655" textAlign="center" ><span ref={countUpRef}/></Heading>
    );
  };
  
  const CountUpDonationsCount = () => {
    const countUpRef = useRef();
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: 1,
      end: allDonationsCount || 0,
      delay: 0,
      duration: 5,
      separator: ",",
      decimals: 0,
      decimal: ".",
      prefix: "",
      suffix: "",
      // onReset: () => console.log('Resetted!'),
      // onUpdate: () => console.log('Updated!'),
      // onPauseResume: () => console.log('Paused or resumed!'),
      // onStart: ({ pauseResume }) => console.log(pauseResume),
      // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
      <Heading size={800} color="#283655" textAlign="center" ><span ref={countUpRef}/></Heading>
    );
  };

  const CountUpDonationsSum = () => {
    const countUpRef = useRef();
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: 1,
      end: allDonationsSum || 0,
      delay: 0,
      duration: 5,
      separator: ",",
      decimals: 0,
      decimal: ".",
      prefix: !currencies[localeState.currency].symbolRight ? currencies[localeState.currency].symbol : "",
      suffix: currencies[localeState.currency].symbolRight ? currencies[localeState.currency].symbol : "",
      // onReset: () => console.log('Resetted!'),
      // onUpdate: () => console.log('Updated!'),
      // onPauseResume: () => console.log('Paused or resumed!'),
      // onStart: ({ pauseResume }) => console.log(pauseResume),
      // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
      <Heading size={800} color="#283655" textAlign="center" ><span ref={countUpRef}/></Heading>
    );
  };

  const CountUpProvidersCount = () => {
    const countUpRef = useRef();
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: 1,
      end: providersCount || 0,
      delay: 0,
      duration: 5,
      separator: ",",
      decimals: 0,
      decimal: ".",
      prefix: "",
      suffix: "",
      // onReset: () => console.log('Resetted!'),
      // onUpdate: () => console.log('Updated!'),
      // onPauseResume: () => console.log('Paused or resumed!'),
      // onStart: ({ pauseResume }) => console.log(pauseResume),
      // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
      <Heading size={800} color="#283655" textAlign="center" ><span ref={countUpRef}/></Heading>
    );
  };

  const CountUpProvisionsCount = () => {
    const countUpRef = useRef(null);
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: 1,
      end: allProvisionsCount || 0,
      delay: 0,
      duration: 5,
      separator: ",",
      decimals: 0,
      decimal: ".",
      prefix: "",
      suffix: "",
      // onReset: () => console.log('Resetted!'),
      // onUpdate: () => console.log('Updated!'),
      // onPauseResume: () => console.log('Paused or resumed!'),
      // onStart: ({ pauseResume }) => console.log(pauseResume),
      // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    return (
      <Heading size={800} color="#283655" textAlign="center" ><span ref={countUpRef}/></Heading>
    );
  };


  return (
    <Pane >
      <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" textAlign="center" padding={10} >
        <Heading size={800} color="#283655">{tAchievements}</Heading>
      </Pane>
      <Pane width="100%" marginTop={24} >
        <Pane marginTop={18} width="100%" minWidth={250} maxHeight="210px" textAlign="center" marginLeft="auto" marginRight="auto">
          <FdIngCap style={{width:"100%", height:"100%", maxHeight:"inherit"}} />
        </Pane>
        <Pane width="100%" marginTop={24} maxWidth={520} padding={12} marginLeft="auto" marginRight="auto" background="tint1" borderRadius={10}  textAlign="center">
          <Pane flex={1} width="100%" height="auto" alignItems="baseline" display="flex" flexWrap="wrap">
            <Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tDonors}</Heading> 
              <CountUpDonorsCount />
            </Pane>
            <Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tRecipients}</Heading>
              {statsLoading ?
                <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
              :
                <CountUpRecipientsCount />
              }
            </Pane>
            <Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tProviders}</Heading>
              {statsLoading ?
                <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
              :
              <CountUpProvidersCount />
              }
            </Pane>
          </Pane>
          <Pane flex={1} width="100%" height="auto" alignItems="baseline" display="flex" flexWrap="wrap">
            <Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tDonations}</Heading>
              {statsLoading ?
                <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
              :
                <CountUpDonationsCount />
              }
            </Pane><Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tDonationsValueReceived}
              {/* <Tooltip content={tDonationsValueReceivedTT}>
                <InfoSignIcon marginLeft={4} size={12} />
              </Tooltip> */}
              </Heading>
              {statsLoading ?
                <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
              :
                <CountUpDonationsSum />
              }
            </Pane>
            <Pane flex={1} marginTop={10} width="100%" minWidth={80} height="auto" textAlign="center">
              <Heading size={400} color="#7B8B9A">{tProvisions}</Heading>
              {statsLoading ?
                <Pane marginLeft="auto" marginRight="auto" width={90}><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={32}/></SkeletonTheme></Pane>
              :
                <CountUpProvisionsCount />
              }
            </Pane>
          </Pane>
        </Pane>
        <Pane marginTop={36}>
          <GalleryOfSupport/>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default AchievementsSection;