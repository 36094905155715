import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';

import { Link, useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { Text, Pane, Button, TextInput } from 'evergreen-ui';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
// import 'react-bootstrap-typeahead/css/Typeahead.css';

import fetch from 'isomorphic-fetch';

import { API, I18n } from 'aws-amplify';


const CACHE = {};
const PER_PAGE = 50;
const SEARCH_URI = 'https://api.github.com/search/users';

function makeAndHandleRequest(query, page = 1) {
  return fetch(`${SEARCH_URI}?q=${query}+in:login&page=${page}&per_page=50`)
    .then((resp) => resp.json())
    .then(({ items, total_count }) => {
      const options = items.map((i) => ({
        avatar_url: i.avatar_url,
        id: i.id,
        login: i.login,
      }));
      return { options, total_count };
    });
}

const SearchTypeahead = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`SearchTypeahead - ${countRef.current}`);
    return () => {
      console.log("SearchTypeahead - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const handleInputChange = (q) => {
    setQuery(q);
  };

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total_count
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    makeAndHandleRequest(query, page).then((resp) => {
      const options = cachedQuery.options.concat(resp.options);
      CACHE[query] = { ...cachedQuery, options, page };

      setIsLoading(false);
      setOptions(options);
    });
  };

  // `handleInputChange` updates state and triggers a re-render, so
  // use `useCallback` to prevent the debounced search handler from
  // being cancelled.
  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].options);
      return;
    }

    setIsLoading(true);
    makeAndHandleRequest(q).then((resp) => {
      CACHE[q] = { ...resp, page: 1 };

      setIsLoading(false);
      setOptions(resp.options);
    });
  }, []);

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
    
      inputProps={{
        // className: 'my-custom-classname',
        style: {
          'backgroundColor': 'red',
          height: "40px",
          width: "100%"
        }
      }}

      menuProps={{
        // className: 'my-custom-classname',
        style: {
          'backgroundColor': 'red',
          'max-height': "600px",
          width: "100%"
        }
      }}

      filterBy={filterBy}
      id="async-pagination"
      isLoading={isLoading}
      labelKey="login"
      maxResults={PER_PAGE - 1}
      minLength={2}
      onInputChange={handleInputChange}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      options={options}
      paginate
      placeholder="Search..."
      renderMenuItemChildren={(option) => (
        <div key={option.id}
          style={{
            'backgroundColor': 'green',
          }}
        >
          <img
            alt={option.login}
            src={option.avatar_url}
            style={{
              height: '24px',
              marginRight: '10px',
              width: '24px',
            }}
          />
          <span>{option.login}</span>
        </div>
      )}
      useCache={false}
    />
  );
}

export default SearchTypeahead;