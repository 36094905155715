import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { setProfileDetails, setTransactionDetails, updateDialogState, updateUploadState } from './actions';

import { API, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const useUploadImage = () => {
  
  const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  const uploadState = useSelector(state => state.uploadState);
  
  
  // const localeState = useSelector(state => state.localeState);

  const handleUpdateProgress = ({key, uploaded = 0, total = 0}) => {
    let currentProgress = uploadState.progress;
    if (!currentProgress[key]) {
			if ((!uploaded && !total) || uploaded < total) {
        currentProgress[key] = {
          uploaded: uploaded,
          total: total,
        };
      }
		}
    else {
      if (uploaded < total) {
        currentProgress[key] = {
          uploaded: uploaded,
          total: total,
        };
      }
      else {
        delete currentProgress[key];
      }
    }

    dispatch(updateUploadState({
      progress: currentProgress,
    }));

  }

  const handleUploadImage = useCallback(async({
    imageDataURL,
    key,
    resize = false,
    maxWidth = 1350,
    maxHeight = 1350,
  }) => {

    console.log("handleUploadImage");

    handleUpdateProgress({
      key: key,
      uploaded: 0,
      total: 0,
    });

    // resize if width or height is greater than 1350px
    // const maxWidth = 1350;
    // const maxHeight = 1350;

    let image = new Image();

    let canvas = document.createElement('canvas'); // remove this later ???
    let ctx = canvas.getContext("2d");

    image.src = imageDataURL;

    let fileBuffer = null;
    
    image.onload = async function() {

      if (resize === true) {
        // console.log(image.width, image.height);
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        let imageRatio = image.width / image.height;
        let dWidth = image.width;
        let dHeight = image.height;
        let sWidth = image.width;
        let sHeight = image.height;
        let sx = 0;
        let sy = 0;
        let dx = 0;
        let dy = 0;

        if (image.width > maxWidth || image.height > maxHeight ) {
          if(imageRatio > 1) {
            //if horizontal
            dWidth = maxWidth;
            dHeight = image.height / (image.width / dWidth);
          }
          else {
            //if vertical
            dHeight = maxHeight;
            dWidth = image.width / (image.height / dHeight);
          }
        }
        
        canvas.width = dWidth;
        canvas.height = dHeight;
        ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

        // can be optimised // resulting .png is heavier than .jpeg // all saved as .png at the end
        imageDataURL = canvas.toDataURL("image/jpeg", 0.92); // 1 significantly increases the output size
      }

      fileBuffer = Buffer.from(imageDataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), 'base64'); // png|jpg|jpeg // data:image/png;base64 // data:image/jpeg;base64 // /^data:image\/(png|jpg|jpeg);base64,/

      let folderPath = 'proofs-temp/';
      const customPrefix = { // access to these is set in Storage 
        public: folderPath,
        // protected: 'myProtectedPrefix/',
        // private: 'myPrivatePrefix/'
      };
      const config = {
        customPrefix: customPrefix,
        level: "public", // private | protected | public, // defaults to `public`
        contentType: "image/png",
        progressCallback(progress) {
          // console.log(`Upload progress: ${progress.loaded}/${progress.total}`);
          handleUpdateProgress({
            key: key,
            uploaded: progress.loaded,
            total: progress.total
          });
          if (progress.loaded >= progress.total) {
            
            dispatch(updateUploadState({
              completed: [...uploadState?.completed || [], key],
              failed: uploadState?.failed || [],
            }));

            console.log(`File has been uploaded, key: ${key} `);
            canvas.remove();
            // console.log("canvas is removed");
          }
        },
      };
      try {
        await Storage.put(key, fileBuffer, config); // returns key
      }
      catch (error) {
        // handle ???
        console.error("within Storage.put", error);
        dispatch(updateUploadState({
          completed: uploadState?.completed,
          failed: [...uploadState?.failed || [], key],
        }));
      }
    }

  }, [dispatch, handleUpdateProgress, uploadState?.completed, uploadState?.failed]);

  return handleUploadImage;
}

export default useUploadImage;