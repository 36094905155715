import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { updateTransactionDetails } from './actions';

import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const useProvideToFace = () => {
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const dialogState = useSelector(state => state.dialogState);
  const uploadState = useSelector(state => state.uploadState);
  

  const handleProvideToFace = useCallback(async({
    publicTransactionId = null,
    accessCode = null,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    // any existing intents should be invalidated by now

    async function wait(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    }

    const currentTransactionDetails = {
      ...transactionDetails,
      publicTransactionId: publicTransactionId,
      accessCode: accessCode,
    };

    const historyTransactionsWithImages = currentTransactionDetails.history?.filter((transaction) => (transaction.proofImages?.length > 0));
    
    for (let o = 0; o < historyTransactionsWithImages.length; o++) {

      const historyRecordWithImages = historyTransactionsWithImages[o];

      let tempTry = 0;
      let tempTryDelay = 0;
      let allFilesUploaded = true;

      do {
        if (tempTry > 0) {
          await wait(tempTryDelay);
        }

        allFilesUploaded = true;

        for (let i = 0; i < historyRecordWithImages.proofImages.length; i++) {
          if (uploadState?.failed?.includes(historyRecordWithImages.proofImages[i].key)) {
            console.error(`error with uploading key ${historyRecordWithImages.proofImages[i].key}`);
            allFilesUploaded = false;            
          }
          else if (!uploadState?.completed?.includes(historyRecordWithImages.proofImages[i].key)) {
            console.log(`key ${historyRecordWithImages.proofImages[i].key} is still uploading`);
            allFilesUploaded = false;
          }
        }

        tempTry = tempTry + 1;
        tempTryDelay = 1000;

        // add processing message
        if (tempTry === 10) {
          dispatch(updateTransactionDetails({
            localProcessingTime: 10,
          }));
        }
        else if (tempTry === 100) {
          dispatch(updateTransactionDetails({
            localProcessingTime: 100,
          }));
        }

      }
      while (!allFilesUploaded && tempTry < 300);

      if (!allFilesUploaded) {
        // ???
        dispatch(updateTransactionDetails({
          ...currentTransactionDetails,
          localProcessingTime: 300,
        }));

        console.error(`no all files have been uploaded`); // ???

        if (typeof onError === "function") {
          onError({
            message: "File upload error."
          });
        }

        return null;
      }
      
      // let proofImages = historyRecordWithImages.proofImages.map((item, index) => ({
      //   key: item.key,
      //   orderIndex: index,
      // }));
      // historyRecordWithImages.proofImages = proofImages; // losing src is problematic...

    }

    // send history record without scr [base64 values]
    const historyToSendWithoutSrc = currentTransactionDetails.history?.map((transaction) => {
      let historyTransaction = {
        ...transaction,
      };
      if (historyTransaction.proofImages?.length > 0) {
        let proofImagesWithoutSrc = historyTransaction.proofImages.map((object) => {
          // _.omit(object, ['src']);
          let newObject = {
            ...object,
            src: undefined,
          };
          return newObject;
        });
        historyTransaction.proofImages = proofImagesWithoutSrc;
      }
      return historyTransaction;

    }) || undefined;

    try {
      
      const response = await API.graphql({
        query: mutations.provideToFace,
        variables: {
          publicTransactionId: currentTransactionDetails.publicTransactionId,
          accessCode: currentTransactionDetails.accessCode, // only applicable to accessed transaction of a particular recipient
          mode: currentTransactionDetails.mode,
          fromPublicFaceId: currentTransactionDetails.fromPublicFaceId || userState?.user?.publicFaceId,
          toPublicFaceId: currentTransactionDetails.toPublicFaceId,
          amount: currentTransactionDetails.amount,
          currency: currentTransactionDetails.currency, // what if payment differs to currency of fromPublicFaceId ???
          caption: currentTransactionDetails.caption,
          paymentMethod: currentTransactionDetails.paymentMethod,
          note: currentTransactionDetails.note, // private to fromPublicFaceId
          history: historyToSendWithoutSrc,
          locale: currentTransactionDetails.locale,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const updatedTransaction = response.data.provideToFace;

      const definedProps = obj => Object.fromEntries(
        Object.entries(obj).filter(([k, v]) => v)
      );
      
      const transactionDetailsToReturn = _.merge(transactionDetails, definedProps(updatedTransaction));

      if (typeof onSuccess === "function") {
        onSuccess(transactionDetailsToReturn);
      }
      
    }
    catch (error) {
      console.error(`within provideToFace`, error);
      if (typeof onError === "function") {
        onError(error);
      }

    }

  }, [dispatch, transactionDetails, uploadState?.completed, uploadState?.failed, userState.user]);

  return handleProvideToFace;
  
}

export default useProvideToFace;