import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, generatePath, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Text, Button, IconButton, CrossIcon, Icon, Paragraph, Strong, LabelIcon, FontIcon, ShopIcon, Pane} from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import FormattedCurrencyNumber from './FormattedCurrencyNumber';
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { I18n } from 'aws-amplify';

const DefaultPageActions = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`DefaultPageActions - ${countRef.current}`);
    return () => {
      console.log("DefaultPageActions - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  // let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleStartMakingNewDonation = () => {
    // only charity can accept direct donations
    dispatch(setTransactionDetails({
      type: "donation",
      toPublicFaceId: profileDetails.tags?.includes("charity") ? profileDetails.publicFaceId : null,
      toFace: profileDetails.tags?.includes("charity") ? profileDetails : null,
      viaPublicFaceId: profileDetails.tags?.includes("charity") ? null : profileDetails.publicFaceId,
      viaFace: profileDetails.tags?.includes("charity") ? null: profileDetails,
    }));

    history.push({
      pathname: generatePath("/:page", {page: "new"}),
    });
    
  };

  // const handleStartMakingNewAllocation = () => {
  //   const tempQueryParams = new URLSearchParams(location.search);
  //   tempQueryParams.set('mode', "allocation");
  //   tempQueryParams.set('to', profileDetails.publicFaceId);

  //   dispatch(updateTransactionDetails({
  //     type: "donation",
  //     mode: "allocation",
  //     toPublicFaceId: profileDetails.publicFaceId,
  //     toFace: {
  //       ...profileDetails
  //     },
  //   }));

  //   console.log(`?${tempQueryParams.toString()}`);

  //   history.push({
  //     pathname: generatePath("/:page/:mode?", {
  //       page: "new",
  //       mode: "donation"
  //     }),
  //     // search: `?${tempQueryParams.toString()}`,
  //   });
  // };

  const handleStartMakingNewAllocation = () => {

    const tempQueryParams = new URLSearchParams(location.search);
    tempQueryParams.set('mode', "allocation");
    tempQueryParams.set('to', profileDetails.publicFaceId);

    return {
      pathname: generatePath("/:page/:mode?", {
        page: "new",
        mode: "donation"
      }),
      search: `?${tempQueryParams.toString()}`,
      // state: {},
    };
  };

  const userDetailsAllocationModeRecord = userDetails?.records?.find(record => record.type === "allocationMode");

  // allocate only to individual pages 
  const isProfessionalPage = profileDetails?.tags?.filter(tag => ["charity", "retailer", "corporate"].includes(tag))?.length > 0 ? true : false;

  
  return (

    <Fragment>

      <Pane display="flex" alignItems="center" flexWrap="wrap" gap={8} >
      
        {userDetails?.rights?.makeAllocations && userDetailsAllocationModeRecord?.isDefault && !isProfessionalPage ?

          <Pane flex={1} width="100%" height="auto" textAlign="center">
            <Button fontSize={16} padding={10} height={48}
              width="100%" justifyContent="center"
              className='button-green' 
              is={Link}
              // target="_blank"
              to={handleStartMakingNewAllocation}
            >
              {I18n.get('Allocate')}
            </Button>
          </Pane>

        :

          <Fragment>
            <Pane flex={1} width="100%" height="auto" textAlign="center">
              <Button fontSize={16} padding={10} height={48} className='button-green' width="100%" justifyContent="center"
                disabled={!(profileDetails?.tags?.includes("charity")
                  || profileDetails?.following?.total > 0 // this may be an individual user following a charity, so donating to their page will offer to donate to whom they follow 
                  // || profileDetails?.tags?.includes("recipient") // how to donate to individual recipient ??? needs to be fixed ??? see getUserDetails
                )}
                onClick={() => {
                  handleStartMakingNewDonation();
                }}
                >
                  {I18n.get('Donate')}
              </Button>
            </Pane>

            {isProfessionalPage || !userDetails?.rights?.makeAllocations ? null :
              <Pane flex="none" height="auto" textAlign="center"  >
                <Button fontSize={16} padding={10} height={48} width={48} justifyContent="center" className='button-green' 
                  is={Link}
                  // target="_blank"
                  to={handleStartMakingNewAllocation}
                >
                  <FontIcon iconSize={18} color="#FFFFFF" height={48} />
                </Button>

                {/* <IconButton icon={FontIcon} iconSize={18} color="#FFFFFF" intent="none" height={48} className='button-green'
                  is={Link}
                  target="_blank"
                  to={handleStartMakingNewAllocation}
                /> */}

              </Pane>
            }
          </Fragment>
        }


        {/* {(userDetails && transactionDetails.type === "intent" && typeof transactionDetails.amountAvailable === "number") ?
          <Pane flex="none" marginLeft={10} height="auto" textAlign="center"  >
            <IconButton icon={ShopIcon} iconSize={18} color="#FFFFFF" intent="none" height={40} className='button-purple'
              onClick={() => {
                // go directly to providing to this profileDetails 
              }}
            />
          </Pane>
        : null } */}

        {/* {!userDetails?.rights?.verifyRecipients ? null :
          <Pane flex="none" marginLeft={10} height="auto" textAlign="center"  >
            <IconButton icon={LabelIcon} iconSize={18} color="#FFFFFF" intent="none" height={40} borderRadius={5}
              onClick={() => {
                // see details of the relationships 
              }}
            />
          </Pane>
        } */}

      </Pane>

    </Fragment>

  );
}

const UserOwnPageActions = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`UserOwnPageActions - ${countRef.current}`);
    return () => {
      console.log("UserOwnPageActions - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const handleStartMakingNewProvision = (mode) => {    
    history.push({
      pathname: generatePath("/:page/:mode", {
        page: "new",
        mode: "post",
      }),
      search: mode ? `?mode=${mode}` : "",
    });
  };

  const handleStartMakingNewAllocation = () => {
    history.push({
      pathname: generatePath("/:page/:mode", {
        page: "new",
        mode: "donation",
      }),
      search: `?mode=${"allocation"}`,
    });
  };
  

  return (
    <Fragment>

      <Pane alignItems="end" display="flex" flexWrap="wrap" >
        <Pane flex={1} width="100%" height="auto" textAlign="left" >
          <Pane flex={1} alignItems="center" display="flex" flexWrap="wrap">
            <Pane height="auto" alignItems="center" display="inline-flex" flexWrap="wrap">
            {!userDetails?.tags?.includes("retailer") ?
              <Strong fontSize={14} color="#425A70" >{I18n.get('Balance')}</Strong>
              :
              <Strong fontSize={14} color="#425A70" >{I18n.get('Revenue')}</Strong>
            }
            </Pane>

            {/* <Button flex="none" marginLeft={10} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" marginRight="auto" className="noselect"
              disabled={false}
              onClick={() => {
                
              }}
            >
              {I18n.get('Top up')}
            </Button> */}

            {/* Add update balance button ???  */}

          </Pane>
          
          {!userDetails?.tags?.includes("retailer") ?
            <FormattedCurrencyNumber is={Pane} number={userDetails?.balance?.available || 0} currency={userDetails?.balance?.currency} fontSize={24} />
            :
            <FormattedCurrencyNumber is={Pane} number={userDetails?.balance?.earned || 0} currency={userDetails?.balance?.currency} fontSize={24} />
          }
        </Pane>

      </Pane>
      
      <Fragment>
        <Pane
          // marginTop={8}
          alignItems="center" display="flex" flexWrap="wrap"
          gap={8}
        >

          {userDetails?.rights?.balancePay ?
            <Button width={"100%"} flex={1} marginTop={8} fontSize={16} lineHeight={1.1} paddingX={8} height={48} textAlign="center" justifyContent="center" marginLeft="auto" marginRight="auto" className='button-blue' 
              disabled={userDetails?.balance?.available > 0 ? false : true}
              onClick={() => {
                // trigger by setting transactionDetails
                dispatch(setTransactionDetails({
                  type: "paymentIntent",
                }));
              }}
            >
              {I18n.get('New payment')}
            </Button>
          : null }

          {userDetails?.rights?.makeAllocations ?
            <Button width={"100%"} flex={1} marginTop={8} fontSize={16} lineHeight={1.1} paddingX={8} height={48} textAlign="center" justifyContent="center" marginLeft="auto" marginRight="auto" className='button-green' 
              disabled={false}
              onClick={handleStartMakingNewAllocation}
            >
              {I18n.get('New allocation')}
            </Button>
          : null }

          {userDetails?.rights?.provideExternally ?
            <Button width={"100%"} flex={1} marginTop={8} fontSize={16} lineHeight={1.1} paddingX={8} height={48} textAlign="center" justifyContent="center" marginLeft="auto" marginRight="auto" className='button-purple' 
              disabled={false}
              onClick={() => {
                handleStartMakingNewProvision("pos");
              }}
            >
              {I18n.get('Accept payment')}
            </Button>
          : null }

          {/* 'New post' is in the Menu */}
          {/* {userDetails?.rights?.provideInternally ?
            <Button width={"100%"} flex={1} marginTop={8} fontSize={16} lineHeight={1.1} paddingX={8} height={48} textAlign="center" justifyContent="center" marginLeft="auto" marginRight="auto" className='button-purple' 
              disabled={false}
              onClick={handleStartMakingNewProvision}
            >
              {I18n.get('New post')}
            </Button>
          : null } */}
          
        </Pane>
      </Fragment>

    </Fragment>

  );
}

const ProfileActions = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileActions - ${countRef.current}`);
    return () => {
      console.log("ProfileActions - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const isUserOwnPage = useMemo(() => {
    return (match.params.handle === userDetails?.publicFaceId || match.params.handle === userDetails?.username)
  }, [match.params.handle, userDetails?.publicFaceId, userDetails?.username]);
  

  if (!profileDetails.publicFaceId ) {
    return null;
  }

  return (
    <Fragment>

      <Pane paddingTop={4} paddingBottom={4} marginLeft="auto" marginRight="auto" maxWidth={350} alignItems="baseline" display="flex" flexWrap="wrap" background="tint1">
        <Pane flex="none" marginLeft="auto" width={10} height={20} borderTopRightRadius={10} borderBottomRightRadius={10} background="#FFFFFF" ></Pane>
        <Pane flex={1} width="100%" height={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A"></Pane>
        <Pane flex="none" marginRight="auto" width={10} height={20} borderTopLeftRadius={10} borderBottomLeftRadius={10} background="#FFFFFF" ></Pane>
      </Pane>

      <Pane paddingX={10}>

        {isUserOwnPage ?
          <UserOwnPageActions />
        :
          <DefaultPageActions />
        }

      </Pane>
    </Fragment>
  );
}

export default ProfileActions;