import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo } from 'react';
import FacePH from '../images/FacePH';

import { Link, Redirect, generatePath, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import { Text, Button, Table, Dialog, Spinner, IconButton, Icon, SymbolTriangleUpIcon, Paragraph, TextInput, Strong, Popover, Menu, Position, CloudDownloadIcon, ChevronLeftIcon, InfoSignIcon, Tooltip, MoreIcon, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';
import ShowMoreText from 'react-show-more-text';
// import LinesEllipsis from 'react-lines-ellipsis';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import styled from "styled-components";

import InfiniteScroll from "react-infinite-scroll-component";

import { useGetTransactions } from "../services/useGetTransactions";
import { usePageInteraction, usePageFollow, usePageIsFollowByUser } from "../services/usePageInteraction";

import { API, I18n} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';


import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import QRCode from 'qrcode';
import { triggerBase64Download } from 'react-base64-downloader';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import QRCodeIcon from '../assets/icons/QRCodeIcon';

import FaceAvatar from './FaceAvatar';
import ProfileActions from './ProfileActions';
import DialogHeader from './DialogHeader';

import QRCodeStyledComponent from './QRCodeStyledComponent';
import ModalResponsive from './ModalResponsive';

import FormattedCurrencyNumber from './FormattedCurrencyNumber';
import AspectRatio from './AspectRatio';
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, updateProfileDetails, updateProfileState, setDialogState, setTransactionDetails } from '../services/actions';

// not in use (has a good drawing function) ???
const FaceQRCode = ({ textToEncode, setQrcodeBase64 }) => {
	
  const [qrCodeImageSrc, setQrCodeImageSrc] = useState();
  
  const viewerElementRef = useRef(null);
  const sensorElementRef = useRef(null);
  const isCurrent = useRef();
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      isCurrent.current = false;
    }
  }, []);
  

  const generateQR = useCallback(async() => {
    try {
      let params = {
        errorCorrectionLevel: 'L',
        // version: 2,
        type: 'image/png',
        quality: 1,
        margin: 0,
        scale: 4,
        width: 1000,
        color: {
          dark:"#FF9C8F",
          light:"#283655"
        }
      }

      const qrCode = await QRCode.toDataURL(textToEncode, params);
      // console.log("qrCode", qrCode);
      // return qrCode;
      let viewerElement = viewerElementRef.current;
      let sensorElement = sensorElementRef.current;
      let ctx = sensorElement.getContext("2d");

      const image = new Image();
      image.onload = drawImageActualSize; // Draw when image has loaded
      image.src = qrCode;

      function drawImageActualSize() {
        // Use the intrinsic size of image in CSS pixels for the canvas element
        sensorElement.width = this.naturalWidth + 400;
        sensorElement.height = this.naturalHeight + 400;
        
        ctx.fillStyle = "#283655";

        ctx.fillRect(0, 0, sensorElement.width, sensorElement.height);
        ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, 200, 150, this.naturalWidth, this.naturalHeight);
        ctx.fillStyle = "#FFFFFF";
        let fontFam = "'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
        ctx.font = `500 90px ${fontFam}`;
        let text = ctx.measureText(textToEncode);
        let textLeftPadding = (1400 - text.width) / 2;
        ctx.fillText(textToEncode, textLeftPadding, 1300);

        let base64StringFull = sensorElement.toDataURL("image/png", 1);
        setQrCodeImageSrc(base64StringFull);
        setQrcodeBase64(base64StringFull);
      }

    } catch (error) {
      console.error(error);
    }
  }, [setQrcodeBase64, textToEncode]);

  useLayoutEffect(() => {
    if (textToEncode) {
      generateQR();
    }
  }, [generateQR, setQrcodeBase64, textToEncode]);


  return (
    <div style={{
      borderRadius: "inherit"
    }}
    >
      {qrCodeImageSrc ? <img width="100%" height="auto" src={qrCodeImageSrc} alt=""
      style={{
        borderRadius: "inherit"
      }}
      /> : null }
      <div
        style={{
          borderRadius: "inherit",
          marginLeft: "auto",
          marginRight: "auto",
          position: "inherit",
          height: "inherit",
          width: "inherit",
          display: "none",
        }}
      >
        <canvas
          ref={sensorElementRef}
          style={{
            "display": "none",
          }}>
        </canvas>
      </div>
    </div>
  );
}

const GenerateProfileQrcode = ({ onClose, publicFaceId, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`GenerateProfileQrcode - ${countRef.current}`);
    return () => {
      console.log("GenerateProfileQrcode - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const isUserOwnPage = useMemo(() => {
    return (match.params.handle === userDetails?.publicFaceId || match.params.handle === userDetails?.username)
  }, [match.params.handle, userDetails?.publicFaceId, userDetails?.username]);
	
	const [qrcodeBase64, setQrcodeBase64] = useState();
  const [qrCodeLoaded, setQrCodeLoaded] = useState(false);

	const pageQrcodeUrl = `https://facedonate.org/${publicFaceId}?utm_source=qr`;

  const pageQrcodeUrlReadable = `facedonate.org/${publicFaceId}`;

  return(
    <Pane padding={10} >

      <DialogHeader
        headerText={I18n.get('Page QR code')}
        hideLeftButton={!qrCodeLoaded}
        leftButtonIcon={null}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        onLeftButtonClick={() => {
					onClose();
        }}
      />

      {isUserOwnPage && userDetails?.rights?.balancePay ?
        <Pane marginTop={0} textAlign="center" >
          <Strong fontSize={16} color="#EC4C47" >
            {I18n.get('This is not a payment QR code.')}
          </Strong>
        </Pane>
      : null }
      
      <Pane marginTop={0} >
        <QRCodeStyledComponent onChange={(e) => {setQrCodeLoaded(e.isLoaded); setQrcodeBase64(e.qrcodeBase64)}} textToEncode={pageQrcodeUrl} frontColor="#283655" backColor="#0000" />
      </Pane>
      
      <Pane textAlign="center" >
        <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >
          {I18n.get('Page address:')}
          {" "}
          {pageQrcodeUrlReadable}
        </Text>
      </Pane>
      
      {/* <Pane marginTop={10} className="noselect" >
        <Button width={"100%"} height={50} padding={8} justifyContent="center"
          borderRadius={5}
          onClick={() => {
            triggerBase64Download(qrcodeBase64, `face_donate_qrcode_${publicFaceId}`); // .png is added automatically
          }}
          >
          <CloudDownloadIcon marginRight={8} size={20} color={"#283655"} />
          <Strong fontSize={16} color={"#283655"} >{I18n.get('Download QR code')}</Strong>
        </Button>
      </Pane> */}

      {qrCodeLoaded ? 
        null
      : 
        <Pane marginTop={0} textAlign="center" className="noselect" >
          <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('A QR code is being generated...')} `}</Text>
        </Pane>
      }

    </Pane>
  );
}

const MoreProfileMenu = ({ onClose, onClickReport, onClickAbout, onClickQrcode, onClickShare, onClickNotification, ...rest }) => {

	const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`MoreProfileMenu - ${countRef.current}`);
    return () => {
      console.log("MoreProfileMenu - cleaned up");
      isCurrent.current = false;
    }
  }, []);
	
	return (
		<Pane padding={10} >
			<DialogHeader
				headerText={null}
				leftButtonIcon={null}
				hideRightButton={true}
				leftButtonDisabled={false}
				rightButtonDisabled={false}
				onLeftButtonClick={() => {
					onClose();
				}}
			/>

			<Pane marginTop={10} background="tint1" borderRadius={5} >
				<Pane borderBottom borderWidth={1} borderColor="#E4E7EB">
					<Button style={{color: "#EC4C47"}} justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" 
					onClick={onClickReport}
					>
						{I18n.get('Report')}
					</Button>
				</Pane>
				<Pane borderBottom borderWidth={1} borderColor="#E4E7EB" >
					<Button style={{color: "#283655"}} justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" 
					onClick={onClickAbout}
					>
						{I18n.get('About this account')}
					</Button>
				</Pane>

				<Pane borderBottom borderWidth={1} borderColor="#E4E7EB" >
					<Button style={{color: "#283655"}} justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" 
					onClick={onClickQrcode}
					>{I18n.get('QR code and link')}</Button>
				</Pane>

				<Pane borderBottom borderWidth={1} borderColor="#E4E7EB" >
					<Button style={{color: "#283655"}} justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" 
					onClick={onClickShare}
					>{I18n.get('Share')}</Button>
				</Pane>

				<Pane >
					<Button style={{color: "#283655"}} justifyContent="center" width="100%" padding={8} height={50} appearance="minimal" 
					onClick={onClickNotification}
					>{I18n.get('Turn on notifications')}</Button>
				</Pane>

			</Pane>

		</Pane>
	);
};


const ProfileDetailsMore = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileDetailsMore - ${countRef.current}`);
    return () => {
      console.log("ProfileDetailsMore - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();

  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  // const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const isUserOwnPage = useMemo(() => {
    return (match.params.handle === userDetails?.publicFaceId || match.params.handle === userDetails?.username)
  }, [match.params.handle, userDetails?.publicFaceId, userDetails?.username]);

	const [localState, setLocalState] = useState();


  if (!profileDetails.publicFaceId) {
		return (
			<Pane paddingTop={9} paddingBottom={6.5} marginX={10} width={100} >
        <SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2">
          <Skeleton height={16} />
        </SkeletonTheme>
      </Pane>
		);
	}


  return (
    <Fragment >
			<Pane marginX={7} paddingTop={6} paddingBottom={4} alignItems="center" display="flex" flexWrap="wrap">
						
        <Pane flex={"auto"} >
          <Button
            padding={4} height={24} appearance="minimal"
            justifyContent="center" 
            onClick={() => {
              setLocalState("GenerateProfileQrcode");
            }}
          >
            <QRCodeIcon color="#283655" width={16} height={"auto"} />
            <Strong fontSize={14} flex={"none"} marginLeft={4} color="#283655">{profileDetails.publicFaceId}</Strong>
          </Button>
        </Pane>

        

        {isUserOwnPage && userDetails?.rights?.balancePay ?
          <IconButton marginRight={8} height={24} width={24} icon={SymbolTriangleUpIcon} iconSize={20} appearance="minimal"
            // #66788A icon colour
            onClick={() => {
              
            }}
          /> 
        : null}

        <IconButton height={24} width={24} icon={MoreIcon} iconSize={14} appearance="minimal"
        // #66788A icon colour
          onClick={() => {
            // dispatch(setDialogState({
            //   componentName: "ProfileMoreDialog",
            // }));
          }}
        />
      </Pane>

      <Dialog
        isShown={localState}
        onCloseComplete={() => {
          setLocalState();
        }}
        // onCancel={(close) => close()}
        title='Face More'
        hasHeader={false}
        hasFooter={false}
        preventBodyScrolling
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        width={350}
        containerProps={{
          "backgroundColor": "transparent",
          "maxHeight": "100%",
          "margin": "auto"
        }}
        contentContainerProps={{
          "padding": "0px"
        }}
      >
      {({ close }) => (
        <Pane background="#FFFFFF" borderRadius={10} >

          <GenerateProfileQrcode
            publicFaceId={profileDetails.publicFaceId}
            onClose={() => {
              close();
            }}
          />

        </Pane>
      )}
        
      </Dialog>
			
    </Fragment>
  );
}

const ProfileImageStyledPane = styled(Pane)`
  width: auto;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: ${425}px) {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ProfileInfo = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileInfo - ${countRef.current}`);
    return () => {
      console.log("ProfileInfo - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  let match = useRouteMatch();
  let history = useHistory();

  // console.log("ProfileInfo match", match);

  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  const dispatch = useDispatch();

  const [profileDetailsLoaded, setProfileDetailsLoaded] = useState(
    (profileDetails.publicFaceId && (profileDetails.publicFaceId === match.params.handle || profileDetails.username === match.params.handle)) ? true : false
  );

  const [isOpenClaimPageModal, setIsOpenClaimPageModal] = useState(false);
  const [claimPageWorkEmail, setClaimPageWorkEmail] = useState();
  const [claimPageProgressing, setClaimPageProgressing] = useState(false);
  function isValidEmail(string) {
    if (!string) {
      return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(string).toLowerCase());
  };

  const handleClaimProfessionalPage = async({ publicFaceId, email, note, onSuccess, onError }) => {
    try {
      const response = await API.graphql({
        query: mutations.claimProfessionalPage,
        variables: {
          publicFaceId: publicFaceId,
          email: email,
          note: note,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      
      const claimProfessionalPage = response.data.claimProfessionalPage;
      console.log("claimProfessionalPage", claimProfessionalPage);

      dispatch(updateProfileDetails({
        tags: claimProfessionalPage?.tags
      }));

      onSuccess();
      
    }
    catch (error) {
      console.error("within claimProfessionalPage:", error);
      onError();
    }
  }
  
  const [isOpenPageBioModal, setIOpenPageBioModal] = useState(false);
  const linesEllipsisRef = useRef(null);
  const [captionIsClamped, setCaptionIsClamped] = useState(true);
  
  useEffect(() => {
    if (profileDetails.publicFaceId !== match.params.handle && profileDetails.username !== match.params.handle) {
      console.log("Clear profileDetails and other states to load new...");
      setProfileDetailsLoaded(false);
      batch(() => {
        dispatch(setProfileDetails());
        dispatch(setTransactionDetails());
      });
    }
  }, [dispatch, match.params.handle, profileDetails.publicFaceId, profileDetails.username]);
  
  const getProfileAndTransactionDetails = useCallback(async() => {
    
    let updatedProfileDetails = null;
    let updatedTransaction = null;
    

    if (match.params.handle && match.params.mode === "fwd" && match.params.publicTransactionId && match.params.accessCode) {
      // access Transaction when forwarded
      try {
        const response = await API.graphql({
          query: mutations.accessTransaction,
          variables: {
            toPublicFaceId: match.params.handle,
            publicTransactionId: match.params.publicTransactionId,
            accessCode: match.params.accessCode,
            // invalidate: false,
            currency: localeState.currency,
            locale: localeState.locale,
          },
          authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        });
        updatedTransaction = response.data.accessTransaction;
        // if (updatedTransaction.objectDetails) {
        //   updatedTransaction.objectDetails = JSON.parse(updatedTransaction.objectDetails);
        // }
      }
      catch (error) {
        console.error("when accessTransaction:", error);
        if (error.errors && error.errors.length > 0) {
          updatedTransaction = {error: error.errors[0]};
        }
        else {
          updatedTransaction = {error: {message: "Something went wrong."}};
        }
      }
    }

    if (!updatedProfileDetails) {
      try {
        let publicTransactionId = null;
        // if (updatedProfileDetails) {
        //   publicTransactionId = updatedProfileDetails.publicTransactionId;
        // }
        const response = await API.graphql({
          query: queries.getUserDetails,
          variables: {
            handle: match.params.handle,
            currency: localeState.currency,
            locale: localeState.locale,
            publicTransactionId: publicTransactionId,
            // indicate to include history and other list or not ??? (adjust API)
          },
          authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        });
        updatedProfileDetails = response.data.getUserDetails;

        console.log("updatedProfileDetails", {...updatedProfileDetails});
      
      }
      catch (error) {
        console.error("within getUserDetails:", error);
        // console.log("match", match);
        // console.log("location", location);
        // handle better ???
        history.replace({
          pathname: "/404",
          // search: "",
          // state: state,
        });
      }
    }

    // redirect when redirectToPublicFaceId
    if (updatedProfileDetails?.redirectToPublicFaceId) {
      history.replace({
        pathname: generatePath(match.path, {...match.params, handle: updatedProfileDetails.redirectToPublicFaceId}),
        // search: "",
        // state: state,
      });
    }

    let handleToDisplay = updatedProfileDetails?.username || updatedProfileDetails?.publicFaceId;
    // redirect from publicFaceId to username (incl. when redirectToPublicFaceId)...
    if (match.params.handle !== handleToDisplay) {
      history.replace({
        pathname: generatePath(match.path, {...match.params, handle: handleToDisplay}),
        // state: state,
      });
    }

    batch(() => {
      dispatch(setProfileDetails(updatedProfileDetails));
      console.log("updatedProfileDetails", updatedProfileDetails);
      dispatch(setTransactionDetails(updatedTransaction));
      console.log("updatedTransaction", updatedTransaction);
      setCaptionIsClamped(true);
      setProfileDetailsLoaded(true);
    });
    

  }, [dispatch, history, localeState.currency, localeState.locale, match.params, match.path, userState.user]);

  
  useEffect(() => {
    // triggers on reload or when no profileDetails is coming in
    if (!userState.isLoading && match.params.handle && !profileDetailsLoaded) {
			console.log("call getProfileAndTransactionDetails");
      getProfileAndTransactionDetails();
    }
  }, [getProfileAndTransactionDetails, match.params.handle, profileDetailsLoaded, userState.isLoading]);

  return (
    <Fragment >

      <ProfileImageStyledPane >
        <AspectRatio ratio={1/1} >
          {profileDetails?.pictureUrl ?
            <img
              width="100%" height="auto"
              src={profileDetails?.pictureUrl}
              alt=""
              style={{
                // borderRadius: "inherit",
                borderRadius: "3px",
                borderWidth: "1px", borderStyle: "solid", borderColor: "#435A6F20", // "#E4E7EB"
                objectFit: "scale-down"
              }}
            />
          :
            <Pane width="100%" height="100%" paddingX={"8%"}
              border borderWidth={1} borderStyle="solid" borderColor="#435A6F20" // "#E4E7EB"
              borderRadius={3}
            >
              <FacePH color={profileDetails?.publicFaceId ? "#7B8B9A" : "#E4E7EB"} width="100%" height="100%" />
            </Pane>
            
          }
        </AspectRatio>
      </ProfileImageStyledPane>

      {profileDetails.publicFaceId ?
        <Fragment>
          <Pane paddingX={10} >

            {/* {profileDetails.verifiedUNIX ?
              <Pane flex="none" marginLeft={depth === 0 ? 4 : 2} height={depth === 0 ? 20 : 18} >
                <EndorsedIcon color="#1070CA" size={depth === 0 ? 14 : 12} />
              </Pane>
            : null} */}

            {profileDetails.name ?
              <Pane>
                <Strong display="block" fontSize={24} minHeight={24} color="#283655" style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >{profileDetails.name}</Strong>
              </Pane>
            : null }

            {/* {profileDetails.bio ?
              <Pane marginTop={4} >
                
                  <Text whiteSpace={"break-spaces"} fontSize={16} lineHeight={1.3} color="#283655" minHeight={32} >
                    <ShowMoreText
                      // ShowMoreText should be replaced with LinesEllipsis from TransactionDetails
                      lines={3}
                      more={I18n.get('more')}
                      less=''
                      // className='show-more-text'
                      anchorClass='show-more-text'
                      onClick={() => {
                        setIOpenPageBioModal(true);
                      }}
                      expanded={false}
                      width={0}
                    >
                      {profileDetails.bio?.replace(/<br\/>/g,'\r\n')}
                    </ShowMoreText>
                  </Text>
                
              </Pane>
            : null } */}

            {profileDetails.bio ?

              <Pane marginTop={4} >
                {captionIsClamped ?
                  <Paragraph is={"div"} fontSize={16} lineHeight={1.3} color="#283655"
                    // minHeight={32}
                  >
                    <Pane
                      onClick={(event) => {
                        console.log("Expand bio ...");
                        // setCaptionIsClamped(false);
                        setIOpenPageBioModal(true);
                      }}
                    >
                      <HTMLEllipsis
                        // https://www.npmjs.com/package/react-lines-ellipsis
                        ref={linesEllipsisRef}
                        // unsafeHTML={`
                        //   <a
                        //     class="inline-link"
                        //     style="fontWeight: 600; color: #283655; "
                        //     href="${generatePath("/:locale?/:handle", {...match.params, handle: "XXX" })}"
                        //     onclick="event.stopPropagation();"
                        //     target="_self" // target="_blank" // 
                        //     rel="noopener noreferrer"
                        //   >${"XXX"}</a>
                        //   <span class="clickable-text" >${" "}${profileDetails.bio}</span>
                        // `}
                        unsafeHTML={`
                          <span class="clickable-text" >${profileDetails.bio}</span>
                        `}
                        maxLine={4} // doesn't seem to strictly work ??? // this should depend on the length of 2nd and 3rd lines
                        ellipsisHTML={` <span fontSize={15} style="color: #7B8B9A" > ${I18n.get('more')}...</span>`}
                        trimRight
                        onReflow={(rleState)=> {
                          // console.log("onReflow:", rleState);
                          setCaptionIsClamped(rleState.clamped);
                        }}
                        basedOn='letters'
                        component='div'
                      />
                    </Pane>
                  </Paragraph>
                :
                  <Fragment>

                    <Paragraph whiteSpace={"break-spaces"} fontSize={16} lineHeight={1.3} color="#283655"
                      // minHeight={32}
                    >
                      {profileDetails.bio?.replace(/<br\/>/g,'\r\n')}
                    </Paragraph>

                    {/* <Button width={"100%"} fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto" marginTop={10} className="noselect"
                      disabled={false}
                      onClick={() => {
                        // do something
                        setIOpenPageBioModal(true);
                      }}
                    >
                      {I18n.get('Read full story')}
                    </Button> */}

                  </Fragment>
                }

                <ModalResponsive
                  isShown={isOpenPageBioModal}
                  onOpenComplete={() => {}}
                  onCloseComplete = {() => {
                    setIOpenPageBioModal(false);
                  }}
                  // onCancel={(close) => close()}
                  title='DialogPageBio'
                  // hasHeader={false}
                  // header={}
                  hasFooter={false}
                  // footer={}
                  preventBodyScrolling={true}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                  modalStyleProps={{
                    // "minHeight": "200px",
                  }}
                  // width={"auto"} // 350 // "100%"
                  // height={"calc(100% - 36px * 2)%"}
                  // containerProps={{
                  //   "backgroundColor": "transparent",
                  //   // "maxWidth": "100%",
                  //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
                  //   "margin": "auto", // auto
                  //   // "overflow": 'hidden',
                  // }}
                  // contentContainerProps={{
                  //   "padding": "0px",
                  //   "maxHeight": "75hv",
                  // }}
                  // styles={{
                  //   overlay: {
                  //     "background": "#7B8B9A10",
                  //     "backdrop-filter": "blur(1px)",
                  //   },
                  //   // modalContainer: {},
                  //   modal: {
                  //     "width": "100%",
                  //     "max-width": "100%",
                  //     "box-shadow": null,
                  //     "margin": 0,
                  //     "padding": 0,
                  //   },
                  // }}
                  
                >
                  
                  {({ close }) => (
                    <Pane width="100%" height="100%" background="#FFFFFF" padding={10} borderRadius={10} >

                      <DialogHeader
                        headerText={I18n.get('Bio')}
                        hideLeftButton={false}
                        hideRightButton={true}
                        leftButtonDisabled={false}
                        rightButtonDisabled={false}
                        leftButtonIcon={null}
                        onLeftButtonClick={() => {
                          close();
                        }}
                        // rightButtonText={I18n.get('Next')}
                        onRightButtonClick={() => {}}
                      />

                      <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
                        <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                          <Paragraph whiteSpace={"break-spaces"} fontSize={16} lineHeight={1.3} color="#283655" minHeight={32}>
                            {profileDetails.bio?.replace(/<br\/>/g,'\r\n')}
                          </Paragraph>
                        </Pane>
                      </Pane>

                      <Pane padding={0} height={0} >
                        {/* <button style={{fontSize: "1rem"}}
                          onClick={() => {
                            close();
                          }}
                        >Close modal</button> */}
                      </Pane>

                    </Pane>
                  )}
                  
                </ModalResponsive>
                
              </Pane>
            : null }

            {profileDetails.website ?
              <Pane marginTop={4}>
                <a href={`https://${profileDetails.website}`} target="_blank" rel="noopener noreferrer" className="blue-link" >
                  <Strong fontSize={16} color="#1070CA" style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >{profileDetails.website}</Strong>
                </a>
              </Pane>
            : null }

            
            {profileDetails.tags?.includes("pageMayBeClaimed") && !profileDetails.tags?.includes("pageIsBeingClaimed")
              && !userState.user?.tags?.filter(tag => ["charity", "retailer", "corporate"].includes(tag))?.length > 0 ?
              <Pane marginTop={12}>
                <Button
                  // display="block"
                  width="100%"
                  height="auto"
                  textAlign="inherit"
                  padding={0}
                  // appearance="minimal"
                  onClick={() => {
                    if (!userState.user) {
                      dispatch(updateProfileState({
                        localStage: "DialogAuth",
                        localMode: "log-in",
                      }));
                    }
                    else {
                      setIsOpenClaimPageModal(true);
                    }
                  }}
                  style={{
                    borderRadius: "5px"
                  }}
                  // margin={6}
                  // className="noselect"
                >
                  <Pane display="flex" alignItems="center" justifyContent="center" width={"100%"} padding={8} >
                    <Pane textAlign="center" >
                      <Pane >
                        <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Work for this organisation?")}</Text>
                      </Pane>
                      <Pane >
                        <Strong fontSize={14} >{I18n.get("Claim this page")}</Strong>
                      </Pane>
                    </Pane>
                  </Pane>
                </Button>

                <ModalResponsive
                  isShown={isOpenClaimPageModal}
                  onOpenComplete={() => {}}
                  onCloseComplete = {() => {
                    setIsOpenClaimPageModal(false);
                    setClaimPageProgressing(false);
                  }}
                  // onCancel={(close) => close()}
                  title='DialogClaimPage'
                  // hasHeader={false}
                  // header={}
                  hasFooter={false}
                  // footer={}
                  preventBodyScrolling={true}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEscapePress={false}
                  modalStyleProps={{
                    // "minHeight": "200px",
                  }}
                  // width={"auto"} // 350 // "100%"
                  // height={"calc(100% - 36px * 2)%"}
                  // containerProps={{
                  //   "backgroundColor": "transparent",
                  //   // "maxWidth": "100%",
                  //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
                  //   "margin": "auto", // auto
                  //   // "overflow": 'hidden',
                  // }}
                  // contentContainerProps={{
                  //   "padding": "0px",
                  //   "maxHeight": "75hv",
                  // }}
                  // styles={{
                  //   overlay: {
                  //     "background": "#7B8B9A10",
                  //     "backdrop-filter": "blur(1px)",
                  //   },
                  //   // modalContainer: {},
                  //   modal: {
                  //     "width": "100%",
                  //     "max-width": "100%",
                  //     "box-shadow": null,
                  //     "margin": 0,
                  //     "padding": 0,
                  //   },
                  // }}
                  
                >
                  
                  {({ close }) => (

                    <Pane width="100%" height="100%" background="#FFFFFF" padding={10} borderRadius={10} >

                      <Pane flex="0 1 auto" >
                        <DialogHeader
                          headerText={I18n.get('Claim page')}
                          hideLeftButton={false}
                          hideRightButton={true}
                          leftButtonDisabled={false}
                          rightButtonDisabled={false}
                          leftButtonIcon={null}
                          onLeftButtonClick={() => {
                            close();
                          }}
                          // rightButtonText={I18n.get('Next')}
                          onRightButtonClick={() => {}}
                        />
                      </Pane>

                      <Paragraph marginTop={10} whiteSpace={"break-spaces"} fontSize={14} lineHeight={1.3} color="#283655" >
                        {"This page has been created automatically. If you work for this organisation, claim this page to get access to it."}
                      </Paragraph>

                      <Pane marginTop={10} className="noselect" >
                        <Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
                        <Strong htmlFor="work-email" marginLeft={2} fontSize={14} color="#283655" >{I18n.get(`Your work email`)}</Strong>
                      </Pane>

                      <Pane marginTop={4} >
                        <TextInput
                          disabled={claimPageProgressing}
                          name={"work-email"}
                          autoComplete={"work-email"}
                          type={"email"}
                          // maxLength={40}
                          margin={0}
                          width={"100%"}
                          height={40}
                          placeholder={`${I18n.get('Work email')}...`}
                          // value={}
                          onChange={(e) => {
                            // console.log(e.target.validity);
                            let value = e.target.value;
                            let temp = value?.trim();
                            // let temp = value?.replace(/\s/g, "");
                            // console.log(temp);
                            if (temp?.length > 150) {
                              // setMatchFundingRequest({
                              //   ...matchFundingRequest,
                              //   fromFace: {
                              //     ...matchFundingRequest?.fromFace,
                              //     emailErrorMessage: I18n.get('Too long.'),
                              //     email: value,
                              //   }
                              // });
                            }
                            else {
                              // setMatchFundingRequest({
                              //   ...matchFundingRequest,
                              //   fromFace: {
                              //     ...matchFundingRequest?.fromFace,
                              //     emailErrorMessage: undefined,
                              //     email: value,
                              //   }
                              // });
                              setClaimPageWorkEmail(value);
                            }
                          }}
                          onFocus={(e) => {}}
                          // onKeyDown={(e) => {
                          // 	console.log(e.which);
                          // 	if (e.which === 32)
                          // 		return false;
                          // }}
                          onBlur={(e) => {
                            // console.log(e.target.value);
                            let value = e.target.value;
                            // console.log(getDomainNameFromEmail(value));
                            // dispatch(updateTransactionDetails({
                              // localOfferToAuth: !isValidEmail(value) ? false : offerToSignInBasedOnEmail(value),
                            // }));

                            setClaimPageWorkEmail(value?.trim());
                            
                          }}
                        />
                      </Pane>
                      <Pane marginTop={2} marginLeft={2} className="noselect" lineHeight={1} >
                        <Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("We need this to verify your association with the page.")}</Text>
                      </Pane>
                      <Button marginTop={12} fontSize={16} padding={10} height={48} className='button-green' width="100%" justifyContent="center"
                        disabled={claimPageProgressing || !isValidEmail(claimPageWorkEmail)}
                        onClick={() => {
                          setClaimPageProgressing(true);
                          handleClaimProfessionalPage({
                            publicFaceId: profileDetails.publicFaceId,
                            email: claimPageWorkEmail,
                            note: null,
                            onSuccess: () => {
                              close();
                            },
                            onError: () => {
                              // handle ???
                            },
                          });
                        }}
                        >
                          {I18n.get('Submit')}
                      </Button>

                    </Pane>
                    
                  )}
                  
                </ModalResponsive>
              </Pane>

            : null }
            

          </Pane>
          
        </Fragment>
      :
        <Pane paddingX={10} marginTop={10} >
          <Pane paddingY={2} width={90} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={180} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={28}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={60} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={220} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
        </Pane>
      }
        
    </Fragment>
  );

};

const PageFollowersList = () => {

  const profileDetails = useSelector(state => state.profileDetails);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);
  
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(false);
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  useEffect(() => {
    setLocalLoadState({
      results: profileDetails?.followers?.results || [],
      total: profileDetails?.followers?.total,
      nextToken: profileDetails?.followers?.nextToken || null,
      error: null,
    })
  }, [profileDetails?.followers?.nextToken, profileDetails?.followers?.results, profileDetails?.followers?.total]);


  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        fromPublicFaceId: null,
        toPublicFaceId: profileDetails.publicFaceId,
        viaPublicFaceId: null,
        type: "follow",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          setLocalLoadState((prev) => {
            let tempTransactionList = [...prev?.results?.results || [], ...searchResult.results];
            return ({
              ...prev,
              results: tempTransactionList,
              total: searchResult.total,
              nextToken: tempTransactionList?.length >= searchResult.total ? null : searchResult.nextToken,
              error: null,
            });
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [handleGetTransactions, localLoadState, localeState.currency, localeState.locale, profileDetails.publicFaceId, triggerLoad]);

  return (
    <ModalResponsive
      isShown={match.params.mode === "followers"}
      onOpenComplete={() => {}}
      onCloseComplete = {() => {
        history.replace({
          pathname: generatePath(match.path, {...match.params, mode: location.state.prevMode}),
          state: null,
        });
      }}
      // onCancel={(close) => close()}
      title='DialogPageFollowers'
      // hasHeader={false}
      // header={}
      hasFooter={false}
      // footer={}
      preventBodyScrolling={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      modalStyleProps={{
        // "minHeight": "200px",
      }}
      // width={"auto"} // 350 // "100%"
      // height={"calc(100% - 36px * 2)%"}
      // containerProps={{
      //   "backgroundColor": "transparent",
      //   // "maxWidth": "100%",
      //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
      //   "margin": "auto", // auto
      //   // "overflow": 'hidden',
      // }}
      // contentContainerProps={{
      //   "padding": "0px",
      //   "maxHeight": "75hv",
      // }}
      // styles={{
      //   overlay: {
      //     "background": "#7B8B9A10",
      //     "backdrop-filter": "blur(1px)",
      //   },
      //   // modalContainer: {},
      //   modal: {
      //     "width": "100%",
      //     "max-width": "100%",
      //     "box-shadow": null,
      //     "margin": 0,
      //     "padding": 0,
      //   },
      // }}
      
    >
      
      {({ close }) => (
        <Pane width="100%" height="100%" background="#FFFFFF" padding={10} borderRadius={10} >

          <DialogHeader
            headerText={I18n.get('Followers')}
            hideLeftButton={false}
            hideRightButton={true}
            leftButtonDisabled={false}
            rightButtonDisabled={false}
            leftButtonIcon={null}
            onLeftButtonClick={() => {
              close();
            }}
            // rightButtonText={I18n.get('Next')}
            onRightButtonClick={() => {}}
          />

          <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 0px)"} overflow={"hidden"} >
            <Pane position={"relative"} maxHeight={"calc(100vh - 20px - 0px - 36px * 2)"} overflow={"scroll"} >

              <Table is={"table"} width={"100%"}
                style={{
                  borderCollapse: "collapse"
                }}
              >

                {!localLoadState.total ?
                  localLoadState.total === 0 ?
                    <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={240} >
                      <Pane textAlign="center" >
                        <Pane >
                          <Strong fontSize={14} color="#7B8B9A">{I18n.get("No history yet")}</Strong>
                        </Pane>
                        <Pane >
                          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Transactions will be displayed here.")}</Text>
                        </Pane>
                      </Pane>
                    </Pane>
                  :
                    <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={240}>
                      <Spinner size={24} />
                    </Pane>
                :
                  <InfiniteScroll
                    dataLength={localLoadState?.results?.length}
                    next={fetchMoreData}
                    hasMore={localLoadState?.results?.length < localLoadState.total}
                    scrollThreshold={0.6}
                    // scrollableTarget="scrollableDiv"
                    height={240}
                    // loader={<h4>Loading...</h4>}
                  >
                  
                    <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                      {localLoadState?.results?.map((transaction, index) => (
                        
                        <Table.Row is={"tr"} width={"100%"} key={transaction.publicTransactionId}
                          // isSelectable
                          // onSelect={() => alert(transaction.name)}
                        >

                          <Table.TextCell
                            is={"td"}
                            padding={0}
                            paddingRight={0}
                          >{
                            <FaceAvatar
                              // height={24}
                              // fontSize={12}
                              faceDetails={transaction.fromFace}
                            />
                          }</Table.TextCell>

                          {/* <Table.TextCell
                            is={"td"}
                            paddingX={0}
                            flex={"none"}
                          >{
                            <Button fontSize={14} paddingX={8} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                              margin={6}
                              className="noselect"
                              disabled={false}
                              // isActive={true}
                              // appearance={"minimal"}
                              onClick={() => {
                                // this needs to depend on the viewer ???
                                // ask if sure to unfollow ...
                                // setIsPageFollowedByUserLocal(false);
                              }}
                            >
                              {I18n.get('Following')}
                            </Button>
                          }</Table.TextCell> */}

                        </Table.Row>
                        
                      ))}

                      {localLoadState?.results?.length < localLoadState.total ?
                        <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                          <Spinner size={24} />
                        </Pane>
                      : null }

                    </Table.Body>

                  </InfiniteScroll>
                }
              </Table>

            </Pane>

          </Pane>

          <Pane padding={0} height={0} >
            {/* <button style={{fontSize: "1rem"}}
              onClick={() => {
                close();
              }}
            >Close modal</button> */}
          </Pane>

        </Pane>
      )}
      
    </ModalResponsive>
  );

}

const PageFollowingList = () => {

  const profileDetails = useSelector(state => state.profileDetails);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);
  
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(false);
  const [localLoadState, setLocalLoadState] = useState({
    results: null,
    total: null,
    nextToken: null,
    error: null,
  });

  useEffect(() => {
    setLocalLoadState({
      results: profileDetails?.following?.results || [],
      total: profileDetails?.following?.total,
      nextToken: profileDetails?.following?.nextToken || null,
      error: null,
    })
  }, [profileDetails?.following?.nextToken, profileDetails?.following?.results, profileDetails?.following?.total]);


  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        fromPublicFaceId: profileDetails.publicFaceId,
        toPublicFaceId: null,
        viaPublicFaceId: null,
        type: "follow",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          setLocalLoadState((prev) => {
            let tempTransactionList = [...prev?.results?.results || [], ...searchResult.results];
            return ({
              ...prev,
              results: tempTransactionList,
              total: searchResult.total,
              nextToken: tempTransactionList?.length >= searchResult.total ? null : searchResult.nextToken,
              error: null,
            });
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [handleGetTransactions, localLoadState, localeState.currency, localeState.locale, profileDetails.publicFaceId, triggerLoad]);

  return (
    <ModalResponsive
      isShown={match.params.mode === "following"}
      onOpenComplete={() => {}}
      onCloseComplete = {() => {
        history.replace({
          pathname: generatePath(match.path, {...match.params, mode: location.state.prevMode}),
          state: null,
        });
      }}
      // onCancel={(close) => close()}
      title='DialogPageFollowing'
      // hasHeader={false}
      // header={}
      hasFooter={false}
      // footer={}
      preventBodyScrolling={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      modalStyleProps={{
        // "minHeight": "200px",
      }}
      // width={"auto"} // 350 // "100%"
      // height={"calc(100% - 36px * 2)%"}
      // containerProps={{
      //   "backgroundColor": "transparent",
      //   // "maxWidth": "100%",
      //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
      //   "margin": "auto", // auto
      //   // "overflow": 'hidden',
      // }}
      // contentContainerProps={{
      //   "padding": "0px",
      //   "maxHeight": "75hv",
      // }}
      // styles={{
      //   overlay: {
      //     "background": "#7B8B9A10",
      //     "backdrop-filter": "blur(1px)",
      //   },
      //   // modalContainer: {},
      //   modal: {
      //     "width": "100%",
      //     "max-width": "100%",
      //     "box-shadow": null,
      //     "margin": 0,
      //     "padding": 0,
      //   },
      // }}
      
    >
      
      {({ close }) => (
        <Pane width="100%" height="100%" background="#FFFFFF" padding={10} borderRadius={10} >

          <DialogHeader
            headerText={I18n.get('Following')}
            hideLeftButton={false}
            hideRightButton={true}
            leftButtonDisabled={false}
            rightButtonDisabled={false}
            leftButtonIcon={null}
            onLeftButtonClick={() => {
              close();
            }}
            // rightButtonText={I18n.get('Next')}
            onRightButtonClick={() => {}}
          />

          <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 0px)"} overflow={"hidden"} >
            <Pane position={"relative"} maxHeight={"calc(100vh - 20px - 0px - 36px * 2)"} overflow={"scroll"} >

              <Table is={"table"} width={"100%"}
                style={{
                  borderCollapse: "collapse"
                }}
              >

                {!localLoadState.total ?
                  localLoadState.total === 0 ?
                    <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={240} >
                      <Pane textAlign="center" >
                        <Pane >
                          <Strong fontSize={14} color="#7B8B9A">{I18n.get("No history yet")}</Strong>
                        </Pane>
                        <Pane >
                          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Transactions will be displayed here.")}</Text>
                        </Pane>
                      </Pane>
                    </Pane>
                  :
                    <Pane is={"tr"} display="flex" alignItems="center" justifyContent="center" height={240}>
                      <Spinner size={24} />
                    </Pane>
                :
                  <InfiniteScroll
                    dataLength={localLoadState?.results?.length}
                    next={fetchMoreData}
                    hasMore={localLoadState?.results?.length < localLoadState.total}
                    scrollThreshold={0.6}
                    // scrollableTarget="scrollableDiv"
                    height={240}
                    // loader={<h4>Loading...</h4>}
                  >
                  
                    <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
                      {localLoadState?.results?.map((transaction, index) => (
                        
                        <Table.Row is={"tr"} width={"100%"} key={transaction.publicTransactionId}
                          // isSelectable
                          // onSelect={() => alert(transaction.name)}
                        >

                          <Table.TextCell
                            is={"td"}
                            padding={0}
                            paddingRight={0}
                          >{
                            <FaceAvatar
                              // height={24}
                              // fontSize={12}
                              faceDetails={transaction.toFace}
                            />
                          }</Table.TextCell>

                          {/* <Table.TextCell
                            is={"td"}
                            paddingX={0}
                            flex={"none"}
                          >{
                            <Button fontSize={14} paddingX={8} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                              margin={6}
                              className="noselect"
                              disabled={false}
                              // isActive={true}
                              // appearance={"minimal"}
                              onClick={() => {
                                // this needs to depend on the viewer ???
                                // ask if sure to unfollow ...
                                // setIsPageFollowedByUserLocal(false);
                              }}
                            >
                              {I18n.get('Following')}
                            </Button>
                          }</Table.TextCell> */}

                        </Table.Row>
                        
                      ))}

                      {localLoadState?.results?.length < localLoadState.total ?
                        <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                          <Spinner size={24} />
                        </Pane>
                      : null }

                    </Table.Body>

                  </InfiniteScroll>
                }
              </Table>

            </Pane>

          </Pane>

          <Pane padding={0} height={0} >
            {/* <button style={{fontSize: "1rem"}}
              onClick={() => {
                close();
              }}
            >Close modal</button> */}
          </Pane>

        </Pane>
      )}
      
    </ModalResponsive>
  );

}

const PageMetrics = () => {

  let history = useHistory();
  let match = useRouteMatch();

  const profileDetails = useSelector(state => state.profileDetails);
  
  const PageMetricTile = ({ quantity, unit, ...rest }) => {

    return (
      <Pane
        // display={"flex"}
        textAlign="center"
        flex={"1 0 25%"}
        // width={32}
        minWidth={70}
        minHeight={32}
        paddingX={4}
        justifyContent="center"
        {...rest}
      >

        <Pane flex="none" >
          <Strong display="contents" flex="none" fontSize={16} color="#283655">{quantity}</Strong>
        </Pane>
        <Pane paddingTop={2} >
          <Text fontSize={14} color="#7B8B9A" >{I18n.get(unit)}</Text>
        </Pane>

      </Pane>
    );
  }

  // const isProfessionalPage = profileDetails?.tags?.filter(tag => ["charity", "retailer", "corporate"].includes(tag))?.length > 0 ? true : false;

  // if (!isProfessionalPage) {
  //   return null;
  // }

  if (!profileDetails?.publicFaceId) {
    return null;
  }

  return (
    <Fragment>
      <Pane
        marginTop={10}
        marginX={10}
        display={"flex"}
        flexWrap={"wrap"}
        gap={8}
      >
        
        {/* <Pane flex={"1 0 25%"} padding={2} justifyContent="center" appearance="minimal" height={"auto"} width={"auto"} >
          <PageMetricTile quantity={profileDetails?.history?.total || 0} unit={I18n.get("posts")} />
        </Pane> */}

        <Button flex={"1 0 25%"} padding={2} justifyContent="center" appearance="minimal" height={"auto"} width={"auto"}
          onClick={() => {
            if (profileDetails?.followers?.total > 0) {
              history.replace({
                pathname: generatePath(match.path, {...match.params, mode: "followers"}),
                state: {
                  prevMode: match.params.mode || null,
                },
              });
            }
          }}
        >
          <PageMetricTile quantity={profileDetails?.followers?.total || 0} unit={I18n.get("followers")} />
        </Button>
        <Button flex={"1 0 25%"} padding={2} justifyContent="center" appearance="minimal" height={"auto"} width={"auto"}
          onClick={(event) => {
            if (profileDetails?.following?.total > 0) {
              history.replace({
                pathname: generatePath(match.path, {...match.params, mode: "following"}),
                state: {
                  prevMode: match.params.mode || null,
                },
              });
            }
          }}
        >
          <PageMetricTile quantity={profileDetails?.following?.total || 0} unit={I18n.get("following")} />
        </Button>
        

      </Pane>
      
      <PageFollowersList/>

      <PageFollowingList/>
      
    </Fragment>
  );
}

const PageFollowAction = ({...rest}) => {

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handlePageFollow = usePageFollow();

  const pageIsFollowedByUserTransaction = usePageIsFollowByUser(profileDetails.publicFaceId);

  const [isPageFollowedByUserLocal, setIsPageFollowedByUserLocal] = useState(pageIsFollowedByUserTransaction ? true : false);
  useEffect(() => {
    setIsPageFollowedByUserLocal(pageIsFollowedByUserTransaction ? true : false);
  }, [pageIsFollowedByUserTransaction]);

  const [isOpenUnfollowPageModal, setIsOpenUnfollowPageModal] = useState(false);

  
  return (

    <Pane {...rest} >

      {isPageFollowedByUserLocal ?
        <Button width={"100%"} fontSize={14} paddingX={8} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
          className="noselect"
          disabled={false}
          // isActive={true}
          // appearance={"minimal"}
          onClick={() => {
            // ask if sure to unfollow ...
            setIsOpenUnfollowPageModal(true);
          }}
        >
          {I18n.get('Following')}
        </Button>
      :
        <Button width={"100%"} fontSize={14} paddingX={8} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
          // className="noselect"
          disabled={false}
          className='button-blue'
          onClick={() => {
            if (userDetails) {
              setIsPageFollowedByUserLocal(true);
            }
            handlePageFollow({
              publicFaceIdToFollow: profileDetails.publicFaceId,
              follow: true,
            });
          }}
        >
          {I18n.get('Follow')}
        </Button>
      }

      <ModalResponsive
        isShown={isOpenUnfollowPageModal}
        onOpenComplete={() => {}}
        onCloseComplete = {() => {
          setIsOpenUnfollowPageModal(false);
        }}
        // onCancel={(close) => close()}
        title='DialogUnfollowPage'
        // hasHeader={false}
        // header={}
        hasFooter={false}
        // footer={}
        preventBodyScrolling={true}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        modalStyleProps={{
          // "minHeight": "200px",
        }}
        // width={"auto"} // 350 // "100%"
        // height={"calc(100% - 36px * 2)%"}
        // containerProps={{
        //   "backgroundColor": "transparent",
        //   // "maxWidth": "100%",
        //   "maxHeight": "calc(100hv - 36px * 2)", // "calc(100% - 36px * 2)",
        //   "margin": "auto", // auto
        //   // "overflow": 'hidden',
        // }}
        // contentContainerProps={{
        //   "padding": "0px",
        //   "maxHeight": "75hv",
        // }}
        // styles={{
        //   overlay: {
        //     "background": "#7B8B9A10",
        //     "backdrop-filter": "blur(1px)",
        //   },
        //   // modalContainer: {},
        //   modal: {
        //     "width": "100%",
        //     "max-width": "100%",
        //     "box-shadow": null,
        //     "margin": 0,
        //     "padding": 0,
        //   },
        // }}
        
      >
        
        {({ close }) => (
          <Pane width="100%" height="100%" background="#FFFFFF" borderRadius={10} >

            <DialogHeader
              headerText={I18n.get('Are you sure?')}
              hideLeftButton={false}
              hideRightButton={true}
              leftButtonDisabled={false}
              rightButtonDisabled={false}
              leftButtonIcon={null}
              onLeftButtonClick={() => {
                close();
              }}
              // rightButtonText={I18n.get('Next')}
              onRightButtonClick={() => {}}
              paddingX={10}
              paddingTop={10}
            />

            <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
              <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                
                <Pane paddingX={10} marginTop={12} textAlign="center" >
                  <Text fontSize={14} lineHeight={1.3} color="#283655" >{I18n.get('If you stop following this page, it will be removed from the list of pages that you support.')}</Text>
                </Pane>

                <Pane paddingX={10} marginTop={12} >
                  <Button  width={"100%"} fontSize={16} paddingX={8} height={48} justifyContent="center" marginLeft="auto" marginRight="auto"
                    className="noselect"
                    disabled={false}
                    style={{color: "#EC4C47"}}
                    onClick={() => {
                      setIsPageFollowedByUserLocal(false);
                      handlePageFollow({
                        publicFaceIdToFollow: profileDetails.publicFaceId,
                        follow: false,
                      });
                      close();
                    }}
                  >
                    {I18n.get('Unfollow')}
                  </Button>
                </Pane>
                <Pane paddingX={10} paddingBottom={12} marginTop={12} >
                  <Button width={"100%"} fontSize={16} paddingX={8} height={48} justifyContent="center" marginLeft="auto" marginRight="auto"
                    className="noselect"
                    disabled={false}
                    onClick={() => {
                      close();
                    }}
                  >
                    {I18n.get('Keep following')}
                  </Button>
                </Pane>

              </Pane>
            </Pane>

            <Pane padding={0} height={0} >
              {/* <button style={{fontSize: "1rem"}}
                onClick={() => {
                  close();
                }}
              >Close modal</button> */}
            </Pane>

          </Pane>
        )}
        
      </ModalResponsive>

    </Pane>
  );
}

const ProfileDetails = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileDetails - ${countRef.current}`);
    return () => {
      console.log("ProfileDetails - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  // let location = useLocation();

  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);

  const isUserOwnPage = useMemo(() => {
    return (match.params.handle === userState.user?.publicFaceId || match.params.handle === userState.user?.username)
  }, [match.params.handle, userState.user?.publicFaceId, userState.user?.username]);

    
  return(
    <Pane marginLeft="auto" marginRight="auto" marginTop={32} minWidth={250} maxWidth={350} background="tint1" borderTopLeftRadius={10} borderTopRightRadius={10} >
      <ProfileDetailsMore />

      <ProfileInfo />
      
      <PageMetrics />

      {!isUserOwnPage && profileDetails?.tags?.includes("charity") ?
        <PageFollowAction paddingX={10} marginTop={8} />
      : null }

      <ProfileActions />

    </Pane>
  );
}

export default ProfileDetails;