import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Table, Button, Pane, SearchInput, Popover, Position, Paragraph } from 'evergreen-ui';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';


import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FacePH from '../images/FacePH';

import { I18n } from 'aws-amplify';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import FaceAvatar from './FaceAvatar';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, updateProfileDetails, setTransactionDetails, updateProfileState, updateTransactionDetails } from '../services/actions';


const LinkDown = () => {

  return (
    <Fragment>
      <Pane>
        <Pane >
          <Pane marginLeft={14} height={17} width={16}
            borderLeftWidth={3}  borderLeftStyle="solid"
            borderColor="#E6E8F0"
          />
        </Pane>
        <Pane display="flex" >
          <Pane marginLeft={14} height={22} width={16}
            borderTopLeftRadius={"60%"}
            borderLeftWidth={3}  borderLeftStyle="solid"
            borderTopWidth={3} borderTopStyle="solid" borderColor="#E6E8F0"
          />
          <Pane position="absolute" marginLeft={14} height={22} width={16}
            borderLeftWidth={3} borderLeftStyle="solid"
            borderTopWidth={3} borderColor="#E6E8F0"
          />
        </Pane>
      </Pane>

      <Pane>
        <Pane height={17} width={3} marginRight={4} />
        <Pane height={3} width={3} marginRight={4}
          background="#E6E8F0"
          borderTopRightRadius={"50%"}
          borderBottomRightRadius={"50%"}
        />
        <Pane height={19} width={3} marginRight={4} />
      </Pane>
    </Fragment>
  );
}


const TransactionFundingStructure = ({
  transactionDetails,
  hideFundingHistoryButton = false,
  ...rest
}) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    // console.log(`TransactionFundingStructure - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      // console.log("TransactionFundingStructure - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	// const profileDetails = useSelector(state => state.profileDetails);
	// const localeState = useSelector(state => state.localeState);

  const fundingTransactions = useMemo(() => {

    const transactionDetailsFundingHistory = transactionDetails?.history?.filter(transaction => transaction.role === "fundingTransaction") || [];

		if (transactionDetailsFundingHistory.length > 0) {
			return transactionDetailsFundingHistory;
		}
		else {
			return null;
		}

  }, [transactionDetails?.history]);

  const giftAidHistory = transactionDetails?.history?.find(transaction => transaction.type === "giftAidHistory");

  const matchFundingHistory = transactionDetails?.history?.find(transaction => transaction.type === "matchFundingHistory");

  const anonymiseRecipient = transactionDetails?.history?.find(transaction => transaction.type === "anonymiseRecipient") || null;
  // console.log("anonymiseRecipient", anonymiseRecipient);

  const [isFundingHistoryShown, setIsFundingHistoryShown] = useState(false);

  // return nothing ???


  return (
    <Pane {...rest} >

      {!isFundingHistoryShown ? null :
        <Fragment>

        <Pane display="flex" alignItems={"flex-end"} >
      
          <Strong fontSize={14} color="#283655">{I18n.get('Funding history')}</Strong>
          <Button marginLeft={6} paddingX={6} paddingY={4} height={18} fontSize={12} justifyContent="center" appearance="minimal"
            // disabled={processing}
            onClick={() => {
              setIsFundingHistoryShown(false);
            }}
          >
            {I18n.get('Hide')}
          </Button>
        </Pane>

        {fundingTransactions.sort((a, b) => {
            if (a.type === "donation") {
                return -1;
            }
            if (a.type === "payment") {
                return 1;
            }
            return 0;
        }).map((fundingTransaction, i) => (

        
          <Fragment>

            {i > 0 && fundingTransaction.type === "payment" ?
              <Pane display="flex" alignItems="stretch" >
                <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
                  borderBottomLeftRadius={"50%"}
                  borderBottomRightRadius={"50%"}
                />
              </Pane>
            : null }


            <Pane display="flex" flexWrap="wrap" alignItems={"center"} >
              
              {i === 0 ?
                /* top transaction */
                <Fragment>
                  <Pane>
                    <Pane height={17} width={20} />
                    <Pane marginLeft={14} height={22} width={16}
                      borderTopLeftRadius={"50%"}
                      borderLeftWidth={3}  borderLeftStyle="solid"
                      borderTopWidth={3} borderTopStyle="solid" borderColor="#E6E8F0"
                    />
                  </Pane>

                  <Pane>
                    <Pane height={17} width={3} marginRight={4} />
                    <Pane height={3} width={3} marginRight={4}
                      background="#E6E8F0"
                      borderTopRightRadius={"50%"}
                      borderBottomRightRadius={"50%"}
                    />
                    <Pane height={19} width={3} marginRight={4} />
                  </Pane>
                </Fragment>
              :
                /* middle transaction */
                <Fragment>
                  {fundingTransaction.type !== "payment" ?
                    <LinkDown />
                  :
                    <Fragment>
                      <Pane marginLeft={13} marginRight={8} height={6} width={6}
                        borderRadius={"50%"}
                        background="#E6E8F0"
                      />
                      
                    </Fragment>
                  }
                </Fragment>
              }

              <Pane paddingBottom={4} >

                <FormattedCurrencyNumber number={fundingTransaction.amount} currency={fundingTransaction.currency} fontSize={16} />

                {fundingTransaction.type === "payment" ?
                  <Text fontSize={11} color="#283655" >
                    {" "}{I18n.get('used by')}
                  </Text>
                : 
                  <Text fontSize={11} color="#283655" >
                    {" "}{I18n.get('from')}{" "}
                  </Text>
                }

                
                
              </Pane>

              <Pane marginLeft={4} >
                <FaceAvatar
                  height={24}
                  fontSize={12}
                  // anonymisedFaceDetails={anonymiseRecipient} ???
                  faceDetails={fundingTransaction.fromFace}
                />
              </Pane>

            </Pane>

            {i > 0 && fundingTransaction.type === "payment" ?
            <Fragment>
              <Pane display="flex" alignItems="stretch" >
                <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
                  borderTopLeftRadius={"50%"}
                  borderTopRightRadius={"50%"}
                />
              </Pane>
              <Pane marginLeft={14} height={10} width={16}
                borderLeftWidth={3} borderLeftStyle="solid"
                borderTopWidth={3} borderColor="#E6E8F0"
              />
            </Fragment>
          : null }

          </Fragment>
        ))}

        

        <Fragment>

          {/* bottom cap */}
          <Pane display="flex" alignItems="stretch" paddingBottom={4} >
            <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
              borderBottomLeftRadius={"50%"}
              borderBottomRightRadius={"50%"}
            />
          </Pane>

          <Pane marginLeft={4} >
            <FaceAvatar
              height={24}
              fontSize={12}
              faceDetails={transactionDetails.fromFace}
            />
          </Pane>

          
          {/* top cap */}
          <Pane display="flex" alignItems="stretch" paddingTop={4} >
            <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
              borderTopLeftRadius={"50%"}
              borderTopRightRadius={"50%"}
            />
          </Pane>


          <Pane marginLeft={14} height={14} width={16}
            borderLeftWidth={3} borderLeftStyle="solid"
            borderTopWidth={3} borderColor="#E6E8F0"
          />

          {/* bottom cap */}
          <Pane display="flex" alignItems="stretch" paddingBottom={4} >
            <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
              borderBottomLeftRadius={"50%"}
              borderBottomRightRadius={"50%"}
            />
          </Pane>
        </Fragment>

        

        </Fragment>
      }

      <Pane paddingBottom={4} display="flex" alignItems={"center"} >

        <FormattedCurrencyNumber removerDecimalZeros={false} textAlign="center" number={transactionDetails.amount} currency={transactionDetails.currency} fontSize={24} />
        
        {!fundingTransactions || isFundingHistoryShown ? null :
          <Fragment>
            {/* <Text fontSize={14} color="#283655" >
              {" "}{"from"}{" "}
            </Text> */}
            {/* <Strong fontSize={14} color="#283655" >
              {fundingTransactions.length}{" "}{I18n.get('contributions')}
            </Strong> */}
            {hideFundingHistoryButton ? null :
              <Button marginLeft={6} paddingX={6} paddingY={4} height={18} fontSize={12} color="#7B8B9A" justifyContent="center" appearance="minimal"
                // disabled={processing}
                onClick={() => {
                  setIsFundingHistoryShown(true);
                }}
              >
                {I18n.get('Funding history')}
              </Button>
            }

          </Fragment>
        }
        
      </Pane>

      {(transactionDetails.toPublicFaceId || anonymiseRecipient) ?
        <Fragment >

          {/* top cap */}
          <Pane display="flex" alignItems="stretch" >
            <Pane marginLeft={14} flex="none" background="#E6E8F0" width={3} height={3} marginRight={4}
              borderTopLeftRadius={"50%"}
              borderTopRightRadius={"50%"}
            />
          </Pane>
          

          {/* GiftAid */}
          <Fragment>
            {giftAidHistory?.relatedRecords?.results?.length > 0 ?

              <Fragment>
                <Pane background="grey" >
                  toFace Gift Aid ???
                </Pane>
              </Fragment>

            : null }
          </Fragment>

          {/* Match funding */}
          <Fragment>
            {matchFundingHistory?.relatedRecords?.results?.length > 0 ?

              <Fragment>
              
                {matchFundingHistory?.relatedRecords?.results?.map((transaction, i) => (
                  <Fragment>
                    <Pane key={i} display="flex" flexWrap="wrap" alignItems={"center"} >
                      <LinkDown />
                      <Pane paddingBottom={4} >
                      
                        {transaction.toPublicFaceId ? // deal with status ???

                          <Fragment>
                            <Pane marginLeft={4} >
                              <FaceAvatar
                                height={24}
                                fontSize={12}
                                faceDetails={transaction.toFace}
                              />
                            </Pane>
                          </Fragment>

                        :
                          <Fragment>
                            {transaction.status === "pending" ?
                              <Pane display="flex" flexWrap="wrap" alignItems={"center"} >

                                <Pane paddingBottom={0} >
                                  <Text fontSize={12} color="#283655" >
                                    {I18n.get('Match funding request is being processed')}
                                  </Text>
                                </Pane>

                              </Pane>
                            :
                              <Fragment>
                                {false ?
                                  null
                                :
                                  <Button width={"100%"} fontSize={12} paddingX={12} height={24} justifyContent="center" marginLeft="auto" marginRight="auto" className="noselect"
                                    disabled={false}
                                    onClick={() => {
                                      if (userState.user) {
                                        //
                                      }
                                      else {
                                        dispatch(updateProfileState({
                                          localStage: "DialogAuth",
                                          localMode: "sign-up",
                                        }));
                                      }
                                    }}
                                  >
                                    {`${I18n.get('Request match funding')}`}
                                  </Button>
                                }
                              </Fragment>
                            }
                          </Fragment>
                        }
                      </Pane>
                    </Pane>
                  </Fragment>
                ))}

              </Fragment>

            : null }
          </Fragment>
          
          {/* to Face link and avatar */}
          <Pane paddingBottom={4} display="flex" flexWrap="wrap" alignItems={"center"} >
            <Pane>
              <Pane marginLeft={14} height={22} width={20}
                borderBottomLeftRadius={"50%"}
                borderLeftWidth={3}  borderLeftStyle="solid"
                borderBottomWidth={3} borderBottomStyle="solid" borderColor="#E6E8F0"
              />
              <Pane height={17} width={20} />
            </Pane>

            <Pane>
              <Pane height={19} width={3} marginRight={4} />
              <Pane height={3} width={3} marginRight={4}
                background="#E6E8F0"
                borderTopRightRadius={"50%"}
                borderBottomRightRadius={"50%"}
              />
              <Pane height={17} width={3} marginRight={4} />
            </Pane>

            <Pane marginLeft={4} >
              <FaceAvatar
                anonymisedFaceDetails={anonymiseRecipient}
                faceDetails={transactionDetails.toFace}
              />
            </Pane>

          </Pane>

        </Fragment>
      : null }

    </Pane>

  );

}

export default TransactionFundingStructure;