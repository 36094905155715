import React from 'react';
// covert SVG here - https://react-svgr.com/

const LinkedinIcon = (props) => {
  // fill={props.color || "#000000"}

  return (
    <svg
      viewBox="0 0 160 154"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M33.2 16.666c0 9.206-7.4 16.666-16.533 16.666-9.133 0-16.533-7.46-16.533-16.666 0-9.2 7.4-16.667 16.533-16.667 9.133 0 16.534 7.467 16.534 16.667zm.134 30H0v106.667h33.334V46.666zm53.213 0h-33.12v106.667h33.127V97.339c0-31.133 40.193-33.68 40.193 0v55.994h33.254v-67.54c0-52.534-59.48-50.62-73.454-24.76V46.665z"
        fill={props.color || "#000000"}
        fillRule="nonzero"
      />
    </svg>
  );
}

export default LinkedinIcon;