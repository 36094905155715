import { useEffect, useState, useRef, useCallback } from 'react';
import { Pane, Button, SmallTickIcon } from 'evergreen-ui';

const DynamicTickBox = ({ disabled, completed = null, checked = null, onClick = () => {}, ...rest }) => {

  return (
    
    <Button padding={0} height={"auto"} justifyContent="center" appearance="minimal"
      className={completed ? 'button-green' : checked ? 'button-blue' : null} 
      
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {checked ?
        <SmallTickIcon padding={0} size={20} color={"#FFFFFF"} />
      :
        <Pane width={20} height={20} borderWidth={2} borderStyle="solid" borderColor="#7B8B9A" borderRadius={5} />
      }
    </Button>

  );
}

export default DynamicTickBox;