import { Fragment, cloneElement, useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tooltip, InfoSignIcon, ChevronLeftIcon, PlusIcon, MinusIcon, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import DialogHeader from './DialogHeader';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setDialogState } from '../services/actions';

import useReverseTransactions from "../services/useReverseTransactions";

import { I18n } from 'aws-amplify';

const TransactionMoreDialog = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionMoreDialog - ${countRef.current}`);
    return () => {
      console.log("TransactionMoreDialog - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  // const dialogState = useSelector(state => state.dialogState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleReverseTransactions = useReverseTransactions();

  return(
    <Pane padding={10}>

      <DialogHeader
        // headerText={I18n.get('More actions')}
        hideLeftButton={false}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        // leftButtonIcon={ChevronLeftIcon}
        onLeftButtonClick={() => {
          dispatch(setDialogState(null)); // this will close the dialog
        }}
        // rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      <Pane paddingY={12}>
        More actions ...
      </Pane>
      
      {userState.user?.rights?.adminPanel ?
                    
        <Fragment>
        
          <Button marginTop={12} fontSize={16} padding={8} height={48}
            // className={processing ? null : 'button-green'}
            width="100%" justifyContent="center"
            // disabled={processing}
            onClick={(event) => {
              console.log("event", event);
              handleReverseTransactions({
                publicTransactionId: transactionDetails.publicTransactionId,
                onSuccess: (transactionDetails) => {
                  console.log("transactionDetails after handleReverseTransactions", transactionDetails);
                  // setProcessing(false);
                },
                onError: (error) => {
                  // setProcessing(false);
                },
              });
            }}
          >
            {!false ?
              <Pane>
                {`${I18n.get('Reverse transaction')}`}
              </Pane>
            :
              <Fragment >
                <Spinner marginRight={8} flex="none" size={24} />
                <Pane >
                  {`${I18n.get('Processing')}...`}
                </Pane>
              </Fragment>
            }
          </Button>
        </Fragment>
      : null }

    </Pane>
  );
}

export default TransactionMoreDialog;