import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';

import { Auth, API, Hub, Logger } from 'aws-amplify';
import _ from 'lodash';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setUserState, updateUserState, updateLocaleState } from '../services/actions';

import { Cookies } from 'react-cookie';

import createPersistedState from 'use-persisted-state';
const useUserGlobalAuthState = createPersistedState('userGlobalAuth');

const universalCookies = new Cookies();

// https://www.rockyourcode.com/custom-react-hook-use-aws-amplify-auth/

function difference(object, base) {
  // https://gist.github.com/Yimiprod/7ee176597fef230d1451
	function changes(object, base) {
		return _.transform(object, function(result, value, key) {
      // if (_.isArray(value)) {
      //   console.log(value);
      // }
			if (!_.isEqual(value, base[key])) {
        // excluding arrays ...
				result[key] = (_.isObject(value) && _.isObject(base[key]) && !_.isArray(value)) ? changes(value, base[key]) : value;
			}
		});
	}
	return changes(object, base);
}

function deepDiffObj(base, object) {
  // https://gist.github.com/TeNNoX/5125ab5770ba287012316dd62231b764

  if (!object) throw new Error(`The object compared should be an object: ${object}`);
  if (!base) return object;
  const result = _.transform(object, (result, value, key) => {
    if (!_.has(base, key)) result[key] = value; // fix edge case: not defined to explicitly defined as undefined
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isPlainObject(value) && _.isPlainObject(base[key]) ? this.deepDiffObj(base[key], value) : value;
    }
  });
  // map removed fields to undefined
  _.forOwn(base, (value, key) => {
    if (!_.has(object, key)) result[key] = undefined;
  });
  return result;
}

const definedProps = obj => Object.fromEntries(
  Object.entries(obj).filter(([k, v]) => v)
);

const useGetUserDetails = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useGetUserDetails - ${countRef.current}`);
    return () => {
      console.log("useGetUserDetails - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);

  const handleGetUserDetails = async ({ publicFaceId = null, currency = null, locale = null, onSuccess = null, onError = null }) => {

    if (!publicFaceId) {
      console.error("within getUserDetails", "Either publicFaceId or handle is required");
      if (typeof onError === "function") {
        onError("Either publicFaceId or handle is required");
      }
      return;
    }
    
    // else

    let authMode = "AWS_IAM";

    try {
      await Auth.currentAuthenticatedUser({ bypassCache: false });
      authMode = "AMAZON_COGNITO_USER_POOLS";
    }
    catch (error) {
      console.error("within currentAuthenticatedUser", error);
      if (typeof onError === "function") {
        onError(error);
      }
      return;
    }

    try {
      const response = await API.graphql({
        query: queries.getUserDetails,
        variables: {
          publicFaceId: publicFaceId,
          currency: currency,
          locale: locale,
        },
        authMode: authMode,
      });
      
      const userDetails = response.data.getUserDetails;

      if (typeof onSuccess === "function") {
        onSuccess(userDetails);
      }

    }
    catch(error) {
      console.error("within getUserDetails", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
    
  };

  return handleGetUserDetails;
}

const useAuthUpdateUserDetails = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthUpdateUserDetails - ${countRef.current}`);
    return () => {
      console.log("useAuthUpdateUserDetails - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState.actAsUser, userState.user]);


  const handleUpdateUserDetails = useCallback(async({
    accessCode,
    userDetailsToUpdate,
    bankAccountsToUpdate,
    recordsToUpdate,
    relatedRecordsToUpdate,
    onSuccess = null,
    onError = null
  }) => {

    function deepObjectDiff(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (_.isUndefined(base?.[key])) {
            result[key] = value;
          } else {
            if (!_.isEqual(value, base?.[key])) {
              result[key] =
                _.isObject(value) || _.isArray(value)
                  ? changes(value, base?.[key])
                  : value;
            }
          }
        });
      }
      return changes(object, base);
    }

    console.log("deepObjectDiff", deepObjectDiff(userDetailsToUpdate, userDetails));

    // this doesn't get the difference in arrays
    // let differenceInUserDetails = difference(userDetailsToUpdate, userDetails);

    // let differenceInUserDetails = deepObjectDiff(userDetailsToUpdate, userDetails);

    // this keeps arrays as passed
    // const tempArray = _.differenceWith([userDetailsToUpdate], [userDetails], _.isEqual);

    // const clearedUserDetailsToUpdate = tempArray[0];
    // console.log("clearedUserDetailsToUpdate", clearedUserDetailsToUpdate);
    // console.log("userDetails.records", [...userDetails.records]);
    
    // if (userDetails?.records && clearedUserDetailsToUpdate?.records) {

    //   // this is needed to ignore array comparison as the amounts arrays didn't work
    //   function customizer(objValue, srcValue) {
    //     if ( _.isArray(objValue) && _.isArray(srcValue) && !_.isEqual(objValue, srcValue) ) {
    //       return false;
    //     }
    //   }
      
    //   function customisedFilter(newObject, oldObject) {
    //     let keepInArrayFlag = _.isMatchWith(oldObject, newObject, customizer);
    //     // console.log("keepInArrayFlag", keepInArrayFlag);
    //     return (keepInArrayFlag);
    //   }

    //   clearedUserDetailsToUpdate.records = _.differenceWith(clearedUserDetailsToUpdate.records, userDetails.records, customisedFilter);
    // }

    console.log("sending userDetailsToUpdate", userDetailsToUpdate);
    console.log("sending bankAccountsToUpdate", bankAccountsToUpdate);
    console.log("sending recordsToUpdate", recordsToUpdate);
    console.log("sending relatedRecordsToUpdate", relatedRecordsToUpdate);

    try {

      const response = await API.graphql({
        query: mutations.updateUserDetails,
        variables: {
          publicFaceId: userDetails?.publicFaceId,
          accessCode: accessCode,
          userDetailsToUpdate: userDetailsToUpdate,
          bankAccountsToUpdate: bankAccountsToUpdate,
          recordsToUpdate: recordsToUpdate,
          relatedRecordsToUpdate: relatedRecordsToUpdate,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      
      const updatedFaceDetailsResponse = response.data.updateUserDetails; // this is not FaceDetails
      console.log("updatedFaceDetailsResponse", updatedFaceDetailsResponse);
      const updatedUserDetails = definedProps(updatedFaceDetailsResponse.updatedUserDetails);
      // const updatedUserDetails = updatedFaceDetailsResponse.updatedUserDetails;
      
      console.log("updatedUserDetails", updatedUserDetails);

      if (updatedUserDetails.status === "error") {
        if (typeof onError === "function") {
          onError(updatedUserDetails.error);
        }
        return null;
      }
      
      function customiser(originalObject, newObject) {

        function isObject(item) {
          return (item && typeof item === 'object' && !Array.isArray(item));
        }

        if (newObject === null || typeof newObject === 'undefined') {
          return originalObject;
        }
        else if (!newObject) { // === "" || === 0
          return newObject; // to return primitive values
        }
        else if (_.isArray(originalObject) && !_.isArray(newObject)) {
          return originalObject;
        }
        else if (_.isArray(originalObject)) {
          if (isObject(originalObject?.[0])) {
            return originalObject; // originally newObject
          }
          else if (!isObject(originalObject?.[0])) {  // to return arrays of primitive values
            return newObject;
          }
          else {
            return originalObject;
          }
        }
        else {
          return newObject; // all "undefined"-like are covered above
        }
      }

      console.log("current userDetails", {...userDetails});
      
      let freshUserDetails = _.mergeWith(userDetails, updatedUserDetails, customiser);
      
      // console.log("freshUserDetails X", {...freshUserDetails});
      // console.log(`string freshUserDetails: ${JSON.stringify(freshUserDetails)}`);

      // deal with updatedRecords
      if (updatedFaceDetailsResponse.updatedRecords?.length > 0) {

        
        const freshUserDetailsRecords = [...freshUserDetails.records]; // keep this, otherwise references to userDetails will not trigger hook updates...
        
        for (let i = 0; i < updatedFaceDetailsResponse.updatedRecords.length; i++) {

          const recordToPlace = updatedFaceDetailsResponse.updatedRecords[i];
          console.log("recordToPlace", {...recordToPlace});
          console.log("recordToPlace.type", recordToPlace.type);

          let indexToReplace = null;

          const recordToUpdate = freshUserDetailsRecords?.find((t, index) => {
            if (t.type === recordToPlace.type) {
              indexToReplace = index;
              return t;
            }
            return false
          });

          console.log("indexToReplace", indexToReplace);

          freshUserDetailsRecords.splice(indexToReplace, indexToReplace !== null ? 1 : 0, recordToPlace);

          _.remove(freshUserDetailsRecords, (t) => (t.status === "deleted"));
        }
        
        console.log("freshUserDetailsRecords", [...freshUserDetailsRecords]);
        freshUserDetails.records = freshUserDetailsRecords;

      }

      // deal with updatedRelatedRecords
      if (updatedFaceDetailsResponse.updatedRelatedRecords?.length > 0) {

        // keep this, otherwise references to userDetails will not trigger hook updates...
        const freshUserDetailsRecords = [...freshUserDetails.records];
        
        for (let i = 0; i < updatedFaceDetailsResponse.updatedRelatedRecords.length; i++) {

          const relatedRecordToPlace = updatedFaceDetailsResponse.updatedRelatedRecords[i];
          // console.log("relatedRecordToPlace", relatedRecordToPlace);

          let indexToReplace = null;

          const recordToUpdate = freshUserDetailsRecords?.find((t, index) => {
            if (t.type === relatedRecordToPlace.type) {
              indexToReplace = index;
              return t;
            }
            return false
          });
          
          if (!_.isArray(recordToUpdate?.relatedRecords?.results)) {
            console.log("empty relatedRecords...");
            recordToUpdate.relatedRecords = {};
            recordToUpdate.relatedRecords.results = [];
          }
          const recordRelatedRecords = recordToUpdate?.relatedRecords?.results || [];
          // console.log("recordRelatedRecords", recordRelatedRecords);

          // console.log("indexToReplace", indexToReplace);

          recordRelatedRecords.splice(indexToReplace, indexToReplace !== null ? 1 : 0, relatedRecordToPlace);

          _.remove(recordToUpdate.relatedRecords.results, (t) => (!t.publicTransactionId || t.status === "deleted"));
        }
        
        // keep this, otherwise references to userDetails will not trigger hook updates...
        freshUserDetails.records = freshUserDetailsRecords;

      }

      console.log("freshUserDetails", freshUserDetails);

      if (userState.actAsUser){
        dispatch(updateUserState({
          actAsUser: freshUserDetails,
        }));
      }
      else {
        dispatch(updateUserState({
          isLoading: false,
          isError: false,
          user: freshUserDetails,
          actAsUser: null,
        }));
      }

      // is this needed at all if userState gets updated ???
      if (typeof onSuccess === "function") {
        onSuccess(freshUserDetails);
      }

    }
    catch (error) {
      // need global handling...
      console.error('with updateUserDetails', error);
      // let tempErrorObject = null;
      // if (error.errors && error.errors.length > 0) {
      //   tempErrorObject = {
      //     name: error.errors[0].message,
      //     message: error.errors[0].message,
      //     code: error.errors[0].message,
      //   }
      // }
      // else {
      //   tempErrorObject = error;
      // }
      if (typeof onError === "function") {
        onError(error);
      }
      return null;
    }
  }, [dispatch, userDetails, userState.actAsUser]);

  return handleUpdateUserDetails;

}

const useGetSystemState = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useGetSystemState - ${countRef.current}`);
    return () => {
      console.log("useGetSystemState - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();
  // let params = useParams();

  // const queryParams = useMemo(() => {
  //   return new URLSearchParams(location.search)
  // },[location.search]);
  
  const dispatch = useDispatch();
  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const getSystemState = async({ onSuccess = null, onError = null }) => {
    try {
      const response = await API.graphql({
        query: queries.getSystemState,
        variables: {
          // publicFaceId: publicFaceId,
          // currency: currency,
          // locale: locale,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      
      const systemStateTransaction = response.data.getSystemState;
      console.log("systemStateTransaction", systemStateTransaction);

      dispatch(updateLocaleState({
        systemState: systemStateTransaction
      }));
      
      if (typeof onSuccess === "function") {
        onSuccess(systemStateTransaction);
      }

    }
    catch(error) {
      console.error("within getSystemState", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
    
    
  };

  // keep this ?...
  const handleGetSystemState = async({ onSuccess = null, onError = null } = {}) => {
    await getSystemState({ onSuccess, onError });
  };
  
  return handleGetSystemState;

}

const useAuthFetchUserData = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthFetchUserData - ${countRef.current}`);
    return () => {
      console.log("useAuthFetchUserData - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();
  // let params = useParams();

  // const queryParams = useMemo(() => {
  //   return new URLSearchParams(location.search)
  // },[location.search]);
  
  const dispatch = useDispatch();
  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const handleGetUserDetails = useGetUserDetails();

  const fetchUserData = useCallback(async({ publicFaceId = null, onSuccess = null, onError = null }) => {

    publicFaceId = publicFaceId || userState.user?.publicFaceId;

    if (!publicFaceId) {
      
      try {

        // const currentCredentials = await Auth.currentCredentials(); // included if authenticated and expiration time
        // console.log("currentCredentials", currentCredentials);

        // const currentSession = await Auth.currentSession();
        // console.log("currentSession", currentSession);

        // const currentUserCredentials = await Auth.currentUserCredentials();
        // console.log("currentUserCredentials", currentUserCredentials);

        // const currentUserInfo = await Auth.currentUserInfo();
        // console.log("currentUserInfo", currentUserInfo);

        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser({
          bypassCache: false, // true  // optional, default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        console.log("currentAuthenticatedUser", currentAuthenticatedUser);

        publicFaceId = currentAuthenticatedUser?.signInUserSession?.idToken?.payload?.["cognito:username"]; // Cognito's USERNAME is NOT ALWAYS the same as publicFaceId
        
      }
      catch (error) {

        if (error === "The user is not authenticated") {
          console.warn(error);
        }
        else {
          console.error("with Auth.currentAuthenticatedUser:", error);
        }

        dispatch(updateUserState({
          isLoading: false,
          globalAuth: false,
        }));

        if (typeof onError === "function") {
          onError(error);
        }

      }
    }
    
    // only if user is authenticated
    if (publicFaceId) {

      console.log("handleGetUserDetails for", publicFaceId);
      handleGetUserDetails({
        publicFaceId: publicFaceId,
        currency: localeState.currency,
        locale: localeState.locale,
        onSuccess: (userDetails) => {

          dispatch(updateUserState({
            isLoading: false,
            isError: false,
            user: userDetails,
            actAsUser: null,
            globalAuth: true,
          }));

          if (typeof onSuccess === "function") {
            onSuccess(userDetails);
          }
  
        },
        onError: (error) => {
  
          console.error("after handleGetUserDetails", error);

          let currentError = error;
          if (currentError.errors?.length > 0) {
            currentError = currentError.errors[0];
          }

          dispatch(updateUserState({
            isError: true, // ???
            error: currentError,
          }));

          if (typeof onError === "function") {
            onError(error);
          }
  
        },
      });
    }
    
    
  }, [dispatch, handleGetUserDetails, localeState.currency, localeState.locale, userState.user?.publicFaceId]);
  
  // keep this...
  const handleFetchUserData = async({ publicFaceId = null, onSuccess = null, onError = null } = {}) => {
    await fetchUserData({publicFaceId, onSuccess, onError});
  };

  return handleFetchUserData;

}

const useAuthInitialiseUser = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthInitialiseUser - ${countRef.current}`);
    return () => {
      isCurrent.current = false;

      Hub.remove('auth');
      // hubListenerRef.current = null;
      console.log("useAuthInitialiseUser - cleaned up");
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  
  const hubListenerRef = useRef();

  // universalCookies.get("_u") === 1
  // set cookie to indicate user signed in
  // let d = new Date();
  // d.setMonth(d.getMonth() + 6);
  // universalCookies.set("_u", 1, { path: '/', expires: d });
  // universalCookies.remove("_u");
  
  const [userGlobalAuth, setUserGlobalAuth] = useUserGlobalAuthState(userState.globalAuth);
  const [systemStatePulled, setSystemStatePulled] = useState(false);
  const [triggerInitFetch, setTriggerInitFetch] = useState(!userGlobalAuth);

  const handleGetSystemState = useGetSystemState();

  const handleFetchUserData = useAuthFetchUserData();
  const handleUserSignOut = useAuthUserSignOut();
  

  useEffect(() => { // keep
    console.log("userGlobalAuth", userGlobalAuth);
    if (userGlobalAuth && !userState.globalAuth) {
      console.log("setTriggerInitFetch(true)");
      setTriggerInitFetch(true);
    }
  }, [userGlobalAuth, userState.globalAuth]);
  
  // HubListener
  useEffect(() => {

    const HubListener = () => {

      const onAuthEvent = (payload) => {
        const logger = new Logger('Auth-Logger');
        switch (payload.event) {
          case 'signIn':
            logger.warn('User signed in', payload);
            dispatch(updateUserState({
              globalAuth: true,
            }));
            setUserGlobalAuth(true);
            break
          case 'signUp':
            logger.warn('user signed up', payload);
            // set cookie to indicate user has signed up
            break;
          case 'signOut':
            setUserGlobalAuth(false);
            logger.warn('user signed out', payload);
            break;
          case 'signIn_failure':
            setUserGlobalAuth(false);
            logger.error('user sign in failed', payload);
            break;
          case 'configured':
            // logger.warn('the Auth module is configured');
            break;
          case 'tokenRefresh':
            logger.warn('tokenRefresh', payload);
            break;
          default:
            logger.warn('payload', payload);
            return
        }
      }

      Hub.listen('auth', data => {
        const { payload } = data;
        // console.log('HubListener data', data);
        onAuthEvent(payload);
      });

    }

    if (!hubListenerRef.current) {
      console.log("trigger HubListener");
      HubListener();
      hubListenerRef.current = true;
    }

  }, [dispatch, setUserGlobalAuth]);

  // init
  useEffect(() => {
    if (triggerInitFetch) {
      console.log("trigger handleFetchUserData");
      handleFetchUserData();
      setTriggerInitFetch(false);
    }
  }, [handleFetchUserData, triggerInitFetch]);

  useEffect(() => {
    if (!systemStatePulled && !userState.isLoading) {
      handleGetSystemState();
      setSystemStatePulled(true);
    }
  }, [handleFetchUserData, handleGetSystemState, systemStatePulled, userState.isLoading]);

  

  // sign out
  useEffect(() => {
    if (!userGlobalAuth && userState.globalAuth) {
      console.log("trigger handleUserSignOut by userGlobalAuth");
      handleUserSignOut();
    }
  }, [handleUserSignOut, userGlobalAuth, userState.globalAuth, userState.user]);

}

const useAuthUserSignIn = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthUserSignIn - ${countRef.current}`);
    return () => {
      console.log("useAuthUserSignIn - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);

  const handleFetchUserData = useAuthFetchUserData();

  const handleUserSignIn = async({ username = null, password = null, onSuccess = null, onError = null }) => {
    
    if (!username || !password) {
      throw Error("username and/or password not provided");
    }

    try {
      
      // For advanced usage
      // You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger

      const user = await Auth.signIn({
        username: username?.toLowerCase()?.trim(), // Required, the username
        password: password, // Optional, the password
        // validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
      });
      

      // if (user.challengeName === 'SMS_MFA' ||
      //   user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      //   // You need to get the code from the UI inputs
      //   // and then trigger the following function with a button click
      //   const code = getCodeFromUserInput();
      //   // If MFA is enabled, log-in should be confirmed with the confirmation code
      //   const loggedUser = await Auth.confirmSignIn(
      //     user,   // Return object from Auth.signIn()
      //     code,   // Confirmation code  
      //     mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      //   );
      // }
      // else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      //   const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      //   // You need to get the new password and required attributes from the UI inputs
      //   // and then trigger the following function with a button click
      //   // For example, the email and phone_number are required attributes
      //   const {username, email, phone_number} = getInfoFromUserInput();
      //   const loggedUser = await Auth.completeNewPassword(
      //     user,              // the Cognito User Object
      //     newPassword,       // the new password
      //     // OPTIONAL, the required attributes
      //     {
      //         email,
      //         phone_number,
      //     }
      //   );
      // }
      // else if (user.challengeName === 'MFA_SETUP') {
      //   // This happens when the MFA method is TOTP
      //   // The user needs to setup the TOTP before using it
      //   // More info please check the Enabling MFA part
      //   Auth.setupTOTP(user);
      // }
      // else {
      //   // The user directly signs in
      //   console.log(user);
      // }

      console.log("trigger handleFetchUserData from handleUserSignIn", user);
      handleFetchUserData({
        publicFaceId: user?.username,
        onSuccess, onError
      });

    }
    catch (error) {

      // if (error.code === 'UserNotConfirmedException') {
      //   // The error happens if the user didn't finish the confirmation step when signing up
      //   // In this case you need to resend the code and confirm the user
      //   // About how to resend the code and confirm the user, please check the signUp part
      // }
      // else if (error.code === 'PasswordResetRequiredException') {
      //   // The error happens when the password is reset in the Cognito console
      //   // In this case you need to call forgotPassword to reset the password
      //   // Please check the Forgot Password part.
      // }
      // else if (error.code === 'NotAuthorizedException') {
      //   // The error happens when the incorrect password is provided
      // }
      // else if (error.code === 'UserNotFoundException') {
      //   // The error happens when the supplied username/email does not exist in the Cognito user pool
      // }
      // else {
      //   // console.log(error);
      // }

      console.error("within useAuthUserSignIn", error);
      
      dispatch(updateUserState({
        isLoading: false,
        isError: true,
        user: null,
        actAsUser: null,
      }));

      if (typeof onError === "function") {
        onError(error);
      }

    }
  }
  
  return handleUserSignIn;

}

const useAuthUserSignOut = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthUserSignOut - ${countRef.current}`);
    return () => {
      console.log("useAuthUserSignOut - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();

  const handleUserSignOut = useCallback(async({ isGlobal = false, onSuccess = null, onError = null } = {}) => {

    console.log("handleUserSignOut");

    try {

      await Auth.signOut({ global: isGlobal });

      dispatch(setUserState({
        isLoading: false,
      }));
      
      if (typeof onSuccess === "function") {
        onSuccess(null);
      }
    }
    catch (error) {
      console.error('Error with handleSignOut:', error);
      dispatch(updateUserState({
        isError: true, // ???
      }));
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }, [dispatch]);

  return handleUserSignOut;

}

const useAuthCheckUserDetails = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthCheckUserDetails - ${countRef.current}`);
    return () => {
      console.log("useAuthCheckUserDetails - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const userState = useSelector(state => state.userState);

  const handleCheckUserDetails = async({ email = null, onSuccess = null, onError = null }) => {
  
    try {
      if (!email) {
        throw Error("email not provided");
      }
      email = email?.toLowerCase().trim();

      const response = await API.graphql({
        query: queries.checkUserDetails,
        variables: {
          email: email
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      
      const faceDetails = response.data.checkUserDetails;

      if (typeof onSuccess === "function") {
        onSuccess(faceDetails);
      }
      
    }
    catch (error) {
      console.error("with useAuthCheckUserDetails", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }
  
  return handleCheckUserDetails;

}

const useAuthUserSignUp = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useAuthUserSignUp - ${countRef.current}`);
    return () => {
      console.log("useAuthUserSignUp - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const handleUserSignUp = async({ email = null, password = null, clientMetadata = null, onSuccess = null, onError = null } = {}) => {

    try {
      if (!email || !password) {
        throw Error("email and/or password not provided");
      }
      const response = await API.graphql({
        query: mutations.userSignUp,
        variables: {
          email: email.toLowerCase().trim(),
          password: password,
          clientMetadata: JSON.stringify(clientMetadata),
        },
        authMode: "AWS_IAM"
        // authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      const userDetails = response.data.userSignUp; // minimal and are not required later... // ???
      // console.log("handleUserSignUp userDetails", userDetails);
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
    catch (error) {
      console.error('within useAuthUserSignUp', error);
      let tempErrorObject = error;
      if (error?.errors?.length > 0) { // ???
        tempErrorObject = error.errors[0];
      }
      if (typeof onError === "function") {
        onError(tempErrorObject);
      }
    }
  };

  return handleUserSignUp;
}

const useChangeUserPassword = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useChangeUserPassword - ${countRef.current}`);
    return () => {
      console.log("useChangeUserPassword - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const handleChangeUserPassword = useCallback(async({ oldPassword = null, newPassword = null, onSuccess = null, onError = null }) => {
    
    try {
      // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#password-operations
      const authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: false }); // need {bypassCache: true} ???
      const data = await Auth.changePassword(authenticatedUser, oldPassword, newPassword);
      console.log("handleChangeUserPassword data:", data);
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
    catch (error) {
      console.error("handleChangeUserPassword", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }


  }, []);

  return handleChangeUserPassword;

}

const useResetUserPassword = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useResetUserPassword - ${countRef.current}`);
    return () => {
      console.log("useResetUserPassword - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();

  const handleFetchUserData = useAuthFetchUserData();

  const handleSendSecretCode = useCallback(async({ username = null, clientMetadata = null, onSuccess = null, onError = null }) => {

    username = username.toLowerCase().trim();
    const password = null;
    // validationData
    const validationData = {
      locale: "x",
    };
    
    try {

      Auth.configure({
        authenticationFlowType: "CUSTOM_AUTH"
      });

      const user = await Auth.signIn({
        username: username, // Required, the username
        password: password, // Optional, the password
        // validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
      });
      // console.log("user after handleSendSecretCode", user);
      if (typeof onSuccess === "function") {
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          onSuccess(user);
        }
      }
      // switch back from "CUSTOM_AUTH"
      Auth.configure({
        authenticationFlowType: "USER_SRP_AUTH",
      });  
    }
    catch (error) {
      console.error('with handleSendSecretCode:', error);
      // handle PasswordResetRequiredException: Password reset required for the user
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }, []);

  const handleVerifySecretCode = useCallback(async({ user = null, code = null, username = null, newPassword = null, signOutOnFailToChangePassword = true, clientMetadata = null, onSuccess = null, onError = null }) => {

    try {
      user = await Auth.sendCustomChallengeAnswer(user, code, clientMetadata);
      // console.log("user after sendCustomChallengeAnswer", user);
    }
    catch (error) {
      console.error('with sendCustomChallengeAnswer:', error);
      let tempErrorObject = null;
      if (error.errors && error.errors.length > 0) {
        tempErrorObject = {
          name: error.errors[0].message,
          message: error.errors[0].message,
          code: error.errors[0].message,
        }
      }
      else if (error === "not authenticated") {
        tempErrorObject = {
          name: "x_wrong_code",
          message: "x_wrong_code",
          code: "x_wrong_code",
        }
      }
      else {
        tempErrorObject = error;
      }
      
      if (typeof onError === "function") {
        onError(tempErrorObject);
      }
    }

    if (user) {
      if (newPassword) {
        try {
          const response = await API.graphql({
            query: mutations.userChangePassword,
            variables: {
              newPassword: newPassword,
              clientMetadata: JSON.stringify(clientMetadata), // just placeholder as of 18th April 2022
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          
          dispatch(updateUserState({
            user: response.data.userChangePassword, // ???
          }));

        }
        catch (error) {
          console.error('with userChangePassword:', error);
          if (signOutOnFailToChangePassword) {
            await Auth.signOut();
          }
          
          if (typeof onError === "function") {
            onError(error);
          }

        }
      }
            
      handleFetchUserData({onSuccess: onSuccess, onError: onError});   // ??? why here
    }
    else {
      throw Error("no user provided at handleVerifySecretCode");
    }
    
  }, [dispatch, handleFetchUserData]);


  return { handleSendSecretCode, handleVerifySecretCode };
  
}

const useRequestAuthentication = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`useRequestAuthentication - ${countRef.current}`);
    return () => {
      console.log("useRequestAuthentication - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const handleRequestAuthentication = async({ type = null, accessCode = null, email = null, phone = null, phoneDialCode = null, onSuccess = null, onError = null}) => {

    try {
      const response = await API.graphql({
        query: queries.requestAuthentication,
        variables: {
          type: type,
          // toPublicFaceId: null, 
          accessCode: accessCode,
          email: email,
          phone: phone,
          phoneDialCode: phoneDialCode,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const transactionDetails = response.data.requestAuthentication;
      
      if (typeof onSuccess === "function") {
        onSuccess(transactionDetails);
      }
      
    }
    catch (error) {
      console.error('within requestAuthentication:', error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
    
  };


  return handleRequestAuthentication;
  
}

// export default useAmplifyAuth;
export {
  useGetUserDetails,
  useAuthInitialiseUser,
  useAuthCheckUserDetails,
  useAuthUserSignUp,
  useAuthUserSignIn,
  useAuthUpdateUserDetails,
  useAuthUserSignOut,
  useChangeUserPassword,
  useResetUserPassword,
  useRequestAuthentication
};