import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Switch, Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';

import { useSelector } from 'react-redux';

import { useChangeUserPassword } from "../services/useAmplifyAuth";

import { Auth, API, Storage, I18n} from 'aws-amplify';
import * as queries from '../graphql/queries';

const AccountChangePassword = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("AccountChangePassword cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const [processing, setProcessing] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmedNewPassword, setConfirmedNewPassword] = useState();
  const [changePasswordError, setChangePasswordError] = useState();
  
  const handleChangeUserPassword = useChangeUserPassword();

  const handleChangePassword = useCallback(async () => {

    if (!userState.isLoading && isCurrent) {
      setProcessing(true);
      handleChangeUserPassword({
        oldPassword: oldPassword,
        newPassword: newPassword,
        onSuccess: () => {
          setOldPassword();
          setNewPassword();
          setConfirmedNewPassword();
          setProcessing(false);
        },
        onError: (error) => {
          console.error('Error: ', error);
          setChangePasswordError(error);
          setProcessing(false);
        },
      });
    }
    
  }, [handleChangeUserPassword, newPassword, oldPassword, userState.isLoading]);

  return (
    <Pane marginTop={20}>
      <DynamicInputField label={I18n.get('Old password')} inputName="current-password" type="password" disabled={processing} placeholder={`${I18n.get('Old password')}...`} value={oldPassword} onChange={setOldPassword} />
      <DynamicInputField label={I18n.get('New password')} inputName="new-password" type="password" disabled={processing} placeholder={`${I18n.get('New password')}...`} value={newPassword} onChange={setNewPassword} />
      <DynamicInputField label={I18n.get('Confirm new password')} inputName="new-password" type="password" disabled={processing} placeholder={`${I18n.get('Confirm new password')}...`} value={confirmedNewPassword} onChange={setConfirmedNewPassword} />

      <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} className="button-green"
                  disabled={!oldPassword || newPassword !== confirmedNewPassword}
                  onClick={handleChangePassword}
                >
                  {I18n.get('Change password')}
                </Button>
              </Pane>

              {/* <Pane flex={1} textAlign="center" >
                <Button justifyContent="center" paddingX={12} height={30} appearance="minimal"
                  disabled={true}
                  onClick={() => {}}
                >
                  {I18n.get('Forgot password')}
                </Button>
              </Pane> */}

            </Pane>
          }
        </Pane>
      </Pane>
    </Pane>
  );

}

export default AccountChangePassword;