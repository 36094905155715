import { useEffect, useState, useMemo, useRef, forwardRef, useImperativeHandle, useCallback, useContext } from 'react';
import { Link, useHistory, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import { NumericFormat } from 'react-number-format';

const DynamicInputField = forwardRef(({
  label,
  inputName,
  type = "text",
  format,
  prefix,
  suffix,
  disabled,
  isInvalid,
  placeholder,
  value = "",
  description = null,
  onChange,
  onBlur, required,
  onValidation,
  messageWhenInvalid,
  marginTop = 12,
  // maxLength,
  // pattern,
}, ref) => {

  const is425PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });
  
  const localInputEventRef = useRef(null);

  // const [localIsInvalid, setLocalIsInvalid] = useState(typeof isInvalid === "boolean" ? isInvalid : typeof isInvalid === "function" ? isInvalid : false);
  // useEffect(() => {
  //   setLocalIsInvalid(typeof isInvalid === "boolean" ? isInvalid : typeof isInvalid === "function" ? isInvalid : false);
  // }, [isInvalid]);

  const localIsInvalid = useMemo(() => {
    if (typeof isInvalid === "boolean") {
      return isInvalid;
    }
    else if (typeof isInvalid === "function") {
      // console.log("isInvalid e", localInputEventRef.current);
      return (isInvalid(localInputEventRef.current));
    }
    else {
      return false;
    }
  }, [isInvalid]);

  // const localRequired = typeof required === "boolean" ? required : false;

  // useEffect(() => {
  //   console.log("value", value);
  // }, [value]);



  


  // useEffect(() => {
  //   console.log();
  // }, []);

  const [localValidationMessage, setLocalValidationMessage] = useState();

  const localInputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    // el: ...
    input: localInputRef.current,

  }));

  return (
    <Pane marginTop={marginTop} alignItems="baseline" display="flex" flexWrap="wrap" maxWidth={570} >
      <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} >
        <Strong htmlFor={inputName} fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{label}</Strong>
      </Pane>
      
      <Pane flex={1} flexBasis={242} maxWidth={390} >
        {format ?
          <NumericFormat
            ref={localInputRef}
            customInput={TextInput}
            format={format}
            // prefix={prefix} // doesn't seems to work
            // suffix={suffix} // doesn't seems to work
            id={inputName}
            autoComplete={inputName}
            name={inputName}
            disabled={disabled}
            isInvalid={localIsInvalid}
            type={type}
            // maxLength={maxLength}
            marginTop={0}
            marginBottom={0}
            width="100%"
            maxWidth={380}
            height={40}
            placeholder={placeholder}
            value={value} // || "" ???
            onValueChange={(e) => { // https://www.npmjs.com/package/react-number-format#values-object
              
              if (typeof onValidation === "function") {
                setLocalValidationMessage(onValidation(e.value));
              }

              if (typeof onChange === "function") {
                onChange(e.value);
              }
              
            }}
            // onChange={(e) => {
            //   if (typeof onChange === "function") {
            //     console.log("e onChange", e);
            //     onChange(e.target.value);
            //   }
            // }}
            onBlur={(e) => {
              console.log("onBlur e", e);
              if (typeof onBlur === "function") {
                onBlur(e);
              }
            }}
          />
        :
          <TextInput
            ref={localInputRef}
            id={inputName}
            autoComplete={inputName}
            name={inputName}
            disabled={disabled}
            isInvalid={localIsInvalid}
            type={type}
            // maxLength={maxLength}
            // pattern={pattern}
            marginTop={0}
            marginBottom={0}
            width="100%"
            maxWidth={380}
            height={40}
            placeholder={placeholder}
            value={value || ""}
            onChange={(e) => {
              localInputEventRef.current = e;
              // console.log("onValueChange e", e);
              if (typeof onValidation === "function") {
                setLocalValidationMessage(onValidation(e.target.value));
              }

              if (typeof onChange === "function") {
                onChange(e.target.value);
              }
            }}

            onBlur={(e) => {
              if (typeof onBlur === "function") {
                onBlur(e);
              }
            }}
          />
        }

        {localValidationMessage || null}

        {(messageWhenInvalid) ?
          <Pane marginLeft="auto" marginRight="auto" paddingBottom={2} >
            <Text fontSize={14} color="#EC4C47">{messageWhenInvalid}</Text>
          </Pane>
        : null }
        {description && !messageWhenInvalid ?
          <Pane marginLeft="auto" marginRight="auto" paddingBottom={2} >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{description}</Text>
          </Pane>
        : null }


      </Pane>
    </Pane>
  );
});

export default DynamicInputField;