import React from 'react';
// covert SVG here - https://react-svgr.com/

const InstagramIcon = (props) => {
  // fill={props.color || "#000000"}

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 511.886"
      {...props}
    >
      <path
        d="M256.057 124.702c-72.645 0-131.241 58.596-131.241 131.241 0 72.645 58.596 131.241 131.241 131.241 72.646 0 131.241-58.596 131.241-131.241 0-72.645-58.595-131.241-131.241-131.241zm0 216.565c-46.945 0-85.323-38.265-85.323-85.324s38.264-85.324 85.323-85.324c47.06 0 85.324 38.265 85.324 85.324s-38.378 85.324-85.324 85.324zm167.221-221.933c0 17.019-13.706 30.611-30.611 30.611-17.019 0-30.612-13.707-30.612-30.611 0-16.905 13.707-30.612 30.612-30.612 16.905 0 30.611 13.707 30.611 30.612zm86.923 31.068c-1.942-41.006-11.308-77.328-41.348-107.254-29.926-29.927-66.249-39.293-107.255-41.349C319.336-.6 192.664-.6 150.402 1.799 109.51 3.741 73.188 13.107 43.148 43.033 13.107 72.959 3.855 109.282 1.799 150.288c-2.398 42.262-2.398 168.934 0 211.196 1.942 41.006 11.308 77.328 41.349 107.255 30.04 29.926 66.248 39.292 107.254 41.348 42.262 2.399 168.934 2.399 211.196 0 41.006-1.942 77.329-11.308 107.255-41.348 29.926-29.927 39.292-66.249 41.348-107.255 2.399-42.262 2.399-168.82 0-211.082zM455.603 406.83c-8.909 22.388-26.157 39.635-48.659 48.659-33.695 13.364-113.65 10.28-150.887 10.28-37.236 0-117.306 2.97-150.887-10.28-22.387-8.909-39.635-26.157-48.659-48.659-13.363-33.695-10.279-113.651-10.279-150.887 0-37.236-2.97-117.306 10.279-150.887 8.91-22.388 26.157-39.635 48.659-48.659 33.696-13.364 113.651-10.28 150.887-10.28 37.237 0 117.306-2.969 150.887 10.28 22.388 8.91 39.635 26.157 48.659 48.659 13.364 33.695 10.28 113.651 10.28 150.887 0 37.236 3.084 117.306-10.28 150.887z"
        fill={props.color || "#000000"}
      />
    </svg>
  );
}

export default InstagramIcon;