import styled, { StyledFunction } from "styled-components"

// const outerWrapper: React.FunctionComponent<{ ratio: number }> = styled.div

// const outerWrapper: StyledFunction<React.ComponentType<{ ratio: number}>> = styled.div

export const OuterWrapper = styled.div<{ ratio: number, display: any }>`
  position: relative;
  width: 100%;
  height: 0;
  border-radius: inherit;
  display: ${props => (props.display || "block")};
  /**
   * For human readability, the ratio is expressed as
   * width / height, so we need to invert it.
   */
  padding-bottom: ${props => (1 / props.ratio) * 100}%;
`

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
`