
const initialState = {
	isLoading: true,
	isError: false,
	error: null,
	user: null,
	actAsUser: null,
	globalAuth: false,
};

const userStateReducer = (state = initialState, action) => {
	switch(action.type){
		case 'SET_USER_STATE':
			if (!action.payload) {
				return initialState;
			}
			return action.payload;
		case 'UPDATE_USER_STATE':
			if (!action.payload) {
				return state;
			}
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export default userStateReducer;