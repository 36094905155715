import { useEffect, useState, useCallback } from 'react';

import { Portal, Strong, Text, Pane } from 'evergreen-ui';

import { I18n } from 'aws-amplify';

import useNetwork from '../services/useNetwork';

const PoorConnection = () => {

  const isOnline = useNetwork();

  // useEffect(() => {
  //   if (!isOnline) {
  //     console.warn("offline");
  //   }
  // }, [isOnline]);
  
  return (
    isOnline ? null :

    <Portal>
      <Pane position="fixed" width={"auto"} bottom={0}>
        <Pane
          elevation={3}
          float="center"
          margin={24}
          padding={18}
          display="flex"
          justifyContent="center"
          alignItems="center"
          background="#EC4C47" borderRadius={10}
        >
          <Strong fontSize={16} color="white">{I18n.get('No internet connection')}</Strong>
        </Pane>
      </Pane>
    </Portal>

  );
}

export default PoorConnection;