import { Fragment, cloneElement, useEffect, useState, useRef, useCallback } from 'react';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tooltip, InfoSignIcon, EditIcon, Radio, Checkbox, ChevronLeftIcon, PlusIcon, MinusIcon, CameraIcon, KeyDeleteIcon, SidebarTab, CrossIcon, TickIcon, IconButton, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


const PageDrawer = ({ children, isShown, onCloseComplete, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PageDrawer - ${countRef.current}`);
    return () => {
      console.log("PageDrawer - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const drawerRef = useRef();
  const oldRef = useRef(null);

  const handleCloseDrawer = () => {
    console.log("handleCloseDrawer");
    drawerRef.current.destroy({animate: true});
  }

  useEffect(() => {
    if (!drawerRef.current) {
      drawerRef.current = new CupertinoPane('.cupertino-pane', {
        initialBreak: 'top', // 'top' | 'middle' | 'bottom'
        parentElement: '.cupertino-parent', // 'body', // Parent container
        buttonClose: false,
        touchMoveStopPropagation: false, // on iOS, true results in scrolling...
        simulateTouch: true,
        fitHeight: true,
        lowerThanBottom: false,
        backdrop: true,
        backdropOpacity: 0.3,
        bottomClose: true,
        animationType: "ease-in", // https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function
        animationDuration: 400,
        // showDraggable: false,
        // draggableOver: false,
        breaks: {
          top: { // Topper point that pane can reach
            enabled: true, // Enable or disable breakpoint
            height: window.screen.height - (135 * 0.40), // 700, // Pane breakpoint height // Default top height: window.screen.height - (135 * 0.35)
            bounce: true // Bounce pane on transition
          },
          middle: { enabled: false, height: 300, bounce: true },
          // bottom: { enabled: true, height: 80, bounce: true },
        },
        onDidPresent: () => {
          console.log('onDidPresent event');
          let reserveScrollBarGap = true;
          let blockScroll = true;
          if (drawerRef.current && blockScroll) {
            console.log('onDidPresent disableBodyScroll');
            console.log(drawerRef.current);
            oldRef.current = drawerRef.current.el;
            disableBodyScroll(drawerRef.current.el, { reserveScrollBarGap });
            // document.body.style["overflow"] = "hidden";
          }
          
        },
        onDidDismiss: () => {
          console.log('onDidDismiss event');
          handleCloseDrawer();
          onCloseComplete();
        },
        onDrag: (e) => {
          // console.log('onDrag event', e);
        },
        onDragStart: () => {
          console.log('onDragStart event');
        },
        onDragEnd: () => {
          console.log('onDragEnd event');
        },
        onBackdropTap: () => {
          console.log('onBackdropTap event')
        },
      });
      
    }

    return () => {
      isCurrent.current = false;
    }

  }, [onCloseComplete]);

  useEffect(() => {
    if (drawerRef.current && !drawerRef.current.isPanePresented() && isShown) {
      drawerRef.current.present({ animate: true });
    }
  }, [isShown]);
  
  
  useEffect(() => {
    return () => {
      if (oldRef.current) {
        enableBodyScroll(oldRef.current);
        oldRef.current = null;
        // document.body.style["overflow"] = "auto";
      }
    };
  }, [isShown]);

      
  return (
    <div class="cupertino-parent" >
      <div class="cupertino-pane" >
        {drawerRef.current ? children({ close: handleCloseDrawer }) : null}
      </div>
    </div>
  );
  
}

export default PageDrawer;