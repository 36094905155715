import {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {useHistory, generatePath, useLocation, useRouteMatch} from 'react-router-dom';
import { Pane } from 'evergreen-ui';

import { useSelector, useDispatch, batch } from 'react-redux';

import FacePH from '../images/FacePH';
import AspectRatio from '../components/AspectRatio';

const Loading = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Loading - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("Loading - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

  // const userState = useSelector(state => state.userState);

  return (
    <Pane>
      <Pane clearfix minWidth={320} maxWidth={960} height="100vh" display="flex" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" >
        <Pane flex={1} maxWidth={90} marginLeft="auto" marginRight="auto" >
          <AspectRatio ratio={1/1} >
            <FacePH color="#7B8B9A"  width="100%" height="100%" />
          </AspectRatio>
        </Pane>
      </Pane>
    </Pane>
  );
}

export default Loading;