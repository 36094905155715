
import { Fragment, useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Pane, Strong, Paragraph, Text, Button, SwapVerticalIcon, FullCircleIcon, RepeatIcon, WarningSignIcon, CrossIcon, TickIcon, IconButton, Spinner } from 'evergreen-ui';
import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import AspectRatio from './AspectRatio';
import { ReactComponent as FDCameraFacePH } from '../images/fd_img_camera_face_ph.svg';// https://react-svgr.com/

import QrScanner from 'qr-scanner';

import { I18n } from 'aws-amplify';

const CameraComponent = forwardRef(({
  isOn = false, // start streaming as soon as the component outline is shown
  ratio = 1/1,  // 1/1 , 4/5, 9/16 // https://later.com/blog/instagram-image-size/
  pictureQuality = 0.95,
  placeHolder,
  overlay,
  placeholderIsShown = false,
  placeholderQrCode = false,
  // scanBarCodes={false} // not yet implemented 
  scanQrCodes = false,
  scanQrBox = false,
  scanQrBoxColor = "#FF9C8F",
  showButtons = true,
  showTakenPicture = true,
  showSystemParams = false,
  showRetakeButton = true,
  isUserFacing = false,
  onChange = null,
  maxPictureWidth = false,
  maxPictureHeight = false,
  onOpen,
  isProcessing = false,
  onClose = null,
  dict = {},
  // ...rest
}, ref) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`CameraComponent - ${countRef.current}`);
    return () => {
      console.log("CameraComponent - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/append
  const videoElementRef = useRef(null); 
  const canvasElementRef = useRef(null);
  const contextElementRef = useRef(null);

  const qrScannerRef = useRef(null);

  const cameraRatio = useMemo(() => {
    return typeof ratio === "number" ? ratio : 1/1;
  }, [ratio]);

  const [cameraIsUserFacing, setCameraIsUserFacing] = useState(isUserFacing === true ? true : false);
  useMemo(() => {
    setCameraIsUserFacing(isUserFacing);
  }, [isUserFacing]);
  
  const [cameras, setCameras] = useState([]);

  const [isCameraStreaming, setIsCameraStreaming] = useState(false);

  const [isCameraShown, setIsCameraShown] = useState(isOn === true ? true : false);

  const [triggerReinitiate, setTriggerReinitiate] = useState(false);
  const [triggerResetElements, setTriggerResetElements] = useState(false);

  const [isCameraReady, setIsCameraReady] = useState(false);

  const [cameraError, setCameraError] = useState();
  const [cameraErrorMessage, setCameraErrorMessage] = useState();
  const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false);

  const [currentQrCodeData, setCurrentQrCodeData] = useState();

  const [currentPictureB64, setCurrentPictureB64] = useState();

  const [currentScanQrCodes, setCurrentScanQrCodes] = useState(scanQrCodes);

  const initiateStreaming = useCallback(async(facingMode, scanQrCodes) => {
    // https://webrtc.org/getting-started/media-devices#using-asyncawait

    console.log("initiateStreaming...");
    console.log("facingMode", facingMode ? "user" : "environment");

    try {
        
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode ? "user" : "environment",
          // width: {
          //   // min: 480,
          //   ideal: 1920,
          //   // max: 1920
          // },
          height: {
            // min: 480,
            ideal: 1920,
            // max: 1920
          }
        },
        audio: false,
        // frameRate: { ideal: 60 },
      });

      // console.log("mediaStream", mediaStream);
      
      try {

        console.log("getCameras...");
        // should happen after getting permissions

        let devices = [];
        let cameraDevices = [];

        devices = await navigator.mediaDevices.enumerateDevices(); // this should be called after getting permissions

        devices.forEach(function(device) {
          // console.log("device", device);
          if (device.kind === "videoinput"){
            cameraDevices.push(device);
          }
        });
        
        if (!cameraDevices || cameraDevices?.length === 0) {
          console.error("No camera is found");
          setCameraErrorMessage("No camera is found.");
          setCameraError(true);
          return null;
        }
        else if (cameraDevices?.length < 2 && facingMode !== true) {
          console.warn("facingMode must be 'user'");
          facingMode = true;
          setCameraIsUserFacing(true);
        }

        setCameras(cameraDevices || []);
      }
      catch (error) {
        console.error("with mediaDevices", error);
        setCameraErrorMessage("Error with devices.");
        setCameraError(error);
        setCameras([]);
        return null;
      }
      
      if ('srcObject' in videoElementRef.current) {
        videoElementRef.current.srcObject = mediaStream;
      }
      else {
        // Avoid using this in new browsers, as it is going away.
        videoElementRef.current.src = URL.createObjectURL(mediaStream);
      }

      // mirroring
      videoElementRef.current.onloadedmetadata = (event) => {
      
        if (facingMode) {
          videoElementRef.current.style["-webkit-transform"] = "scaleX(-1)";
          videoElementRef.current.style["-moz-transform"] = "scaleX(-1)";
          videoElementRef.current.style["-o-transform"] = "scaleX(-1)";
          videoElementRef.current.style["-ms-transform"] = "scaleX(-1)";
          videoElementRef.current.style["transform"] = "scaleX(-1)";
    
          canvasElementRef.current.style["-webkit-transform"] = "scaleX(-1)";
          canvasElementRef.current.style["-moz-transform"] = "scaleX(-1)";
          canvasElementRef.current.style["-o-transform"] = "scaleX(-1)";
          canvasElementRef.current.style["-ms-transform"] = "scaleX(-1)";
          canvasElementRef.current.style["transform"] = "scaleX(-1)";
        }
        else {
          videoElementRef.current.style["-webkit-transform"] = "scaleX(1)";
          videoElementRef.current.style["-moz-transform"] = "scaleX(1)";
          videoElementRef.current.style["-o-transform"] = "scaleX(1)";
          videoElementRef.current.style["-ms-transform"] = "scaleX(1)";
          videoElementRef.current.style["transform"] = "scaleX(1)";
    
          canvasElementRef.current.style["-webkit-transform"] = "scaleX(1)";
          canvasElementRef.current.style["-moz-transform"] = "scaleX(1)";
          canvasElementRef.current.style["-o-transform"] = "scaleX(1)";
          canvasElementRef.current.style["-ms-transform"] = "scaleX(1)";
          canvasElementRef.current.style["transform"] = "scaleX(1)";
        }

      };

      if (scanQrCodes) {
    
        console.log("trigger QrScanner");
  
        // https://www.npmjs.com/package/qr-scanner
        // https://github.com/nimiq/qr-scanner/blob/master/demo/index.html
        qrScannerRef.current = new QrScanner(
          videoElementRef.current,
          (result) => {
            // console.log('qrScanner:', result);
            if (!isProcessing) {
              setCurrentQrCodeData(result.data);
            }
          },
          /* your options or returnDetailedScanResult: true if you're not specifying any other options */
          {
            // returnDetailedScanResult: true,
            onDecodeError: (error) => {
              // console.log('onDecodeError:', error); //
            },
            // preferredCamera: facingMode ? "user" : "environment", // why needed here ???
            maxScansPerSecond: 10, // The default is 25
            highlightScanRegion: true,
            highlightCodeOutline: true,
          }
        );
  
        qrScannerRef.current?.setInversionMode('both');

        // QrScanner.listCameras(true).then(cameras => {
        //   console.log("QrScanner cameras", cameras);
        // });
        
        // qrScannerRef.current?.start().then(() => {
        //   console.log("QrScanner has started...");
        //   // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        //   // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        //   // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        //   // start the scanner earlier.
        //   // QrScanner.listCameras(true).then(cameras => {
        //   //   console.log("cameras", cameras)
        //   // });
        // });

        // qrScannerRef.current?.stop();
        
      }

      console.log("Streaming has initiated");
      setIsCameraStreaming(true);

    }
    catch (error) {
      throw error; // this is handled later
    }

  }, [isProcessing]);
  
  const startStreaming = useCallback(async(facingMode, scanQrCodes) => {
    // https://webrtc.org/getting-started/media-devices#using-asyncawait

    console.log("startStreaming...");

    try {

      contextElementRef.current = canvasElementRef.current.getContext("2d");

      await initiateStreaming(facingMode, scanQrCodes);
      
      videoElementRef.current.onloadeddata = async(event) => {
        // console.log("onloadeddata", event);
        try {

          if (!scanQrCodes) {
            await videoElementRef.current.play(); // Automatic playback started! Show playing UI.
            // https://stackoverflow.com/questions/48304646/ie-11-html5-video-flashes-a-black-screen-between-displaying-the-poster-image-and
            setTimeout(() => {
              // videoElementRef.current.style.visibility = "inherit"; // 'visible'; // reset back on useEffect
              if (videoElementRef.current?.style) {
                videoElementRef.current.style.height = '100%';
              }
              setIsCameraReady(true);
            }, 100);
          }
          else {
            setTimeout(() => {
              // videoElementRef.current.style.visibility = "inherit"; // 'visible'; // reset back on useEffect
              if (videoElementRef.current?.style) {
                videoElementRef.current.style.height = '100%';
              }
              qrScannerRef.current?.start().then(() => {
                // console.log("QrScanner has started...", {
                //   "isCameraReady": isCameraReady
                // });
                setIsCameraReady(true);
              });
              
            }, 100);
          }

          setCameraError();
        }
        catch (error) {
          // Auto-play was prevented
          // Show paused UI.
          console.error("with play()", error);
          setCameraErrorMessage("Error with starting camera");
          setCameraError(error);
        }
      }
      
    }
    catch (error) {
      console.error("within initiateStreaming", error);
      if (error.message === "Permission denied") {
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#user_privacy
        console.error("Camera Access: Permission denied");
        // let permissions = await navigator.permissions.query({name:'camera'}); // ???
        setCameraPermissionDenied(true);
      }
      
      setCameraErrorMessage("Error with initiation");
      setCameraError(error);
    }
    
  }, [initiateStreaming]);

  const stopStreaming = useCallback((videoElement, restart = false) => {
    console.log("stopStreaming...");

    if (qrScannerRef.current) {
      console.log("stop and destroy qrScannerRef");
      qrScannerRef.current?.stop();
      qrScannerRef.current?.destroy();
      qrScannerRef.current = null;
    }
    else {

      // https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/stop
      // console.log("videoElement", videoElement);
      if (videoElement?.srcObject) {
        // console.log("stop tracks");
        const stream = videoElement?.srcObject;
        const tracks = stream?.getTracks();
        tracks?.forEach(function(track) {
          track?.stop();
        });
        if (videoElement?.srcObject) {
          videoElement.srcObject = null;
        }
      }

    }
    
    setIsCameraStreaming(false);
    // no need for more state adjustments...
    
    if (videoElementRef.current?.style) {
      videoElementRef.current.style.height = '0px';
    }

    if (restart) {
      setTriggerReinitiate(true);
    }
    
  }, []);


  const handleOnChange = useCallback(({ pictureB64 = null, qrCodeData = null}) => {

    console.log("handleOnChange in CameraComponent", {pictureB64, qrCodeData});

    if (pictureB64 && showTakenPicture === true) {
      console.log("showTakenPicture", showTakenPicture);
      setCurrentPictureB64(pictureB64);
      stopStreaming(videoElementRef.current);
    }

    if (typeof onChange === "function") {
      onChange({pictureB64, qrCodeData});
    }
    
  }, [onChange, showTakenPicture, stopStreaming]);

  // keep for smoothness
  useEffect(() => {
    if (!isCameraStreaming && isCameraReady) {
      setIsCameraShown(false);
    }
  }, [isCameraReady, isCameraStreaming]);
  
  // startStreaming on init
  useEffect(() => {
    if (isCameraShown && isCurrent.current) { // keep isOn/isCameraShown and isCurrent.current to happen only on the initial render // && cameras?.length > 0 
      startStreaming(cameraIsUserFacing, scanQrCodes);
    }
  }, [cameraIsUserFacing, isCameraShown, scanQrCodes, startStreaming]);

  // triggerReinitiate
  useEffect(() => {
    if (!triggerResetElements && triggerReinitiate) {
      console.log("triggerReinitiate...");
      setTriggerReinitiate(false);
      startStreaming(cameraIsUserFacing, scanQrCodes);
    }
  }, [cameraIsUserFacing, isCameraStreaming, scanQrCodes, startStreaming, stopStreaming, triggerReinitiate, triggerResetElements]);

  // trigger
  useEffect(() => {
    if (currentQrCodeData) {
        // console.log("process QR code", currentQrCodeData);
        handleOnChange({
          qrCodeData: currentQrCodeData,
        });
      setCurrentQrCodeData();
    }
  }, [currentQrCodeData, handleOnChange]);

  // stopStreaming
  useEffect(() => {
    const currentVideoElement = videoElementRef.current; // keep this
    return () => {
      console.log("stopStreaming on cleanup");
      stopStreaming(currentVideoElement);
    }
  }, [stopStreaming]);
  

  const handleOpenCamera = useCallback(() => {
    console.log("handleOpenCamera...");
    setTriggerReinitiate(true);
    setIsCameraShown(true); // keep here ???
    setCurrentPictureB64();

    if (typeof onOpen === "function") {
      onOpen();
    }

  }, [onOpen]);

  // trigger switch of scanQrCodes
  useEffect(() => {
    if (currentScanQrCodes !== scanQrCodes) {
      setCurrentScanQrCodes(scanQrCodes)
      setIsCameraReady(false);
      setTriggerResetElements(true); // to clear QR code scanner animation
      stopStreaming(videoElementRef.current, true);
    }
  }, [currentScanQrCodes, scanQrCodes, stopStreaming]);

  const handleSwitchCameraMode = useCallback(() => {
    console.log("handleSwitchCameraMode...");
    setIsCameraReady(false);
    setCameraIsUserFacing((prev) => {
      return !prev;
    });
    setTriggerResetElements(true); // to clear QR code scanner animation
    stopStreaming(videoElementRef.current, true);
  }, [stopStreaming]);

  // keep to complete cleaning
  useEffect(() => {
    if (triggerResetElements) {
      setTriggerResetElements(false);
    }
  }, [triggerResetElements]);

  const handleOnUsePicture = useCallback(async() => {
    console.log("handleOnUsePicture");
    handleOnChange({
      pictureB64: currentPictureB64,
    });
  }, [currentPictureB64, handleOnChange]);

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    else {
      stopStreaming(videoElementRef.current, false);
    }
  };

  const handleTakePicture = useCallback(() => {

    if (isCameraStreaming) {
      
      let viewerRatio = cameraRatio; // keep this as it works on iOS  // videoElementRef.current.offsetWidth / videoElementRef.current.offsetHeight;

      // if view port is horizontal or vertical
      let sourceWidth = videoElementRef.current.videoWidth;
      let sourceHeight = videoElementRef.current.videoHeight;
      let sourceRatio = sourceWidth / sourceHeight;
      let dWidth = sourceWidth;
      let dHeight = sourceHeight;
      let sx = 0;
      let sy = 0;
      let dx = 0;
      let dy = 0;

      const maxWidth = typeof maxPictureWidth === 'number' ? maxPictureWidth : dWidth;
      const maxHeight = typeof maxPictureHeight === 'number' ? maxPictureHeight : dHeight;

      console.log("viewerRatio", viewerRatio);
      console.log("sourceRatio", sourceRatio);

      console.log("source size", {
        sourceHeight: sourceHeight,
        sourceWidth: sourceWidth,
      });

      console.log("max picture size", {
        maxHeight: maxHeight,
        maxWidth: maxWidth,
      });

      if (sourceRatio > viewerRatio) {
        // horizontal source (e.g. computer)
        // use sourceHeight as driving dimension
        sourceWidth = sourceHeight * viewerRatio;
        if (sourceWidth > videoElementRef.current.videoWidth) {
          sourceHeight = sourceHeight * (videoElementRef.current.videoWidth / sourceWidth);
          sourceWidth = videoElementRef.current.videoWidth;
        }
        dHeight = sourceHeight > maxHeight ? maxHeight : sourceHeight;
        dWidth = sourceHeight > maxHeight ? sourceWidth * (dHeight / sourceHeight) : sourceWidth;
      }
      else {
        // vertical source (e.g. mobile)
        // use sourceWidth as driving dimension
        sourceHeight = sourceWidth * viewerRatio;
        if (sourceHeight > videoElementRef.current.videoHeight) {
          sourceHeight = sourceHeight * (videoElementRef.current.videoHeight / sourceHeight);
          sourceHeight = videoElementRef.current.videoHeight;
        }
        dWidth = sourceWidth > maxWidth ? maxWidth : sourceWidth;
        dHeight = sourceWidth > maxWidth ? sourceHeight * (dWidth / sourceHeight) : sourceHeight;
      }
      
      sx = (videoElementRef.current.videoWidth - sourceWidth) / 2;
      sy = (videoElementRef.current.videoHeight - sourceHeight) / 2;
      
      // console.log ("settings: " + sx + " " + sy + " " + sourceWidth + " " + sourceHeight + " " + dx + " " + dy + " " + dWidth + " " + dHeight);

      let increaseFactor = 1;

      canvasElementRef.current.width = dWidth * increaseFactor;
      canvasElementRef.current.height = dHeight * increaseFactor;   
      // let context = contextElementRef.current.getContext("2d");

      console.log("source picture size", {
        sourceHeight: sourceHeight,
        sourceWidth: sourceWidth,
      });

      console.log("source picture size", {
        xHeight: maxHeight / sourceHeight,
        xWidth: maxWidth / sourceWidth,
      });

      // if (sourceHeight > sourceWidth) {
      //   increaseFactor = maxHeight / sourceHeight;
      // }
      // else {
      //   increaseFactor = maxWidth / sourceWidth;
      // }

      console.log("destination picture size", {
        dHeight: dHeight * increaseFactor,
        dWidth: dWidth * increaseFactor,
      });
      
      if (cameraIsUserFacing){
        contextElementRef?.current?.translate(canvasElementRef.current.width, 0);
        contextElementRef?.current?.scale(-1, 1);
      };
      // contextElementRef.current.imageSmoothingEnabled = true;
      contextElementRef?.current?.drawImage(videoElementRef.current, sx, sy, sourceWidth, sourceHeight, dx, dy, dWidth * increaseFactor, dHeight * increaseFactor);
  
      let base64StringFull = canvasElementRef?.current?.toDataURL("image/jpeg", pictureQuality); // why jpeg here ???
      // console.log(base64StringFull);
      
      handleOnChange({
        pictureB64: base64StringFull,
      });
      
    }
    
  }, [cameraIsUserFacing, cameraRatio, handleOnChange, isCameraStreaming, maxPictureHeight, maxPictureWidth, pictureQuality]);

  
  useImperativeHandle(ref, () => ({
    // cameras: cameras,
    // isReady: isCameraStreaming,
    // error: cameraError,
    isUserFacing: cameraIsUserFacing || false,
    openCamera() {
      handleOpenCamera();
    },
    takePicture() {
      handleTakePicture();
    },
    stopCamera() {
      console.log("stopStreaming on stopCamera");
      stopStreaming(videoElementRef.current);
    }
  }), [cameraIsUserFacing, handleOpenCamera, handleTakePicture, stopStreaming]);


  const showRoundMidBorder = useMemo(() => {
    if (
      isProcessing
      || (showRetakeButton && currentPictureB64)
      || (showButtons && isCameraStreaming && !currentPictureB64)
    ) {
      return false;
    }
    return true;
  }, [currentPictureB64, isCameraStreaming, isProcessing, showButtons, showRetakeButton]);


  return (
    <Fragment >
    
      <AspectRatio ratio={cameraRatio}>
      
        {(showTakenPicture === true && currentPictureB64) ?
          <Fragment>
            <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto" background="inherit"
              border borderWidth={0} borderTopLeftRadius="inherit" borderTopRightRadius="inherit"
              borderBottomLeftRadius={showRoundMidBorder ? "inherit" : null}
              borderBottomRightRadius={showRoundMidBorder ? "inherit" : null}
            >
              <img src={currentPictureB64} alt=""
                style={{ "width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit", }}
              />
            </Pane>
            
            {/* <Pane position="absolute" top={0} width="100%" height="100%"
              border borderWidth={0} borderTopLeftRadius="inherit" borderTopRightRadius="inherit"
              borderBottomLeftRadius={showRoundMidBorder ? "inherit" : null}
              borderBottomRightRadius={showRoundMidBorder ? "inherit" : null}
              style={{
                // "background": "rgba(255, 255, 255, 0.41)",
                // "borderRadius": "inherit",
                // "boxShadow": "0 4px 30px rgba(0, 0, 0, 0.1)",
                "backdropFilter": "blur(2px)",
                "WebkitBackdropFilter": "blur(2px)",
              }}
            ></Pane> */}

          </Fragment>
        : null }

        {(!currentPictureB64 && placeholderIsShown === true && !cameraError) ?
          <Pane position="absolute" zIndex={1} width="100%" height="100%" paddingX={"15%"} marginLeft="auto" marginRight="auto"
            border borderWidth={0} borderTopLeftRadius="inherit" borderTopRightRadius="inherit"
            borderBottomLeftRadius={showRoundMidBorder ? "inherit" : null}
            borderBottomRightRadius={showRoundMidBorder ? "inherit" : null}
          >
            {placeholderQrCode !== true ? // #FF9C8F
              <FDCameraFacePH width="100%" height="100%" opacity={!isCameraStreaming ? 1 : 0.3}
              // color="#7B8B9A" // doesn't work ???
              />
            :
              null
            }

          </Pane>
        :
          !cameraError ? null :
            <Pane textAlign="center" marginTop={"40%"} >
              <WarningSignIcon flex={1} marginX={12} icon={WarningSignIcon} appearance="minimal" color="#7B8B9A" size={24} height={40} width={40} />
              
              {!cameraPermissionDenied ?
                <Pane textAlign="center" marginTop={8} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("Permission denied")}</Text>
                </Pane>
              :
                <Pane textAlign="center" marginTop={8} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("Something went wrong")}</Text>
                </Pane>
              }
              
              <Pane paddingX={20} textAlign="center" marginTop={8} >
                <Text fontSize={14} color="#7B8B9A" >{I18n.get("Please check your browser settings and allow to use your camera.")}</Text>
              </Pane>

              {!cameraErrorMessage ? null :
                <Pane paddingX={20} textAlign="center" marginTop={8} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get(cameraErrorMessage)}</Text>
                </Pane>
              }
            </Pane>
        }

        {triggerResetElements ? null : // keep this to reset the video element on reinitiating
          <Pane
            // display={isCameraReady ? "block" : "none"} // doesn't stop camera streaming
            height="100%"
            width="100%"
            marginLeft="auto" marginRight="auto"
            background="inherit"
            border borderWidth={0} borderTopLeftRadius="inherit" borderTopRightRadius="inherit"
            borderBottomLeftRadius={showRoundMidBorder ? "inherit" : null}
            borderBottomRightRadius={showRoundMidBorder ? "inherit" : null}
            // background={"green"}
          >
            
            <video // https://googlechrome.github.io/samples/media/controlslist.html
              id="video"
              ref={videoElementRef}
              // autoPlay // no need, instead videoElementRef.current.play() is used
              playsInline
              // preload="none"
              // nodownload
              // nofullscreen
              style={{
                position: "absolute",
                // visibility: "hidden", // causes issues
                borderRadius: "inherit",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "0px",
                objectFit: "cover",
                display: "flex",
              }}
            />

            <canvas
              id="canvas_for_screenshots"
              ref={canvasElementRef}
              style={{
                position: "absolute",
                border: "0px",
                top: "0px",
                left: "0px",
                borderRadius: "inherit",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "none", // no need to display
              }}
            />

          </Pane>
        }

      </AspectRatio>


      {(isCameraShown && !isProcessing && !currentPictureB64 && showButtons) ?
        <Pane width="100%" paddingY={10} alignItems="center" display="flex" flexWrap="wrap" background="inherit" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit" >
          {!cameraError ?
            <IconButton flex="none" marginX={12} icon={CrossIcon} appearance="minimal" iconSize={18} height={40} width={40}
              disabled={!isCameraStreaming}
              onClick={handleOnClose}
            />
          : cameraError ?
            <IconButton flex="none" marginX={12} icon={CrossIcon} appearance="minimal" iconSize={18} height={40} width={40}
              disabled={false}
              onClick={() => {
                stopStreaming(videoElementRef.current);
                handleOnClose();
              }}
            />
            : <Pane flex="none" marginX={12} height={40} width={40}/>
          }

          {!cameraError ?
            <IconButton flex={1} marginX={12} icon={FullCircleIcon} appearance="minimal" iconSize={24} height={40} width={40}
              disabled={!isCameraStreaming}
              onClick={handleTakePicture}
            />
          :
            !cameraError ?
              <Pane flex={1} marginX={12} height={40} width={40}/>
            :
              <IconButton flex={1} marginX={12} icon={RepeatIcon} appearance="minimal" iconSize={24} height={40} width={40}
                // disabled={}
                onClick={() => {
                  // ???
                  setCameraErrorMessage();
                  setCameraError();
                }}
              />
          }

          {(!cameraError && cameras?.length > 1) || true ?
            <IconButton flex="none" marginX={12} icon={SwapVerticalIcon} appearance="minimal" iconSize={18} height={40} width={40}
              disabled={!isCameraStreaming}
              onClick={handleSwitchCameraMode}
            />
          : <Pane flex="none" marginX={12} height={40} width={40}/> }

        </Pane>
      :
        isCameraShown || isProcessing || currentPictureB64 ? null :
          <Pane width="100%" paddingY={10} flexWrap="wrap" background="inherit" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit"
            borderTopLeftRadius={!placeholderIsShown ? "inherit" : null}
            borderTopRightRadius={!placeholderIsShown ? "inherit" : null}
          >
            <Button fontSize={16} display="flex" marginLeft="auto" marginRight="auto" paddingX={24} height={40} width="75%" maxWidth={250} justifyContent="center" className='button-blue'
              onClick={handleOpenCamera}
            >
              {dict['Open Camera'] || "Open Camera"}
            </Button>
          </Pane>
      }

      {isProcessing ?
        <Pane width="100%" paddingY={24} alignItems="center" background="inherit" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit" >
          <Pane width="75%" height={12} marginLeft="auto" marginRight="auto" borderRadius={6} className="colourfulAnimation"></Pane>
        </Pane>
      : null }

      {(!isProcessing && showTakenPicture && showRetakeButton && currentPictureB64) ?
        
        <Pane paddingY={10}  alignItems="center" display="flex" flexWrap="wrap" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit" >

          <Pane textAlign="center" flex={1} width="100%" height="auto" >
            <Button fontSize={16} display="flex" marginLeft="auto" marginRight="auto" paddingX={24} width="auto" minWidth="75%" height={40} justifyContent="center" appearance="minimal" style={{ color: '#283655'}}
              onClick={handleOpenCamera}
            >
              {dict['Retake'] || "Retake"}
            </Button>
          </Pane>

          <Pane textAlign="center" flex={1} width="100%" height="auto" >
            <Button fontSize={16} display="flex"  marginLeft="auto" marginRight="auto" paddingX={24} width="auto" minWidth="75%" height={40} justifyContent="center" style={{ color: '#283655'}}  className='button-green'
              onClick={handleOnUsePicture}
            >
              {dict['Use photo'] || "Use photo"}
            </Button>
          </Pane>

        </Pane>
      : null }



      {showSystemParams ?

        <Pane paddingX={8} >
        
          <Paragraph fontSize={14} color="#283655" >{
            `isCameraShown: ${isCameraShown}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `isCameraReady: ${isCameraReady}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `isCameraStreaming: ${isCameraStreaming}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `video readyState: ${videoElementRef?.current?.readyState}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `isUserFacing: ${isUserFacing}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `cameraIsUserFacing: ${cameraIsUserFacing}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `viewerRatio: ${cameraRatio}`
          }</Paragraph>
          <Paragraph fontSize={14} color="#283655" >{
            `sourceRatio: ${Math.round(((videoElementRef.current?.videoWidth / videoElementRef.current?.videoHeight) + Number.EPSILON) * 100) / 100} (${videoElementRef.current?.videoWidth}:${videoElementRef.current?.videoHeight})`
          }</Paragraph>
          
          <Paragraph fontSize={14} color="#283655" >{
            `Cameras: ${cameras?.length}`
          }</Paragraph>
          {cameras?.map((e, i) => (
            <Paragraph key={i} fontSize={14} color="#283655" >{
              `${i+1}: ${e.label}`
            }</Paragraph>
          ))}

        </Pane>

      : null }



    </Fragment>
  );
  
});

export default CameraComponent;