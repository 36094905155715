import { useEffect, useState, useCallback, useRef } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { ThemeConsumer, Heading, Strong, Text, Table, Button, Pane } from 'evergreen-ui';

import { useMediaQuery } from 'react-responsive';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Footer from '../components/Footer';
import ProfileCard from '../components/ProfileCard';

import TakeFacePicturePic from '../assets/images/TakeFacePicturePic';

import { useSelector } from 'react-redux';

const Browse = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("Browse", countRef.current)
    return () => {
      console.log("Browse cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  // let location = useLocation();

  const userState = useSelector(state => state.userState);
  const [profilesListLoaded, setProfilesListLoaded] = useState(false);
  const [profilesList, setProfilesList] = useState([null, null, null, null, null, null,]);
  // const [level, setLevel] = useState();

  const handleSetListOfProfile = useCallback(async(nextToken) => {
    if (!userState.isLoading && !profilesListLoaded) {
      try {
        const response = await API.graphql({
          query: queries.listProfiles,
          variables: {
            nextToken: nextToken,
          },
          authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        });
        let listOfProfiles = response.data.listProfiles;
        console.log("listOfProfiles", listOfProfiles);
        if (!profilesListLoaded) {
          setProfilesList(listOfProfiles);
          setProfilesListLoaded(true);
        }
        
      }
      catch (error) {
        console.error("within listProfiles", error);
      }
    }
  }, [profilesListLoaded, userState.isLoading, userState.user]);

  useEffect(() => {
    handleSetListOfProfile();
  }, [handleSetListOfProfile]);



  return (
    <Pane >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        {/* <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" textAlign={is426PlusScreen ? "left" : "center"}>
            <Strong display="block" fontSize={is426PlusScreen ? 36 : 36} height="1em" lineHeight={1} color="#283655" >{"Take a picture"}</Strong>
            <Text display="block" fontSize={24} height="1em" lineHeight={1.3} color="#7B8B9A" >{"or scan QR code"}</Text>
            <Button marginTop={16} paddingX={24} height={40} className='button-blue'
              is={Link}
              to={{
                pathname: generatePath("/:locale?/:page", {...match.params, page: "camera"}),
                state: {
                  isModal: true,
                },
              }}
            >
              {I18n.get('Open camera').toUpperCase()}
            </Button>

          </Pane>
          {is426PlusScreen ?
          <Pane flex={0.8} width="100%" marginTop={16} maxHeight="250px" minWidth="200px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <TakeFacePicturePic height={250} />
          </Pane>
          : null}
        </Pane> */}

        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          {/* <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Recipients"}</Strong> */}
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Choose who you wish to help"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>
        <Pane marginTop={10} textAlign={"center"} >
          <Text fontSize={16} >{I18n.get('or contact us at')} <a style={{ color: '#1070CA'}} className='text-link' href={"mailto:hello@facedonate.org"}>{"hello@facedonate.org"}</a> {I18n.get('to start your campaign today')}</Text>
        </Pane>

        <Pane marginTop={36} display="flex" flexWrap="wrap" gap={20}>
          {profilesList.map((profileDetails, index, array) => (
            <Pane flex={1} flexBasis={"250px"} flexShrink={1} flexGrow={1} maxWidth={300} key={profileDetails ? profileDetails.publicFaceId : index} >
              <ProfileCard profileDetails={profileDetails} />
            </Pane>
          ))}
        </Pane>
      </Pane>
      <Footer/>
    </Pane>
  );
}

export default Browse;