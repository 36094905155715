import { Fragment, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { Amplify, Auth, API, I18n } from 'aws-amplify';
// import * as queries from './graphql/queries';
import * as subscriptions from './graphql/subscriptions';

import awsconfig from './aws-exports';
import langDictionary from './services/langDictionary';

import { BrowserRouter, Route, Switch, Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
// import { ThemeProvider, classicTheme } from 'evergreen-ui';
import numeral from 'numeral'; // http://numeraljs.com/
import _ from 'lodash';

// import { Cookies } from 'react-cookie';
// import Fingerprint2 from 'fingerprintjs2';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import TagManager from 'react-gtm-module'; // https://github.com/alinemorelli/react-gtm

import Home from './pages/Home';
import Legal from './pages/Legal';
import Questions from './pages/Questions';
import Directory from './pages/Directory';
import Browse from './pages/Browse';

import Map from './pages/Map';

import Goals from './pages/Goals';

import AuthPage from './pages/AuthPage';
import SignOutPage from './pages/SignOutPage';
import SystemPage from './pages/SystemPage';
import CameraPage from './pages/CameraPage';
import MapPage from './pages/MapPage';

import WidgetDemoPage from './pages/WidgetDemoPage';
import Search from './pages/Search';

import Testing from './pages/Testing';

import Sitemap from './pages/Sitemap';

import Account from './pages/Account';
import NewTransaction from './pages/NewTransaction';
import Profile from './pages/Profile';
import TransactionOld from './pages/TransactionOld';
import Transaction from './pages/Transaction';
import Loading from './pages/Loading';
import PageNotFound from './pages/404';
import Maintenance from './pages/Maintenance';


import Header from './components/Header';
import Modal from './components/Modal';
import TransactionDetails from './components/TransactionDetails';

import { useAuthInitialiseUser } from "./services/useAmplifyAuth";
import useAccessTransaction from "./services/useAccessTransaction";

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, updateLocaleState, updateTransactionDetails } from './services/actions';
import localeDictionary, { localePathOptions, langLocaleOptions, areaServedOptions, langOptions } from './services/localeDictionary';

Amplify.configure(awsconfig);
I18n.putVocabularies(langDictionary);

// load a locale
// https://github.com/adamwdraper/Numeral-js/blob/master/locales.js
numeral.register("locale", "en-gb", {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? "th"
      : b === 1
      ? "st"
      : b === 2
      ? "nd"
      : b === 3
      ? "rd"
      : "th";
  },
  currency: {
    symbol: "£",
  },
});

numeral.register("locale", "it", {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  abbreviations: {
    thousand: "mila",
    million: "mil",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return "º";
  },
  currency: {
    symbol: "€",
  },
});
// switch between locales
numeral.locale('en-gb');

console.log("process.env", process.env);
if (process.env.NODE_ENV === "production") {
  TagManager.initialize({
    gtmId: 'GTM-M7Q5RH6',
  });
}
else {
  console.warn("TagManager is not set.");
}


const PrivateRoute = ({ anyUser = false, children, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PrivateRoute - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("PrivateRoute - cleaned up");
    }
  }, []);
  
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  
  return (
    <Route
      {...rest}
      render={
        () => {
          return (
            userState.isLoading ?
              (<Loading/>)
            :
              (userState.user || anyUser ?
                (children)
              :
              // profileDetails this should be replaced with the last user's page ???
              profileDetails?.username || profileDetails?.publicFaceId ?
                (<Redirect
                  to={{
                    pathname: generatePath("/:handle", {...rest.computedMatch.params, handle: profileDetails?.username || profileDetails?.publicFaceId}),
                    state: { from: rest.computedMatch } // rest.location
                  }}
                />)
                :
                (<Redirect
                  to={{
                    pathname: generatePath("/:locale?/log-in", {...rest.computedMatch.params}),
                    state: { from: rest.computedMatch } // rest.location
                  }}
                />)
              )
          )
        }
      }
    />
  );
}

const MainRouter = () => {
  // https://github.com/ReactTraining/history/issues/644

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`MainRouter - ${countRef.current}`);
    return () => {
      console.log("MainRouter - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  // console.log("MainRouter match", match);
  // console.log("MainRouter location", location);

  // const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);
  const localeState = useSelector(state => state.localeState);

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);
  
  
  const localePath = match.path;

  // order of previousLocationRef and isModal is sensitive
  const previousLocationRef = useRef();
  const isModal = (location.state && location.state.isModal); //  && previousLocationRef.current !== location

  if (isModal) {
    console.log("App ----- isModal");
    function getMatchDetails(path, uri) {
      // start: false makes parsing optional params work
      const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
      const matchDetails = matchPath(uri);
      return matchDetails;
    }
    if (previousLocationRef.current) {
      previousLocationRef.current.newMatch = getMatchDetails(`/:locale(${localePathOptions})?/:page(camera)?`, location.pathname);
    }
  }

  useEffect(() => {
    if (!(location.state && location.state.isModal)) {
      previousLocationRef.current = location;
    }
  }, [location]);



  // a way to see previous location.search
  // console.log("previousLocationRef.current", previousLocationRef.current);

  // import UserContext from "./services/UserContext";

  // const {
  //   userState: { isLoading, isError, user },
  //   handleSignOut,
  //   handleSignIn,
  //   handleSendSecretCode,
  //   handleVerifySecretCode,
  //   handleSignUp,
  // } = useAmplifyAuth();
  

  // const userProviderValue = useMemo(() => {
  //   return ({
  //     // isAuthLoading: isLoading,
  //     // isAuthenticated: user ? true : false,
  //     // isAuthError: isError,
  //     // authUser: user,
  //     // handleSignOut: handleSignOut,
  //     // handleSignIn: handleSignIn,
  //     // handleSendSecretCode: handleSendSecretCode,
  //     // handleVerifySecretCode: handleVerifySecretCode,
  //     // handleSignUp: handleSignUp,
  //   });
  // }, []);

  // <UserContext.Provider value={userProviderValue} >
  // </UserContext.Provider>
  

  // function getCurrentFPrint() {
  //   if (window.requestIdleCallback) {
  //     requestIdleCallback(function () {
  //       Fingerprint2.get(function (components) {
  //         // console.log(components); // an array of components: {key: ..., value: ...}
  //         let values = components.map(function (component) { return component.value });
  //         // console.log(values);
  //         let murmur = Fingerprint2.x64hash128(values.join(''), 31);
  //         console.log(murmur);
  //       })
  //     })
  //   }
  //   else {
  //     setTimeout(function () {
  //       Fingerprint2.get(function (components) {
  //         // console.log(components); // an array of components: {key: ..., value: ...}
  //         let values = components.map(function (component) { return component.value });
  //         // console.log(values);
  //         let murmur = Fingerprint2.x64hash128(values.join(''), 31);
  //         console.log(murmur);
  //       })  
  //     }, 500)
  //   }
  // }
  
  // useEffect(() => {
  //   getCurrentFPrint();
  // }, []) 

  
  // <Route
  // exact
  // strict
  // sensitive
  // path='/:url([a-z/]*[A-Z]+[a-z/]*)'
  // render={(props) => {
  //   const path = props.location.pathname
  //   return <Redirect to={`${path.toLowerCase()}`} />
  // }}
  // />


  // useEffect(() => {
  //   console.log("----- MainRouter RENDER -----");
  //   console.log("match", match);
  //   console.log("previousLocationRef.current", previousLocationRef.current);
  //   console.log("location", location);
  // }, [location, match]);



  // the order matters
  return (
    <Fragment>

      <Route path={`${localePath}/:page?`} component={Header} />

      <Switch location={isModal ? previousLocationRef.current : location} >

        {["qr_code", "payment_qr_code", "auth"].includes(queryParams.get('camera')) ?
          <Route exact path={`${location.pathname}`} component={CameraPage} />
        : null}
        
        <Route exact path={`${localePath}/:page(home|overview|pricing)?/:other(contribute)?`} component={Home} />
        
        <Route exact path={`${localePath}/:page(about|demo|switch|faq|media-kit|email)?`} component={Home} />

        <Route exact path={`${localePath}/:page(socials)?`} component={Home} />
        {/* socials redirects */}

        <Route exact path={`${localePath}/:page(log-in|sign-in|sign-up|forgot-password)?`} component={Home} />

        {/* <Route exact path={`${localePath}/x/:page(log-in|sign-in|sign-up|forgot-password)?`} component={Home} /> */}
        
        <Route exact path={`${localePath}/:page(auth)`} component={AuthPage} />
        <Route exact path={`${localePath}/:page(log-out|sign-out)`} component={SignOutPage} />
        
        <Route exact path={`${localePath}/:page(donations|feedback|fundraising|vouchers|dashboard|impact-reporting|docs)?`} component={Home} />
        <Route exact path={`${localePath}/:page(personal|corporate|foodbanks|food-banks|beneficiaries|retail|retailer|retailers|charity|charities|foundations|government)?`} component={Home} />
        <Route exact path={`${localePath}/:page(complaints)?`} component={Home} />

        <Route exact path={`${localePath}/:page(legal)/:mode(terms|privacy|cookies|gift_aid)?`} component={Home} />

        <Route exact path={`${localePath}/:page(system)`} component={SystemPage} />

        <Route exact path={`${localePath}/:page(camera)?`} component={CameraPage} />

        <Route exact path={`${localePath}/:page(widget)`} component={WidgetDemoPage} />

        {/* <Route exact path={`${localePath}/:page(search)`} component={Search} /> */}

        <Route exact path={`${localePath}/:page(goals)/:goalNumber?`} component={Goals} />
        

        <Route exact path={`${localePath}/:page(map)`} component={Map} />
        <Route exact path={`${localePath}/x/:page(map)?`} component={MapPage} />

        {/* <Route exact path={`${localePath}/:page(faq)/:uri?`} component={Questions} /> */}

        {/* <Route exact path={`${localePath}/:page(legal)/:mode(terms|privacy|cookies|gift_aid)?`} component={Legal} /> */}
        <Route exact path={`${localePath}/:page(directory)/:mode(profiles|tags)?/:nextToken?`} component={Directory} />
        <Route exact path={`${localePath}/:page(browse)`} component={Browse} />

        
        <Route exact path={`${localePath}/:page(testing_page)`} component={Testing} />

        <Route exact path={`${localePath}/:page(sitemap)`} component={Sitemap} />
        
        <PrivateRoute path={`${localePath}/:page(account)/:mode(menu|verify|edit|personal|professional|password|balance|payments|notifications|privacy-and-security|admin)?`} >
          <Account />
        </PrivateRoute>

        {/* <Route path={`${localePath}/:page(new)/:mode(donation|topup|allocation|post|sale)?`} component={NewTransaction} /> */}

        <PrivateRoute anyUser={true} path={`${localePath}/:page(new)/:mode(donation|post)?`} >
          <NewTransaction />
        </PrivateRoute>

        <Route path={`${localePath}/:page(p)/:publicTransactionId`} component={TransactionOld} />

        <Route path={`${localePath}/:page(t)/:publicTransactionId`} component={Transaction} />

        <Route exact path={`${localePath}/:page(qr)/:mode(p|r)?/:publicTransactionId?/:accessCode?/:amount?/:currency?`} component={Loading} />

        <Route exact path={`${localePath}/:page(maintenance)`} component={Maintenance} />
        
        <Route exact path={`${localePath}/:page(404)`} component={PageNotFound} />

        <Route exact path={`${localePath}/:handle/:mode(fwd|history|dashboard|following|followers)?`} component={Profile} />

        <Redirect to={`${localePath}/:page(404)`} />
      </Switch>

      {/* {isModal ?
        <Switch location={location} >
          <Route path={`${localePath}/:page(t)/:publicTransactionId`} >
            <Modal >
              <TransactionDetails />
            </Modal>
          </Route>
        </Switch>
      : null} */}

    </Fragment>
  );
};

// turn this into a custom hook ???
const Services = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Services - ${countRef.current}`);
    return () => {
      console.log("Services - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);

  // subscribe to onTransactionUpdate
  const sOnTransactionUpdateRef = useRef();
  const sPublicTransactionIdRef = useRef();
  const sTransactionDetailsRef = useRef();

  const [trySubscribingOnTransactionUpdateAgain, setTrySubscribingOnTransactionUpdateAgain] = useState(false);

  const handleTrySubscribingOnTransactionUpdateAgain = async() => {
    async function wait(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    }
    console.log("wait for 1 sec and try subscribingOnTransactionUpdate again...");
    await wait(1000);
    setTrySubscribingOnTransactionUpdateAgain(true);
  }

  const handleAccessTransaction = useAccessTransaction();

  const handleInvalidateTransaction = useCallback(() => {
    console.log(`invalidate ${sTransactionDetailsRef.current?.type}`);
    const { data, error } = handleAccessTransaction({
      publicTransactionId: sTransactionDetailsRef.current?.publicTransactionId,
      accessCode: sTransactionDetailsRef.current?.accessCode,
      invalidate: true,
    });
  }, [handleAccessTransaction]);

  useEffect(() => {
    if (transactionDetails.publicTransactionId && (!sOnTransactionUpdateRef.current
      || sPublicTransactionIdRef.current !== transactionDetails.publicTransactionId
      || trySubscribingOnTransactionUpdateAgain
    )) {
      if (sPublicTransactionIdRef.current && sPublicTransactionIdRef.current !== transactionDetails.publicTransactionId ) {
        console.log("unsubscribe from onTransactionUpdate (new publicTransactionId):", sPublicTransactionIdRef.current);
        if (transactionDetails.type === "paymentIntent") {
          handleInvalidateTransaction();
        }
        sOnTransactionUpdateRef.current.unsubscribe();
      }

      if (trySubscribingOnTransactionUpdateAgain) {
        setTrySubscribingOnTransactionUpdateAgain(false);
      }

      console.log("subscribe to onTransactionUpdate:", transactionDetails.publicTransactionId);
      
      sOnTransactionUpdateRef.current = API.graphql({
        query: subscriptions.onTransactionUpdate,
        variables: {
          publicTransactionId: transactionDetails.publicTransactionId,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      }).subscribe({
          next: (eventData) => {
            // console.log("onTransactionUpdate eventData", eventData);
            let updatedTransaction = eventData.value.data.onTransactionUpdate;

            const definedProps = obj => Object.fromEntries(
              Object.entries(obj).filter(([k, v]) => v)
            );

            updatedTransaction = definedProps(updatedTransaction);
            
            console.log("sOnTransactionUpdate:", updatedTransaction);

            // merge history arrays by type, assumes unique types
            const mergedTransactionHistory = _.unionBy(updatedTransaction.history, transactionDetails.history, 'type'); // new (left) is kept, old (right) is replaced

            batch(() => {
              dispatch(updateTransactionDetails({
                ...updatedTransaction,
                history: mergedTransactionHistory,
              }));
            });

          },
          error: (error) => {
            console.error("within sOnTransactionUpdate:", error);
            handleTrySubscribingOnTransactionUpdateAgain();
          },
      });

      sPublicTransactionIdRef.current = transactionDetails.publicTransactionId;
      sTransactionDetailsRef.current = transactionDetails;

    }
    else if (sOnTransactionUpdateRef.current && !transactionDetails.publicTransactionId) {
      console.log("unsubscribe from onTransactionUpdate (no publicTransactionId):", sPublicTransactionIdRef.current);
      if (sTransactionDetailsRef.current?.type === "paymentIntent") {
        handleInvalidateTransaction();
      }
      sOnTransactionUpdateRef.current.unsubscribe();
      sOnTransactionUpdateRef.current = null;
      sPublicTransactionIdRef.current = null;
      sTransactionDetailsRef.current = null;
    }

    return () => {
      // this is triggered every time this useEffect is re-rendered, i.e. when the dependencies array see changes
      // console.log("sOnTransactionUpdate useEffect - cleaned up");
    }

  }, [dispatch, handleInvalidateTransaction, transactionDetails, transactionDetails.history, transactionDetails.publicTransactionId, transactionDetails.type, trySubscribingOnTransactionUpdateAgain, userState.user]);

  return null;
  
}

const App = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`App - ${countRef.current}`);
    return () => {
      console.log("App - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);
  // const localeState = useSelector(state => state.localeState);
  
  useAuthInitialiseUser();
  // const handleInitialiseUser = useAuthInitialiseUser();
  // handleInitialiseUser();
  
  // if (process.env.NODE_ENV === "production") {
  //   console.log = function () {};
  // }

  // <ThemeProvider value={classicTheme}>
  // </ThemeProvider>

  return (
    <Fragment>

      <Services/>

      <BrowserRouter>
        <Route path={`/:locale(${localePathOptions})?`} component={MainRouter} />
      </BrowserRouter>

    </Fragment>
  );
}

export default App;