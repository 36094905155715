import React from 'react';
// covert SVG here - https://react-svgr.com/

const SettingsIcon = (props) => {
  // console.log(`HeartIcon started...`);

  return (
    <svg
      viewBox="0 0 56 56"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M18.37 55.556a6.25 6.25 0 01-3.75-1.278L8.87 50c-2.74-2.136-3.341-6.091-1.361-8.944a5.728 5.728 0 00.638-5.167l-.166-.445a5.086 5.086 0 00-3.111-3.388h-.445c-3.355-1.129-5.203-4.8-4.111-8.167l2.278-7.222a6.06 6.06 0 013.111-3.917 5.944 5.944 0 014.667-.333 5.363 5.363 0 004.944-.806l.361-.278a5.39 5.39 0 002.028-4.194v-.667C17.688 2.958 20.55.045 24.064 0h7.083a6.28 6.28 0 014.445 1.861 6.592 6.592 0 011.889 4.667v.778a4.893 4.893 0 001.916 3.972l.306.222a4.837 4.837 0 004.417.722l.944-.305a6.254 6.254 0 011.975-.319 6.292 6.292 0 016.025 4.513l2.195 7c1.048 3.342-.757 6.964-4.056 8.139l-.556.194A5.255 5.255 0 0047.231 35a5.557 5.557 0 00.694 4.583l.722 1.056c1.978 2.869 1.364 6.835-1.388 8.972l-5.584 4.306a6.226 6.226 0 01-8.944-1.473l-.334-.472a4.864 4.864 0 00-4.166-2.166 5.003 5.003 0 00-3.972 2.138l-.639.917a6.256 6.256 0 01-4.167 2.695c-.36.035-.723.035-1.083 0zM6.675 26.722a10.644 10.644 0 016.611 6.945V34a11.121 11.121 0 01-1.277 10.056 1.057 1.057 0 000 1.416L17.981 50a.693.693 0 001.028-.194l.638-.917a10.483 10.483 0 018.612-4.513c3.433 0 6.656 1.689 8.611 4.513l.333.5a.834.834 0 00.5.333.692.692 0 00.528-.139l5.722-4.333c.414-.328.5-.93.194-1.361l-.722-1.056a11.116 11.116 0 01-1.472-9.5 10.899 10.899 0 016.917-7.25l.555-.194a.948.948 0 00.528-1.222l-2.167-6.917a.977.977 0 00-.555-.528.585.585 0 00-.528 0l-.944.306a10.398 10.398 0 01-9.528-1.584l-.111-.25a10.45 10.45 0 01-4.139-8.333V6.5a1.027 1.027 0 00-.278-.722.856.856 0 00-.583-.222h-7.056a.864.864 0 00-.805.916v.695c0 3.358-1.562 6.533-4.223 8.583l-.361.278a10.87 10.87 0 01-10.083 1.639.603.603 0 00-.389 0 .775.775 0 00-.333.416l-2.306 7.25c-.166.51.107 1.068.611 1.25l.5.139z"
        fillRule="nonzero"
        fill={props.color || "#000000"}
      />
      <path
        d="M27.786 37.5c-5.333 0-9.722-4.389-9.722-9.722 0-5.334 4.389-9.722 9.722-9.722 5.334 0 9.723 4.388 9.723 9.722 0 5.333-4.389 9.722-9.723 9.722zm0-13.889c-2.285 0-4.166 1.881-4.166 4.167 0 2.286 1.881 4.166 4.166 4.166 2.286 0 4.167-1.88 4.167-4.166 0-2.286-1.881-4.167-4.167-4.167z"
        fillRule="nonzero"
        fill={props.color || "#000000"}
      />
    </svg>

  );
}

export default SettingsIcon;