
import profileStateReducer from './profileState';

import profileDetailsReducer from './profileDetails';

import transactionDetailsReducer from './transactionDetails';

import localeStateReducer from './localeState';

import userStateReducer from './userState';

import basicStatsReducer from './basicStats';

import dialogStateReducer from './dialogState';

import uploadStateReducer from './uploadState';


import { combineReducers } from 'redux';

const allReducers = combineReducers({
	profileState: profileStateReducer,
	profileDetails: profileDetailsReducer,
	transactionDetails: transactionDetailsReducer,
	localeState: localeStateReducer,
	userState: userStateReducer,
	basicStats: basicStatsReducer,
	dialogState: dialogStateReducer,
	uploadState: uploadStateReducer,
});

export default allReducers;