const HomeIcon = ({size="inherit", style={}, color, width="1em", height="1em", opacity=1, ...rest}) => {

  return (

    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fontSize={size}
      width={width}
      height={height}
      opacity={opacity}
      style={style}
      {...rest}
    >
      <path d="M8.556.36a1.605 1.605 0 012.068 0l7.994 6.783a1.6 1.6 0 01.565 1.219v10.022c0 .877-.722 1.599-1.599 1.599h-6.129a.804.804 0 01-.799-.8v-6.661H8.524v6.661c0 .439-.361.8-.799.8H1.596a1.606 1.606 0 01-1.598-1.599V8.362a1.6 1.6 0 01.564-1.22L8.556.362zM9.59 1.58L1.596 8.362v10.022h5.33v-6.661c0-.439.36-.8.799-.8h3.73c.439 0 .8.361.8.8v6.661h5.33V8.362L9.59 1.58z"
        fill={color || "#000000"}
      />
    </svg>

  );
}

export default HomeIcon;