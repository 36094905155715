import {useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo, useContext} from 'react';
import { Pane } from 'evergreen-ui';

import AspectRatio from './AspectRatio';
import QRCode from 'qrcode'; // https://nimiq.github.io/qr-creator/demo/

const QrcodeComponent = ({
	textToEncode = null,
	frontColor = "#283655",
	backColor = "#0000", // Transparent background
	onChange = () => {},
	...rest
}) => {

	const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`QrcodeComponent - ${countRef.current}`);
    return () => {
      console.log("QrcodeComponent - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const [qrCodeImageSrc, setQrCodeImageSrc] = useState(null);

	const qrParams = useMemo(() => {
		return ({
			errorCorrectionLevel: 'Q',
			version: 7, // max. characters 125 at Q // max. version 40 // https://www.qrcode.com/en/about/version.html
			type: 'image/png',
			quality: 1,
			margin: 0,
			scale: 12,
			// width: 1350,
			color: {
				dark: frontColor,
				light: backColor,
			}
		});
	}, [backColor, frontColor]);

  useEffect(() => {

		const generateQR = async () => {

			try {
				// https://www.npmjs.com/package/qrcode
				// https://nimiq.github.io/qr-creator/demo/
	
				const qrCode = await QRCode.toDataURL(textToEncode, qrParams);
				setQrCodeImageSrc(qrCode);

				// const image = new Image();
				// image.onload = drawImageActualSize; // Draw when image has loaded
				// image.src = qrCode;
	
				// function drawImageActualSize() {
				// 	let canvas = document.createElement('canvas');
				// 	let ctx = canvas.getContext("2d");
				// 	// Use the intrinsic size of image in CSS pixels for the canvas element
				// 	canvas.width = this.naturalWidth + 100;
				// 	canvas.height = this.naturalHeight + 100;
					
				// 	ctx.fillStyle = "#283655";
	
				// 	ctx.fillRect(0, 0, canvas.width, canvas.height);
				// 	ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, 50, 50, this.naturalWidth, this.naturalHeight);
	
				// 	let base64StringFull = canvas.toDataURL("image/png", 1);
				// 	setQrCodeImageSrc(base64StringFull);
				// }
				
				if (typeof onChange === "function") {
					onChange({
						isLoaded: true,
						qrcodeBase64: qrCode,
						error: null,
					});
				}
				
	
			} catch (error) {
				console.error("with generateQR", error);
				if (typeof onChange === "function") {
					onChange({
						isLoaded: false,
						qrcodeBase64: null,
						error: error,
					});
				}
			}
		}

    if (textToEncode && isCurrent.current) { // keep
			// console.log("generateQR");
      generateQR();
    }
		
	}, [onChange, qrParams, textToEncode]);
	
  return (
		<AspectRatio ratio={1/1} >
			{!qrCodeImageSrc ? 
				<Pane width="100%" paddingY={"50%"} alignItems="center" background="inherit" borderBottomLeftRadius="inherit" borderBottomRightRadius="inherit" >
					<Pane width="75%" height={16} marginTop={-8} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
				</Pane>
			:
				<img src={qrCodeImageSrc} alt="QR code" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit",}} />
			}
				
	  </AspectRatio>
  );
}

export default QrcodeComponent;