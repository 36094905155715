import { useEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Table, Button, Pane } from 'evergreen-ui';

import { useAuthUserSignOut } from "../services/useAmplifyAuth";

import { I18n } from 'aws-amplify';

import Footer from '../components/Footer';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

const SignOutPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("SignOutPage", countRef.current)
    return () => {
      console.log("SignOutPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const browserInfo = useSelector(state => state.localeState.browserInfo);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  // trigger sign out from URL
  const handleUserSignOut = useAuthUserSignOut();
  useEffect(() => {
    if (!userState.isLoading) {
      // all global option from URL ???
      // add processing ???
      handleUserSignOut({
        isGlobal: false,
        onSuccess: () => {
          history.replace({
            pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
            // search: "",
            // state: state,
          });
        },
        onError: (error) => {
          // handle ???
        },
      });
    }
  }, [handleUserSignOut, history, match.params.page, userState.isLoading]);

  return (
    <Fragment >
    
      <Pane clearfix minWidth={320} maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Pane
          // marginTop={is575PlusVH ? "10vh" : null}
        >

          <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
            <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
            <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Sign Out"}</Strong>
            <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          </Pane>

          {/* <Pane textAlign="center" marginTop={16}>
            <Text size={500} color="#283655">{I18n.get("XXX.")}</Text>
          </Pane> */}

          <Pane textAlign="center" marginTop={20}>
            <Button fontSize={16} paddingX={18} height={40} appearance="minimal"
              onClick={() => {
                history.replace({
                  pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
                  // search: "",
                  // state: state,
                });
              }}
            >
              {I18n.get("Go back to FACE DONATE")}
            </Button>
          </Pane>
        </Pane>

      </Pane>
      <Footer/>

    </Fragment>
  );
}

export default SignOutPage;