import { Fragment, useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo, useContext} from 'react';
import FacePH from '../images/FacePH';

import { Redirect, generatePath, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import { Heading, Text, Button, Table, Dialog, IconButton, CrossIcon, Icon, Paragraph, Strong, Popover, LockIcon, Menu, Position, Tablist, Tab, InfoSignIcon, Tooltip, AddIcon, ShopIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon, MoreIcon, Pill, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';

import { API, graphqlOperation, Storage, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import numeral from 'numeral';
import QRCode from 'qrcode';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import HeartIcon from '../assets/icons/HeartIcon';
import QRCodeIcon from '../assets/icons/QRCodeIcon';

import PaperPlaneIcon from '../assets/icons/PaperPlaneIcon';
import CommentIcon from '../assets/icons/CommentIcon';
import ProfileIcon from '../assets/icons/ProfileIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';

import AspectRatio from './AspectRatio';


import { useSelector, useDispatch } from 'react-redux';


const ProfileTabButtons = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileTabs - ${countRef.current}`);
    return () => {
      console.log("ProfileTabs - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const tabs = useMemo(() => {
    let initTabs = [
      {
        id: "history",
        heading: I18n.get('History'),
        mode: "history", // default 
        // component: <AccountEditProfile />,
      },
      {
        id: "dashboard",
        heading: I18n.get('Dashboard'),
        mode: "dashboard",
        // component: <AccountChangePassword />,
      },

      
    ];

    // if (profileDetails?.XXX?.total > 0) {
    //   initTabs.splice(1, 0, {
    //     id: "following",
    //     heading: I18n.get('Following'),
    //     mode: "following",
    //     // component: <AccountPersonalDetails />,
    //   });
    // }

    return initTabs;
  
  }, []);

  // useEffect(() => {
  //   if (!match.params.mode) {
  //     history.replace(generatePath(match.path, {...match.params, mode: "edit"}));
  //   }
  // }, [history, match.params, match.path]);

  // const selectedTab = useMemo(() => {
  //   let initTab = tabs.find(tab => tab.mode === match.params.mode);
  //   if (!initTab) {
  //     initTab = tabs.find(tab => tab.mode === "history")
  //   }
  //   return initTab;
  // }, [match.params.mode, tabs]);



  if (!profileDetails?.publicFaceId) {
    return null;
  }

  return (
    <Fragment>
      <Pane height="fit-content" paddingY={8} >
        <Tablist
          width={"100%"}
          display={"flex"}
          gap={8}
        >
          {tabs.map((tab, index) => (tab.hideFromMenu ? false :
            <Tab
              flex={1}
              key={tab.id}
              id={tab.id}
              marginX={0}
              onSelect={() => {
                // selectTab(tab);
                let newMode = null;
                if (tab.mode !== "history") {
                  newMode = tab.mode;
                }
                history.push(generatePath(match.path, {...match.params, mode: newMode}));
              }}
              isSelected={
                tab.mode === location.state?.prevMode
                || (!location.state?.prevMode && tab.mode === (match.params.mode || "history"))
                || (location.state?.prevMode === null && tab.mode === "history")
              }
              aria-controls={`panel-${tab.id}`}
              fontSize={14}
              height={40}
              borderRadius={5}
              className="noselect"
            >
              {tab.heading}
            </Tab>
          ))}
        </Tablist>
        
      </Pane>

    </Fragment>
  );
}

export default ProfileTabButtons;