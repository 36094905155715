import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';

import { Pane, Strong, Text, Button, TextInput, Paragraph, Tooltip, KeyDeleteIcon, SidebarTab, CrossIcon, TickIcon, IconButton, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const NumberPadComponent = ({ value, onChange, disabled = false }) => {
  
  const currentValueRef = useRef(value);

  const handleChangeCurrentValue = (digit) => {
    let temp = Math.round((currentValueRef.current + Number.EPSILON) * 100)
    temp = temp.toString() + digit.toString();
    temp = "00" + temp;
    temp = parseInt(temp);
    temp = Math.round(temp + Number.EPSILON) / 100;
    temp = Math.round((temp + Number.EPSILON) * 100) / 100;
    
    currentValueRef.current = temp;
    if (typeof onChange === "function") {
      onChange(currentValueRef.current);
    }
  }

  const handleDeleteLastDigitFromCurrentValue = () => {
    let temp = currentValueRef.current * 100;
    temp = Math.floor(temp / 10);
    temp = parseFloat(temp / 100);
    temp = Math.round((temp + Number.EPSILON) * 100) / 100;

    currentValueRef.current = temp;
    if (typeof onChange === "function") {
      onChange(currentValueRef.current);
    }
  }

  return(
    <Fragment >
      <Pane marginTop={10} alignItems="center" display="flex" height={40} >
        <Pane flex={1} >
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(1)}} >
            {"1"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(2)}} >
            {"2"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(3)}} >
            {"3"}
          </Button>
        </Pane>
      </Pane>
      <Pane marginTop={16} alignItems="center" display="flex" height={40} >
        <Pane flex={1} >
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(4)}} >
            {"4"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(5)}} >
            {"5"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(6)}} >
            {"6"}
          </Button>
        </Pane>
      </Pane>
      <Pane marginTop={16} alignItems="center" display="flex" height={40} >
        <Pane flex={1} >
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(7)}} >
            {"7"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(8)}} >
            {"8"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(9)}} >
            {"9"}
          </Button>
        </Pane>
      </Pane>
      <Pane marginTop={16} alignItems="center" display="flex" height={40} >
        <Pane flex={1} >
          <Pane width={"100%"} height={40}></Pane>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button fontSize={16} width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={() => {handleChangeCurrentValue(0)}} >
            {"0"}
          </Button>
        </Pane>
        <Pane flex={1} marginLeft={16}>
          <Button width={"100%"} justifyContent="center" height={40} disabled={(disabled)}
            onClick={handleDeleteLastDigitFromCurrentValue} >
            <KeyDeleteIcon color="#283655" style={{transform: "scaleX(-1)"}}/>
          </Button>
        </Pane>
      </Pane>
    </Fragment>
  );
}

export default NumberPadComponent;