import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ReceiptPH from '../images/ReceiptPH';
import HomeIcon from '../assets/icons/HomeIcon';
import FoodIcon from '../assets/icons/FoodIcon';
import HealthIcon from '../assets/icons/HealthIcon';
import EducationIcon from '../assets/icons/EducationIcon';
import PersonIcon from '../assets/icons/PersonIcon';

import { Link, useHistory, useParams, generatePath, useLocation, useRouteMatch} from 'react-router-dom';
import { Heading, Text, Dialog, Icon, MoreIcon, ChevronRightIcon, ChevronLeftIcon, Paragraph, Button, DoubleChevronRightIcon, ShopIcon, Checkbox, IconButton, Pane, ListItem, UnorderedList, Strong } from 'evergreen-ui';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Zoomable from "react-instagram-zoom";

import ShowMoreText from 'react-show-more-text';
import LinesEllipsis from 'react-lines-ellipsis';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import FormattedCurrencyNumber from './FormattedCurrencyNumber';

import TransactionHistoryTable from './TransactionHistoryTable';
import DialogHeader from './DialogHeader';

import AspectRatio from './AspectRatio';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, setTransactionDetails } from '../services/actions';

const TransactionDetailsMore = ({ modalClose, transactionDetails }) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionDetailsMore - ${countRef.current}`);
    return () => {
      console.log("TransactionDetailsMore - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  let history = useHistory();
  let location = useLocation();

  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  const [showMoreDialog, setShowMoreDialog] = useState(false);
  const [showQRDialogContent, setShowQRDialogContent] = useState(false);
  

  if (!transactionDetails) {
    return null;
  }

  const getTransactionType = () => {
    switch (transactionDetails.type) {
      case "donation":
      case "transfer":
      case "demoDonation":
        return I18n.get('Donation');

      case "topUp":
        return I18n.get('Top up');

      case "matchFunding":
        return I18n.get('Match-funding');

      case "provision":
      case "provisionByProvider":
      case "demoProvision":
        return I18n.get('Post');

      default:
        break;
    }
  }

  const GetImpactType = () => {

    switch (transactionDetails.type) {
      case "donation":
      case "transfer":
      case "demoDonation":
        return null;

      case "topUp":
        return null;

      case "matchFunding":
        return null;

      case "provision":
      case "provisionByProvider":
      case "demoProvision":
        switch (transactionDetails.description) {
          case "home":
            return(
              <Fragment>
                <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
                <HomeIcon color="#283655" size={24} style={{flex: "none"}} />
              </Fragment>
            );

          case "food":
            return (
              <Fragment>
                <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
                <FoodIcon color="#283655" size={24} style={{flex: "none"}} />
              </Fragment>
            );

          case "health":
            return (
              <Fragment>
                <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
                <HealthIcon color="#283655" size={24} style={{flex: "none"}} />
              </Fragment>
            );

          case "education":
            return (
              <Fragment>
                <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
                <EducationIcon color="#283655" size={24} style={{flex: "none"}} />
              </Fragment>
            );
    
          default:
            // return null;
            return (
              <Fragment>
                <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
                <FoodIcon color="#283655" size={24} style={{flex: "none"}} />
              </Fragment>
            );
        }

      default:
        return null;
    }
  }

  const GetImpactRecipient = () => {
    if (transactionDetails.toFace && transactionDetails.fromPublicFaceId !== transactionDetails.toPublicFaceId) {
      return (
        <Fragment>
          <ChevronRightIcon color="#7B8B9A" flex="none" size={16} marginX={4}/>
          <Strong className="inline-link" fontSize={16} color="#283655" style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}}
            is={Link} to={{
              pathname: generatePath("/:locale?/:handle", {...match.params, handle: transactionDetails.toFace.username}),
              // state: {
              //   isModal: true,
              // },
            }}
          >
            {transactionDetails.toFace.name}
          </Strong>
        </Fragment>
      );
    }
    else if (transactionDetails.peopleImpacted) {
      return (
        <Fragment>
          <ChevronRightIcon color="#7B8B9A" size={14} marginX={4}/>
          <PersonIcon color="#283655" size={20} flex="none" />
          {transactionDetails.peopleImpacted > 1 ?
            <Strong fontSize={16} color="#283655">{` ×${transactionDetails.peopleImpacted}`}</Strong>
          : null }
        </Fragment>
      );

    }
    
    return null;
  }


  return (
    <Fragment>
    <Pane >
      {(transactionDetails && (transactionDetails.type === "demoDonation" || transactionDetails.type === "demoProvision")) ?
        <Pane height={50} marginLeft="auto" marginRight="auto" padding={10} background="linear-gradient(0deg, rgba(249,249,251,1) 10%, rgba(108,99,255,1) 95%)" borderTopLeftRadius={10} borderTopRightRadius={10}>
          <Strong fontSize={24} color="#F9F9FB" >{"DEMO"}</Strong>
        </Pane>
      : null}
      {transactionDetails ?
        <Pane flex={1} width="100%" height="auto" alignItems="center" display="flex" flexWrap="wrap">

          {/* {modalClose ?
            <Pane flex={1} width="100%" height="auto" textAlign="left">
              <Pane marginTop={8} height="auto" alignItems="center" display="inline-flex" flexWrap="wrap">
                  <Button marginRight={8} padding={0} height={40} appearance="minimal" justifyContent="center"
                    onClick={(e) => {modalClose(() => {
                      if (location.state && location.state.isModal) {
                        e.stopPropagation();
                        history.goBack();
                      }
                    })}}
                  >
                    <ChevronLeftIcon color="#283655" size={24} />
                  </Button>                
              </Pane>
            </Pane>
          : null} */}

          {modalClose ?
            <Pane padding={10} >
              <DialogHeader
                // headerText={I18n.get('New post')}
                // hideLeftButton={(processing || readyToComplete || cancelToComplete)}
                hideRightButton={true}
                // leftButtonDisabled={(processing || readyToComplete || cancelToComplete)}
                leftButtonIcon={ChevronLeftIcon}
                onLeftButtonClick={() => {
                  modalClose(() => {
                    history.goBack();
                  });
                }}
              />
            </Pane>
          : null}

          <Pane flex="none" width="100%" height="auto" textAlign="right">
            {/*<Button margin={0} padding={2} width={20} height={18} appearance="minimal" 
              onClick={() => {setShowMoreDialog(true)}}
            >
              <Icon icon={MoreIcon} size={18} color="#7B8B9A" />
            </Button>*/}
            <Dialog
              isShown={showMoreDialog || showQRDialogContent}
              onCloseComplete={() => {
                setShowMoreDialog(false);
              }}
              // onCancel={(close) => close()}
              title='Face More'
              hasHeader={false}
              hasFooter={false}
              preventBodyScrolling
              shouldCloseOnOverlayClick={false}
              shouldCloseOnEscapePress={false}
              width={350}
              containerProps={{
                "maxHeight": "100%",
                "margin": "auto"
              }}
            >
              <Pane>
                {/*<QRDialogContent isShown={showQRDialogContent} />*/}
              </Pane>
            </Dialog>
          </Pane>
        </Pane>
      : 
        <Pane paddingX={10} paddingTop={8} marginBottom={3} width={100} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={16}/></SkeletonTheme></Pane>
      }
    </Pane>

    {transactionDetails.status === "restricted" ?
      null // needs design improvement
    :

      <Pane paddingX={10} className="noselect" >

        <Pane >
          <Strong className="inline-link" fontSize={16} lineHeight={1.3} color="#283655"
            is={Link} to={{
              pathname: generatePath("/:locale?/:handle", {...match.params, handle: transactionDetails.fromFace.username}),
              // state: {
              //   isModal: true,
              // },
            }}
          >
            {transactionDetails.fromFace.name}
          </Strong>
        </Pane>
        {/* <Pane textAlign="center" marginTop={10} >
          <Strong fontSize={16} color="#7B8B9A">{getTransactionType()}</Strong>
        </Pane> */}
        
        <Pane marginTop={4} flex="none" display="flex" alignItems="center" justifyContent="left" >
          <FormattedCurrencyNumber textAlign="center" number={transactionDetails.amount} currency={transactionDetails.currency} fontSize={20} />
          {transactionDetails.objectDetails && transactionDetails.objectDetails.matched ?
            <Strong marginLeft={4} display="inline-flex" height={20} fontSize={18} color={"#1070CA"} >{ "×2" }</Strong>
          : null }
          
          <GetImpactType />
          <GetImpactRecipient />
          
        </Pane>
        {transactionDetails.objectDetails && transactionDetails.objectDetails.matched ? 
          <Pane textAlign="center" marginTop={2} >
            <Text fontSize={14} color="#7B8B9A" >{
              `${I18n.get("This donation is match-funded")}.`
            }</Text>
          </Pane>
        : null }
      </Pane>
    }

    </Fragment>
  );
}

const TransactionInfo = ({ transactionDetails }) => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.warn("TransactionInfo cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  let match = useRouteMatch();
  let history = useHistory();
  let location = useLocation();

  // const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);


  let tRestricted = "Restricted";
  let tAmount = "Amount";
  let tDate = "Date";
  let tProvider = "Provider";
  let tDonationFrom = "Donation from";
  let tMatchFundingFrom = "Match funding from";
  let tRecipient = "Recipient";

  switch (localeState.language) {
    case "it":
      tRestricted = "Restricted";
      tAmount = "Totale";
      tDate = "Date";
      tProvider = "Provider";
      tDonationFrom = "Donation from";
      tMatchFundingFrom = "Match funding from";
      tRecipient = "Beneficiario";
      break;

    default:
      break;
  }

  let tFrom = tProvider;
  if (transactionDetails) {
    switch (transactionDetails.type) {
      case "donation":
      case "transfer":
      case "demoDonation":
        tFrom = tDonationFrom;
        break;
      case "matchFunding":
        tFrom = tMatchFundingFrom;
        break;
      default:
        break;
    }
  }
  

  let tTo = null;
  if (transactionDetails && transactionDetails.toFace
    && transactionDetails.type !== "matchFunding"
    && transactionDetails.fromPublicFaceId !== transactionDetails.toPublicFaceId
  ) {
    tTo = tRecipient;
  }

  const linesEllipsisRef = useRef(null);

  const [captionIsClamped, setCaptionIsClamped] = useState(true);

  return (
    <Pane marginX={10} >

      {transactionDetails ?
        transactionDetails.status === "restricted" ?
          <Pane >
            <Heading is="p" size={800} lineHeight={1} color="#283655" >{tRestricted}</Heading>
          </Pane>
        :
          <Pane>
            

            {transactionDetails.caption ?
              <Pane marginTop={10} className="noselect" >
                {captionIsClamped ?
                  <Paragraph fontSize={16} lineHeight={1.3} color="#283655" minHeight={32} >
                    <Pane
                      onClick={(event) => {
                        setCaptionIsClamped(false);
                      }}
                    >
                      <HTMLEllipsis
                        // https://www.npmjs.com/package/react-lines-ellipsis
                        ref={linesEllipsisRef}
                        unsafeHTML={`
                          <a
                            class="inline-link"
                            style="fontWeight: 600; color: #283655; "
                            href="${generatePath("/:locale?/:handle", {...match.params, handle: transactionDetails.fromFace.username})}"
                            onclick="event.stopPropagation();"
                            target="_self" // target="_blank" // 
                            rel="noopener noreferrer"
                          >${transactionDetails.fromFace.name}</a>
                          <span class="clickable-text" >${" "}${transactionDetails.caption}</span>
                        `}
                        maxLine={3} // doesn't strictly work // this should depend on the length of 2nd and 3rd lines
                        ellipsisHTML={`... <span fontSize={15} style="color: #7B8B9A" > ${I18n.get('more')} </span>`}
                        trimRight
                        onReflow={(rleState)=> {
                          // console.log("rleState", rleState);
                          setCaptionIsClamped(rleState.clamped);
                        }}
                        basedOn='letters'
                        component='div'
                      />
                    </Pane>
                  </Paragraph>
                :
                  <Paragraph whiteSpace={"break-spaces"} fontSize={16} lineHeight={1.3} color="#283655" minHeight={32}>
                    <Text className="inline-link" style={{"fontWeight": "600"}} color="#283655"
                      is={Link} to={{
                        pathname: generatePath("/:locale?/:handle", {...match.params, handle: transactionDetails.fromFace.username}),
                        // state: {
                        //   isModal: true,
                        // },
                      }}
                    >
                      {transactionDetails.fromFace.name}
                    </Text>
                    {" "}{transactionDetails.caption.replace(/<br\/>/g,'\r\n')}
                  </Paragraph>
                }
                
              </Pane>
            : null }

            {transactionDetails.type === "provision" || transactionDetails.type === "provisionByProvider" || transactionDetails.type === "demoProvision" ?
              <Pane className="noselect" >
                <Text fontSize={13} color="#7B8B9A">{formatDateFns(new Date(transactionDetails.createdAtUNIX * 1000), "d/M/yyyy")}</Text>
              </Pane>
            : null }

            

            {/* <Pane marginTop={8} >
              <Pane >
                <Strong fontSize={14} color="#7B8B9A">{tAmount}</Strong>
              </Pane>
              <Pane flex="none" display="flex" alignItems="center" >
                <FormattedCurrencyNumber number={transactionDetails.amount} currency={transactionDetails.currency} fontSize={32} fontColor={"#283655"} />
                {(transactionDetails.objectDetails && transactionDetails.objectDetails.matched) ?
                  <Strong marginLeft={4} display="inline-flex" height={20} fontSize={18} color={"#283655"} >{ "×2" }</Strong>
                : null }
              </Pane>
            </Pane> */}

            {/* {(transactionDetails.fromFace) ?
              <Pane marginTop={4}>
                <Strong fontSize={14} color="#7B8B9A" >{tFrom}</Strong>
                <Link target="_blank" className='text-link'
                  to={{
                    pathname: `/${transactionDetails.fromFace.username}`,
                    // search: "?sort=name",
                    // hash: "#the-hash",
                    // state: { fromDashboard: true }
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Heading is="p" size={800} lineHeight={1} color="#283655" minHeight={32} style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >{transactionDetails.fromFace.name}</Heading>
                </Link>
              </Pane>
            : null } */}

            {/* {tTo ?
              <Pane marginTop={4}>
                <Strong fontSize={14} color="#7B8B9A" >{tTo}</Strong>
                <Link target="_blank" className='text-link'
                  to={{
                    pathname: `/${transactionDetails.toFace.username}`,
                    // search: "?sort=name",
                    // hash: "#the-hash",
                    // state: { fromDashboard: true }
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Heading is="p" size={800} lineHeight={1} color="#283655" minHeight={32} style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >{transactionDetails.toFace.name}</Heading>
                </Link>
              </Pane>
            : null } */}
            
          </Pane>
        :
        <Pane marginY={10} >
          <Pane paddingY={2} width={90} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={180} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={28}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={60} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={220} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
        </Pane>
      }
              
    </Pane>
  );

};

const handleDragStart = (e) => e.preventDefault();

const GalleryOfImages = ({ imageUrls = [] }) => {
  
  const slideCount = Array.isArray(imageUrls) ? imageUrls.length : 0;
  let centerDots = slideCount < 6 ? slideCount : 3;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const centerOffset = useRef(0);
  const slideOffset = useRef(0);
  const slideDirectionRightRef = useRef(true);

  const TOTAL_SPACE = 10;
  const SIZES = [6, 4, 2];

  // background="#E4E7EB"

  const items = imageUrls?.map((url) => {
    return (
      <AspectRatio ratio={1/1} >
        <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto" className="noselect" >
            <img src={url} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit"}} onDragStart={handleDragStart} />
        </Pane>
      </AspectRatio>
    );
    
  });

  const NavDots = () => {

    if (slideCount < 2) {
      return null;
    }

    function clamp(value, min, max) {
      return Math.min(Math.max(min, value), max)
    }

    let currentCenterOffset = activeSlideIndex - slideOffset.current;
    if (currentCenterOffset >= 0 && currentCenterOffset < centerDots) {
      centerOffset.current = currentCenterOffset;
    } else {
      slideOffset.current = activeSlideIndex - centerOffset.current;
    }

    return (
      <Fragment>
        <Pane alignItems="center" display="flex" flexWrap="wrap" className="noselect" height={18} marginTop={10} justifyContent="center" >
          {Array.from({ length: slideCount }, (v, k) => k).map((e, i, a) => {
            
            const centerPage = parseInt(centerDots / 2, 10) + slideOffset.current;
            const distance = Math.abs(i - centerPage);
            let scaledDistance = clamp(distance - parseInt(centerDots / 2, 10), 0, 3);
            
            if (slideDirectionRightRef.current && slideOffset.current > 2 && i > activeSlideIndex) {
              scaledDistance = scaledDistance - 1;
            }
            else if (!slideDirectionRightRef.current && slideOffset.current < 3 && i < activeSlideIndex) {
              scaledDistance = scaledDistance - 1;
            }
            const size = SIZES[scaledDistance] || 0;
            const margin = size !== 0 ? (TOTAL_SPACE - size) / 2 : 0;


            return (
              <Pane key={`dot-${i}`} flex={"none"} background={activeSlideIndex === i ? "#4e99e9" : "#E4E7EB"} marginLeft={margin} marginRight={0}
                width={size} height={size} borderRadius={"50%"}
                style={{"transition": "width 300ms ease-in-out, height 300ms ease-in-out, margin 300ms ease-in-out, background 800ms ease-in-out",
                "-webkit-transition": "width 300ms ease-in-out, height 300ms ease-in-out, margin 300ms ease-in-out, background 800ms ease-in-out" }}
              ></Pane>
            );
          })}
        </Pane>
      </Fragment>
    );
  }

  if (!Array.isArray(imageUrls)) {
    return null;
  }

  return (
    <Fragment>
      <Pane width="100%" height={1} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#E6E8F0"></Pane>
      <Zoomable zIndex={20} maxScale={4} style={{"zIndex": 20}} >
        <div style={{ "user-select": "none", "touch-action": "pan-y", "-webkit-user-drag": "none", "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)" }} >
          <AliceCarousel
            animationType="fadeout" 
            animationDuration={400}
            disableButtonsControls={true}
            disableDotsControls={true}
            autoPlay={false}
            autoPlayInterval={1800}
            swipeExtraPadding={200}
            infinite={false}
            mouseTracking={slideCount > 1}
            touchTracking={slideCount > 1}
            activeIndex={activeSlideIndex}
            // onInitialized={(e) => {
            //   console.log("onInitialized", e);
            // }}
            onSlideChanged={(e) => {
              // console.log("onSlideChanged", e);
              slideDirectionRightRef.current = activeSlideIndex > e.slide ? false : true;
              setActiveSlideIndex(e.slide);
            }}
            // renderDotsItem={(e) => {
            //   console.log("renderDotsItem", e);
            // }}
            // responsive={}
            items={items}
          />
        </div>
      </Zoomable>
      <Pane width="100%" height={1} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#E6E8F0"></Pane>
      <NavDots />
    </Fragment>
  );
}


const TransactionPicture = ({ transactionDetails }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionPicture - ${countRef.current}`);
    return () => {
      console.log("TransactionPicture - cleaned up");
      isCurrent.current = false;
    }
  }, []);


  return(
    !transactionDetails ?
      <Pane marginX={10} border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
        <AspectRatio ratio={1/1} >
          <Pane position="absolute" width="100%" height="100%" paddingY={50} paddingX={36} marginLeft="auto" marginRight="auto" >
            <ReceiptPH baseColor="#E4E7EB" width="100%" height="100%" />
          </Pane>
        </AspectRatio>
      </Pane>
    :
      !Array.isArray(transactionDetails.proofImagesUrls) || transactionDetails.proofImagesUrls.length === 0 ? null
      :
        <Pane marginX={10} marginTop={10} >
          <GalleryOfImages imageUrls={transactionDetails.proofImagesUrls} />
        </Pane>
  );
}

const TransactionHistory = ({ modalClose, transactionDetails }) => {

  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  let match = useRouteMatch();

  let tHistoryHeading = "History";
  switch (localeState.language) {
    case "it":
      tHistoryHeading = "Storia";
      break;

    default:
      break;
  }


  return(
    !transactionDetails ?
    null
    :
    <Pane >
      <Pane marginLeft="auto" marginRight="auto" maxWidth={350} alignItems="baseline" display="flex" flexWrap="wrap" background="tint1">
        <Pane flex="none" marginLeft="auto" width={10} height={20} borderTopRightRadius={10} borderBottomRightRadius={10} background="#FFFFFF" ></Pane>
        <Pane flex={1} width="100%" height={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A"></Pane>
        <Pane flex="none" marginRight="auto" width={10} height={20} borderTopLeftRadius={10} borderBottomLeftRadius={10} background="#FFFFFF" ></Pane>
      </Pane>
      <Pane marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" paddingTop={10}
        borderBottomLeftRadius={10} borderBottomRightRadius={10} >
        {transactionDetails.history?.length > 0 ?
          <Pane >
            <Pane paddingX={10} >
              <Strong size={400} color="#7B8B9A" >{tHistoryHeading}</Strong> 
            </Pane>
            <TransactionHistoryTable modalClose={modalClose} transactionDetails={transactionDetails} />
          </Pane>
        : 
          <Pane paddingX={10} paddingBottom={10}>
            <Pane textAlign="center" >
              <Strong size={400} color="#7B8B9A" >{I18n.get("No history")}</Strong>
            </Pane>
          </Pane>
        }
      </Pane>
    </Pane>
  );

}


const TransactionDetails = ({ modalClose, ...rest }) => {

  let match = useRouteMatch();
  // let history = useHistory();
  let location = useLocation();

  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  const [transactionDetailsLoaded, setTransactionDetailsLoaded] = useState(false);
  const [localTransactionDetails, setLocalTransactionDetails] = useState();


  const getTransactionDetails = useCallback(async() => {
    
    let transactionDetails = null;
    const publicTransactionId = match.params.publicTransactionId;
    
    try {
      const response = await API.graphql({
        query: queries.getTransactionDetails,
        variables: {
          publicTransactionId: publicTransactionId,
          currency: localeState.currency,
          locale: localeState.locale,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      transactionDetails = response.data.getTransactionDetails;
      console.log("transactionDetails", transactionDetails);
      if (transactionDetails.objectDetails) {
        transactionDetails.objectDetails = JSON.parse(transactionDetails.objectDetails);
      }

      if (transactionDetails.type === "demoDonation") {
        let demoToFaceName = "Bob";
        let demoFromFaceName = "Demo Supporter";
        switch (localeState.language) {
          case "it":
            demoToFaceName = "Gino";
            demoFromFaceName = "Demo Supporter";
            break;
      
          default:
            break;
        }
        transactionDetails.toFace.name = demoToFaceName;
        transactionDetails.fromFace.name = demoFromFaceName;
      }

      if (transactionDetails.type === "demoProvision") {
        let demoToFaceName = "Bob";
        let demoFromFaceName = "GreenGroceries";
        switch (localeState.language) {
          case "it":
            demoToFaceName = "Gino";
            demoFromFaceName = "Supermercato";
            break;
      
          default:
            break;
        }
        transactionDetails.toFace.name = demoToFaceName;
        transactionDetails.fromFace.name = demoFromFaceName;
      }
      
      // dispatch(setTransactionDetails(transactionDetails));
      setLocalTransactionDetails(transactionDetails);
      setTransactionDetailsLoaded(true);
    }
    catch (error) {
      console.error("with getTransactionDetails", error);
      if (error.errors && error.errors.length > 0) {
        transactionDetails = {error: error.errors[0]};
      }
      else {
        transactionDetails = {
          error: {
            message: "Something went wrong."
          }
        };
      }
    }

  }, [localeState.currency, localeState.language, localeState.locale, match.params.publicTransactionId, userState.user]);

  useEffect(() => {
    if (localTransactionDetails && localTransactionDetails.publicTransactionId !== match.params.publicTransactionId && (!location.state || !location.state.isModal)) {
      setTransactionDetailsLoaded(false);
    }
  }, [location.state, match.params.publicTransactionId, localTransactionDetails]);

  useEffect(() => {
    if (!transactionDetailsLoaded && !userState.isLoading && match.params.publicTransactionId) {
      getTransactionDetails();
    }
  }, [getTransactionDetails, location.state, match.params.publicTransactionId, transactionDetailsLoaded, userState.isLoading]);


  return (
    <Pane flex={1} marginLeft="auto" marginRight="auto" minWidth={250} maxWidth={350} background="tint1" borderRadius={10} >
      <TransactionDetailsMore modalClose={modalClose} transactionDetails={localTransactionDetails}/>
      <TransactionPicture transactionDetails={localTransactionDetails}/>
      <TransactionInfo transactionDetails={localTransactionDetails}/>
      <TransactionHistory modalClose={modalClose} transactionDetails={localTransactionDetails}/>
    </Pane>
  );
}

export default TransactionDetails;