// I18n.get('XXX')

// let tNewRecipient = "New recipient";
// let tPleaseTypeName = "Please type the recipient's name...";
// let tTooLong = "The name looks too long...";
// let tSomethingWentWrong = "Something went wrong...";
// let tRegister = "Register";

// tNewRecipient = "Nuovo destinatario";
// tPleaseTypeName = "Per favore inserisci il nome del destinatario...";
// tTooLong = "Questo nome e’ troppo lungo...";
// tSomethingWentWrong = "Qualcosa non ha funzionato...";
// tRegister = "Registra";

const dict = {
  'en': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': null,
    'Choose language': null,
    'Language': null,
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,  
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': "On",
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": "Make sure your password includes:",
    "contains a digit": "1 digit (e.g. 8)",
    "contains uppercase and lowercase letters": "uppercase and lowercase letters (e.g. Ab)",
    "at least 8 characters": "at least 8 characters",
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'it': {
    'Donate': "Dona",
    'Top up': "Ricarica",
    'Mission': "Mission",
    'Profile': "Profilo",
    'Settings': "Impostazioni",
    'Sign out': "Disconnetti",
    'You have been signed out.': "Sei disconnesso.",
    'Currency': "Valuta",
    'Choose language': "Scegli la lingua",
    'Language': "Lingua",
    'We use essential cookies.': "Utilizziamo solo i cookies essenziali.",
    'Learn more': "Scopri di piu’",
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': "In avanzamento",
    'Supported by': "Con il supporto di",
    'Powered by': "Alimentati da",
    'Featured in': "Menzionati in",
    'Registered with': "Registrato con",
    'Say hello at': "Contattaci su",
    'Legal': "Termini",
    'Directory': "Lista progetti",
    'FAQ': null,
    'Sign in': "Accesso",
    'Log in': "Accesso",
    'Sign up': "Registrazione",
    'Confirm password': 'Conferma password',
    'Reset password': "Cambia password",
    'Forgot password?': "Scordata la password?",
    'Open camera': "Apri fotocamera",
    'Retake': "Riprova",
    'Use photo': "Usa la foto",
    'Take a picture or scan QR code.': "Fai una foto o scansiona il QR code.",
    'View': "Visita",
    'Thank you!': "Grazie!",
    'Menu': "Menu",
    'Pay': "Paga",
    'Pay from your balance for goods and services.': "Paga dal tuo saldo",
    'Provide': "Fornisci",
    'Provide goods and/or services and get paid.': "Fornisci beni o servizi e ricevi pagamento.",
    'New picture': "Nuova foto",
    'New post': "Nuova fornitura",
    'Show this QR code to recipient.': "Mostra questo codice QR al ricevente.",
    'New payment': "Nuovo pagamento",
    'Payment is progressing': "Verifica pagamento in corso",
    'Show this QR code to make payment.': "Mostra questo codice QR per pagare.",
    'A QR code is being generated': "Stiamo generando il tuo codice QR.",
    'Expires in': "Scade tra",
    'Payment expired': "Tempo scaduto",
    'No payment has been made. If you still want to pay, please start again.': "Non Mostra questo codice QR al ricevente.",
    'A voucher is being generated': "Stiamo generando il tuo voucher.",
    'Download voucher': "Scarica il voucher",
    'Use funds from': "Usa i fondi del",
    'Available amount': "Saldo disponibile",
    'Required amount': "Ammontare richiesto",
    'Available balance': "Saldo disponibile",
    'unknown': "Sconosciuto",
    'Amount': "Ammontare", 
    'max.': "max.",
    'min.': null,
    "Recipient's balance": "Dal saldo ricevente",
    'My balance': "Il mio saldo",
    'Insufficient funds. Your balance:': "Fondi insufficienti. Saldo disponibile:",
    'Insufficient funds. Available:': "Fondi insufficienti. Saldo disponibile:",
    'will be deducted from your current balance of': "saranno dedotti dal tuo saldo di",
    "will be deducted from the recipient's available balance of": "saranno dedotti dal saldo del ricevente di",
    'Select payment method': "Seleziona un metodo di pagamento",
    'Scan a payment QR code': "Scansiona il codice QR abilitato al pagamento",
    'Next': "Continua",
    'Recipient': "Beneficiario",
    'Provision amount': "Ammontare della fornitura",
    'Enter value of what you are providing.': "Digita il totale da pagare",
    'Feedback': "Feedback",
    'Browse': "Esplora",
    'Add pictures showing what you are providing.': "Fai una foto a quello chestai fornendo.",
    'Maximum number of pictures is added.': "Hai raggiunto il numero massimo di foto caricabili.",
    'New feedback': "Nuovo feedback",
    'Write a caption': "Descrivi l'impatto",
    'Caption is too long.': "La descrizione é troppo lunga",
    'This caption will be visible to others.': "Questa descrizione é visibile a tutti.",
    'Category': "Tipo di impatto",
    'Select a category describing this provision.': "Seleziona il tiop di imaptto di questa fornitura.",
    'Food': "Nutrizione",
    'Home': "Abitazione",
    'Health': "Salute e benessere",
    'Education':"Educazione",
    'Search': "Cerca",
    'Profiles directory': "Lista profili",
    'History': "Cronologia",
    'Click to see more': "Clicca per scoprire di più",
    'No history yet': "Cronologia non ancora presente",
    'hidden': "privato",
    'anonymous': "anonimo",
    'donation from': "donazione da",
    'donation to': "donazione a",
    'match donation from': "Raddoppia le donazioni da",
    'matching donation of': "Raddoppia le donazioni di",
    'provision from': "fornitura da",
    'provision to': "fornitura a",
    'Balance': "Saldo",
    'On': "Attivo",
    'Off': "Disattivo",
    'Cancel': "Cancella",
    'Are you sure?': "Sei sicuro?",
    'Yes, cancel': "Si, cancella",
    'No, go back': "No, torna indietro",
    'Complete': "Completa",
    'Yes, complete': "Sì, procedi",
    'Add recipient': "Raddoppia le donazioni da",
    'Directly impacted recipient': "Numero di persone aiutate",
    'Number of people directly impacted': "Numero di beneficiari diretti",
    'Enter number to show impact of this provision.': "Inserisci un numero per dimostrare l'impatto del tuo servizio.",
    'Hmm... This is slow...': "Hmm... E' più lento del previsto...",
    'Close': "Chiudi",
    'or': "oppure",
    'Try again': "Riprova",
    'Continue': "Continua",
    'Please, could you try again?': "Per favore, puoi provare di nuovo?",
    'Something went wrong': "Qualcosa non ha funzionato",
    'Match donations': "Raddoppia le donazioni",
    'When a donation is made to your page, it will be displayed as "matched"': "Quando una donazione viene fatta alla tua pagina, sara' visualizzata come \"raddoppiata\"",
    'Match funding due': "Saldo dovuto per corrispondenza donazioni",
    'Total of matched donations yet to be funded': "Totale donazioni raddoppiate ancora da corrispondere",
    'Add funds': "Aggiungi fondi",
    'Payouts': "Pagamenti",
    'Available to use': "Disponibile all'uso",
    'Will be available soon': "Saldo presto disponibile",
    'Currently on the way to your bank account': "Stanno raggiungendo il tuo conto in banca",
    'Available to pay out to your bank account': "Disponibile ad essere pagato sul tuo conto",
    'Total': "Totale",
    // Account Headings
    'Edit Profile': "Modifica Profilo",
    'Change Password': "Modifica Password",
    'Payments': "Pagamenti",
    'Manage Balance': "Gestione saldo",
    'Notifications': "Notifiche",
    'Privacy and Security': "Sicurezza e privacy",
    // Account - Edit Profile
    'Name': "Nome",
    'First name': null,
    'Username': "Nome utente",
    'Website': "Sito web",
    'Bio': "Bio",
    'Email': "E-mail",
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': "Password",
    'Phone number': "Numero di telefono",
    'Face': "Volto",
    'Link Face': "Connetti un volto",
    'Submit': "Invia",
    'Change Picture': "Cambia la foto del profilo.",
    'Temporarily disable my account': "Disabilita questo account temporaneamente.",
    'Download QR code': "Scarica il codice QR",
    'Upload Photo': "Carica foto",
    'Remove Current Photo': "Rimuovi la foto.",
    'Too long.': null,
    'Name is too long.': "Il nome é troppo lungo.",
    'Bio is too long.': "La bio é troppo lunga.",
    'Website is too long.': "L'URL del sito é troppo lungo.",
    'Website is not a valid.': "L'URL del sito non é valido.",
    'Email is too long.': "L'e-mail é troppo lunga",
    'Email is not a valid.': "L'e-mail non é valida.",
    'Username is too long.': "Il nome utente é troppo lungo.",
    'Username is too short.': "Il nome utente é troppo corto.",
    'Username is invalid.': "Questo nome utente é invalido.",
    'Username is taken.': "Questo nome utente é già in uso.",
    // Account - Change Password
    'Old password': "Vecchia password",
    'New password': "Nuova password",
    'Confirm new password': "Conferma nuova password",
    'Change password': "Cambia password",
    'Forgot password': "Hai scordato la tua password?",
    // Auth
    "Please make sure your password:": "Per favore la tua password deve:",
    "contains a digit": "contenere un numero",
    "contains uppercase and lowercase letters": "contenere maiuscole e minuscole",
    "at least 8 characters": "includere almeno 8 caratteri",
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": "Ops, questa pagina non e’ disponibile.",
    "The link you followed may be broken, or the page may have been removed.": "La pagina che cerchi ha un errore, non esiste, oppure e’ stata rimossa.",
    "Go back to FACE DONATE": "Torna a FACE DONATE",
    'No internet connection': "Nessuna connessione internet",
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'bg': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': "Валута",
    'Choose language': "Изберете език",
    'Language': "Език",
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': "Продължи",
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'ru': {
    'Donate': null,
    'Top up': null,
    'Mission': "Миссия",
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': "Валюта",
    'Choose language': null,
    'Language': "Язык",
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'fr': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': null,
    'Choose language': null,
    'Language': null,
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'de': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': null,
    'Choose language': null,
    'Language': null,
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'es': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': null,
    'Choose language': null,
    'Language': null,
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
  'xx': {
    'Donate': null,
    'Top up': null,
    'Mission': null,
    'Profile': null,
    'Settings': null,
    'Sign out': null,
    'You have been signed out.': null,
    'Currency': null,
    'Choose language': null,
    'Language': null,
    'We use essential cookies.': null,
    'Learn more': null,
    'X1': null,
    'X2': null,
    'X3': null,
    'X4': null,
    'Processing': null,
    'Supported by': null,
    'Powered by': null,
    'Featured in': null,
    'Registered with': null,
    'Say hello at': null,
    'Legal': null,
    'Directory': null,
    'FAQ': null,
    'Sign in': null,
    'Log in': null,
    'Sign up': null,
    'Confirm password': null,
    'Reset password': null,
    'Forgot password?': null,
    'Open camera': null,
    'Retake': null,
    'Use photo': null,
    'Take a picture or scan QR code.': null,
    'View': null,
    'Thank you!': null,
    'Menu': null,
    'Pay': null,
    'Pay from your balance for goods and services.': null,
    'Provide': null,
    'Provide goods and/or services and get paid.': null,
    'New picture': null,
    'New post': null,
    'Show this QR code to recipient.': null,
    'New payment': null,
    'Payment is progressing': null,
    'Show this QR code to make payment.': null,
    'A QR code is being generated': null,
    'Expires in': null,
    'Payment expired': null,
    'No payment has been made. If you still want to pay, please start again.': null,
    'A voucher is being generated': null,
    'Download voucher': null,
    'Use funds from': null,
    'Available amount': null,
    'Required amount': null,
    'Available balance': null,
    'unknown': null,
    'Amount': null,
    'max.': null,
    'min.': null,
    "Recipient's balance": null,
    'My balance': null,
    'Insufficient funds. Your balance:': null,
    'Insufficient funds. Available:': null,
    'will be deducted from your current balance of': null,
    "will be deducted from the recipient's available balance of": null,
    'Select payment method': null,
    'Scan a payment QR code': null,
    'Next': null,
    'Recipient': null,
    'Provision amount': null,
    'Enter value of what you are providing.': null,
    'Feedback': null,
    'Browse': null,
    'Add pictures showing what you are providing.': null,
    'Maximum number of pictures is added.': null,
    'New feedback': null,
    'Write a caption': null,
    'Caption is too long.': null,
    'This caption will be visible to others.': null,
    'Category': null,
    'Select a category describing this provision.': null,
    'Food': null,
    'Home': null,
    'Health': null,
    'Education': null,
    'Search': null,
    'Profiles directory': null,
    'History': null,
    'Click to see more': null,
    'No history yet': null,
    'hidden': null,
    'anonymous': null,
    'donation from': null,
    'donation to': null,
    'match donation from': null,
    'matching donation of': null,
    'provision from': null,
    'provision to': null,
    'Balance': null,
    'On': null,
    'Off': null,
    'Cancel': null,
    'Are you sure?': null,
    'Yes, cancel': null,
    'No, go back': null,
    'Complete': null,
    'Yes, complete': null,
    'Add recipient': null,
    'Directly impacted recipient': null,
    'Number of people directly impacted': null,
    'Enter number to show impact of this provision.': null,
    'Hmm... This is slow...': null,
    'Close': null,
    'or': null,
    'Try again': null,
    'Continue': null,
    'Please, could you try again?': null,
    'Something went wrong': null,
    'Match donations': null,
    'When a donation is made to your page, it will be displayed as "matched"': null,
    'Match funding due': null,
    'Total of matched donations yet to be funded': null,
    'Add funds': null,
    'Payouts': null,
    'Available to use': null,
    'Will be available soon': null,
    'Currently on the way to your bank account': null,
    'Available to pay out to your bank account': null,
    'Total': null,
    // Account Headings
    'Edit Profile': null,
    'Change Password': null,
    'Payments': null,
    'Manage Balance': null,
    'Notifications': null,
    'Privacy and Security': null,
    // Account - Edit Profile
    'Download QR code': null,
    'Name': null,
    'First name': null,
    'Username': null,
    'Website': null,
    'Bio': null,
    'Email': null,
    'Personal information': null,
    "This won't be part of your public profile.": null,
    'Password': null,
    'Phone number': null,
    'Face': null,
    'Link Face': null,
    'Submit': null,
    'Change Picture': null,
    'Temporarily disable my account': null,
    'Upload Photo': null,
    'Remove Current Photo': null,
    'Too long.': null,
    'Name is too long.': null,
    'Bio is too long.': null,
    'Website is too long.': null,
    'Website is not a valid.': null,
    'Email is too long.': null,
    'Email is not a valid.': null,
    'Username is too long.': null,
    'Username is too short.': null,
    'Username is invalid.': null,
    'Username is taken.': null,
    // Account - Change Password
    'Old password': null,
    'New password': null,
    'Confirm new password': null,
    'Change password': null,
    'Forgot password': null,
    // Auth
    "Please make sure your password:": null,
    "contains a digit": null,
    "contains uppercase and lowercase letters": null,
    "at least 8 characters": null,
    // Account Manage Notifications
    'New donations': null,
    'You will be notified every time a new donation is made to your page.': null,
    // 404
    "Sorry, this page isn't available.": null,
    "The link you followed may be broken, or the page may have been removed.": null,
    "Go back to FACE DONATE": null,
    'No internet connection': null,
    'Stop accepting cookies': null,
    'kg of CO2_plural': "kg of CO2",
    'adult_plural': "adults",
    'child_plural': "children",
    'animal_plural': "animals",
    'meal_plural': "meals",
    'class_plural': "classes",
    'shopping trip_plural': "shopping trips",

    // end
  },
};

export default dict;