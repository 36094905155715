import React from 'react';
// covert SVG here - https://react-svgr.com/

const CommentIcon = ({
  filled,
  color,
  ...props
}) => {
  // console.log(`HeartIcon started...`);


  if (filled) {
    return (
      <svg
        viewBox="0 0 24 20"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        width="1em"
        height="1em"
        {...props}
      >
        <path
          d="M11.762 20a1.176 1.176 0 01-.836-.341L1.78 10.5c-2.372-2.397-2.372-6.315 0-8.711 2.392-2.385 6.32-2.385 8.712 0l1.271 1.271 1.271-1.271c2.392-2.385 6.32-2.385 8.712 0 2.372 2.396 2.372 6.314 0 8.711l-9.147 9.159c-.223.22-.523.343-.836.341zM6.135 2.342a3.769 3.769 0 00-2.684 1.106 3.832 3.832 0 000 5.38l8.311 8.323 8.311-8.323a3.832 3.832 0 000-5.38 3.925 3.925 0 00-5.368 0l-2.107 2.12a1.179 1.179 0 01-1.672 0L8.82 3.447a3.769 3.769 0 00-2.684-1.106z"
          fill={color || "#000000"}
          fillRule="nonzero"
        />
      </svg>
    );
  }

  return (
    <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M16.9 4H7.1c-1.15 0-1.73.11-2.35.44-.56.3-1 .75-1.31 1.31C3.11 6.37 3 6.95 3 8.1v5.8c0 1.15.11 1.73.44 2.35.3.56.75 1 1.31 1.31l.15.07c.51.25 1.04.35 1.95.37h.25v2.21c0 .44.17.85.47 1.16l.12.1c.64.55 1.6.52 2.21-.08L13.37 18h3.53c1.15 0 1.73-.11 2.35-.44.56-.3 1-.75 1.31-1.31.33-.62.44-1.2.44-2.35V8.1c0-1.15-.11-1.73-.44-2.35a3.17 3.17 0 0 0-1.31-1.31A4.51 4.51 0 0 0 16.9 4zm-10 1.8h9.99c.88 0 1.18.06 1.5.23.25.13.44.32.57.57.17.32.23.62.23 1.5v6.16c-.02.61-.09.87-.23 1.14-.13.25-.32.44-.57.57-.32.17-.62.23-1.5.23h-4.02a.9.9 0 0 0-.51.26l-3.47 3.4V17.1c0-.5-.4-.9-.9-.9H6.74a2.3 2.3 0 0 1-1.14-.23 1.37 1.37 0 0 1-.57-.57c-.17-.32-.23-.62-.23-1.5V7.74c.02-.61.09-.87.23-1.14.13-.25.32-.44.57-.57.3-.16.58-.22 1.31-.23z"
          fill={color || "#000000"}
          fillRule="nonzero"
        />
      </g>
    </svg>
    
  )
}

export default CommentIcon;

