// covert SVG here - https://react-svgr.com/

const ProfileIcon = ({...props}) => {
  // console.log(`HeartIcon started...`);

  return (
    <svg
      viewBox="0 0 56 56"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M27.778 0c15.331 0 27.778 12.447 27.778 27.778S43.109 55.556 27.778 55.556 0 43.109 0 27.778 12.447 0 27.778 0zM43.9 43.066A22.147 22.147 0 0050 27.778c0-12.265-9.957-22.222-22.222-22.222S5.556 15.513 5.556 27.778c0 5.92 2.32 11.303 6.1 15.288 1.31-1.735 3.309-2.703 5.839-2.86a2.779 2.779 0 01.344 5.545c-.784.049-1.4.351-1.801.894A22.105 22.105 0 0027.778 50c4.308 0 8.332-1.229 11.739-3.355-.4-.543-1.017-.845-1.8-.894a2.78 2.78 0 01-2.601-2.944 2.78 2.78 0 012.944-2.601c2.531.157 4.529 1.125 5.84 2.86zm-16.014-.505c3.086-.061 5.829-1.508 8.025-3.879 2.935-3.168 4.875-8.052 5.286-12.941.727-8.635-3.169-17.036-13.357-17.039-10.174-.003-14.084 8.519-13.358 17.216.412 4.938 2.361 9.843 5.299 12.964 2.218 2.356 4.989 3.741 8.105 3.679zm-.093-4.721c-1.776.035-3.31-.852-4.574-2.195-2.285-2.428-3.711-6.279-4.031-10.12-.496-5.937 1.706-12.103 8.651-12.1 6.931.002 9.147 6.045 8.653 11.92-.322 3.814-1.756 7.656-4.045 10.128-1.287 1.389-2.847 2.331-4.654 2.367z"
        fill={props.color || "#000000"}
      />
    </svg>

  );
}

export default ProfileIcon;