import { useEffect, useState, useRef, useCallback } from 'react';
import { Pane, Text, Radio } from 'evergreen-ui';

const DynamicRadioButton = ({
  name,
  disabled,
  checked = false,
  value,
  onChange = () => {},
  label,
  ...rest }) => {

  return (
    
    <Pane aria-label={`Radio Group ${name}`} role={"group"}
      {...rest}
      // ref={ref}
      alignItems="center" display="flex" marginTop={0} marginBottom={0}
      height={24}
    >
      <Radio size={18} flex="none" marginLeft={8} marginRight={8} marginTop={0} marginBottom={0}
        name={name}
        disabled={disabled}
        checked={checked}
        value={value}
        // onChange={onChange}
        onChange={(event) => {
          event.persist(); // this makes the event value to go through
          onChange(event);
        }}
      />
      <Pane flex={1} marginTop={2} >
        <Text fontSize={16} color="#425A70">{label}</Text>
      </Pane>
    </Pane>

  );
}

export default DynamicRadioButton;