import { Fragment, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Table, Tablist, SidebarTab, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';

import DynamicInputField from './DynamicInputField';
import PickCurrency, { FlagByCurrency } from './PickCurrency';
import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useSelector } from 'react-redux';
import IBAN from 'iban';
import { I18n, API } from 'aws-amplify';
import * as queries from '../graphql/queries';

const BankCards = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("BankCards cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const {userDetails, userBankAccounts} = useMemo(() => {
    let currentUserDetails = userState.actAsUser || userState.user;
    let activeBankAccounts = currentUserDetails?.bankAccounts?.filter((bankAccount) => (bankAccount.type !== 'bank' && bankAccount.status === "active") );

    return {
      userDetails: currentUserDetails,
      userBankAccounts: activeBankAccounts,
    };

  }, [userState.actAsUser, userState.user]);

  const is425PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);
  const [accountUpdateError, setAccountUpdateError] = useState();


  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [selectedBankAccountIndex, setSelectedBankAccountIndex] = useState();
  const [updatedBankAccount, setUpdatedBankAccount] = useState();
  
  const [provideIban, setProvideIban] = useState(false);
  const [bankAccountIbanInvalidMessage, setBankAccountIbanInvalidMessage] = useState();

  const handleUpdateSelectBankAccount = (payload) => {
    let temp = updatedBankAccount || {};
    setUpdatedBankAccount({
      ...temp,
      ...payload,
    });
  }

  const handleValidateAccountIban = (e) => {
    if (updatedBankAccount.iban) {
      let temp = updatedBankAccount.iban.trim();
      if (!IBAN.isValid(temp)) {
        setBankAccountIbanInvalidMessage(I18n.get("IBAN is not a valid."));
      }
      else {
        setBankAccountIbanInvalidMessage();
      }
    }
  }

  const someAttributesAreInvalid = useMemo(() => {
    if (!updatedBankAccount ||
      !updatedBankAccount.currency ||
      !updatedBankAccount.holderName ||
      (provideIban && !updatedBankAccount.iban) ||
      (!provideIban && !updatedBankAccount.accountNumber) ||
      (!provideIban && !updatedBankAccount.sortCode)
      || (provideIban && !IBAN.isValid(updatedBankAccount.iban))
    ){
      return true;
    }
    return false;
  }, [provideIban, updatedBankAccount]);

  const handleSelectAccount = (bankAccount, index) => {
    if (!bankAccount) {
      setSelectedBankAccount();
      setSelectedBankAccountIndex();
      setUpdatedBankAccount();
    }
    else {
      setSelectedBankAccount(bankAccount);
      setSelectedBankAccountIndex(index);
      setUpdatedBankAccount(bankAccount);
    }
    
  };

  
  return (
    <Fragment>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get("Payment methods")}</Strong>
        {processing ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
        <Button marginLeft={"auto"} flex="none" marginRight={8} justifyContent="center" paddingX={12} height={30}
          onClick={() => {
            // add new payment method
            // setSelectedBankAccount({
            //   currency: localeState.currency,
            //   type: "paymentMethod",
            //   role: "card",
            // });
            // setProvideIban(localeState.currency === "gbp");
          }}
        >
          {I18n.get("Add new payment method")}
        </Button>
      </Pane>
      {/* <Pane marginTop={4}>
        <Paragraph fontSize={14} color="#7B8B9A">{I18n.get("xxx.")}</Paragraph>
      </Pane> */}

      <Pane marginTop={10} >
        
          {!userBankAccounts?.length > 0 ?
            <Fragment>
              <Pane textAlign="center" marginTop={20} className="noselect" >
                <Strong fontSize={14} color="#7B8B9A">{I18n.get("No payment method is set up.")}</Strong>
              </Pane>
              <Pane paddingX={10} textAlign="center" marginTop={0} >
                <Text lineHeight={0.8} fontSize={14} color="#7B8B9A" >{I18n.get("Add new payment method to use it on your next transaction.")}</Text>
              </Pane>
            </Fragment>
          :
            <Table >
              <Table.Body >
                {userBankAccounts.map((bankAccount, index, array) => {
                  if (!bankAccount.id) {
                    return null;
                  }
                  const accountNumberToDisplay = `•••• ${bankAccount.iban?.substr(bankAccount.iban.length - 4) || bankAccount.accountNumber?.substr(bankAccount.accountNumber.length - 4)  || bankAccount.cardLast4}`;
                  const accountTypeToDisplay = bankAccount.cardBrand ? `${bankAccount.cardBrand.charAt(0).toUpperCase() + bankAccount.cardBrand.slice(1)}` : null;
                  const accountExpiresToDisplay = bankAccount.cardExpMonth && bankAccount.cardExpYear ? `${bankAccount.cardExpMonth}/${bankAccount.cardExpYear}` : null;
                  
                  return (
                    <Table.Row key={bankAccount.id} minHeight={40} height="auto" style={{border: "none"}} display="block" >
                      {bankAccount.id !== selectedBankAccount?.id ?
                        <Pane width="100%" minHeight={30} display="flex" alignItems="center" flexWrap="wrap">
                          {!bankAccount.currency ? null :
                            <Pane width={32} display="flex" border borderColor="#F9F9FB" borderRadius={3} marginRight={20}>
                              <FlagByCurrency currency={bankAccount.currency} />
                            </Pane>
                          }
                          {!accountTypeToDisplay ? null :
                            <Pane marginRight={20} >
                              <Strong fontSize={16} color="#283655" >{accountTypeToDisplay}</Strong>
                            </Pane>
                          }
                          <Pane marginRight={20} >
                            <Strong fontSize={16} color="#283655" >{accountNumberToDisplay}</Strong>
                          </Pane>
                          {accountExpiresToDisplay ?
                            <Pane marginRight={20} >
                              <Strong fontSize={16} color="#283655" >{accountExpiresToDisplay}</Strong>
                            </Pane>
                          : null }
                          {bankAccount.id === selectedBankAccount?.id || updatedBankAccount ? null :
                            <Button marginLeft={"auto"} flex="none" marginRight={8} justifyContent="center" paddingX={12} height={30}
                              onClick={() => {
                                handleSelectAccount(bankAccount, index);
                              }}
                            >
                              {I18n.get("Edit")}
                            </Button>
                          }
                        </Pane>
                      :
                      
                        <Pane marginTop={20} width="100%" >
                          
                          {bankAccount.type === "bank" ? 
                            <Fragment>

                              {!updatedBankAccount.currency ? null :
                                <Pane marginBottom={20} alignItems="center" display="flex" flexWrap="wrap" >
                                  <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} >
                                    <Strong htmlFor={"Account currency"} fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get("Account currency")}</Strong>
                                  </Pane>
                                  <Pane flex={1} flexBasis={242} maxWidth={390} >
                                    <PickCurrency hideLabel={true} width={32}
                                      onSelect={(currency) => {
                                        console.log("PickCurrency onSelect ...");
                                        handleUpdateSelectBankAccount({
                                          currency: currency,
                                        });
                                        if (currency !== "gbp") {
                                          setProvideIban(true);
                                        }
                                        else if (updatedBankAccount.accountNumber) {
                                          setProvideIban(false);
                                        }
                                      }}
                                      selectedCurrency={updatedBankAccount.currency}
                                    />
                                  </Pane>
                                </Pane>
                              }

                              <DynamicInputField label={I18n.get("Holder name")} inputName="Holder name" type="text" disabled={processing} placeholder={"Holder name..."}
                                value={updatedBankAccount.holderName}
                                onChange={(value) => {
                                  handleUpdateSelectBankAccount({
                                    holderName: value,
                                  });
                                }}
                                // messageWhenInvalid={null} onBlur={() => {}}
                              />
                              
                              {provideIban ? 
                                <DynamicInputField label={I18n.get("IBAN")} inputName="IBAN" type="text" disabled={processing} placeholder={"IBAN..."}
                                  value={updatedBankAccount.iban}
                                  onChange={(value) => {
                                    setBankAccountIbanInvalidMessage();
                                    let temp = value?.trim();
                                    handleUpdateSelectBankAccount({
                                      iban: temp,
                                    });
                                  }}
                                  messageWhenInvalid={bankAccountIbanInvalidMessage}
                                  onBlur={handleValidateAccountIban}
                                />
                              :
                                <Fragment>
                                  <DynamicInputField label={I18n.get("Sort code")} inputName="Sort code" format="##-##-##" type="text" disabled={processing} placeholder={"Sort code..."}
                                    value={updatedBankAccount.sortCode}
                                    onChange={(value) => {
                                      handleUpdateSelectBankAccount({
                                        sortCode: value,
                                      });
                                    }}
                                    // messageWhenInvalid={null} onBlur={() => {}}
                                  />
                                  <DynamicInputField label={I18n.get("Account number")} inputName="Account number" format="########" type="text" disabled={processing} placeholder={"Account number..."}
                                    value={updatedBankAccount.accountNumber}
                                    onChange={(value) => {
                                      handleUpdateSelectBankAccount({
                                        accountNumber: value,
                                      });
                                    }}
                                    // messageWhenInvalid={null} onBlur={() => {}}
                                  />
                                </Fragment>
                              }
                            </Fragment>
                          : null }

                          {bankAccount.type === "paymentMethod" && bankAccount.role === "card" ?
                            <Fragment>
                              <DynamicInputField label={I18n.get("Card type")} inputName="Card type" type="text" disabled={true} placeholder={`${I18n.get("Card type")}...`}
                                value={accountTypeToDisplay}
                                onChange={(value) => {}}
                                // messageWhenInvalid={null} onBlur={() => {}}
                              />
                              <DynamicInputField label={I18n.get("Card number")} inputName="Card number" type="text" disabled={true} placeholder={`${I18n.get("Card number")}...`}
                                value={accountNumberToDisplay}
                                onChange={(value) => {}}
                                // messageWhenInvalid={null} onBlur={() => {}}
                              />
                              <DynamicInputField label={I18n.get("Card expires")} inputName="Card expires" type="text" disabled={true} placeholder={`${I18n.get("Card expires")}...`}
                                value={accountExpiresToDisplay}
                                onChange={(value) => {}}
                                // messageWhenInvalid={null} onBlur={() => {}}
                              />
                            </Fragment>
                          : null }

                          {updatedBankAccount.currency !== "gbp" ? null :
                            <Pane marginBottom={20} alignItems="center" display="flex" flexWrap="wrap" >
                              <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} ></Pane>
                              <Pane flex={1} flexBasis={242} maxWidth={390} >
                                <Button justifyContent="center" paddingX={12} height={30}
                                  onClick={() => {setProvideIban(!provideIban)}}
                                >
                                  {provideIban ? I18n.get("Provide Sort code and Account Number") : I18n.get("Provide IBAN")}
                                </Button>
                              </Pane>
                            </Pane>
                          }
                          
                          <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
                            <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>
                            <Pane flex={1} flexBasis={242} maxWidth={390} >
                              {processing ?
                                <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
                                  <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                                </Pane>
                              :
                                <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

                                  {bankAccount.role === "card" ?
                                    <Pane flex="none" marginRight={36} >
                                      <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-red"
                                        disabled={false}
                                        onClick={() => {
                                          setProcessing(true);
                                          let newBankAccountAttributes = {
                                            ...updatedBankAccount,
                                            status: "deleted",
                                          };
                                          console.log("newBankAccountAttributes", newBankAccountAttributes);
                                          let refreshBankAccounts = userDetails.bankAccounts;
                                          refreshBankAccounts[selectedBankAccountIndex].status = "deleted";
                                          refreshBankAccounts.splice(selectedBankAccountIndex, 1);
                                          handleUpdateUserDetails({
                                            userDetailsToUpdate: {
                                              bankAccounts: [newBankAccountAttributes],
                                            },
                                            onSuccess: (freshUserDetails) => {
                                              handleSelectAccount();
                                              setProcessing(false);
                                            },
                                            onError: (error) => {
                                              console.error('Error: ', error);
                                              setAccountUpdateError(error);
                                              setProcessing(false);
                                            },
                                          });
                                        }}
                                      >
                                        {I18n.get("Remove this card")}
                                      </Button>
                                    </Pane>
                                  :
                                    <Pane flex="none" marginRight={36} >
                                      <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                                        disabled={_.isEqual(selectedBankAccount, updatedBankAccount) || someAttributesAreInvalid || processing}
                                        onClick={() => {
                                          // setProcessing(true);
                                          // let newBankAccountAttributes = {
                                          //   ...updatedBankAccount,
                                          //   type: "bank",
                                          //   iban: provideIban ? updatedBankAccount.iban : null,
                                          //   sortCode: !provideIban ? updatedBankAccount.sortCode : null,
                                          //   accountNumber: !provideIban ? updatedBankAccount.accountNumber : null,
                                          // };
                                          // console.log("newBankAccountAttributes", newBankAccountAttributes);
                                          // handleUpdateUserDetails({
                                          //   userDetailsToUpdate: {
                                          //     bankAccounts: [newBankAccountAttributes],
                                          //   },
                                          //   onSuccess: () => {
                                          //     handleSelectAccount();
                                          //     setProcessing(false);
                                          //   },
                                          //   onError: (error) => {
                                          //     console.error('Error: ', error);
                                          //     setAccountUpdateError(error);
                                          //     setProcessing(false);
                                          //   },
                                          // });
                                        }}
                                      >
                                        {I18n.get("Save")}
                                      </Button>
                                    </Pane>
                                  }

                                  <Pane flex={1} >
                                    <Button justifyContent="center" paddingX={12} height={30}
                                      disabled={false}
                                      onClick={() => {
                                        handleSelectAccount();
                                      }}
                                    >
                                      {bankAccount.role === "card" ? I18n.get("Keep this card") : I18n.get("Cancel")}
                                    </Button>
                                  </Pane>

                                  {/* <Pane flex="none" textAlign="right" >
                                    <Button style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={30} appearance="minimal"
                                      disabled={false}
                                      onClick={() => {
                                        // ???
                                        setSelectedBankAccount();
                                      }}
                                    >
                                      {I18n.get("Remove account")}
                                    </Button>
                                  </Pane> */}

                                </Pane>
                              }
                            </Pane>
                          </Pane>

                        </Pane>
                      }

                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          }

        <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        
      </Pane>
    </Fragment>
  );
}

const BankAccounts = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("BankAccounts cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const is425PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processingBankAccountDetails, setProcessingBankAccountDetails] = useState(false);
  const [accountUpdateError, setAccountUpdateError] = useState();

  const userBankAccounts = useMemo(() => {
    return userDetails.bankAccounts?.filter(bankAccount => bankAccount.type === 'bank') || [];
  }, [userDetails.bankAccounts]);

  const [selectedBankAccount, setSelectedBankAccount] = useState();

  const [updatedBankAccount, setUpdatedBankAccount] = useState();

  useEffect(() => {
    setUpdatedBankAccount(selectedBankAccount);
  }, [selectedBankAccount]);
  
  const [provideIban, setProvideIban] = useState(false);
  const [bankAccountIbanInvalidMessage, setBankAccountIbanInvalidMessage] = useState();

  const handleUpdateSelectBankAccount = (payload) => {
    let temp = updatedBankAccount || {};
    setUpdatedBankAccount({
      ...temp,
      ...payload,
    });
  }

  const handleValidateAccountIban = (e) => {
    if (updatedBankAccount.iban) {
      let temp = updatedBankAccount.iban.trim();
      if (!IBAN.isValid(temp)) {
        setBankAccountIbanInvalidMessage(I18n.get("IBAN is not a valid."));
      }
      else {
        setBankAccountIbanInvalidMessage();
      }
    }
  }

  const someAttributesAreInvalid = useMemo(() => {
    if (!updatedBankAccount ||
      !updatedBankAccount.currency ||
      !updatedBankAccount.holderName ||
      (provideIban && !updatedBankAccount.iban) ||
      (!provideIban && !updatedBankAccount.accountNumber) ||
      (!provideIban && !updatedBankAccount.sortCode)
      || (provideIban && !IBAN.isValid(updatedBankAccount.iban))
    ){
      return true;
    }
    return false;
  }, [provideIban, updatedBankAccount]);

  


  const renderBankAccountRow = useCallback(({ current, index, array }) => {
    
    if (!current.id) {
      return null;
    }
  
    const accountNumberToDisplay = current.iban || current.accountNumber;

    return (

      <Table.Row key={current.id} minHeight={40} height="auto" style={{border: "none"}} >
        <Pane width="100%" display="flex" alignItems="center" flexWrap="wrap">
          <Pane width={32} display="flex" border borderColor="#F9F9FB" borderRadius={3}>
            <FlagByCurrency currency={current.currency} />
          </Pane>
          <Pane marginLeft={20} >
            <Strong fontSize={16} color="#283655" >{`••••${accountNumberToDisplay.substr(accountNumberToDisplay.length - 4)}`}</Strong>
          </Pane>
          <Pane marginLeft={"auto"} flex="none" marginRight={8}>
            <Button justifyContent="center" paddingX={12} height={30}
              onClick={() => {
                const currentAccount = userBankAccounts.find(bankAccount => bankAccount.id === current.id);
                setSelectedBankAccount(currentAccount);
              }}
            >
              {I18n.get("Edit")}
            </Button>
          </Pane>
        </Pane>
      </Table.Row>

    );

  }, [userBankAccounts]);


  return (
    <Fragment>

      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get("Bank accounts")}</Strong>
        {processingBankAccountDetails ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
      </Pane>
      <Pane marginTop={4}>
        <Paragraph fontSize={14} color="#7B8B9A">{I18n.get("This is needed for us to make payouts to you.")}</Paragraph>
      </Pane>

      <Pane marginTop={10} >
        
        {!updatedBankAccount ?
          userBankAccounts?.length > 0 ?
            <Table >
              <Table.Body >
                {userBankAccounts.map((bankAccount, index, array) => (
                  renderBankAccountRow({
                    current: bankAccount,
                    index: index,
                    array: array,
                  })
                ))}
              </Table.Body>
            </Table>
          :
            <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                  disabled={false}
                  onClick={() => {
                    setSelectedBankAccount({
                      currency: localeState.currency,
                    });
                    setProvideIban(localeState.currency === "gbp");
                  }}
                >
                  {I18n.get("Add bank account")}
                </Button>
              </Pane>
            </Pane>
        :
          <Pane marginTop={20} >
            <Pane marginBottom={20} alignItems="center" display="flex" flexWrap="wrap" >
              <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} >
                <Strong htmlFor={"Account currency"} fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get("Account currency")}</Strong>
              </Pane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <PickCurrency hideLabel={true} width={32}
                  onSelect={(currency) => {
                    console.log("PickCurrency onSelect ...");
                    handleUpdateSelectBankAccount({
                      currency: currency,
                    });
                    if (currency !== "gbp") {
                      setProvideIban(true);
                    }
                    else if (updatedBankAccount.accountNumber) {
                      setProvideIban(false);
                    }
                  }}
                  selectedCurrency={updatedBankAccount.currency}
                />
              </Pane>
            </Pane>

            <DynamicInputField label={I18n.get("Holder name")} inputName="Holder name" type="text" disabled={processingBankAccountDetails} placeholder={"Holder name..."}
              value={updatedBankAccount.holderName}
              onChange={(value) => {
                handleUpdateSelectBankAccount({
                  holderName: value,
                });
              }}
              // messageWhenInvalid={null} onBlur={() => {}}
            />
            
            {provideIban ? 
              <DynamicInputField label={I18n.get("IBAN")} inputName="IBAN" type="text" disabled={processingBankAccountDetails} placeholder={"IBAN..."}
                value={updatedBankAccount.iban}
                onChange={(value) => {
                  setBankAccountIbanInvalidMessage();
                  let temp = value?.trim();
                  handleUpdateSelectBankAccount({
                    iban: temp,
                  });
                }}
                messageWhenInvalid={bankAccountIbanInvalidMessage}
                onBlur={handleValidateAccountIban}
              />
            :
              <Fragment>
                <DynamicInputField label={I18n.get("Sort code")} inputName="Sort code" format="##-##-##" type="text" disabled={processingBankAccountDetails} placeholder={"Sort code..."}
                  value={updatedBankAccount.sortCode}
                  onChange={(value) => {
                    handleUpdateSelectBankAccount({
                      sortCode: value,
                    });
                  }}
                  // messageWhenInvalid={null} onBlur={() => {}}
                />
                <DynamicInputField label={I18n.get("Account number")} inputName="Account number" format="########" type="text" disabled={processingBankAccountDetails} placeholder={"Account number..."}
                  value={updatedBankAccount.accountNumber}
                  onChange={(value) => {
                    handleUpdateSelectBankAccount({
                      accountNumber: value,
                    });
                  }}
                  // messageWhenInvalid={null} onBlur={() => {}}
                />
              </Fragment>
            }

            {updatedBankAccount.currency !== "gbp" ? null
            :
              <Pane marginBottom={20} alignItems="center" display="flex" flexWrap="wrap" >
                <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} ></Pane>
                <Pane flex={1} flexBasis={242} maxWidth={390} >
                  <Button justifyContent="center" paddingX={12} height={30}
                    onClick={() => {setProvideIban(!provideIban)}}
                  >
                    {provideIban ? I18n.get("Provide Sort code and Account Number") : I18n.get("Provide IBAN")}
                  </Button>
                </Pane>
              </Pane>
            }
            
            <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
              <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                {processingBankAccountDetails ?
                  <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
                    <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                  </Pane>
                :
                  <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">
                    <Pane flex="none" marginRight={36} >
                      <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                        disabled={_.isEqual(selectedBankAccount, updatedBankAccount) || someAttributesAreInvalid || processingBankAccountDetails}
                        onClick={() => {
                          setProcessingBankAccountDetails(true);
                          let newBankAccountAttributes = {
                            ...updatedBankAccount,
                            type: "bank",
                            iban: provideIban ? updatedBankAccount.iban : null,
                            sortCode: !provideIban ? updatedBankAccount.sortCode : null,
                            accountNumber: !provideIban ? updatedBankAccount.accountNumber : null,
                          };
                          console.log("newBankAccountAttributes", newBankAccountAttributes);
                          handleUpdateUserDetails({
                            userDetailsToUpdate: {
                              bankAccounts: [newBankAccountAttributes],
                            },
                            onSuccess: () => {
                              setSelectedBankAccount();
                              setProcessingBankAccountDetails(false);
                            },
                            onError: (error) => {
                              console.error('Error: ', error);
                              setAccountUpdateError(error);
                              setProcessingBankAccountDetails(false);
                            },
                          });
                        }}
                      >
                        {I18n.get("Save")}
                      </Button>
                    </Pane>

                    <Pane flex={1} >
                      <Button justifyContent="center" paddingX={12} height={30}
                        disabled={false}
                        onClick={() => {
                          setSelectedBankAccount();
                        }}
                      >
                        {I18n.get("Cancel")}
                      </Button>
                    </Pane>

                    {/* <Pane flex="none" textAlign="right" >
                      <Button style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={30} appearance="minimal"
                        disabled={false}
                        onClick={() => {
                          // ???
                          setSelectedBankAccount();
                        }}
                      >
                        {I18n.get("Remove account")}
                      </Button>
                    </Pane> */}

                  </Pane>
                }
              </Pane>
            </Pane>
          </Pane>
        }

        <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        
      </Pane>
    </Fragment>
  );
}

const AccountManagePayments = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountManagePayments - ${countRef.current}`);
    return () => {
      console.log("AccountManagePayments - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const userState = useSelector(state => state.userState);
  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  return (
    <Fragment>
      <Pane marginTop={16}>
        <BankCards />
      </Pane>

      {userDetails.tags.filter(tag => ["charity", "retailer"].includes(tag)).length > 0 ?
        <Pane marginTop={16} >
          <BankAccounts />
        </Pane>
      : null }
      
    </Fragment>
  );

}

export default AccountManagePayments;