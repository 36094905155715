import { Fragment, cloneElement, useMemo, useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tooltip, InfoSignIcon, WidgetButtonIcon, EditIcon, Radio, Checkbox, ChevronLeftIcon, PlusIcon, MinusIcon, CameraIcon, KeyDeleteIcon, SidebarTab, CrossIcon, TickIcon, IconButton, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';
import FacePH from '../images/FacePH';
import ReceiptPH from '../images/ReceiptPH';
import CameraComponent from './CameraComponent';
import DialogHeader from './DialogHeader';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';

import ModalResponsive from '../components/ModalResponsive';

import QrcodeComponent from './QrcodeComponent';
import BarCodeComponent from './BarCodeComponent';
import NumberPadComponent from './NumberPadComponent';

import PageDrawer from './PageDrawer';

import TakePicture from './TakePicture';
import ShareOptions from './ShareOptions';
import TransactionMoreDialog from './TransactionMoreDialog';

import { triggerBase64Download } from 'react-base64-downloader';
import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PickCurrency from './PickCurrency';
import Repeatable from 'react-repeatable';
import { useLongPress } from 'use-long-press';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails, setDialogState, updateDialogState } from '../services/actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import styled, { StyledFunction } from "styled-components";
import { GridContextProvider, GridDropZone, GridItem, swap, move } from "react-grid-dnd";
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import CountUp, { useCountUp } from 'react-countup';

import useHover from "../services/useHover";
import useAccessTransaction from "../services/useAccessTransaction";
import useUploadImage from "../services/useUploadImage";
import { currencies } from '../services/localeDictionary';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';


const drawBoundingBoxToFace = (imageB64, faceBoundingBox) => {
  //SearchedFaceBoundingBox; // https://docs.aws.amazon.com/rekognition/latest/dg/images-displaying-bounding-boxes.html

  if (faceBoundingBox) {
    let base64StringFull = null;

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext("2d");
    const image = new Image();   // Create new img element
    image.src = imageB64;

    return new Promise((resolve, reject) => {
      
      // image.onload = () => {
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   let xLeft = faceBoundingBox.Left * image.width - 28;
      //   let yTop = faceBoundingBox.Top * image.height - 28;
      //   let faceW = faceBoundingBox.Width * image.width + 28;
      //   let faceH = faceBoundingBox.Height * image.height + 28;
      //   ctx.drawImage(image, 0, 0);
      //   ctx.beginPath();
      //   ctx.moveTo(xLeft + 10, yTop);
      //   ctx.lineTo(xLeft + faceW, yTop);
      //   ctx.quadraticCurveTo(xLeft + faceW + 10, yTop, xLeft + faceW + 10, yTop + 10);
      //   ctx.lineTo(xLeft + faceW + 10, yTop + faceH);
      //   ctx.quadraticCurveTo(xLeft + faceW + 10, yTop + faceH + 10, xLeft + faceW, yTop + faceH + 10);
      //   ctx.lineTo(xLeft + 10, yTop + faceH + 10);
      //   ctx.quadraticCurveTo(xLeft, yTop + faceH + 10, xLeft, yTop + faceH);
      //   ctx.lineTo(xLeft, yTop + 10);
      //   ctx.quadraticCurveTo(xLeft, yTop, xLeft + 10, yTop);
      //   ctx.strokeStyle = "#FF9C8F";
      //   ctx.lineWidth = 10;
      //   ctx.stroke();
      //   base64StringFull = canvas.toDataURL("image/png", 1);
      //   resolve(base64StringFull);
      // }
      image.onerror = reject;
      // image.crossOrigin = "anonymous"; ???

      canvas.width = image.width;
      canvas.height = image.height;
      let xLeft = faceBoundingBox.Left * image.width - 28;
      let yTop = faceBoundingBox.Top * image.height - 28;
      let faceW = faceBoundingBox.Width * image.width + 28;
      let faceH = faceBoundingBox.Height * image.height + 28;
      ctx.drawImage(image, 0, 0);
      ctx.beginPath();
      ctx.moveTo(xLeft + 10, yTop);
      ctx.lineTo(xLeft + faceW, yTop);
      ctx.quadraticCurveTo(xLeft + faceW + 10, yTop, xLeft + faceW + 10, yTop + 10);
      ctx.lineTo(xLeft + faceW + 10, yTop + faceH);
      ctx.quadraticCurveTo(xLeft + faceW + 10, yTop + faceH + 10, xLeft + faceW, yTop + faceH + 10);
      ctx.lineTo(xLeft + 10, yTop + faceH + 10);
      ctx.quadraticCurveTo(xLeft, yTop + faceH + 10, xLeft, yTop + faceH);
      ctx.lineTo(xLeft, yTop + 10);
      ctx.quadraticCurveTo(xLeft, yTop, xLeft + 10, yTop);
      ctx.strokeStyle = "#FF9C8F";
      ctx.lineWidth = 8;
      ctx.stroke();
      base64StringFull = canvas.toDataURL("image/png", 1);
      resolve(base64StringFull);
      
    });

    // return base64StringFull;
    
  }
  else {
    return imageB64;
  }

}

const CameraView = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`CameraView - ${countRef.current}`);
    return () => {
      console.log("CameraView - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  const dispatch = useDispatch();
  
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);
  
  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const cameraRef = useRef(null);
  const [processing, setProcessing] = useState(false);

  const handleOnChange = useCallback(({ pictureB64, qrCodeData }) => {
    dispatch(updateDialogState({
      qrCodeDataToProcess: qrCodeData,
      pictureB64ToProgress: pictureB64
    }));
  }, [dispatch]);

  const handleCapturedCameraData = useCallback(async({ pictureB64, qrCodeData }) => {

    setProcessing(true);

    // console.log("handleCapturedCameraData");
    // async function wait(ms) {
    //   return new Promise(resolve => {
    //     setTimeout(resolve, ms);
    //   });
    // }

    // console.log("wait", 0);
    // await wait(5000);
    // console.log("wait", 5000);
    // dispatch(setDialogState(null)); // this will close the dialog
    // return null;

    if (qrCodeData) {
      
      function domain_from_url(url) {
        let result;
        let match;
        if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im))) {
            result = match[1]
            if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
                result = match[1]
            }
        }
        return result;
      }

      try {
        const domain = domain_from_url(qrCodeData);
        // console.log("domain", domain);

        if (domain && domain.toLowerCase() === "facedonate.org") {

          function getMatchDetails(path, uri) {
            // start: false makes parsing of optional params work
            const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
            const matchDetails = matchPath(uri);
            return matchDetails;
          }

          // async function wait(ms) {
          //   return new Promise(resolve => {
          //     setTimeout(resolve, ms);
          //   });
          // }
          // await wait(10000);
          // console.log("DONE WAITING");
    
          let matchDetails = null;
          if ((matchDetails = getMatchDetails(`/:domain(${domain})/:page(qr)/:mode(p|r)/:publicTransactionId/:accessCode`, qrCodeData))) {
            console.log("matchDetails", matchDetails);
            const params = matchDetails.params;
            
            if (params.mode === "p") {
              
              // const { data, error } = handleAccessTransaction({
              //   publicTransactionId: params.publicTransactionId,
              //   accessCode: params.accessCode,
              // });
              // if (error) {
              //   // handle error
              // }
              // else {
              //   console.log("data", data)
              //   // combine this dispatch with the below dispatch ???
              //   batch(() => {
              //     dispatch(setProfileDetails(tempRecipient));
              //     dispatch(updateTransactionDetails(tempTransaction));
              //   });

              //   dispatch(updateTransactionDetails({
              //     ???: "ViewNotRecognisedFace",
              //   }));

              //   history.replace({
              //     pathname: generatePath("/:locale?/:handle", {...match.params, handle: tempRecipient.username}),
              //     // state: {}
              //   });
              // }

              dispatch(updateDialogState({
                qrCodeDataToProcess: null
              }));
              
            }
            else if (params.mode === "r") {
              // process request for payment ???

              dispatch(updateDialogState({
                qrCodeDataToProcess: null
              }));
            }

          }
          else if ((matchDetails = getMatchDetails(`/:domain(${domain})/:rest(.*)?`, qrCodeData))) {
            // go to path, wherever it takes on the domain...
            console.log("qrCodeData", qrCodeData);
            console.log("matchDetails", matchDetails);
            const params = matchDetails.params;

            dispatch(setDialogState()); // this will close the dialog

            history.push(`/${params.rest}`);
          }
          else {
            // QR code is process... no action recognised
            dispatch(updateDialogState({
              qrCodeDataToProcess: null
            }));
          }
        }
        setProcessing(false);
      }
      catch(error) {
        console.error("with process QR code data", error);
        dispatch(updateDialogState({
          qrCodeDataToProcess: null
        }));
        setProcessing(false);
      }
    }
    
    if (pictureB64) {

      try {

        const definedProps = obj => Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v)
        );

        // generate transaction if no existing
        if (transactionDetails.status === "auth") {
          
          if (!transactionDetails.publicTransactionId) {
            let toPublicFaceId = null;
            let fromPublicFaceId = null;
            
            switch (transactionDetails.type) {
              case "paymentIntent":
                toPublicFaceId = null;
                fromPublicFaceId = userDetails?.publicFaceId;
                break;
              case "provisionIntent": // not sure what this is for ???
                toPublicFaceId = null;
                fromPublicFaceId = userDetails?.publicFaceId;
                break;

              default:
                // send to error view ?
                break;
            }
            const response = await API.graphql({
              query: mutations.generateTransaction,
              variables: {
                toPublicFaceId: toPublicFaceId,
                fromPublicFaceId: fromPublicFaceId,
                type: transactionDetails.type,
                amount: transactionDetails.amount,
                currency: userDetails?.balance.currency, // ???
                faceB46: pictureB64,
              },
              authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
            });

            let generatedTransaction = response.data.generateTransaction;
            // console.log("generatedTransaction", generatedTransaction);

            batch(() => {
              dispatch(updateTransactionDetails({
                ...definedProps(generatedTransaction),
              }));
              dispatch(setDialogState());
            });

          }
          else {
            // not sure ???
            const response = await API.graphql({
              query: mutations.accessTransaction,
              variables: {
                publicTransactionId: transactionDetails.publicTransactionId,
                accessCode: transactionDetails.accessCode,
                faceB46: pictureB64, // improvement ???
              },
              authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
            });
            const accessedTransaction = response.data.accessTransaction;
            
            // console.log("accessedTransaction", accessedTransaction);
            // transaction is updated via subscription // ???
            // dispatch(updateTransactionDetails(accessedTransaction)); // ???
          }

        }
        else {
          
          const response = await API.graphql({
            query: mutations.recogniseFace,
            variables: {
              faceB46: pictureB64,
              currency: localeState.currency,
              // locale: null,
            },
            authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
          });
          console.log("recogniseFace response", response.data.recogniseFace);
          let faceDetails = response.data.recogniseFace;

          if (!faceDetails) {
            // handle is nothing is return ???
            // retry ???
            setProcessing(false);
            return null;
          }
          
          dispatch(setDialogState()); // this will close the dialog

          if (faceDetails.status === "new") { // ???
            // console.log("go to try again");
            // dispatch(updateTransactionDetails({
            //   // ???: "ViewNotRecognisedFace",
            //   localPicture: await drawBoundingBoxToFace(value.pictureB64, tempRecipient.objectDetails.SearchedFaceBoundingBox),
            // }));
          }
          else if (faceDetails.status === "readyToRegister") { // ???
            // console.log("go to register a new Face");
            // dispatch(updateTransactionDetails({
            //   // ???: "ViewRegisterNewFace",
            //   localPicture: await drawBoundingBoxToFace(value.pictureB64, tempRecipient.objectDetails.SearchedFaceBoundingBox),
            // }));
          }
          else {
            // go to found Profile page
            history.push({
              pathname: generatePath("/:locale?/:handle", {...match.params, handle: faceDetails.username}),
              // state: {}
            });
          }
        }
        dispatch(updateDialogState({
          pictureB64ToProgress: null
        }));
        setProcessing(false);
      }
      catch (error) {
        console.error("within generateTransaction or recogniseFace:", error);
        dispatch(updateDialogState({
          pictureB64ToProgress: null,
        }));
        dispatch(updateTransactionDetails({
          localError: error,
        }));
        setProcessing(false);
      }
    }

  }, [dispatch, history, localeState.currency, match.params, transactionDetails.accessCode, transactionDetails.amount, transactionDetails.publicTransactionId, transactionDetails.status, transactionDetails.type, userDetails]);
  
  // trigger handleCapturedCameraData
  useEffect((() => {
    if (dialogState.pictureB64ToProgress || dialogState.qrCodeDataToProcess) {
      handleCapturedCameraData({
        pictureB64: dialogState.pictureB64ToProgress,
        qrCodeData: dialogState.qrCodeDataToProcess,
      });
    }
  }), [dialogState.pictureB64ToProgress, dialogState.qrCodeDataToProcess, handleCapturedCameraData]);

  const handleClose = useCallback(() => {
    batch(() => {

      if (transactionDetails.type === "paymentIntent"){
        dispatch(setTransactionDetails());
      }

      dispatch(setDialogState()); // this will close the dialog

      if (match.params.page === "camera") {
        history.push({
          pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
          // search: "",
          // state: state,
        });
      }

    });
    
  }, [dispatch, history, match.params.page, transactionDetails.type]);

  return (
    <Pane padding={10}>

      <DialogHeader
        headerText={transactionDetails.status === "auth" ? I18n.get('Face ID') : I18n.get('Camera')}
        hideLeftButton={processing}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        leftButtonIcon={null}
        onLeftButtonClick={handleClose}
        // rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      {transactionDetails.status === "auth" ? 
        <Fragment>
          <Pane marginTop={10} textAlign="center" >
            <Strong fontSize={16} color="#283655">{I18n.get('Take a picture of your face to proceed.')}</Strong>
          </Pane>
          <Pane textAlign="center" marginTop={4} >
            <Text fontSize={14} color={"#7B8B9A"} >{I18n.get('The process is 100% save and secure.')}</Text>
          </Pane>
        </Fragment>
      : null }

      <Pane marginTop={10} borderRadius={5} background="#E4E7EB" >
        <CameraComponent
          ref={cameraRef}
          isOn={true}
          isUserFacing={dialogState?.params?.localCameraIsUserFacing}
          scanQrCodes={transactionDetails.status === "auth" ? false : true}
          scanQrBox={transactionDetails.status === "auth" ? false : true}
          ratio={transactionDetails.status === "auth" ? 1/1 : 4/5}
          dict={{
            'Open camera': I18n.get('Open camera'),
            'Retake': I18n.get('Retake'),
            'Use': I18n.get('Use photo'),
          }}
          placeholderIsShown={(transactionDetails.status === "auth")} 
          showButtons={true}
          showTakenPicture={true}
          isProcessing={processing}
          showRetakeButton={false}
          onChange={handleOnChange}
          maxPictureWidth={1350}
          maxPictureHeight={1350}
          onClose={handleClose}
          showSystemParams={match.params.page === "system"}
        />
      </Pane>

    </Pane>
  );
}

const LinkFace = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`LinkFace - ${countRef.current}`);
    return () => {
      console.log("LinkFace - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  // const profileState = useSelector(state => state.profileState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  const dialogState = useSelector(state => state.dialogState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const cameraRef = useRef(null);
  const [processing, setProcessing] = useState(false);

  useLayoutEffect(() => {
    dispatch(updateDialogState({
      params: {
        localCameraIsUserFacing: true
      },
    }));
  }, [dispatch]);

  const handleOnChange = useCallback(({ pictureB64, qrCodeData }) => {
    dispatch(updateDialogState({
      qrCodeDataToProcess: qrCodeData,
      pictureB64ToProgress: pictureB64
    }));
  }, [dispatch]);

  const handleCapturedFaceImage = useCallback(async({ pictureB64, qrCodeData }) => {
    
    if (pictureB64) {

      setProcessing(true);
      
      try {
        const response = await API.graphql({
          query: mutations.linkFaceToUser,
          variables: {
            publicFaceId: userDetails.publicFaceId,
            publicTransactionId: transactionDetails.publicTransactionId,
            faceB46: pictureB64,
            onBehalfOfPublicFaceId: userState.actAsUser ? userDetails.publicFaceId : null,
            byPublicFaceId: userState.actAsUser ? userDetails.publicFaceId : null,
            // key: fileKeyRef.current,
          },
          authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
        });
        let linkFaceToUserTransaction = response.data.linkFaceToUser;
        console.log("linkFaceToUserTransaction", linkFaceToUserTransaction);
        
        if (linkFaceToUserTransaction.status === "completed") {
          
          batch(() => {
            // update user...
            // refresh user (balanceS ? may update) ? rights ?
            dispatch(updateUserState({
              [userState.actAsUser ? "actAsUser" : "user"]: {
                ...userDetails,
                settings : {
                  ...userDetails.settings,
                  faceLinked: true,
                },
                rights: {
                  ...userDetails.rights,
                  balancePay: true,
                },
              },
            }));

            // dispatch(updateTransactionDetails({
            //   ...linkFaceToUserTransaction,
            // }));

            // dispatch(updateTransactionDetails({
            //   publicTransactionId: null,
            // }));

            dispatch(setDialogState({
              componentName: "ViewSuccessfulTransaction",
              params: {
                header: I18n.get('Well done!'),
                body: I18n.get('Face ID is set up for your account.'),
              }
            }));
            setProcessing(false);
          });
        }
        else {
          // console.log("continue");
          batch(() => {
            dispatch(updateTransactionDetails(linkFaceToUserTransaction));
            dispatch(updateDialogState({
              pictureB64ToProgress: null
            }));
            cameraRef.current.openCamera(); // to clear the taken picture
            setProcessing(false);
          });
        }
        
      }
      catch (error) {
        console.error("after linkFaceToUser", error);
        dispatch(updateTransactionDetails({
          localError: error,
        }));
        setProcessing(false);
      }

    }
  }, [dispatch, transactionDetails.publicTransactionId, userDetails, userState.actAsUser]);

  useEffect(() => {
    if (dialogState.pictureB64ToProgress || dialogState.qrCodeDataToProcess) {
      handleCapturedFaceImage({
        pictureB64: dialogState.pictureB64ToProgress,
        qrCodeData: dialogState.qrCodeDataToProcess,
      });
    }
  }, [dialogState.pictureB64ToProgress, dialogState.qrCodeDataToProcess, handleCapturedFaceImage]);
  
  const handleCloseCameraView = () => {
    // clear transaction created
    batch(() => {
      dispatch(setDialogState(null)); // this will close the dialog
      dispatch(setTransactionDetails(null));
    });
    
  }
  

  return (
    <Pane padding={10}>

      <DialogHeader
        headerText={I18n.get('Set up Face ID')}
        hideLeftButton={processing}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        leftButtonIcon={null}
        onLeftButtonClick={handleCloseCameraView}
        // rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      <Pane marginTop={10} textAlign="center" >
        <Strong fontSize={16} color="#283655">{I18n.get('Take 3-5 pictures of your face.')}</Strong>
      </Pane>
      <Pane textAlign="center" marginTop={4} >
        <Text fontSize={14} color={"#7B8B9A"} >{I18n.get('The process is 100% save and secure.')}</Text>
      </Pane>

      <Pane marginTop={10} borderRadius={5} background="#E4E7EB" >
        <CameraComponent
          ref={cameraRef}
          isOn={true}
          isUserFacing={true}
          scanQrCodes={false}
          // scanQrBoxColor = {"#FF9C8F"}
          // pictureB64={}
          // placeholder={}
          // overlay={}
          ratio={1/1} // 1/1 , 4/5, 9/16 // https://later.com/blog/instagram-image-size/
          dict={{
            'Open camera': I18n.get('Open camera'),
            'Retake': I18n.get('Retake'),
            'Use': I18n.get('Use photo'),
          }}
          placeholderIsShown={true}
          showButtons={true}
          showTakenPicture={true}
          isProcessing={processing}
          showRetakeButton={false}
          onChange={handleOnChange} // exposes value = .pictureB64 or .qrCodeData
          maxPictureWidth={1350}
          maxPictureHeight={1350}
          // onClose={handleCloseCameraView}
          onClose={() => {cameraRef.current.stopCamera();}}
        />
      </Pane>

    </Pane>
  );
}

const ViewRegisterNewFace = ({ onClose, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ViewRegisterNewFace - ${countRef.current}`);
    return () => {
      console.log("ViewRegisterNewFace - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const profileDetails = useSelector(state => state.profileDetails);
  // const profileState = useSelector(state => state.profileState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const [processing, setProcessing] = useState(false);
  const [newRecipientName, setNewRecipientName] = useState();
  const [newRecipientNameInvalidMessage, setNewRecipientNameInvalidMessage] = useState();

  useEffect (() => {
    if (newRecipientName) {
      let temp = newRecipientName.trim();
      if (temp.length > 30) {
        setNewRecipientNameInvalidMessage(I18n.get('Too long.'));
      }
      else {
        setNewRecipientNameInvalidMessage();
      }
    }
  }, [newRecipientName]);
  
  const handleRegisterNewRecipient = useCallback(async() => {
    console.log("handleRegisterNewRecipient");
    setProcessing(true);
    try {
      const response = await API.graphql({
        query: mutations.registerFace,
        variables: {
          publicTransactionId: transactionDetails.publicTransactionId,
          publicFaceId: profileDetails.publicFaceId,
          name: newRecipientName,
        },
        authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      let tempRecipient = response.data.registerFace;

      // urgent
      const tempTransaction = JSON.parse(JSON.stringify(tempRecipient.objectDetails.transaction));
      delete tempRecipient.objectDetails.transaction;
      
      batch(() => {
        dispatch(setProfileDetails(tempRecipient));
        dispatch(updateTransactionDetails(tempTransaction));
      });

      // go to Profile page
      // replace seems to make sense here
      history.replace({
        pathname: generatePath("/:locale?/:handle", {...match.params, handle: tempRecipient.username}),
        // state: {}
      });
      
    }
    catch (error) {
      // handle already registered Face error and go to profile...
      console.error("with registerFace", error);
      dispatch(updateTransactionDetails({
        localError: error,
      }));
    }

  }, [dispatch, history, match.params, newRecipientName, profileDetails, transactionDetails, userDetails]);


  const handleCloseViewRegisterNewFace = () => {
    onClose();
  }

  return (
    <Pane padding={10}>

      <DialogHeader
        headerText={I18n.get('Registration')}
        hideLeftButton={false}
        hideRightButton={true}
        leftButtonDisabled={processing}
        rightButtonDisabled={false}
        leftButtonIcon={null}
        onLeftButtonClick={handleCloseViewRegisterNewFace}
        rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      <Pane marginTop={10} maxWidth={"65%"} marginLeft="auto" marginRight="auto" >
        <AspectRatio ratio={1/1} >
          <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto" >
            <img src={transactionDetails.localPicture} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit", "imageRendering": "-webkit-optimize-contrast"}} />
          </Pane>
        </AspectRatio>
      </Pane>
      
      <Pane marginTop={10} className="noselect" >

        <Pane marginTop={10} marginBottom={2} marginLeft={2} >
          <Strong fontSize={16} color="#283655">{I18n.get('Name')}</Strong>
        </Pane>

        <TextInput
          id={"inputName"}
          autoComplete={"inputName"}
          name={"inputName"}
          disabled={processing}
          isInvalid={(newRecipientNameInvalidMessage)}
          type={"text"}
          marginTop={0}
          marginBottom={0}
          width="100%"
          // maxWidth={380}
          fontSize={16}
          height={40}
          placeholder={`${I18n.get('First name')}...`}
          value={newRecipientName || ""}
          onChange={(e) => {
            setNewRecipientName(e.target.value);
          }}
          // onBlur={(e) => {
          //   if (typeof onBlur === "function") {
          //     onBlur(e);
          //   }
          // }}
        />
        
        <Pane marginTop={0} marginLeft={2} >
          {(newRecipientNameInvalidMessage) ?
            <Text fontSize={14} color="#EC4C47" >{I18n.get(newRecipientNameInvalidMessage)}</Text>
          :
            null
          }
        </Pane>
        
      </Pane>

      <Pane marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      {!processing ? // may depend on user auth
        <Fragment>
          <Pane marginTop={16} marginBottom={8} paddingX={10} className="noselect" >
            <Button fontSize={16} padding={8} height={50} className='button-green' width="100%" justifyContent="center"
              disabled={(newRecipientNameInvalidMessage) || !newRecipientName}
              onClick={handleRegisterNewRecipient}
            >
              {I18n.get('Complete')}
            </Button>
          </Pane>
          {/* <Pane textAlign="center" marginTop={10} marginBottom={8} className="noselect" >
            <Button fontSize={16} style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={40} marginLeft="auto" marginRight="auto"
              onClick={() => {
                handleCloseViewRegisterNewFace();
              }}
            >
              {I18n.get('Cancel')}
            </Button>
          </Pane> */}
        </Fragment>
      :
        
        <Pane width="100%" marginTop={16} marginBottom={8} paddingY={21}>
          <Pane width="75%" height={16} marginTop={-8} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
        </Pane>
      }

    </Pane>
  );
}

const ViewNotRecognisedFace = ({ onClose, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ViewNotRecognisedFace - ${countRef.current}`);
    return () => {
      console.log("ViewNotRecognisedFace - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  // const profileState = useSelector(state => state.profileState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);


  const [processing, setProcessing] = useState(false);
  
  const handleCloseViewNotRecognisedFace = () => {
    onClose();
  }

  return(
    <Pane padding={10}>

      <DialogHeader
        headerText={I18n.get('New person')}
        hideLeftButton={true}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        leftButtonIcon={null}
        onLeftButtonClick={handleCloseViewNotRecognisedFace}
        rightButtonText={I18n.get('Next')}
        onRightButtonClick={() => {}}
      />

      <Pane marginTop={10} maxWidth={"65%"} marginLeft="auto" marginRight="auto" >
        <AspectRatio ratio={1/1} >
          <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto"
          >
            <img src={transactionDetails.localPicture} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit", "imageRendering": "-webkit-optimize-contrast"}} />
          </Pane>
        </AspectRatio>
      </Pane>
      
      {transactionDetails.status === "continue" ?
        <Fragment>
          <Pane textAlign="center" marginTop={16} className="noselect" >
            <Strong fontSize={16} color="#283655">{I18n.get("Take another picture to continue with registration.")}</Strong>
          </Pane>
        </Fragment>
      :
        <Fragment>
          <Pane textAlign="center" marginTop={16} className="noselect" >
            <Strong fontSize={16} color="#283655">{I18n.get("We don't recognise this person.")}</Strong>
          </Pane>
          <Pane textAlign="center" marginTop={4} className="noselect" >
            <Text fontSize={14} color="#7B8B9A" >{I18n.get("Take another picture to try again or to start registering this person.")}</Text>
          </Pane>
        </Fragment>
      }

      <Pane marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      {(true) ? // may depend on user auth
        <Fragment>
          <Pane marginTop={16} marginBottom={8} paddingX={10} className="noselect" >
            <Button fontSize={16} padding={8} height={50} className='button-green' width="100%" justifyContent="center"
              // disabled={}
              onClick={() => {
                dispatch(setDialogState({
                  componentName: "CameraView",
                }));
              }}
            >
              {transactionDetails.status === "continue" ? I18n.get('Continue') : I18n.get('Try again')}
            </Button>
          </Pane>
          {/* <Pane textAlign="center" marginTop={10} marginBottom={8} className="noselect" >
            <Button fontSize={16} style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={40} marginLeft="auto" marginRight="auto"
              onClick={() => {
                handleCloseViewNotRecognisedFace();
              }}
            >
              {I18n.get('Cancel')}
            </Button>
          </Pane> */}
        </Fragment>
      :
        null
      }

      
      {/* <Pane textAlign="center" marginTop={8} >
        <Text fontSize={14} color="#7B8B9A" >{I18n.get("Take a picture or scan QR code.")}</Text>
      </Pane> */}

    </Pane>
  );
}

const GenerateQrCode = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`GenerateQrCode - ${countRef.current}`);
    return () => {
      console.log("GenerateQrCode - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();

  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  const dialogState = useSelector(state => state.dialogState);

  const [qrCodeLoaded, setQrCodeLoaded] = useState(false);

  // const handleAccessTransaction = useAccessTransaction();

  let tHeader = null;
  let tShowThisQrCodeTo = null;
  let mode = null; // p - payment // r - request
  let expiryTimeToDisplay = null;

  if (transactionDetails) {
    switch (transactionDetails.type) {
      case "paymentIntent":
        tHeader = I18n.get('New payment');
        tShowThisQrCodeTo = I18n.get('Show this QR code to make payment.');
        mode = "p"; // p - payment
        expiryTimeToDisplay = transactionDetails.accessExpiryUNIX > 600 ? 600 : transactionDetails.accessExpiryUNIX; // this needs to be dynamic for various durations
        break;
  
      case "provisionIntent":
        tHeader = I18n.get('New post');
        tShowThisQrCodeTo = I18n.get('Show this QR code to the payee.');
        mode = "r"; // r - request for payment
        break;
  
      default:
        // send to error view ?
        break;
    }
  }

  const handlePaymentExpired = () => {
    dispatch(updateTransactionDetails({
      status: "expired",
      localError: {
        message: "Payment intent has expired."
      }
    }));
  }

  const handleRequestForPaymentFailed = () => {
    // ???
  }

  const handleCancelRequestForPayment = () => {
    // ???
  }

  const qrCodeUrl = useMemo(() => {
    if (transactionDetails.publicTransactionId && transactionDetails.status === "intent") {
      
      const newQrCodeUri = generatePath("/:page/:mode?/:publicTransactionId?/:accessCode?/:amount?/:currency?", {
        page: "qr",
        mode: mode,
        publicTransactionId: transactionDetails.publicTransactionId,
        accessCode: transactionDetails.accessCode,
        amount: transactionDetails.amountAvailable || transactionDetails.amountRequired,
        currency: transactionDetails.currency,
      });

      // console.log("newQrCodeUri", newQrCodeUri);
      return (`https://facedonate.org${newQrCodeUri}`);
    }

    return null;

	}, [mode, transactionDetails.accessCode, transactionDetails.amountAvailable, transactionDetails.amountRequired, transactionDetails.currency, transactionDetails.publicTransactionId, transactionDetails.status]);

  return (
    <Pane padding={10} >

      <DialogHeader
        headerText={tHeader}
        hideLeftButton={!qrCodeLoaded}
        leftButtonIcon={transactionDetails.type === "provisionIntent" ? ChevronLeftIcon : null}
        hideRightButton={true}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        onLeftButtonClick={() => {
          switch (transactionDetails.type) {
            case "paymentIntent":
              dispatch(setTransactionDetails(null)); // to unsubscribe and invalidate if needed
              dispatch(setDialogState(null)); // this will close the dialog
              break;
            case "provisionIntent": // not sure what this is ???
            dispatch(setTransactionDetails(null)); // to unsubscribe and invalidate if needed
              dispatch(setDialogState(null)); // this will close the dialog
              break;
            default:
              dispatch(setTransactionDetails(null)); // to unsubscribe and invalidate if needed
              dispatch(setDialogState(null)); // this will close the dialog
              break;
          }
        }}
      />

      {(!transactionDetails.amountAvailable && !transactionDetails.amountRequired) ? null :
        <Fragment>
          <Pane textAlign="center" marginTop={10} >
            <Strong fontSize={16} color="#283655">{transactionDetails.amountAvailable ? I18n.get('Available to spend') : I18n.get('Required amount')}</Strong>
          </Pane>
          <FormattedCurrencyNumber width="100%" textAlign="center" number={transactionDetails.amountAvailable || transactionDetails.amountRequired} currency={transactionDetails.currency} fontSize={30} />
        </Fragment>
      }

      
      {/* <Pane marginTop={8} textAlign="center" className="text-flip" >
        <Strong fontSize={16} color="#EC4C47" >{`${I18n.get('Code')}: ${transactionDetails.publicTransactionId}`}</Strong>
      </Pane> */}

      <Pane marginTop={8} textAlign="center" >
        <Strong fontSize={16} color="#EC4C47" >{`${I18n.get('Code')}: ${transactionDetails.publicTransactionId}`}</Strong>
      </Pane>
      
      <Pane marginTop={4} padding={10} >
        <QrcodeComponent
          textToEncode={qrCodeUrl} frontColor="#283655" backColor="#0000"
          onChange={(e) => {
            setQrCodeLoaded(e.isLoaded);
          }}
        />
      </Pane>
      
      {qrCodeLoaded ?
        <Pane marginTop={4} textAlign="center" >
          <Text fontSize={14} color="#7B8B9A" >{tShowThisQrCodeTo}</Text>
        </Pane>
      : 
        <Pane marginTop={0} textAlign="center" >
          <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('A QR code is being generated')}...`}</Text>
        </Pane>
      }

      {(transactionDetails.accessExpiryUNIX && qrCodeLoaded) ?
        <Pane marginTop={0} textAlign="center" >
          <Paragraph>
            <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('Expires in')} `}</Text>
            <Text fontSize={14} color="#7B8B9A" >
              <CountUp 
                useEasing={false}
                start={expiryTimeToDisplay}
                end={0}
                delay={0}
                duration={expiryTimeToDisplay}
                formattingFn={(currentNumber) => {
                  let formattedValue = formatDateFns(new Date(currentNumber * 1000), "mm:ss");
                  return formattedValue;
                }}
                onEnd={({ pauseResume }) => {
                  handlePaymentExpired();
                }}
              />
            </Text>
          </Paragraph>
        </Pane>
      : null }

      

      {/* {transactionInProgress ?
        <Fragment>
          <Pane width="100%" marginTop={16} marginBottom={8} paddingY={21}>
            <Pane width="75%" height={16} marginTop={-8} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
          </Pane>
          <Pane marginTop={-16} textAlign="center" className="noselect" >
            <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('Payment is progressing')}...`}</Text>
          </Pane>
          
          <Pane marginTop={16} paddingX={10} className="noselect" >
            <Button fontSize={18} padding={8} height={40} className='button-red' width="100%" justifyContent="center"
              onClick={handleCancelRequestForPayment}
            >
              {I18n.get('Cancel')}
            </Button>
          </Pane>
        </Fragment>
      : null } */}

    </Pane>
  );
}

const VoucherView = ({ close, ...rest }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`VoucherView - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("VoucherView - cleaned up");
    }
  }, []);

  const dispatch = useDispatch();

  // const userState = useSelector(state => state.userState);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);

  const [barcodeBase64, setBarcodeBase64] = useState();
  const [voucherBase64, setVoucherBase64] = useState();
  const [barcodeData, setBarcodeData] = useState({
    barcodeValue: "01040550181031269030000120310012208429",
    originalValueHeading: "Original value",
    originalValueText: "£30",
    useHeading: "Use:",
    useText: "at Lidl, in store or online.",
    serialNoHeading: "Serial No.",
    serialNoText: "123456789",
    cardNoHeading: "Card No.",
    cardNoText: "123456789",
    pinHeading: "PIN:",
    pinText: "3214",
    cvvHeading: "CVV:",
    cvvText: "567",
    expiresHeading: "Expires:",
    expiresText: "12/09/2021",
    barcodeText: "01040550181031269030000120310012208429",
  });

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();


  const canvasElementRef = useRef(null);
    
  const generateVoucherBase64 = useCallback(async(barcodeBase64, voucherParams) => {
    try {
      let sensorElement = canvasElementRef.current;
      let ctx = sensorElement.getContext("2d");

      const image = new Image();
      image.onload = drawImageActualSize; // Draw when image has loaded
      image.src = barcodeBase64;

      function drawImageActualSize() {
        // Use the intrinsic size of image in CSS pixels for the canvas element
        
        let scale = 3;
        const xWidth = 330 * scale;
        const xHeight = 330 * scale;
        
        sensorElement.width = xWidth; // this.naturalWidth + 400;
        sensorElement.height = xHeight; // this.naturalHeight + 400;
        
        ctx.fillStyle = "#FFFFFF"; // background

        ctx.fillRect(0, 0, sensorElement.width, sensorElement.height);

        let barcodeWidth = this.naturalWidth;
        let barcodeHeight = this.naturalHeight;
        console.log(barcodeWidth, barcodeHeight);
        // check barcode width fits
        if ((barcodeWidth * scale) > (xWidth - 20 * scale * 2)) {
          barcodeWidth = xWidth / scale - 20 * 2;
          barcodeHeight = xHeight / scale / 5.5;
        }
        
        let barcodeX = (xWidth -  barcodeWidth * scale) / 2;
        let barcodeY = xHeight - (10 + barcodeHeight  + 30) * scale;
        let barcodeEndY = barcodeY + barcodeHeight * scale;
        
        ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, barcodeX, barcodeY, barcodeWidth * scale, barcodeHeight * scale);

        function drawText (params) {
          let textFontSize = params.fontSize * scale;
          ctx.fillStyle = "#283655";
          let fontFam = "'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
          ctx.font = `${params.fontWeight || 600} ${textFontSize}px ${fontFam}`;
          let textMeasure = ctx.measureText(params.text);
          let textLeftPadding = params.left * scale; // (1400 - text.width) / 2;
          if (params.textAlign === "center") {
            textLeftPadding = (xWidth - textMeasure.width) / 2;
          }
          let textTopPadding = params.top * scale + textFontSize; // (1400 - text.width) / 2;
          ctx.fillText(params.text, textLeftPadding, textTopPadding);
          return ({
            x: textMeasure.width / scale,
            y: params.top + params.fontSize,
          });
        }
        
        let nextLine = {
          y: 0,
          x: 0,
        }
        let currentX = 20;
        let currentY = 20;

        nextLine = drawText({text: voucherParams.originalValueHeading, fontSize: 16, top: currentY, left: currentX});

        //
        nextLine = drawText({text: voucherParams.originalValueText, fontSize: 30, top: nextLine.y, left: currentX});
        
        //
        currentY = nextLine.y + 6;
        nextLine = drawText({text: voucherParams.useHeading, fontSize: 16, top: currentY, left: currentX});
        currentX = currentX + nextLine.x + 4;
        nextLine = drawText({text: voucherParams.useText, fontSize: 16, top: currentY, left: currentX, fontWeight: 400});

        //
        currentX = 20;
        nextLine = drawText({text: voucherParams.serialNoHeading, fontSize: 14, top: nextLine.y + 10, left: currentX});
        nextLine = drawText({text: voucherParams.serialNoText, fontSize: 14, top: nextLine.y + 5, left: currentX, fontWeight: 400});

        //
        currentX = 20;
        nextLine = drawText({text: voucherParams.cardNoHeading, fontSize: 14, top: nextLine.y + 10, left: currentX});
        nextLine = drawText({text: voucherParams.cardNoText, fontSize: 14, top: nextLine.y + 5, left: currentX, fontWeight: 400});

        //
        currentX = 20;
        currentY = nextLine.y + 5;

        nextLine = drawText({text: voucherParams.pinHeading, fontSize: 14, top: currentY, left: currentX});
        currentX = currentX + nextLine.x + (currentX !== nextLine.x ? 4 : 0 );
        nextLine = drawText({text: voucherParams.pinText, fontSize: 14, top: currentY, left: currentX, fontWeight: 400});
        
        currentX = currentX + nextLine.x + (currentX !== nextLine.x ? 10 : 0 );
        nextLine = drawText({text: voucherParams.cvvHeading, fontSize: 14, top: currentY, left: currentX});
        currentX = currentX + nextLine.x + (currentX !== nextLine.x ? 4 : 0 );
        nextLine = drawText({text: voucherParams.cvvText, fontSize: 14, top: currentY, left: currentX, fontWeight: 400});

        currentX = currentX + nextLine.x + (currentX !== nextLine.x ? 10 : 0 );
        nextLine = drawText({text: voucherParams.expiresHeading, fontSize: 14, top: currentY, left: currentX});
        currentX = currentX + nextLine.x + (currentX !== nextLine.x ? 4 : 0 );
        nextLine = drawText({text: voucherParams.expiresText, fontSize: 14, top: currentY, left: currentX, fontWeight: 400});

        // barcode value
        currentX = 20;
        nextLine = drawText({text: voucherParams.barcodeText, fontSize: 12, top: barcodeEndY / scale + 5, left: currentX, fontWeight: 400, textAlign: "center"});

        // border
        let xLeft = 10 * scale;
        let yTop = 10 * scale;
        let lineWidth = 1 * scale;
        let radius = 5 * scale;
        let radiusOffset = 5 * scale;
        let faceW = xWidth - (10 * 2 * scale) - radiusOffset - lineWidth;
        let faceH = xHeight - (10 * 2 * scale) - radiusOffset - lineWidth;
        ctx.beginPath();
        ctx.moveTo(xLeft + radiusOffset, yTop);
        ctx.lineTo(xLeft + faceW, yTop);
        ctx.quadraticCurveTo(xLeft + faceW + radius, yTop, xLeft + faceW + radius, yTop + radius);
        ctx.lineTo(xLeft + faceW + radiusOffset, yTop + faceH);
        ctx.quadraticCurveTo(xLeft + faceW + radius, yTop + faceH + radius, xLeft + faceW, yTop + faceH + radius);
        ctx.lineTo(xLeft + radiusOffset, yTop + faceH + radiusOffset);
        ctx.quadraticCurveTo(xLeft, yTop + faceH + radius, xLeft, yTop + faceH);
        ctx.lineTo(xLeft, yTop + radiusOffset);
        ctx.quadraticCurveTo(xLeft, yTop, xLeft + radius, yTop);
        ctx.strokeStyle = "#283655";
        ctx.lineWidth = lineWidth;
        ctx.stroke();

        let base64StringFull = sensorElement.toDataURL("image/png", 1);
        // console.log("base64StringFull", base64StringFull);
        setVoucherBase64(base64StringFull);
      }
    }
    catch (error) {
      console.error("error with generateVoucherBase64", error);
    }
  }, []);
  
  useEffect(() => {
    if (barcodeBase64) {
      generateVoucherBase64(barcodeBase64, barcodeData);
    }
  }, [barcodeBase64, barcodeData, generateVoucherBase64]);

  return(
    <Pane padding={10}>
      
      <DialogHeader
        headerText={I18n.get('Voucher')}
        rightButtonDisabled={false}
        onLeftButtonClick={() => {
          // handle "Are you sure?"
          close();
        }}
        rightButtonText={I18n.get('Next')}
        hideRightButton={true}
        onRightButtonClick={() => {
          dispatch(updateTransactionDetails({
            // ???
          }));
        }}
      />
      
      {/* <Pane marginTop={10} padding={10} border borderWidth={1} borderStyle="solid" borderColor="#7B8B9A" borderRadius={5} className="noselect" >

        <Pane marginTop={0} >
          <Strong fontSize={16} color="#283655">{I18n.get('Original value')}</Strong>
        </Pane>
        <FormattedCurrencyNumber number={transactionDetails.amountAvailable || 20} currency={transactionDetails.currency || "gbp"} fontSize={30} />

        <Pane marginTop={4} >
          <Strong fontSize={16} color="#283655">{`${I18n.get('Use')}: `}</Strong>
          <Text fontSize={16} color="#283655" >{"at Lidl, in store or online"}</Text>
        </Pane>

        <Pane marginTop={10} >
          <Pane >
            <Strong fontSize={14} color="#283655">{I18n.get('Serial No.')}</Strong>
          </Pane>
          <Pane >
            <Text fontSize={14} color="#283655" >{"98765432112345"}</Text>
          </Pane>

          <Pane marginTop={4} >
            <Strong fontSize={14} color="#283655">{I18n.get('Card No.')}</Strong>
          </Pane>
          <Pane >
            <Text fontSize={14} color="#283655" >{"1234 5678 9098 7654"}</Text>
          </Pane>
        </Pane>

        <Pane marginTop={0} alignItems="center" display="flex" flexWrap="wrap" >
          <Pane >
            <Strong fontSize={14} color="#283655">{`${I18n.get('PIN')}: `}</Strong>
            <Text fontSize={14} color="#283655" >{"1234"}</Text>
          </Pane>

          <Pane marginLeft={8} >
            <Strong fontSize={14} color="#283655">{`${I18n.get('CVV')}: `}</Strong>
            <Text fontSize={14} color="#283655" >{"678"}</Text>
          </Pane>

          <Pane marginLeft={8} >
            <Strong fontSize={14} color="#283655">{`${I18n.get('Expires')}: `}</Strong>
            <Text fontSize={14} color="#283655" >{"12/34/2021"}</Text>
          </Pane>
        </Pane>

        <Pane marginTop={20} display="flex" marginLeft="auto" marginRight="auto" >
          <BarCodeComponent setBarcodeBase64={setBarcodeBase64} height={60} value={barcodeData.barcodeValue} />
        </Pane>

        <Pane lineBreak="anywhere" marginTop={4} textAlign="center" className="noselect" >
          <Text fontSize={12} color="#283655" >{barcodeData.barcodeValue}</Text>
        </Pane>

      </Pane> */}

      <Pane marginTop={10} >
        <AspectRatio ratio={1/1} >
          <Pane position="absolute" width="100%" height="100%" marginLeft="auto" marginRight="auto"
          >
            <img src={voucherBase64} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit", "imageRendering": "-webkit-optimize-contrast"}} />
          </Pane>
        </AspectRatio>
      </Pane>

      <BarCodeComponent doNotShow={true} setBarcodeBase64={setBarcodeBase64} height={60} value={barcodeData.barcodeValue} />
      <canvas
        ref={canvasElementRef}
        style={{
          "display": "none",
        }}>
      </canvas>

      {barcodeBase64 && voucherBase64 ? 
        <Pane marginTop={16}  marginBottom={8} display="flex" background="tint1" borderRadius={5}>
          <Button width={"100%"} height={50} padding={8} justifyContent="center"
            borderRadius={5}
            onClick={() => {
              triggerBase64Download(voucherBase64, `face_donate_voucher`); // .png is added automatically
            }}
          >
            <Strong fontSize={16} color={"#283655"} >{I18n.get('Download voucher')}</Strong>
          </Button>
        </Pane>
      : 
        <Pane marginTop={0} textAlign="center" className="noselect" >
          <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('A voucher is being generated')}...`}</Text>
        </Pane>
      }
      

      {/* <Pane marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane marginTop={16} marginBottom={8} paddingX={10} className="noselect" >
        <Button width={"100%"} height={50} padding={8} justifyContent="center"
          borderRadius={5}
          onClick={() => {
            // dispatch(updateTransactionDetails({
            //   // ???
            // }));
          }}
        >
          <Strong fontSize={16} color={"#283655"} >{I18n.get('Add feedback')}</Strong>
        </Button>
      </Pane> */}

    </Pane>
  );
}

const ViewProvisionIntent = ({ close, ...rest }) => {

  const dispatch = useDispatch();

  // const userState = useSelector(state => state.userState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);

  const timeUntilAccessExpiry = transactionDetails.accessExpiryUNIX && transactionDetails.amountAvailable > 0 ? Math.round(transactionDetails.accessExpiryUNIX - Math.round(new Date().getTime()/1000)) : 0;
  const [timeToDisplay, setTimeToDisplay] = useState(timeUntilAccessExpiry > 600 ? 600 : timeUntilAccessExpiry); // this needs to be dynamic for various durations
  const handleIntentExpired = () => {
    setTimeToDisplay(0);
  }

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  return(
    <Pane padding={10}>

      <DialogHeader
        // headerText={I18n.get('New post')}
        rightButtonDisabled={false}
        onLeftButtonClick={() => {
          // handle "Are you sure?"
          close();
        }}
        hideRightButton={true}
        // rightButtonText={I18n.get('Next')}
        // onRightButtonClick={() => {
        //   dispatch(updateTransactionDetails({
        //     // ???
        //   }));
        // }}
      />
      
      <Pane marginTop={10} textAlign="center" >
        <Strong fontSize={16} color="#283655">{I18n.get('Recipient')}</Strong>
      </Pane>
      <Pane textAlign="center" marginTop={4} className="noselect" >
        <Strong fontSize={30} lineHeight={1} color="#283655" >{transactionDetails.toFace.name}</Strong>
      </Pane>

      <Pane marginTop={10} textAlign="center" >
        <Strong fontSize={16} color="#283655">{I18n.get('Available balance')}</Strong>
      </Pane>
      <FormattedCurrencyNumber width="100%" textAlign="center" number={transactionDetails.amountAvailable} currency={transactionDetails.currency} fontSize={30} />

      {/* <Pane textAlign="center" marginTop={8} className="noselect" >
        <Text fontSize={14} color="#7B8B9A" >{
          `${handleFormattedCurrencyNumber(transactionDetails.amountAvailable, transactionDetails.currency)} ${I18n.get("is XXXXXX.")}`
        }</Text>
      </Pane> */}

      {timeToDisplay > 0 ? null :
        <Pane textAlign="center" marginTop={8} className="noselect" >
          <Text fontSize={14} color="#EC4C47" >{I18n.get("Access expired. Please start again if you want to use the recipient's funds.")}</Text>
        </Pane>
      }
      

      <Pane marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane marginTop={16} marginBottom={8} paddingX={10} className="noselect" >
        <Button width={"100%"} height={50} padding={8} justifyContent="center"
          borderRadius={5}
          onClick={() => {
            dispatch(updateTransactionDetails({
              // ???
            }));
          }}
        >
          <Strong fontSize={16} color={"#283655"} >{I18n.get('New post')}</Strong>
        </Button>
      </Pane>

      {timeToDisplay > 0 ?
        <Pane marginTop={4} textAlign="center" className="noselect" >
          <Paragraph>
            <Text fontSize={14} color="#7B8B9A" >{`${I18n.get('Expires in')} `}</Text>
            <Text fontSize={14} color="#7B8B9A" >
              <CountUp 
                useEasing={false}
                start={timeToDisplay} // 
                end={0}
                delay={0}
                duration={timeToDisplay}
                formattingFn={(currentNumber) => {
                  let formattedValue = formatDateFns(new Date(currentNumber * 1000), "mm:ss");
                  return formattedValue;
                }}
                onEnd={({ pauseResume }) => {
                  handleIntentExpired();
                }}
              />
            </Text>
          </Paragraph>
        </Pane>
      : 
        <Pane marginTop={16} paddingX={10} >
          <Button width={"100%"} height={50} padding={8} justifyContent="center"
            borderRadius={5}
            onClick={() => {
              
            }}
          >
            <Strong fontSize={16} color={"#283655"} >{I18n.get('Open camera')}</Strong>
          </Button>
        </Pane>
      }

    </Pane>
  );
}

const StyledDraggableContainer = styled.div`
  /* used for positioning the after content */
  position: relative;
  height: 100%;
  display: inline-flex;
  width: 100%
  /* stylelint-disable  comment-empty-line-before */
  /* add little portal indicator when in a portal */
  ${(props) =>
    props.inPortal ?
    `
      ::after {
        position: absolute;
        background: lightgreen;
        bottom: 0;
        right: 0;
        content: "in portal";
      }
    `
  : ''}/* stylelint-enable */;
`;

const DraggableCard = ({ item, index }) => {
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx

  const portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');

  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }

  document.body.appendChild(portal); // this needs to be removed ?

  

  return (
    <Draggable draggableId={item.id} index={index} >
      
      {(provided, snapshot) => {
        const usePortal = snapshot.isDragging;

        const child = (
          <StyledDraggableContainer ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            inPortal={snapshot.isDragging}
          >
            <Pane minWidth={60} >
              <AspectRatio ratio={1/1} >
                {item.src ?
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                    <img src={item.src} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit"}}/>
                  </Pane>
                :
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                    <FacePH color="#7B8B9A" width="100%" height="100%" />
                  </Pane>
                }
              </AspectRatio>
            </Pane>
          </StyledDraggableContainer>
        )

        if (!usePortal) {
          return child;
        }
        return ReactDOM.createPortal(child, portal);
      }}
    </Draggable>
  );
}

const DraggableList = ({ listItems }) => {
  return listItems.map((item, index) => (
    <DraggableCard item={item} index={index} key={item.id} />
  ));
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const ViewSuccessfulTransaction = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ViewSuccessfulTransaction - ${countRef.current}`);
    return () => {
      console.log("ViewSuccessfulTransaction - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  const dialogState = useSelector(state => state.dialogState);

  const handleClose = useCallback(() => {
    dispatch(setDialogState(null)); // this will close the dialog
  }, [dispatch]);

  return (
    <Fragment>
      <Pane height={150} borderTopLeftRadius={10} borderTopRightRadius={10} style={{background: "linear-gradient(0deg, #FFFFFF 10%, rgba(71,184,129,1) 95%)"}} >
        <Pane textAlign="center" >
          <Pane marginTop={20} background="#91d4b3" padding={10} borderRadius="50%" display="inline-block" >
            <Pane background="#7ecda7" padding={10} borderRadius="50%" display="flex">
              <Pane background="#6cc69a" padding={10} borderRadius="50%" display="flex" >
                <TickIcon size={36} color="#FFFFFF" />
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <Pane padding={10}>
        <Pane textAlign="center" flex={1} width="100%" height="auto" className="noselect" >
          <Strong display="contents" flex="none" fontSize={30} color="#283655">{dialogState?.params?.header || I18n.get('Thank you!')}</Strong>
        </Pane>
        <Pane marginTop={10} width="100%" height="auto" textAlign="center" >
          <Paragraph fontSize={18} lineHeight={1.3} color="#283655" >
            {dialogState?.params?.body} 
          </Paragraph>
        </Pane>

        <Pane marginTop={16} marginBottom={10} textAlign="center" >
          <Button fontSize={16} paddingX={24} height={40} minWidth={140} justifyContent="center" 
            onClick={handleClose}
          >
          {dialogState?.params?.closeButton || I18n.get('Close')}
          </Button>
        </Pane>
      </Pane>
    </Fragment>
  );

}

const ViewErrorTransaction = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ViewErrorTransaction - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("ViewErrorTransaction - cleaned up");
    }
  }, []);

  const dispatch = useDispatch();
  
  // const profileDetails = useSelector(state => state.profileDetails);
  // const profileState = useSelector(state => state.profileState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);
  
  // temp ???
  useEffect(() => {
    if (transactionDetails.localError) {
      console.error("transactionDetails.localError", transactionDetails.localError);
    }
  }, [transactionDetails.localError]);


  const defaultParams = useCallback((overrideProps = {}) => {

    const definedProps = obj => Object.fromEntries(
      Object.entries(obj)?.filter(([k, v]) => v)
    );

    return ({
      header: I18n.get('Something went wrong'),
      body: I18n.get('Please try again'),
      closeButton: I18n.get('Try again'),
      onClose: () => {},
      ...definedProps(overrideProps),
    });

  }, []);

  const [params, setParams] = useState(defaultParams());

  useLayoutEffect(() => { // keep

    let currentError = transactionDetails.localError;

    if (currentError.errors?.length > 0) {
      currentError = currentError.errors[0];
    }
    
    let errorMessage = currentError.message;
    let headerMessage = null;
    let bodyMessage = null;
    let closeButton = null;
    let onClose = null;

    let newComponentName = null;
    
    
    switch (errorMessage) {
      case "Invalid QR code.":
        headerMessage = I18n.get(errorMessage);
        // bodyMessage = I18n.get('No payment has been made. If you still want to pay, please start again.');
        // onClose = () => {};
        break;
      case "Payment intent has expired.":
        headerMessage = I18n.get('QR code has expired');
        bodyMessage = I18n.get('No payment has been made. If you still want to pay, please start again.');
        onClose = () => {
          dispatch(setDialogState()); // this will close the dialog
          dispatch(updateTransactionDetails());
        };
        break;
      case "There are no faces in the image.":
        headerMessage = I18n.get('No face in the photo');
        bodyMessage = I18n.get('Try again');
        break;
      case "Face is already linked to a user.":
        bodyMessage = I18n.get('Your face is already linked to an account.');
        closeButton = I18n.get('Close'); // ???
        onClose = () => {
          dispatch(setDialogState()); // this will close the dialog
        };
        break;
      case "User already has a face linked.":
        bodyMessage = I18n.get('A face is already linked to your account.');
        closeButton = I18n.get('Close'); // ???
        onClose = () => {
          dispatch(setDialogState()); // this will close the dialog
        };
        break;
      case "Multiple faces recognised.":
        bodyMessage = I18n.get('Continue taking pictures of your face.');
        closeButton = I18n.get('Continue');
        break;
      case "Invalid publicTransactionId.":
        newComponentName = "LinkFace";
        break;
      
      case "Request failed with status code 401":
        bodyMessage = I18n.get('Action is not allowed (401).');
        onClose = () => {
          dispatch(setDialogState()); // this will close the dialog
        };
        break;
      case "Unauthorised request.":
        bodyMessage = I18n.get('Unauthorised request.');
        onClose = () => {
          dispatch(setDialogState()); // this will close the dialog
        };
        break;
      default:
        break;
    }

    if (newComponentName) {
      dispatch(updateDialogState({
        componentName: newComponentName,
      }));
    }
    
    setParams(defaultParams({
      header: headerMessage,
      body: bodyMessage,
      closeButton: closeButton,
      onClose: onClose,
    }));
    
  }, [defaultParams, dispatch, transactionDetails.localError]);

  const handleOnClose = () => {
    batch(() => {
      dispatch(updateTransactionDetails({
        localError: null, // to prevent reopening
      }));
      if (transactionDetails.localError || typeof params?.onClose !== "function") { // when triggered manually
        dispatch(setDialogState()); // this will close the dialog
      }
      params?.onClose();
    });
  };
  

  return (
    <Fragment>
              
      <Pane height={150} borderTopLeftRadius={10} borderTopRightRadius={10} style={{background: "linear-gradient(0deg, #FFFFFF 10%, rgba(236,76,71,1) 95%)"}} >
        <Pane textAlign="center" >
          <Pane marginTop={20} background="#f6a6a3" padding={10} borderRadius="50%" display="inline-block" >
            <Pane background="#f49491" padding={10} borderRadius="50%" display="flex">
              <Pane background="#f0706c" padding={10} borderRadius="50%" display="flex" >
                <CrossIcon size={36} color="#FFFFFF" />
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <Pane padding={10}>
        <Pane textAlign="center" flex={1} width="100%" height="auto" className="noselect" >
          <Strong display="contents" flex="none" fontSize={24} color="#EC4C47">{params?.header}</Strong>
        </Pane>
        <Pane marginTop={10} width="100%" height="auto" textAlign="center" >
          <Paragraph fontSize={18} lineHeight={1.3} color="#EC4C47" >
            {params?.body}
          </Paragraph>
        </Pane>

        <Pane marginTop={16} marginBottom={10} textAlign="center" >
          <Button fontSize={16} paddingX={24} height={40} minWidth={140} justifyContent="center" 
            onClick={handleOnClose}
          >
          {params?.closeButton}
          </Button>
        </Pane>
      </Pane>
    </Fragment>
  );
}

const RenderContent = ({ close }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`RenderContent - ${countRef.current}`);
    return () => {
      console.log("RenderContent - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);

  return (
    <Pane background="#FFFFFF" borderRadius={10} >

    {transactionDetails.localError ?
      <ViewErrorTransaction />
    :
      <Fragment> 
        {dialogState.componentName === "CameraView" ?
          <CameraView />
        : null }

        {/* { === "ViewNotRecognisedFace" ?
          <ViewNotRecognisedFace onClose={close} />
        : null } */}

        {/* { === "ViewRegisterNewFace" ?
          <ViewRegisterNewFace onClose={close} />
        : null } */}

        {dialogState.componentName === "GenerateQrCode" ?
          <GenerateQrCode />
        : null }

        {/* { === "VoucherView" ?
          <VoucherView close={close} />
        : null } */}
        
        {/* { === "ViewProvisionIntent" ?
          <ViewProvisionIntent close={close} />
        : null } */}


        {dialogState.componentName === "TakePicture" ?
          <TakePicture />
        : null }

        {dialogState.componentName === "ShareOptions" ?
          <ShareOptions />
        : null }

        {dialogState.componentName === "TransactionMoreDialog" ?
          <TransactionMoreDialog />
        : null }

        {dialogState.componentName === "LinkFace" ?
          <LinkFace />
        : null }

        {dialogState.componentName === "ViewSuccessfulTransaction" ?
          <ViewSuccessfulTransaction />
        : null }

      </Fragment>
    }

    </Pane>
  );
}

const DialogProfileActions = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`DialogProfileActions - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("DialogProfileActions - cleaned up");
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);

  const browserInfoName = useSelector(state => state.localeState.browserInfo.name);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const [showDialogInPageDrawer, setShowDialogInPageDrawer] = useState(false); // useState(browserInfoName === "ios" ? true : false);

  const isDialogShown = useMemo(() => {
    if (dialogState.componentName || transactionDetails.localError) {
      return true;      
    }
    else {
      return false;  
    }
  }, [dialogState.componentName, transactionDetails.localError]);

  const handleAccessTransaction = useAccessTransaction();

  useEffect(() => {
    if (!transactionDetails.publicTransactionId && transactionDetails.type === "paymentIntent") {
      console.log("Auth required...");
      dispatch(updateTransactionDetails({
        status: "auth",
      }));
    }
  }, [dispatch, transactionDetails.publicTransactionId, transactionDetails.type]);

  useEffect(() => {

    if (transactionDetails.status === "auth") {
      dispatch(setDialogState({
        componentName: "CameraView",
        params: {
          localCameraIsUserFacing: true,
        },
      }));
    }
    else if (transactionDetails.status === "intent" && transactionDetails.type === "paymentIntent") {
      dispatch(setDialogState({
        componentName: "GenerateQrCode",
      }));
    }
    else if (transactionDetails.status === "succeeded" && transactionDetails.type === "payment" && dialogState.componentName === "GenerateQrCode") {
      
      // update user history and balance
      // more transactionDetails [probably get provision details in .history] needed to make this appear in user's history
      if (userDetails) {
        let newHistoryResults = [
          transactionDetails,
          ...userDetails.history?.results || [],
        ];

        dispatch(updateUserState({
          [userState.actAsUser ? "actAsUser" : "user"]: {
            ...userDetails,
            history: {
              results: newHistoryResults,
              total: newHistoryResults.length,
              nextToken: userDetails.history?.nextToken,
            },
            balance: {
              ...userDetails.balance,
              available: Math.round(((userDetails.balance?.available - transactionDetails.amount) + Number.EPSILON) * 100) / 100,
              paymentsMade: Math.round(((userDetails.balance?.paymentsMade + transactionDetails.amount) + Number.EPSILON) * 100) / 100,
            }
          },
        }));
      }

      dispatch(setDialogState({
        componentName: "ViewSuccessfulTransaction",
        // improve - add details of the paid transaction ???
      }));
    }

  }, [dialogState.componentName, dispatch, transactionDetails, userDetails, userState.actAsUser]);

  const handleOnCloseDialogComplete = useCallback(() => {
    // needs improvement ???
    // why having common actions if each view can handle its own actions on close ???

    if (transactionDetails.status !== "completed" && transactionDetails.status !== "succeeded") {

      // what to do with images from unfinished transaction ???

      // if (transactionDetails.publicTransactionId && transactionDetails.accessCode && transactionDetails.type !== "intent") {
      //   console.log("invalidate transaction on closing DialogProfileActions");
      //   const { data, error } = handleAccessTransaction({
      //     publicTransactionId: transactionDetails.publicTransactionId,
      //     accessCode: transactionDetails.accessCode,
      //     invalidate: true,
      //   });
      // }
    }

    dispatch(setDialogState()); // ???

  }, [dispatch, transactionDetails.status]);

  return (
    
    !showDialogInPageDrawer ?
    
      <ModalResponsive
        // ref={dialogRef}
        isShown={isDialogShown}
        // onOpenComplete={() => {}}
        onCloseComplete={handleOnCloseDialogComplete}
        // onCancel={(close) => close()}
        title='DialogActions'
        hasHeader={false}
        hasFooter={false}
        preventBodyScrolling
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        width={350} // 350 // "100%"
        height={"100%"}
        topOffset={10}
        containerProps={{
          "backgroundColor": "transparent",
          // "maxWidth": "100%",
          "maxHeight": "100%", // "calc(100% - 36px * 2)",
          "margin": "auto", // auto
          "overflow": 'scroll',
        }}
        contentContainerProps={{
          "padding": "0px",
          "maxHeight": "75hv",
        }}
      >
        {({ close }) => (
          <Pane background="#FFFFFF" borderRadius={10}
            // padding={10}
          >
            
            <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
              <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 1)"} overflow={"scroll"} >

                <RenderContent close={close} />

              </Pane>
            </Pane>

            <Pane padding={0} height={0} >
              {/* <button style={{fontSize: "1rem"}}
                onClick={() => {
                  close();
                }}
              >Close modal</button> */}
            </Pane>

          </Pane>
          
        )}
      </ModalResponsive>

    :
      <PageDrawer
        isShown={isDialogShown}
        onCloseComplete={handleOnCloseDialogComplete}
      >
        {({ close }) => (
          <RenderContent close={close} />
        )}
      </PageDrawer>

  );
}

export default DialogProfileActions;