
//  https://medium.com/bleeding-edge/enforcing-an-aspect-ratio-on-an-html-element-in-react-and-css-27a13241c3d4

import * as React from "react"
import * as Styles from "./AspectRatio.styles"

interface Props {
  children?: any
  /**
   * The width divided by the height. This ratio can be passed in
   * using JavaScript division syntax. So, to get a 16:9 ratio, 
   * simply pass `ratio={16/9}`.
   */
  ratio: number
  display: any
}

const AspectRatio = ({ children, ratio, display }: Props) => (
  <Styles.OuterWrapper ratio={ratio} display={display} >
    <Styles.InnerWrapper>
      {children}
    </Styles.InnerWrapper>
  </Styles.OuterWrapper>
)
export default AspectRatio