import { Fragment, useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, Checkbox, Table, SmallCrossIcon, SidebarTab, Radio, RadioGroup, Spinner } from 'evergreen-ui';
import _, { includes, sortedLastIndex } from 'lodash';
import styled from "styled-components";

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import { NumericFormat } from 'react-number-format';

import { currencies } from '../services/localeDictionary';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { I18n } from 'aws-amplify';

const ResponsivePane = styled(Pane)`
  text-align: right;
  @media only screen and (max-width: ${425}px) {
    text-align: left;
  }
`;

const CurrencyNumberInput = ({
  disabled,
  placeholder = "0.00",
  value,
  currency,
  onChange = () => {},
  onBlur = () => {},
  getInputRef = () => {},
  ...rest
}) => {

  return (
    <Pane
      textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" className="noselect"
    {...rest}
    >
      {currencies[currency]?.symbolRight ? null :
        <Pane
          paddingX={8}
          height={32}
          border
          borderWidth={1}
          borderStyle="solid"
          borderColor="#C7CED4"
          justifyContent="center"
          background={"#F9F9FB"} // #F9F9FB // #E4E7EB
          borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
          className="noselect"
          display={"flex"}
          alignItems="center"
        >
          <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{currencies[currency]?.symbol}</Strong>
        </Pane>
      }
      
      <NumericFormat
        getInputRef={(el) => {getInputRef(el)}}
        customInput={TextInput}
        min="0" step="0.01"
        // format={"#.##"}
        thousandSeparator={true}
        // id={"allocation-amount"}
        type={"text"}
        pattern={"[0-9]*"} // to bring up the numeric keypad
        inputMode="numeric" // to bring up the numeric keypad
        // autoComplete="off" // doesn't seem to work
        
        // autoComplete={"allocation-amount"}
        // name={"allocation-amount"}
        disabled={disabled} 
        // isInvalid={localIsInvalid}
        marginTop={0}
        marginBottom={0}
        width={80}
        height={32}
        fontSize={14}
        color={"#283655"}
        placeholder={placeholder}

        paddingY={0}
        marginLeft={-1}
        marginRight={-1}
        
        borderRadius={0}
        // style={{
        //   boxShadow: "none",
        //   border: "solid",
        //   borderWidth: "1px",
        //   borderColor: "#C7CED4",
        //   borderRight: "0px",
        // }}
        
        // border={"solid"}
        // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}

        value={value ? parseFloat(value)?.toFixed(2) : ""}
        defaultValue={null}
        onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
          onChange(values);
        }}
        onBlur={(e) => {
          onBlur(e)
        }}
      />
      {!currencies[currency]?.symbolRight ? 
      
        <Pane
          paddingX={4}
          height={32}
          border
          borderWidth={1}
          borderStyle="solid"
          borderColor="#C7CED4"
          background={"#F9F9FB"} // #F9F9FB // #E4E7EB
          borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
        />
      
      :
        <Pane
          paddingX={8}
          height={32}
          justifyContent="center"
          background={"#F9F9FB"}
          borderRadius={0}
          className="noselect"
          display={"flex"}
          alignItems="center"
          borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
        >
          <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{currencies[currency]?.symbol}</Strong>
        </Pane>
      }
    </Pane>
  );
}

export default CurrencyNumberInput;