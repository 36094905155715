import { Fragment, useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Switch, Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Menu, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';
import DialogHeader from './DialogHeader';

import { useSelector } from 'react-redux';

import { useChangeUserPassword } from "../services/useAmplifyAuth";

import { Auth, API, Storage, I18n} from 'aws-amplify';
import * as queries from '../graphql/queries';

const AccountMenu = ({children}) => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("AccountMenu cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  return (
    <Pane clearfix maxWidth={960} minHeight="100vh" paddingX={10} alignItems="center" marginLeft="auto" marginRight="auto">

      

      <Pane alignItems="center" marginTop={20} display="flex" padding={10} background="tint1" borderRadius={5} >
        <DialogHeader
          headerText={null}
          hideLeftButton={false}
          hideRightButton={true}
          leftButtonDisabled={false}
          rightButtonDisabled={false}
          leftButtonIcon={null}
          onLeftButtonClick={() => {
            // close();
          }}
          // rightButtonText={I18n.get('Next')}
          onRightButtonClick={() => {}}
        />
      </Pane>

      <Pane alignItems="center" display="flex" marginTop={20} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Account')}</Strong>
      </Pane>



      {children}




    </Pane>
  );

}

export default AccountMenu;