import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import FacePH from '../images/FacePH';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';

import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useSelector } from 'react-redux';

import { API, Storage} from 'aws-amplify';
import * as queries from '../graphql/queries';

const AccountPrivacyAndSecurity = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("PrivacyAndSecurity cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  

  return (
    (!userState.user || !isCurrent.current) ? null :
    <Pane>
      <Heading size={700} color="#283655">{"Privacy And Security: this section is being developed."}</Heading>
    </Pane>
  );

}

export default AccountPrivacyAndSecurity;