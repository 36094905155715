const localeDictionary = {
	ae: "en_AE",
  "ae-ar": "ar_AE",
	am: "en_AM",
  "am-en": "en_AM",
	"am-hy": "hy_AM",
  at: "de_AT",
  au: "en_AU",
  az: "en_AZ",
	be: "fr_BE",
  "be-fr": "fr_BE",
  "be-nl": "nl_BE",
  bg: "bg_BG",
  "bh-ar": "ar_BH",
  bh: "en_BH",
  br: "pt_BR",
  bw: "en_BW",
  by: "en_BY",
  ca: "en_CA",
  "ca-fr": "fr_CA",
  cf: "fr_CF",
	ch: "de_CH",
  "ch-de": "de_CH",
  "ch-fr": "fr_CH",
  ci: "fr_CI",
  cl: "es_CL",
  cm: "fr_CM",
  co: "es_CO",
  cz: "cs_CZ",
  de: "de_DE",
  dk: "da_DK",
  ee: "et_EE",
  "eg-ar": "ar_EG",
  eg: "en_EG",
  es: "es_ES",
  fi: "fi_FI",
  fr: "fr_FR",
  ge: "en_GE",
  gn: "fr_GN",
  gq: "fr_GQ",
  gr: "el_GR",
  gw: "en_GW",
  hk: "zh_HK",
  "hk-en": "en_HK",
  hr: "hr_HR",
  hu: "hu_HU",
  id: "en_ID",
  ie: "en_IE",
  il: "en_IL",
  in: "en_IN",
  it: "it_IT",
  "jo-ar": "ar_JO",
  jo: "en_JO",
  jp: "ja_JP",
  ke: "en_KE",
  kg: "en_KG",
  kr: "ko_KR",
  "kw-ar": "ar_KW",
  kw: "en_KW",
  kz: "en_KZ",
  la: "es_HN",
  la: "es_AR",
  la: "es_SV",
  la: "es_PR",
  la: "es_DO",
  la: "es_EC",
  la: "es_GT",
  la: "es_PE",
  la: "es_BO",
  la: "es_NI",
  la: "es_VE",
  la: "es_PA",
  la: "es_CR",
  la: "es_UY",
  la: "es_PY",
  lae: "en_AG",
  lae: "en_BS",
  lae: "en_TC",
  lae: "en_TT",
  lae: "en_SR",
  lae: "en_BM",
  lae: "en_BB",
  lae: "en_BZ",
  lae: "en_GD",
  lae: "en_KY",
  lae: "en_LC",
  lae: "en_MS",
  lae: "en_KN",
  lae: "en_AI",
  lae: "en_VC",
  lae: "en_GY",
  lae: "en_VG",
  lae: "en_PR",
  lae: "en_DM",
  lae: "en_JM",
  li: "de_LI",
  lt: "lt_LT",
  lu: "fr_LU",
  lv: "lv_LV",
  ma: "fr_MA",
  md: "en_MD",
  me: "en_ME",
  mg: "en_MG",
  mk: "en_MK",
  ml: "fr_ML",
  mo: "zh_MO",
  mt: "en_MT",
  mu: "fr_MU",
  mx: "es_MX",
  my: "en_MY",
  mz: "en_MZ",
  ne: "fr_NE",
  ng: "en_NG",
  nl: "nl_NL",
  no: "no_NO",
  nz: "en_NZ",
  "om-ar": "ar_OM",
  om: "en_OM",
  ph: "en_PH",
  pl: "pl_PL",
  pt: "pt_PT",
  "qa-ar": "ar_QA",
  qa: "en_QA",
  ro: "ro_RO",
  ru: "ru_RU",
  "sa-ar": "ar_SA",
  sa: "en_SA",
  se: "sv_SE",
  sg: "en_SG",
  si: "en_SI",
  sk: "sk_SK",
  sn: "fr_SN",
  th: "th_TH",
  tj: "en_TJ",
  tm: "en_TM",
  tn: "fr_TN",
  tr: "tr_TR",
  tw: "zh_TW",
  ua: "uk_UA",
  ug: "en_UG",
  uk: "en_GB",
  us: "en_US",
  uz: "en_UZ",
  vn: "en_VN",
  za: "en_ZA",
};

// keep synced with res functions
const currencies = {
  gbp: {
    name: "British pound",
    symbol: "£",
    symbolRight: false,
    code: "gbp",
    minValue: 5,
    minChargeValue: 0.3,
    stepValue: 1,
    initialValue: 20,
    limitValue: 50,
  },
  eur: {
    name: "Euro",
    symbol: "€",
    symbolRight: false,
    code: "eur",
    minValue: 5,
    minChargeValue: 0.5,
    stepValue: 1,
    initialValue: 20,
    limitValue: 50,
  },
  bgn: {
    name: "Bulgarian lev",
    symbol: "лв",
    symbolRight: true,
    code: "bgn",
    minValue: 15,
    minChargeValue: 1,
    stepValue: 5,
    initialValue: 40,
    limitValue: 100,
  },
  chf: {
    name: "Swiss franc",
    symbol: "Fr",
    symbolRight: true,
    code: "chf",
    minValue: 10,
    minChargeValue: 0.5,
    stepValue: 1,
    initialValue: 20,
    limitValue: 50,
  },
  nok: {
    name: "Norwegian krone",
    symbol: "-kr.",
    symbolRight: true,
    code: "nok",
    minValue: 100,
    minChargeValue: 3,
    stepValue: 20,
    initialValue: 300,
    limitValue: 1000,
  },
  sek: {
    name: "Swedish krona",
    symbol: "-kr.",
    symbolRight: true,
    code: "sek",
    minValue: 100,
    minChargeValue: 3,
    stepValue: 20,
    initialValue: 300,
    limitValue: 1000,
  },
  dkk: {
    name: "Danish krone",
    symbol: "-kr.",
    symbolRight: true,
    code: "dkk",
    minValue: 50,
    minChargeValue: 2.5,
    stepValue: 10,
    initialValue: 200,
    limitValue: 500,
  },
  czk: {
    name: "Czech koruna",
    symbol: "Kč",
    symbolRight: true,
    code: "czk",
    minValue: 150,
    minChargeValue: 15,
    stepValue: 50,
    initialValue: 500,
    limitValue: 1500,
  },
  pln: {
    name: "Polish zloty",
    symbol: " zł",
    symbolRight: true,
    code: "pln",
    minValue: 30,
    minChargeValue: 2,
    stepValue: 10,
    initialValue: 100,
    limitValue: 300,
  },
  rub: {
    name: "Russian ruble",
    symbol: "₽",
    symbolRight: true,
    code: "rub",
    minValue: 500,
    minChargeValue: 100,
    stepValue: 100,
    initialValue: 1500,
    limitValue: 5000,
  },
  zar: {
    name: "South African rand",
    symbol: "R",
    symbolRight: false,
    code: "zar",
    minValue: 100,
    minChargeValue: 20,
    stepValue: 10,
    initialValue: 300,
    limitValue: 900,
  },
  usd: {
    name: "US dollar",
    symbol: "$",
    symbolRight: false,
    code: "usd",
    minValue: 10,
    minChargeValue: 0.5,
    stepValue: 1,
    initialValue: 20,
    limitValue: 50,
  },
  amd: {
    name: "Armenian dram",
    symbol: "դր",
    symbolRight: true,
    code: "amd",
    minValue: 3500,
    minChargeValue: 1000,
    stepValue: 500,
    initialValue: 5000,
    limitValue: 33000,
  },
  uah: {
    name: "Ukrainian hryvnia",
    symbol: "₴",
    symbolRight: false,
    code: "uah",
    minValue: 180,
    minChargeValue: 50,
    stepValue: 50,
    initialValue: 350,
    limitValue: 1800,
  },
	aud: {
    name: "Australian dollar",
    symbol: "$",
    symbolRight: false,
    code: "aud",
    minValue: 10,
    minChargeValue: 0.5,
    stepValue: 5,
    initialValue: 50,
    limitValue: 100,
  },
	cad: {
    name: "Canadian dollar",
    symbol: "$",
    symbolRight: false,
    code: "cad",
    minValue: 10,
    minChargeValue: 0.5,
    stepValue: 5,
    initialValue: 50,
    limitValue: 100,
  },
	aed: {
    name: "UAE dirham",
    symbol: "د.إ",
    symbolRight: true,
    code: "aed",
    minValue: 50,
    minChargeValue: 5,
    stepValue: 10,
    initialValue: 200,
    limitValue: 500,
  },
  cny: {
    name: "Chinese Yuan",
    symbol: "¥",
    symbolRight: false,
    code: "cny",
    minValue: 50,
    minChargeValue: 10,
    stepValue: 10,
    initialValue: 150,
    limitValue: 500,
  },
};

const languages = {
  en: {
		name: "English",
		nameEnglish: "English",
		language: "en",
	},
	it: {
		name: "Italiano",
		nameEnglish: "Italian",
		language: "it",
	},
	bg: {
		name: "Бългapcки",
		nameEnglish: "Bulgarian",
		language: "bg",
		hide: true,
	},
	ru: {
		name: "Русский",
		nameEnglish: "Russian",
		language: "ru",
		hide: true,
	},
	fr: {
		name: "Français",
		nameEnglish: "French",
		language: "fr",
		hide: true,
	},
	de: {
		name: "Deutsche",
		nameEnglish: "German",
		language: "de",
		hide: true,
	},
	es: {
		name: "Español",
		nameEnglish: "Spanish",
		language: "es",
		hide: true,
	},
};

const countryData = {
  "AE": {
    "countryCode": "AE",
    "defaultLanguage": null
  },
  "AM": {
    "countryCode": "AM",
		"defaultCurrency": "amd",
    "defaultLanguage": null
  },
  "AT": {
    "countryCode": "AT",
    "defaultLanguage": null
  },
  "AU": {
    "countryCode": "AU",
    "defaultLanguage": null
  },
  "AZ": {
    "countryCode": "AZ",
    "defaultLanguage": null
  },
  "BE": {
    "countryCode": "BE",
    "defaultLanguage": null
  },
  "BG": {
    "countryCode": "BG",
    "defaultCurrency": "bgn",
    "defaultLanguage": "bg"
  },
  "BH": {
    "countryCode": "BH",
    "defaultLanguage": null
  },
  "BR": {
    "countryCode": "BR",
    "defaultLanguage": null
  },
  "BW": {
    "countryCode": "BW",
    "defaultLanguage": null
  },
  "BY": {
    "countryCode": "BY",
    "defaultLanguage": null
  },
  "CA": {
    "countryCode": "CA",
    "defaultLanguage": null
  },
  "CF": {
    "countryCode": "CF",
    "defaultLanguage": null
  },
  "CH": {
    "countryCode": "CH",
    "defaultLanguage": null
  },
  "CI": {
    "countryCode": "CI",
    "defaultLanguage": null
  },
  "CL": {
    "countryCode": "CL",
    "defaultLanguage": null
  },
  "CM": {
    "countryCode": "CM",
    "defaultLanguage": null
  },
  "CO": {
    "countryCode": "CO",
    "defaultLanguage": null
  },
  "CZ": {
    "countryCode": "CZ",
    "defaultLanguage": null
  },
  "DE": {
    "countryCode": "DE",
    "defaultCurrency": "eur",
    "defaultLanguage": null
  },
  "DK": {
    "countryCode": "DK",
    "defaultLanguage": null
  },
  "EE": {
    "countryCode": "EE",
    "defaultLanguage": null
  },
  "EG": {
    "countryCode": "EG",
    "defaultLanguage": null
  },
  "ES": {
    "countryCode": "ES",
    "defaultCurrency": "eur",
    "defaultLanguage": null
  },
  "FI": {
    "countryCode": "FI",
    "defaultLanguage": null
  },
  "FR": {
    "countryCode": "FR",
    "defaultCurrency": "eur",
    "defaultLanguage": null
  },
  "GE": {
    "countryCode": "GE",
    "defaultLanguage": null
  },
  "GN": {
    "countryCode": "GN",
    "defaultLanguage": null
  },
  "GQ": {
    "countryCode": "GQ",
    "defaultLanguage": null
  },
  "GR": {
    "countryCode": "GR",
    "defaultLanguage": null
  },
  "GW": {
    "countryCode": "GW",
    "defaultLanguage": null
  },
  "HK": {
    "countryCode": "HK",
    "defaultLanguage": null
  },
  "HR": {
    "countryCode": "HR",
    "defaultLanguage": null
  },
  "HU": {
    "countryCode": "HU",
    "defaultLanguage": null
  },
  "ID": {
    "countryCode": "ID",
    "defaultLanguage": null
  },
  "IE": {
    "countryCode": "IE",
    "defaultLanguage": null
  },
  "IL": {
    "countryCode": "IL",
    "defaultLanguage": null
  },
  "IN": {
    "countryCode": "IN",
    "defaultLanguage": null
  },
  "IT": {
    "countryCode": "IT",
    "defaultCurrency": "eur",
    "defaultLanguage": "it"
  },
  "JO": {
    "countryCode": "JO",
    "defaultLanguage": null
  },
  "JP": {
    "countryCode": "JP",
    "defaultLanguage": null
  },
  "KE": {
    "countryCode": "KE",
    "defaultLanguage": null
  },
  "KG": {
    "countryCode": "KG",
    "defaultLanguage": null
  },
  "KR": {
    "countryCode": "KR",
    "defaultLanguage": null
  },
  "KW": {
    "countryCode": "KW",
    "defaultLanguage": null
  },
  "KZ": {
    "countryCode": "KZ",
    "defaultLanguage": null
  },
  "PY": {
    "countryCode": "PY",
    "defaultLanguage": null
  },
  "JM": {
    "countryCode": "JM",
    "defaultLanguage": null
  },
  "LI": {
    "countryCode": "LI",
    "defaultLanguage": null
  },
  "LT": {
    "countryCode": "LT",
    "defaultLanguage": null
  },
  "LU": {
    "countryCode": "LU",
    "defaultLanguage": null
  },
  "LV": {
    "countryCode": "LV",
    "defaultLanguage": null
  },
  "MA": {
    "countryCode": "MA",
    "defaultLanguage": null
  },
  "MD": {
    "countryCode": "MD",
    "defaultLanguage": null
  },
  "ME": {
    "countryCode": "ME",
    "defaultLanguage": null
  },
  "MG": {
    "countryCode": "MG",
    "defaultLanguage": null
  },
  "MK": {
    "countryCode": "MK",
    "defaultLanguage": null
  },
  "ML": {
    "countryCode": "ML",
    "defaultLanguage": null
  },
  "MO": {
    "countryCode": "MO",
    "defaultLanguage": null
  },
  "MT": {
    "countryCode": "MT",
    "defaultLanguage": null
  },
  "MU": {
    "countryCode": "MU",
    "defaultLanguage": null
  },
  "MX": {
    "countryCode": "MX",
    "defaultLanguage": null
  },
  "MY": {
    "countryCode": "MY",
    "defaultLanguage": null
  },
  "MZ": {
    "countryCode": "MZ",
    "defaultLanguage": null
  },
  "NE": {
    "countryCode": "NE",
    "defaultLanguage": null
  },
  "NG": {
    "countryCode": "NG",
    "defaultLanguage": null
  },
  "NL": {
    "countryCode": "NL",
    "defaultLanguage": null
  },
  "NO": {
    "countryCode": "NO",
    "defaultLanguage": null
  },
  "NZ": {
    "countryCode": "NZ",
    "defaultLanguage": null
  },
  "OM": {
    "countryCode": "OM",
    "defaultLanguage": null
  },
  "PH": {
    "countryCode": "PH",
    "defaultLanguage": null
  },
  "PL": {
    "countryCode": "PL",
    "defaultLanguage": null
  },
  "PT": {
    "countryCode": "PT",
    "defaultCurrency": "eur",
    "defaultLanguage": null
  },
  "QA": {
    "countryCode": "QA",
    "defaultLanguage": null
  },
  "RO": {
    "countryCode": "RO",
    "defaultLanguage": null
  },
  "RU": {
    "countryCode": "RU",
    "defaultCurrency": "rub",
    "defaultLanguage": null
  },
  "SA": {
    "countryCode": "SA",
    "defaultLanguage": null
  },
  "SE": {
    "countryCode": "SE",
    "defaultLanguage": null
  },
  "SG": {
    "countryCode": "SG",
    "defaultLanguage": null
  },
  "SI": {
    "countryCode": "SI",
    "defaultLanguage": null
  },
  "SK": {
    "countryCode": "SK",
    "defaultLanguage": null
  },
  "SN": {
    "countryCode": "SN",
    "defaultLanguage": null
  },
  "TH": {
    "countryCode": "TH",
    "defaultLanguage": null
  },
  "TJ": {
    "countryCode": "TJ",
    "defaultLanguage": null
  },
  "TM": {
    "countryCode": "TM",
    "defaultLanguage": null
  },
  "TN": {
    "countryCode": "TN",
    "defaultLanguage": null
  },
  "TR": {
    "countryCode": "TR",
    "defaultLanguage": null
  },
  "TW": {
    "countryCode": "TW",
    "defaultLanguage": null
  },
  "UA": {
    "countryCode": "UA",
    "defaultCurrency": "uah",
    "defaultLanguage": null
  },
  "UG": {
    "countryCode": "UG",
    "defaultLanguage": null
  },
  "GB": {
    "countryCode": "GB",
    "defaultCurrency": "gbp",
    "defaultLanguage": "en"
  },
  "US": {
    "countryCode": "US",
    "defaultCurrency": "usd",
    "defaultLanguage": null
  },
  "UZ": {
    "countryCode": "UZ",
    "defaultLanguage": null
  },
  "VN": {
    "countryCode": "VN",
    "defaultLanguage": null
  },
  "ZA": {
    "countryCode": "ZA",
    "defaultCurrency": "zar",
    "defaultLanguage": null
  }
};

const localeOptions = [];
const langLocaleOptions = [];
const langOptions = []; // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
const supportedLanguages = ["en", "it", ];

const areaServedOptions = ["GB", "IT", "BG", "ZA", ]; // https://en.wikipedia.org/wiki/ISO_3166-1

for (const [key, value] of Object.entries(localeDictionary)) {
  // console.log(`${key}: ${value}`);

	if (!localeOptions.includes(key)) {
		localeOptions.push(key);
	}

	if (!langLocaleOptions.includes(value)) {
		langLocaleOptions.push(value);
	}

	// keep this to comer all languages and country variations
	if (!langOptions.includes(value.substring(0, 2).toLowerCase())) {
		langOptions.push(value.substring(0, 2).toLowerCase());
	}
	if (!langOptions.includes(value.replace(/_/g, "-").toLowerCase())) {
		langOptions.push(value.replace(/_/g, "-").toLowerCase());
	}

}

const localePathOptions = localeOptions.join("|");
// console.log("localePathOptions", localePathOptions);
// console.log("langLocaleOptions", langLocaleOptions);
// console.log("langOptions", langOptions);
// console.log("countryData", countryData);



export default localeDictionary;
export { localeOptions, localePathOptions, areaServedOptions, langLocaleOptions, langOptions, countryData, languages, currencies, supportedLanguages };
