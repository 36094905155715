import { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Checkbox, Table, Spinner } from 'evergreen-ui';
import _, { includes, sortedLastIndex } from 'lodash';
import styled from "styled-components";

import InfiniteScroll from "react-infinite-scroll-component";
import FacePH from '../images/FacePH';

import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';
import AspectRatio from './AspectRatio';
import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import FeedbackImpactForm, { newFeedbackImpactBreakdown } from './FeedbackImpactForm';
import CurrencyNumberInput from './CurrencyNumberInput';

import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";

import { useGetTransactions } from "../services/useGetTransactions";

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { API, I18n} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const ResponsivePane = styled(Pane)`
  text-align: right;
  @media only screen and (max-width: ${425}px) {
    text-align: left;
  }
`;

const AccountRecipients = () => {
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(true);
  const [localLoadState, setLocalLoadState] = useState({
    total: null,
    nextToken: null,
    error: null,
  });
  const [accountHistory, setAccountHistory] = useState([]);

  const fetchMoreData = () => {
    console.log("fetchMoreData...");
    setTriggerLoad(true);
  };
  
  useEffect(() => {
    if (userState.actAsUser?.publicFaceId) {
      setAccountHistory([]);
      setTriggerLoad(true);
    }
  }, [userState.actAsUser?.publicFaceId]);

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        // toPublicFaceId: userDetails?.publicFaceId,
        // fromPublicFaceId: userDetails?.publicFaceId,
        // viaPublicFaceId: userDetails?.publicFaceId, // ???
        type: "recipient",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          setAccountHistory([...accountHistory, ...searchResult.results]);

          console.log("recipient accountHistory ", [...accountHistory, ...searchResult.results]);

          setLocalLoadState({
            ...localLoadState,
            total: searchResult.total,
            nextToken: accountHistory.length === localLoadState.total ? null : searchResult.nextToken,
            error: null,
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [accountHistory, handleGetTransactions, localLoadState, localeState.currency, localeState.locale, triggerLoad]);

  const [addNewPartner, setAddNewPartner] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [newPartnerPublicFaceId, setNewPartnerPublicFaceId] = useState();

  const handleUserAddPartner = useCallback(async( { toPublicFaceId = null }) => {
    try {
      // const response = await API.graphql({
      //   query: mutations.userAddPartner,
      //   variables: {
      //     toPublicFaceId: toPublicFaceId,
      //     fromPublicFaceId: userDetails?.publicFaceId,
      //   },
      //   authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      // });

      // let partnerTransaction = response.data.userAddPartner;

      setAddNewPartner(false);
      setProcessing(false);

    }
    catch(error) {
      console.error("with userAddPartner", error);
      // handle ???
      setProcessing(false);
    }
  }, [userDetails]);



  return(
    <Fragment>

      <Pane alignItems="center" display="flex" marginTop={20} marginBottom={0} height={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Recipients')}</Strong>
        {processing ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
        {(!userDetails.rights?.adminPanel && !userState.user?.rights?.adminPanel) || addNewPartner ? null :
          <Button flex="none" marginLeft={"auto"} marginRight={0} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" className="noselect"
            disabled={processing}
            onClick={() => {
              setAddNewPartner(true);
            }}
          >
            {I18n.get('Add new recipeint')}
          </Button>
        }
      </Pane>

      {!addNewPartner ? null :
        <Fragment>
          <Pane height={20} ></Pane>

          <DynamicInputField
            label={I18n.get('Name')}
            inputName="charity-number"
            type="text"
            disabled={processing}
            placeholder={`${I18n.get('Public ID')}...`}
            value={null}
            onChange={(value) => {
              if (value) {
                let temp = value.trim();
                if (temp.length > 30) {
                  // setAccountCharityNoInvalidMessage(I18n.get('Too long.'));
                }
                else {
                  // setAccountCharityNoInvalidMessage();
                }
              }
              setNewPartnerPublicFaceId(value);
            }}
            onBlur={(e) => {
              setNewPartnerPublicFaceId(newPartnerPublicFaceId?.trim());
            }}
            // messageWhenInvalid={accountCharityNoInvalidMessage}
          />

          <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        
            <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

            <Pane flex={1} flexBasis={242} maxWidth={390} >
              {processing ?
                <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
                  <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                </Pane>
              :
                <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap">
                  <Pane flex="none" marginRight={36} >
                    <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                      disabled={processing}
                      onClick={() => {
                        handleUserAddPartner({
                          toPublicFaceId: newPartnerPublicFaceId
                        });
                      }}
                    >
                      {I18n.get('Submit')}
                    </Button>
                  </Pane>

                </Pane>
              }
            </Pane>
          </Pane>
        </Fragment>
      }

    <Table marginTop={10} is={"table"} width={"100%"} >

      {!localLoadState.total || localLoadState?.error ?
        localLoadState?.error ?
          <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
            <Text fontSize={14} color="#7B8B9A">{I18n.get("Error")}</Text>
          </Pane>
        :
          localLoadState.total === 0 ?
            <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
              <Text fontSize={14} color="#7B8B9A">{I18n.get("No history")}</Text>
            </Pane>
          :
            <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
              <Spinner size={24} />
            </Pane>
      :
        <InfiniteScroll
          dataLength={accountHistory.length}
          next={fetchMoreData}
          hasMore={accountHistory.length < localLoadState.total}
          scrollThreshold={0.6}
          // scrollableTarget="scrollableDiv"
          height={240}
          // loader={<h4>Loading...</h4>}
        >
          <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
            {accountHistory.map((transaction) => (
              <Table.Row is={"tr"} width={"100%"} key={transaction.publicTransactionId}
                // isSelectable
                // onSelect={() => alert(transaction.name)}
              >


                <Table.TextCell is={"td"} textAlign="left"
                  textProps={{
                    color: "#283655",
                    fontSize: 16,
                    lineHeight: 1.1,
                  }}
                  paddingX={5}
                >{
                  <Fragment>
                    <Pane width={60} height={60} marginLeft="auto" marginRight="auto" >
                      <AspectRatio ratio={1/1} >
                        {transaction.toFace?.pictureUrl ?
                          <img width="100%" height="auto" src={transaction.toFace?.pictureUrl} alt="" style={{borderRadius: "inherit"}}/>
                        :
                          <Pane
                            // position="absolute"
                            // zIndex={1}
                            // width="100%"
                            // height="100%"
                            
                            display="flex"
                            border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB"
                            borderRadius={"50%"}
                            // is border needed, it doesn't seem to work here ???
                          >
                            <FacePH color={true ? "#7B8B9A" : "#E4E7EB"} width="100%" height="100%" />
                          </Pane>
                        }
                      </AspectRatio>
										</Pane>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell is={"td"} textAlign="left"
                  textProps={{
                    color: "#283655",
                    fontSize: 16,
                    lineHeight: 1.1,
                  }}
                  paddingX={5}
                >{
                  <Fragment>
                    <Strong fontSize={16} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                    <Table.TextCell is={"td"}
                      textAlign="left"
                      textProps={{
                        color: "#7B8B9A",
                        fontSize: 14,
                        lineHeight: 1.3,
                      }}
                      paddingX={0}
                    >{transaction.toPublicFaceId}</Table.TextCell>
                  </Fragment>
                }</Table.TextCell>

              </Table.Row>
            ))}
            {accountHistory.length < localLoadState.total ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                <Spinner size={24} />
              </Pane>
            : null }
          </Table.Body>
        </InfiniteScroll>
      }

      <Table.Head is={"tfoot"}
        height={"auto"}
        style={{
          border: "none",
          background: "none",
        }}
      >
      </Table.Head>

    </Table>
    </Fragment>
    
  );
}

const AccountRecordAllocationMode = ({ ...rest }) => {

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    // console.log("userDetails updates");
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const userDetailsSectionRecords = useMemo(() => {

    const allocationAmountsRecords = userDetails?.records?.filter(record => record.type === "allocationAmounts") || [];

    const mainAllocationAmountsRecord = {
      type: "allocationAmounts",
      amounts: allocationAmountsRecords?.[0]?.amounts || [],
      currency: allocationAmountsRecords?.[0]?.currency || localeState.currency,
    };

    const userDetailsAllocationModeRecord = userDetails?.records?.find(record => record.type === "allocationMode");

    const tempResArray = [
      {
        type: mainAllocationAmountsRecord?.type || "allocationAmounts",
        amounts: mainAllocationAmountsRecord?.amounts,
        currency: mainAllocationAmountsRecord?.currency,
      },
      {
        type: userDetailsAllocationModeRecord?.type || "allocationMode",
        isDefault: userDetailsAllocationModeRecord?.isDefault || false,
      }
    ];

    if (userDetails.rights?.anonymiseRecipients) {
      const userDetailsAnonymiseAllocationRecipientsRecord = userDetails?.records?.find(record => record.type === "anonymiseAllocationRecipients");
      tempResArray.push({
        type: userDetailsAnonymiseAllocationRecipientsRecord?.type || "anonymiseAllocationRecipients",
        isDefault: userDetailsAnonymiseAllocationRecipientsRecord?.isDefault || false,
      });
    }
    
    return tempResArray;

  }, [localeState.currency, userDetails?.records, userDetails.rights?.anonymiseRecipients]);

  const [updatedUserDetailsSectionRecords, setUpdatedUserDetailsSectionRecords] = useState(userDetailsSectionRecords);

  useEffect(() => {
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
  }, [userDetailsSectionRecords]);

  
  const updateUserRecords = (recordType, updatedValues) => {
    let updatedRecords = updatedUserDetailsSectionRecords || [];
    let currentIndex = null;
    let currentRecord = updatedRecords?.find((record, index) => {
			if (record.type === recordType) {
        currentIndex = index;
				return record;
			}
			else {
				return false;
			}
		});

    let updatedRecord = {
      ...currentRecord,
      ...updatedValues,
      type: recordType,
    };

    updatedRecords.splice(currentIndex, currentIndex !== null ? 1 : 0, updatedRecord);
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(updatedRecords)); // needed to trigger render, otherwise change in array doesn't trigger
  }

  const handleUpdateUserDetails = useAuthUpdateUserDetails();
  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  const [processing, setProcessing] = useState(false);

  const [newAllocationAmount, setNewAllocationAmount] = useState();
  
  const handleAddNewAllocationAmount = () => {
    let currentAllocationAmounts = updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts");
    
    if (!currentAllocationAmounts?.amounts?.includes(newAllocationAmount)) {
      let tempAmounts = [...currentAllocationAmounts?.amounts, newAllocationAmount];
      tempAmounts.sort((a, b) => {
        return a - b;
      });
      updateUserRecords("allocationAmounts", {
        amounts: tempAmounts,
      });
    }
    else {
      return null;
    }
    setNewAllocationAmount();
  }

  const handleRemoveAllocationAmount = (amountToRemove) => {
    let currentAllocationAmounts = updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts");
    let tempAmounts = [...currentAllocationAmounts?.amounts];
    let tempCurrentIndex = null;
    tempAmounts?.find((amount, index) => {
      if (amount === amountToRemove) {
        tempCurrentIndex = index;
        return amount;
      }
      else {
        return false;
      }
    });
    if (Number.isInteger(tempCurrentIndex)) {
      tempAmounts.splice(tempCurrentIndex, 1);
    }
    
    updateUserRecords("allocationAmounts", {
      amounts: tempAmounts || [],
    });
  }


  return (
    <Pane {...rest} >

      <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Allocation mode')}</Strong>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("A simplified way for making donations from your balance.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      {/* <Pane alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
          <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Amounts')}</Strong> 
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
          {!updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.amounts?.length ? null :
            <Fragment>
              <Pane maxWidth={380} alignItems="center" display="flex" >

                <Pane
                  display={"flex"}
                  flexWrap={"wrap"}
                  gap={10}
                >
                  {updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.amounts.map((amount) => (
                    <Pane display={"flex"}>{
                      <Fragment>
                        <Pane
                          paddingX={8}
                          height={32}
                          border
                          borderWidth={1}
                          borderStyle="solid"
                          borderColor="#C7CED4"
                          justifyContent="center"
                          background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                          borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                          className="noselect"
                          display={"flex"}
                          alignItems="center"
                        >
                          <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(amount, updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.currency, false, false)}</Strong>
                        </Pane>
                        <Button marginLeft={-1} width={32} height={32} padding={0} disabled={processing} justifyContent="center" className="noselect"
                          borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                          style={{
                            boxShadow: "none",
                            border: "solid",
                            borderWidth: "1px",
                            borderColor: "#C7CED4",
                          }}
                          onClick={() => {
                            handleRemoveAllocationAmount(amount);
                          }}
                        >
                          <SmallCrossIcon size={20} color="#425A70" />
                        </Button>
                      </Fragment>
                    }</Pane>
                  ))}
                </Pane>

              </Pane>

              <Pane marginTop={4} marginBottom={10} >
                <Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >{I18n.get("These amounts will appear as buttons when making an allocation. You will still be able to input any other amount manually.")}</Text>
              </Pane>
            </Fragment>
          }
          

          {updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.amounts?.length < 15 ?

            <Pane maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" className="noselect" >

              {currencies[updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.currency]?.symbolRight ? null :
                <Pane
                  paddingX={8}
                  height={32}
                  border
                  borderWidth={1}
                  borderStyle="solid"
                  borderColor="#C7CED4"
                  justifyContent="center"
                  background={"#F9F9FB"} // #F9F9FB // #E4E7EB
                  borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                  className="noselect"
                  display={"flex"}
                  alignItems="center"
                >
                  <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{currencies[updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.currency]?.symbol}</Strong>
                </Pane>
              }
              
              <NumericFormat
                // getInputRef={(el) => {allocationAmountInputRef.current = el}}
                customInput={TextInput}
                min="0" step="1"
                format={"#############"}
                thousandSeparator={true}
                // id={"allocation-amount"}
                type={"text"}
                pattern={"[0-9]*"} // to bring up the numeric keypad
                inputMode="numeric" // to bring up the numeric keypad
                // autoComplete="off" // doesn't seem to work
                
                // autoComplete={"allocation-amount"}
                // name={"allocation-amount"}
                disabled={processing} 
                // isInvalid={localIsInvalid}
                marginTop={0}
                marginBottom={0}
                width={80}
                height={32}
                fontSize={14}
                color={"#283655"}
                placeholder={`${I18n.get('Amount')}...`}

                paddingY={0}
                marginLeft={-1}
                marginRight={-1}
                
                borderRadius={0}
                // style={{
                //   boxShadow: "none",
                //   border: "solid",
                //   borderWidth: "1px",
                //   borderColor: "#C7CED4",
                //   borderRight: "0px",
                // }}
                
                // border={"solid"}
                // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}

                value={newAllocationAmount || ""}
                defaultValue={null}
                onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                  let temp = values.value;
                  if (temp && temp > 0 && temp !== "") {
                    let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                    setNewAllocationAmount(newAmount);;
                  }
                  else {
                    setNewAllocationAmount();
                  }
                }}
                // onBlur={(e) => {}}
              />
              {!currencies[updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.currency]?.symbolRight ? null :
                <Pane
                  paddingX={8}
                  height={32}
                  justifyContent="center"
                  background={"#F9F9FB"}
                  borderRadius={0}
                  className="noselect"
                  display={"flex"}
                  alignItems="center"
                  
                >
                  <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{currencies[updatedUserDetailsSectionRecords?.find(record => record.type === "allocationAmounts")?.currency]?.symbol}</Strong>
                </Pane>
              }

              <Button width={"auto"} height={32} paddingX={8}
                disabled={processing || !newAllocationAmount} justifyContent="center" className="noselect"
                style={{
                  boxShadow: "none",
                  border: "solid",
                  borderWidth: "1px",
                  borderColor: "#C7CED4",
                }}
                
                borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                onClick={handleAddNewAllocationAmount}
              >
                <Strong fontSize={14} lineHeight={1} display={"block"} >{I18n.get('Add amount')}</Strong>
              </Button>

              

            </Pane>
          

          :
            <Pane marginTop={4} >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("Please save up to 15 allocation amounts.")}</Text>
            </Pane>
          }
          
        </Pane>
      </Pane> */}

      <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} />
        <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
          <Pane maxWidth={380} alignItems="center" display="flex" >
            <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
              <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                label={""}
                disabled={processing}
                checked={updatedUserDetailsSectionRecords?.find(record => record.type === "allocationMode")?.isDefault}
                onChange={(e) => {
                  updateUserRecords("allocationMode" , {
                    isDefault: e.target.checked,
                  });
                }}
              />
              <Pane flex={1} marginTop={2} >
                <Text fontSize={14} color="#283655">{I18n.get('Show "Allocate" as the main action button')}</Text>
              </Pane>
            </Pane>
          </Pane>

          <Pane marginTop={2} >
            <Text fontSize={14} color="#7B8B9A" >{I18n.get('Instead of the "Donate" button.')}</Text>
          </Pane>
        </Pane>
      </Pane>

      {/* {!userDetails.rights?.anonymiseRecipients ? null :

        <Pane paddingTop={20} alignItems="flex-start" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} />
          <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
            <Pane maxWidth={380} alignItems="center" display="flex" >
              <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                  label={""}
                  disabled={processing}
                  checked={updatedUserDetailsSectionRecords?.find(record => record.type === "anonymiseAllocationRecipients")?.isDefault}
                  onChange={(e) => {
                    updateUserRecords("anonymiseAllocationRecipients", {
                      isDefault: e.target.checked,
                    });
                  }}
                  
                />
                <Pane flex={1} marginTop={2} >
                  <Text fontSize={14} color="#283655">{I18n.get("Anonymise recipients")}</Text>
                </Pane>
              </Pane>
            </Pane>

            <Pane marginTop={2} >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("Only you will know who the recipients of your donations are.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      } */}

      {/* {!userDetails.rights?.anonymiseRecipients ? null :

        <Pane paddingTop={20} alignItems="flex-start" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} />
          <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
            <Pane maxWidth={380} alignItems="center" display="flex" >
              <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                  label={""}
                  disabled={processing}
                  // checked={updatedUserDetailsSectionRecords?.find(record => record.type === "anonymiseAllocationRecipients")?.isDefault}
                  onChange={(e) => {
                    // updateUserRecords("anonymiseAllocationRecipients", {
                    //   isDefault: e.target.checked,
                    // });
                  }}
                  
                />
                <Pane flex={1} marginTop={2} >
                  <Text fontSize={14} color="#283655">{I18n.get("Do not share feedback on how allocations are used")}</Text>
                </Pane>
              </Pane>
            </Pane>

            <Pane marginTop={2} >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("You will still be notified how your allocations are used.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      } */}

      {/* {!userDetails.rights?.anonymiseRecipients ? null :

        <Pane paddingTop={20} alignItems="flex-start" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} />
          <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
            <Pane maxWidth={380} alignItems="center" display="flex" >
              <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                  label={""}
                  disabled={processing}
                  // checked={updatedUserDetailsSectionRecords?.find(record => record.type === "anonymiseAllocationRecipients")?.isDefault}
                  onChange={(e) => {
                    // updateUserRecords("anonymiseAllocationRecipients", {
                    //   isDefault: e.target.checked,
                    // });
                  }}
                  
                />
                <Pane flex={1} marginTop={2} >
                  <Text fontSize={14} color="#283655">{I18n.get("Do not notify donors when allocations are used")}</Text>
                </Pane>
              </Pane>
            </Pane>

            <Pane marginTop={2} >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("...")}</Text>
            </Pane>
          </Pane>
        </Pane>
      } */}

      {/* If you allocate donated to you funds, your donors will still be notified when you make allocations and when the funds are used by the recipients. However, the recipients will be anonymised and the feedback to the donors will be based on your feedback presets. */}

      <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={processing ? null : 'button-blue'}
                disabled={processing || _.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords)}
                onClick={() => {
                  setProcessing(true);
                  handleUpdateUserDetails({
                    // userRecordsToUpdate: {},
                    recordsToUpdate: _.differenceWith(updatedUserDetailsSectionRecords, userDetailsSectionRecords, _.isEqual),
                    onSuccess: (freshUserDetails) => {
                      setProcessing(false);
                    },
                    onError: (error) => {
                      setProcessing(false);
                    },
                  });
                }}
              >
                {!processing ?
                  I18n.get("Save")
                :
                  <Fragment >
                    <Spinner marginRight={8} flex="none" size={24} />
                    <Pane >
                      {`${I18n.get('Saving')}...`}
                    </Pane>
                  </Fragment>
                }
              </Button>
            </Pane>

            {_.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords) || processing ? null :
              <Button justifyContent="center" paddingX={12} height={30}
                disabled={processing}
                onClick={() => {
                  setNewAllocationAmount();
                  setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
                }}
              >
                {I18n.get("Discard")}
              </Button>
            }

          </Pane>
        </Pane>
      </Pane>
      
    </Pane>
  );
}

const AccountCharityDetails = ({ ...rest }) => {

  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);
  
  const userDetailsSectionRecords = useMemo(() => {

    const userDetailsCharityDetailsRecord = userDetails?.records?.find(record => record.type === "charityDetails");
    const userDetailsGiftAidAcceptanceRecord = userDetails?.records?.find(record => record.type === "giftAidAcceptance");

    let sectionRecords = [{
      ...userDetailsCharityDetailsRecord,
      type: userDetailsCharityDetailsRecord?.type || "charityDetails",
      regNumber: userDetailsCharityDetailsRecord?.regNumber || undefined,
      regName: userDetailsCharityDetailsRecord?.regName || undefined,
    }];

    if (userDetails.rights?.acceptGiftAid) {
      sectionRecords.push({
        type: userDetailsGiftAidAcceptanceRecord?.type || "giftAidAcceptance",
        mayBeAccepted: userDetailsGiftAidAcceptanceRecord?.mayBeAccepted || false,
      });
    }
    
    return sectionRecords;

  }, [userDetails?.records, userDetails.rights?.acceptGiftAid]);

  const [updatedUserDetailsSectionRecords, setUpdatedUserDetailsSectionRecords] = useState(userDetailsSectionRecords);

  useEffect(() => {
    // setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
    setUpdatedUserDetailsSectionRecords([...userDetailsSectionRecords]);
  }, [userDetailsSectionRecords]);

  
  const updateUserRecords = (recordType, updatedValues) => {
    let updatedRecords = updatedUserDetailsSectionRecords || [];
    let currentIndex = null;
    let currentRecord = updatedRecords?.find((record, index) => {
			if (record.type === recordType) {
        currentIndex = index;
				return record;
			}
			else {
				return false;
			}
		});

    let updatedRecord = {
      ...currentRecord,
      ...updatedValues,
      type: recordType,
    };

    updatedRecords.splice(currentIndex, currentIndex !== null ? 1 : 0, updatedRecord);
    // setUpdatedUserDetailsSectionRecords(_.cloneDeep(updatedRecords)); // _.cloneDeep is needed to trigger render, otherwise change in array doesn't trigger
    setUpdatedUserDetailsSectionRecords([...updatedRecords]);
  }


  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);

  
  return (
    <Pane {...rest} >

      <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Charity details')}</Strong>
            {/* <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("...")}</Text>
            </Pane> */}
          </Pane>
        </Pane>
      </Pane>

      <Fragment>
        <DynamicInputField label={I18n.get('Charity name')} inputName="charity-name" type="text" disabled={processing} placeholder={`${I18n.get('Charity name')}...`}
          value={updatedUserDetailsSectionRecords?.find(record => record.type === "charityDetails")?.regName}
          onValidation={(value) => {
            if (value) {
              let temp = value.trim();
              if (temp.length > 150) {
                return I18n.get('Too long.');
              }
              else {
                return null;
              }
            }
          }}
          onChange={(value) => {
            updateUserRecords("charityDetails", {
              regName: value
            });

          }}
          onBlur={(e) => {
            updateUserRecords("charityDetails", {
              regName: e.target.value?.trim(),
            });
          }}
        />

      </Fragment>

      <Fragment>
        <DynamicInputField marginTop={12} label={I18n.get('Charity number')} inputName="charity-number" type="text" disabled={processing} placeholder={`${I18n.get('Charity number')}...`}
          value={updatedUserDetailsSectionRecords?.find(record => record.type === "charityDetails")?.regNumber}
          onValidation={(value) => {
            if (value) {
              let temp = value.trim();
              if (temp.length > 30) {
                return I18n.get('Too long.');
              }
              else {
                return null;
              }
            }
          }}
          onChange={(value) => {
            updateUserRecords("charityDetails", {
              regNumber: value
            });

          }}
          onBlur={(e) => {
            updateUserRecords("charityDetails", {
              regNumber: e.target.value?.trim(),
            });
          }}
        />

      </Fragment>

      {userDetails.rights?.acceptGiftAid ?
        <Fragment>
          <Pane marginTop={12} alignItems="flex-start" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
              <Strong fontWeight={500} fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Gift Aid')}</Strong> 
            </ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
              <Pane maxWidth={380} alignItems="center" display="flex" >
                <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                  <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                    label={""}
                    disabled={processing}
                    checked={updatedUserDetailsSectionRecords?.find(record => record.type === "giftAidAcceptance")?.mayBeAccepted}
                    onChange={(e) => {
                      updateUserRecords("giftAidAcceptance", {
                        mayBeAccepted: e.target.checked
                      });
                    }}
                  />
                  <Pane flex={1} marginTop={2} >
                    <Text fontSize={14} color="#283655">{I18n.get("Accept Gift Aid")}</Text>
                  </Pane>
                </Pane>
              </Pane>
              
            </Pane>
          </Pane>
        </Fragment>
      : null }

      <Fragment>
        <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
            <Strong fontWeight={500} fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Note to donors')}</Strong> 
          </ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >

            <Pane maxWidth={380} >
              <textarea
                className="input-standard"
                style={{
                  "resize": "vertical",
                  "display": "block",
                  "width": "100%",
                  "height": "80px",
                  "minHeight": "80px",
                  "maxHeight": "160px",
                }}
                disabled={processing}
                placeholder={`${I18n.get('Note')}...`}
                value={updatedUserDetailsSectionRecords?.find(record => record.type === "charityDetails")?.caption?.replace(/<br\/>/g,'\r\n') || ""}
                onChange={(e) => {
                  let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                  if (temp?.length > 2200) {
                    // setAccountDescriptionInvalidMessage(I18n.get('Too long.'));
                  }
                  else {
                    // setAccountDescriptionInvalidMessage();
                    updateUserRecords("charityDetails", {
                      caption: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ')
                    });
                  }
                }}
                // onBlur={(e) => {}}
              />
              {/* {(accountDescriptionInvalidMessage) ?
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                  <Text size={400} color="#EC4C47">{accountDescriptionInvalidMessage}</Text>
                </Pane>
              :
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{"Use this field to describe your page at length."}</Text>
                </Pane>
              } */}
              
              <Paragraph marginTop={2} fontSize={12} fontStyle="italic" lineHeight={1.3} color="#7B8B9A" >
                {I18n.get("This note will be displayed at the bottom of the receipt notifications sent to donors when they make donations to your page.")}
              </Paragraph>

            </Pane>

            
          </Pane>
        </Pane>
      </Fragment>
      
      <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={processing ? null : 'button-blue'}
                disabled={processing || _.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords)}
                onClick={() => {
                  setProcessing(true);
                  handleUpdateUserDetails({
                    // userDetailsToUpdate: {},
                    recordsToUpdate: _.differenceWith(updatedUserDetailsSectionRecords, userDetailsSectionRecords, _.isEqual),
                    onSuccess: (freshUserDetails) => {
                      setProcessing(false);
                    },
                    onError: (error) => {
                      setProcessing(false);
                    },
                  });
                }}
              >
                {!processing ?
                  I18n.get("Save")
                :
                  <Fragment >
                    <Spinner marginRight={8} flex="none" size={24} />
                    <Pane >
                      {`${I18n.get('Saving')}...`}
                    </Pane>
                  </Fragment>
                }
              </Button>
            </Pane>

            {_.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords)|| processing ? null :
              <Button justifyContent="center" paddingX={12} height={30}
                disabled={false}
                onClick={() => {
                  // setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
                  setUpdatedUserDetailsSectionRecords([...userDetailsSectionRecords]);
                }}
              >
                {I18n.get("Discard")}
              </Button>
            }

          </Pane>
        </Pane>
      </Pane>
      
    </Pane>
  );
}

const AccountProfessionalPageRequest = ({ ...rest }) => {

  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);

  const [processing, setProcessing] = useState(false);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const userDetailsSectionRecords = useMemo(() => {

    const userDetailsProfessionalPageRequestRecord = userDetails?.records?.find(record => record.type === "professionalPageRequest");

    let sectionRecords = [{
      ...userDetailsProfessionalPageRequestRecord,
      type: userDetailsProfessionalPageRequestRecord?.type || "professionalPageRequest",
      note: userDetailsProfessionalPageRequestRecord?.note || undefined,
    }];
    
    return (sectionRecords);

  }, [userDetails?.records]);

  const [updatedUserDetailsSectionRecords, setUpdatedUserDetailsSectionRecords] = useState(userDetailsSectionRecords);

  useEffect(() => {
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
  }, [userDetailsSectionRecords]);

  
  const updateUserRecords = (recordType, updatedValues) => {
    let updatedRecords = updatedUserDetailsSectionRecords || [];
    let currentIndex = null;
    let currentRecord = updatedRecords?.find((record, index) => {
			if (record.type === recordType) {
        currentIndex = index;
				return record;
			}
			else {
				return false;
			}
		});

    let updatedRecord = {
      ...currentRecord,
      ...updatedValues,
      type: recordType,
    };

    updatedRecords.splice(currentIndex, currentIndex !== null ? 1 : 0, updatedRecord);
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(updatedRecords)); // needed to trigger render, otherwise change in array doesn't trigger
  }

  const handleRequestProfessionalPage = async({note, onSuccess, onError}) => {
    try {

      const response = await API.graphql({
        query: mutations.requestProfessionalPage,
        variables: {
          publicFaceId: userDetails?.publicFaceId,
          note: note,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      
      const requestProfessionalPage = response.data.requestProfessionalPage;
      console.log("requestProfessionalPage", requestProfessionalPage);

      if (userState.actAsUser){
        dispatch(updateUserState({
          actAsUser: {
            ...userState.actAsUser,
            tags: ["professionalPagePending"]
          },
        }));
      }
      else {
        dispatch(updateUserState({
          user: {
            ...userState.user,
            tags: ["professionalPagePending"]
          },
        }));
      }

      // is this needed at all if userState gets updated ???
      if (typeof onSuccess === "function") {
        onSuccess();
      }

    }
    catch (error) {
      console.error('within requestProfessionalPage', error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }

  

  
  return (
    <Pane {...rest} >

      <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Professional page request')}</Strong>
            {userDetails.tags?.includes("professionalPagePending") ? null :
              <Pane >
                <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("If you are a charity, corporate or retailer, please submit a request. We will get in touch about setting up a dedicated page for you.")}</Text>
              </Pane>
            }
          </Pane>
        </Pane>
      </Pane>

      {userDetails.tags?.includes("professionalPagePending") ?
        <Fragment>
          <Pane alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
              <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Status')}</Strong> 
            </ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
              <Pane maxWidth={380} >
                <Pane >
                  <Strong fontSize={14} color="#283655" >{I18n.get('Your request is being reviewed.')}</Strong>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Fragment>
      :
        <Fragment>
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
              <Strong fontWeight={500} fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Note')}</Strong> 
            </ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >

              <Pane maxWidth={380} >
                <textarea
                  className="input-standard"
                  style={{
                    "resize": "vertical",
                    "display": "block",
                    "width": "100%",
                    "height": "80px",
                    "minHeight": "80px",
                    "maxHeight": "160px",
                  }}
                  disabled={processing}
                  placeholder={`${I18n.get('Note')}...`}
                  value={updatedUserDetailsSectionRecords?.find(record => record.type === "professionalPageRequest")?.note?.replace(/<br\/>/g,'\r\n') || ""}
                  onChange={(e) => {
                    let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                    if (temp?.length > 2200) {
                      // setAccountDescriptionInvalidMessage(I18n.get('Too long.'));
                    }
                    else {
                      // setAccountDescriptionInvalidMessage();
                      updateUserRecords("professionalPageRequest", {
                        note: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' ')
                      });
                    }
                  }}
                  // onBlur={(e) => {}}
                />
                {/* {(accountDescriptionInvalidMessage) ?
                  <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                    <Text size={400} color="#EC4C47">{accountDescriptionInvalidMessage}</Text>
                  </Pane>
                :
                  <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                    <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{"Use this field to describe your page at length."}</Text>
                  </Pane>
                } */}
                
                <Paragraph marginTop={2} fontSize={12} fontStyle="italic" lineHeight={1.3} color="#7B8B9A" >
                  {I18n.get("Briefly describe your organisation. Leave your preferred contact details.")}
                </Paragraph>

              </Pane>

              
            </Pane>
          </Pane>
          
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

                <Pane flex="none" marginRight={36} >
                  <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={processing ? null : 'button-blue'}
                    disabled={processing}
                    onClick={() => {
                      setProcessing(true);
                      handleRequestProfessionalPage({
                        note: updatedUserDetailsSectionRecords?.find(record => record.type === "professionalPageRequest")?.note,
                        onSuccess: () => {
                          setProcessing(false);
                        },
                        onError: (error) => {
                          setProcessing(false);
                        },
                      });
                    }}
                  >
                    {!processing ?
                      I18n.get("Submit")
                    :
                      <Fragment >
                        <Spinner marginRight={8} flex="none" size={24} />
                        <Pane >
                          {`${I18n.get('Submitting')}...`}
                        </Pane>
                      </Fragment>
                    }
                  </Button>
                </Pane>

                {_.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords)|| processing ? null :
                  <Button justifyContent="center" paddingX={12} height={30}
                    disabled={false}
                    onClick={() => {
                      setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
                    }}
                  >
                    {I18n.get("Discard")}
                  </Button>
                }

              </Pane>
            </Pane>
          </Pane>
        </Fragment>
      }
      
    </Pane>
  );
}

const AccountRecordsForRetailerDetails = () => {

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    // console.log("userDetails updates");
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const userDetailsSectionRecords = useMemo(() => {

    const userDetailsRetailerDetailsRelatedRecord = userDetails?.records?.find(record => record.type === "retailerDetails");
    const userDetailsPosModeRecord = userDetails?.records?.find((record) => (record.type === "posMode"));
    
    return ([
      {
        type: userDetailsRetailerDetailsRelatedRecord?.type || "retailerDetails",
        regNumber: userDetailsRetailerDetailsRelatedRecord?.regNumber,
        regName: userDetailsRetailerDetailsRelatedRecord?.regName,
      },
      {
        type: userDetailsPosModeRecord?.type || "posMode",
        isDefault: userDetailsPosModeRecord?.isDefault || false,
      }
    ]);

  }, [userDetails?.records]);

  const [updatedUserDetailsSectionRecords, setUpdatedUserDetailsSectionRecords] = useState(userDetailsSectionRecords);

  useEffect(() => {
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
  }, [userDetailsSectionRecords]);

  
  const updateUserRecords = (recordType, updatedValues) => {
    let updatedRecords = updatedUserDetailsSectionRecords || [];
    let currentIndex = null;
    let currentRecord = updatedRecords?.find((record, index) => {
			if (record.type === recordType) {
        currentIndex = index;
				return record;
			}
			else {
				return false;
			}
		});

    let updatedRecord = {
      ...currentRecord,
      ...updatedValues,
      type: recordType,
    };

    updatedRecords.splice(currentIndex, currentIndex !== null ? 1 : 0, updatedRecord);
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(updatedRecords)); // needed to trigger render, otherwise change in array doesn't trigger
  }


  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);

  
  return (
    <Fragment>

      <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Retailer details')}</Strong>
            {/* <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("...")}</Text>
            </Pane> */}
          </Pane>
        </Pane>
      </Pane>

      <Fragment>
        <DynamicInputField label={I18n.get('Company name')} inputName="company-name" type="text" disabled={processing} placeholder={`${I18n.get('Company name')}...`}
          value={updatedUserDetailsSectionRecords?.find(record => record.type === "retailerDetails")?.regName}
          onValidation={(value) => {
            if (value) {
              let temp = value.trim();
              if (temp.length > 150) {
                return I18n.get('Too long.');
              }
              else {
                return null;
              }
            }
          }}
          onChange={(value) => {
            updateUserRecords("retailerDetails", {
              regName: value
            });

          }}
          onBlur={(e) => {
            updateUserRecords("retailerDetails", {
              regName: e.target.value?.trim(),
            });
          }}
        />

      </Fragment>

      <Fragment>
        <DynamicInputField label={I18n.get('Company number')} inputName="company-number" type="text" disabled={processing} placeholder={`${I18n.get('Company number')}...`}
          value={updatedUserDetailsSectionRecords?.find(record => record.type === "retailerDetails")?.regNumber}
          onValidation={(value) => {
            if (value) {
              let temp = value.trim();
              if (temp.length > 30) {
                return I18n.get('Too long.');
              }
              else {
                return null;
              }
            }
          }}
          onChange={(value) => {
            updateUserRecords("retailerDetails", {
              regNumber: value
            });

          }}
          onBlur={(e) => {
            updateUserRecords("retailerDetails", {
              regNumber: e.target.value?.trim(),
            });
          }}
        />

      </Fragment>

      {/* {userDetails.rights?.provideExternally && userDetails.rights?.provideInternally ?
        <Fragment>
          <Pane marginBottom={10} alignItems="flex-start" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
              <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('POS mode')}</Strong> 
            </ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
              <Pane maxWidth={380} alignItems="center" display="flex" >
                <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                  <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                    label={""}
                    disabled={processing}
                    checked={updatedUserDetailsSectionRecords?.find(record => record.type === "posMode")?.isDefault}
                    onChange={(e) => {
                      updateUserRecords("posMode", {
                        isDefault: e.target.checked
                      });
                    }}
                  />
                  <Pane flex={1} marginTop={2} >
                    <Text fontSize={14} color="#283655">{I18n.get("Use POS mode by default")}</Text>
                  </Pane>
                </Pane>
              </Pane>

              <Pane marginTop={2} >
                <Text fontSize={14} color="#7B8B9A" >{I18n.get("POS mode is best suited for direct provisions.")}</Text>
              </Pane>
            </Pane>
          </Pane>
        </Fragment>
      : null } */}
      

      <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                  disabled={processing || _.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords)}
                  onClick={() => {
                    setProcessing(true);
                    handleUpdateUserDetails({
                      userDetailsToUpdate: {
                        records: updatedUserDetailsSectionRecords,
                      },
                      onSuccess: (freshUserDetails) => {
                        setProcessing(false);
                      },
                      onError: (error) => {
                        setProcessing(false);
                      },
                    });
                  }}
                >
                  {I18n.get("Save")}
                </Button>
              </Pane>

              {_.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords) ? null :
                <Button justifyContent="center" paddingX={12} height={30}
                  disabled={false}
                  onClick={() => {
                    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
                  }}
                >
                  {I18n.get("Cancel")}
                </Button>
              }

            </Pane>
          }
        </Pane>
      </Pane>
      
    </Fragment>
  );
}

const AccountFeedbackPresetSetting = ({
  feedbackPreset,
  onDiscard
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountFeedbackPresetSetting - ${countRef.current}`);
    return () => {
      console.log("AccountFeedbackPresetSetting - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const userState = useSelector(state => state.userState);

  const [processing, setProcessing] = useState(false);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const [updatedFeedbackPreset, setUpdatedFeedbackPreset] = useState(feedbackPreset);

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const handleUpdateFeedbackPreset = ({key, value}) => {
    setUpdatedFeedbackPreset((prev) => ({
      ...prev,
      [key]: value
    }));
  }


  const [updatedFeedbackPresetImpact, setUpdatedFeedbackPresetImpact] = useState(updatedFeedbackPreset?.impact || []);
  useEffect(() => {
    setUpdatedFeedbackPresetImpact(updatedFeedbackPreset?.impact || []);
  }, [updatedFeedbackPreset?.impact]);

  const handleUpdateImpactBreakdown = (breakdown, updatesToBreakdown) => {
    // improve ???
    let updatedImpactBreakdowns = updatedFeedbackPresetImpact || [];
    const impactBreakdownIndex = _.findIndex(updatedImpactBreakdowns, breakdown);
    updatedImpactBreakdowns.splice(impactBreakdownIndex, impactBreakdownIndex !== null ? 1 : 0, {
      ...breakdown,
      ...updatesToBreakdown,
    });

    setUpdatedFeedbackPresetImpact(_.cloneDeep(updatedImpactBreakdowns)); // keep

  };

  useEffect(() => {
    handleUpdateFeedbackPreset({
      key: "impact",
      value: updatedFeedbackPresetImpact,
    });
  }, [updatedFeedbackPresetImpact]);

  const handleOnDiscard = () => {
    setUpdatedFeedbackPreset(feedbackPreset);
    onDiscard();
  }

  return (
    <Pane marginBottom={10} alignItems="flex-start" display="flex" flexWrap="wrap" >

      <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} />
      
      <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >

        <Pane marginTop={10} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" />

        {false ? null :
          <Fragment>
            <Pane marginTop={10} marginBottom={4} >
              <Text fontSize={12} color="#425A70" >{updatedFeedbackPreset.status} | {feedbackPreset.publicTransactionId}</Text>
            </Pane>
          </Fragment>
        }
        
        {false ? null :
          <Fragment>

            <Pane marginTop={10} marginBottom={4} >
              <Strong fontSize={14} color="#425A70" >{I18n.get('Amount')}</Strong>
            </Pane>

            <Pane maxWidth={380} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" className="noselect" >

            <CurrencyNumberInput
              disabled={processing}
              placeholder={`${I18n.get('Amount')}...`}
              value={updatedFeedbackPreset.amount}
              currency={updatedFeedbackPreset?.currency}
              onChange={(values) => {
                let temp = values.value;
                let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                handleUpdateFeedbackPreset({
                  key: "amount",
                  value: newAmount,
                });

              }}
            />
            
            </Pane>
          </Fragment>
        }

        {false ? null :
          <Fragment>
          
            <Pane marginTop={10} marginBottom={4} >
              <Strong fontSize={14} color="#425A70" >{I18n.get('Internal reference')}</Strong>
            </Pane>

            <Pane maxWidth={380} >

              {/* <textarea
                className="input-standard"
                style={{
                  "resize": "vertical",
                  "display": "block",
                  "width": "100%",
                  "height": "80px",
                  "minHeight": "80px",
                  "maxHeight": "160px",
                }}
                disabled={processing}
                placeholder={`${I18n.get('Internal reference')}...`}
                value={updatedFeedbackPreset.description?.replace(/<br\/>/g,'\r\n') || ""}
                onChange={(e) => {
                  
                }}
                // onBlur={(e) => {}}
              /> */}

              <TextInput
                // id={inputName}
                // autoComplete={inputName}
                // name={inputName}
                disabled={processing}
                // isInvalid={localIsInvalid}
                type={"text"}
                marginTop={0}
                marginBottom={0}
                width="100%"
                maxWidth={380}
                height={40}
                placeholder={`${I18n.get('Reference')}...`}
                value={updatedFeedbackPreset.description?.replace(/<br\/>/g,'\r\n') || ""}
                onChange={(e) => {
                  let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                  if (temp?.length > 2200) {
                    // setAccountDescriptionInvalidMessage(I18n.get('Too long.'));
                  }
                  else {
                    // setAccountDescriptionInvalidMessage();
                    handleUpdateFeedbackPreset({
                      key: "description",
                      value: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
                    });
                    
                  }
                }}
                onBlur={(e) => {}}
              />
              {/* {(accountDescriptionInvalidMessage) ?
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                  <Text size={400} color="#EC4C47">{accountDescriptionInvalidMessage}</Text>
                </Pane>
              :
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{"Use this field to describe your page at length."}</Text>
                </Pane>
              } */}
            </Pane>

            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This will not be visible to others. Use this field, for example, to distinguish different presets with the same amount.")}</Text>
            </Pane>

          </Fragment>
        }

        {false ? null :
          <Fragment>

            {/* {false ? null :
              <Fragment>

                <Pane marginTop={10} maxWidth={380} alignItems="center" display="flex" >
                  <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                    <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                      label={""}
                      disabled={processing}
                      checked={updatedFeedbackPreset.conditions?.includes("admin")}
                      onChange={(e) => {

                        let currentConditions = updatedFeedbackPreset.conditions || []
                        if (e.target.checked) {
                          if (!currentConditions.includes("admin")) {
                            currentConditions.push("admin");
                          }
                        }
                        else {
                          if (currentConditions.includes("admin")) {
                            currentConditions = _.pullAll(currentConditions, ["admin"])
                          }
                        }
                        handleUpdateFeedbackPreset({
                          key: "conditions",
                          value: currentConditions,
                        });
                      }}
                      
                    />
                    <Pane flex={1} marginTop={2} >
                      <Text fontSize={14} color="#283655">{I18n.get("Set as default by Admin")}</Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane marginTop={2} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("When ...")}</Text>
                </Pane>

              </Fragment>
            } */}

            {!userDetails.tags.includes("retailer") ? null :
              <Fragment>

                <Pane marginTop={10} maxWidth={380} alignItems="center" display="flex" >
                  <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                    <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                      label={""}
                      disabled={processing}
                      checked={updatedFeedbackPreset.onEvents?.includes("posProvision")}
                      onChange={(e) => {

                        let currentOnEvents = updatedFeedbackPreset.onEvents || [];
                        if (e.target.checked) {
                          if (!currentOnEvents.includes("posProvision")) {
                            currentOnEvents.push("posProvision");
                          }
                        }
                        else {
                          if (currentOnEvents.includes("posProvision")) {
                            currentOnEvents = _.pullAll(currentOnEvents, ["posProvision"])
                          }
                        }
                        handleUpdateFeedbackPreset({
                          key: "onEvents",
                          value: currentOnEvents,
                        });
                      }}
                      
                    />
                    <Pane flex={1} marginTop={2} >
                      <Text fontSize={14} color="#283655">{I18n.get("Apply to all provisions made in POS mode")}</Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane marginTop={2} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("When ...")}</Text>
                </Pane>

              </Fragment>
            }

            {!userDetails.rights?.makeAllocations ? null :
              <Fragment>

                <Pane marginTop={10} maxWidth={380} alignItems="center" display="flex" >
                  <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                    <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                      label={""}
                      disabled={processing} 
                      checked={updatedFeedbackPreset.onEvents?.includes("allocation")}
                      onChange={(e) => {

                        let currentOnEvents = updatedFeedbackPreset.onEvents || [];
                        let currentConditions = updatedFeedbackPreset.conditions || [];
                        if (e.target.checked) {
                          if (!currentOnEvents.includes("allocation")) {
                            currentOnEvents.push("allocation");
                            currentConditions.push("anonymiseRecipientOnAllocation");
                          }
                        }
                        else {
                          if (currentOnEvents.includes("allocation")) {
                            currentOnEvents = _.pullAll(currentOnEvents, ["allocation"]);
                            currentConditions = _.pullAll(currentConditions, ["anonymiseRecipientOnAllocation"]);
                          }
                        }
                        handleUpdateFeedbackPreset({
                          key: "onEvents",
                          value: currentOnEvents,
                        });
                        handleUpdateFeedbackPreset({
                          key: "conditions",
                          value: currentConditions,
                        });
                      }}
                      
                    />
                    <Pane flex={1} marginTop={2} >
                      <Text fontSize={14} color="#283655">{I18n.get("Offer to apply in allocation mode")}</Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane marginTop={2} >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("After you make an allocation using this preset, donors (whose funds are used in the allocations) will be notified of the impact in line with the information provided in this preset.")}</Text>
                </Pane>

              </Fragment>
            }

            {userDetails.rights?.makeAllocations && updatedFeedbackPreset.onEvents?.includes("allocation") ?
              <Fragment>

                <Pane marginTop={10} maxWidth={380} alignItems="center" display="flex" >
                  <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                    <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                      label={""}
                      disabled={processing}
                      checked={updatedFeedbackPreset.conditions?.includes("anonymiseRecipientOnAllocation")}
                      onChange={(e) => {

                        let currentConditions = updatedFeedbackPreset.conditions || [];
                        if (e.target.checked) {
                          if (!currentConditions.includes("anonymiseRecipientOnAllocation")) {
                            currentConditions.push("anonymiseRecipientOnAllocation");
                          }
                        }
                        else {
                          if (currentConditions.includes("anonymiseRecipientOnAllocation")) {
                            currentConditions = _.pullAll(currentConditions, ["anonymiseRecipientOnAllocation"]);
                          }
                        }
                        handleUpdateFeedbackPreset({
                          key: "conditions",
                          value: currentConditions,
                        });
                      }}
                      
                    />
                    <Pane flex={1} marginTop={2} >
                      <Text fontSize={14} color="#283655">{I18n.get("Anonymise recipient")}</Text>
                    </Pane>
                    
                  </Pane>
                </Pane>

                <Pane marginTop={2} >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Only you and the recipient will see who made and who received the allocation made using this preset.")}</Text>
                </Pane>

              </Fragment>
            :  null }
          </Fragment>
        }

        {true ? null :
          <Fragment>
          
            <Pane marginTop={10} marginBottom={4} >
              <Strong fontSize={14} color="#425A70" >{I18n.get('Caption')}</Strong>
            </Pane>

            <Pane maxWidth={380} >
              <textarea
                className="input-standard"
                style={{
                  "resize": "vertical",
                  "display": "block",
                  "width": "100%",
                  "height": "80px",
                  "minHeight": "80px",
                  "maxHeight": "160px",
                }}
                disabled={processing}
                placeholder={`${I18n.get('Caption')}...`}
                value={updatedFeedbackPreset.caption?.replace(/<br\/>/g,'\r\n') || ""}
                onChange={(e) => {
                  let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
                  if (temp?.length > 2200) {
                    // setAccountDescriptionInvalidMessage(I18n.get('Too long.'));
                  }
                  else {
                    // setAccountDescriptionInvalidMessage();
                    handleUpdateFeedbackPreset({
                      key: "caption",
                      value: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
                    });
                  }
                }}
                // onBlur={(e) => {}}
              />
              {/* {(accountDescriptionInvalidMessage) ?
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} textAlign="left" >
                  <Text size={400} color="#EC4C47">{accountDescriptionInvalidMessage}</Text>
                </Pane>
              :
                <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
                  <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{"Use this field to describe your page at length."}</Text>
                </Pane>
              } */}
            </Pane>

            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Describe the typical impact such an allocation makes.")}</Text>
            </Pane>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("E.g. £20 supports an individual-in-need for 3 days with food and essentials...")}</Text>
            </Pane>

          </Fragment>
        }
        
        <FeedbackImpactForm
          feedbackRecord={{
            impact: updatedFeedbackPresetImpact
          }}
          disabled={processing}
          
          onChange={(breakdown, changedKeyValues) => {
            handleUpdateImpactBreakdown(breakdown, {
              ...changedKeyValues,
            });
          }}
        />

        {updatedFeedbackPreset.status !== "deleted" ? null
        :
          <Pane marginTop={10} marginBottom={10} >
            <Strong fontSize={14} color="#EC4C47" >{I18n.get('Are you sure you want to delete this preset?')}</Strong>
          </Pane>
        }

        {processing ?
          <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
            <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
          </Pane>
        :
          <Pane marginTop={20} width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                disabled={processing || _.isEqual(updatedFeedbackPreset, feedbackPreset)}
                onClick={() => {
                  setProcessing(true);
                  handleUpdateUserDetails({
                    relatedRecordsToUpdate: [updatedFeedbackPreset],
                    onSuccess: (freshUserDetails) => {
                      setProcessing(false);
                    },
                    onError: (error) => {
                      // handle ???
                      setProcessing(false);
                    },
                  });
                }}
              >
                {updatedFeedbackPreset.status !== "deleted" ? 
                  I18n.get("Save")
                :
                  I18n.get("Yes, delete")
                }
              </Button>
            </Pane>

            {_.isEqual(updatedFeedbackPreset, feedbackPreset) ? 
            
              <Button style={{color: "#EC4C47"}} justifyContent="center" paddingX={12} height={30}
                disabled={false}
                onClick={() => {
                  if (!updatedFeedbackPreset.publicTransactionId) {
                    handleOnDiscard();
                  }
                  else {
                    handleUpdateFeedbackPreset({
                      key: "status",
                      value: "deleted",
                    });
                  }
                }}
              >
                {I18n.get("Delete")}
              </Button>

            :
              <Button justifyContent="center" paddingX={12} height={30}
                disabled={false}
                onClick={() => {
                  handleOnDiscard();
                }}
              >
                {updatedFeedbackPreset.status !== "deleted" ? 
                  I18n.get("Discard")
                :
                  I18n.get("Cancel")
                }
              </Button>
            }

          </Pane>
        }
        
      </Pane>

    </Pane>
  );

}

const AccountFeedbackPresets = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountFeedbackPresets - ${countRef.current}`);
    return () => {
      console.log("AccountFeedbackPresets - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    // console.log("userDetails updates");
    return(userState.actAsUser || userState.user);
  }, [userState]);

  // const userDetailsFeedbackPresetCover = useMemo(() => {
  //   return userDetails?.records?.find(r => (r.type === "feedbackPreset" && r.role === "cover"));
  // }, [userDetails?.records]);

  const userDetailsSectionRecords = useMemo(() => {
    
    const activeFeedbackPresets = userDetails?.records?.filter(r => (r.type === "feedbackPreset" && r.role !== "cover" && r.status !== "deleted")) || [];
    return activeFeedbackPresets;
    
  }, [userDetails?.records]);

  const [updatedUserDetailsSectionRecords, setUpdatedUserDetailsSectionRecords] = useState(userDetailsSectionRecords);

  useEffect(() => {
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords));
  }, [userDetailsSectionRecords]);

  const [processing, setProcessing] = useState(false);
  
  const handleAddNewFeedbackPreset = () => {

    let newFeedbackPresetRelatedRecord = {
      type: "feedbackPreset",
      publicTransactionId: null,
      description: null,
      caption: null,
      amount: null,
      currency: localeState.currency,
      status: "active",
      isDefault: null,
      onEvents: [],
      conditions: [],
      impact: newFeedbackImpactBreakdown(),
    };
    
    setUpdatedUserDetailsSectionRecords((prev) => (_.cloneDeep([newFeedbackPresetRelatedRecord, ...prev || []])));

    // const feedbackPresetCoverRecord = userDetails?.records?.find(record => (record.type === "feedbackPreset" && record.role === "cover"));

    //   relatedRecords: {
    //     results: [newFeedbackPresetRelatedRecord, ...currentFeedbackPresetRecord.relatedRecords.results],
    //     total: currentFeedbackPresetRecord.relatedRecords.total + 1,
    //     nextToken: currentFeedbackPresetRecord.relatedRecords.nextToken,
    //   },

    // setUpdatedUserDetailsSectionRecords(_.cloneDeep(updatedRecords)); // needed to trigger render, otherwise change in array doesn't trigger

  };

  const handleDiscardFeedbackPresetChanges = () => {
    setUpdatedUserDetailsSectionRecords(_.cloneDeep(userDetailsSectionRecords)); // needed to trigger render, otherwise change in array doesn't trigger
  };
  // useEffect(() => {
  //   console.log("updatedUserDetailsSectionRecords", updatedUserDetailsSectionRecords);
  // }, [updatedUserDetailsSectionRecords]);
  

  return (
    <Fragment>

      <Pane alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >

            <Pane alignItems="center" display="flex" height={24} >
              <Strong fontSize={16} color="#425A70" >{I18n.get('Feedback curation')}</Strong>
              {/* {processing ?
                <Spinner marginLeft={8} flex="none" size={24} />
              : null } */}
              {false ? null :
                <Button flex="none" marginLeft={"auto"} marginRight={0} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" className="noselect"
                  disabled={processing || !_.isEqual(userDetailsSectionRecords, updatedUserDetailsSectionRecords) }
                  onClick={handleAddNewFeedbackPreset}
                >
                  {I18n.get('Add preset')}
                </Button>
              }
            </Pane>

          </Pane>
        </Pane>
      </Pane>
      
      {updatedUserDetailsSectionRecords?.map(feedbackPreset =>
        <AccountFeedbackPresetSetting key={feedbackPreset?.publicTransactionId || "new"} 
          feedbackPreset={feedbackPreset}
          onDiscard={handleDiscardFeedbackPresetChanges}
        />
      )}
      
    </Fragment>
  );
}


const AccountProfessionalSettings = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountProfessionalSettings - ${countRef.current}`);
    return () => {
      console.log("AccountProfessionalSettings - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const localeState = useSelector(state => state.localeState);
 
  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState.actAsUser, userState.user]);

  const [processing, setProcessing] = useState(false);

  // not yet set as a professional account
  if (userDetails.tags?.filter(tag => ["charity", "corporate", "retailer"].includes(tag)).length < 1) {
    return (
      <Fragment>
        <AccountProfessionalPageRequest marginTop={16} />
      </Fragment>
    );
  }


  return (
    <Fragment >

      {/* <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
          <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Category')}</Strong> 
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
          <Pane minHeight={30} maxWidth={380} alignItems="center" display="flex" >
            <Strong fontSize={14} color="#283655" display="inline-flex" >{I18n.get(userCategory)}</Strong> 
          </Pane>
        </Pane>
      </Pane> */}
      

      {!userDetails.tags.includes("charity") ? null :
        <Fragment>
          <AccountCharityDetails />
        </Fragment>
      }

      {/* {!userDetails.tags.includes("retailer") ? null :
        <Fragment>
          <AccountRecordsForRetailerDetails />
        </Fragment>
      } */}

      {!userDetails.tags.includes("retailer") ? null :
        <Fragment>
          {"Type of page: Retailer"}
        </Fragment>
      }

      {!userDetails.tags.includes("corporate") ? null :
        <Fragment>
          {"Type of page: Corporate"}
        </Fragment>
      }


      {!userDetails.rights?.makeAllocations ? null :
        <Fragment>
          <AccountRecordAllocationMode paddingTop={20} width="100%" borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />
        </Fragment>
      }

      {userDetails.rights?.curateFeedback && userDetails.rights?.makeAllocations ?
        <Fragment>
          <Pane width="100%" marginTop={20} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Pane marginTop={20} >
            <AccountFeedbackPresets />
          </Pane>
        </Fragment>
      : null }      

      {!userDetails.rights?.verifyRecipients ? null :
        <Fragment>
          <Pane width="100%" marginTop={20} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

          <AccountRecipients />

        </Fragment>
      }

      

    </Fragment>
  );

}

export default AccountProfessionalSettings;