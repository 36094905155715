const initialState = {
	// ... mirror Face
	history: {
		results: null,
    total: null,
    nextToken: null,
	},
	following: {
		results: null,
    total: null,
    nextToken: null,
	},
	followers: {
		results: null,
    total: null,
    nextToken: null,
	},
	dashboard: {
		results: null,
    total: null,
    nextToken: null,
	},
};

const profileDetailsReducer = (state = initialState, action) => {
	switch(action.type){
		case 'SET_PROFILE_DETAILS':
			if (!action.payload) {
				return initialState;
			}
			return action.payload;
		case 'UPDATE_PROFILE_DETAILS':
			if (!action.payload) {
				return initialState;
			}
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export default profileDetailsReducer;