import { useEffect, useLayoutEffect, useState, useCallback, useRef, useMemo, useContext } from 'react';
import FacePH from '../images/FacePH';

import { Link, Redirect, generatePath, useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import { Text, Button, Table, Dialog, IconButton, CrossIcon, Icon, Paragraph, Strong, Popover, Menu, Position, InfoSignIcon, Tooltip, AddIcon, ShopIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon, MoreIcon, Pill, Pane} from 'evergreen-ui';

import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';
import ShowMoreText from 'react-show-more-text';

import { I18n} from 'aws-amplify';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';


import FormattedCurrencyNumber from './FormattedCurrencyNumber';
import AspectRatio from './AspectRatio';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, setTransactionDetails } from '../services/actions';


const ProfileCard = ( {profileDetails, ...rest} ) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProfileCard - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("ProfileCard - cleaned up");
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
    
  return(
    <Pane background="tint1" padding={10} borderRadius={10} >

      <Pane border borderRadius={5} >
        <AspectRatio ratio={1/1} >
          {profileDetails?.pictureUrl ?
            <img width="100%" height="auto" src={profileDetails.pictureUrl} alt="" style={{borderRadius: "inherit"}}/>
          :
            <Pane position="absolute" zIndex={1} width="100%" height="100%" paddingX={36} marginLeft="auto" marginRight="auto" >
              <FacePH color={profileDetails ? "#7B8B9A" : "#E4E7EB"} width="100%" height="100%" />
            </Pane>
          }
        </AspectRatio>
      </Pane>
      
      {profileDetails ?
        <Pane>
          {profileDetails.name ?
            <Pane marginTop={12} >
              <Strong display="block" fontSize={24} minHeight="1em" color="#283655" style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >{profileDetails.name}</Strong>
            </Pane>
          : null }
          {profileDetails.publicFaceId ?
            <Pane marginTop={0} >
              <Strong size={500} color="#7B8B9A" >{profileDetails.username || profileDetails.publicFaceId}</Strong>
            </Pane>
          : null }
          
          {profileDetails.bio ?
            <Pane marginTop={6} >
              <Text fontSize={18} color="#283655" >
                <ShowMoreText
                  lines={3}
                  more=''
                  less=''
                  // className='show-more-text'
                  anchorClass='show-more-text'
                  // onClick={this.executeOnClick}
                  expanded={false}
                  width={0}
                >
                  {profileDetails.bio.replace(/<br\/>/g,'\r\n')}
                </ShowMoreText>
              </Text>
            </Pane>
          : null }

          <Pane textAlign="center">
            <Button width={"75%"} fontSize={16} marginTop={8} paddingX={16} height={40} justifyContent="center" className='button-blue'
              // disabled={}
              is={Link}
              to={{
                pathname: generatePath("/:locale?/:handle", {...match.params, handle: profileDetails.username || profileDetails.publicFaceId}),
                // state: {
                //   isModal: true,
                // },
              }}
            >
              {I18n.get('View')}
            </Button>
          </Pane>
          
        </Pane>
      : 

        <Pane marginTop={12} >
          <Pane paddingY={2} width={160} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={22}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={80} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={18}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={240} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={14}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={150} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={14}/></SkeletonTheme></Pane>
          <Pane paddingY={2} width={220} ><SkeletonTheme baseColor="#E4E7EB" highlightColor="#EDF0F2"><Skeleton height={14}/></SkeletonTheme></Pane>
        </Pane>
      
      }
      
    </Pane>
  );
}

export default ProfileCard;