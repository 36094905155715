const FoodIcon = ({size="inherit", style={}, color, width="1em", height="1em", opacity=1, ...rest}) => {

  return (
    <svg
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fontSize={size}
      width={width}
      height={height}
      opacity={opacity}
      style={style}
      {...rest}
    >
    
      <path
        d="M7.9 19.1v.613l-.002.014.002.014c.006.152.13.272.282.275l.006.001h7.204a.289.289 0 00.283-.288l.001-.002v-.695c4.044-1.325 7.073-4.601 7.74-8.594l.003-.024a.29.29 0 00-.286-.33H.236a.29.29 0 00-.291.29l.015.13c.7 4.031 3.812 7.326 7.94 8.597M15.7.364c-.094.135-2.307 3.318.05 5.892 1.08 1.182.986 2.224.678 2.928h1.511c.306-1.04.18-2.343-1.047-3.683-1.736-1.897.022-4.46.048-4.5.208-.293.097-.67-.245-.846-.343-.175-.787-.081-.995.21M11.9 9.103h1.512c.305-1.04.179-2.343-1.048-3.683-1.737-1.897.02-4.46.048-4.501.206-.292.097-.668-.246-.845-.342-.174-.787-.081-.994.21-.095.133-2.309 3.317.05 5.891 1.08 1.182.986 2.225.678 2.928M6.6.365c-.096.135-2.308 3.318.049 5.892 1.08 1.182.987 2.225.68 2.928h1.51c.306-1.039.18-2.343-1.048-3.683-1.737-1.897.022-4.46.05-4.501.205-.292.095-.669-.247-.845-.342-.176-.787-.082-.994.21"
        fillRule="nonzero"
        fill={color || "#000000"}
      />

    </svg>

  );
}

export default FoodIcon;