import { Fragment, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Switch, Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, Menu, Radio, Spinner } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

// import { PinInput, PinInputField, usePinInput, usePinInputField, } from "@chakra-ui/pin-input"

import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';
import DialogHeader from './DialogHeader';

import _ from 'lodash';
import styled from "styled-components";

import { useSelector } from 'react-redux';

import { useAuthUpdateUserDetails } from "../services/useAmplifyAuth";

import { I18n } from 'aws-amplify';

const AccountVerify = ({children}) => {

  const countRef = useRef(0);
	const isCurrent = useRef(true);
	useEffect(() => {
		countRef.current = countRef.current + 1;
		console.log(`AccountVerify - ${countRef.current}`);
		return () => {
			console.log("AccountVerify - cleaned up");
			isCurrent.current = false;
		}
	}, []);

	let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  // const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const userState = useSelector(state => state.userState);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState.actAsUser, userState.user]);

  useEffect(() => {
    // trigger auth ???
  }, []);

  useEffect(() => {
    // force return to personal
    // should go to where came from ???
    if (userDetails?.records?.find((record) => (record.type === "emailVerification"))?.status === "succeeded") {
      history.push({
        pathname: generatePath(match.path, {...match.params, mode: "personal"}),
        search: null
      });
    }
  }, [history, match.params, match.path, userDetails?.records]);

  

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);
  
  
  const [userEmail, setUserEmail] = useState();

  const [transactionStatus, setTransactionStatus] = useState();

  const [secretCode, setSecretCode] = useState();
  const [codeExpiryUNIX, setCodeExpiryUNIX] = useState();

  const [processErrorMessage, setProcessErrorMessage] = useState();

  useEffect(() => {
    
    setUserEmail(queryParams.get('email') || userDetails.email);
    setTransactionStatus(queryParams.get('status'));
    if (!queryParams.get('status') && userDetails?.records?.find((record) => (record.type === "emailVerification"))?.status === "required") {
      setTransactionStatus("required");
    }
    setSecretCode(queryParams.get('one-time-code'));
    setCodeExpiryUNIX(queryParams.get('expire'));
        
  }, [queryParams, userDetails.email, userDetails?.records]);


  
  const ResponsivePane = styled(Pane)`
    text-align: right;
    @media only screen and (max-width: ${425}px) {
      text-align: left;
    }
  `;

  return (
    <Pane clearfix maxWidth={960} minHeight="100vh" paddingX={10} alignItems="center" marginLeft="auto" marginRight="auto">

      <Pane marginLeft="auto" marginRight="auto" maxWidth={590} marginTop={36} padding={10} background="tint1" borderRadius={10}>
    
        <Pane maxWidth={570} paddingTop={10} paddingBottom={0} alignItems="center" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} >
            <Pane maxWidth={380} >
              <Strong fontSize={16} color="#425A70" >{I18n.get('Email verification')}</Strong>
              <Pane >
                <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This is needed to make sure you have access to your email.")}</Text>
              </Pane>
            </Pane>
          </Pane>
        </Pane>

        <Pane alignItems="baseline" display="flex" flexWrap="wrap" marginTop={20} >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
            <Strong fontSize={14} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Email')}</Strong> 
          </ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} >
            <Pane maxWidth={380} >

              <Strong fontSize={14} color="#283655" fontWeight={500} display="inline-flex" marginRight={32} >{userEmail}</Strong> 

            </Pane>
          </Pane>
        </Pane>

        {transactionStatus !== "required" ? null :
          <Pane paddingTop={20} paddingBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

                <Pane flex="none" marginRight={36} >
                  <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={processing ? null : "button-blue"}
                    disabled={processing}
                    onClick={() => {
                      setProcessing(true);
                      handleUpdateUserDetails({
                        recordsToUpdate: [{
                          type: "emailVerification",
                        }],
                        onSuccess: (freshUserDetails) => {
                          let queryParams = new URLSearchParams(location.search);
                          queryParams.set('status', "pending");
                          history.push({
                            search: `?${queryParams.toString()}`
                          });
                          setProcessing(false);
                        },
                        onError: (error) => {
                          setProcessing(false);
                        },
                      });
                    }}
                  >
                    {I18n.get("Send verification code")}
                    {!processing ? null : 
                      <Spinner marginLeft={8} flex="none" size={20} />
                    }
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        }

        {transactionStatus !== "pending" ? null :
          <Fragment>
            <Pane alignItems="baseline" display="flex" flexWrap="wrap" marginTop={20} >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
                <Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
                <Strong fontSize={14} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Secret code')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <Pane maxWidth={380} >
                  <TextInput
                    disabled={processing}
                    name={"one-time-code"}
                    type={"text"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Secret code')}...`}
                    value={secretCode || ""}
                    onChange={(e) => {setSecretCode(e.target.value);}}
                    // onFocus={(e) => {setProcessErrorMessage()}}
                  />

                  {/* <PinInput size='lg' placeholder=''>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput> */}

                  <Pane display="flex" marginTop={4}>
                    <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get('The 6-character code we sent to your email.')}</Text>
                  </Pane>

                </Pane>
              </Pane>
            </Pane>

            {!processErrorMessage ? null : 
              <Pane alignItems="baseline" display="flex" flexWrap="wrap" marginTop={20} >
                <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
                <Pane flex={1} flexBasis={242} maxWidth={390} >
                  <Pane maxWidth={380} >
                      
                    <Strong fontSize={14} color="#EC4C47" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get(processErrorMessage)}</Strong> 

                  </Pane>
                </Pane>
              </Pane>
            }

            <Pane paddingTop={20} paddingBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <Pane width="100%" maxWidth={380} height="height" alignItems="center" display="flex" flexWrap="wrap">

                <Pane flex="none" marginRight={36} >
                  <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={processing ? null : "button-blue"}
                    disabled={processing || !secretCode }
                    onClick={() => {
                      setProcessErrorMessage();
                      setProcessing(true);
                      handleUpdateUserDetails({
                        recordsToUpdate: [{
                          type: "emailVerification",
                          accessCode: secretCode,
                        }],
                        onSuccess: (freshUserDetails) => {
                          console.log("freshUserDetails after emailVerification:", freshUserDetails);
                          if (userDetails?.records?.find((record) => (record.type === "emailVerification"))?.status === "succeeded") {
                            history.push({
                              pathname: generatePath(match.path, {...match.params, mode: "personal"}),
                              search: null
                            });
                          }
                          else {
                            let existingEmailVerificationRecord = freshUserDetails?.records?.find((record) => (record.type === "emailVerification"));
                            if (existingEmailVerificationRecord.status === "error") {
                              if (existingEmailVerificationRecord.description === "Invalid access code.") {
                                setProcessErrorMessage("Invalid secret code.");
                                setSecretCode();
                              }
                              else {
                                setProcessErrorMessage("Something went wrong.");
                              }
                              
                            }
                            // queryParams.set('status', "error");
                            // history.push({
                            //   search: `?${queryParams.toString()}`
                            // });
                          }
                          setProcessing(false);
                        },
                        onError: (error) => {
                          setProcessing(false);
                        },
                      });
                    }}
                  >
                    {I18n.get("Submit")}

                    {!processing ? null : 
                      <Spinner marginLeft={8} flex="none" size={20} />
                    }

                  </Button>
                </Pane>
                
                <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                  disabled={processing}
                  onClick={() => {

                    history.push({
                      pathname: generatePath(match.path, {
                        ...match.params,
                        mode: "personal" // make dynamic
                      }),
                      // search: `?${queryParams.toString()}`
                    });
                  }}
                >
                  {I18n.get("Cancel")}
                </Button>

                </Pane>
              </Pane>
            </Pane>

          </Fragment>
        }


      </Pane>

      {children}




    </Pane>
  );

}

export default AccountVerify;