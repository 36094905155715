import { Fragment, useEffect, useState, useRef, useCallback, useMemo } from 'react';

import { Link, Redirect, useHistory, useParams, generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import { Text, TextInput, Dialog, Paragraph, EyeOffIcon, EyeOpenIcon, SmallTickIcon, Button, Spinner, Checkbox, CircleIcon, TickCircleIcon, IconButton, ChevronLeftIcon, Pane, ListItem, UnorderedList, Strong } from 'evergreen-ui';

import { I18n } from 'aws-amplify';

import { useResetUserPassword, useAuthUserSignIn, useAuthUserSignUp } from "../services/useAmplifyAuth";
import { useSelector, useDispatch, batch } from 'react-redux';

import { updateUserState, updateLocaleState, setTransactionDetails, updateProfileState, updateTransactionDetails } from '../services/actions';

// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import DialogHeader from './DialogHeader';
import ModalResponsive from './ModalResponsive';
import PageDrawer from './PageDrawer';

function isValidEmail(string) {
  if (!string) {
    return false;
  }
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

function hasEightChar(string) {
  if (!string) {
    return false;
  }
  const re = /(.{8,}$)/;
  return re.test(String(string));
};

function hasDigitChar(string) {
  if (!string) {
    return false;
  }
  const re = /^(?=.*\d)/;
  return re.test(String(string));
};

function hasUpperChar(string) {
  if (!string) {
    return false;
  }
  const re = /(?=.*[A-Z])/;
  return re.test(String(string));
};

function hasLowerChar(string) {
  if (!string) {
    return false;
  }
  const re = /(?=.*[a-z])/;
  return re.test(String(string));
};

function validatePassword(passwordMain, passwordConfirmed) {
  if (!passwordMain || !passwordConfirmed || passwordMain !== passwordConfirmed) {
    return false;
  }
  const re = /(?=.{8,}$)(?=^.*\d)(?=.*[A-Z])(?=.*[a-z])/;
  return re.test(String(passwordMain));
}

const PasswordRequirements = ({password, onValidation, confirmedPassword}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PasswordRequirements - ${countRef.current}`);
    return () => {
      console.log("PasswordRequirements - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  useEffect(() => {
    let tempValid = validatePassword(password, confirmedPassword);
    onValidation(tempValid);
  }, [confirmedPassword, onValidation, password]);


  return(
    <Pane >
      <Pane display="flex" marginTop={4}>
        <Text fontSize={14} color="#425A6F" >{I18n.get('Please make sure your password:')}</Text>
      </Pane>
      <Pane display="flex">
        <UnorderedList
          icon={SmallTickIcon}
          iconColor="success"
        >
          <ListItem margin={1} icon={hasUpperChar(password) && hasLowerChar(password) ? TickCircleIcon : CircleIcon} iconColor={hasUpperChar(password) && hasLowerChar(password) ? "#47B881" : "#7B8B9A" } >{I18n.get('contains uppercase and lowercase letters')}</ListItem>
          <ListItem margin={1} icon={hasDigitChar(password) ? TickCircleIcon : CircleIcon} iconColor={hasDigitChar(password) ? "#47B881" : "#7B8B9A" } >{I18n.get('contains a digit')}</ListItem>
          <ListItem margin={1} icon={hasEightChar(password) ? TickCircleIcon : CircleIcon} iconColor={hasEightChar(password) ? "#47B881" : "#7B8B9A" } >{I18n.get('at least 8 characters')}</ListItem>
        </UnorderedList>
      </Pane>
    </Pane>
  );
}

const ErrorSection = ({ error, onGoToSignUp, onGoToLogIn, onTryAgain, onGoToResetPassword }) => {

  function getErrorTexts(error, part) {
  
    if (!error || !error.message) {
      return;
    }
  
    let errorMessage = error.message;
  
    let text = "Something went wrong.";
    let action = I18n.get("Please try again.");
    let systemErrorMessage = null;
  
    let errorTexts = {
      "User does not exist.": {
        text: I18n.get("Email is not registered."),
        // action: I18n.get("Please sign up or try again."),
      },
      "User account already exists.": {
        text: I18n.get("Email is already registered."),
        // action: I18n.get("Please try logging in."),
      },
      "Incorrect username or password.": {
        text: I18n.get("Incorrect details."),
        action: I18n.get("Please check details and try again."),
      },


      "Invalid session for the user.": {
        text: I18n.get("The secret code has expired."),
      },
      
      

      x_passwords_do_not_match: {
        text: I18n.get("Passwords don't match."),
      },
      x_auth_too_old: {
        text: I18n.get("It has been a while..."),
      },
      x_wrong_code: {
        text: I18n.get("Invalid code."),
      },
      x_unauthenticated_user: {
        text: I18n.get("No unauthenticated request."),
      },
    };
  
    if (errorTexts[errorMessage]) {
      let errorObject = errorTexts[errorMessage];
      if (errorObject["text"]) {
        text = errorObject["text"];
      }
      if (errorObject["action"]) {
        action = errorObject["action"];
      }
    }
    else {
      systemErrorMessage = errorMessage;
    }
  
    if (part === "text") {
      return text;
    }
    else if (part === "action") {
      return action;
    }
    else if (part === "system") {
      return systemErrorMessage;
    }
    else {
      return text;
    }
  
  }
  
  return (
    !error ? null :

      <Pane marginTop={12} paddingY={8} paddingX={8} textAlign="center"
        background="#EC4C4710"
        borderTopWidth={1} borderTopStyle="solid"
        borderBottomWidth={1} borderBottomStyle="solid"
        borderColor="#EC4C47" >

        <Pane marginTop={4} >
          <Strong fontSize={16} color="#EC4C47">{getErrorTexts(error, "text")}</Strong>
        </Pane>
        
        {/* <Paragraph marginTop={4} lineHeight={1} >
          <Text fontSize={12} color="#EC4C47">{getErrorTexts(error, "action")}</Text>
        </Paragraph> */}

        {!getErrorTexts(error, "system") ? null :
          <Paragraph marginTop={4} lineHeight={1} >
            <Text fontSize={12} color="#EC4C47">{getErrorTexts(error, "system")}</Text>
          </Paragraph>
        }

        <Pane alignItems="center" display="flex" gap={8} >
          {error.message !== "User does not exist." ? null :
            <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
              className="noselect"
              // disabled={}
              onClick={onGoToSignUp}
            >
              {I18n.get('Sign up')}
            </Button>
          }

          {error.message !== "User account already exists." ? null :
            <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
              className="noselect"
              // disabled={}
              onClick={onGoToLogIn}
            >
              {I18n.get('Log in')}
            </Button>
          }
          
          {false ? null :
            <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
              className="noselect"
              // disabled={}
              onClick={onTryAgain}
            >
              {I18n.get('Try again')}
            </Button>
          }

          {error.message !== "Incorrect username or password." ? null :
            <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
              className="noselect"
              // disabled={}
              onClick={onGoToResetPassword}
            >
              {I18n.get('Reset password')}
            </Button>
          }

        </Pane>
        
        <Paragraph marginTop={4} >
          <Text fontStyle="italic" fontSize={12} color="#EC4C47">{I18n.get("If the error persists, please try to reload this page.")}</Text>
        </Paragraph>

      </Pane>
  );
}

const DialogAuth = ({ ...rest }) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`DialogAuth - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("DialogAuth - cleaned up");
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  const profileState = useSelector(state => state.profileState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const userState = useSelector(state => state.userState);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const handleUserSignIn = useAuthUserSignIn();
  const handleUserSignUp = useAuthUserSignUp();
  const { handleSendSecretCode, handleVerifySecretCode } = useResetUserPassword();

  const [processing, setProcessing] = useState(false);
  const [processError, setProcessError] = useState();
  const [goToAccountSettings,  setGoToAccountSettings] = useState(false);

  const [signInUserEmail, setSignInUserEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [signUpErrorCount, setSignUpErrorCount] = useState(0);
  
  const signInUserEmailRef = useRef(null);
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  
  const [authedUser, setAuthedUser] = useState();
  
  const [authCode, setAuthCode] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [codeFromUrlParams, setCodeFromUrlParams] = useState(false);
  const [codeExpiryUNIX, setCodeExpiryUNIX] = useState();

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);

    // for (let p of queryParams) {
    //   console.log(p);
    // }
    
    let adjustedQuerystring = "";
    
    let currentUserName = queryParams.get('username');
    let currentCode = queryParams.get('one-time-code');
    let currentCodeExpiryUNIX = queryParams.get('expire');
    if (currentUserName) {
      setSignInUserEmail(currentUserName);
      // queryParams.delete('username');
    }
    if (currentCode) {
      setCodeSent(true);
      setCodeFromUrlParams(true);
      setAuthCode(currentCode);
      // queryParams.delete('one-time-code');
    }
    if (currentCodeExpiryUNIX) {
      setCodeExpiryUNIX(currentCodeExpiryUNIX);
      // queryParams.delete('expire');
    }
    
    // is URL cleaning needed?
    // if (currentUserName || currentCode) {
    //   console.log("clear params in URL");
    //   if (queryParams.toString()) {
    //     adjustedQuerystring = `?${queryParams.toString()}`;
    //   }
    //   history.replace({
    //     // pathname: generatePath(match.path, {...match.params, locale: null}),
    //     search: adjustedQuerystring,
    //   });
    // }
    
  }, [location.search]);

  // ???
  useEffect(() => {
    if (!signInUserEmail && transactionDetails?.fromFace?.email) {
      setSignInUserEmail(transactionDetails?.fromFace?.email);
    }
  }, [signInUserEmail, transactionDetails?.fromFace?.email]);

 
  // when user is signed-in
  useEffect(() => {
    if (profileState.localStage === "DialogAuth") {
      if (userDetails) {
        dispatch(updateProfileState({
          localStage: null,
          localMode: null,
        }));

        history.push({
          pathname: generatePath("/:page", {page: "auth"}),
        });

      }
      else if (profileState.localMode && profileState.localMode !== match.params.page && (match.params.page === "log-in" || match.params.page === "sign-up" || match.params.page === "forgot-password")) {
        history.push({
          pathname: generatePath("/:page", {page: profileState.localMode}),
        });
      }
    }
    
  }, [dispatch, history, match.params.page, profileState.localMode, profileState.localStage, userDetails]);


  const handleShowResetPasswordView = () => {
    if (processing) {
      return;
    }
    setProcessError();
    dispatch(updateProfileState({
      localMode: "forgot-password",
    }));
  }
  
  const handleShowSignUpView = () => {
    if (processing) {
      return;
    }
    setProcessError();
    dispatch(updateProfileState({
      localMode: "sign-up",
    }));
  }

  const handleShowLogInView = (clearPasswords) => {
    if (processing) {
      return;
    }
    setProcessError();
    dispatch(updateProfileState({
      localMode: "log-in",
    }));
    if (clearPasswords === true) {
      // console.log("clear clearPasswords");
      setCurrentPassword();
      setAuthCode();
    }
    setNewPassword();
    setAgreeToTerms(false);
    setCodeSent(false);
  }

  const handleUserSignInProcess = () => {
    setProcessing(true);
    handleUserSignIn({
      username: signInUserEmail,
      password: currentPassword,
      onSuccess: (userDetails) => {
        dispatch(updateProfileState({
          localStage: null,
          localMode: null,
        }));
        history.push({
          pathname: generatePath("/:handle", {...match.params, handle: userDetails?.username}),
          // search: "",
          // state: state,
        });
        setProcessing(false);
      },
      onError: (error) => {
        // console.error("within handleUserSignInProcess", error);
        setProcessError(error);
        setProcessing(false);
      },
    });
  }

  const handleUserSignUpProcess = useCallback(() => {
    setProcessing(true);
    handleUserSignUp({
      email: signInUserEmail,
      password: currentPassword,
      clientMetadata: {
        locale: localeState.locale,
        currency: localeState.currency,
      },
      onSuccess: () => {
        handleUserSignIn({
          username: signInUserEmail,
          password: currentPassword,
          onSuccess: (userDetails) => {
            
            dispatch(updateProfileState({
              localStage: null,
              localMode: null,
            }));
            
            history.push({
              pathname: "/account/personal", // generatePath("/:handle", {...match.params, handle: userDetails?.username}),
              // search: "",
              // state: state,
            });
            setProcessing(false);
          },
          onError: (error) => {
            setProcessError(error);
            setProcessing(false);
          },
        });
      },
      onError: (error) => {
        setSignUpErrorCount(signUpErrorCount + 1);
        if (signUpErrorCount > 1) {
          setSignInUserEmail();
          setCurrentPassword();
          setNewPassword();
          setAgreeToTerms(false);
        }
        setProcessError(error);
        setProcessing(false);
      },
    });
  }, [currentPassword, dispatch, handleUserSignIn, handleUserSignUp, history, localeState.currency, localeState.locale, signInUserEmail, signUpErrorCount]);

  const handleSendResetCode = () => {
    // setCodeSent(true);
    // return;
    setProcessing(true);
    const clientMetadata = {
      locale: "x"
    };
    handleSendSecretCode({
      username: signInUserEmail,
      clientMetadata: clientMetadata,
      onSuccess: (user) => {
        setCodeExpiryUNIX(Math.round(new Date().getTime()/1000) + 180);
        setAuthedUser(user);
        setCurrentPassword();
        setNewPassword();
        setCodeSent(true);
        setProcessing(false);
      },
      onError: (error) => {
        setProcessError(error);
        setProcessing(false);
      },
    });
  }

  // handleResendResetCode = () => {}

  const handleValidateResetCode = () => {
    setProcessing(true);

    const clientMetadata = {
      locale: "x"
    };

    handleVerifySecretCode({
      user: authedUser,
      username: signInUserEmail,
      code: authCode,
      newPassword: newPassword,
      clientMetadata: clientMetadata,
      onSuccess: (userDetails) => {
        dispatch(updateProfileState({
          localStage: null,
          localMode: null,
        }));
        history.push({
          pathname: generatePath("/:handle", {...match.params, handle: userDetails?.username}),
          // search: "",
          // state: state,
        });
      },
      onError: (error) => {
        console.error("within handleVerifySecretCode error", error);
        setProcessError(error);
        setProcessing(false);
      },
    });
  }

  // const handleKeypressEmail = (e) => {
  //   e.persist();
  //   console.log("event", e);
  //   if (e.code === "code" || e.charCode === 13 || e.keyCode === 13 || e.which === 13) {
  //     console.log("try submitting...");
  //     // handleUserSignInProcess();
  //   }
  // }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    e.persist();
    // console.log("handleSubmitForm", e); // useful ???
  }

  return (

    <ModalResponsive
      // ref={dialogRef}
      isShown={profileState.localStage === "DialogAuth"}
      // onOpenComplete={() => {}}
      onCloseComplete={() => {

        dispatch(updateProfileState({
          localStage: null,
          localMode: null,
        }));

        // if (!transactionDetails.localStage) {

        //   if (userDetails?.records?.find((record) => (record.type === "emailVerification"))?.status === "required") {
        //     // trigger required verification
        //     history.push("/account/verify");
        //   }
        //   // else if (goToAccountSettings) {
        //   //   console.log("Go to Settings, Personal details");
        //   //   history.push("/account/personal");
        //   // }
        //   // else if (userDetails?.username && !match.params.handle && !match.params.page) {
        //   //   console.log("Go to page", userDetails?.username);
        //   //   history.push(generatePath("/:handle", {...match.params, handle: userDetails?.username}));
        //   // }
        //   else if (match.params.page === "log-in" || match.params.page === "sign-up" || match.params.page === "forgot-password") {
        //     console.log("Return to Home page");
        //     history.push({
        //       pathname: "/",
        //     });
        //   }
        // }
        
        setProcessing(false);
        setCurrentPassword();
        setNewPassword();
        setAuthCode();
        setCodeSent(false);
        setProcessError();
        setIsPasswordVisible(false);

      }}
      // onCancel={(close) => close()}
      title='DialogAuth'
      hasHeader={false}
      hasFooter={false}
      preventBodyScrolling
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      width={350} // 350 // "100%"
      height={"100%"}
      topOffset={10}
      containerProps={{
        "backgroundColor": "transparent",
        // "maxWidth": "100%",
        "maxHeight": "100%", // "calc(100% - 36px * 2)",
        "margin": "auto", // auto
        "overflow": 'scroll',
      }}
      contentContainerProps={{
        "padding": "0px",
        "maxHeight": "75hv",
      }}
    >
    {({ close }) => (
      <Pane background="#FFFFFF" borderRadius={10} padding={10} >

        <DialogHeader
          headerText={profileState.localMode === "sign-up" ? 
            I18n.get('Sign up')
            :
            profileState.localMode === "forgot-password" ? I18n.get('Reset password') : I18n.get('Log in')
          }
          hideLeftButton={false}
          hideRightButton={true}
          leftButtonDisabled={processing}
          leftButtonIcon={profileState.localMode === "forgot-password" ? ChevronLeftIcon : null}
          onLeftButtonClick={() => {
            if (profileState.localMode === "forgot-password") {
              handleShowLogInView(true);
            }
            else {
              close();
            }
          }}
        />
        
        <Pane marginTop={10} width="100%" height={"100%"} maxHeight={"calc(100vh - 50px)"} overflow={"hidden"} >
          <Pane position={"relative"} maxHeight={"calc(100vh - 0px - 0px - 36px * 2)"} overflow={"scroll"} >
                        
          <form
            onSubmit={handleSubmitForm} //keep
          >

            <Pane marginTop={10} >
              <Strong htmlFor="username" fontSize={14} color="#425A70" >{I18n.get('Email')}</Strong>
            </Pane>

            {!codeSent ? 

              <Fragment>
                <Pane marginTop={4} >
                  <TextInput
                    ref={signInUserEmailRef}
                    disabled={processing}
                    name={"username"}
                    autoComplete={"username"}
                    type={profileState.localMode === "sign-up" ? "email" : "text"} //
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Email')}...`}
                    value={signInUserEmail}
                    onChange={(e) => {
                      setSignInUserEmail(e.target.value);
                    }}
                    onFocus={(e) => {setProcessError()}}
                    // onKeyDown={handleKeypressEmail}
                  />
                </Pane>

                {!(profileState.localMode === "forgot-password") ? null :
                  <Pane display="flex" marginTop={4} paddingX={2} >
                    <Text fontSize={14} fontStyle="italic" color="#7B8B9A" >{I18n.get('We will email you a one-time code to securely reset your password.')}</Text>
                  </Pane>
                }

              </Fragment>
            
            :
              <Fragment>
              
                <Pane marginTop={1} paddingY={10} paddingX={13} borderRadius={3} backgroundColor={"#F5F6F7"} >
                  <Text htmlFor="username" fontSize={14} color="#283655" >{signInUserEmail}</Text>
                </Pane>

                {codeFromUrlParams ? null :
                  <Pane display="flex" marginTop={4}>
                    <Text fontSize={14} color="#47B881" >{I18n.get('Please check your email.')}</Text>
                  </Pane>
                }

                <Pane marginTop={10} >
                  <Strong htmlFor="one-time-code" fontSize={14} color="#425A70" >{I18n.get('Secret code')}</Strong>
                </Pane>
                
                <Pane marginTop={4} >
                  <TextInput
                    disabled={processing}
                    name={"one-time-code"}
                    type={"text"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Secret code')}...`}
                    value={authCode}
                    onChange={(e) => {setAuthCode(e.target.value);}}
                    onFocus={(e) => {setProcessError()}}
                  />
                </Pane>

                <Pane display="flex" marginTop={4}>
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get('6-character code we sent you')}</Text>
                </Pane>
                
              </Fragment>
            }
              
            {profileState.localMode === "forgot-password" && !codeSent ? null :
              <Fragment>
                <Pane alignItems="center" display="flex" marginTop={10} marginBottom={4} >
                  <Strong htmlFor={profileState.localMode === "sign-up" || codeSent ? "new-password" : "current-password"} flex={1} fontSize={14} color="#425A70" >{profileState.localMode === "sign-up" || codeSent ? I18n.get('New password') : I18n.get('Password')}</Strong>
                  {profileState.localMode === "sign-up" || codeSent ? null :
                    <Pane flex={1} textAlign="right" >
                      <Button flex={1} paddingX={4} fontSize={14} height={20} appearance="minimal" intent="none"
                        type="button"
                        // disabled={processing}
                        onClick={handleShowResetPasswordView}>
                        {I18n.get('Forgot password?')}
                      </Button>
                    </Pane>
                  }
                </Pane>
                
                <Pane alignItems="center" display="flex" gap={8} >
                  <TextInput
                    ref={currentPasswordRef}
                    disabled={processing}
                    name={profileState.localMode === "sign-up" || codeSent ? "new-password" : "current-password"}
                    autoComplete={profileState.localMode === "sign-up" || codeSent ? "new-password" : "current-password"}
                    type={isPasswordVisible ? "text" : "password"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={profileState.localMode === "sign-up" || codeSent ? `${I18n.get('New password')}...` : `${I18n.get('Password')}...`}
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                    onFocus={(e) => {setProcessError()}}
                  />

                  <IconButton icon={isPasswordVisible ? EyeOffIcon : EyeOpenIcon} iconSize={18} width={40} height={40} flex={"none"}
                    type="button"
                    disabled={processing}
                    onClick={() => {
                      setIsPasswordVisible((prev) => {
                        return !prev;
                      });
                    }}
                  />

                </Pane>

              </Fragment>
            }
            
            {!(profileState.localMode === "sign-up") && !codeSent ? null :
              <Fragment>

                <PasswordRequirements password={currentPassword} confirmedPassword={newPassword} onValidation={setIsValidPassword} />
                
                <Pane alignItems="center" display="flex" marginTop={10} marginBottom={4} >
                  <Strong htmlFor="new-password" flex={1} fontSize={14} color="#425A70" >{I18n.get('Confirm new password')}</Strong>
                </Pane>

                <Pane alignItems="center" display="flex" gap={8} >
                  <TextInput
                    ref={newPasswordRef}
                    disabled={processing}
                    name={"new-password"}
                    autoComplete={"new-password"}
                    type={isPasswordVisible ? "text" : "password"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Confirm new password')}...`}
                    value={newPassword}
                    onChange={(e) => {setNewPassword(e.target.value);}}
                    onFocus={(e) => {setProcessError()}}
                  />
                  <IconButton icon={isPasswordVisible ? EyeOffIcon : EyeOpenIcon} iconSize={18} width={40} height={40} flex={"none"}
                    type="button"
                    disabled={processing}
                    onClick={() => {
                      setIsPasswordVisible((prev) => {
                        return !prev;
                      });
                    }}
                  />
                </Pane>

                <Pane marginTop={4}>
                  <UnorderedList>
                    <ListItem margin={1} icon={currentPassword && newPassword && currentPassword === newPassword ? TickCircleIcon : CircleIcon} iconColor={currentPassword && newPassword && currentPassword === newPassword ? "#47B881" : "#7B8B9A" } >
                    {currentPassword && newPassword && currentPassword === newPassword ? I18n.get('passwords match') : I18n.get("passwords don't match")}
                    </ListItem>
                  </UnorderedList>
                </Pane>

              </Fragment>
            }

            {!(profileState.localMode === "sign-up") ? null :
            
              <Pane height={40} display="flex" alignItems="center"
                marginTop={12}
                // borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F40"
              >

                <Pane display="flex">
                  <Checkbox
                    size={18}
                    disabled={processing}
                    checked={agreeToTerms}
                    onChange={(e) => {setAgreeToTerms(e.target.checked)}}
                  />
                </Pane>

                <Pane display="flex" marginLeft={12}>
                  <Paragraph >
                    <Strong fontSize={14} color={"#283655"} >{`${I18n.get('I agree to')} `}</Strong>
                    <Strong fontSize={14} color={"#283655"} className="blue-link"
                      is={Link}
                      target="_blank"
                      to={{
                        pathname: generatePath("/:locale?/:page", {...match.params, page: "legal"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Terms and Privacy Policy')}
                    </Strong>
                  </Paragraph>
                </Pane>
              </Pane>
            }
            
            <Pane marginTop={12}>

              <Button fontSize={16} padding={8} height={48} width="100%" justifyContent="center"
                className='button-green'
                type="submit"
                disabled={
                    profileState.localMode === "sign-up" ? (processError|| !isValidPassword || currentPassword !== newPassword || !isValidEmail(signInUserEmail) || !signInUserEmail || !agreeToTerms)
                    :
                    profileState.localMode === "forgot-password" ?
                        codeSent ? (processError|| !authCode || !isValidPassword || !isValidEmail(signInUserEmail) || !signInUserEmail) : (!isValidEmail(signInUserEmail) || !signInUserEmail)
                      :
                        (processError || !currentPassword || !signInUserEmail || !isValidEmail(signInUserEmail))
                }
                onClick={
                  profileState.localMode === "forgot-password" ? (!codeSent ? handleSendResetCode : handleValidateResetCode)
                :
                  (!(profileState.localMode === "sign-up") ? handleUserSignInProcess : handleUserSignUpProcess)
                }
                // isLoading={processing}
              >{
                processing ?
                  <Spinner flex="none" size={24} />
                :
                  profileState.localMode === "forgot-password" ? 
                    codeSent ? I18n.get('Reset password') : I18n.get('Send code')
                  :
                    !(profileState.localMode === "sign-up") ? I18n.get('Log in') : I18n.get('Sign up')
              }</Button>
            </Pane>
          
          </form>

          <ErrorSection error={processError}
            onGoToSignUp={handleShowSignUpView}
            onGoToLogIn={handleShowLogInView}
            onGoToResetPassword={handleShowResetPasswordView}
            onTryAgain={() => {
              setProcessError();
            }}
          />

          {codeSent ? null :
            <Fragment>
              <Pane marginTop={12} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" className="noselect" ></Pane>

              <Pane height={40} marginLeft="auto" marginRight="auto" marginTop={10} alignItems="center" display="flex" className="noselect" justifyContent="center" >
                
                <Pane >
                  <Strong fontSize={14} fontWeight={500} color="#425A70" >
                    {profileState.localMode === "forgot-password" ?
                      !codeSent ? I18n.get("Remember password?") : null
                    :
                      !(profileState.localMode === "sign-up") ? I18n.get("Don't have an account?") : I18n.get('Have an account?')
                    }
                  </Strong>
                </Pane>

                {!codeSent ?
                  <Button marginLeft={4} paddingX={4} fontSize={14} height={20} justifyContent="center" appearance="minimal"
                    type="button"
                    onClick={!(profileState.localMode === "sign-up") && !(profileState.localMode === "forgot-password") ? handleShowSignUpView : handleShowLogInView}
                  >
                    {!(profileState.localMode === "sign-up") && !(profileState.localMode === "forgot-password") ? I18n.get('Sign up') : I18n.get('Log in')}
                  </Button>
                : null}

              </Pane>
            </Fragment>
          }
          

          </Pane>
        </Pane>

        <Pane padding={0} height={0} >
          {/* <button style={{fontSize: "1rem"}}
            onClick={() => {
              close();
            }}
          >Close modal</button> */}
        </Pane>

      </Pane>
      
    )}
    </ModalResponsive>
  );
}

export default DialogAuth;