import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Button, Pane, TextInput } from 'evergreen-ui';

import _, { includes, sortedLastIndex } from 'lodash';

import { I18n } from 'aws-amplify';

import Footer from '../components/Footer';

import FeedbackImpactForm, { newFeedbackImpactBreakdown } from '../components/FeedbackImpactForm';

import NumberPadComponent from '../components/NumberPadComponent';
import FeedbackPicturesInput from '../components/FeedbackPicturesInput';
import ProvisionFundingOptions from '../components/ProvisionFundingOptions';

import { NumericFormat } from 'react-number-format';

import NewDonationView from '../components/NewDonationView';
import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from '../components/FormattedCurrencyNumber';

import { currencies } from '../services/localeDictionary';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, updateProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';


const ProvisionAmountInput = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProvisionAmountInput - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("ProvisionAmountInput - cleaned up");
    }
  }, []);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const localeState = useSelector(state => state.localeState);

  const amountInputRef = useRef();

  const [localAmountTextFormatted, setLocalAmountTextFormatted] = useState(transactionDetails.amount ? Number(transactionDetails.amount)?.toFixed(2) : "");
  
  // ???
  const claimLimit = useMemo(() => {
    if (false) {
      return (currencies[localeState.currency].limitValue * (userState.user?.rights?.provideInternallyLimitFactor || 1));
    }
    return (currencies[localeState.currency].limitValue * (userState.user?.rights?.provideExternallyLimitFactor || 1));
  }, [localeState.currency, userState.user?.rights?.provideExternallyLimitFactor, userState.user?.rights?.provideInternallyLimitFactor]);

  
  return (
    <Fragment>

      <Pane marginTop={10} textAlign="center" >
        <Strong fontSize={14} color="#425A70" >{I18n.get('Amount')}</Strong>
      </Pane>

      <Pane marginTop={4} marginLeft="auto" marginRight="auto" width="fit-content" textAlign="center" alignItems="center" display="flex" flexWrap="wrap" className="noselect" >

        {currencies[transactionDetails?.currency]?.symbolRight ? null :
          <Pane
            paddingX={8}
            height={48}
            border
            borderWidth={1}
            borderStyle="solid"
            borderColor="#C7CED4"
            justifyContent="center"
            background={"#F9F9FB"} // #F9F9FB // #E4E7EB
            borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
            className="noselect"
            display={"flex"}
            alignItems="center"
          >
            <Strong fontSize={24} lineHeight={1} display={"block"} color={"#283655"} >{currencies[transactionDetails?.currency]?.symbol}</Strong>
          </Pane>
        }
        

        <NumericFormat
          // https://s-yadav.github.io/react-number-format/docs/numeric_format
          getInputRef={amountInputRef}
          // id={"allocation-amount"}
          customInput={TextInput}
          // min={"0"}
          // step={"0.01"}
          // format={format}
          allowLeadingZeros={false}
          thousandSeparator=","
          allowNegative={false}
          decimalScale={2}
          // fixedDecimalScale
          // allowedDecimalSeparators="."
          type={"input"} // Supported types include text | tel | password
          pattern={"[0-9]*"} // to bring up the numeric keypad
          inputMode="decimal" // to bring up the numeric keypad with a dot // https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
          // autoComplete="off" // doesn't seem to work
          // autoComplete={"allocation-amount"}
          // name={"allocation-amount"}
          disabled={transactionDetails.localIsProcessing}
          isInvalid={transactionDetails.amount < 0}
          marginTop={0}
          marginBottom={0}
          width={160}
          fontWeight={600}
          height={48}
          fontSize={30} // 14
          color={"#283655"}
          placeholder={"0.00"}

          paddingY={0}
          marginLeft={-1}
          marginRight={0}
          
          borderRadius={0}
          // style={{
          //   boxShadow: "none",
          //   border: "solid",
          //   borderWidth: "1px",
          //   borderColor: "#C7CED4",
          //   borderRight: "0px",
          // }}
          // border={"solid"}
          // borderTopLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={currencies[updatedAllocationAmountsRecord?.currency].symbolRight ? 5 : null}
          isAllowed={(values, sourceInfo) => {
            const { value } = values;
            return !(value < 0);
          }}
          value={localAmountTextFormatted}
          // defaultValue={null}
          onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
            // console.log("values", values);
            // console.log("values.value", values.value);
            // let newAmount = values.value ? Math.round((Number(values.value) + Number.EPSILON) * 100) / 100 : null; // not sure if still needed ???
            // let newAmount = values.value;
            // console.log("newAmount", newAmount);
            dispatch(updateTransactionDetails({
              amount: values.floatValue || null,
            }));
          }}
          // onChange={(event) => {
          //   console.log("onChange event", event);
          // }}
          onBlur={(event) => {
            // console.log("event", event);
            setLocalAmountTextFormatted(transactionDetails.amount ? Number(transactionDetails.amount)?.toFixed(2) : "");
            
          }}
        />

        {!currencies[transactionDetails?.currency]?.symbolRight ? 
        
          <Pane
            paddingX={4}
            height={48}
            border
            borderWidth={1}
            borderStyle="solid"
            borderColor="#C7CED4"
            background={"#F9F9FB"} // #F9F9FB // #E4E7EB
            borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
            borderLeftStyle={"none"}
          />
        
        :
          <Pane
            paddingX={8}
            height={32}
            justifyContent="center"
            background={"#F9F9FB"}
            borderRadius={0}
            className="noselect"
            display={"flex"}
            alignItems="center"
            borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
            borderLeftStyle={"none"}
          >
            <Strong fontSize={14} lineHeight={1} display={"block"} color={"#283655"} >{currencies[transactionDetails?.currency]?.symbol}</Strong>
          </Pane>
        }
        
      </Pane>

      {transactionDetails.amount > claimLimit ?
        <Pane textAlign="center" marginTop={4} className="noselect" >
          <Text fontSize={12} fontStyle="italic" color={transactionDetails.amount > claimLimit ? "#EC4C47" : "#7B8B9A"} >{`${handleFormattedCurrencyNumber(claimLimit, localeState.currency, false, true)} ${I18n.get("max.")}`}</Text>
        </Pane>
      : 
        null
      }

      {/* <Pane textAlign="center" marginTop={4} className="noselect" >
          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Value of what you are providing")}</Text>
        </Pane> */}
      
      {/* <NumberPadComponent value={transactionDetails.amount} onChange={(value) => {
        // needs fixing ...
        dispatch(updateTransactionDetails({
					amount: value,
				}));
      }} disabled={false} /> */}

      
    </Fragment>
  );
}

const ProvisionReceiptPicturesInput = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProvisionReceiptPicturesInput - ${countRef.current}`);
    return () => {
      console.log("ProvisionReceiptPicturesInput - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);

  const updateProvisionReceiptProofImages = (S3Objects) => {

    let updatedHistory = transactionDetails?.history || [];
    if (!updatedHistory.find((t) => (t.type === "provisionReceipt"))) {
      updatedHistory = [
        ...transactionDetails?.history || [],
        {
          type: "provisionReceipt",
          proofImages: [],
        }
      ]
    }
    const provisionReceipt = updatedHistory.find((t) => (t.type === "provisionReceipt"));
    provisionReceipt.proofImages = S3Objects;

    dispatch(updateTransactionDetails({
			history: updatedHistory,
		}));

  }

  return (
    <Fragment>

      <Pane textAlign="center" marginTop={16} >
        <Strong fontSize={14} color="#425A70">{I18n.get('Receipt')}</Strong>
      </Pane>

      {transactionDetails?.history?.find((t) => (t.type === "provisionReceipt"))?.proofImages?.length > 0 ? null :
        <Pane textAlign="center" marginTop={4} >
          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Add pictures of the receipt from your till")}</Text>
        </Pane>
      }

      <FeedbackPicturesInput
        disabled={!transactionDetails.amount || transactionDetails.localIsProcessing}
        values={transactionDetails?.history?.find((t) => (t.type === "provisionReceipt"))?.proofImages}
        onChange={(S3Objects) => {
          updateProvisionReceiptProofImages(S3Objects);
        }}
        hideBrowseButton={true}
        hideLargePreview={true}
      />

    </Fragment>
    
  );

}

const ProvisionFeedbackPicturesInput = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProvisionFeedbackPicturesInput - ${countRef.current}`);
    return () => {
      console.log("ProvisionFeedbackPicturesInput - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);

  const updateProvisionFeedbackProofImages = (S3Objects) => {

    let updatedHistory = transactionDetails?.history || [];
    if (!updatedHistory.find((t) => (t.type === "feedback"))) {
      updatedHistory = [
        ...transactionDetails?.history || [],
        {
          type: "feedback",
          proofImages: [],
        }
      ]
    }
    const provisionFeedback = updatedHistory.find((t) => (t.type === "feedback"));
    provisionFeedback.proofImages = S3Objects;

    dispatch(updateTransactionDetails({
			history: updatedHistory,
		}));
  }

  return (
    <Fragment>

      <Pane marginTop={12} className="noselect" >
        <Strong htmlFor="pictures" marginLeft={2} fontSize={14} color="#425A70" >{I18n.get('Pictures')}</Strong>
      </Pane>

      {transactionDetails?.history?.find((t) => (t.type === "feedback"))?.proofImages?.length > 0 ? null :
        <Pane className="noselect" >
          <Text marginLeft={2} fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Add pictures illustrating the impact of what you are posting about.")}</Text>
        </Pane>
      }

      <FeedbackPicturesInput
        disabled={!transactionDetails.amount || transactionDetails.localIsProcessing}
        values={transactionDetails?.history?.find((t) => (t.type === "feedback"))?.proofImages}
        onChange={(S3Objects) => {
          // console.log("S3Objects", S3Objects);
          updateProvisionFeedbackProofImages(S3Objects);
        }}
        // hideBrowseButton={true}
        browseButtonText={I18n.get('Add pictures')}
        hideLargePreview={true}
      />

    </Fragment>
    
  );

}

const ProvisionCaption = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProvisionCaption - ${countRef.current}`);
    return () => {
      console.log("ProvisionCaption - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);
  
  const [captionInvalidMessage, setCaptionInvalidMessage] = useState();
  
  useEffect (() => {
    if (transactionDetails.caption) {
      let temp = transactionDetails.caption.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
      if (temp.length > 2200) {
        setCaptionInvalidMessage(I18n.get('Caption is too long.'));
      }
      else {
        setCaptionInvalidMessage();
      }
    }
  }, [transactionDetails.caption]);

  return(
    <Fragment >

      <Pane marginTop={12} className="noselect" >
        <Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#425A70" >{I18n.get('Caption')}</Strong>
      </Pane>

      <Pane marginTop={4} >
        <textarea
          // should not expand ?
          className="input-standard"
          style={{
            "resize": "vertical",
            "display": "block",
            "width": "100%",
            "height": "80px",
            "minHeight": "80px",
            "maxHeight": "160px",
          }}
          disabled={transactionDetails.localIsProcessing}
          placeholder={`${I18n.get('Write a caption')}...`}
          value={transactionDetails.caption?.replace(/<br\/>/g,'\r\n') || ""}
          onChange={(e) => {
            dispatch(updateTransactionDetails({
              caption: e.target.value.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
            }));
          }}
          // onBlur={(e) => {}}
        />
        {captionInvalidMessage ?
          <Pane padding={2} className="noselect" >
            <Text fontSize={12} fontStyle="italic" color="#EC4C47">{captionInvalidMessage}</Text>
          </Pane>
        :
          <Pane padding={2} className="noselect" >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This caption will be visible to others.")}</Text>
          </Pane>
        }

      </Pane>

    </Fragment>
  );
}

const ProvisionImpactInput = ({ onValidation = (isValid) => {} }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`ProvisionImpactInput - ${countRef.current}`);
    return () => {
      console.log("ProvisionImpactInput - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const localeState = useSelector(state => state.localeState);
  // const userState = useSelector(state => state.userState);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!transactionDetails?.history?.find((t) => (t.type === "feedback"))) {
      let updatedHistory = transactionDetails?.history || [];
      updatedHistory = [
        ...transactionDetails?.history || [],
        {
          type: "feedback",
          impact: newFeedbackImpactBreakdown(),
        }
      ];
      dispatch(updateTransactionDetails({
        history: updatedHistory,
      }));
    }
  }, [dispatch, transactionDetails?.history]);

  const handleUpdateProvisionFeedbackImpactBreakdown = (breakdown, updatesToBreakdown) => {

    let updatedHistory = transactionDetails?.history || [];
    const currentFeedbackRecord = updatedHistory?.find(record => record.type === "feedback");
    
    let updatedImpactBreakdowns = currentFeedbackRecord?.impact || [];
    const impactBreakdownIndex = _.findIndex(updatedImpactBreakdowns, breakdown);
    updatedImpactBreakdowns.splice(impactBreakdownIndex, impactBreakdownIndex !== null ? 1 : 0, {
      ...breakdown,
      ...updatesToBreakdown,
    });

    dispatch(updateTransactionDetails({
			history: updatedHistory,
		}));
  };

  return(
    <Fragment >

      <Pane marginTop={12} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"></Pane>
                
      <FeedbackImpactForm
        feedbackRecord={transactionDetails?.history?.find((transaction) => (transaction.type === "feedback"))}
        leadingHeading={I18n.get('Meaningful impact on')}
        disabled={processing}
        onChange={(breakdown, changedKeyValues) => {
          handleUpdateProvisionFeedbackImpactBreakdown(breakdown, {
            ...changedKeyValues,
          });
        }}
        onValidation={(isValid) => {
          onValidation(isValid);
        }}
      />

    </Fragment>
  );
}

const TransactionInternalNote = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionInternalNote - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("TransactionInternalNote - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

	const dispatch = useDispatch();

	// const userState = useSelector(state => state.userState);
	// const profileDetails = useSelector(state => state.profileDetails);
	const transactionDetails = useSelector(state => state.transactionDetails);
	// const localeState = useSelector(state => state.localeState);

  return (
    <Fragment>
      <Pane marginTop={16} className="noselect" >
        <Strong htmlFor="caption" marginLeft={2} fontSize={14} color="#425A70" >{I18n.get('Internal note')}</Strong>
        <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{` (${I18n.get('optional')})`}</Text>
      </Pane>

      <Pane marginTop={4} >
        <textarea
          className="input-standard"
          style={{
            "resize": "vertical",
            "display": "block",
            "width": "100%",
            "height": "60px",
            "minHeight": "60px",
            "maxHeight": "160px",
          }}
          disabled={transactionDetails.localIsProcessing}
          placeholder={`${I18n.get('Note')}...`}
          value={transactionDetails.note?.replace(/<br\/>/g,'\r\n') || ""}
          onChange={(e) => {
            let temp = e.target.value?.replace(/<br\/>/g, ' ').trim(); // cover tabs, newlines, etc
            if (temp?.length > 2200) {
              // dispatch(updateTransactionDetails({
              // 	captionLocalErrorMessage: I18n.get('Too long.'),
              // }));
            }
            else {
              // captionLocalErrorMessage: undefined,
              dispatch(updateTransactionDetails({
                note: e.target.value?.replace(/\r\n/g,'<br/>').replace(/\n/g,'<br/>').replace(/\s/g,' '),
              }));
            }
          }}
          onBlur={(e) => {
            dispatch(updateTransactionDetails({
              note: transactionDetails.note?.trim() || undefined,
            }));
          }}
        />
        {/* {(transactionDetails.captionLocalErrorMessage) ?
          <Pane marginLeft="auto" marginRight="auto" marginBottom={-15} paddingY={4} >
            <Text size={400} color="#EC4C47">{transactionDetails.captionLocalErrorMessage}</Text>
          </Pane>
        :
          <Pane padding={2} className="noselect" >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This note will not be visible to others.")}</Text>
          </Pane>
        } */}
        <Pane padding={2} className="noselect" >
          <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This note will not be visible to others")}</Text>
        </Pane>
      </Pane>
      
    </Fragment>
  );
}

const NewProvisionView = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`NewProvisionView - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("NewProvisionView - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

  // const userState = useSelector(state => state.userState);
	const transactionDetails = useSelector(state => state.transactionDetails);
	// const localeState = useSelector(state => state.localeState);

  const [isImpactInputValid, setIsImpactInputValid] = useState(null);

  return (
    
    <Fragment >

      <ProvisionAmountInput disabled={false} />

      {transactionDetails.mode !== "pos" ?
        <Fragment>
          <ProvisionCaption />
          <ProvisionFeedbackPicturesInput />
          <ProvisionImpactInput
            onValidation={(isValid) => {
              // this needs improvements ???
              // console.log("isValid", isValid);
              setIsImpactInputValid(isValid);
            }}

          />
        </Fragment>
      : null }

      {transactionDetails.mode === "pos" ?
        <Fragment>
          <ProvisionReceiptPicturesInput disabled={false} />
          <TransactionInternalNote disabled={false} />
        </Fragment>
      : null }

      <ProvisionFundingOptions disabled={!isImpactInputValid || !transactionDetails.amount} />

    </Fragment>
    
  );
  
}

const NewTransaction = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`NewTransaction - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("NewTransaction - cleaned up");
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

	const dispatch = useDispatch();

	const userState = useSelector(state => state.userState);
	const transactionDetails = useSelector(state => state.transactionDetails);
	const localeState = useSelector(state => state.localeState);


  // redirect to own page when no mode is provided
	useEffect(() => {
		if (!match.params.mode && !transactionDetails?.type) {
      console.log("redirect to when no mode is provided");
			history.replace({
				pathname: userState.user?.username ? generatePath("/:handle", {handle: userState.user?.username}) : generatePath(match.path, {...match.params, mode: "donation"}),
        search: "",
			});
		}
	}, [history, match.params, match.path, transactionDetails?.type, userState.user?.username]);

  // redirect to donation when posting is no allowed
  useEffect(() => {
    if (match.params.mode === "post" && (!userState.user?.rights?.provideExternally && !userState.user?.rights?.provideInternally)) {

      history.replace({
        pathname: userState.user?.username ?
          generatePath("/:handle", {handle: userState.user?.username})
        :
          generatePath(match.path, {...match.params, mode: "donation"}),
        search: "",
      });
    }
	}, [history, match.params, match.path, userState.user?.rights?.provideExternally, userState.user?.rights?.provideInternally, userState.user?.username]);

  useEffect(() => {
    if (queryParams.get('mode') === "pos" && (!userState.user?.rights?.provideExternally || !userState.user?.tags?.includes("retailer"))) {

      history.replace({
        pathname: userState.user?.username ?
          generatePath("/:handle", {handle: userState.user?.username})
        :
          generatePath(match.path, {...match.params, mode: "donation"}),
        search: "",
      });
    }
	}, [history, match.params, match.path, queryParams, userState.user?.rights?.provideExternally, userState.user?.tags, userState.user?.username]);

  useEffect(() => {
    if (queryParams.get('mode') === "allocation" && !userState.user?.rights?.makeAllocations) {

      history.replace({
        pathname: userState.user?.username ?
          generatePath("/:handle", {handle: userState.user?.username})
        :
          generatePath(match.path, {...match.params, mode: "donation"}),
        search: "",
      });
    }
	}, [history, match.params, match.path, queryParams, userState.user?.rights?.makeAllocations, userState.user?.username]);


  
  const transactionDetailsRef = useRef(transactionDetails);
  const isShapedFromQueryParamsRef = useRef(false);

  useEffect(() => {
    transactionDetailsRef.current = transactionDetails;
  }, [transactionDetails]);
  
  // shape transactionDetails by queryParams
	useEffect(() => {
    if (!isShapedFromQueryParamsRef.current) {
      let updatedTransactionDetails = {};

      if (!transactionDetailsRef.current?.type) {
        if (match.params.mode === "post") {
          updatedTransactionDetails.type = "provision";
        }
        else {
          updatedTransactionDetails.type = match.params.mode;
        }
      }

      if (!transactionDetailsRef.current?.mode) {
        updatedTransactionDetails.mode = queryParams.get('mode');
      }

      const isUserOwnPage = (transactionDetailsRef.current.toPublicFaceId === userState.user?.publicFaceId);

      if (!transactionDetailsRef.current?.localUserOwnPage || isUserOwnPage !== transactionDetailsRef.current.localUserOwnPage) {
        updatedTransactionDetails.localUserOwnPage = isUserOwnPage;
      }

      if (!transactionDetailsRef.current?.fromPublicFaceId) {
        updatedTransactionDetails.fromPublicFaceId = queryParams.get('from') || userState.user?.publicFaceId;
      }

      if (!transactionDetailsRef.current?.toPublicFaceId && queryParams.get('to')) {
        updatedTransactionDetails.toPublicFaceId = queryParams.get('to');
      }

      if (!transactionDetailsRef.current?.viaPublicFaceId && queryParams.get('via')) {
        updatedTransactionDetails.viaPublicFaceId = queryParams.get('via');
      }

      if (!transactionDetailsRef.current?.locale) {
        updatedTransactionDetails.locale = queryParams.get('locale') || localeState.locale;
      }

      if (!transactionDetailsRef.current?.currency || transactionDetailsRef.current?.currency !== localeState.currency) {
        updatedTransactionDetails.currency = queryParams.get('currency') || localeState.currency;
      }

      if (!transactionDetailsRef.current?.amount && transactionDetailsRef.current?.type === "donation") {

        let defaultTransactionAmount = null;
        
        let currentParamsAmount = queryParams.get('amount') && !isNaN(Number(queryParams.get('amount'))) ? Number(queryParams.get('amount')) : null;
        
        if (match.params.mode === "donation" || transactionDetailsRef.current?.type === "donation") {
          // should match.params.mode go ???
          defaultTransactionAmount = currentParamsAmount || currencies[localeState.currency].initialValue;
        }
        else if ((queryParams.get('mode') !== "allocation" && transactionDetailsRef.current?.mode !== "allocation")) { // ???
          defaultTransactionAmount = currentParamsAmount;
        }
        else {
          defaultTransactionAmount = currentParamsAmount;
        }

        console.log("defaultTransactionAmount", defaultTransactionAmount);
        
        if (defaultTransactionAmount) {
          updatedTransactionDetails.amount = defaultTransactionAmount;
          queryParams.set('amount', defaultTransactionAmount);
        }

      }
      else {
        // console.log("queryParams.get('amount')", queryParams.get('amount'));
        let currentParamsAmount = Number(queryParams.get('amount')) ? Number(queryParams.get('amount')) : null;
        // console.log("currentParamsAmount", currentParamsAmount);
        updatedTransactionDetails.amount = currentParamsAmount;
      }

      if (!transactionDetailsRef.current?.localFormStep) {
        updatedTransactionDetails.localFormStep = transactionDetailsRef.current?.localUserOwnPage || transactionDetailsRef.current?.mode === "allocation" || queryParams.get('mode') === "allocation" ? 3 : 0;
      }

      if (!transactionDetailsRef.current?.paymentMethod && (transactionDetailsRef.current?.mode === "allocation" || queryParams.get('mode') === "allocation") ) {
        updatedTransactionDetails.paymentMethod = "balance";
      }

      isShapedFromQueryParamsRef.current = true;

      console.log("Shape transactionDetails from queryParams", updatedTransactionDetails);
      dispatch(updateTransactionDetails({
        ...updatedTransactionDetails,
      }));
    }
		
		
	}, [dispatch, localeState.currency, localeState.locale, match.params.mode, queryParams, userState.user?.publicFaceId]);


  // shape queryParams by transactionDetails
  useEffect(() => {
    
    // ??? do not set from as the URL may be shared
    // if (transactionDetails?.fromPublicFaceId) {
    //   queryParams.set("from", transactionDetails?.fromPublicFaceId);
    // }

    if (transactionDetails?.viaPublicFaceId) {
      queryParams.set("via", transactionDetails?.viaPublicFaceId);
    }

    if (transactionDetails?.toPublicFaceId) {
      queryParams.set("to", transactionDetails?.toPublicFaceId);
    }
    
    if (transactionDetails?.amount) {
      queryParams.set('amount', transactionDetails?.amount);
    }

    if (transactionDetails?.currency) {
      queryParams.set("currency", transactionDetails?.currency);
    }

    history.replace({
      search: `?${queryParams.toString()}`,
    });

	}, [history, queryParams, transactionDetails?.amount, transactionDetails?.currency, transactionDetails?.mode, transactionDetails?.toPublicFaceId, transactionDetails?.viaPublicFaceId, userState.user?.rights?.makeAllocations, userState.user?.rights?.provideExternally, userState.user?.tags]);


	const newTransactionTitle = useMemo(() => {

    if (transactionDetails?.type === "donation") {
      if (transactionDetails?.mode === "allocation") {
        return I18n.get("New allocation");
      }
      else {
        return I18n.get("New donation");
      }
    }
    else {
      if (transactionDetails.mode === "pos") {
        return I18n.get("New transaction");
      }
      else {
        return I18n.get("New post");
      }
    }
    
	}, [transactionDetails.mode, transactionDetails?.type]);


  return (
    <Fragment>
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto"
        paddingBottom={20}
        // paddingBottom={150}
      >

        {process.env.NODE_ENV !== "production" ?
          <Fragment>
            <Pane >
              Only visible in dev
            </Pane>
            <Pane >
              {location.search}
            </Pane>
            <Pane >
              type: {transactionDetails?.type}
            </Pane>
            <Pane >
              mode: {transactionDetails?.mode}
            </Pane>
            <Pane >
              user: {userState?.user?.publicFaceId}
            </Pane>
            <Pane >
              amount: {transactionDetails?.amount}
            </Pane>
          </Fragment>
        : null }

        <Pane marginTop={32} maxWidth={590} marginLeft="auto" marginRight="auto" >

          {false ?
            <Fragment>
              <Pane >
                Return somewhere ???
              </Pane>
            </Fragment>
          : null }

          {!transactionDetails?.type ? null :
            <Pane textAlign="center" flex={1} width="100%" height="auto" className="noselect" >
              <Strong display="contents" flex="none" fontSize={24} color="#283655">{newTransactionTitle}</Strong>
            </Pane>
          }

          {/* <DialogHeader
            headerText={}
            // leftButtonDisabled={processing}
            onLeftButtonClick={() => {
              // handle "Are you sure?"
              // dispatch(setTransactionDetails());
              // if (typeof onClose === "function") {
              //   onClose();;
              // }
              
            }}
            hideLeftButton={true}
            hideRightButton={true}
          /> */}

          {transactionDetails?.type !== "donation" ? null : <NewDonationView /> }

          {transactionDetails?.type !== "provision" ? null : <NewProvisionView /> }

        </Pane>
        

      </Pane>

      <Footer isVisible={false}/>

    </Fragment>
  );
}

export default NewTransaction;