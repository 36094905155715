import { useEffect, useLayoutEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Strong, Text, Paragraph, Button, Pane, Icon, SideSheet, Card, Position, CircleArrowLeftIcon } from 'evergreen-ui';

import mapboxgl from 'mapbox-gl';
import DeckGL from '@deck.gl/react';
import { MapboxOverlay, MapboxLayer } from '@deck.gl/mapbox';
import { CompositeLayer, LineLayer, IconLayer, PolygonLayer, ScatterplotLayer, GeoJsonLayer } from '@deck.gl/layers';
import { Map, useControl, MapProvider, Popup, Source, Layer, Marker, NavigationControl, FullscreenControl, ScaleControl, GeolocateControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import DialogHeader from '../components/DialogHeader';

import { MapView, FirstPersonView } from '@deck.gl/core';

import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import Footer from '../components/Footer';


import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';


const MAPBOX_ACCESS_TOKEN = process.env.NODE_ENV === "production" ? 
  'pk.eyJ1IjoiZmFjZWRvbmF0ZS1vcmciLCJhIjoiY2xrMXIyZXM3MDJsaDNlcGtqeWYwbnRkeiJ9.V16AhyPc5dxGna-su7kuAw'
  : 'pk.eyJ1IjoiZmFjZWRvbmF0ZS1vcmciLCJhIjoiY2xrMXIwY2dsMDVoNDNxb2Z2dzVmaXU4ZyJ9.6Y3qSg8CHRuJq55m5E6hbA';

// const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiZmFjZWRvbmF0ZS1vcmciLCJhIjoiY2xrMXIyZXM3MDJsaDNlcGtqeWYwbnRkeiJ9.V16AhyPc5dxGna-su7kuAw';

// mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// https://docs.mapbox.com/help/glossary/style-url/
// https://docs.mapbox.com/mapbox-gl-js/api/map/
// "mapbox://styles/mapbox/streets-v12?optimize=true"
const MAPBOX_MAP_STYLE = 'mapbox://styles/facedonate-org/clk1s2vkn00dq01pfh2tegit8';


const foodbanksGeoJsonData = [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-0.075925, 51.532315]
    },
    "properties": {
      name: "Hackney Foodbank: Monday Hub",
      pictureUrl: "",
      publicFaceId: "wmuewd",
      address: "384 Geffrye St, London E2 8ER, UK",
      tags: ["food bank"],
      latitude: 51.532315,
      longitude: -0.075925,
      google_DirUrl: "https://goo.gl/maps/SNzBQNxccenoUq8U9",
      google_place_id: "",
      "opening_time": [
        {
          "day": "monday",
          "foodbank_status": "open",
          "opening_time": "11:00",
          "closing_time": "13:00"
        }
      ],
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-0.072619, 51.5876183]
    },
    "properties": {
      name: "Tottenham Foodbank",
      pictureUrl: "",
      publicFaceId: "ywcrdr",
      address: "Tottenham Town Hall, Town Hall Approach Rd, London N15 4RX",
      opening_time_text: "Mon: 12pm - 2pm<br/>Thu: 5pm - 7pm",
      tags: ["food bank"],
      latitude: 51.532315,
      longitude: -0.075925,
      google_DirUrl: "https://goo.gl/maps/qsqiXDeKoTZateLR7",
      google_place_id: "ChIJ-YqyK-QddkgR0vLBVXlT8Nk",
      // "opening_time": [
      //   {
      //     "day": "monday",
      //     "foodbank_status": "open",
      //     "opening_time": "11:00",
      //     "closing_time": "13:00"
      //   }
      // ],
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-0.013366189924738365, 51.52715703571206], 
    },
    "properties": {
      name: "Bow Foodbank",
      pictureUrl: "",
      publicFaceId: "wtyecr",
      address: "St Leonard's St, London E3",
      opening_time_text: "Mon: 8am - 12.30pm",
      tags: ["food bank"],
      latitude: 51.52715703571206,
      longitude: -0.013366189924738365,
      google_DirUrl: "https://goo.gl/maps/2MrsJJCJNb5K7pj97",
      google_place_id: "",
      // "opening_time": [
      //   {
      //     "day": "monday",
      //     "foodbank_status": "open",
      //     "opening_time": "11:00",
      //     "closing_time": "13:00"
      //   }
      // ],
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-0.0500188375411988, 51.53165604015858], 
    },
    "properties": {
      name: "Bethnal Green Food Bank",
      pictureUrl: "",
      publicFaceId: "wtyecr",
      address: "Raines Foundation School, Approach Rd, Bethnal Green, London E2 9LY",
      opening_time_text: "Wed: 2pm - 7pm",
      tags: ["food bank"],
      latitude: 51.53165604015858,
      longitude: -0.0500188375411988,
      google_DirUrl: "https://goo.gl/maps/v189kdij4KB1LBy89",
      google_place_id: "ChIJmWoZSqwddkgRqUIYF5ucxVY",
      // "opening_time": [
      //   {
      //     "day": "monday",
      //     "foodbank_status": "open",
      //     "opening_time": "11:00",
      //     "closing_time": "13:00"
      //   }
      // ],
    }
  },
];

const Pin = ({
  size = 36,
  fill = '#008751' // '#47B881', // '#d00',
}) => {

  return (
    <svg
      height={32} width={32}
      // fillRule="evenodd"
      // clipRule="evenodd"
      // strokeLinejoin="round"
      // strokeMiterlimit={2}
      // width="1em"
      // height="1em"
      style={{
        display: 'block',
        cursor: 'pointer',
        fill: fill, 
        stroke: 'none'
      }}
    >
      <path fillRule="nonzero" d={"M16 3C10.5 3 6 7.5 6 13c0 8.4 9 15.5 9.4 15.8.2.1.4.2.6.2s.4-.1.6-.2C17 28.5 26 21.4 26 13c0-5.5-4.5-10-10-10zm0 14c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"} />
    </svg>
  );
}

const DeckGLReact = () => {
  
  const [popupInfo, setPopupInfo] = useState();

  // Viewport settings
  const initialViewState = {
    latitude: 51.5333312,
    longitude: -0.083333,
    zoom: 12,
    // pitch: 45,
    // bearing: 0,
  };

  // https://visgl.github.io/react-map-gl/examples/interaction
  const [mapSettings, setMapSettings] = useState({
    // scrollZoom: true,
    // boxZoom: true,
    // dragRotate: true,
    // dragPan: true,
    // keyboard: true,
    // doubleClickZoom: true,
    // touchZoomRotate: true,
    // touchPitch: true,
    minZoom: 11,
    // maxZoom: 20,
    // minPitch: 0,
    // maxPitch: 85
  });


  const retailersGeoJsonLayerData = [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.080386, 51.533755]
      },
      "properties": {
        name: "Hoxton Brothers Food",
        pictureUrl: "",
        publicFaceId: "erkprx",
        address: "247-249 Hoxton St, London N1 5LG",
        opening_time_text: "Mon - Sun: 7am - 11pm",
        tags: ["retailer"],
        latitude: 51.533755,
        longitude: -0.080386,
        google_DirUrl: "https://goo.gl/maps/HFuA5Wg5cmzsb5Ky5",
        google_place_id: "ChIJS6TaHb0cdkgRqitEKbnYt88",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0912342, 51.5311249]
      },
      "properties": {
        name: "Nisa Local",
        pictureUrl: "",
        publicFaceId: "ptkdym",
        address: "95 Murray Grove, London N1 7QP",
        opening_time_text: "Mon - Sun: 9am - 7pm",
        tags: ["retailer"],
        latitude: 51.5311249,
        longitude: -0.0912342,
        google_DirUrl: "https://goo.gl/maps/qS8A6v3ptf51S7Gv6",
        google_place_id: "ChIJUwGJTqEcdkgR0eGk3kkifQU",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.071878, 51.5442274]
      },
      "properties": {
        name: "Prestige Supermarket",
        pictureUrl: "",
        publicFaceId: "htertt",
        address: "350-352 Queensbridge Rd, London E8 3AR",
        opening_time_text: "Mon - Thu: 7am - 11pm<br/>Fri - Sat: 7am - 12am<br/>Sun: 7am - 11pm",
        tags: ["retailer"],
        latitude: 51.5442274,
        longitude: -0.071878,
        google_DirUrl: "https://goo.gl/maps/1fS8VG7ysyPdsKY27",
        google_place_id: "ChIJQVIKJu0cdkgRL1j_H5jk3fM",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0829656, 51.5459662]
      },
      "properties": {
        name: "N1 Food Centre",
        pictureUrl: "",
        publicFaceId: "tkwypp",
        address: "49 Balls Pond Rd, London N1 4BW",
        opening_time_text: "Mon - Fri: 6am - 2am<br/>Sat - Sun: 8am - 2am",
        notes_text: "",
        tags: ["retailer"],
        latitude: 51.5459662,
        longitude: -0.0829656,
        google_DirUrl: "https://goo.gl/maps/1VefzyHgphGnowxv6",
        google_place_id: "ChIJY6R03JEcdkgRpkSdlxHP9UY",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0741244, 51.5482581]
      },
      "properties": {
        name: "Fruit & Veg Stall",
        pictureUrl: "",
        publicFaceId: "muyuhr",
        address: "20 Ridley Rd, London E8 2NR",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am", // ???
        tags: ["retailer"],
        latitude: 51.5482581,
        longitude: -0.0741244,
        google_DirUrl: "https://goo.gl/maps/LKALCMUMJ7yhmySP9",
        google_place_id: "",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.07404978595430703, 51.5481682328402], 
      },
      "properties": {
        name: "Dalston Butchers",
        pictureUrl: "",
        publicFaceId: "xewmrc",
        address: "20-24 Ridley Rd, London E8 2LX",
        opening_time_text: "Mon - Sat: 10.30am - 6pm<br/>Sun: closed",
        tags: ["retailer"],
        latitude: 51.5481682328402,
        longitude: -0.07404978595430703,
        google_DirUrl: "https://goo.gl/maps/KW56pjUGChxAu4Rn9",
        google_place_id: "Dalston Butchers",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.07680241366863914, 51.53467399987828], 
      },
      "properties": {
        name: "Today's Extra",
        pictureUrl: "",
        publicFaceId: "tuuprx",
        address: "194-196 Kingsland Rd, London E2 8EB",
        opening_time_text: "Mon - Thu: 7am - 12am<br/>Fri - Sat: 7am - 1am<br/>Sun: 7am - 12am",
        tags: ["retailer"],
        latitude: 51.53467399987828,
        longitude: -0.07680241366863914,
        google_DirUrl: "https://goo.gl/maps/hwhfGqqVNttXsCEg7",
        google_place_id: "ChIJBWLu2b0cdkgRarrfjV6olSA",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0282755, 51.5337535]
      },
      "properties": {
        name: "Inci Food Centre",
        pictureUrl: "",
        publicFaceId: "kyhwcw",
        address: "599 Roman Rd, Old Ford, London E3 2RN",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: 51.5337535,
        longitude: -0.0282755,
        google_DirUrl: "https://goo.gl/maps/cuMaoVn9doZoQGW89",
        google_place_id: "ChIJ17H6Yz4ddkgRHlI4U_XAPwA",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.04604689365412506, 51.52947554371998], 
      },
      "properties": {
        name: "SimplyFresh (Bethnal Green)",
        pictureUrl: "",
        publicFaceId: "tphtxc",
        address: "201-203 Roman Rd, Globe Town, London E2 0QY",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: 51.52947554371998,
        longitude: -0.04604689365412506,
        google_DirUrl: "https://goo.gl/maps/kAebmGNS76p1UyxXA",
        google_place_id: "ChIJb4R5QicddkgR_cBBQnw72R4",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0469669736346715, 51.52904525755261], 
      },
      "properties": {
        name: "FreshGo",
        pictureUrl: "",
        publicFaceId: "drykmd",
        address: "132 Roman Rd, Globe Town, London E2 0RN",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: 51.52904525755261,
        longitude: -0.0469669736346715,
        google_DirUrl: "https://goo.gl/maps/6vEu9LvS3NYdx1tWA",
        google_place_id: "ChIJ12W9ndgcdkgRPeb0mBNFJsA",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.04849722415145847, 51.52865604526816], 
      },
      "properties": {
        name: "Anondo Bazar",
        pictureUrl: "",
        publicFaceId: "ytphkp",
        address: "98 Roman Rd, Globe Town, London E2 0PG",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: 51.52865604526816,
        longitude: -0.04849722415145847,
        google_DirUrl: "https://goo.gl/maps/A33JpMg4yXywAoG66",
        google_place_id: "ChIJqXNOpjoddkgRP5pljM7GUCo",
      }
    },
    // {
    //   "type": "Feature",
    //   "geometry": {
    //     "type": "Point",
    //     "coordinates": [, ]
    //   },
    //   "properties": {
    //     name: "",
    //     pictureUrl: "",
    //     publicFaceId: "",
    //     address: "",
    //     tags: ["retailer"],
    //     latitude: 0,
    //     longitude: 0,
    //     google_DirUrl: "",
    //     google_place_id: "",
    //   }
    // },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.106211, 51.5315608]
      },
      "properties": {
        name: "4You Express",
        pictureUrl: "",
        publicFaceId: "whuded",
        address: "424 St John St, London EC1V 4NJ",
        // opening_time_text: "Mon - Sat: 7am - 1am <br/> Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: -0.106211,
        longitude: 51.5315608,
        google_DirUrl: "https://goo.gl/maps/SG3wAiPm6fLpcoii8",
        google_place_id: "",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0804485, 51.588867]
      },
      "properties": {
        name: "Expa Supermarkets",
        pictureUrl: "",
        publicFaceId: "mpkdew",
        address: "143-145 Philip Ln, London N15 4HQ",
        opening_time_text: "Mon - Sun: 6.30am - 11pm",
        tags: ["retailer"],
        latitude: 51.588867,
        longitude: -0.0804485,
        google_DirUrl: "https://goo.gl/maps/m5tpMh6tn1vNvgt96",
        google_place_id: "ChIJH5WyBxAcdkgRNGBVIG7NxcQ",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.07281505533744931, 51.577384652155345], 
      },
      "properties": {
        name: "Seven Brothers London",
        pictureUrl:
          "http://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Above_Gotham.jpg/240px-Above_Gotham.jpg",
        publicFaceId: "ypydep",
        status: "pending",
        address: "72 High Rd, London N15 6JU",
        opening_time_text: "Mon - Sun: 6am - 12am",
        tags: ["retailer"],
        latitude: 51.577384652155345,
        longitude: -0.07281505533744931,
        google_DirUrl: "https://goo.gl/maps/MiUYeQG47VJpnSdZ8",
        google_place_id: "ChIJQa3Z0WocdkgRY3QrAs7xT3c",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.0697405509663178, 51.59172586012999], 
      },
      "properties": {
        name: "Enfield Food Centre",
        pictureUrl: "",
        publicFaceId: "",
        status: "pending",
        address: "422-424 High Rd, London N17 9JB",
        opening_time_text: "Open 24 hours",
        tags: ["retailer"],
        latitude: 51.59172586012999,
        longitude: -0.0697405509663178,
        google_DirUrl: "https://goo.gl/maps/JWdfeoajWUxhfC5GA",
        google_place_id: "ChIJ1dR1ZCIcdkgRmTjW5c4o04I",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.05726539282550728, 51.532796377133955], 
      },
      "properties": {
        name: "Naz Supermarket",
        pictureUrl: "",
        publicFaceId: "erkprx",
        address: "475 Cambridge Heath Rd, London E2 9HA",
        // opening_time_text: "Mon - Sat: 7am - 1am<br/>Sun: 8am - 1am",
        tags: ["retailer"],
        latitude: 51.532796377133955,
        longitude: -0.05726539282550728,
        google_DirUrl: "https://goo.gl/maps/G3Q765vV3V6xubLC8",
        google_place_id: "",
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [-0.07139591604983174, 51.58604466199328], 
      },
      "properties": {
        name: "Martinez Butchers",
        pictureUrl: "",
        publicFaceId: "ckthmd",
        address: "278 High Rd, London N15 4AJ",
        opening_time_text: "Mon - Sat: 8am - 8pm<br/>Sun: 10am - 2pm",
        tags: ["retailer"],
        latitude: 51.58604466199328,
        longitude: -0.07139591604983174,
        google_DirUrl: "https://goo.gl/maps/8xWQAgip9Vji7ZC4A",
        google_place_id: "ChIJOQl-KukddkgR4VfmFbbr7jA",
      }
    },
    

  ];

  const retailersCoverageLayer = new ScatterplotLayer({
    id: 'retailersCoverageLayer',
    data: retailersGeoJsonLayerData.filter((f) => (!f.properties?.status)) || [],
    pickable: true,
    opacity: 0.01,
    // stroked: true,
    filled: true,
    radiusUnits: 'meters',
    radiusScale: 1,
    radiusMinPixels: 1,
    // radiusMaxPixels: 100,
    // lineWidthMinPixels: 1,
    // lineWidthMaxPixels: 1,
    getPosition: d => d.geometry.coordinates,
    getRadius: d => 800, // Math.sqrt(d.exits), // 800m is 10-min walk
    getFillColor: d => [115, 93, 208],
    getLineColor: d => [0, 0, 0],
    // parameters: {
    //   // blendFunc: [GL.SRC_ALPHA, GL.CONSTANT_ALPHA],
    //   blendColor: [0, 0, 0, 0.5]
    // },
  });

  const geoJsonLayerDataLondonBoroughs = [
    {
      "type": "Feature",
      "id": 20,
      "properties": {
        "ons_label": "00AM",
        "name": "Hackney",
        "partic_per": 17.6,
        "pop_2001": 202825
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.093913549085223, 51.5302779659565],
            [-0.095423373319991, 51.5324730348311],
            [-0.085005714683992, 51.536747923685],
            [-0.082226845488685, 51.5460960343482],
            [-0.074803353298198, 51.5464679553208],
            [-0.077895009594859, 51.5511870222668],
            [-0.088791802442348, 51.5536439495373],
            [-0.090090936892688, 51.5597169240035],
            [-0.102897996184241, 51.5642053623635],
            [-0.094502673608761, 51.5701950324307],
            [-0.096237402510952, 51.5731049151082],
            [-0.05956863662496, 51.5772810366937],
            [-0.045485886629674, 51.5620649498554],
            [-0.026161776255566, 51.5602609772178],
            [-0.015570398419721, 51.5512009288539],
            [-0.014883425438635, 51.5427709640731],
            [-0.027470266384503, 51.5417770416581],
            [-0.031659690192017, 51.5441273020579],
            [-0.04324481989618, 51.5352990007528],
            [-0.060855345682651, 51.5350080032475],
            [-0.064698591158279, 51.5307181967771],
            [-0.073035818492444, 51.5292830858801],
            [-0.076858997485013, 51.5210001924618],
            [-0.083606588143324, 51.5198242802028],
            [-0.083408763961654, 51.5256940155018],
            [-0.093913549085223, 51.5302779659565]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": 23,
      "properties": {
        "ons_label": "00AP",
        "name": "Haringey",
        "partic_per": 20.8,
        "pop_2001": 216505
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.164577635981447, 51.5815090237128],
            [-0.155570299982949, 51.5857019475305],
            [-0.160180211867115, 51.5969770261316],
            [-0.15478913520879, 51.6048439679066],
            [-0.149772651550698, 51.5969474584434],
            [-0.142887196235574, 51.5996270819576],
            [-0.141841610151131, 51.6083469482319],
            [-0.137177939873971, 51.6096939710332],
            [-0.052761776384888, 51.6083630786885],
            [-0.039830896336334, 51.6051303419881],
            [-0.05956863662496, 51.5772810366937],
            [-0.096237402510952, 51.5731049151082],
            [-0.094502673608761, 51.5701950324307],
            [-0.102897996184241, 51.5642053623635],
            [-0.1179758607303, 51.5750290617499],
            [-0.140807795751444, 51.568617051414],
            [-0.169680865908561, 51.5719279991207],
            [-0.164577635981447, 51.5815090237128]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": 31,
      "properties": {
        "ons_label": "00BG",
        "name": "Tower Hamlets",
        "partic_per": 19.1,
        "pop_2001": 196083
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.006484105550071, 51.5233687004161],
            [-0.004065595358945, 51.5162420472213],
            [0.00551897647111, 51.5145279542328],
            [0.004004232382536, 51.510190068538],
            [0.00903936540783, 51.513229936724],
            [0.009220280431778, 51.5049970048943],
            [0.002096410358223, 51.5059400937038],
            [-0.002850915547767, 51.5023249466466],
            [0.001203892627492, 51.4908750782234],
            [-0.001334698468948, 51.4866289274063],
            [-0.011557103462329, 51.4840479946373],
            [-0.021900155726551, 51.4863069807623],
            [-0.028332139678161, 51.4930490298626],
            [-0.030588125720569, 51.5063689551272],
            [-0.041733776582447, 51.5079680462366],
            [-0.05140588281784, 51.5033033755006],
            [-0.059291015666275, 51.5012000470381],
            [-0.07858988000961, 51.5063369178676],
            [-0.071107077389324, 51.5097154052901],
            [-0.076858997485013, 51.5210001924618],
            [-0.073035818492444, 51.5292830858801],
            [-0.064698591158279, 51.5307181967771],
            [-0.060855345682651, 51.5350080032475],
            [-0.04324481989618, 51.5352990007528],
            [-0.031659690192017, 51.5441273020579],
            [-0.027470266384503, 51.5417770416581],
            [-0.014883425438635, 51.5427709640731],
            [-0.019600960809429, 51.5363519479498],
            [-0.006484105550071, 51.5233687004161]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": 11,
      "properties": {
        "ons_label": "00BE",
        "name": "Southwark",
        "partic_per": 21,
        "pop_2001": 244867
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.05140588281784, 51.5033033755006],
            [-0.041733776582447, 51.5079680462366],
            [-0.030588125720569, 51.5063689551272],
            [-0.028332139678161, 51.4930490298626],
            [-0.052401773753215, 51.4874549177822],
            [-0.050670015594225, 51.4686968907032],
            [-0.040034189898353, 51.4544720300674],
            [-0.044539140900646, 51.4493550687375],
            [-0.055949175620705, 51.4498206207877],
            [-0.060216638411899, 51.4472780506011],
            [-0.061846597082266, 51.4370199763161],
            [-0.07197690677915, 51.4308320125215],
            [-0.072335235803394, 51.4256308015179],
            [-0.076684024217856, 51.4200516526493],
            [-0.086711695188255, 51.4289239972605],
            [-0.099820306923765, 51.4514379956225],
            [-0.088470605374181, 51.4656419466936],
            [-0.091472913177961, 51.4716119311586],
            [-0.094389123911729, 51.4693650059786],
            [-0.098391317222942, 51.4763579176973],
            [-0.106747008719723, 51.4798000032205],
            [-0.102432191414438, 51.4803450426202],
            [-0.106844543118576, 51.4853349094781],
            [-0.101465292011957, 51.4910090438095],
            [-0.109835410449561, 51.4959350653815],
            [-0.104377907283287, 51.5014980426567],
            [-0.107548458429662, 51.5093150874526],
            [-0.07858988000961, 51.5063369178676],
            [-0.059291015666275, 51.5012000470381],
            [-0.05140588281784, 51.5033033755006]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": 30,
      "properties": {
        "ons_label": "00AG",
        "name": "Camden",
        "partic_per": 26.8,
        "pop_2001": 198022
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.129637094569017, 51.5511440244843],
            [-0.120948584287871, 51.5302180161424],
            [-0.114445650177929, 51.5293639612078],
            [-0.103736380012813, 51.5180330816846],
            [-0.111359709724166, 51.5177226107003],
            [-0.109944538240741, 51.5147130725962],
            [-0.12767989827189, 51.5129700726189],
            [-0.129230562212141, 51.5165909644292],
            [-0.145591360977153, 51.5245329891834],
            [-0.151128417905176, 51.5370130544316],
            [-0.163358352427722, 51.5352729893302],
            [-0.177163021871714, 51.5392779613495],
            [-0.187232486874307, 51.5340698176278],
            [-0.189883102056348, 51.5357829880361],
            [-0.211864144130465, 51.5546460378893],
            [-0.197809568409177, 51.555687946173],
            [-0.187651862966708, 51.5650109666539],
            [-0.169680865908561, 51.5719279991207],
            [-0.140807795751444, 51.568617051414],
            [-0.139330620622337, 51.5597136429961],
            [-0.129637094569017, 51.5511440244843]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "id": 32,
      "properties": {
        "ons_label": "00AA",
        "name": "City of London",
        "partic_per": 9.1,
        "pop_2001": 7181
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-0.111359709724166, 51.5177226107003],
            [-0.103736380012813, 51.5180330816846],
            [-0.095168068788508, 51.5227428928471],
            [-0.083606588143324, 51.5198242802028],
            [-0.076858997485013, 51.5210001924618],
            [-0.071107077389324, 51.5097154052901],
            [-0.07858988000961, 51.5063369178676],
            [-0.107548458429662, 51.5093150874526],
            [-0.109851618249191, 51.5092323687263],
            [-0.109944538240741, 51.5147130725962],
            [-0.111359709724166, 51.5177226107003]
          ]
        ]
      }
    }
  ];

  const geoJsonLayerLayer = new GeoJsonLayer({
    id: 'geoJsonLayerLayer',
    data: geoJsonLayerDataLondonBoroughs,
    pickable: true,
    stroked: true,
    filled: false,
    opacity: 0.4,
    extruded: false,
    pointType: 'circle',
    lineWidthScale: 20,
    lineWidthMinPixels: 1,
    // lineWidthMaxPixels: 1,
    getFillColor: d => [144, 238, 144],
    getLineColor: d => [71, 184, 129], // colorToRGBArray(d.properties.color),
    
    getPointRadius: d => 20, // Math.sqrt(d.exits), // 800m is 10-min walk
    getLineWidth: 1,
    getElevation: 0,

    radiusUnits: 'meters',
    radiusScale: 1,
    radiusMinPixels: 1,
    radiusMaxPixels: 100,
    
    
    // getPosition: d => d.coordinates,

    // _subLayerProps: {
    //   points: {
    //     type: IconLayer,
    //     iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    //     iconMapping: {
    //       marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
    //     },
    //     getIcon: d => 'marker', // d => d.sourceFeature.feature.properties.marker,
    //     getColor: [255, 200, 0],
    //     getSize: 32,
    //     updateTriggers: {
    //       getIcon: d => 'marker', // triggerValue
    //     },
    //   }
    // }

  });

  const layers = [
    geoJsonLayerLayer,
    retailersCoverageLayer,
  ];

  const pinsFoodbanks = useMemo(
    () =>
      foodbanksGeoJsonData.map((feature, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={Number(feature.geometry?.coordinates?.[0])}
          latitude={Number(feature.geometry?.coordinates?.[1])}
          anchor="bottom"
          onClick={e => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(feature);
          }}
        >
          <Pin fill={'#008751'} />
        </Marker>
      )),
    []
  );

  const pinsRetailers = useMemo(
    () =>
      retailersGeoJsonLayerData.map((feature, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={Number(feature.geometry?.coordinates?.[0])}
          latitude={Number(feature.geometry?.coordinates?.[1])}
          anchor="bottom"
          onClick={e => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(feature);
          }}
        >
          <Pin fill={feature.properties?.status === "pending" ? "#7B8B9A80" : "#735DD0"} />
        </Marker>
      )),
    []
  );
  
  // https://deck.gl/docs/developer-guide/base-maps/using-with-mapbox
  // https://deck.gl/docs/api-reference/mapbox/mapbox-overlay
  // function DeckGLOverlay({...props}) {
  //   const overlay = useControl(() => new MapboxOverlay({
  //     interleaved: props.interleaved,
  //     layers: props.layers
  //   }));
  //   overlay.setProps({...props});
  //   return null;
  // }

  function DeckGLOverlay(props) {
    const deck = useControl(() => new MapboxOverlay(props));
    deck.setProps(props);
    return null;
  }

  const mapRef = useRef();

  // https://deck.gl/docs/whats-new#mapboxoverlay
  // https://github.com/visgl/react-map-gl/blob/master/examples/deckgl-overlay/src/app.tsx
  function getTooltip(info) {
    // console.log("getTooltip info", info);
    return null;
  }
  
  const [isShown, setIsShown] = useState(false);
  
  return (
    <Fragment>

      {/* <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        preventBodyScrolling
        position={Position.RIGHT}
        isClosing={true}
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column'
        }}
        // width={"100%"}
        maxWidth={"80%"}
        style={{
          maxWidth: "100%"
        }}
      >
        {({close}) => (
          <Fragment>
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
              <Pane padding={16}>
                <Strong size={600}>Title</Strong>
              </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
              <Card
                backgroundColor="white"
                elevation={0}
                height={240}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Strong>Some content</Strong>
                <Button onClick={() => {close()}}>Close</Button>
              </Card>
            </Pane>
          </Fragment>
        )}
      </SideSheet> */}

      {/* <Button onClick={() => setIsShown(true)}>Show Basic Side Sheet</Button> */}

      <Map
        ref={mapRef}
        initialViewState={initialViewState}
        style={{
          position: "relative",
          width: "100%",
          height: "inherit",
        }}
        {...mapSettings}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={MAPBOX_MAP_STYLE}
        interactiveLayerIds={['geojson-layer', 'geoJsonLayerLayer']}
        onClick={(event) => {
          console.log("Map onCLick event:", event);
        }}
      >
      
        <DeckGLOverlay layers={layers} interleaved={true} getTooltip={getTooltip} />

        <NavigationControl position="top-right" />
        <GeolocateControl position="top-right" />
        {/* <FullscreenControl position="top-left" /> */}
        {/* <ScaleControl /> */}

        {pinsFoodbanks}

        {pinsRetailers}

        {popupInfo && (
          <Popup
            // https://visgl.github.io/react-map-gl/docs/api-reference/popup
            anchor="top"
            longitude={Number(popupInfo?.geometry?.coordinates?.[0])}
            latitude={Number(popupInfo?.geometry?.coordinates?.[1])}
            // closeButton={false}
            onClose={() => setPopupInfo()}
            maxWidth={350}
            // style={{
            //   // padding: "0px",
            // }}
          >
            <Pane paddingX={2} paddingY={2} minWidth={226} width={300} maxWidth={326} >

              {/* <DialogHeader
                // headerText={I18n.get('More actions')}
                hideLeftButton={false}
                hideRightButton={true}
                leftButtonDisabled={false}
                rightButtonDisabled={false}
                // leftButtonIcon={ChevronLeftIcon}
                onLeftButtonClick={() => {setPopupInfo()}}
                // rightButtonText={I18n.get('Next')}
                onRightButtonClick={() => {}}
              /> */}

              <Pane marginTop={8} >
                <Strong display="block" fontSize={24} minHeight={24} color="#283655" style={{"textOverflow": "ellipsis", overflow: "hidden", "whiteSpace": "nowrap"}} >
                  {popupInfo.properties?.name}
                </Strong>
              </Pane>
              <Pane>
                <Paragraph marginTop={4} fontSize={"1rem"} color="#283655">
                  {popupInfo.properties?.address}
                </Paragraph>
                <Paragraph whiteSpace={"break-spaces"} marginTop={4} fontSize={"1rem"} color="#283655">
                  {"⏱  "}{popupInfo.properties?.opening_time_text?.replace(/<br\/>/g,'\r\n')}
                </Paragraph>
                {popupInfo.properties?.notes_text ?
                  <Paragraph marginTop={4} fontSize={"1rem"} color="#283655">
                    {popupInfo.properties?.notes_text?.replace(/<br\/>/g,'\r\n')}
                  </Paragraph>
                : null }

              </Pane>
              {/* <img width="100%" src={popupInfo.image} /> */}

              {popupInfo.properties?.google_DirUrl  ? 
                <Button width={"100%"} flex={1} marginTop={12} fontSize={16} lineHeight={1.1} paddingX={8} height={48} textAlign="center" justifyContent="center" marginLeft="auto" marginRight="auto" className='button-blue' 
                  disabled={false}
                  is={Link}
                  target="_blank"
                  
                  to={() => ({
                      // pathname: generatePath("/:page/:mode?", {
                      //   page: "new",
                      //   mode: "donation"
                      // }),
                      pathname: popupInfo.properties?.google_DirUrl
                      // search: ``,
                      // state: {},
                    })
                  }
                  onClick={(event) => {
                    // event.originalEvent.stopPropagation();
                    setPopupInfo();
                  }}
                >
                  {I18n.get('Directions')}
                </Button>
              : null}

              {/* <Button marginTop={12}
                onClick={() => {setPopupInfo()}}
              >
                Close
              </Button> */}

            </Pane>
          </Popup>
        )}

      </Map>
    

    </Fragment>
    
  );

}



const MapPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("MapPage", countRef.current)
    return () => {
      console.log("MapPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  },[location.search]);

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const browserInfo = useSelector(state => state.localeState.browserInfo);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);
  
  const [processing, setProcessing] = useState(false);

  const [pageTitle, setPageTitle] = useState("Map");

  
  return (
    <Fragment >
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto">

        {/* <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Map Page"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane> */}

        <Pane width="100%" marginTop={12}>

          <Pane maxWidth={630} marginLeft="auto" marginRight="auto" >

            <Pane width="100%" marginTop={12} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
            
              <Pane flex={1} marginTop={10} alignItems="center" display="flex" flexWrap="wrap"  >
                <Pane flex={"none"} paddingRight={10} >
                  <Button
                    disabled={false}
                    height={"auto"}
                    padding={3}
                    borderRadius={"50%"}
                    // appearance="minimal"
                    onClick={() => {
                      if (queryParams.get('camera')) {
                        queryParams.delete('camera');
                        history.push({
                          search: `?${queryParams.toString()}`,
                        });
                      }
                      else {
                        if (userDetails) {
                          history.push(generatePath("/:handle", {...match.params, handle: userDetails?.username}));
                        }
                        else {
                          history.push(generatePath("/", {...match.params}));
                        }
                      }

                    }}
                  >
                    <Icon icon={CircleArrowLeftIcon} size={28} color="#425A70" />
                  </Button>
                  
                </Pane>
                <Pane flex={1} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />
              </Pane>

              <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{pageTitle}</Strong>

              <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />

            </Pane>

            {queryParams.get('camera') === "auth" ? 
              <Fragment>
                <Pane marginTop={10} textAlign="center" >
                  <Strong fontSize={16} color="#283655">{I18n.get('Take a picture of your face to proceed.')}</Strong>
                </Pane>
                <Pane textAlign="center" marginTop={4} >
                  <Text fontSize={14} color={"#7B8B9A"} >{I18n.get('The process is 100% save and secure.')}</Text>
                </Pane>
              </Fragment>
            : null }

            

          </Pane>
          

        </Pane>

      </Pane>
       
      <Pane marginTop={12} position={"relative"} width="100%" height={"calc(100vh - 78px - 56px - 12px)"} background="#E4E7EB" >
        <DeckGLReact />
      </Pane>

      {/* <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Footer/>

      </Pane> */}
      
    </Fragment>
  );
}

export default MapPage;