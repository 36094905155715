import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import { Link, useRouteMatch, useLocation} from 'react-router-dom';

import { TextInput, IconButton, Heading, Icon, LinkIcon, Tooltip, InfoSignIcon, Text, Strong, OrderedList, ListItem, TextInputField, Paragraph, Button, Checkbox, Pane} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import { I18n } from 'aws-amplify';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const galleryOfSupportResponsive = {
  0: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
};

const GalleryOfBeneficiariesTestimonials = () => {

  const galleryOfSupportItems = [
    <Pane width="fit-content" paddingX={10} marginLeft="auto" marginRight="auto" className="item" >
      <Paragraph fontStyle="italic" lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get(`"It's game changer, relevant and necessary. An easy-to-use and very useful app."`)}
      </Paragraph>

      {/* <Paragraph marginTop={8} lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get('X.')}
      </Paragraph> */}
      
    </Pane>,
    <Pane width="fit-content" paddingX={10} marginLeft="auto" marginRight="auto" className="item" >
      <Paragraph fontStyle="italic" lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get('"It allows me to buy the food I and my kids need: fresh fruit and veg."')}
      </Paragraph>
    </Pane>,
    <Pane width="fit-content" paddingX={10} marginLeft="auto" marginRight="auto" className="item" >
      <Paragraph fontStyle="italic" lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get('"I can now pick what I need and pay with my phone: this makes me feel normal."')}
      </Paragraph>
    </Pane>,
    <Pane width="fit-content" paddingX={10} marginLeft="auto" marginRight="auto" className="item" >
      <Paragraph fontStyle="italic" lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get('"I hope it gets adopted by every food bank across the country."')}
      </Paragraph>
    </Pane>,
    <Pane width="fit-content" paddingX={10} marginLeft="auto" marginRight="auto" className="item" >
      <Paragraph fontStyle="italic" lineHeight={"inherit"} fontSize={14} color="#283655">
        {I18n.get('"The system is accurate and works within seconds. So much better than a parcel."')}
      </Paragraph>
    </Pane>,
  ];

  return (
    <AliceCarousel
    disableButtonsControls={true}
    disableDotsControls={true}
    autoPlay={true}
    autoPlayInterval={12125}
    infinite={true}
    mouseTracking
    responsive={galleryOfSupportResponsive}
    items={galleryOfSupportItems} />
  );
}

export default GalleryOfBeneficiariesTestimonials;