import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, updateProfileState, updateProfileDetails, setTransactionDetails } from '../services/actions';
import _ from 'lodash';
import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const usePageInteraction = () => {

  const userState = useSelector(state => state.userState);

  // const userDetails = useMemo(() => {
  //   return(userState.actAsUser || userState.user);
  // }, [userState]);

  const handlePageInteraction = useCallback(async({ onSuccess = null, onError = null, ...variables }) => {
    
    try {

      // validate ???
      
      const response = await API.graphql({
        query: mutations.pageInteraction,
        variables: {
          ...variables,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });

      let tempTransaction = response.data.pageInteraction;
      
      if (typeof onSuccess === "function") {
        onSuccess(tempTransaction);
      }

    }
    catch(error) {
      console.error("with pageInteraction", error);
      if (typeof onError === "function") {
        onError(error);
      }
    }
  }, [userState.user]);

  return handlePageInteraction;
}

const usePageFollow = () => {
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handlePageInteraction = usePageInteraction();

  const handlePageFollow = useCallback(async({publicFaceIdToFollow, follow = true}) => {

    if (userDetails) {
      // follow/unfollow publicFaceIdToFollow

      // quick count update...
      if (profileDetails.publicFaceId === publicFaceIdToFollow) {
        dispatch(updateProfileDetails({
          followers: {
            ...profileDetails.followers,
            total: profileDetails.followers.total || 0 + follow ? 1 : -1,
          }
        }));
      }

      handlePageInteraction({
        publicTransactionId: null,
        fromPublicFaceId: userDetails.publicFaceId,
        toPublicFaceId: publicFaceIdToFollow,
        viaPublicFaceId: null,
        type: "follow",
        status: follow ? "active" : "deleted",
        onSuccess: (transaction) => {
          console.log("handlePageInteraction transaction", transaction);
          // add/remove to user's following: TransactionSearch based on publicTransactionId

          const existingFollowingResults = userDetails?.following?.results || [];
          let updatedFollowingResults = existingFollowingResults;

          if (transaction.status === "active") {

            // check if a transaction with the same publicTransactionId exists
            
            let existingTransaction = existingFollowingResults?.find(t => t.publicTransactionId === transaction.publicTransactionId);

            if (existingTransaction) {
              existingTransaction = transaction;
            }
            else {
              existingFollowingResults.push(transaction);
            }

            updatedFollowingResults = existingFollowingResults;

            // followers of the profileDetails should reduce ...
            if (profileDetails.publicFaceId === publicFaceIdToFollow) {
              let updatedFollowersResults = profileDetails?.followers?.results || [];
              let existingFollowerTransaction = updatedFollowersResults?.find(t => (t.publicTransactionId === transaction.publicTransactionId || t.fromPublicFaceId === userDetails.publicFaceId));
              if (existingFollowerTransaction) {
                existingFollowerTransaction = transaction;
              }
              else {
                updatedFollowersResults.push(transaction);
              }
              dispatch(updateProfileDetails({
                followers: {
                  results: updatedFollowersResults,
                  total: updatedFollowersResults.length,
                  nextToken: profileDetails?.followers?.nextToken,
                }
              }));
            }
            
          }
          else {
            // i.e. when status === "deleted"
            // remove from following
            _.remove(updatedFollowingResults, (t) => (t.status === "deleted"));

            // followers of the profileDetails should reduce ...
            if (profileDetails.publicFaceId === publicFaceIdToFollow) {
              let updatedFollowersResults = profileDetails?.followers?.results || [];
              _.remove(updatedFollowersResults, (t) => (t.status === "deleted" || t.fromPublicFaceId === userDetails.publicFaceId));
              dispatch(updateProfileDetails({
                followers: {
                  results: updatedFollowersResults,
                  total: updatedFollowersResults.length,
                  nextToken: profileDetails?.followers?.nextToken,
                }
              }));
            }

          }

          dispatch(updateUserState({
            [userState.actAsUser ? "actAsUser" : "user"]: {
              ...userDetails,
              following: {
                results: updatedFollowingResults,
                total: updatedFollowingResults.length,
                nextToken: userDetails?.following?.nextToken,
              }
            },
          }));

        },
        onError: (error) => {
          // handle ???
        },
      });
    }
    else {
      // user auth is required
      dispatch(updateProfileState({
        localStage: "DialogAuth",
        localMode: "log-in",
      }));
    }
    
  }, [dispatch, handlePageInteraction, profileDetails.followers, profileDetails.publicFaceId, userDetails, userState.actAsUser]);

  return handlePageFollow;
}

const usePageIsFollowByUser = (pagePublicFaceId) => {
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const [result, setResult] = useState();

  useEffect(() => {
    const pageIsFollowedByUserTransaction = userDetails?.following?.results?.find(transaction => transaction.toPublicFaceId === pagePublicFaceId) || null;

    setResult(pageIsFollowedByUserTransaction);
  }, [pagePublicFaceId, userDetails?.following?.results]);

  return result;
}

export { usePageInteraction, usePageFollow, usePageIsFollowByUser };