import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import { Link, useRouteMatch, useLocation} from 'react-router-dom';

import { TextInput, IconButton, Heading, Icon, LinkIcon, Tooltip, InfoSignIcon, Text, Strong, OrderedList, ListItem, TextInputField, Paragraph, Button, Checkbox, Pane} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import { I18n } from 'aws-amplify';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();
const galleryOfSupportResponsive = {
  0: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
};

const GalleryOfGoodCompany = () => {

  const galleryOfSupportItems = [
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane height={60}>
        <img src={process.env.PUBLIC_URL + '/media/other/logo-uclie.png'} alt={"University College London (UCL) Innovation & Enterprise"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={5} height={60} maxWidth={130} >
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-nisa.png'}
          alt={"Nisa - The Family of Independent Grocers, Nisa stores are independently owned retail outlets operated throughout the UK"}
          height="auto" onDragStart={handleDragStart}
        />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Powered by')}</Text>
      </Pane> */}
      <Pane height={60}>
        <img src={process.env.PUBLIC_URL + '/media/other/logo-aws.png'} alt={"Amazon Web Services (AWS)"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane height={60}>
        <img src={process.env.PUBLIC_URL + '/media/other/logo-frreg.png'} alt={"Fundraising Badge - FaceDonate.org is registered with the Fundraising Regulator"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={20} height={60} maxWidth={130} >
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-forbes.png'} alt={"Forbes"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane height={60}>
        <img src={process.env.PUBLIC_URL + '/media/other/logo-lbs.png'} alt={"London Business School (LBS)"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane height={60}>
        <img src={process.env.PUBLIC_URL + '/media/other/logo-hfb.png'} alt={"The Trussell Trust Hackney Foodbank (HFB)"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane height={60} >
        <img src={process.env.PUBLIC_URL + '/media/other/logo-trf.png'} alt={"Thomson Reuters Foundation"} height="100%" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={10} height={60} maxWidth={130} >
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-baringa.png'} alt={"Baringa Partners, Business management consultant in London, England"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={10} height={60} maxWidth={130} marginLeft="auto" marginRight="auto">
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-cds.png'} alt={"Corrier Della Sera, Italy"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={10} height={60} maxWidth={130} marginLeft="auto" marginRight="auto">
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-ukri.png'} alt={"UKRI – UK Research and Innovation"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    // <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
    //   {/* <Pane marginBottom={8}>
    //     <Text color="#7B8B9A">{I18n.get('Registered with')}</Text>
    //   </Pane> */}
    //   <Pane height={60}>
    //     <img src={process.env.PUBLIC_URL + '/media/other/logo-ico_and_iso.png'} alt={"Information Commissioner's Office (ICO)"} height="100%" onDragStart={handleDragStart} />
    //   </Pane>
    // </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Supported by')}</Text>
      </Pane> */}
      <Pane position="relative" top={10} height={60} maxWidth={130} marginLeft="auto" marginRight="auto">
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-niesr.png'} alt={"The National Institute Of Economic and Social Research (NIESR)"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={0} height={60} maxWidth={130} marginLeft="auto" marginRight="auto" >
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-gbs.png'} alt={"Go Beyond by Sisal & CVC Capital Partners"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>,
    <Pane width="fit-content" marginLeft="auto" marginRight="auto" className="item" >
      {/* <Pane marginBottom={8}>
        <Text color="#7B8B9A">{I18n.get('Featured in')}</Text>
      </Pane> */}
      <Pane position="relative" top={20} height={60} maxWidth={130} marginLeft="auto" marginRight="auto">
        <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-24ore.png'} alt={"Il Sole 24 Ore"} height="auto" onDragStart={handleDragStart} />
      </Pane>
    </Pane>
  ];

  return (
    <AliceCarousel
    disableButtonsControls={true}
    disableDotsControls={true}
    autoPlay={true}
    autoPlayInterval={1800}
    infinite={true}
    mouseTracking
    responsive={galleryOfSupportResponsive}
    items={galleryOfSupportItems} />
  );
}

export default GalleryOfGoodCompany;