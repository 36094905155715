import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';

import { Pane, Strong, Text, Button, TextInput, Paragraph, CreditCardIcon, Spinner } from 'evergreen-ui';

import DynamicTickBox from './DynamicTickBox';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PaymentInput from './PaymentUI';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';
import styled, { StyledFunction } from "styled-components";
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import useHover from "../services/useHover";
import useAccessTransaction from "../services/useAccessTransaction";
import { currencies } from '../services/localeDictionary';

import { I18n } from 'aws-amplify';

const PaymentComponent = ({
  disabled,
  buttonText = I18n.get('Donate'),
  suffixAmount = false,
  allowToUseUserBallance = false,
  onProcessing = () => {},
  onComplete = () => {},
  onError = () => {},
  validationProcess = () => { return true },
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`PaymentComponent - ${countRef.current}`);
    return () => {
      console.log("PaymentComponent - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();
  
  const userState = useSelector(state => state.userState);
	const transactionDetails = useSelector(state => state.transactionDetails);
	const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const [processing, setProcessing] = useState(false);

  const paymentInputRef = useRef(null);
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [isPaymentInputReady, setIsPaymentInputReady] = useState(false);
  const [showCardDetailsInput, setShowCardDetailsInput] = useState(false);

  const [paymentButtonHasLoaded, setPaymentButtonHasLoaded] = useState(false);
  const [paymentButtonIsAvailable, setPaymentButtonIsAvailable] = useState(null);

  const totalPaymentAmount = useMemo(() => {
    const voluntaryContribution = transactionDetails.history?.find((transaction) => (transaction?.type === "voluntaryContribution"));
    let voluntaryContributionAmount = voluntaryContribution?.hasBeenDeclared ? voluntaryContribution?.amount : null;

    const result = Math.round(((transactionDetails.amount + voluntaryContributionAmount + Number.EPSILON) * 100)) / 100;

    return result;
  }, [transactionDetails]);
  

  const handleMakePayment = () => {
    paymentInputRef.current.makePayment();
  }


  const userSavedPaymentMethods = useMemo(() => {

    const userBanksAccounts = userDetails?.bankAccounts;
    const userPaymentMethods = userBanksAccounts?.filter((account) => (account?.type === "paymentMethod"));
    return userPaymentMethods?.length > 0 ? userPaymentMethods : null;

  }, [userDetails?.bankAccounts]);
  
  return (

    <Fragment >
    
      {userDetails ? null :
				<Fragment>
					{userDetails ? null :
						<Fragment>

							<Pane height={30} display="flex" alignItems="center" className="noselect" marginTop={10} borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20"> 
							<Strong htmlFor="required-field" marginLeft={0} fontSize={16} color="#EC4C47" >{'*'}</Strong>

								<Pane display="flex" marginLeft={4} marginTop={10}>
									<DynamicTickBox
										disabled={processing}
										completed={transactionDetails.localAgreeToTCs}
										checked={transactionDetails.localAgreeToTCs}
										onClick={() => {
											dispatch(updateTransactionDetails({
												localAgreeToTCs: !transactionDetails.localAgreeToTCs,
											}));
										}}
									/>
								</Pane>

								<Pane display="flex" marginTop={10} marginLeft={10}>
									<Paragraph >
										<Strong fontSize={14} color={"#283655"} >{`${I18n.get('I agree to')} `}</Strong>
										<Strong fontSize={14} color={"#283655"} className="blue-link"
											is={Link}
											target="_blank"
											to={{
												pathname: generatePath("/:page", {page: "legal"}),
												// state: {},
											}}
										>
											{I18n.get('Terms and Privacy Policy')}
										</Strong>
									</Paragraph>
								</Pane>
							</Pane>

						</Fragment>
					}
				</Fragment>
			}

      {/* {!userSavedPaymentMethods || transactionDetails.paymentMethod === "balance" ? null :
        <Fragment>
          User's saved bank cards
        </Fragment> 
      } */}

      
      { isPaymentInputReady || transactionDetails.paymentMethod === "balance" || transactionDetails.status === "error" || transactionDetails.error ? null :
        <Pane marginTop={10} height={40} display="flex" alignItems="center" justifyContent="center" >
          <Spinner flex="none" size={24} />
        </Pane>
      }

      
      <Pane display={isPaymentInputReady ? "block" : "none" } >

        <Pane marginTop={paymentButtonIsAvailable !== true ? 12 : 12} >
          <Strong htmlFor="required-field" marginLeft={0} fontSize={16} color="#EC4C47" >{'*'}</Strong>
          <Strong marginLeft={4} fontSize={14} color="#425A70" >{I18n.get('Payment')}</Strong>
        </Pane>

        {!userDetails || transactionDetails?.mode === "allocation" || !allowToUseUserBallance ? null :
          <Pane marginTop={12} width="100%" height="auto" marginLeft="auto" marginRight="auto">

            <Pane textAlign="center" height="auto" alignItems="center" display="flex" flexWrap="wrap">

              <Button flex={1} width={"100%"} display="block" paddingY={4} height={55} lineHeight={1}
                disabled={processing}
                borderTopLeftRadius={5} borderTopRightRadius={0} borderBottomRightRadius={0} borderBottomLeftRadius={5}
                isActive={transactionDetails.paymentMethod !== "balance"}
                onClick={() => {
                  dispatch(updateTransactionDetails({
                    paymentMethod: undefined,
                  }));
                }}
              >
                <Strong lineHeight={1} fontSize={14} color={transactionDetails.paymentMethod !== "balance" ? "#283655" : "#7B8B9A"} >{I18n.get('Bank card')}</Strong>
                <Pane height={32} >
                  <CreditCardIcon color={transactionDetails.paymentMethod !== "balance" ? "#283655" : "#7B8B9A"} size={32} />
                </Pane>
              </Button>

              <Button flex={1} marginLeft={-1} display="block" paddingY={4} height={55} lineHeight={1}
                disabled={processing || !allowToUseUserBallance}
                borderTopLeftRadius={0} borderTopRightRadius={5} borderBottomRightRadius={5} borderBottomLeftRadius={0}
                isActive={transactionDetails.paymentMethod === "balance"}
                onClick={() => {
                  dispatch(updateTransactionDetails({
                    paymentMethod: "balance",
                  }));
                }}
              >
                <Strong is={Pane} lineHeight={1} fontSize={14} color={transactionDetails.paymentMethod === "balance" ? "#283655" : "#7B8B9A"} >{I18n.get('My balance')}</Strong>
                <FormattedCurrencyNumber isShort={true} number={userDetails.balance.available} currency={userDetails.balance.currency} fontSize={30} fontColor={transactionDetails.paymentMethod === "balance" ? "#283655" : "#7B8B9A"} />
              </Button>
            </Pane>
          </Pane>
        }
        
        <Pane marginTop={12} 
          display={true
            // && showCardDetailsInput
            && isPaymentInputReady
            && transactionDetails.paymentMethod !== "balance" ? "block" : "none"
          }
        >
          <PaymentInput
            ref={paymentInputRef}
            onReady={setIsPaymentInputReady}
            onDetailsComplete={setCardDetailsComplete}

            disabled={processing}
            user={userDetails}
            fromPublicFaceId={userDetails?.publicFaceId}
            toPublicFaceId={transactionDetails.toPublicFaceId}
            viaPublicFaceId={transactionDetails.viaPublicFaceId}
            publicTransactionId={transactionDetails.publicTransactionId}

            onProcessing={() => {
              onProcessing(true);
              setProcessing(true);
            }}
            onComplete={(transaction) => {
              onComplete(transaction);
              setProcessing(false);
            }}
            onError={(error) => {
              onError(error);
              setProcessing(false);
            }}

            fromFace={transactionDetails.fromFace}
            type={transactionDetails.type}
            mode={transactionDetails.mode}

            amount={transactionDetails.amount}
            totalPaymentAmount={totalPaymentAmount}
            currency={localeState.currency} // ???

            frequency={transactionDetails.frequency}
            locale={localeState.locale} // ???
            caption={transactionDetails.caption}
            note={transactionDetails.note}
            paymentMethod={transactionDetails.paymentMethod}
            paymentMethodId={transactionDetails.paymentMethodId}
            history={transactionDetails.history}
          />
        </Pane>

      </Pane>

        

      {transactionDetails.status === "error" || transactionDetails.error ?
        <Pane marginTop={20} paddingY={8} paddingX={8} textAlign="center"
          background="#EC4C4710"
          borderTopWidth={1} borderTopStyle="solid"
          borderBottomWidth={1} borderBottomStyle="solid"
          borderColor="#EC4C47" >

          <Pane marginTop={4} >
            <Strong fontSize={16} color="#EC4C47">{I18n.get('Something went wrong.')}</Strong>
          </Pane>
          
          {!transactionDetails.error?.message ? null :
            <Paragraph marginTop={4} lineHeight={1} >
              <Text fontSize={12} color="#EC4C47">{I18n.get(transactionDetails.error?.message)}</Text>
            </Paragraph>
          }

          <Pane alignItems="center" display="flex" gap={8} >

            {/* {false ? null :
              <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
                className="noselect"
                // disabled={}
                onClick={() => {

                }}
              >
                {I18n.get('Sign up')}
              </Button>
            } */}
            

            <Button marginTop={12} fontSize={16} padding={8} height={40} width="100%" marginLeft="auto" marginRight="auto" justifyContent="center"
              className="noselect"
              // disabled={}
              onClick={() => {
                // clear current error publicTransactionId, status and error
                // and API calls to indicate retrying ???
                dispatch(updateTransactionDetails({
                  publicTransactionId: null,
                  status: "pending",
                  error: null,
                }));

              }}
            >
              {I18n.get('Try again')}
            </Button>

          </Pane>
          
          {/* <Paragraph marginTop={4} >
            <Text fontStyle="italic" fontSize={12} color="#EC4C47">{I18n.get("If the error persists, please try to reload this page.")}</Text>
          </Paragraph> */}

        </Pane>
        : null
      }

      {(false
        // && (!paymentButtonHasLoaded || !isPaymentInputReady)
        && !isPaymentInputReady
        && transactionDetails.paymentMethod !== "balance"
      ) ?
          null
      :
        <Pane display="flex" alignItems="center" justifyContent="center" marginTop={20} className="noselect">
          <Button fontSize={16} padding={8} height={48} className={processing ? null : 'button-green'} width="100%" justifyContent="center"
            disabled={
              disabled
              || transactionDetails.status === "error" || transactionDetails.error
              || !totalPaymentAmount
              || (!cardDetailsComplete && transactionDetails.paymentMethod !== "balance")
              || (transactionDetails.paymentMethod === "balance" && userDetails?.balance.available < totalPaymentAmount)
              || (!userDetails && transactionDetails.localAgreeToTCs !== true)
              || transactionDetails.history?.find((historyTransaction) => (historyTransaction.isBeingEdited))
            }
            onClick={() => {
              let validationPassed = null;
              if (typeof validationProcess === "function") {
                validationPassed = validationProcess();
                if (validationPassed === undefined) {
                  validationPassed = true;
                }
              }
              if (validationPassed) {
                handleMakePayment();
              }
              else {
                console.error("validation not passed");
              }
            }}
          >
            {!processing ?
              <Pane>
                {buttonText}
                {typeof suffixAmount !== "number" || suffixAmount === false ? null :
                  <Fragment>
                    {" "}
                    <FormattedCurrencyNumber removerDecimalZeros={true} number={typeof suffixAmount === "number" ? suffixAmount : totalPaymentAmount} currency={localeState.currency} fontSize={20} fontColor={"#FFFFFF"} />
                  </Fragment>
                }
                {transactionDetails.frequency !== "monthly" ? null : <Text fontSize={16} color="inherit" >{` ${I18n.get('monthly')}`}</Text>}
              </Pane>
            :
              <Fragment >
                <Spinner marginRight={8} flex="none" size={24} />
                <Pane >
                  {`${I18n.get('Processing')}...`}
                </Pane>
              </Fragment>
            }
          </Button>
          
        </Pane>
      }
      
    </Fragment>
  );

}

export default PaymentComponent;