import { useEffect, useState } from 'react';

import { Link, Redirect} from 'react-router-dom';

import { ThemeConsumer, Tooltip, Menu, Popover, Link as A, Position, TextDropdownButton, Heading, UnorderedList, ListItem, Text, Tab, Tablist, SidebarTab, Paragraph, Table, Button, Switch, Checkbox, Pane, SegmentedControl} from 'evergreen-ui';

import Markdown from 'markdown-to-jsx';
import { RemoveCookiesConsentButton } from '../components/CookiesConsent';

import Footer from '../components/Footer';


const infoSection = `
## Latest update

The below Terms, Privacy Policy and Cookies Policy were last reviewed and updated on 22th March 2022.

Please see out [FAQ page](https://facedonate.org/faq) for the latest information on pricing and services.

Please email us at [hello@facedonate.org](mailto:hello@facedonate.org) with any queries or suggestions.
`;

const termsBody = `
# TERMS

## General

THIS TERMS, PRIVACY POLICY AND COOKIES POLICY  (COLLECTIVELY REFERRED TO AS THE TERMS) FORM A LEGAL AGREEMENT BETWEEN YOU  (THE USER) AND THE OWNER OF FACEDONATE.ORG IS FOMSON LIMITED, THAT GOVERNS YOUR USE OF THE FACEDONATE.ORG WEBSITE, AND ALL ASSOCIATED SERVICES AND SITES LINKED TO IT BY THE OWNER  (COLLECTIVELY REFERRED TO AS THE SITE).

  

The Site is the property of FOMSON LIMITED  (incorporated and registered in England and Wales with company number 10974844).

  

“FACE DONATE” is a trading name of FOMSON LIMITED.

  

**BY USING THE SITE, YOU AGREE TO THESE TERMS, PRIVACY POLICY AND COOKIES POLICY. IF YOU DO NOT AGREE WITH ANY PART OF TERMS OR PRIVACY POLICY OR COOKIES POLICY, DO NOT USE THE SITE.**

  

IT IS IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS.

The Owner reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms and/or Privacy Policy and/or Cookies Policy at any time without explaining or reasoning. It is your responsibility to check the Site periodically  for any changes.

Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. Your use of the Site is regulated by the most current version of the Terms, which is legally binding on you. As long as you comply with these Terms, the Owner grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Site.

  

If you do not agree with any part of these Terms, you must stop using the Site immediately and destroy any content, documents, emails and schedules and a-likes you have acquired from using the Site.

  

The Terms describe what the Site does for you and the other users  (persons and organisations) and what we require of you as a user.

  

Definitions:

  

-   “FACE DONATE” or  “us” or  “we” or  “our” refer to the Owner of the Site.
-   “You” and  “your” refers to each user or viewer of the Site, including, but not limited to, donors, charities and any other persons and organisations.
-   “Donor” refers to any person or organisation that supports any  “Recipient” or  “Provider” with a donation through the use of the Site.
-   “Recipient” or  “Provider” refers to any person or organisation that Donors support through the use of our Site.
-   “Donate”,  “spend” and  “receive” funds refers to actions by the users of the Site.
-   “Pay” refers to action by the Site.
-   Data Privacy - your privacy is paramount to us. Please see our Privacy Policy for more details.
-   User Conduct - if you use the Site or use any of our its parts, please remember the Site is for everyone. If we find offensive, defamatory, libellous, threatening, harassing, or obscene content on the Site, we will remove it and terminate your access. Please ensure the content you upload to the site, especially pictures, does not infringe another person’s rights. Any action that we become aware of  (both related and unrelated to the use of the Site) that may anyhow be considered to be illegal may be reported to the authorities.

  

These Terms apply globally and in each country where we operate and you use the Site.

The following are the counties where we actively support the operation of the Site:

-   The United Kingdom  (main domain - [facedonate.org/uk](https://facedonate.org/uk))
-   Italy  (main domain - [facedonate.org/it](https://facedonate.org/it))

  

## About the features on the Site

The Site allows users to donate, receive and spend funds. We are not responsible for the actions and activities of any individual user or any group of users for the use, or misuse, of the Site.

The Site and its associated services allow you to donate to and receive funds for any recipient /providers listed on, or searchable through, the Site. Every recipient featured may define an agreement with FACE DONATE on how donations are processed, both in terms of values and times.

FACE DONATE is not responsible for the activities of any recipient, donor or provider. Any provider self-declaring a charitable status must have, and provide at request, the appropriate authorisation, permit or licence to operate as a charity, as required by the local laws of the territory in which it operates.

## Donations

Subject to the requirements of applicable local laws, and what we say below in relation to the Site, once your donation is made it will only be refunded to you when an unauthorised use  (i.e. not by you) of your payment card or other payment method is proved.

Your donation will be processed via the payment method used at the time of donating.

NOTE: no donations will be refunded for any reason, subject to applicable local laws. Occasionally, the person or organisation who created the page to which you have donated does not pass identity verification or other necessary checks, or there is some other reason that we cannot transfer the funds to them.

If we are unable to transfer the funds to the intended Recipient you donated to, and we are unable to allocate your donation to another Recipient, we will seek to return your donation to you via the payment method from which you made the donation, subject to the deduction of applicable transaction and payment processing fees. When a donation is returned, or if we are unable to return your donation via the payment method you used, your donation will be shared across the other recipients in the Site network, subject to the deduction of applicable transaction and payment processing fees.

## Use of donation

The Site will do whatever in its power to retrieve with the highest level of certainty and clarity the proof of purchase provided for the service or product purchased with/through the donation. However, the Site does not – or does not want to – limit the needs of the recipients in freely using their donations.

We do not represent or warrant that any donations will be used for any particular purpose. The Site and its owners shall not be held responsible for any dissatisfaction the User may have regarding a use of any donation that may be made through the Site, or its associated services, or websites powered by us, or for any misuse, or non-use of such donations. After donations are made, we will seek to ensure that the donations are processed and credited to corresponding Recipients, and that the Recipients  (persons and/or organisations) spend them as intended.

We will impede, by not paying out, any provision not clearly stating the products or services provided, or that we deemed not to be appropriate in content or scope, when vouchers are submitted for payment.

Please note that recipient organisations may reserve the right to use donations for their general purposes in accordance with their practices and rules. We shall not be responsible for ensuring that funds are earmarked for a particular appeal.

Recipients may choose to utilise the donation over the four months following the exchange. In case of an unused donation the Site reserves the right to discretionally reallocate the amount to alternative recipients or use.

We  (or any payments processor or card brand we work with) may verify the identity of Recipients and may undertake any other necessary checks before any funds raised are transferred to them. We shall have no liability to any donors whatsoever for any use or misuse of donations made to us or via the Site. Some pages of the Site may state that Recipients will use donations only for specific purposes, but We cannot guarantee that they will use them for such purpose.

If you have any doubts as to how your donation may be spent you should not use the Site to make that donation. It is your sole responsibility, as a User of the Site, to ensure that the purpose presented on a any page of the Site or by any Recipient is one that you wish to support.

Users will be able to access the information relative an history of provisions and collections directly on the Site, or alternatively by contacting [hello@facedonate.org](mailto:hello@facedonate.org).

## Unauthorised donations

When you make a donation, the transaction is final and not disputable unless unauthorised use of your payment card or other payment method is proved. If you become aware of fraudulent use of your card, or if it is lost or stolen, you must notify your card provider in accordance with its reporting rules. Similarly, if you experience any issues of this nature when using another payment method, you should contact the provider of that payment method for assistance.

We will never email or phone you and ask you to provide all of your payment details When providing recipients are redeeming the credit, the Site will request to provide the payment details only through a secure and certified payment gateway. The payment gateway used by the Site is provided by a third-party provider, hence being all payment related processes not owned, but licensed to the Site, we cannot be held responsible in any manner for failure in the payment mechanism leading to potential material loss, or related stress.

## Protecting your account

When you register with us  (sign up on the Site) and choose a password to protect and secure your account, you are responsible for maintaining the confidentiality of your password to prevent others gaining access to the information associated with your account - this includes, but is not limited to, any details you may have saved under your profile. If you become aware of any unauthorised use of your account, you must immediately change your password and contact us by emailing at [hello@facedonate.org](mailto:hello@facedonate.org).

## Information

FACE DONATE is not an accounting, taxation, financial advisor or any other type of advisory body, and you should not rely on any information given on the Site or its associated services to determine any consequences, to yourself or others, of making a donation or any other decision.

## Privacy

Our Privacy Policy forms part of these Terms. By agreeing to these Terms, you also give your consent to the way we handle your personal data and information under our Privacy Policy.

## Acceptable use

-   You must at all times use the Site and its associated services in a responsible and legal manner and ensure that the content you provide does not breach any intellectual property rights ,or any other rights, of a third party, or breach any right, or duty, owed to a third party.
-   If any content you wish to share on the Site is copyright-protected, you must obtain the copyright owner's written consent to use it.
-   You must not upload  (or provide in any other way) offensive, obscene, racist, defamatory, misleading, deceptive, or illegally or unlawfully obtained content  (including photographs), on to the Site or its associated services. We do not actively edit the Site but reserve the right to remove or edit any content posted on the Site or its associated services at our sole discretion and without notice, regardless of whether or not it is, in the opinion of any third party, offensive, obscene, racist, defamatory, misleading, deceptive or otherwise inappropriate. If you notice any such content, you must email us at [hello@facedonate.org](mailto:hello@facedonate.org).
-   Any persistence of derogatory, or anything the Site classifies as  ‘unpleasant behaviour’; will be removed from the site and the associated profiles will be blocked, prior to reporting the facts to the competent authorities.
-   You must always ensure that any information you provide is accurate and not misleading. It is your responsibility to ensure that the content you upload, including, but not limited to, pictures and photographs, is your original work and/or you have the right and/or licence necessary to obtain and share it with us and it is not copyright-protected. If it is copyright-protected or in other way may be restricted, you must obtain the copyright owner's written consent to share it with us. We reserve the right to remove any pictures, photographs, videos or copy the Site at our sole discretion and without notice. If you suspect a breach of copyright on the Site, please email us at [hello@facedonate.org](mailto:hello@facedonate.org). Please note that by uploading  (or sharing with us by other means) any content to the Site, you grant to us a perpetual, irrevocable, worldwide, royalty-free and non-exclusive license to use, modify, reproduce, publish, broadcast, display and distribute the content in any format, using any medium, for the purpose of promoting our activities, or for any other purpose that we may deem appropriate.
-   You must not misrepresent your identity or affiliation with any other person or organisation.
-   You must not use the Site to send junk email or  “spam” to people who do not wish to receive email from you.
-   You must not use the Site to conduct, display or forward any content, including, but not limited to, surveys, raffles, lotteries contests, pyramid schemes or chain letters.
-   You must not interfere with, or disrupt, the service or services or networks connected to the Site and/or introduce any computer virus  (including any variant or similar malicious code or instructions) to the Site.
-   You must not attempt to modify, adapt, translate, sell, reverse engineer, decompile or disassemble any portion of the Site.
-   You must not attempt to bypass the network firewall or any other systems associated with the Site.
-   You must not use any part of the Site which you are not authorised to use or devise ways to circumvent security in order to access part of the site which you are not authorised to access  (includes, but not limited to, scanning networks with intent to breach and/or evaluate security, whether or not the intrusion results in access).
-   You must not use or attempt to use the Site for any unlawful, illegal, criminal, or negligent purposes  (includes, but not limited to, password cracking, social engineering, denial-of-service attacks, harmful and malicious destruction of data, and intentional invasion of privacy).
-   You must not disclose any information relating to any other user except with the consent of the user or as permitted or required by law.

  

We reserve the right to cancel your access and delete any of the Site’s pages or content associated with you without notice in the event you fail to follow any of these Terms. We may also suspend or delete any data if it is is no longer relevant to our activities.

Any information provided on the Site in no way implies our endorsement of any fundraising activity.

Prior to commencing activity on the Site, it is your responsibility to ensure that the benefitting party has understood the nature of the Site and has actively agreed in participating to the proposed activity. It is highly recommended to use the Site to track record of the initial agreement by registering the recipient with a picture clearly presenting a sign of approval  (i.e. thumbs up).  We reserves the right, at our discretion and without notice, to refuse service to any person or organisation.

## Links

The Site and its associated services may contain links to other websites and/or services, including the websites of persons and organisations who we work with. Inclusion of a link to another website does not imply endorsement of its content or opinions. Your relationship and any direct transactions with other persons or organisations are your own responsibility.

## Partner services

We may from time to time select partners offering relevant information and services that we believe will enhance the Site. Whilst we will do our best to select reputable partners, we are not responsible for any of the information or services offered by them, and if you choose to use their services, you do so at your own risk. The Site reserves the right to investigate any partner service and to request further information, prior to submit any payment when not deemed safe, secure or legitimate.

## Trademarks and copyrights

The names  “FACE DONATE”,  “Recipient”,  “Provider”, and  “FACEDONATE.ORG”, the FACE DONATE logo and any other trademarks and product or service names that we may present on the Site and/or any associated services from time to time are of sole property of the Owner of the Site and may not be used in connection with any product or service that is not ours, nor in any manner that is likely to cause confusion, or in any way that may disparage or discredit us. Other trademarks, service marks or logos that appear on the Site or its associated services, in particular  (but not exclusively) those of charities or other organisations, are the property of their respective owners and are likely to be registered trademarks and/or subject to restrictions as to their use. They must not be used without the express permission of both us and the trademark owner.

All content on the Site and its associated services is owned by the Owner. No use of the Site's and its associated services' content is permitted without the express prior permission from us, and, where applicable, the copyright holder.

  

At convenience the Site may release the use of its logo and its associated logo and any other product, and service, ideas, information and data, or any trademarks to anyone transacting any sort of legally exchangeable product or service, as long as the exchange is traced and conducted with the use of the Site. Any transaction using any material associated with the Site  (including but not limited to trademarks, logo, images, colours, and brand identity) shall be transacted using the services of the Site and only between registered users  (namely Donors, Recipients, and Providers). Any transactions done with, or thanks to any content related to Site brand, message and identity done externally to the site – especially if cash related – will be will be persecuted in the court of law as infringement to these Terms, and appropriation of brad image.

## Prohibition on data extraction and distribution

By visiting or using the Site or any of its associated services, you agree not to use, upload, send, distribute any tool, program, script, browser extension or other technique, including, but not limited to bots, robots, spiders and scrapers and any similar digital or physical tools or methods, in order to any tampering action, including but not limited to copy  (except as authorised by these Terms), mirror, frame, index, scrape, mine or otherwise gather or extract any of the content or data from the Site or its associated services, and/or sell and/or distribute any data which is gathered or extracted in breach of these Terms, or which is based on or derived from any such data.

We may take any measures we sees fit to block access to the Site and its associated services where we believe, or perceive, that these Terms have been, or will be breached. You agree that you will not take any steps to try to circumvent these measures and that you will not take any steps to mask your credentials  (e.g. IP address).

You acknowledge, and agree, that where there is any actual or threatened breach of these Terms, damages may be an inadequate remedy and we shall be entitled, without prejudice to any other rights and remedies we may have, to seek an injunction or any other equitable relief for such breach. We may also request that you to destroy and substantiate the destruction of any such data you have gathered or extracted in breach of these Terms and you agree that you will comply with such request promptly. Any inquiries and permission requests may be sent to [hello@facedonate.org](mailto:hello@facedonate.org).

## Changes to the Site

We reserve the right to improve, change, restructure, modify, suspend or discontinue all or any part of the Site and its associated services at any time with or without notice. All new features, services or software applications shall be subject to these Terms.

## Failure to comply with these Terms and termination

We reserve the right, at our sole discretion, to immediately and without notice suspend or permanently deny your access to all or part of the Site and associated services.

Upon termination or suspension or denial of access to the Site and/or associated services, funds we already received will be handled in accordance with the provisions in these Terms. Any payment  (where applicable) may be delayed when we conduct an investigation regarding your use of the Site and we may decide to refuse to pay funds to you where you have breached these Terms.

You may discontinue use of the Site and associated services at any time. These Terms will continue to apply to your past use.

## Disclaimer and limitation of liability

We strive to make the service as reliable and continuous as possible, in the unlucky event of Site’s interruption, the Owners are not, and cannot be held, responsible for any loss or potential damage, relative to the services connected to the Site.

You agree that your use of the Site and its associated services is on an  "as is" and  "as available" basis and that your use of the Site and its associated services is at your sole risk. We do not warrant or guarantee continuous uninterrupted or secure access to our services and operation of the Site and associated services may be interfered with by numerous factors within and outside of our control. On that basis, except as expressly set out in these Terms and except for the rights, guarantees and remedies which cannot be excluded, we do not provide other conditions, guarantees, warranties or terms in relation to the Site or its associated services, to the extent permissible by law. We shall undertake general maintenance and upkeep of the Site from time to time. During these periods, the Site and its associated services may not be available for use. In exceptional circumstances, the Site and associated services may also become unavailable at other times.

If found liable, we shall only be liable under these Terms for direct losses which are reasonably foreseeable and caused by our uncured material breach of these Terms or our negligence. Our total liability to you arising under or in connection with these Terms, whether in contract, tort  (including negligence), breach of statutory duty or otherwise, shall in no circumstances exceed the total sum of the donations you personally have made using the Site in the preceding six-month period, ending on the date the circumstances giving rise to our liability arose.

Nothing in these Terms excludes any statutory rights which may apply to your use of the Site and associated services which cannot be excluded, restricted or modified by contract.

Notwithstanding the provisions of the above section of the Terms, our total liability to you arising under or in connection with these Terms, so far as such liability arises out of or relates to your use of the Site as a Provider, shall be limited to the total fees paid by you to us under these Terms for your use of the Site and associated services during the preceding six-month period ending on the date the circumstances giving rise to the liability arose.

In no event shall we be liable for losses relating to any business of yours or lost or corrupt data, loss of profits, loss of contracts, loss of business opportunity, loss of sales, loss of revenue, loss of goodwill, loss of any software or data, loss of bargain, loss of opportunity, loss of use of computer equipment, loss of or waste of management or other staff time, even if we have or may have been advised of the possibility of such damages. You expressly agree to the allocation of risk set forth herein.

## Third party rights and confidential information

Any party  (an individual, organisation, state or any other form of entity) who is not a party to these Terms has no right to enforce any term of these Terms.

All users shall treat as strictly confidential all information about the others which has been acquired as a result of the use of the Site and/or associated services and which is not in the public domain. No party shall use or disclose to any third party such information belonging to the other party without that party's prior written consent, except where required to do so by applicable law, including, without limitation, the Freedom of Information Act 2000 and the Environmental Information Regulations 2004, or regulatory or governmental body or those third parties operating under non-disclosure provisions no less restrictive than those set forth in this agreement and who have a justified business  “need to know.”. This paragraph shall survive termination of these Terms. For the avoidance of doubt, any Personal Information shall be treated in accordance with applicable laws and shall not be considered  “confidential information” belonging to a party unless it is the Personal Information of a particular user of the Site.

## Governing law and dispute resolution

These Terms and any contractual or non-contractual dispute arising out of or in connection with the use of the Site or the associated services are governed by English law.

The parties agree to submit all unresolved disputes between them to arbitration administered by the International Centre for Dispute Resolution  (“ICDR”) and governed by the ICDR Arbitration Rules  (“ICDR Rules”) then in effect, except that either party may seek injunctive relief for infringement of intellectual property rights or other proprietary rights in court. For all arbitrated matters, one arbitrator will be appointed under the ICDR Rules, and the locale of arbitration will be London, England, unless the parties mutually agree to another locale before appointment of the arbitrator.

## Users under the age of 18

It is a condition of use of the Site that any action on the Site may only be conducted by users over the age of 18. Persons under the age of 18 may use the Site only with parental consent and in-person supervision. The Site reserves the right to limit use when deemed, or reported, as unlawful. Parents, or minor’s supervisor, will need to present a written consent consent for the minor to use of the platform prior to commencing use. The consent can be requested by email at [hello@facedonate.org](mailto:hello@facedonate.org). For no reason and in any manner the Site supports any collection activity done by minors. Vetted providers can – after obtaining parental consent in writing – define crowdfunding campaigns for specific needs of particular underage recipients. The supervision requirements are as follows:

-   Children under the age of 18 using the Site must do so under the direct supervision of an adult  (a parent or a legal guardian).
-   If you have any concerns whatsoever about a child's use of the Site, please notify us immediately by emailing us at [hello@facedonate.org](mailto:hello@facedonate.org).

## Availability

We seek to ensure that the Site is available 24 hours a day every day. However, the limitation of liability and disclaimers within these Terms apply equally to the availability of the Site. We are not liable if for any reason the Site is unavailable at any time and for any period and for any reason. We may suspend access to the Site without notice at any time.

The principle  “What-You-See-Is-What-You-Get” is applicable and applies at all times.

The Site access may be restricted to individuals and organisations arbitrarily by the Owners. In the case the Owners perceive the possibility of malicious or criminal behaviour all actors involved will be immediately reported to the relevant Authorities.

## Additional terms for Providers

If you act as a Provider on the Site the following terms, in addition to the Terms, will apply to your dealings with us in relation to the relevant features and your use of the Site and associated services. To the extent there is any conflict between these additional terms and the core Terms, these additional terms will prevail.

  

We will:

-   Operate the Site so that donors can make online donations by credit or debit card, or by another payment method available on the Site;
-   Provide a system to verify the transaction via receipt, either physical or virtual;
-   Pay out by bank transfer to your bank account, or by any other payment method that you have selected via the Site, provided that you and your action have passed identity verification and other necessary checks and have meet our system's requirements  (either on one-off basis or, where applicable on transaction basis).
-   If you do not pass these checks within 30 days from your first donation all funds received will be refunded and your page will be removed. If funds are unable to be refunded, they may be redirected to a registered charity at the Site’s discretion. The Site may allow an additional 30 days to verify your identity and bank account ownership.

As a provider you may be required to supply additional information to the Owner of the site upon request. Failure to provide any additional information requested will result in an infringement of these terms and the removal of your provider’s page.

Providers will be able to access the information relative an history of your provision and collections directly on the Site, or alternatively by contacting us at [hello@facedonate.org](mailto:hello@facedonate.org).

The Site is not responsible for any provision that does not corresponds to the criteria highlighted on the site. We reserve the right to suspend payments to providers, if we do not believe providers are acting ethically or in alignment with the social ethos of the Site.

By acting as a Provider you represent, warrant and undertake to us and the users of the Site that:

-   you will ensure all Provisions you make to the Recipients will be used for the purposes set out on the Site; and
-   you will ensure no fraudulent, criminal or otherwise improper uses will be made of Provisions by you and/or donations made to you.
-   will not recommend to donors any specific or different fundraising activity, unless through the use of the Site;
-   maintain the highest standard of service and ethics to defend and enrich the Site reputation and mission;
-   to raise any complaints directly to the Site by writing at [hello@facedonate.org](mailto:hello@facedonate.org), prior to any diffusion on other platforms or media. Any other behaviour will be deemed derogatory, we are doing our best to make a social innovation work, we will appreciate all your help.

  

It is the Provider’s responsibility to verify the Recipients balance and provide accordingly. For no reason the Site will compensate Providers for provisions not covered by the balance of the recipient. Each single provision will have a maximum value as described in the Site.

It is our policy that all pages and function serve a purpose that benefits the public good. We retain the unrestricted ability to reject or terminate a page in its sole discretion when we believe the burdens of continuing a page are not in our best interest. In rejecting or terminating a page, we are not passing judgment on the worthiness of the page or its cause, but rather exercising our prerogative to avoid any controversy that could potentially injure our reputation.

You agree that if your use of the Site and/or associated services results in access to any Personal Information you shall:

-   at all times assist with the responsibilities of the Site, as a data controller responsible for determining how the Personal Information is processed under the provisions of the Privacy Laws;
-   not do, or cause or permit to be done, anything which may result in a breach by the Site of the Privacy Laws and comply with all reasonable instructions from the Site relating to the processing by you and/or the providing recipients of such Personal Information;
-   comply with the Privacy Laws in respect of your and/or the Provider's collection, use, disclosure or processing of the Personal Information;
-   abide by the lawful instructions of all data subjects in respect of the Personal Information and not do anything to compromise the security of such information;
-   not sell, trade or rent Personal Information to third parties;
-   hold the Personal Information securely and not disclose it to anyone other than the Site, as agreed to by the data subject and/or as permitted by Privacy Laws;
-   implement adequate administrative, technical and physical safeguards against all unauthorised, unlawful or accidental access, processing, use, erasure, loss or destruction of, or damage to, Personal Information in accordance with Privacy Laws, and abide by the Site reasonable requirements to ensure the security of the Personal Information as notified to you from time to time;
-   use Personal Information appropriately and only for the specific purposes as notified to you from time to time, including by way of the applicable privacy policy available on the Site;
-   will not communicate, or advertise, to donors unless they have agreed to receive further communications from you and/or the provider, and only to the extent that they have indicated their preference to do so  (for example in relation to communications for a specific fundraising event only);
-   not retain any Personal Information for longer than is necessary; and
-   to the extent legally permissible, you shall indemnify and hold harmless the Site and its Owners, its successors and assigns, from and against all losses, costs and other damage caused by your and/or the Providers’ breach of this paragraph.

  

We do not review or exercise any editorial control over the content of information on some of the Site’s pages. In the event that we are made aware of or has knowledge of any unlawful activity or information on the Site or associated services, we shall act to remove or disable access to the information. The Site, or its Owners, shall not be liable to you or any other party as a result of its role hosting specific pages and information on them.

## Miscellaneous

If any of the provisions of these Terms are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms, so that these Terms shall remain in full force and effect. These Terms and Privacy Policy constitute the entire agreement between you and us with regard to your use of the Site, and any and all other written or oral agreements or understandings previously existing or formed between you and us with respect to such use are hereby superseded and cancelled. Other than as provided in a purchase agreement you enter into with us, we will not accept any counter-offers to these Terms and/or Privacy Policy, and all such offers are hereby categorically rejected. A failure by us to insist on or enforce strict performance of these Terms and/or Privacy Policy shall not be construed as a waiver by us of any provision or any right we have to enforce these Terms and/or Privacy Policy, nor shall any course of conduct between us and you or any other party be deemed to modify any provision of these Terms and/or Privacy Policy. These and/or Privacy Policy shall not be interpreted or construed to confer any rights or remedies on any third parties. The Site shall not be held responsible for the representativity of the information provided, but will defer the claims to the individual or the organisation that provider fallacious information.

  

**Feedback and Information**

Any feedback you provide at this Site shall be deemed to be non-confidential. We shall be free to use such information on an unrestricted basis. The information contained in the Site is subject to change without notice. The Site shall not be held responsible for the representativity of the information provided on the Site. All claims shall be directly addressed to the individual or the organisation that provides malicious, or fallacious, information, not to the Site.

  

**Unsolicited Idea Submission Policy**

We or any of our employees and directors do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new or improved products, services or technologies, product and services enhancements, processes, materials, marketing plans, visual assets  (images, illustrations or icons) or new product names. Please do not submit any unsolicited ideas, original creative artwork, suggestions or other works  (collectively -  “Submissions”) in any form to us or any of our employees and/or directors. The sole purpose of this policy is to avoid potential misunderstandings or disputes when products, services or marketing strategies might seem similar to ideas submitted to us. If, despite our request that you do not send us your ideas, you still submit them, then regardless of what your letter says, the following terms shall apply to your submissions.

Terms of Idea Submission - you agree to waive all your rights to your Submissions and their contents - all will become the property of the Owner, without any compensation to you; we may use or redistribute the Submissions and their contents for any purpose and in any way; there is no obligation for us to review the Submission; and there is no obligation to keep any Submissions confidential.

We do welcome your feedback regarding many areas of our existing business. If you want to send us your feedback, and we hope you do, we simply request that you send it to us by email to [hello@facedonate.org](mailto:hello@facedonate.org). Do not include any ideas that this policy will not permit it to accept or consider. It’s just one more way that we may learn how to best satisfy your needs.
`;

const privacyPolicy = `
# PRIVACY POLICY

## Who are we?

You can contact the Owner of the Site by emailing us at [hello@facedonate.org](mailto:hello@facedonate.org), or by writing to the registered address of the Owner. This Privacy Policy applies to our collection and use of your data in connection with our services.

## How to reach our data protection officer?

To contact our Data Protection Officer regarding our processing of your personal data, please email at [hello@facedonate.org](mailto:hello@facedonate.org).

## How do we notify you of changes to this policy?

We keep this Privacy Policy under regular review and place updates on [facedonate.org/legal](https://facedonate.org/legal). We may also provide notifications of material changes via email or other channels. If you keep using the Site, you consent to the any updates to this Privacy Policy. If not, you must stop using the Site.

## What are your rights?

We recognise that your personal data belongs to you and we do not wish to use it in ways that you do not want us to. We bring your attention to the fact that anyone using with the Site, by virtue of use, is protected as a Site user, and accepts the terms of this policy.

If granted by applicable laws, you can also exercise a variety of rights regarding our use of your data, some of which are:

-   You can ask us for a copy of the information we have about you;
-   You can ask us to correct any incorrect data we have about you and you can also update your account details yourself at any time by visiting your account on the Site;
-   You can ask us to delete your data;
-   You can object to any processing we do on the basis of legitimate interests or to any automated decision-making;
-   You can ask us to restrict the processing of your data.

To exercise any of your rights, please email us at [hello@facedonate.org](mailto:hello@facedonate.org). Note that some of these rights may not be enforceable in your jurisdiction and some aren’t absolute - for example, we may not be able to  ‘forget’ you if we have to keep some of your data to comply with the law - but we shall evaluate your request in accordance with applicable data protection laws. We shall seek to respond to your request within one month of receiving it. Also, note that if you’re located in the UK or the EU, you have the right to lodge a complaint with the UK Information Commissioner’s Office or the supervisory authority in your country of residence or place of work.

## What data do we collect?

We don’t receive any personal data about you other than what you provide us using the Site or data we can infer from your use of the Site.

  

**When you make a donation:**

To enable us to process donations, we may collect basic payment information as well as your name, surname, address and email address. Your name and the amount you have donated may be displayed on the public page.

  

**When you create an account on the Site:**

We will collect basic contact information about you to set up your account you so you can make donations, receive donations and process donations, depending on the role you will select in your account  (recipient, donor, provider). The requested information will include your name and email address. We will also ask you to register a password so you can gain secure access to your account in the future. We may enable you to set an image for your profile on the Site and this image is shown as a thumbnail next to all of your actions on the platform, unless you have chosen to hide your name and photo from the public when you donate. Please note that images are dynamic and not fixed in time, e.g. if you change your image in 2022, the new image will appear next to your activities from 2019.

  

**When creating a new Recipient’s Page:**

We will use the details you provided when you create a new Recipient’s page. We will also collect details of the person that you are acting on behalf of, or the details for your proposed new Recipient page. When creating a new Recipient page on the behalf on another person, make sure the recipient is well aware and informed of your intentions of registering them on the Site. As a rule, a clear sign of acceptance and consent shall be available when registering  (our recommendation is to ask the recipient to smile, raise a  ‘OK’ sign or a  ‘thumbs up’ or any other sign that can be interpreted as acceptance. When signing another person, please take the time to explain them the process and make sure they are in favour of creating a recipient’s page on their behalf.

  

**When you create a new Provider’s Page:**

We will use the details you provided when you set up your account to create your Provider’s page. We will also collect details of the organisation that you are acting on behalf of, or the details for your proposed Provider’s page. Where applicable, we will ask you to provide further details of the organisation for the Provider’s page so that we may process outgoing payment to this organisation, in order to do, we may also collect your title  (if you choose to provide it), date of birth, telephone number and some bank account details for incoming payments  (sort-code account number, institute name and address). Discretionally, we will ask you to provide further information relative to your legal ability to provide goods and services  (i.e. company number, charity registration number, VAT registration, UTR…). Failure to provide details may result in delays in payment’s processing, and might lead to justified refusal of payment. Malicious use of the Site will immediately be reported to the competent Authorities. If you create a Provider’s page for an organisation which is managed by someone other than yourself, you must ensure that the manager(s) of that organisation agrees to your intention. All information requested for the creation of the provider’s page data are necessary to allocate payments and collect resources locally.

  

**When you use the Site:**

We may also collect information about your experience on the use of our Site. This might include your IP address, mobile device identifier, how much time you spend on the site, and what you do, like, follow or view. We do this through the use of cookies. We do this to improve your experience when using out Site. To read more about our use of cookies, please refer to our  “Cookies Policy” for further information.

## What happens if you do not want us to have your data?

You are not required to provide personal data to us. Please note, that lack of information or refusal to provide required information may affect your ability to use the Site. For example, we are unable to process your donation if you do not provide your payment information.

## How do we use your data?

We use data to enable the Site to function. Different data are required in order to provide our services  (i.e. processing donations, compensating providers, monitoring expenditure, etc.), as well as to personalise your experience, for example by sending targeted communications and enhancing your ability to donate, collect and contribute and, when/if applicable, claim Gift Aid.

  

We use your information to keep you involved in doing good. We do this by sharing with you progress and experiences via emails. You can adjust your notification settings by signing in to your account or by emailing at [hello@facedonate.org](mailto:hello@facedonate.org), where we would be happy to assist you.

  

We use aggregated and personal data about how users use the Site so that we develop and test better functionality, drive our research and development and better understand our users and partners. We do this analysis using a variety of data sources - transactional data  (how you use the Site), clickstream and log data  (web traffic), email data  (how you interact with emails we send you), survey data, and customer service data. We may invite you to partake to surveys about the Site and its possible future developments.

Aside from sending informative and marketing communications by email, we may target other information materials to our users and measure how they perform, both through our Site and through other channels like social networking sites and other websites.

## With whom do we share your data?

We may disclose your data to our affiliated organisations and subsidiaries, and to service providers who provide services to us or you on our behalf. We also may disclose your information if required by law, requested by law enforcement authorities, or to enforce our legal rights, such as pursuant to a subpoena or to HMRC  (for example, if/when claiming Gift Aid). We may share your information in connection with a sale or reorganisation of the Site and/or any associated services, but in any such case, the terms of this Policy will continue to apply.

Our service providers include:

-   banks and payment providers - to authorise and complete payment transactions; and organisations within the payment card industry, for example, to help prevent online fraud;
-   third party identity checking or credit reference agencies - for the purposes of identity checking and bank account verification;
-   IT, information security and cloud services providers – to help us make our Site function properly and keep your data safe;
-   communication providers – to assist us with the processing and delivery of email and other communications;
-   behavioural analytics tools, such as Google Analytics – to collect and help us understand data when you use the Site.

## How long do we keep your data?

We keep data in an identifiable form for as long as we have a legitimate reason to use the data and/or as required by law.

## Children’s data

We do not direct the Site to users under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us at [hello@facedonate.org](mailto:hello@facedonate.org).

## Users located in the UK and European Union

The following provisions apply to our users located in the UK and EU. We are aware, comply and regularly undertake reviews to align our operation with the highest level of data-related regulations, including GDPR, across the EU and the UK.

  

**GDPR Regulation and Compliance**

The Site is aligned with the EU GDPR regulation as it applies all the requirements highlighted as follow:

-   the data subject has given their consent to the processing of their personal data for one or more specific purposes;
-   processing is necessary for the performance of these Terms which define the contract to which the data subject is party, or that are needed in order to register with the Site;
-   processing is necessary for compliance with a legal obligation emerging from the legitimate interests pursued by the Site and to which the Site is subject in order to accept and distribute donations as well as compensating provisions.

For this reason, it is very important that when registering all users clearly identify the acceptance of the terms with a clear visible sign of their acceptance, being this a smile, a wink, a thumbs up or an ok, the sign shall be clearly interpreted by the donor signing the recipient as clear acceptance. There is to highlight the practical difficulty in using the Site’s services without the express and compliant willingness of the users involved.

**On what legal bases do we process your data?**

We process your personal data on a variety of legal bases depending on the use. Sometimes it is necessary to process your data for us to comply with our legal obligations, for example, if/when we send Gift Aid information to HMRC.

**How do we use your data based on our legitimate interests?**

We may process your personal data for the purposes of our legitimate interests, provided that these uses aren’t outweighed by your rights or interests. For any uses we justify on the basis of legitimate interest, you have the right, and shall be offered, to opt out of.

**Are we doing any automated decision making?**

To help our Site function more effectively, we utilise some algorithms that make inferences about some characteristics of the users and the Site, for example, to customise emails to our users.

**Where do we send your data?**

As a globally operating platform, sometime we rely on the third party operated systems. On occasions we may store some users’ personal data outside the UK or EU. If we do, we ensure your data is processed only in countries that provide an adequate level of protection for your data or where the recipient provides appropriate safeguards, such as model contract clauses, binding corporate rules. For a copy of such safeguards, please contact by email us at [hello@facedonate.org](mailto:hello@facedonate.org).
`;

const cookiesPolicy = `
# COOKIES POLICY

## What is a cookie?

A cookie is a small file, typically contains letters and numbers, stored by your internet browser. Cookies help us provide you with a the best experience on our Site and allow us to make the Site more efficient. These small files allow to have bits of information which might be about preferences or devices types and is mostly used to make the Site function as you expect it to. The files do not usually directly identify you, but they can give you a more personalised web experience.

In accordance with the E-Privacy Directive  (May 2012), we’re asking you to consent to the way we use cookies.

## Find out more about cookies

Please use the below internet resources to find out more about cookies:

-   Information Commissioner’s Office  (ICO) website - [ico.org.uk](https://ico.org.uk/);
-   Guidance on the new cookies regulation - [ICO](https://ico.org.uk/media/for-organisations/documents/1545/cookies_guidance.pdf)  [(PDF](https://ico.org.uk/media/for-organisations/documents/1545/cookies_guidance.pdf) [677KB)](https://ico.org.uk/media/for-organisations/documents/1545/cookies_guidance.pdf).

## Changing your cookie settings on our site

Opting out of cookies or not allowing/accepting the optional cookies may limit the features available to you on Site. If this is what you’d like to do, please press ‘Stop accepting cookies’ at the bottom of the page or alternatively email us at [hello@facedonate.org](mailto:hello@facedonate.org) where we would be happy to assist you.

## A few things to note

-   We use cookies to run key processes such as making a donation, and to make our Site easier to use. If you choose not to allow/accept the optional cookies, you may not be able to use certain parts of the Site.
-   You can change your settings at any time visiting this page  ([facedonate.org/legal](https://facedonate.org/legal)), or alternatively by email at [hello@facedonate.org](mailto:hello@facedonate.org) where we would be happy to assist you.
-   If you change your settings, you may want to delete the cookies that were added to your device before you made the change. You can do this using your browser settings.
-   We use a cookie to remember your cookie settings. This means that if you delete all your cookies through your browser, you may have to update your cookie settings again on our Site. You may also need to do this if you use a different computer or smartphone, computer profile or browser to the one you usually use to access the Site.
-   Please note that cookies we use shall originate from the [facedonate.org](https://facedonate.org) domain  (this may help you with identification of the ones relevant to our Site cookies).

## Strictly necessary cookies

These cookies are necessary for the Site to work, and cannot be turned off in our systems. They are usually only set in response to actions made by you, such as:

-   making a request for services/good;
-   making a donation;
-   taking a picture;
-   logging in and signing out of your account.

  

You can set your browser to block or alert you about cookies, but the Site may stop functioning as expected.

## Performance cookies

Performance cookies tell us how well our site performs. They help us to know which pages people like and which parts they never visit. All the data collected is at an aggregated level and therefore is anonymous. Performance cookies are automatically allowed because they are essential for the Site to function and tell us how to improve the Site’s performance.

We use third-party service providers to provide certain performance and analytics services to us in connection with the operation of the Site, including the collection and tracking of certain data and information regarding the characteristics and activities of the users.

  

Performance cookies allow us to:

-   help identify unique visitors and/or devices;
-   monitor the performance of our site;
-   collect information about how users use the Site;
-   trial new features on a small sample of users.

## Personalisation cookies

Personalisation cookies are automatically allowed because the are essential for the Site to function. They are essential to allow you to navigate the Site and to be able to access relevant to you. Personalisation cookies are automatically allowed to enhance your user experience and are essential for the Site to function. When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised experience. Personalisation cookies are essential to allow you to navigate the Site and to be able to access information relevant to you, like signing in and out of your account.

Personalisation cookies allow us to:

-   show you content that is relevant to you;
-   log in and sign out of your account.

## Our contact details

If you have any questions or concerns about the Site's Privacy Policy or data processing, or if you would like to make a complaint about a possible breach of local privacy laws, please contact us directly or reach to a relevant regulator in your jurisdiction.

You are always welcome to contact us by emailing at [hello@facedonate.org](mailto:hello@facedonate.org).

All communications are sought to be examined and replies issued where appropriate as soon as possible. If you are unsatisfied with the reply received, you may refer your complaint to the relevant regulator in your jurisdiction.

We may update the Site's Privacy Policy from time to time. If/when we materially change the policy, a notice may be posted on our Site.
`;

const markdownOptions = {
  overrides: {
    h1: {
      component: Heading,
      props: {
        size: 700,
        marginTop: 24,
        color: "#283655",
      },
    },
    h2: {
      component: Heading,
      props: {
        size: 600,
        marginTop: 12,
        color: "#283655",
      },
    },
    p: {
      component: Paragraph,
      props: {
        marginTop: "default",
      },
    },
    ul: {
      component: UnorderedList,
      props: {
        marginTop: "default",
      },
    },
    li: {
      component: ListItem,
      props: {
        marginTop: "default",
      },
    },
    a: {
      component: A,
      props: {
        className: "blue-link",
      },
    },
  },
};


const Legal = () => {

  return (
    <Pane>
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Markdown
          children={infoSection}
          options={markdownOptions}
        />

        <Markdown
          children={termsBody}
          options={markdownOptions}
        />

        <Markdown
          children={privacyPolicy}
          options={markdownOptions}
        />

        <Markdown
          children={cookiesPolicy}
          options={markdownOptions}
        />
        
        <Pane marginTop={36} >
          <RemoveCookiesConsentButton />
        </Pane>
          
      </Pane>
      <Footer />
    </Pane>
  );
}

export default Legal;