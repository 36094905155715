import { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useParams, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph } from 'evergreen-ui';
import { currencies } from '../services/localeDictionary';

import numeral from 'numeral';

import { useSelector, useDispatch } from 'react-redux';

const FormattedCurrencyNumber = ({
  is = Text,
  number = 0,
  currency,
  removerDecimalZeros = false,
  isShort = false,
  isRound = false,
  fontSize = 30,
  fontColor = "#283655",
  width = null, // keep null as default
  textAlign = null, // keep null as default
  ...rest
}) => {

  // const localeState = useSelector(state => state.localeState);

  number = Math.round((number + Number.EPSILON) * 100) / 100;

  if (isRound) {
    if (number > 1) {
      number = number.toFixed();
    }
    else {
      isRound = false;
    }
  }

  let sign = "";
  let numberInt = numeral(Math.abs(number)).format('0,0', Math.floor);
  if (number < 0) {
    sign = "-";
  }
  let numberDec = numeral(number).format('.00');
  numberDec = numberDec.replace('-', '');
  let numberA = null;
  // console.log("numberDec", numberDec);

  if (isRound || isShort) {
    if (!isShort || number >= 100) {
      numberDec = null;
      numberInt = numeral(number).format('0,0');
    }
    else if (number >= 1000) {
      numberDec = null;
      numberInt = numeral(number).format('0,0.0a');
      numberA = numberInt.replace(/[0-9[\].]/g, ''); // numeric /[0-9]/g , non-numeric /[^0-9\.]+/g
      numberInt = numberInt.replace(numberA, '');
    }
  }

  if (removerDecimalZeros && numberDec === ".00") {
    numberDec = null;
  }

  const CustomTag = textAlign || width ? Pane : is;

  const CustomTagSub = CustomTag === Pane ? Text : Fragment;
    
  return (
    !currency ? null :
    <Fragment>
      <CustomTag alignItems="baseline" textAlign={textAlign} width={width} {...rest} >
        <CustomTagSub>
          <Strong fontSize={fontSize} lineHeight={1} color={fontColor} >
            {sign}
          </Strong>

          {currencies[currency]?.symbolRight ? null
          : <Strong fontSize={fontSize - Math.floor(fontSize * 1 / 3)} lineHeight={1} whiteSpace="nowrap" color={fontColor} >{currencies[currency]?.symbol}</Strong>
          }
          
          <Strong fontSize={fontSize} lineHeight={1} color={fontColor} >
            {numberInt}
          </Strong>

          {numberDec ? 
            <Strong fontSize={fontSize - Math.floor(fontSize * 1 / 3)} lineHeight={1} color={fontColor} >{numberDec}</Strong>
          : null}

          {numberA ? 
            <Strong fontSize={fontSize - Math.floor(fontSize * 1 / 3)} lineHeight={1} color={fontColor} >{numberA}</Strong>
          : null}

          {currencies[currency]?.symbolRight ? 
            <Strong fontSize={fontSize - Math.floor(fontSize * 1 / 3)} lineHeight={1} whiteSpace="nowrap" color={fontColor} >{currencies[currency]?.symbol}</Strong>
          : null}
        </CustomTagSub>
      </CustomTag>
    
    </Fragment>
  );
}

const useFormattedCurrencyNumber = () => {

  // const localeState = useSelector(state => state.localeState);
  
  const handleFormattedCurrencyNumber = (number = 0, currency, isShort = false, isRound = false, removerDecimalZeros = false, ) => {
    
    if (!number) {
      number = 0; // Number.parseFloat(number).toFixed(2)
    }

    number = Math.round((number + Number.EPSILON) * 100) / 100;
    
    if (isRound) {
      if (number > 1) {
        number = number.toFixed();
      }
      else {
        isRound = false;
      }
    }
  
    let sign = "";
    let numberInt = numeral(Math.abs(number)).format('0,0', Math.floor);
    if (number < 0) {
      sign = "-";
    }
    
    let numberDec = numeral(number).format('.00');
    numberDec = numberDec.replace('-', '');
    let numberA = null;

    if (isRound || isShort) {
      if (!isShort || number >= 100) {
        numberDec = null;
        numberInt = numeral(number).format('0,0');
      }
      else if (number >= 1000) {
        numberDec = null;
        numberInt = numeral(number).format('0,0.0a');
        numberA = numberInt.replace(/[0-9[\].]/g, ''); // numeric /[0-9]/g , non-numeric /[^0-9\.]+/g
        numberInt = numberInt.replace(numberA, '');
      }
    }

    if (removerDecimalZeros && numberDec === ".00") {
      numberDec = null;
    }

    let symbolLeft = currencies[currency]?.symbolRight ? "" : currencies[currency]?.symbol;
    let symbolRight = currencies[currency]?.symbolRight ? currencies[currency]?.symbol : "" ;
    numberA = numberA ? numberA : "";
    numberDec = numberDec ? numberDec : "";
    let result = symbolLeft + sign + numberInt + numberDec + numberA + symbolRight;

    return(result);
  }

  return handleFormattedCurrencyNumber;

}


export default FormattedCurrencyNumber;
export { useFormattedCurrencyNumber };