
const initialState = {
	// ... should mirror Transaction
	localFormStep: null,
	localUserOwnPage: null,
	localAgreeToTCs: false,
	localOfferToAuth: false,
	localError: null, // replace by error: ErrorDetails
	localIsProcessing: false,
};

const transactionDetailsReducer = (
	state = initialState,
	action
) => {
	switch(action.type){
		case 'SET_TRANSACTION_DETAILS':
			if (!action.payload) {
				return initialState;
			}
			return {
				...initialState,
				...action.payload
			};
		case 'UPDATE_TRANSACTION_DETAILS':
			if (!action.payload) {
				return initialState;
			}
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export default transactionDetailsReducer;