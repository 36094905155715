import React from 'react';
// covert SVG here - https://react-svgr.com/

const ReceiptPH = (props) => {

  return (
    <svg
      viewBox="0 0 355 449"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M185.69 331.9a9.992 9.992 0 00-9.998 10c0 5.534 4.477 10 9.998 10h106.07a9.992 9.992 0 009.997-10c0-5.536-4.477-10-9.997-10H185.69zM62.708 287.4c-5.48 0-9.984 4.464-9.984 10 0 5.534 4.504 10 9.984 10h229.096c5.48 0 9.985-4.466 9.985-10 0-5.536-4.504-10-9.985-10H62.708zM62.773 238.8a9.985 9.985 0 00-10.02 10c0 5.534 4.459 10 10.02 10h200.024a9.985 9.985 0 0010.02-10c0-5.536-4.458-10-10.02-10H62.773zM62.723 190.3c-5.517 0-10.005 4.464-10.005 10 0 5.534 4.488 10 10.005 10h123.054c5.517 0 10.005-4.466 10.005-10 0-5.536-4.488-10-10.005-10H62.723zM62.78 141.7c-5.513 0-10.012 4.464-10.012 10 0 5.534 4.499 10 10.011 10h166.01c5.513 0 10.011-4.466 10.011-10 0-5.536-4.498-10-10.01-10H62.78zM166.685 93.2a9.992 9.992 0 00-9.994 10c0 5.534 4.479 10 9.994 10h38.078a9.992 9.992 0 009.993-10c0-5.536-4.478-10-9.993-10h-38.078z"
        fill={props.color || "#000000"}
      />
      <path
        d="M1.1 59.1c.3-3.3 1.6-6.4 4.1-8.9L50.9 4.5C53.6 1.8 57.2.4 60.8.4c3.6 0 7.2 1.4 10 4.1l37.8 37.9 37.8-37.9c5.3-5.3 13.8-5.4 19.3-.4.1 0 .1.1.2.1l59.7 41.3 60.1-42.9c6.2-4.5 15-3 19.5 3.3.3.4.6.9.8 1.4l43 42.9c1.9 2 3.2 4.3 3.7 6.8 1.2 2 1.8 4.4 1.8 6.8l-.3 370V434.6c0 .5-.1 1-.2 1.5V436.4c-.5 2.6-1.8 5.2-3.9 7.3-2 2-4.6 3.3-7.2 3.8h-.2v.1h-.1c-.5 0-1 .1-1.5.1l-.1.1H340c-1.4 0-2.7-.3-4-.7-2.1-.6-4-1.8-5.7-3.4l-35.8-35.9-35.8 35.9c-3.3 3.2-7.8 4.5-12 3.9-4.2.6-8.7-.7-11.9-3.9l-37.4-37.4-58.1 40.2c-2.9 2.1-6.4 2.8-9.7 2.4-3.3.4-6.8-.4-9.8-2.5l-56.3-40.1-37.4 37.4c-3 3-7 4.3-10.9 4-.4.1-.8.1-1.2.1-7.8 0-14-6.3-14-14l.3-370c0-1.6.3-3.2.8-4.7zm325.4 8.2l-34.7-34.7-56.3 40.2c-2.9 2.1-6.5 2.9-9.8 2.5-3.3.4-6.8-.4-9.7-2.4l-58.1-40.2L120.5 70c-3.2 3.3-7.7 4.6-11.9 4-4.2.6-8.7-.7-11.9-4L60.8 34.2 28.3 66.7 28 402.2l21.3-21.3c.2-.4.5-.9.8-1.3 4.5-6.3 13.3-7.8 19.6-3.3l60 42.8 59.7-41.3c.1 0 .1 0 .2-.1 5.5-5 14-4.8 19.3.5l37.8 37.8 37.8-37.8c2.8-2.7 6.4-4.1 10-4.1 3.6 0 7.2 1.4 9.9 4.1l21.8 21.8.3-332.7z"
        fill={props.color || "#000000"}
      />
    </svg>
  )
}

export default ReceiptPH;