
import { Fragment, cloneElement, useMemo, useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, Dialog, Tooltip, InfoSignIcon, WidgetButtonIcon, EditIcon, Radio, Checkbox, ChevronLeftIcon, PlusIcon, MinusIcon, CameraIcon, KeyDeleteIcon, SidebarTab, CrossIcon, TickIcon, IconButton, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';
import FacePH from '../images/FacePH';
import ReceiptPH from '../images/ReceiptPH';
import DialogHeader from './DialogHeader';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';


import { triggerBase64Download } from 'react-base64-downloader';
import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PickCurrency from './PickCurrency';
import Repeatable from 'react-repeatable';
import { useLongPress } from 'use-long-press';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails, setDialogState,updateDialogState } from '../services/actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { useDropzone } from 'react-dropzone';
import styled, { StyledFunction } from "styled-components";
import { GridContextProvider, GridDropZone, GridItem, swap, move } from "react-grid-dnd";
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import CountUp, { useCountUp } from 'react-countup';

import useHover from "../services/useHover";
import useAccessTransaction from "../services/useAccessTransaction";
import useUploadImage from "../services/useUploadImage";

import { currencies } from '../services/localeDictionary';

import { I18n } from 'aws-amplify';


const StyledDraggableContainer = styled.div`
  /* used for positioning the after content */
  position: relative;
  height: 100%;
  display: inline-flex;
  width: 100%
  /* stylelint-disable  comment-empty-line-before */
  /* add little portal indicator when in a portal */
  ${(props) =>
    props.inPortal ?
    `
      ::after {
        position: absolute;
        background: lightgreen;
        bottom: 0;
        right: 0;
        content: "in portal";
      }
    `
  : ''}/* stylelint-enable */;
`;

const DraggableCard = ({ item, index }) => {
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx

  const portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');

  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }

  document.body.appendChild(portal); // this needs to be removed ?

  return (
    <Draggable draggableId={item.id} index={index} >
      
      {(provided, snapshot) => {
        const usePortal = snapshot.isDragging;

        const child = (
          <StyledDraggableContainer ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            inPortal={snapshot.isDragging}
          >
            <Pane minWidth={60} >
              <AspectRatio ratio={1/1} >
                {item.src ?
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                    <img src={item.src} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit"}}/>
                  </Pane>
                :
                  <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                  border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                    <FacePH color="#7B8B9A" width="100%" height="100%" />
                  </Pane>
                }
              </AspectRatio>
            </Pane>
          </StyledDraggableContainer>
        )

        if (!usePortal) {
          return child;
        }
        return ReactDOM.createPortal(child, portal);
      }}
    </Draggable>
  );
}

const DraggableList = ({ listItems }) => {
  return listItems.map((item, index) => (
    <DraggableCard item={item} index={index} key={item.id} />
  ));
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const FeedbackPicturesInput = ({
  values = [],
  onChange = () => {},
  disabled = false,
  hideBrowseButton = false,
  browseButtonText = I18n.get('Browse'),
  hideLargePreview = false, // remove LargePreview as not being used ???
  ...rest
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`FeedbackPicturesInput - ${countRef.current}`);
    return () => {
      console.log("FeedbackPicturesInput - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const transactionDetails = useSelector(state => state.transactionDetails);
  const dialogState = useSelector(state => state.dialogState);
  const uploadState = useSelector(state => state.uploadState);

  const [processing, setProcessing] = useState(false);
  const [processingPicture, setProcessingPicture] = useState(false);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleUploadImage = useUploadImage();

  const imageInputRef = useRef(null);
  const imageInputNativeCameraRef = useRef(null);

  // const initialListItems = Array.from({ length: 6 }, (v, k) => k).map(k => {
  //   const custom = {
  //     id: `id-${k}`,
  //   };
  //   return custom;
  // });

  // const initialListItems = [];
  // const [listItemsState, setListItemsState] = useState({ listItems: initialListItems });

  const gridItemRef = useRef(null);

  const [dndGridRowHight, setDndGridRowHight] = useState(80); // !!! this doesn't work on resizing the screen, needs fixing ???

  const [dndGridRows, setDndGridRows] = useState(1);
  const [dndGridColumns, setDndGridColumns] = useState(4);
  const [dndGridHeight, setDndGridHeight] = useState(`${dndGridRowHight*dndGridRows}px`);

  const maxNumberOfImages = 8;
  const [items, setItems] = useState({
    // "leftId": transactionDetails.proofImages || [],
    "leftId": values || [],
    "removed": [],
    // "leftId": [],
    // right: []
  });

  const handleAddNewPicture = useCallback(({ key, imageDataURL }) => {
    let newItems = [...items?.leftId || [], {
      key: key,
      src: imageDataURL,
      orderIndex: items?.leftId?.length || 0,
    }];
    setItems((current) => (
      {
        ...current,
        "leftId": newItems || [],
      }
    ));
    onChange(newItems);
  }, [items?.leftId, onChange]);
  

  const handleOnImageSelect = useCallback(async(event) => {
    let imageFile = event.target.files[0];
    
    if (imageFile) {
      setProcessingPicture(true);
      // console.log("imageFile", imageFile);
      let randomPart = Math.floor(Math.random() * 100000); // 5-digit long
      let fileName = imageFile.name; // the original file name
      fileName = Math.round(new Date().getTime()/1000) + "-" + randomPart + "-" + userDetails?.publicFaceId;
      const key = fileName + ".png";
      
      function getFileExtension(filename) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0].toLowerCase().trim() : undefined;
      }
      const fileExtension = getFileExtension(imageFile.name);
      // console.log("fileExtension", fileExtension);
      if (fileExtension !== "png" && fileExtension !== "jpeg" && fileExtension !== "jpg") {
        console.error('bad file extension', imageFile);
        // handleError
        return; // ???
      }
      
      let imageDataURL = await toBase64(imageFile).catch(e => Error(e));
      if(imageDataURL instanceof Error) {
          // handle error
          console.error('Error with imageDataURL: ', imageDataURL.message);
          return null;
      }

      // check imageFile.size to limit by size ?

      // let newListOfPictures = items["leftId"];
      // newListOfPictures.push({
      //   key: key,
      //   src: imageDataURL,
      // });

      // setItems((current) => ({
      //   ...current,
      //   "leftId": newListOfPictures,
      // }));

      handleAddNewPicture({ key, imageDataURL });

      setProcessingPicture(false);
      
      handleUploadImage({
        imageDataURL: imageDataURL,
        key: key,
        resize: false,
      });
      
    }
    else {
      console.log("no file was selected"); // doesn't work
    }
    
  }, [handleAddNewPicture, handleUploadImage, userDetails?.publicFaceId]);

  // const generateProofImagesArray = () => {
  //   let proofImagesArray = items["leftId"].map((item, index, array) => ({
  //       ...item,
  //       orderIndex: index,
  //   }));
    
  //   return proofImagesArray;
  // }

  // const reorderListItems = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);
  //   return result;
  // };

  // const onDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }
  //   if (result.destination.index === result.source.index) {
  //     return;
  //   }
  //   const listItems = reorderListItems(
  //     listItemsState.listItems,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   setListItemsState({ listItems });
  // }


  // const onDrop = useCallback(acceptedFiles => {
  //   // Do something with the files
  //   console.log("A FILE IS DROPPED");
  // }, []);
  // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});


  useEffect(() => {
    let currentRows = items["leftId"].length > 0 ? 1 : 0;
    if (items["leftId"].length > dndGridColumns) {
      currentRows = Math.ceil(items["leftId"].length / dndGridColumns);
      if (currentRows === 0) {
        currentRows++;
      }
    }
    setDndGridRows(currentRows);
    setDndGridRowHight(gridItemRef?.current?.clientHeight < 80 ? 80 : gridItemRef?.current?.clientHeight); // !!! doesn't work on screen resizing
    setDndGridHeight(`${dndGridRowHight*currentRows}px`);
  }, [dndGridColumns, dndGridRowHight, items]);

  const onDndGridChange = useCallback((sourceId, sourceIndex, targetIndex, targetId) => {
    if (isCurrent.current) {
      if (targetId) {
        const result = move(
          items[sourceId],
          items[targetId],
          sourceIndex,
          targetIndex
        );
        setItems((current) => ({
          ...current,
          [sourceId]: result[0],
          [targetId]: result[1]
        }));
      }
      else {
        const result = swap(items[sourceId], sourceIndex, targetIndex);
        setItems((current) => ({
          ...current,
          [sourceId]: result
        }));
        onChange(result?.map((p, i) => ({
          key: p.key,
          orderIndex: i,
          src: p.src,
        })) || []);
        
      }
    }
  }, [items, onChange]);

  const removeImageFromArray = useCallback((sourceIndex = 0) => {
    const result = move(items["leftId"], items["removed"], sourceIndex, items["removed"].length);
    let newListOfPictures = result[0];
    setItems((current) => ({
      ...current,
      "leftId": newListOfPictures,
      "removed": result[1],
    }));
    onChange(newListOfPictures?.map((p, i) => ({
      key: p.key,
      orderIndex: i,
      src: p.src,
    })) || []);
  }, [items, onChange]);
  
  const GridItemHolder = useCallback(({ gridItemRef, item, sourceIndex }) => {

    return (
      <Pane padding={5} boxSizing="border-box" ref={gridItemRef}
        onClick={() => {
          console.log("onClick...", sourceIndex);
        }}
      >
        <AspectRatio ratio={1/1} >
          {item.src ?
            <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto" background="#FFFFFF"
              border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
              <img src={item.src} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit",}}
                onDragStart={(e) => {e.preventDefault();}}
              />
              <Pane style={{position: "absolute", top: 2, left: "auto", right: 2, }} >
                <IconButton height={20} icon={CrossIcon} iconSize={14} appearance="minimal"
                  onClick={(event) => {
                    if (!processingPicture) {
                      event.stopPropagation();
                      event.preventDefault();
                      removeImageFromArray(sourceIndex);
                    }
                  }}
                />
              </Pane>
            </Pane> 
          :
            <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto" background="#FFFFFF"
            border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
              <FacePH color="#7B8B9A" width="100%" height="100%" />
            </Pane>
          }
        </AspectRatio>
      </Pane>
    );
  }, [processingPicture, removeImageFromArray]);

  const handleGoToTakePicture = useCallback(() => {

    if (!processingPicture) {
      dispatch(setDialogState({
        componentName: "TakePicture",
        params: {
          localCameraIsUserFacing: false
        },
      }));
    }
  }, [dispatch, processingPicture]);

  useEffect(() => {
    if (dialogState.pictureB64ToProgress) {
      let randomPart = Math.floor(Math.random() * 100000); // 5-digit long
      const fileName = Math.round(new Date().getTime()/1000) + "-" + randomPart + "-" + userDetails.publicFaceId || "unknown";
      const key = fileName + ".png";

      handleAddNewPicture({
        key,
        imageDataURL: dialogState.pictureB64ToProgress
      });

      dispatch(setDialogState(null)); // this will close the dialog and clear pictureB64ToProgress

      handleUploadImage({
        imageDataURL: dialogState.pictureB64ToProgress,
        key: key,
        resize: false,
      });
    }
  }, [dialogState.pictureB64ToProgress, dispatch, handleAddNewPicture, handleUploadImage, userDetails.publicFaceId]);
  

  return (
    <Fragment>
      {/* <Pane marginTop={10} alignItems="center" display="flex" gap={10} flexWrap="wrap" >
        {imagesBase64.map((item, index, array) => (
          <Pane key={index} flex={1} maxWidth={190} marginLeft="auto" marginRight="auto" >
            <AspectRatio ratio={1/1} >
              {item ?
                <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                  <img src={item} alt="" style={{"width": "100%", "height": "100%", "objectFit": "cover", "borderRadius": "inherit",}}/>
                </Pane>
              :
                <Pane position="absolute" zIndex={1} width="100%" height="100%" marginLeft="auto" marginRight="auto"
                border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={10} >
                  <FacePH color="#7B8B9A" width="100%" height="100%" />
                </Pane>
              }
            </AspectRatio>
          </Pane>
        ))}
      </Pane> */}


      {/* {setListItemsState.length > 0 ?
        <Pane>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list" direction="horizontal" >
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{"display": "flex", "overflow": "auto", "gap": "10px"}} className="hide-scrollbar" >
                  <DraggableList listItems={listItemsState.listItems} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Pane>
      : null } */}


      {hideLargePreview ? null :
        <Pane marginTop={10} marginX={"15%"} border borderWidth={1} borderStyle="solid" borderColor="#E4E7EB" borderRadius={5} >
          {items["leftId"].length > 0 ? 
            <AspectRatio ratio={1/1} >
              <img src={items["leftId"][0].src} alt="" style={{"width": "100%", "height": "100%", "objectFit": "scale-down", "borderRadius": "inherit",}}/>
              <Pane style={{position: "absolute", top: 4, left: "auto", right: 4, }} >
                <IconButton height={20} icon={CrossIcon} iconSize={16} appearance="minimal"
                  onClick={(event) => {
                    if (!processingPicture) {
                      event.stopPropagation();
                      event.preventDefault();
                      removeImageFromArray(0);
                    }
                  }}
                />
              </Pane>
            </AspectRatio>
          :
            <Fragment>
              <AspectRatio ratio={1/1} >
                <Pane position="absolute" zIndex={1} width="100%" height="100%" paddingY={50} paddingX={36} marginLeft="auto" marginRight="auto" >
                  <ReceiptPH baseColor="#E4E7EB" width="100%" height="100%" />
                </Pane>
              </AspectRatio>
            </Fragment>
          }
        </Pane>
      }

      {hideBrowseButton || hideLargePreview || items["leftId"].length > 1 ?

        <Pane marginTop={4}>

          <GridContextProvider onChange={onDndGridChange}>
            <div
              style={{
                display: "flex",
                touchAction: "none",
              }}
            >
              <GridDropZone
                style={{
                  height: dndGridHeight, // "auto", // dndGridHeight
                  flex: 1,
                  position: "inherit",
                  display: "flex",
                  // flexBasis: "160px",
                  // border: "1px solid red"
                }}
                id="leftId" // seems needed
                
                boxesPerRow={dndGridColumns}
                rowHeight={dndGridRowHight}
              >
                {items["leftId"].map((item, index) => (
                  <GridItem key={item.key} >
                    <GridItemHolder item={item} sourceIndex={index} gridItemRef={gridItemRef} />
                  </GridItem>
                ))}
              </GridDropZone>

              {/* <GridDropZone
                style={{ height: "200px", flex: 1, flexBasis: "200px", border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "1rem" }}
                id="right"
                boxesPerRow={4}
                rowHeight={70}
              >
                {items.right.map(item => (
                  <GridItem key={item.name}>
                    <div style={{ padding: "10px", width: "100%", height: "100%", boxSizing: "border-box" }}>
                      <div style={{width: "50px", height: "50px", boxSizing: "border-box", background: "#08e", display: "flex", justifyContent: "center", color: "white", fontFamily: "Arial, Helvetica, sans-serif", alignItems: "center", borderRadius: "50%"}}>
                        {item.name[0]}
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone> */}
              
            </div>
          </GridContextProvider>
        </Pane>

      : null }

      {/* {hideBrowseButton ? 
        <Fragment >

          <Button marginTop={10} fontSize={16} paddingX={8} height={50} width={"100%"} justifyContent="center" className='button-blue'
            disabled={disabled}
            onClick={handleGoToTakePicture}
          >
            <Pane display="inline-flex" marginRight={10} >
              <CameraIcon color={"#FFFFFF"} size={24} />
            </Pane>
            {I18n.get('Take picture')}
          </Button>

          {items["leftId"].length >= maxNumberOfImages ?
            <Pane textAlign="center" marginTop={10} className="noselect" >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("Maximum number of pictures is added.")}</Text>
            </Pane>
          :
            null
          }
        </Fragment>
      : null } */}

      {/* // ??? */}
      {Object.keys(uploadState.progress)?.length > 0 ? "Uploading image..." : null}
      {/* {JSON.stringify(uploadState.progress)} */}

      {hideBrowseButton ?
        <Fragment >

          <input
            ref={imageInputNativeCameraRef}
            style={{
              display: "none",
              // height: "0px" // this makes it visible on iOS
            }}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={(event) => {
              // console.log("onChange event", event);
              handleOnImageSelect(event);
              let selectedFile = event.target.files[0];
              imageInputNativeCameraRef.current.value = null;
              if (selectedFile) {
                console.log("selectedFile", selectedFile);
              }
              else {
                console.warn("No file was selected"); // doesn't work ???
              }
            }}
            // onInput={(event) => {
            //   console.log("onInput event", event);
            // }}
            // onBlur={(event) => {
            //   console.log("onBlur event", event);
            //   let selectedFile = event.target.files[0];
            //   if (!selectedFile) {
            //     console.warn("No file was selected..."); // ???
            //     // imageInputNativeCameraRef.current.blur();
            //   }
            // }}
            // onFocus={(event) => {
            //   console.log("onFocus event", event);
            // }}
          />

          <Button marginTop={10} fontSize={16} paddingX={8} height={50} width={"100%"} justifyContent="center" className='button-blue'
            disabled={disabled}
            onClick={(event) => {
              // console.log("onClick event", event);
              event.stopPropagation();
              event.preventDefault();
              // imageInputNativeCameraRef.current.addEventListener("focus", (event) => {
              //   console.log("input event", event);
              // });
              // imageInputNativeCameraRef.current.focus();
              imageInputNativeCameraRef.current.click();
            }}
          >
            {/* <Pane display="inline-flex" marginRight={10} >
              <CameraIcon color={"#FFFFFF"} size={24} />
            </Pane> */}
            {I18n.get('Take picture')}
          </Button>

          {items["leftId"].length >= maxNumberOfImages ?
            <Pane textAlign="center" marginTop={10} className="noselect" >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("Maximum number of pictures is added.")}</Text>
            </Pane>
          :
            null
          }

        </Fragment>
      : null }


      {/* <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div> */}

      {hideBrowseButton || items["leftId"].length >= maxNumberOfImages ? null :
        <Fragment>
          <Pane marginTop={10} >

            <input
              ref={imageInputRef}
              type="file"
              accept="image/png, image/jpeg" // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
              style={{
                display: 'none'
              }}
              // onClick={(event) => {}}
              onChange={(event) => {
                handleOnImageSelect(event);
                imageInputRef.current.value = null;
              }}
            />  
            
            <Pane alignItems="center" height={40} display="flex" flexWrap="wrap">
              <Pane width={40} textAlign="left" flex="none" >
              </Pane>
              <Pane textAlign="center" flex={1} width="100%" height="auto" >
                <Button fontSize={16} paddingX={24} height={40} minWidth={140} justifyContent="center" 
                  onClick={(event) => {
                    if (!processingPicture) {
                      event.stopPropagation();
                      event.preventDefault();
                      imageInputRef.current.click();
                    }
                  }}
                >
                  {browseButtonText}
                </Button>
              </Pane>
              
              <Pane width={40} textAlign="right" flex="none" >
                <IconButton width={40} height={40} marginLeft="auto" marginRight="auto" icon={CameraIcon} iconSize={24}
                  onClick={handleGoToTakePicture}
                />

              </Pane>
            </Pane>

          </Pane>

          {items["leftId"].length >= maxNumberOfImages ?
            <Pane textAlign="center" marginTop={10} className="noselect" >
              <Text fontSize={14} color="#7B8B9A" >{I18n.get("Maximum number of pictures is added.")}</Text>
            </Pane>
          :
            null
          } 
        </Fragment>
      }

      

    </Fragment>
  );
}

export default FeedbackPicturesInput;