import React from 'react';
// covert SVG here - https://react-svgr.com/

const YoutubeIcon = (props) => {
  // fill={props.color || "#000000"}

  return (
    <svg
      viewBox="0 0 513 359"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M501.451 56.093c-5.902-21.933-23.195-39.222-45.125-45.128C416.26 0 255.995 0 255.995 0S95.734 0 55.668 10.547C34.16 16.45 16.445 34.164 10.543 56.093 0 96.156 0 179.241 0 179.241s0 83.504 10.543 123.148c5.906 21.93 23.195 39.223 45.129 45.13 40.484 10.964 200.327 10.964 200.327 10.964s160.261 0 200.327-10.547c21.934-5.903 39.223-23.195 45.129-45.125 10.543-40.066 10.543-123.148 10.543-123.148s.422-83.507-10.547-123.57zm-296.483 46.391V256l133.269-76.758-133.269-76.757z"
        fill={props.color || "#000000"}
      />
    </svg>
  );
}

export default YoutubeIcon;