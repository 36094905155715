import { Fragment, useEffect, useState, useRef, useMemo, useCallback, useContext } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, useParams, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, Checkbox, SidebarTab, IconButton, RepeatIcon, UpdatedIcon, ArrowTopRightIcon, Table, TagInput, Radio, RadioGroup, Spinner } from 'evergreen-ui';
import _ from 'lodash';
import styled from "styled-components";

import { NumericFormat } from 'react-number-format';
import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";

import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';
import { PatternFormat } from 'react-number-format';
import DynamicRadioButton from './DynamicRadioButton';
import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';
import PickCurrency from './PickCurrency';
import FacePH from '../images/FacePH';
import CurrencyNumberInput from './CurrencyNumberInput';
import { Textfit } from 'react-textfit';

import DynamicTickBox from './DynamicTickBox';
import DialogHeader from './DialogHeader';
import ModalResponsive from './ModalResponsive';

import { useGetTransactions } from "../services/useGetTransactions";

import { useGetUserDetails, useAuthUpdateUserDetails } from "../services/useAmplifyAuth";
import { useSelector, useDispatch, batch } from 'react-redux';
import {updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import { useAssignMatchFundingTransactionsToFunders } from "../services/useMatchFundTransactions";

import { API, I18n} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

const ResponsivePane = styled(Pane)`
  text-align: right;
  @media only screen and (max-width: ${425}px) {
    text-align: left;
  }
`;

const ToggleSection = ({toggleParams, onToggle, isProcessing, ...rest}) => {

  const [localProcessing, setLocalProcessing] = useState(false);

  const toggleOptions = useMemo(() => (toggleParams.options || [
    { label: I18n.get('Off'), value: false},
    { label: I18n.get('On'), value: true},
  ]), [toggleParams.options]);

  const handleToggleChange = (event) => {
    let tempValue = event.target.value;
    if (tempValue && typeof tempValue === "string") {
      if (tempValue.toLowerCase() === "true") {
        tempValue = true;
      }
      else if (tempValue.toLowerCase() === "false") {
        tempValue = false;
      }
    }
    onToggle(tempValue);
  }

  return(
    <Fragment>
      <Pane alignItems="center" display="flex" marginTop={0} marginBottom={10} >
        <Strong display="contents" flex="none" fontSize={16} color="#283655">{I18n.get(toggleParams.title)}</Strong>
        {localProcessing || isProcessing ?
          <Spinner marginLeft={8} flex="none" size={20} />
        : null }
      </Pane>
      <Pane marginBottom={10} >

        <Pane aria-label={`Radio Group ${toggleParams.key}`} role={"group"} >
          {toggleOptions.map((option, index) => (
            <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
              <Radio size={18} flex="none" marginLeft={8} marginRight={8} marginTop={0} marginBottom={0}
                // name={null} // do not use, results in odd behaviour
                disabled={localProcessing || isProcessing}
                checked={option.value === toggleParams.value}
                value={option.value}
                onChange={handleToggleChange}
              />
              <Pane flex={1} marginTop={2} >
                <Text fontSize={14} color="#425A70">{option.label}</Text>
              </Pane>
            </Pane>
          ))}
          
        </Pane>

        {!toggleParams.description ? null :
          <Pane marginTop={8} >
            <Text fontSize={14} color="#7B8B9A" >{toggleParams.description}</Text>
          </Pane>
        }
      </Pane>
      
    </Fragment>
  );
}

const AccountAdminPayouts = () => {
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(true);
  const [localLoadState, setLocalLoadState] = useState({
    total: null,
    nextToken: null,
    error: null,
  });
  const [transactionList, setTransactionList] = useState([]);

  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };
  
  useEffect(() => {
    if (userState.actAsUser?.publicFaceId) {
      setTransactionList([]);
      setTriggerLoad(true);
    }
  }, [userState.actAsUser?.publicFaceId]);

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        fromPublicFaceId: null,
        type: "payout",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          console.log("payout transactionList", [...transactionList, ...searchResult.results]);
          setTransactionList((prev) => ([...prev, ...searchResult.results]));
          setLocalLoadState({
            ...localLoadState,
            total: searchResult.total,
            nextToken: transactionList.length === localLoadState.total ? null : searchResult.nextToken,
            error: null,
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [transactionList, handleGetTransactions, localLoadState, localeState.currency, localeState.locale, triggerLoad, userDetails?.publicFaceId]);

  const [addNewPayout, setAddNewPayout] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [payoutToPublicFaceId, setPayoutToPublicFaceId] = useState();
  const [payoutAmount, setPayoutAmount] = useState();
  const [doNotNotify, setDoNotNotify] = useState(false);

  const handleCreatePayout = async() => {

    setProcessing(true);

    try {
      const response = await API.graphql({
        query: mutations.createPayout,
        variables: {
          toPublicFaceId: payoutToPublicFaceId,
          amount: payoutAmount,
          currency: localeState.currency,
          notify: !doNotNotify,
        },
        authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });

      let newTransaction = response.data.createPayout;

      console.log("new payout", newTransaction);
      setTransactionList((prev) => ([...prev, newTransaction]));
      setLocalLoadState({
        ...localLoadState,
        total: localLoadState.total + 1,
      });

      setAddNewPayout(false);
      setProcessing(false);

    }
    catch(error) {
      console.error("within createPayout", error);
      // handle ???
      setProcessing(false);
    }
  };

  return(
    <Fragment>
    
      <Pane alignItems="center" display="flex" marginTop={20} minHeight={24} >
        <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Payouts')}</Strong>
        {processing ?
          <Spinner marginLeft={8} flex="none" size={24} />
        : null }
        {addNewPayout ? null :
          <Button flex="none" marginLeft={"auto"} marginRight={0} fontSize={14} paddingX={8} height={26} textAlign="center" justifyContent="center" className="noselect"
            disabled={processing}
            onClick={() => {
              setAddNewPayout(true);
            }}
          >
            {I18n.get('Create new payout')}
          </Button>
        }
      </Pane>

      {!addNewPayout ? null :
        <Fragment>
          <Pane height={20} ></Pane>

          <DynamicInputField
            label={I18n.get('To publicFaceId')}
            // inputName="charity-number"
            type="text"
            disabled={processing}
            placeholder={`${I18n.get('publicFaceId')}...`}
            value={payoutToPublicFaceId}
            onChange={(value) => {
              if (value) {
                let temp = value.trim();
                if (temp.length > 30) {
                  // setAccountCharityNoInvalidMessage(I18n.get('Too long.'));
                }
                else {
                  // setAccountCharityNoInvalidMessage();
                }
              }
              setPayoutToPublicFaceId(value);
            }}
            onBlur={(event) => {}}
            // messageWhenInvalid={""}
          />



          <Fragment>
            <Pane marginBottom={20} alignItems="baseline" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} >
                <Strong fontWeight={500} fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Amount')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <CurrencyNumberInput
                  disabled={processing}
                  placeholder={`${I18n.get('Amount')}...`}
                  value={payoutAmount}
                  currency={localeState.currency}
                  onChange={(values) => {
                    let temp = values.value;
                    let newAmount = Math.round((Number(temp) + Number.EPSILON) * 100) / 100;
                    setPayoutAmount(newAmount);
                  }}
                />
              </Pane>
            </Pane>
          </Fragment>

          <Fragment>
            <Pane marginBottom={10} alignItems="flex-start" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
                <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Notification')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
                <Pane maxWidth={380} alignItems="center" display="flex" >
                  <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
                    <Checkbox size={18} flex="none" marginLeft={0} marginRight={8} marginTop={0} marginBottom={0}
                      label={""}
                      disabled={processing}
                      checked={doNotNotify}
                      onChange={(e) => {
                        setDoNotNotify(e.target.checked)
                      }}
                    />
                    <Pane flex={1} marginTop={2} >
                      <Text fontSize={14} color="#283655">{I18n.get("Do not notify of this payout")}</Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane marginTop={2} >
                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("'Do not notify' is to be used when creating payouts retrospectively")}</Text>
                </Pane>
              </Pane>
            </Pane>
          </Fragment>

          <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        
            <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

            <Pane flex={1} flexBasis={242} maxWidth={390} >
              {processing ?
                <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
                  <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
                </Pane>
              :
                <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap">
                  <Pane flex="none" marginRight={36} >
                    <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                      disabled={processing}
                      onClick={() => {
                        handleCreatePayout();
                      }}
                    >
                      {I18n.get('Create')}
                    </Button>
                  </Pane>

                </Pane>
              }
            </Pane>
          </Pane>
        </Fragment>
      }


      <Table marginTop={8} is={"table"} width={"100%"} >

        <Table.Head is={"thead"} height={"auto"} style={{border: "none"}} >
        
          <Table.Row is={"tr"} width={"100%"} style={{border: "none"}} >
            {/* <Table.SearchHeaderCell /> */}

            <Table.TextHeaderCell is={"th"} textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
              }}
              paddingX={5}
              flex="none"
              width={26}
            >{I18n.get("???")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"} textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("Date")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              flex="none"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
              }}
              padding={0}
            >{I18n.get("Time")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("To")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="left"
              flex="none"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
                width: 0,
              }}
              padding={0}
            >{I18n.get("username")}</Table.TextHeaderCell>

            <Table.TextHeaderCell is={"th"}
              textAlign="right"
              textProps={{
                color: "#283655",
                fontSize: 16,
                lineHeight: 1,
              }}
              paddingX={5}
            >{I18n.get("Amount")}</Table.TextHeaderCell>
            
            <Table.TextHeaderCell is={"th"}
              flex="none"
              textAlign="right"
              textProps={{
                color: "#283655",
                fontSize: 0,
                lineHeight: 1,
                width: 0,
              }}
              padding={0}
            >{
              I18n.get("Action")
            }</Table.TextHeaderCell>

          </Table.Row>
        </Table.Head>

        {!localLoadState.total ?
          localLoadState.total === 0 ?
            <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
              <Text fontSize={14} color="#7B8B9A">{I18n.get("No history")}</Text>
            </Pane>
          :
            <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
              <Spinner size={24} />
            </Pane>
        :
          <InfiniteScroll
            dataLength={transactionList.length}
            next={fetchMoreData}
            hasMore={transactionList.length < localLoadState.total}
            scrollThreshold={0.6}
            // scrollableTarget="scrollableDiv"
            height={240}
            // loader={<h4>Loading...</h4>}
          >
            <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
              {transactionList.map((transaction) => (
                <Table.Row is={"tr"} width={"100%"} key={transaction.publicTransactionId}
                  // isSelectable
                  // onSelect={() => alert(transaction.name)}
                >

                  <Table.TextCell is={"td"} textAlign="left"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1,
                    }}
                    flex="none"
                    paddingX={5}
                  >{
                    <Fragment>
                      <Text height={0} display="flex" fontSize={0} color="#7B8B9A">{I18n.get(transaction.frequency)}</Text>
                      <Pane display="flex" alignItems="center" >
                        {transaction.frequency === "monthly" ? <UpdatedIcon size={16} /> : <ArrowTopRightIcon size={16} />}
                      </Pane>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell is={"td"} textAlign="left"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1.1,
                    }}
                    paddingX={5}
                  >{
                    <Fragment>
                      <Strong fontSize={16} color="#283655">{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")}</Strong>
                      <Table.TextCell is={"td"}
                        textAlign="left"
                        textProps={{
                          color: "#7B8B9A",
                          fontSize: 14,
                          lineHeight: 1.3,
                        }}
                        paddingX={0}
                      >{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")}</Table.TextCell>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell is={"td"} textAlign="left"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1.1,
                    }}
                    paddingX={5}
                  >{
                    <Fragment>
                      <Strong fontSize={16} color="#283655">{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                      <Table.TextCell is={"td"}
                        textAlign="left"
                        textProps={{
                          color: "#7B8B9A",
                          fontSize: 14,
                          lineHeight: 1.3,
                        }}
                        paddingX={0}
                      >{transaction.toPublicFaceId}</Table.TextCell>
                    </Fragment>
                  }</Table.TextCell>

                  <Table.TextCell is={"td"} textAlign="right"
                    textProps={{
                      color: "#283655",
                      fontSize: 16,
                      lineHeight: 1.1,
                    }}
                    paddingX={5}
                  >{
                    <Fragment>

                      <Strong fontSize={16} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>

                    </Fragment>

                  }</Table.TextCell>

                </Table.Row>
              ))}
              {transactionList.length < localLoadState.total ?
                <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                  <Spinner size={24} />
                </Pane>
              : null }
            </Table.Body>
          </InfiniteScroll>
        }

        <Table.Head is={"tfoot"}
          height={"auto"}
          style={{
            border: "none",
            background: "none",
          }}
        >
        
          {/* <Pane textAlign="center" marginTop={16} className="noselect" >
            <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
              disabled={false}
              onClick={() => {
                console.log('Load more...');
                console.log("localLoadState", localLoadState);
                setTriggerLoad(true);
              }}
            >
              {`${I18n.get('Load more')}...`}
            </Button>
          </Pane> */}

          <Pane paddingY={5} >
            <Text fontSize={14} color="#7B8B9A">{`${transactionList.length}/${localLoadState.total} results`}</Text>
          </Pane>
        </Table.Head>

      </Table>
    </Fragment>
    
  );
}

const AccountAdminMatchFundingRequests = () => {
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  
  const handleGetTransactions = useGetTransactions();

  const handleAssignMatchFundingTransactionsToFunders = useAssignMatchFundingTransactionsToFunders();

  const [triggerLoad, setTriggerLoad] = useState(true);
  const [localLoadState, setLocalLoadState] = useState({
    result: null,
    total: null,
    nextToken: null,
    error: null,
  });

  const [processing, setProcessing] = useState(false);
  const [processingList, setProcessingList] = useState([]);

  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };
  
  useEffect(() => {
    if (userState.actAsUser?.publicFaceId) {
      setLocalLoadState({
        result: null,
        total: null,
        nextToken: null,
        error: null,
      });
      setTriggerLoad(true);
    }
  }, [userState.actAsUser?.publicFaceId]);

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        toPublicFaceId: null,
        fromPublicFaceId: null,
        viaPublicFaceId: null,
        type: "matchFunding",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          setLocalLoadState((prev) => {
            let updatedResultsList = [...prev?.results || [], ...searchResult.results];

            let updatedLocalLoadState = {
              ...prev,
              results: updatedResultsList,
              total: searchResult.total,
              nextToken: updatedResultsList?.length >= searchResult.total ? null : searchResult.nextToken,
              error: null,
            };

            return updatedLocalLoadState;
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [handleGetTransactions, localLoadState, localeState.currency, localeState.locale, triggerLoad]);

  const handleReassignOneTransaction = (event, transaction) => {
    event.stopPropagation();
    
    // setProcessing(true);

    setProcessingList((prev) => {
      let temp = [...prev || [], transaction.publicTransactionId]; // spreading is important
      return temp;
    });

    // console.log("processingList", processingList);

    handleAssignMatchFundingTransactionsToFunders({
      transactionsToProcess: [{
        publicTransactionId: transaction.publicTransactionId,
      }],
      onSuccess: (transactionSearchResult) => {
        
        // add some animation here ???

        console.log("transactionSearchResult", transactionSearchResult);

        // adjust localLoadState.results
        let currentResults = localLoadState.results;

        for (let i = 0; i < transactionSearchResult?.results?.length; i++) {
          const updatedTransaction = transactionSearchResult?.results[i];

          if (updatedTransaction.status === "succeeded") {
            _.remove(currentResults, (o) => {
              return o.publicTransactionId === updatedTransaction.publicTransactionId;
            });
          }
          else {
            const tempIndex = _.findIndex(localLoadState.results, { 'publicTransactionId': updatedTransaction.publicTransactionId });
            const transactionToAdjust = currentResults[tempIndex];
            // ??? what to do here ???
          }

        }

        setLocalLoadState((prev) => {
          return ({
            ...prev,
            result: currentResults,
            total: currentResults.length
          });
        });

        setProcessingList((prev) => {
          let temp = [...prev || []]; // spreading is important
          _.remove(temp, function(v) {
            return v === transaction.publicTransactionId;
          });
          return temp;
        });

        // console.log("processingList2", processingList);

        // setProcessing(false);
        
      },
      onError: (error) => {
        // handle ???
        // setProcessing(false);
      }
    });
  }

  return(
    <Fragment>

    <Table
      is={"table"}
      width={"100%"}
      style={{
        borderCollapse: "collapse",
      }}
    >

      <Table.Head is={"thead"} height={"auto"} style={{border: "none"}} >

        <Table.Row is={"tr"} width={"100%"} height={32} style={{border: "none"}} >

          {/* <Table.SearchHeaderCell /> */}

          <Table.TextHeaderCell
            is={"th"}
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
              width: 0,
            }}
            padding={0}
            width={30}
          >{
            I18n.get("Selected")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell
            is={"th"}
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
              width: 0,
            }}
            padding={0}
            width={0}
          >{
            I18n.get("Transaction ID")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell
            is={"th"}
            textAlign="left"
            textProps={{
              color: "#425A70",
              fontSize: 14,
            }}
            flex={0.8}
            paddingX={0}
            marginX={4}
          >{
            I18n.get("Date")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
            }}
            padding={0}
          >{
            I18n.get("Time")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            textProps={{
              color: "#425A70",
              fontSize: 14,
            }}
            paddingX={0}
            marginX={4}
          >{
            I18n.get("From")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
              width: 0,
            }}
            padding={0}
          >{I18n.get("From username")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            textProps={{
              color: "#425A70",
              fontSize: 14,
            }}
            paddingX={0}
            marginX={4}
          >{
            I18n.get("Request for")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
              width: 0,
            }}
            padding={0}
          >{
            I18n.get("To username")
          }</Table.TextHeaderCell>
          
          <Table.TextHeaderCell is={"th"}
            textAlign="right"
            textProps={{
              color: "#425A70",
              fontSize: 14,
            }}
            paddingX={0}
            marginX={4}
          >{
            I18n.get("Amount")
          }</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#425A70",
              fontSize: 0,
              width: 0,
            }}
            padding={0}
            width={40}
          >{
            I18n.get("Action")
          }</Table.TextHeaderCell>
          
          
        </Table.Row>
      </Table.Head>

      {!localLoadState.total ?
        localLoadState.total === 0 ?
          <Pane display="flex" alignItems="center" justifyContent="center" height={240} >
            <Text fontSize={14} color="#7B8B9A">{I18n.get("No transactions")}</Text>
          </Pane>
        :
          <Pane display="flex" alignItems="center" justifyContent="center" height={240} >
            <Spinner size={24} />
          </Pane>
      :
        <InfiniteScroll
          dataLength={localLoadState.results?.length}
          next={fetchMoreData}
          hasMore={localLoadState.results?.length < localLoadState.total}
          scrollThreshold={0.6}
          // scrollableTarget="scrollableDiv"
          height={240}
          // loader={<h4>Loading...</h4>}
        >
          <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
            {localLoadState.results?.map((transaction) => (
              <Table.Row
                is={"tr"}
                width={"100%"}
                height="auto"
                minHeight={48}
                key={transaction.publicTransactionId}
                isSelectable
                onSelect={() => {
                  alert(transaction.publicTransactionId);
                  // let newUrl = generatePath("/:page?/:publicTransactionId", {page: "t", publicTransactionId: transaction.linkedPublicTransactionId});
                  // window.open(newUrl, '_blank', 'noopener,noreferrer'); // ???
                }}
              >

                  <Table.TextCell
                    is={"td"}
                    flex="none"
                    textAlign="right"
                    paddingX={0}
                    marginX={4}
                  >{
                    <Fragment>
                    
                      <DynamicTickBox
                        disabled={false
                          || (transaction.amount >= transaction.amountExpected && false) // ???
                        }
                        completed={false}
                        // checked={selectedTransactionsById[transaction.publicTransactionId] ? true : false}
                        // onClick={() => {
                        //   if (selectedTransactionsById[transaction.publicTransactionId] === undefined) {
                        //     setSelectedTransactionsById((prev) => {
                        //       let updatedObj = {
                        //         ...prev
                        //       };
                        //       updatedObj[transaction.publicTransactionId] = {
                        //         publicTransactionId: transaction.publicTransactionId
                        //       };
                        //       return updatedObj;
                        //     });
                        //   }
                        //   else {
                        //     setSelectedTransactionsById((prev) => {
                        //       let updatedObj = {
                        //         ...prev
                        //       };
                        //       delete updatedObj[transaction.publicTransactionId];
                        //       return updatedObj;
                        //     });
                        //   }
                        // }}
                      />
                      
                      <Text
                        is={"td"}
                        height={0}
                        width={0}
                        display={"block"}
                        lineHeight={"inherit"}
                        textAlign="left"
                        color={"#7B8B9A"}
                        fontSize={0}
                        padding={0}
                      >{
                        transaction.publicTransactionId
                      }</Text>

                    </Fragment>
                  }</Table.TextCell>
              
                <Table.TextCell
                  is={"td"}
                  flex={0.8}
                  textAlign="left"
                  paddingX={0}
                  marginX={4}
                >{
                  <Fragment>
                    <Strong fontSize={14} color="#283655" >{
                      formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")
                    }</Strong>
                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >{
                      formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")
                    }</Text>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell
                  is={"td"}
                  textAlign="left"
                  paddingX={0}
                  marginX={4}
                >{
                  <Fragment>
                    <Strong fontSize={14} color="#283655" >{transaction.toFace?.name || transaction.toPublicFaceId}</Strong>
                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >{
                      transaction.toPublicFaceId
                    }</Text>
                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >{
                      transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "matchFundingRequest"))?.fromFace?.email
                    }</Text>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell
                  is={"td"}
                  textAlign="left"
                  paddingX={0}
                  marginX={4}
                >{
                  <Fragment>
                    
                      <Strong fontSize={14} color="#283655" >
                        <Textfit mode="single" max={14} >
                          {transaction?.history?.find((t) => (t.publicTransactionId === transaction.publicTransactionId && t.type === "matchFundingRequest"))?.toFace?.email}
                        </Textfit>
                      </Strong>
                    
                    
                    <Text
                      is={"td"}
                      display={"block"}
                      lineHeight={"inherit"}
                      textAlign="left"
                      color={"#7B8B9A"}
                      fontSize={12}
                      padding={0}
                    >
                      {"..."}
                    </Text>
                  </Fragment>
                }</Table.TextCell>


                <Table.TextCell
                  is={"td"}
                  textAlign="right"
                  paddingX={0}
                  marginX={4}
                  textProps={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "baseline",
                    justifyContent: "end",
                  }}
                >{
                  <Fragment>

                    <Strong fontSize={14} color="#283655" order={2} >
                      {handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)}
                    </Strong>
                  
                    <Text
                      is={"td"}
                      // display={"flex"}
                      // flex={1}
                      order={1}
                      lineHeight={"inherit"}
                      textAlign="right"
                      color={"#7B8B9A"}
                      fontSize={transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ? 12 : 0}
                      padding={0}
                      marginRight={6}
                      marginLeft="auto"
                    >
                      <Fragment>
                        {transaction.relatedCurrency && transaction.currency !== transaction.relatedCurrency ?
                          handleFormattedCurrencyNumber(transaction.relatedAmountExpected, transaction.relatedCurrency || localeState.currency, false, false)
                        :
                          handleFormattedCurrencyNumber(transaction.amountExpected, transaction.currency || localeState.currency, false, false)
                        }
                      </Fragment>
                    </Text>

                    {transaction.status === "succeeded" ?
                      <Strong
                        is={"td"}
                        // display={"flex"}
                        order={3}
                        lineHeight={"inherit"}
                        textAlign="right"
                        width={"100%"}
                        color={"#47B881"}
                        fontSize={12}
                        padding={0}
                      >
                        {Math.round(((transaction.amount / transaction.amountExpected) + Number.EPSILON) * 100) / 1}{"%"} {I18n.get('match-funded')}
                      </Strong>
                    :
                      <Text
                        is={"td"}
                        // display={"flex"}
                        order={3}
                        lineHeight={"inherit"}
                        textAlign="right"
                        width={"100%"}
                        color={"#7B8B9A"}
                        fontSize={12}
                        padding={0}
                      >
                        {
                          I18n.get(
                            transaction.status === "pending" ? "Pending" :
                            transaction.status === "processing" ? "Processing" : "..."
                          )
                        }
                      </Text>
                    }
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell is={"td"} textAlign="right"
                  paddingX={5}
                  flex="none"
                >{
                  <Fragment>
                  
                    <IconButton
                      height={30}
                      icon={RepeatIcon}
                      iconSize={16}
                      disabled={processingList?.find(v => v === transaction.publicTransactionId)}
                      onClick={(event) => {
                        handleReassignOneTransaction(event, transaction);
                      }}
                    />

                  </Fragment>

                }</Table.TextCell>

              </Table.Row>
            ))}
            {localLoadState.results?.length < localLoadState.total ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                <Spinner size={24} />
              </Pane>
            : null }
          </Table.Body>
        </InfiniteScroll>
      }

      <Table.Head is={"tfoot"}
        height={"auto"}
        style={{
          border: "none",
          background: "none",
        }}
      >
      
        {/* <Pane textAlign="center" marginTop={16} className="noselect" >
          <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
            disabled={false}
            onClick={() => {
              console.log('Load more...');
              console.log("localLoadState", localLoadState);
              setTriggerLoad(true);
            }}
          >
            {`${I18n.get('Load more')}...`}
          </Button>
        </Pane> */}

        <Text fontSize={12} color={"#7B8B9A"}>
          {I18n.get("Viewing")} {localLoadState.results?.length} {I18n.get("of")} {localLoadState.total} {I18n.get("results")}
        </Text>

      </Table.Head>

    </Table>
    </Fragment>
    
  );
}

const AccountAdminVoluntaryContribution = () => {
  
  // const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const handleFormattedCurrencyNumber = useFormattedCurrencyNumber();
  
  const handleGetTransactions = useGetTransactions();

  const [triggerLoad, setTriggerLoad] = useState(true);
  const [localLoadState, setLocalLoadState] = useState({
    total: null,
    nextToken: null,
    error: null,
  });
  const [transactionList, setTransactionList] = useState([]);

  const fetchMoreData = () => {
    console.log("Fetch more data...");
    setTriggerLoad(true);
  };
  
  useEffect(() => {
    if (userState.actAsUser?.publicFaceId) {
      setTransactionList([]);
      setTriggerLoad(true);
    }
  }, [userState.actAsUser?.publicFaceId]);

  useEffect(() => {

    if (triggerLoad && !localLoadState.error) {
      setTriggerLoad(false);
      handleGetTransactions({
        toPublicFaceId: null, // null will only work for admin ....
        type: "voluntaryContribution",
        currency: localeState.currency,
        locale: localeState.locale,
        nextToken: localLoadState.nextToken,
        onSuccess: (searchResult) => {
          console.log("voluntaryContribution transactions:", [...transactionList, ...searchResult.results]);
          setTransactionList([...transactionList, ...searchResult.results]);
          setLocalLoadState({
            ...localLoadState,
            total: searchResult.total,
            nextToken: transactionList.length === localLoadState.total ? null : searchResult.nextToken,
            error: null,
          });
        },
        onError: (error) => {
          setLocalLoadState({
            ...localLoadState,
            error: error,
          });
        },
      });
    }
    
  }, [transactionList, handleGetTransactions, localLoadState, localeState.currency, localeState.locale, triggerLoad]);

  return(
    <Fragment>

    <Table is={"table"} width={"100%"} >

      <Table.Head is={"thead"} height={"auto"} style={{border: "none"}} >
      
        <Table.Row is={"tr"} width={"100%"} style={{border: "none"}} >
          {/* <Table.SearchHeaderCell /> */}

          <Table.TextHeaderCell is={"th"} textAlign="left"
            textProps={{
              color: "#283655",
              fontSize: 0,
              lineHeight: 1,
            }}
            paddingX={5}
            flex="none"
            width={26}
          >{I18n.get("frequency")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"} textAlign="left"
            textProps={{
              color: "#283655",
              fontSize: 16,
              lineHeight: 1,
            }}
            paddingX={5}
          >{I18n.get("Date")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#283655",
              fontSize: 0,
              lineHeight: 1,
            }}
            padding={0}
          >{I18n.get("Time")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            textProps={{
              color: "#283655",
              fontSize: 16,
              lineHeight: 1,
            }}
            paddingX={5}
          >{I18n.get("From")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="left"
            flex="none"
            textProps={{
              color: "#283655",
              fontSize: 0,
              lineHeight: 1,
              width: 0,
            }}
            padding={0}
          >{I18n.get("username")}</Table.TextHeaderCell>

          <Table.TextHeaderCell is={"th"}
            textAlign="right"
            textProps={{
              color: "#283655",
              fontSize: 16,
              lineHeight: 1,
            }}
            paddingX={5}
          >{I18n.get("Amount")}</Table.TextHeaderCell>
          
        </Table.Row>
      </Table.Head>

      {!localLoadState.total ?
        localLoadState.total === 0 ?
          <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
            <Text fontSize={14} color="#7B8B9A">{I18n.get("No history")}</Text>
          </Pane>
        :
          <Pane display="flex" alignItems="center" justifyContent="center" height={240}>
            <Spinner size={24} />
          </Pane>
      :
        <InfiniteScroll
          dataLength={transactionList.length}
          next={fetchMoreData}
          hasMore={transactionList.length < localLoadState.total}
          scrollThreshold={0.6}
          // scrollableTarget="scrollableDiv"
          height={240}
          // loader={<h4>Loading...</h4>}
        >
          <Table.Body is={"tbody"} id="scrollableDiv" overflow={"scroll"} display={"block"} >
            {transactionList.map((transaction) => (
              <Table.Row is={"tr"} width={"100%"} key={transaction.publicTransactionId}
                // isSelectable
                // onSelect={() => alert(transaction.name)}
              >

                <Table.TextCell is={"td"} textAlign="left"
                  flex="none"
                  paddingX={5}
                >{
                  <Fragment>
                    <Text height={0} display="flex" fontSize={0} color="#7B8B9A">{I18n.get(transaction.frequency)}</Text>
                    <Pane display="flex" alignItems="center" >
                      {transaction.frequency === "monthly" ? <UpdatedIcon size={16} /> : <ArrowTopRightIcon size={16} />}
                    </Pane>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell is={"td"} textAlign="left"
                  paddingX={5}
                >{
                  <Fragment>
                    <Strong fontSize={14} color="#283655">{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "d MMM yyyy")}</Strong>
                    <Table.TextCell is={"td"}
                      textAlign="left"
                      textProps={{
                        color: "#7B8B9A",
                        fontSize: 12,
                      }}
                      paddingX={0}
                    >{formatDateFns(new Date(transaction.createdAtUNIX * 1000), "HH:mm")}</Table.TextCell>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell is={"td"} textAlign="left"
                  paddingX={5}
                >{
                  <Fragment>
                    <Strong fontSize={14} color="#283655">{transaction.fromFace?.name || transaction.fromPublicFaceId}</Strong>
                    <Table.TextCell is={"td"}
                      textAlign="left"
                      textProps={{
                        color: "#7B8B9A",
                        fontSize: 12,
                      }}
                      paddingX={0}
                    >{transaction.fromPublicFaceId}</Table.TextCell>
                  </Fragment>
                }</Table.TextCell>

                <Table.TextCell is={"td"} textAlign="right"
                  paddingX={5}
                >{
                  <Fragment>
                    <Strong fontSize={14} color="#283655">{handleFormattedCurrencyNumber(transaction.amount, transaction.currency || localeState.currency, false, false)}</Strong>
                  </Fragment>

                }</Table.TextCell>

              </Table.Row>
            ))}
            {transactionList.length < localLoadState.total ?
              <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                <Spinner size={24} />
              </Pane>
            : null }
          </Table.Body>
        </InfiniteScroll>
      }

      <Table.Head is={"tfoot"}
        height={"auto"}
        style={{
          border: "none",
          background: "none",
        }}
      >
      
        {/* <Pane textAlign="center" marginTop={16} className="noselect" >
          <Button fontSize={14} paddingX={12} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
            disabled={false}
            onClick={() => {
              console.log('Load more...');
              console.log("localLoadState", localLoadState);
              setTriggerLoad(true);
            }}
          >
            {`${I18n.get('Load more')}...`}
          </Button>
        </Pane> */}

        <Pane paddingY={5} >
          <Text fontSize={14} color="#7B8B9A">{`${transactionList.length}/${localLoadState.total} results`}</Text>
        </Pane>
      </Table.Head>

    </Table>
    </Fragment>
    
  );
}

const AccountAdminPanel = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountAdminPanel - ${countRef.current}`);
    return () => {
      console.log("AccountAdminPanel - cleaned up");
      isCurrent.current = false;
    }
  }, []);
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  const localeState = useSelector(state => state.localeState);
  const handleUpdateUserDetails = useAuthUpdateUserDetails();
  
  const [processing, setProcessing] = useState(false);

  const userDetails = useMemo(() => {
    if (userState.actAsUser){
      return (userState.actAsUser);
    }
    return (userState.user);
  }, [userState]);

  const userDetailsSection = useMemo(() => {
    
    // const userDetailsGiftAidAcceptanceRecord = userDetails?.records?.find((record) => (record.type === "giftAidAcceptance"));
    
    return ({
      tags: userDetails.tags,
      suffixes: userDetails.suffixes?.filter((el) => {
        return (el !== null && el !== undefined);
      }),
      verifiedUNIX: userDetails.verifiedUNIX,
      rights: userDetails.rights
    });

  }, [userDetails.rights, userDetails.suffixes, userDetails.tags, userDetails.verifiedUNIX]);

  const [updatedUserDetailsSection, setUpdatedUserDetailsSection] = useState(userDetailsSection);

  useEffect(() => {
    setUpdatedUserDetailsSection(_.cloneDeep(userDetailsSection));
  }, [userDetails.rights, userDetails.suffixes, userDetails.tags, userDetails.verifiedUNIX, userDetailsSection]);


  const [actAsPublicFaceId, setActAsPublicFaceId] = useState();


  const handleAddSubsToFaces = useCallback(async() => {
    
    try {
      
      const response = await API.graphql({
        query: mutations.triggerAddSubsToFaces,
        variables: {},
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      
      console.log("response triggerAddSubsToFaces", response.data.triggerAddSubsToFaces);
    
    }
    catch (error) {
      console.error("within triggerAddSubsToFaces", error);
    }

  }, [userState.user]);

  const handleSetRegularEvents = useCallback(async() => {
    
    try {
      const response = await API.graphql({
        query: queries.setRegularEvents,
        variables: {},
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      const setRegularEvents = response.data.setRegularEvents;
      console.log("setRegularEvents", setRegularEvents);
    
    }
    catch (error) {
      console.error("with setRegularEvents", error);
    }

  }, [userState.user]);


  const handleTriggerDoTableMagic = useCallback(async() => {
    
    try {
      const response = await API.graphql({
        query: mutations.triggerDoTableMagic,
        variables: {
          input: JSON.stringify({

            // 1. // do this of the current index // may need to "delete index" an old _copy ???
            // "operation": "reindex",
            // "body": {
            //   "body": {
            //       "source": {
            //         "index": "transaction"
            //       },
            //       "dest": {
            //         "index": "transaction_copy",
            //         "type": "doc"
            //       }
            //   },
            // }

            // // give some time to copy ...

            
            // 2.
            // "operation": "delete index",
            // "body": {
            //   "index": "transaction",
            // }

            // // -----
            // 3.
            // "operation": "index",
            // "body": {
            //   "index": "transaction",
            //   "type": "doc",
            //   "id": "some-id-just-to-have-a-temp-document", // take a note of this id as it will be needed later to remove this document later
            //   "body": {
            //     "status": "status-status" // this is just to have a field in a document, some string for a field's name that will be used in the future.
            //   }
            // }

            // // -----
            // 4. // this needs to detail all fields as "float" type fields may end up being of "long" type resulting in aggregation errors !!!
            // "operation": "putMapping",
            // "body": {
            //   "index": "transaction",
            //   // "type": "doc", // doesn't seem required for 7.10
            //   "body": {
            //     "properties" : {
            //       "impact" : { 
            //         "type" : "nested", // https://www.elastic.co/guide/en/elasticsearch/reference/7.2/nested.html
            //         "properties" : {
            //           // "orderIndex": { "type": "long" },
            //           // "action": { "type": "text" },
            //           // "effect": { "type": "text" },
            //           // "unit": { "type": "text" },
            //           "durationDays": { "type": "float" },
            //           "quantityDurationDays": { "type": "float" },
            //           "quantity": { "type": "float" }, 
            //           // "references": { "type": "text" },
            //         },
            //       },
            //       "history" : { 
            //        "type" : "nested",
            //         // "properties" : {},
            //       },
            //       "rate" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amount" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amountAvailable" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amountRequired" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "relatedAmount" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amountExpected" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amountSubjected" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "amountOffset" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "relatedAmountRequired" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "relatedAmountExpected" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "relatedAmountSubjected" : { 
            //         "type" : "float",
            //         // "properties" : {}
            //       },
            //       "partialUse" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "liveMode" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "isDefault" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "isBeingEdited" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "mayBeAccepted" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "mayBeAdded" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "isToBeDeclared" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //       "hasBeenDeclared" : { 
            //         "type" : "boolean",
            //         // "properties" : {}
            //       },
            //     }
            //   }
            // }

            // // -----
            // 5.
            // "operation": "delete", // this deletes one document, not the whole index
            // "body": {
            //   "index": "transaction",
            //   "id": "some-id-just-to-have-a-temp-document",
            //   "body": {}
            // }

            // // -----
            // 6.
            // "operation": "reindex",
            // "body": {
            //   "body": {
            //       "source": {
            //         "index": "transaction_copy"
            //       },
            //       "dest": {
            //         "index": "transaction",
            //         "type": "doc"
            //       }
            //   },
            // }

            // // give some time to copy ...

            // // -----
            // 7.
            // "operation": "delete index",
            // "body": {
            //   "index": "transaction_copy",
            // }

            // // End, now everyone should hope it went well

          })
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      const triggerDoTableMagic = response.data.triggerDoTableMagic;
      console.log("triggerDoTableMagic", triggerDoTableMagic);
    
    }
    catch (error) {
      console.error("with triggerDoTableMagic", error);
    }

  }, [userState.user]);

  const [allocationReportDateFromUNIX, setAllocationReportDateFromUNIX] = useState();
  const [allocationReportDateToUNIX, setAllocationReportDateToUNIX] = useState();

  const handleTriggerDoReports = useCallback(async({fromUNIX, toUNIX}) => {

    let nowTemp = new Date();
    const timezoneOffset = nowTemp.getTimezoneOffset();
    console.log(`triggerDoReports timezoneOffset: ${timezoneOffset}`);
    console.log(`triggerDoReports fromUNIX: ${fromUNIX}`);
    console.log(`triggerDoReports toUNIX: ${toUNIX}`);
    
    try {
      const response = await API.graphql({
        query: queries.triggerDoReports,
        variables: {
          type: "x",
          timezoneOffset: timezoneOffset,
          fromUNIX: fromUNIX,
          toUNIX: toUNIX,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
      });
      const triggerDoReports = response.data.triggerDoReports;
      console.log("triggerDoReports", triggerDoReports);
    
    }
    catch (error) {
      console.error("with triggerDoReports", error);
    }

  }, [userState.user]);

  const handleGetUserDetails = useGetUserDetails();

  const handleSetActAsUser = async() => {

    // localProcessing ???

    await handleGetUserDetails({
      publicFaceId: actAsPublicFaceId,
      currency: localeState.currency,
      locale: localeState.locale,
      onSuccess: (userDetails) => {
        console.log("actAsUser", userDetails);
        dispatch(updateUserState({
          actAsUser: userDetails,
        }));
      },
      onError: (error) => {
        // handle ???
      }, 
    });
  }

  const userTagOptions = ["charity", "corporate", "retailer", "professionalPagePending", "pageMayBeClaimed", "pageIsBeingClaimed", ]; // "recipient", "donor" and "provider" get added conditionally? yet only for /browse (Feb 2022)

  const handleUpdateUserRights = () => {

    setProcessing(true);

    handleUpdateUserDetails({
      userDetailsToUpdate: updatedUserDetailsSection,
      onSuccess: (freshUserDetails) => {
        setProcessing(false);
      },
      onError: (error) => {
        console.error('Error: ', error);
        setProcessing(false);
      },
    });
  }

  return(
    <Pane>

      <Fragment>
        <Pane marginTop={10} >
          <Strong htmlFor="publicFaceId" fontSize={16} color="#283655" >{I18n.get('Act as publicFaceId')}</Strong>
        </Pane>
        <Pane marginTop={4} >
          <TextInput
            ref={null}
            disabled={false}
            name={"publicFaceId"}
            // autoComplete={"username"}
            type={"text"} //
            margin={0}
            width={"100%"}
            height={40}
            placeholder={`${I18n.get('PublicFaceId')}...`}
            value={actAsPublicFaceId}
            onChange={(e) => {
              if (!e.target.value) {
                dispatch(updateUserState({
                  actAsUser: null,
                }));
              }
              setActAsPublicFaceId(e.target.value);
            }}
            // onFocus={(e) => {setProcessError()}}
          />
        </Pane>

        <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={10} >
          <Pane flex="none" marginRight={36} >
            <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
              disabled={!actAsPublicFaceId}
              onClick={handleSetActAsUser}
            >
              {I18n.get('Set')}
            </Button>
          </Pane>
        </Pane>

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      </Fragment>

      <Fragment>
        
        <Pane marginTop={10} >
          <ToggleSection
            key={"verifiedToggle"}
            toggleParams={{
              key: "verifiedToggle",
              title: I18n.get('Verified'),
              description: I18n.get(`...`),
              value: updatedUserDetailsSection.verifiedUNIX ? true : false,
              default: false,
              options: [
                { label: I18n.get('No'), value: false },
                { label: I18n.get('Yes'), value: true },
              ],
            }}
            onToggle={(value) => {
              setUpdatedUserDetailsSection({
                ...updatedUserDetailsSection,
                verifiedUNIX: value ? (userDetails.verifiedUNIX || Math.round(new Date().getTime()/1000)) : null,
              });
            }}
            isProcessing={false}
          />
        </Pane>

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      </Fragment>

      <Fragment>
        <Pane marginTop={10} >
          <Strong fontSize={16} color="#283655" >{I18n.get('Category')}</Strong>
        </Pane>
        
        <Pane marginTop={10} >
        {userTagOptions.map((category) => (
          <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
            <Checkbox size={18} flex="none" marginLeft={8} marginRight={8} marginTop={0} marginBottom={0}
              label={""}
              // disabled={localProcessing || isProcessing}
              checked={updatedUserDetailsSection.tags.includes(category)}
              onChange={(e) => {
                // setChecked(e.target.checked);
                if (e.target.checked) {
                  setUpdatedUserDetailsSection({
                    ...updatedUserDetailsSection,
                    tags: [...updatedUserDetailsSection.tags, category],
                  });
                }
                else if (!e.target.checked) {
                  setUpdatedUserDetailsSection({
                    ...updatedUserDetailsSection,
                    tags: updatedUserDetailsSection.tags.filter(e => e !== category),
                  });
                }
              }}
            />
            <Pane flex={1} marginTop={2} >
              <Text fontSize={16} color="#425A70">{category}</Text>
            </Pane>
          </Pane>
        ))}
        </Pane>

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      </Fragment>

      <Fragment>
        <Pane marginTop={10} >
          <Strong fontSize={16} color="#283655" >{I18n.get('Suffixes')}</Strong>
        </Pane>
        
        <Pane marginTop={10} >
          <TagInput
            width={"100%"}
            height={40}
            inputProps={{ placeholder: 'Add association suffixes...' }}
            values={updatedUserDetailsSection.suffixes || []}
            onChange={(suffixes) => {
              setUpdatedUserDetailsSection({
                ...updatedUserDetailsSection,
                suffixes: suffixes,
              });
            }}
          />
        </Pane>

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      </Fragment>

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "balanceTransfer",
            title: I18n.get('Allow to transfer donated funds'),
            description: I18n.get(`Mainly applicable to corporate users. Includes only donated funds. Transferring topped-up funds is available to all users. 'Funds received from provisions' are not transferable.`), // should certain recipients be able to do this?
            value: updatedUserDetailsSection.rights?.balanceTransfer || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                balanceTransfer: value,
              }
            });
          }}
          isProcessing={false}
        />

        

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "provideExternally",
            title: I18n.get('Allow to provide externally'),
            description: I18n.get(`Mainly applicable to retail users. Includes providing using recipients' funds. This increases 'Funds received from provisions'.`),
            value: updatedUserDetailsSection.rights?.provideExternally || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                provideExternally: value,
              }
            });
          }}
          isProcessing={false}
        />
        
        {updatedUserDetailsSection.rights?.provideExternally ? 
          <Fragment>
            <Pane >
              <Strong fontSize={14} color="#425A70">{I18n.get("Limit factor")}</Strong>
            </Pane>
            <NumericFormat
              // getInputRef={(el) => {xInputRef.current = el}}
              customInput={TextInput}
              // format={"#############"}
              thousandSeparator={true}
              id={"donation-amount"}
              type={"text"}
              pattern={"[0-9]*"} // to bring up the numeric keypad
              inputMode="numeric" // to bring up the numeric keypad
              // autoComplete="off" // doesn't seem to work
              // autoComplete={"donation-amount"}
              // name={"donation-amount"}
              // disabled={false}
              // isInvalid={localIsInvalid}
              marginTop={4}
              marginBottom={0}
              width="100%"
              maxWidth={120}
              height={40}
              fontSize={14}
              // color={"#283655"}
              placeholder={`${I18n.get('Factor')}...`}
              value={updatedUserDetailsSection.rights?.provideExternallyLimitFactor}
              onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                let temp = values.value;
                let tempValue = null;
                if (temp && temp !== "") {
                  tempValue = Number(temp);
                }
                setUpdatedUserDetailsSection({
                  ...updatedUserDetailsSection,
                  rights: {
                    ...updatedUserDetailsSection.rights,
                    provideExternallyLimitFactor: tempValue,
                  }
                });
              }}
              // onBlur={(e) => {}}
            />
          </Fragment>
        : null }

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "provideInternally",
            title: I18n.get('Allow to provide internally'),
            description: I18n.get(`Mainly applicable to charity users. Includes providing using donated and topped-up funds. Providing using topped-up funds will move funds from 'Funds received from topups' to 'Funds received from provisions', making the funds subject to fees. Providing using 'Funds received from provisions' is not possible: these funds should first be moved to topups, which may include fees.`),
            value: updatedUserDetailsSection.rights?.provideInternally || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                provideInternally: value,
              }
            });
          }}
          isProcessing={false}
        />
        
        {updatedUserDetailsSection.rights?.provideInternally ? 
          <Fragment>
            <Pane >
              <Strong fontSize={14} color="#425A70" >{I18n.get("Limit factor")}</Strong>
            </Pane>
            <NumericFormat
              // getInputRef={(el) => {xInputRef.current = el}}
              customInput={TextInput}
              // format={"#############"}
              thousandSeparator={true}
              id={"donation-amount"}
              type={"text"}
              pattern={"[0-9]*"} // to bring up the numeric keypad
              inputMode="numeric" // to bring up the numeric keypad
              // autoComplete="off" // doesn't seem to work
              // autoComplete={"donation-amount"}
              // name={"donation-amount"}
              // disabled={false}
              // isInvalid={localIsInvalid}
              marginTop={4}
              marginBottom={0}
              width="100%"
              maxWidth={120}
              height={40}
              fontSize={14}
              // color={"#283655"}
              placeholder={`${I18n.get('Factor')}...`}
              value={updatedUserDetailsSection.rights?.provideInternallyLimitFactor}
              onValueChange={(values) => { // https://www.npmjs.com/package/react-number-format#values-object
                let temp = values.value;
                let tempValue = null;
                if (temp && temp !== "") {
                  tempValue = Number(temp);
                }
                setUpdatedUserDetailsSection({
                  ...updatedUserDetailsSection,
                  rights: {
                    ...updatedUserDetailsSection.rights,
                    provideInternallyLimitFactor: tempValue,
                  }
                });
              }}
              // onBlur={(e) => {}}
            />
          </Fragment>
        : null }

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "curateFeedback",
            title: I18n.get('Allow to curate feedback'),
            description: I18n.get(`Applicable to charity users. ...`),
            value: updatedUserDetailsSection.rights?.curateFeedback || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                curateFeedback: value,
              }
            });
          }}
          isProcessing={false}
        />

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "acceptGiftAid",
            title: I18n.get('Allow to accept Gift Aid'),
            description: I18n.get(`...`),
            value: updatedUserDetailsSection.rights?.acceptGiftAid || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                acceptGiftAid: value,
              }
            });
          }}
          isProcessing={false}
        />

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      {/* <Fragment>
        <ToggleSection
          toggleParams={{
            key: "verifyRecipients",
            title: I18n.get('Allow to verify recipients'),
            description: I18n.get(`...`),
            value: updatedUserDetailsSection.rights?.verifyRecipients || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                verifyRecipients: value,
              }
            });
          }}
          isProcessing={false}
        />

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment> */}

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "makeAllocations",
            title: I18n.get('Allow to make allocations'),
            description: I18n.get(`This will allow to add allocation amounts to be used in the allocation mode. Suitable for charities.`),
            value: updatedUserDetailsSection.rights?.makeAllocations || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                makeAllocations: value,
              }
            });
          }}
          isProcessing={false}
        />

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>

      <Fragment>
        <ToggleSection
          toggleParams={{
            key: "anonymiseRecipients",
            title: I18n.get('Allow to anonymise recipients'),
            description: I18n.get(`This will allow to anonymise recipients. Right to make allocations is required for this to be set.`),
            value: updatedUserDetailsSection.rights?.anonymiseRecipients || false,
            default: false,
          }}
          onToggle={(value) => {
            setUpdatedUserDetailsSection({
              ...updatedUserDetailsSection,
              rights: {
                ...updatedUserDetailsSection.rights,
                anonymiseRecipients: value,
              }
            });
          }}
          isProcessing={false}
        />

        <Pane width="100%" marginTop={16} marginBottom={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Fragment>
      

      <Pane marginTop={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        
        <Pane height="auto" flex={1} minWidth={140} maxWidth={180}></Pane>

        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap">
              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                  disabled={!updatedUserDetailsSection || _.isEqual(userDetailsSection, updatedUserDetailsSection)}
                  onClick={handleUpdateUserRights}
                >
                  {I18n.get('Submit')}
                </Button>
              </Pane>

              {/* Temporarily disable my account, see Edit Profile */}

            </Pane>
          }
        </Pane>
      </Pane>
      

      <Pane marginTop={16}>

      <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>


        <Pane marginTop={10} >

          <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                disabled={false}
                onClick={handleAddSubsToFaces}
              >
                {I18n.get('Add subs to Faces')}
              </Button>
            </Pane>

          </Pane>

          <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane marginTop={10} >

          <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                disabled={false}
                onClick={handleSetRegularEvents}
              >
                {I18n.get('Set regular events')}
              </Button>
            </Pane>
            

          </Pane>

          <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane marginTop={10} >

          <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex="none" marginRight={36} >
              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                disabled={false}
                onClick={handleTriggerDoTableMagic}
              >
                {I18n.get('Do Table Magic')}
              </Button>
            </Pane>
            

          </Pane>

          <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane marginTop={16} >

          <Pane alignItems="center" display="flex" >
            <Strong marginLeft={2} fontSize={16} color="#283655">{I18n.get("Allocation reports")}</Strong>
            {/* {localProcessing || isProcessing ?
              <Spinner marginLeft={8} flex="none" size={20} />
            : null } */}
          </Pane>

          <Fragment>

            <Pane marginTop={8} display="flex" alignItems="center" gap={20} >
              <Pane minWidth={200} >

                <Strong marginLeft={2} fontSize={14} color="#283655" >{I18n.get('From')}</Strong>

                <TextInput
                  fontSize={16}
                  // id={inputName}
                  // autoComplete={inputName}
                  // name={inputName}
                  // disabled={disabled}
                  // isInvalid={localIsInvalid}
                  type="date"
                  // maxLength={maxLength}
                  marginTop={4}
                  marginBottom={0}
                  width="100%"
                  maxWidth={200}
                  height={40}
                  onChange={(e) => {
                    // console.log("e onChange", e);
                    setAllocationReportDateFromUNIX(Math.round(Date.parse(e.target.value)/1000));
                  }}
                  // onBlur={(e) => {
                  //   console.log("onBlur e", e);
                  //   // if (typeof onBlur === "function") {
                  //   //   onBlur(e);
                  //   // }
                  // }}
                />
            
              </Pane>

              <Pane minWidth={200} >
              
                <Strong marginLeft={2}  fontSize={14} color="#283655" >{I18n.get('To')}</Strong>

                <TextInput
                  fontSize={16}
                  // id={inputName}
                  // autoComplete={inputName}
                  // name={inputName}
                  // disabled={disabled}
                  // isInvalid={localIsInvalid}
                  type="date"
                  // maxLength={maxLength}
                  marginTop={4}
                  marginBottom={0}
                  width="100%"
                  maxWidth={200}
                  height={40}
                  onChange={(e) => {
                    // console.log("e onChange", e);
                    console.log("reportDateToUNIX", Math.round(Date.parse(e.target.value)/1000));
                    let nowTemp = new Date();
                    console.log("timezoneOffset", nowTemp.getTimezoneOffset());
                    setAllocationReportDateToUNIX(Math.round(Date.parse(e.target.value)/1000));
                  }}
                  // onBlur={(e) => {
                  //   console.log("onBlur e", e);
                  //   // if (typeof onBlur === "function") {
                  //   //   onBlur(e);
                  //   // }
                  // }}
                />
              </Pane>
            </Pane>

          </Fragment>

          <Pane width="100%" height={40} alignItems="center" display="flex" flexWrap="wrap" marginTop={16}>

              <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                disabled={!allocationReportDateFromUNIX || !allocationReportDateToUNIX
                  || allocationReportDateFromUNIX > allocationReportDateToUNIX
                }
                onClick={() => {
                  handleTriggerDoReports({
                    fromUNIX: allocationReportDateFromUNIX,
                    toUNIX: allocationReportDateToUNIX,
                  })
                }}
              >
                {I18n.get('Do Allocation Reports')}
              </Button>
            

          </Pane>          

          <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>



      </Pane>
      
      <Pane marginTop={16}>

        <AccountAdminPayouts />

        <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
      </Pane>

      {false ? null :
        <Pane marginTop={16}>
          <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
            <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Match funding requests to assign')}</Strong>
            {/* {processingMatchDonations ?
              <Spinner marginLeft={8} flex="none" size={24} />
            : null } */}
          </Pane>
          <Pane marginTop={8} >
            <Text fontSize={14} color="#7B8B9A">{I18n.get('These match funding transactions may be retried once the suffix recorded in the match funding request in the linked donation transaction is associated with a corporate page.')}</Text>
          </Pane>

          <Pane marginTop={10} >

            <AccountAdminMatchFundingRequests />

            <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          </Pane>
        </Pane>
      }

      {false ? null :
        <Pane marginTop={16}>
          <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
            <Strong display="contents" flex="none" fontSize={24} color="#283655">{I18n.get('Voluntary contributions')}</Strong>
            {/* {processingMatchDonations ?
              <Spinner marginLeft={8} flex="none" size={24} />
            : null } */}
          </Pane>
          <Pane marginTop={10} >

            <AccountAdminVoluntaryContribution />

            <Pane width="100%" marginTop={16} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          </Pane>
        </Pane>
      }


    </Pane>
  );

}

export default AccountAdminPanel;