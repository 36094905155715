import {useEffect, useState, useContext, useRef, useCallback, useMemo} from 'react';

import { ReactComponent as FdImgMission } from '../assets/images/mission_rocket.svg';

import {Link, Redirect, useRouteMatch, useLocation} from 'react-router-dom';

import {TextInput, IconButton, Heading, Pill, toaster, Icon, LinkIcon, Tooltip, InfoSignIcon, Text, Strong, OrderedList, ListItem, TextInputField, Paragraph, Table, Button, Checkbox, Pane} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import { useSelector } from 'react-redux';

const MissionSection = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`MissionSection - ${countRef.current}`);
    return () => {
      console.log("MissionSection - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let match = useRouteMatch();
  const localeState = useSelector(state => state.localeState);
  const is426PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });
  
  let tMissionHeaderPartOne = "We are on a mission";
  let tMissionHeaderPartTwo = "to connect those who need help";
  let tMissionHeaderPartThree = "with those who care.";
  let tNowIsTheTimeFor = "Now is the time for";
  let tRevolutionInGiving = "a revolution in giving.";
  let tP11 = "It’s very clear that everyone enjoys giving generously to great causes.";
  let tP12 = "Wouldn’t it be better if we knew exactly the impact we make?";
  let tP122 = "That’s precisely what FACE DONATE is all about!";
  let tP13 = "We deliver that much needed end-to-end transparency of the use and impact of every donation.";
  let tP14 = "With FACE DONATE, giving is no longer faceless, and how your donations are spent is never a secret.";

  switch (localeState.language) {
    case "it":
      tMissionHeaderPartOne = "Il nostro sogno";
      tMissionHeaderPartTwo = "è connettere chi ha bisogno di aiuto";
      tMissionHeaderPartThree = "e chi desidera aiutare.";
      tNowIsTheTimeFor = "Oggi è il giorno per";
      tRevolutionInGiving = "fare del bene.";
      tP11 = "E' bello fare del bene, ci si sente utili.";
      tP12 = "Ma non sarebbe ancora più emozionante vedere l'impatto che generiamo?";
      tP122 = "Questo è esattamente lo scopo di FACE DONATE!";
      tP13 = "Ogni buon cittadino desidera trasparenza sull’impiego e l’impatto delle proprie donazioni.";
      tP14 = "FACE DONATE ci mette la faccia fornendo completa trasparenza su uso e impatto di ogni donazione.";
      break;

    default:
      break;
  }

  return (
    <Pane >
      {/* <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
        <Pane clearfix flex={0.8} width="100%" marginTop={16} minWidth={250} height="100%" marginLeft="auto" marginRight="auto" lineHeight={0}>
          <FdImgMission width="100%" height="100%" />
        </Pane>
        <Pane flex={1} marginTop={36} minWidth={250} textAlign="center">
          <Pane marginLeft="auto" marginRight="auto" >
            <Heading size={700} color="#283655">{tMissionHeaderPartOne}</Heading>
            <Heading size={700} color="#283655">{tMissionHeaderPartTwo}</Heading>
            <Heading size={700} color="#283655">{tMissionHeaderPartThree}</Heading>
          </Pane>
        </Pane>
      </Pane> */}

      <Pane width="100%" marginTop={36} >
        <Pane minWidth={250} >
          <Pane marginTop={36} width="fit-content" padding={16} marginRight="auto" background="tint1" borderRadius={10} >
            <Heading fontSize={is426PlusScreen ? 48 : 36} lineHeight={1.1} color="#283655">{tNowIsTheTimeFor}{" "}{tRevolutionInGiving}</Heading>
          </Pane>
        </Pane>
        <Pane padding={16} marginRight="auto" >
          <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP11}
          </Paragraph>
          <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP12}
          </Paragraph>
          <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP122}
          </Paragraph>
          <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP13}
          </Paragraph>
          {/* <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP14}
          </Paragraph> */}
        </Pane>
      </Pane>          
    </Pane>
  );
}

export default MissionSection;