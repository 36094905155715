import { useEffect, useState, useCallback, useRef, Fragment } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Heading, Strong, Text, Table, Button, Pane } from 'evergreen-ui';

import { useMediaQuery } from 'react-responsive';

import { I18n } from 'aws-amplify';
import Footer from '../components/Footer';

import { useSelector } from 'react-redux';

const Goals = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("Goals", countRef.current)
    return () => {
      console.log("Goals cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  // let location = useLocation();

  // const userState = useSelector(state => state.userState);

  useEffect(() => {
    const goalNumber = Number(match.params?.goalNumber);
    if (goalNumber > 0 && goalNumber < 18) {
      window.location.href = `https://unric.org/en/sdg-${goalNumber}`;
    }
    else {
      window.location.href = "https://sdgs.un.org/goals";
    }
  }, [match]);
  

  return (
    <Fragment >

      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>


        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
          <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{"Goals"}</Strong>
          <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>
        </Pane>

        <Pane width="100%" marginTop={36} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">

          

        </Pane>

      </Pane>

      <Footer/>

    </Fragment>
  );
}

export default Goals;