import { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Strong, Text, Table, Button, Pane, SearchInput, Popover, Position, Paragraph, IconButton, MoreIcon } from 'evergreen-ui';

import { compareAsc, isSameDay, parse as parseDateFns, format as formatDateFns, fromUnixTime } from 'date-fns';

import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FacePH from '../images/FacePH';

import { I18n } from 'aws-amplify';

import HeartIcon from '../assets/icons/HeartIcon';
import QRCodeIcon from '../assets/icons/QRCodeIcon';

import PaperPlaneIcon from '../assets/icons/PaperPlaneIcon';
import CommentIcon from '../assets/icons/CommentIcon';
import ProfileIcon from '../assets/icons/ProfileIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';

import FormattedCurrencyNumber, { useFormattedCurrencyNumber } from './FormattedCurrencyNumber';

import FaceAvatar from './FaceAvatar';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setDialogState, setTransactionDetails, updateTransactionDetails } from '../services/actions';

const TransactionHeader = ({ transactionDetails }) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`TransactionHeader - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("TransactionHeader - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();

	const dispatch = useDispatch();

	// const userState = useSelector(state => state.userState);
	// const profileDetails = useSelector(state => state.profileDetails);
	// const transactionDetails = useSelector(state => state.transactionDetails);
	// const localeState = useSelector(state => state.localeState);

  return (
    <Pane alignItems="center" display="flex" flexWrap="wrap" >
      <Pane flex={"auto"} >

        <FaceAvatar
          faceDetails={transactionDetails.fromFace}
        />
        
        {!transactionDetails.viaPublicFaceId ? null : 
          <Pane >

            <Pane paddingTop={2} >
              <Text fontSize={12} color="#283655" >
                {I18n.get("via")}{" "}
              </Text>

              <Strong fontSize={12} color="#283655" >
                {transactionDetails.viaFace?.name}
              </Strong>
            </Pane>

          </Pane>
        }
      </Pane>

      <IconButton height={24} width={24} icon={MoreIcon} iconSize={14} appearance="minimal"
        onClick={() => {
          dispatch(setDialogState({
            componentName: "TransactionMoreDialog",
          }));
        }}
      />

    </Pane>
  );

}

export default TransactionHeader;