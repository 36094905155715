import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Link, useHistory, useLocation, useRouteMatch, generatePath } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';

import { updateUserState, updateLocaleState } from '../services/actions';
import localeDictionary, { localePathOptions, langLocaleOptions, areaServedOptions, langOptions } from '../services/localeDictionary';
import { useSelector, useDispatch, batch } from 'react-redux';

const preferredDomain = "facedonate.org";
const alternativeDomains = ["facedonate.com", "facedonate.it"];


const pageDescriptionsData = {
  // Page - the name of a tab in the browser (as short as possible), which is only for UX not SEO.
  // Title up to 50, 60 characters, for Twitter 70 characters
  // Description up to 100, 160 characters, for Twitter 200 characters
  "home": {
    "en": {
      page: "FACE DONATE",
      title: "FACE DONATE",
      description: "Create an account or log in to FACE DONATE - See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "FACE DONATE",
      title: "FACE DONATE",
      description: "Crea il tuo profilo o accedi a FACE DONATE - Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "new-picture": {
    "en": {
      page: "New picture • FACE DONATE",
      title: "New picture • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "FACE DONATE",
      title: "FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "mission": {
    "shared": {
      logo192Url: `https://${preferredDomain}/media/mission192.png`,
      logo300Url: `https://${preferredDomain}/media/mission300.png`,
      logo512Url: `https://${preferredDomain}/media/mission512.png`,
      logo1200x628Url: `https://${preferredDomain}/media/mission1200x628.png`,
    },
    "en": {
      page: "Mission • FACE DONATE",
      title: "Mission • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Mission • FACE DONATE",
      title: "Mission • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "log-in": {
    "en": {
      page: "Log in • FACE DONATE",
      title: "Log in • FACE DONATE",
      description: "Log in to FACE DONATE - See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Accedi • FACE DONATE",
      title: "Accedi • FACE DONATE",
      description: "Accedi a FACE DONATE - Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "sign-up": {
    "en": {
      page: "Sign up • FACE DONATE",
      title: "Sign up • FACE DONATE",
      description: "Create an account on FACE DONATE - See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Registrati • FACE DONATE",
      title: "Registrati • FACE DONATE",
      description: "Crea il tuo profilo su FACE DONATE - Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "faq": {
    "en": {
      page: "FAQ • FACE DONATE",
      title: "FAQ • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "FAQ • FACE DONATE",
      title: "FAQ • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "legal": {
    "shared": {
      robotsContent: "noindex",
    },
    "en": {
      page: "Legal • FACE DONATE",
      title: "Legal • FACE DONATE",
      description: "Our legal page explains certain legal details one may wish to learn about...",
    },
    "it": {
      page: "Aspetti Legali • FACE DONATE",
      title: "Aspetti Legali • FACE DONATE",
      description: "La nostra sezione legale spiega i dettagli e le condizioni del servizio che bisogna conoscere...",
    },
  },
  "profiles": {
    "shared": {
      robotsContent: "noimageindex, noarchive",
    },
    "en": {
      page: "Profiles • FACE DONATE",
      title: "Profiles • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Profiles • FACE DONATE",
      title: "Profiles • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "browse": {
    "shared": {
      robotsContent: "noimageindex, noarchive",
    },
    "en": {
      page: "Browse • FACE DONATE",
      title: "Browse • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Sfoglia • FACE DONATE",
      title: "Sfoglia • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "account": {
    "shared": {
      robotsContent: "noindex",
    },
    "en": {
      page: "Account • FACE DONATE",
      title: "Account • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Profilo • FACE DONATE",
      title: "Profilo • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
  "redeem": {
    "shared": {
      robotsContent: "noindex",
    },
    "en": {
      page: "Redeem • FACE DONATE",
      title: "Redeem • FACE DONATE",
      description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.",
    },
    "it": {
      page: "Redeem • FACE DONATE",
      title: "Redeem • FACE DONATE",
      description: "Scopri l’impatto delle tue donazioni. Scegli chi vuoi aiutare e ricevi feedback in tempo reale sull’impatto positivo della la tua donazione.",
    },
  },
};

function getMatchDetails(path, uri) {
  // start: false makes parsing optional params work
  const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
  const currentMatchDetails = matchPath(uri);
  return currentMatchDetails;
}

function handleGenerateScriptTagsForJsonLd(jsonLdObjects) {
  if (!jsonLdObjects) {
    jsonLdObjects = [];
  }
  let scriptTagsForJsonLd = jsonLdObjects.map((object, index, array) => (
    <script key={index} type="application/ld+json">{JSON.stringify(object)}</script>
  ))
  // console.log("scriptTagsForJsonLd", scriptTagsForJsonLd);
  return scriptTagsForJsonLd;
}

function handleGenerateAltLinkTagsForLang(currentMatchDetails, searchParams) {
  const toPathRaw = compile(`/:locale?/:rest(.*)?`);

  let queryParams = new URLSearchParams(searchParams);

  let altLinkTagsForLang = langOptions.map((lang, index, array) => {
    queryParams.set('hl', lang);
    
    let adjustedUri = toPathRaw({...currentMatchDetails.params, locale: null});
    return <link key={lang} rel="alternate" href={`https://${preferredDomain}${adjustedUri}?${queryParams.toString()}`} hreflang={lang}/>
  });

  let defaultUri = toPathRaw({...currentMatchDetails.params, locale: null});
  altLinkTagsForLang.unshift(<link key={"null"} rel="alternate" href={`https://${preferredDomain}${defaultUri}`} hreflang="x-default"/>);

  // process alternativeDomains and related languages

  return altLinkTagsForLang;
}

function handleGenerateAltMataTagsForLocale() {
  // <meta property="og:locale:alternate" content="en_GB" />
  // <meta property="og:locale:alternate" content="it_IT" />
  // see languages http://www.loc.gov/standards/iso639-2/php/code_list.php?fbclid=IwAR1fDh9DxaFKfmZq2xaGxu-XVBBiH9Froqkq1HjQjY7sw5UeXFo4quyoKqc
  // see countries https://en.wikipedia.org/wiki/ISO_3166-1?fbclid=IwAR3fQ2E87vMzsGW9hzr6XLKPFzXtG1w58lgjMvN6UF8MicW6K_bk59HJ6F8
  let altMataTagsForLocale = langLocaleOptions.map((locale, index, array) => (
    <meta key={locale} property="og:locale:alternate" content={locale}/>
  ));
  return altMataTagsForLocale;
}

function generateJsonLdObjectForPerson(pageUrl, name, handle, description, website) {
  return {
    "@context": "http://schema.org",
    "@type": "Person",
    "name": name || "",  // "Orlando Bloom",
    "alternateName": `@${handle}`, // "@orlandobloom",
    "description": description || "",
    "url": website || "", // "https://linktr.ee/orlandobloom",
    "mainEntityofPage": {
      "@type": "ProfilePage",
      "@id": pageUrl, // "https://www.instagram.com/orlandobloom/",
      // "interactionStatistic": {
      //   "@type": "InteractionCounter",
      //   "interactionType": "http://schema.org/FollowAction",
      //   "userInteractionCount": "5007932"
      // }
    },
    "image": null // favicon-200.png // "https://www.instagram.com/static/images/ico/favicon-200.png/ab6eff595bb1.png"
  };
}



const HelmetTags = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`HelmetTags - ${countRef.current}`);
    return () => {
      console.log("HelmetTags - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  // console.log("HelmetTags match", match);
  // console.log("HelmetTags location", location);
  
  // const dispatch = useDispatch();
  // const user = useSelector(state => state.userState.user);
  const localeState = useSelector(state => state.localeState);
  const profileDetails = useSelector(state => state.profileDetails);
  
  // manipulate metaTagsData

  const [metaTagsData, setMetaTagsData] = useState({
    type: "website", // for more, see https://ogp.me/?fbclid=IwAR1AzsQPXKkaG87pdoVGjujMlASTHbIW_mDQvBqchS89puk3TDRA1QMmrM4#types
    locale: "en_GB", // en - language GB - country, see https://developers.facebook.com/docs/internationalization#locales
    lang: "en", // default
    robotsContent: "noimageindex, noarchive", // DEFAULT // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
    facebookAppId: "3606842295995777", // optional
    siteName: "FACE DONATE", // DEFAULT SITE NAME
    title: "FACE DONATE", // DEFAULT PAGE NAME up to 50, 60 characters, for Twitter 70 characters
    description: "See how donations are spent. Choose who you wish to help, get real-time feedback on the impact you are making.", // "Unlike just giving spare change, face donate will only pay for good.", // DEFAULT PAGE DESCRIPTION up to 100, 160 characters, for Twitter 200 characters
    faviconUrl: `https://${preferredDomain}/favicon.ico`,
    logo192Url: `https://${preferredDomain}/logo192.png`,
    logo300Url: `https://${preferredDomain}/logo300.png`, // for WhatApp preview, recommended < 300KB
    logo512Url: `https://${preferredDomain}/logo512.png`,
    logo1200x628Url: `https://${preferredDomain}/logo1200x628.png`, // recommended 1200×628
    imageUrl: `https://${preferredDomain}/logo1200x628.png`, // this may change later depending on user-agent
    twitterCardType: "summary_large_image", // for more, see https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/abouts-cards
    twitterSite: "@face_donate", // @publisher_handle
    twitterCreator: "@face_donate", // @author_handle
    manifestUrl: `https://${preferredDomain}/manifest.json`,
    jsonLdObjects: [
      {
        "@context": "http://schema.org",
        "@type": "Corporation",
        "@id": "https://facedonate.org",
        "name": "FACE DONATE",
        "legalName" : "FACE DONATE",
        "url": "https://facedonate.org",
        "logo": "https://facedonate.org/logo512.png",
        "foundingDate": "2019",
        "founders": [
            {
            "@type": "Person",
            "name": "Alexandr Kulakov",
            "gender": "Male"
            }
        ],
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "UCL Hatchery",
        "addressLocality": "103c Camley St, Kings Cross, London",
        "addressRegion": "London",
        "postalCode": "N1C 4PF",
        "addressCountry": "en"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Customer Support",
        "telephone": "(44)7794327309",
        "areaServedOptions" : areaServedOptions,
        "email": "hello@facedonate.org"
        },
        "sameAs": [ 
          "https://www.linkedin.com/company/facedonate",
          "https://www.facebook.com/facedonate.org",
          "https://twitter.com/face_donate",
          "https://www.instagram.com/face_donate",
          "https://www.youtube.com/channel/UCw7kGZTKkXaBmmudjMPIO2Q",
          "https://www.crunchbase.com/organization/face-donate",
          "https://www.trustpilot.com/review/facedonate.org",
          "https://g.page/face_donate"
        ]
      },
      { 
        "@context": "http://schema.org", 
        "@type": "WebSite", 
        "url": "https://facedonate.org", 
        "name": "FACE DONATE",
        "description": ""
      },
    ],
  });
  
  
  
  const describeStaticPage = useCallback((page) => {
    let updatedMetaTagsData = metaTagsData;
    updatedMetaTagsData.robotsContent = "index, follow";
    updatedMetaTagsData.type = "website";
    if (!page) {
      page = "home"; // default
    }
    let pageData = pageDescriptionsData[page];
    if (!pageData) {
      pageData = pageDescriptionsData["home"];
    }
    let pageDataInLang = pageData[localeState.language || "en"];
    if (!pageDataInLang) {
      pageDataInLang = pageData["en"];
    }
    let sharedData = pageData["shared"];
    updatedMetaTagsData = Object.assign(updatedMetaTagsData, sharedData); // this updates metaTagsData with all matching properties from sharedData
    updatedMetaTagsData = Object.assign(updatedMetaTagsData, pageDataInLang); // this updates metaTagsData with all matching properties from pageDataInLang
    setMetaTagsData(updatedMetaTagsData);
  }, [localeState.language, metaTagsData]);

  const handleDescribeFacePage = useCallback((handle) => {
    // const lang = localeState.language || "en";
    let faceDetails = (profileDetails.publicFaceId === handle || profileDetails.username === handle) ? profileDetails : null;

    if (faceDetails) {
      let handleToDisplay = faceDetails.username ? faceDetails.username : faceDetails.publicFaceId;

      let nameToDisplay = faceDetails.name ? faceDetails.name : null;
      let tagLine = ` • FACE DONATE • See how donations are spent`;
      // Orlando Bloom (@orlandobloom) • FACE DONATE see how donations are spent
      metaTagsData.page = `@${handleToDisplay}${tagLine}`;
      metaTagsData.title = `@${handleToDisplay}${tagLine}`;
      if (nameToDisplay) {
        metaTagsData.page = `${nameToDisplay} (@${handleToDisplay})${tagLine}`;
        metaTagsData.title = `${nameToDisplay} (@${handleToDisplay})${tagLine}`;
      }
      
      if (faceDetails.bio && faceDetails.bio !== "") {
        metaTagsData.description = faceDetails.bio.replace(/<br\/>/g, " ").replace(/\s\s+/g, ' '); // cover tabs, newlines, etc
      }
      let tempWebsite = `https://${preferredDomain}${match.url}`;
      if (faceDetails.website && faceDetails.website !== "") {
        tempWebsite = faceDetails.website;
      }

      metaTagsData.imageUrl = metaTagsData.logo512Url; // 512x512 works for WhatsApp and iMessage, looks fine (being cut) on Twitter
      
      metaTagsData.robotsContent = "noimageindex, noarchive";
      metaTagsData.type = "profile";
      metaTagsData.jsonLdObjects = [generateJsonLdObjectForPerson(`https://${preferredDomain}${match.url}`, nameToDisplay, handleToDisplay, metaTagsData.description, tempWebsite)];
    }
    // else if () {}
    else {
      // console.log("redirect to 404: unknown page");
      describeStaticPage("home");
    }
  }, [describeStaticPage, metaTagsData, profileDetails, match.url]);

  const handleDescribeTransactionPage = useCallback((currentPublicTransactionId) => {
    // const lang = localeState.language || "en";
    let transactionDetails = null; // await getTransactionDDBItemByPublicId(currentPublicTransactionId);
    // console.log("transactionDetails", transactionDetails);
    if (transactionDetails) {
      const fromPublicFaceId = transactionDetails.fromPublicFaceId;
      const faceDetails = null; // await getFaceDDBItem("publicFaceId", fromPublicFaceId);
      if (faceDetails) {
        let tHelped = "helped";
        let tagLine = ` • FACE DONATE • See how donations are spent`;
        let handleToDisplay = faceDetails.username ? faceDetails.username : faceDetails.publicFaceId;
        let nameToDisplay = faceDetails.name ? faceDetails.name : null;
        metaTagsData.page = `@${handleToDisplay} ${tHelped}${tagLine}`;
        metaTagsData.title = `@${handleToDisplay} ${tHelped}${tagLine}`;
        if (nameToDisplay) {
          metaTagsData.page = `${nameToDisplay} (@${handleToDisplay}) ${tHelped}${tagLine}`;
          metaTagsData.title = `${nameToDisplay} (@${handleToDisplay}) ${tHelped}${tagLine}`;
        }
        // add image ? 
      }
      else {
        describeStaticPage("home");
      }
      const transactionType = transactionDetails.type;

      metaTagsData.imageUrl = metaTagsData.logo512Url; // 512x512 works for WhatsApp and iMessage, looks fine (being cut) on Twitter
      
      metaTagsData.robotsContent = "noimageindex, noarchive";
    }
    else {
      describeStaticPage("home");
    }
  }, [describeStaticPage, metaTagsData]);

  
  useMemo(() => {
    let matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:rest(.*)?`, match.url);
    if (matchDetails.params.rest) {
      if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:mode(about|mission|log-in|sign-up)`, match.url))) {
        // console.log("Page home", matchDetails.params.page);
        let currentMode = matchDetails.params.mode;
        if (currentMode) {
          describeStaticPage(currentMode);
        }
        else {
          describeStaticPage("home");
        }
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(faq)/:uri?`, match.url))) {
        // console.log("Page faq", matchDetails.params.page);
        describeStaticPage(matchDetails.params.page);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(legal)/:mode(terms|privacy|cookies)?`, match.url))) {
        // console.log("Page legal", matchDetails.params.page);
        describeStaticPage(matchDetails.params.page);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(directory)/:mode(profiles|tags)/:nextToken?`, match.url))) {
        // console.log("Page directory, mode", matchDetails.params.mode);
        describeStaticPage(matchDetails.params.mode);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(browse)`, match.url))) {
        // console.log("Page browse", matchDetails.params.page);
        describeStaticPage(matchDetails.params.page);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(redeem)/:publicTransactionId?/:accessCode?`, match.url))) {
        // console.log("Page redeem", matchDetails.params.page);
        describeStaticPage(matchDetails.params.page);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:page(t)/:publicTransactionId`, match.url))) {
        // console.log("Page t", matchDetails.params.page);
        const currentPublicTransactionId = matchDetails.params.publicTransactionId;
        handleDescribeTransactionPage(currentPublicTransactionId);
      }
      else if ((matchDetails = getMatchDetails(`/:locale(${localePathOptions})?/:handle/:mode(fwd)?/:publicTransactionId?/:accessCode?`, match.url))) {
        // console.log("Page profile", matchDetails.params.handle);
        const currentHandle = matchDetails.params.handle;
        handleDescribeFacePage(currentHandle);
      }
      else {
        describeStaticPage("home");
      }
    }
    else {
      describeStaticPage("home");
    }
  }, [describeStaticPage, handleDescribeFacePage, handleDescribeTransactionPage, match.url]);
  
  // below meta tags must be children components of Helmet, cannot be stings
  return (
    <Helmet>

      <html lang={localeState.language || metaTagsData.lang}/>
      <meta charset="utf-8"/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="apple-mobile-web-app-status-bar-style" content="default"/>
      <meta name="mobile-web-app-capable" content="yes"/>
      <meta id="viewport" name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover"/>
      <meta name="theme-color" content="#ffffff"/>

      <meta name="robots" content={metaTagsData.robotsContent}/>
      <meta name="revisit-after" content="7 days"/>
        
      <title>{metaTagsData.title}</title>
      <meta name="description" content={metaTagsData.description}/>
      
      <meta name="image" content={metaTagsData.imageUrl}/>
      <link rel="canonical" href={`https://${preferredDomain}${location.pathname}${location.search}`}/>
      <link rel="icon" href={metaTagsData.faviconUrl}/>
      
      <meta property="og:type" content={metaTagsData.type}/>
      <meta property="og:url" content={`https://${preferredDomain}${match.url}`}/>
      <meta property="og:site_name" content={metaTagsData.siteName}/>
      <link rel="apple-touch-icon" href={metaTagsData.logo192Url}/>

      {handleGenerateScriptTagsForJsonLd(metaTagsData.jsonLdObjects)}

      {handleGenerateAltLinkTagsForLang(getMatchDetails(`/:locale(${localePathOptions})?/:rest(.*)?`, match.url), location.search)}

      <meta itemprop="name" content={metaTagsData.title}/>
      <meta itemprop="description" content={metaTagsData.description}/>
      <meta itemprop="image" content={metaTagsData.imageUrl}/>

      <meta prefix="og: http://ogp.me/ns#" property="og:locale" content={metaTagsData.locale}/>
      {handleGenerateAltMataTagsForLocale()}

      <meta prefix="og: http://ogp.me/ns#" property="og:site_name" content={metaTagsData.siteName}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:type" content={metaTagsData.type}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:title" content={metaTagsData.title}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:description" content={metaTagsData.description}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:image" content={metaTagsData.imageUrl}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:image:secure_url" content={metaTagsData.imageUrl}/>
      <meta prefix="og: http://ogp.me/ns#" property="og:url" content={`https://${preferredDomain}${match.url}`}/>
      
      <meta property="fb:app_id" content={metaTagsData.facebookAppId}/>
      
      <meta name="twitter:card" content={metaTagsData.twitterCardType}/>
      <meta name="twitter:title" content={metaTagsData.title}/>
      <meta name="twitter:description" content={metaTagsData.description}/>
      <meta name="twitter:image" content={metaTagsData.imageUrl}/>
      <meta name="twitter:site" content={metaTagsData.twitterSite}/>
      <meta name="twitter:creator" content={metaTagsData.twitterCreator}/>
      <meta name="twitter:url" value={`https://${preferredDomain}${match.url}`}/>

      <link rel="manifest" href={metaTagsData.manifestUrl}/>

    </Helmet>
  );
}

export default HelmetTags;