import { Fragment, useMemo, useEffect, useState, useRef, useCallback } from 'react';

import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Strong, Text, Button, TextInput, Paragraph, PlusIcon, MinusIcon, Spinner } from 'evergreen-ui';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


import Repeatable from 'react-repeatable';
import { useLongPress } from 'use-long-press';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, updateTransactionDetails } from '../services/actions';

import styled, { StyledFunction } from "styled-components";

import useHover from "../services/useHover";

import { I18n } from 'aws-amplify';


const NumberPlusMinusPicker = ({ children, step = 1, value, minValue = 0, maxValue = null, onChange, hideButtons = false, disabled = false, disabledMinus = false, disabledPlus = false}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`NumberPlusMinusPicker - ${countRef.current}`);
    return () => {
      console.log("NumberPlusMinusPicker - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  // const currentValueRef = useRef(typeof value === "number" ? value : null);

  const [currentValue, setCurrentValue] = useState(typeof value === "number" ? value : null);
  useEffect(() => {
    setCurrentValue(typeof value === "number" ? value : null);
  }, [value])

  // below is needed to prevent selection of other elements when press and hold
  const [enabled, setEnabled] = useState(true);
  const callback = useCallback((event) => {
    //console.log('Long pressed!');
  }, []);
  const pressAndHold = useLongPress(enabled ? callback : null, {
    onStart: (event) => {
      //console.log('Press started')
    },
    onFinish: (event) => {
      //console.log('Long press finished')
    },
    onCancel: (event) => {
      //console.log('Press cancelled')
    },
    onMove: (event) => {
      //console.log('Detected mouse or touch movement')
    },
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: 'both',
  });
  
  return (
    <Pane marginTop={0} marginBottom={0} textAlign="center" width="100%" alignItems="center" display="flex" flexWrap="wrap" justifyContent="center" >
      {hideButtons ? null :
        <Repeatable
          repeatDelay={500}
          repeatInterval={50}
          onPress={(event) => {
            // event.preventDefault();
            event.stopPropagation();
            // Callback fired when the mousedown or touchstart event is triggered.
            if (currentValue && currentValue > minValue) {
              let temp = Number(currentValue - step);
              setCurrentValue(temp);
              if (typeof onChange === "function") {
                onChange(temp);
              }
            }
          }}
          onHoldStart={() => {
            // Callback fired once before the first hold action.
          }}
          onHold={() => {
            // Callback fired multiple times while holding down.
            if (currentValue && currentValue > minValue) {
              let temp = Number(currentValue - step);
              setCurrentValue(temp);
              if (typeof onChange === "function") {
                onChange(temp);
              }
            }
          }}
          onHoldEnd={() => {
            // Callback fired once after the last hold action.
          }}
          onRelease={(event) => {
            // Callback fired when the mouseup, touchcancel, or touchend event is triggered.
            event.preventDefault();
            event.stopPropagation();
            if (window.getSelection) {
              if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
              } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
              }
            } else if (document.selection) {  // IE?
              document.selection.empty();
            }
          }}
        >
          <Button {...pressAndHold}
            marginX={12} height={40} width={40} padding={6}
            disabled={disabled || disabledMinus}
            className='button-red-empty'
            // onClick={() => {}}
          >
            <MinusIcon size={28} color={disabled || disabledMinus ? "#7B8B9A" : "#EC4C47"} />
          </Button>
        </Repeatable>
      }

      <Pane flex={0.5} className="noselect"
        order={(currentValue && currentValue.toString().length > 7) ? -1 : 0}
        flexBasis={(currentValue && currentValue.toString().length > 7) ? "100%" : null}
        marginBottom={(currentValue && currentValue.toString().length > 7) ? 6 : null} 
      >
        {children ?  
          <Fragment >
            {children({value: currentValue || 0})}
          </Fragment>
        :
          <Strong fontSize={30} color={"#283655"} lineHeight="1em" >{currentValue || 0}</Strong>
        }
      </Pane>
      
      
      {hideButtons ? null :
          
        <Repeatable
          // tag={'button'}
          // style={{position: "absolute", top: 0, left: 0, height: 40, width: 40, "margin-left": 12, "margin-right": 12, "user-select": "none", /* supported by Chrome and Opera */
          //   "-webkit-user-select": "none", /* Safari */
          //   "-khtml-user-select": "none", /* Konqueror HTML */
          //   "-moz-user-select": "none", /* Firefox */
          //   "-ms-user-select": "none", /* Internet Explorer/Edge */}
          // }
          repeatDelay={500}
          repeatInterval={50}
          onPress={(event) => {
            event.preventDefault();
            event.stopPropagation();
            // Callback fired when the mousedown or touchstart event is triggered.
            if (typeof currentValue !== "number" || !currentValue) {
              setCurrentValue(step);
              if (typeof onChange === "function") {
                onChange(currentValue);
              }
            }
            else {
              if (maxValue && Number(currentValue + step) >= maxValue) {
                setCurrentValue(maxValue);
                if (typeof onChange === "function") {
                  onChange(maxValue);
                }
              }
              else {
                let temp = Number(currentValue + step);
                setCurrentValue(temp);
                if (typeof onChange === "function") {
                  onChange(temp);
                }
              }
            }
          }}
          onHoldStart={() => {
            // Callback fired once before the first hold action.
          }}
          onHold={() => {
            // Callback fired multiple times while holding down.
            if (typeof currentValue !== "number" || !currentValue) {
              setCurrentValue(step);
              if (typeof onChange === "function") {
                onChange(currentValue);
              }
            }
            else {
              if (maxValue && Number(currentValue + step) >= maxValue) {
                setCurrentValue(maxValue);
                if (typeof onChange === "function") {
                  onChange(maxValue);
                }
              }
              else {
                let temp = Number(currentValue + step);
                setCurrentValue(temp);
                if (typeof onChange === "function") {
                  onChange(temp);
                }
              }
            }
          }}
          onHoldEnd={() => {
            // Callback fired once after the last hold action.
          }}
          onRelease={(event) => {
            // Callback fired when the mouseup, touchcancel, or touchend event is triggered.
            event.preventDefault();
            event.stopPropagation();
            if (window.getSelection) {
              if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
              } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
              }
            } else if (document.selection) {  // IE?
              document.selection.empty();
            }
          }}
        >
          <Button {...pressAndHold} marginX={12} height={40} width={40} padding={6} disabled={disabled || disabledPlus} className='button-green-empty'
            // onClick={() => {}}
          >
            <PlusIcon size={28} color={disabled || disabledPlus ? "#7B8B9A" : "#47B881"} />
          </Button>
        </Repeatable>
        
      }

    </Pane>
  );
}

export default NumberPlusMinusPicker;