import {useEffect, useState} from 'react';

import {Link, Redirect} from 'react-router-dom';
import {Heading, Text, Button, Pane} from 'evergreen-ui';


import Footer from '../components/Footer';
import TransactionDetails from '../components/TransactionDetails';

const TransactionOld = () => {

  return (
    <Pane >
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Pane marginTop={36} >
          <TransactionDetails/>
        </Pane>

      </Pane>
      <Footer />
    </Pane>
  );
}

export default TransactionOld;