import { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';

import { useRouteMatch, useLocation } from 'react-router-dom';

import {TextInput, IconButton, Heading, Pill, toaster, Icon, LinkIcon, Tooltip, HeartIcon, InfoSignIcon, Text, Strong, OrderedList, ListItem, TextInputField, Paragraph, Table, Button, Checkbox, Pane} from 'evergreen-ui';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ResponsiveEmbed from 'react-responsive-embed';
import { useMediaQuery } from 'react-responsive';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { NumericFormat } from 'react-number-format';

import { useSelector } from 'react-redux';

const HowWorksSection = () => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`HowWorksSection - ${countRef.current}`);
    return () => {
      console.log("HowWorksSection - cleaned up");
      isCurrent.current = false;
    }
  }, []);


  let match = useRouteMatch();
  let location = useLocation();

  const localeState = useSelector(state => state.localeState);


  const howToHelpRef = useRef(null);
  const weWorkWithInstitutions = useRef(null);

  const is426PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });

  useEffect(() => {
    if (match.params.mode) {
      let currentMode = match.params.mode.toLowerCase();
      if (currentMode === "contribute") {
        window.scrollTo({left:0, top: howToHelpRef.current.offsetTop - 20, behavior: 'smooth'});
      }
      // else if (currentMode === "institutions") {
      //   window.scrollTo({left:0, top: weWorkWithInstitutions.current.offsetTop - 20, behavior: 'smooth'});
      // }
    }
  }, [match.params.mode]);
  
  let tTransparency = "Transparency";
  let tEfficiency = "Efficiency";
  let tTrust = "Trust";
  let tHowDoesThisWork = "How does this work?";
  let tP21 = "We trace the lifecycle of each donation using a self-policing system of safe QR codes and contactless payments. This way, everyone can accept and give donations seamlessly and securely.";
  let tDonateDirectly = "Donate directly to charities, retailers or individuals.";
  let tP31 = "Choose who you wish to support.";
  let tP32 = "Donate online or by taking a picture of their QR code.";
  let tP33 = "See the history of how others' donations were spent.";
  let tP40 = "100% of each donation goes to the intended recipient and only their specific needs.";
  let tP41 = "Individual recipients and charities can securely generate verified, time-limited QR code vouchers.";
  let tP42 = "The vouchers are used to make payments at participating stores.";
  let tP50 = "Receive real-time feedback and insight to the impact you make.";
  let tP51 = "Establish trust and help again.";
  let tDescriptionForVideo = "Via a simple interface, we empower both recipients and donors to make a difference to one another’s lives. Technology helps us to show how small acts of kindness add up to bring huge changes to the way we live together, in harmony and dignity."


  switch (localeState.language) {
    case "it":
      tTransparency = "Trasparenza";
      tEfficiency = "Efficienza";
      tTrust = "Fiducia";
      tHowDoesThisWork = "Come funziona?";
      tP21 = "FACE DONATE usa una tecnologia intelligente per seguire il flusso ogni donazione. Così, tutti possono contribuire in modo trasparente e sicuro, consapevoli sull'uso delle risorse.";
      tDonateDirectly = "Dona direttamente a enti di beneficenza, negozianti solidali o individui.";
      tP31 = "Scegli chi desideri aiutare con la tua donazione.";
      tP32 = "Dona online o scannerizza il codice QR.";
      tP33 = "Grazie alla cronologia delle donazioni verifichi il tuo impatto.";
      tP40 = "Il 100% di ogni donazione va al destinatario per soddisfare i bisogni previsti e dimostrati.";
      tP41 = "I beneficiari usano la donazione generando un voucher individuale, sicuro e non trasferibile.";
      tP42 = "I buoni-voucher vengono utilizzati come pagamento presso gli esercizi commerciali aderenti.";
      tP50 = "Ricevi informazioni in tempo reale sull'uso e l'impatto di ogni tua donazione.";
      tP51 = "Crea la fiducia necessaria ad aiutare di nuovo.";
      tDescriptionForVideo = "Destinatari e donatori fanno differenza nella vita degli altri grazie ad una semplice interfaccia. La tecnologia ci aiuta a mostrare come piccoli atti di gentilezza si sommano in enormi cambiamenti positivi nel modo in cui viviamo insieme, con armonia e dignità.";
      break;

    default:
      break;
  }

  return (
    <Pane >
      <Pane width="100%" marginTop={36} >
        <Pane minWidth={250} >
          <Pane marginTop={36} width="fit-content" padding={16} marginRight="auto" background="tint1" borderRadius={10} >
            <Heading fontSize={is426PlusScreen ? 48 : 36} lineHeight={1.1} color="#283655">{tHowDoesThisWork}</Heading>
          </Pane>
        </Pane>

        <Pane padding={16} marginRight="auto" >
          <Paragraph fontSize={18} lineHeight={1.5} color="7B8B9A">
            {tP21}
          </Paragraph>
        </Pane>

          <Pane width="100%" maxWidth={780} padding={10} paddingTop={is426PlusScreen ? 0 : 10} marginLeft="auto" marginRight="auto" background="tint1" borderRadius={10} textAlign="center">
            {/* <Paragraph fontSize={18} lineHeight={1.1}>
              {tDescriptionForVideo}
            </Paragraph> */}

            <Pane flex={1} width="100%" marginBottom={18} alignItems="center" display={is426PlusScreen ? "flex" : "none"} flexWrap="wrap" minWidth={250} justifyContent="center" >
              <Pane flex={1} marginTop={18} width="100%" minWidth={140} maxWidth={200} background="#DDEBF7" borderRadius={10} padding={10} textAlign="center">
                <Heading size={600} color="#1070CA" >{tTransparency}</Heading>
              </Pane>
              <Pane flex={1} marginTop={18} marginX={36} width="100%" maxWidth={40} background="tint1" borderRadius={10} padding={10} textAlign="center">
                <Heading size={600} color="#283655" >{"+"}</Heading>
              </Pane>
              <Pane flex={1} marginTop={18} minWidth={140} width="100%" maxWidth={200} background="#D4EEE2" borderRadius={10} padding={10} textAlign="center">
                <Heading size={600} color="#00783E" >{tEfficiency}</Heading>
              </Pane>
              <Pane flex={1} marginTop={18} marginX={36} width="100%" maxWidth={40} background="tint1" borderRadius={10} padding={10} textAlign="center">
                <Heading size={600} color="#283655" >{"="}</Heading>
              </Pane>
              <Pane flex={1} marginTop={18} minWidth={140} width="100%" maxWidth={200} background="#EAE7F8" borderRadius={10} padding={10} display="flex" justifyContent="center" textAlign="center">
                <Heading size={600} color="#37248F" >{tTrust} </Heading>
                <Icon marginLeft={10} icon={HeartIcon} color="danger" size={24}/>
              </Pane>
            </Pane>

            <Pane>
              <ResponsiveEmbed src='https://www.youtube.com/embed/xbG3blXMv0M' ratio='16:9' allowFullScreen style={{"border-radius":"5px"}} />
            </Pane>
          </Pane>

        <Pane padding={16} >

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap">
            <Pane flex={0.06} width="100%" minWidth={42}>
              <Pane marginLeft="auto" marginRight="auto">
                <Heading fontSize={48} lineHeight={1.1} color="#283655">{"1."}</Heading>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane padding={10} >
                <Heading size={600} color="#283655" >{tDonateDirectly}</Heading>
              </Pane>
            </Pane>
          </Pane>

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP31}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP32}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP33}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>

        </Pane>
        

        <Pane padding={16} >

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap">
            <Pane flex={0.06} width="100%" minWidth={42}>
              <Pane marginLeft="auto" marginRight="auto">
                <Heading fontSize={48} lineHeight={1.1} color="#283655">{"2."}</Heading>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane padding={10} >
                <Heading size={600} color="#283655" >{tP40}</Heading>
              </Pane>
            </Pane>
          </Pane>

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP41}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>
          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP42}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>

        </Pane>

        <Pane padding={16} >

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap">
            <Pane flex={0.06} width="100%" minWidth={42}>
              <Pane marginLeft="auto" marginRight="auto">
                <Heading fontSize={48} lineHeight={1.1} color="#283655">{"3."}</Heading>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane padding={10} >
                <Heading size={600} color="#283655" >{tP50}</Heading>
              </Pane>
            </Pane>
          </Pane>

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" marginTop={8}>
            <Pane flex={0.06} width="100%"></Pane>
            <Pane flex={0} position="relative" top={8} marginRight={8}>
              <Pane marginLeft={"auto"} marginRight="auto" textAlign="center">
                <Paragraph fontSize={48} color="7B8B9A">{"*"}</Paragraph>
              </Pane>
            </Pane>
            <Pane flex={1} width="100%" >
              <Pane >
                <Paragraph fontSize={18} lineHeight={1.1} color="7B8B9A">
                  {tP51}
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>

        </Pane>

      </Pane>
      
    </Pane>
  );
}

export default HowWorksSection;