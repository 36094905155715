import { useEffect, useState, useMemo, useRef, useCallback } from "react";

import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { setProfileDetails, setTransactionDetails, updateTransactionDetails } from './actions';

import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const useMatchFundTransactions = () => {
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  
  const localeState = useSelector(state => state.localeState);

  const handleMatchFundTransactions = useCallback(async({
    fromPublicFaceId = null,
    transactionsToProcess = [],
    onSuccess = () => {},
    onError = () => {},
    currency = localeState.currency,
  }) => {

    // validate ...
    if (!Array.isArray(transactionsToProcess)) {
      console.error("handleMatchFundTransactions: transactionsToProcess is not an array");
      if (typeof onError === "function") {
        onError("transactionsToProcess is not an array");
      }
      return null;
    }
    
    if (transactionsToProcess.length === 0) {
      console.warn("handleMatchFundTransactions: transactionsToProcess is an empty array");
      if (typeof onSuccess === "function") {
        onSuccess({
          results: [],
          total: 0,
          nextToken: null
        });
      }
      return null;
    }

    try {
      
      const response = await API.graphql({
        query: mutations.matchFundTransactions,
        variables: {
          fromPublicFaceId,
          transactionsToProcess,
          currency: currency,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const transactionSearchResult = response.data.matchFundTransactions;

      if (typeof onSuccess === "function") {
        onSuccess(transactionSearchResult);
      }
      
    }
    catch (error) {
      console.error(`within handleMatchFundTransactions`, error);

      if (typeof onError === "function") {
        onError(error);
      }

      dispatch(updateTransactionDetails({
        localError: error,
      }));

    }

  }, [dispatch, localeState.currency, userState.user]);

  return handleMatchFundTransactions;
}

const useAssignMatchFundingTransactionsToFunders = () => {
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  // const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  
  const localeState = useSelector(state => state.localeState);

  const handleAssignMatchFundingTransactionsToFunders = useCallback(async({
    // fromPublicFaceId = null,
    transactionsToProcess = [],
    onSuccess = () => {},
    onError = () => {},
    currency = localeState.currency,
  }) => {

    // validate ...
    if (!Array.isArray(transactionsToProcess)) {
      console.error("handleAssignMatchFundingTransactionsToFunders: transactionsToProcess is not an array");
      if (typeof onError === "function") {
        onError("transactionsToProcess is not an array");
      }
      return null;
    }
    
    if (transactionsToProcess.length === 0) {
      console.warn("handleAssignMatchFundingTransactionsToFunders: transactionsToProcess is an empty array");
      if (typeof onSuccess === "function") {
        onSuccess({
          results: [],
          total: 0,
          nextToken: null
        });
      }
      return null;
    }

    try {
      
      const response = await API.graphql({
        query: mutations.assignMatchFundingTransactionsToFunders,
        variables: {
          // fromPublicFaceId: fromPublicFaceId,
          transactionsToProcess,
          currency,
        },
        authMode: userState.user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });

      const transactionSearchResult = response.data.assignMatchFundingTransactionsToFunders;

      if (typeof onSuccess === "function") {
        onSuccess(transactionSearchResult);
      }
      
    }
    catch (error) {
      console.error(`within handleAssignMatchFundingTransactionsToFunders`, error);

      if (typeof onError === "function") {
        onError(error);
      }

      dispatch(updateTransactionDetails({
        localError: error,
      }));

    }

  }, [dispatch, localeState.currency, userState.user]);

  return handleAssignMatchFundingTransactionsToFunders;
}

export default useMatchFundTransactions;
export { useAssignMatchFundingTransactionsToFunders };