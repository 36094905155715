
const initialState = {
	statsLoading: true,
};

const basicStatsReducer = (
	state = initialState,
	action) => {
	switch(action.type){
		case 'SET_BASIC_STATS':
			if (!action.payload) {
				return state;
			}
			return action.payload;
		default:
			return state;
	}
}

export default basicStatsReducer;