import { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';

import { ReactComponent as Fd96 } from '../images/fd96.svg';

import { Link, Redirect, generatePath, useHistory, useParams, useLocation, useRouteMatch} from 'react-router-dom';
import { Text, Strong, ArrowTopRightIcon, ChevronUpIcon, ShopIcon, AddIcon, MenuIcon, MapIcon, CubeAddIcon, CameraIcon, ChevronDownIcon, DashboardIcon, TextInputField, Paragraph, Button, Popover, Menu, Position, Icon, CircleArrowRightIcon, Pane} from 'evergreen-ui';

import { I18n } from 'aws-amplify';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

import ProfileIcon from '../assets/icons/ProfileIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';

import HelmetTags from './HelmetTags';
import DialogProfileActions from './DialogProfileActions';
import DialogAuth from './DialogAuth';
import { SelectLanguageDefault } from './PickLanguage';
import { useAuthUserSignOut } from "../services/useAmplifyAuth";
// import localeDictionary, { localePathOptions, langLocaleOptions, areaServedOptions, langOptions } from '../services/localeDictionary';

const VisibleHeader = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`VisibleHeader - ${countRef.current}`);
    return () => {
      console.log("VisibleHeader - cleaned up");
      isCurrent.current = false;
    }
  }, []);


  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();
  // let params = useParams();
  
  // console.log("VisibleHeader history", history);
  // console.log("VisibleHeader match", match);
  // console.log("VisibleHeader location", location);
  // console.log("VisibleHeader params", params);
  
  const dispatch = useDispatch();
  const userState = useSelector(state => state.userState);
  // const profileDetails = useSelector(state => state.profileDetails);
  const localeState = useSelector(state => state.localeState);
	// const transactionDetails = useSelector(state => state.transactionDetails);


  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const isInTestDataEnv = useMemo(() => {
    return (false);
  }, []);

  const handleUserSignOut = useAuthUserSignOut();

  const handleOpenCamera = () => {
    console.log("match.params", match.params);
    console.log("location", location);
    if (!match.params.page) {
      history.push({
        pathname: generatePath("/:page", {
          page: "camera",
        }),
        search: "",
      });
    }
    else if (match.params.page !== "camera") {
      queryParams.set('camera', "qr_code");
      history.push({
        search: `?${queryParams.toString()}`,
      });
    }
  }

  const handleStartMakingNewAllocation = () => {

    history.push({
      pathname: generatePath("/:page/:mode", {
        page: "new",
        mode: "donation",
      }),
      search: true ? `?mode=${"allocation"}` : "",
    });

  };
  
  const handleStartMakingNewProvision = (mode) => {

    history.push({
      pathname: generatePath("/:page/:mode", {
        page: "new",
        mode: "post",
      }),
      search: mode ? `?mode=${mode}` : "",
    });

  }

  const handleStartMakingNewPayment = () => {
    // trigger by setting transactionDetails
    dispatch(setTransactionDetails({
      type: "paymentIntent",
    }));
  }

  useEffect(() => {
    if (localeState?.systemState?.error?.name === "undergoing_maintenance") {
      history.push({
        pathname: generatePath("/:page", {page: "maintenance"}),
      });
    }
  }, [history, localeState?.systemState?.error?.name]);


  const hideVisiblePart = useMemo(() => {
    if (
      match.params.page === "qr"
      || (match.params.page === "account" && userState.isLoading)
      || (match.params.page === "new" && userState.isLoading)
      // || (match.params.page === "404")
      || (match.params.page === "maintenance")
    ) {
      return true;
    }
    return false;
  }, [match.params.page, userState.isLoading]);

  // hide visible part
  if (hideVisiblePart) {
    return null;
  }
  
  return (

    <Fragment>

      <SelectLanguageDefault />

      <Pane clearfix minWidth={320} maxWidth={960} paddingX={12} marginLeft="auto" marginRight="auto" >
        <Pane alignItems="center" marginTop={16} display="flex" padding={8}
          background="#FAFBFF"
          borderRadius={10}
          // elevation={2}
        >
          <Pane flex={1} alignItems="center" display="flex" marginRight={8}>
            <Button
              flex="none"
              padding={4} marginTop={2} height="auto" appearance="minimal" alignItems="center" display="flex" flexWrap="wrap"
              onClick={() => {
                if (userDetails) {
                  history.push(generatePath("/:locale?/:handle", {...match.params, handle: userDetails?.username}));
                }
                else {
                  history.push(generatePath("/:locale?", {...match.params}));
                }
              }}
            >
              <Pane width={36} height={36} padding={0} marginLeft="auto" marginRight="auto">
                <Fd96 width="100%" height="100%" />
              </Pane>
              {/* <Pane marginLeft={6} paddingTop={6} paddingBottom={4} paddingX={4}
                className="mobile-hide"
              >
                <Pane width="100%" padding={0} marginBottom={2} height={2} background="#FF9C8F" borderRadius={1} ></Pane>
                <Pane width="100%" padding={0} marginBottom={2} height={2} background="#283655" borderRadius={1} ></Pane>
                <Strong fontSize={18} color="#283655" marginTop="auto" marginBottom="auto" borderRadius={10} >
                  {"FD"}
                </Strong>
                <Pane width="100%" padding={0} marginTop={2} height={2} background="#283655" borderRadius={1} ></Pane>
              </Pane> */}
            </Button>
          </Pane>
          
          <Pane marginRight={6}>
            {/*<Link to={generatePath("/:locale?/:page", {...match.params, page: "mission"})} target="_blank" style={{"textDecoration": "none"}} ></Link>*/}

            {/* <Button fontSize={18} marginRight={12} paddingX={8} height={40} appearance="minimal"
              onClick={() => {
                history.push({
                  pathname: generatePath("/:locale?/:mode?", {...match.params, mode: "mission"}),
                  state: {}
                });
              }}
            >
              {I18n.get('Mission')}
            </Button> */}
            
            {/* <Button fontSize={18} paddingX={16} height={40} className="button-green"
            onClick={() => {
              // else {
              //   history.push({
              //     pathname: generatePath("/:locale?/:page?", {...match.params, page: "browse"}),
              //     // state: {}
              //   });
              // }
              if (match.params.page !== "browse") {
                history.push(generatePath("/:locale?/:page?", {...match.params, page: "browse"}));
              }
            }}
            >
              {I18n.get("Browse")}
            </Button> */}
              
            
            {/* <Button marginLeft={12} paddingX={8} width={40} height={40} appearance="minimal" justifyContent="center"
              onClick={() => {
                if (match.params.page !== "browse") {
                  history.push(generatePath("/:locale?/:page?", {...match.params, page: "browse"}));
                }
              }}
            >
              <SearchIcon size={20} color="#283655" height="auto" />
            </Button> */}

            {userState.isLoading ? null :
              <Fragment>
                {userDetails ?
                  <Popover
                    position={Position.BOTTOM_RIGHT}
                    content={({ close }) => (
                      <Menu>
                        <Pane marginTop={8} >

                          <Menu.Item height={40}
                            onSelect={() => {
                              close();
                              if (match.params.handle !== userDetails.username) {
                                history.push(generatePath("/:locale?/:handle", {...match.params, handle: userDetails.username}));
                              }
                            }}
                          >
                            <Pane justifyContent="left" display="flex" className="noselect" >
                              <Pane display={"flex"} marginRight={10} >
                                <ProfileIcon color="#283655" width={20} height={20} />
                              </Pane>
                              <Text color="#283655" fontSize={18}>{I18n.get("My page")}</Text> 
                              {/* Profile */}
                            </Pane>
                          </Menu.Item>

                          {(userDetails?.rights?.balancePay && userDetails?.settings?.faceLinked) ? null :
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                if (match.params.handle !== userDetails.username || match.params.mode !== "dashboard") {
                                  history.push(generatePath("/:handle/:mode", {...match.params, handle: userDetails.username, mode: "dashboard"}));
                                }
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <DashboardIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("Dashboard")}</Text>
                              </Pane>
                            </Menu.Item>
                          }

                          {!(userDetails?.rights?.balancePay && userDetails?.settings?.faceLinked) ? null :
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                let newUrl = generatePath("/:page?", {...match.params, page: "map",});
                                window.open(newUrl, '_blank', 'noopener,noreferrer');
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <MapIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("Map")}</Text>
                              </Pane>
                            </Menu.Item>
                          }

                          {!(userDetails?.rights?.balancePay && userDetails?.settings?.faceLinked) ? null :
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                handleStartMakingNewPayment();
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <ArrowTopRightIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("New payment")}</Text>
                              </Pane>
                            </Menu.Item>
                          }

                          {!userDetails?.rights?.makeAllocations ? null :
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                handleOpenCamera();
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <CameraIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("Open camera")}</Text>
                              </Pane>
                            </Menu.Item>
                          }

                          {!userDetails?.rights?.makeAllocations ? null :
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                handleStartMakingNewAllocation();
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <CubeAddIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("New allocation")}</Text>
                              </Pane>
                            </Menu.Item>
                          }

                          {userDetails?.rights?.provideInternally ?
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                handleStartMakingNewProvision();
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <AddIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("New post")}</Text>
                              </Pane>
                            </Menu.Item>
                          : null }

                          {/* {userDetails?.tags?.includes("charity") ?
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                // handleStartMakingNewAllocation();
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <ShopIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("Accept donation")}</Text>
                              </Pane>
                            </Menu.Item>
                          : null } */}

                          {userDetails?.tags?.includes("retailer") && userDetails?.rights?.provideExternally ?
                            <Menu.Item height={40}
                              onSelect={() => {
                                close();
                                handleStartMakingNewProvision("pos");
                              }}
                            >
                              <Pane justifyContent="left" display="flex" className="noselect" >
                                <Pane display={"flex"} marginRight={10} >
                                  <ShopIcon color="#283655" size={20} />
                                </Pane>
                                <Text color="#283655" fontSize={18}>{I18n.get("Accept payment")}</Text>
                              </Pane>
                            </Menu.Item>
                          : null }

                          <Menu.Item height={40}
                            onSelect={() => {
                              close();
                              if (match.params.page !== "account") {
                                history.push(generatePath("/:locale?/account/edit", {...match.params}));
                              }
                            }}
                          >
                            <Pane justifyContent="left" display="flex" className="noselect" >
                              <Pane display={"flex"} marginRight={10} >
                                <SettingsIcon color="#283655" width={20} height={20} />
                              </Pane>
                              <Text color="#283655" fontSize={18}>{I18n.get("Settings")}</Text>
                            </Pane>
                          </Menu.Item>
                        </Pane>

                        <Menu.Divider />

                        <Pane >
                          <Menu.Item height={40}
                            onSelect={() => {
                              handleUserSignOut({
                                isGlobal: false,
                                onSuccess: () => {
                                  close();
                                  // toaster.success(I18n.get('You have signed out.'));
                                },
                                onError: (error) => {
                                  console.error("handleUserSignOut error", error);
                                },
                              });
                            }}
                          >
                            <Pane justifyContent="left" display="flex" className="noselect" >
                              <Text color="#EC4C47" fontSize={18}>{I18n.get("Sign out")}</Text>
                            </Pane>
                          </Menu.Item>            
                        </Pane>

                      </Menu>
                    )
                    }
                  >
                    <Button marginLeft={12} paddingX={8} height={40} minWidth={80} justifyContent="center" appearance="minimal"
                      className="noselect"
                      // onClick={() => {}}
                      // <ProfileIcon color="#283655" width={24} height="auto" />
                    >
                      <MenuIcon color="#283655" size={28} />
                      <Strong marginLeft={8} fontSize={18} color={"#283655"} >{I18n.get("Menu")}</Strong>
                    </Button>

                  </Popover>
                :
                  <Fragment>

                    <Button
                      marginLeft={12} fontSize={18} paddingX={8} height={40} minWidth={80} justifyContent="center"
                      className="button-blue"
                      // disabled={false}
                      // onClick={() => {
                      //   // console.log("onClick handleLinkToLogIn");
                      // }}
                      is={Link}
                      // target="_blank"
                      to={() => {
                        // const tempQueryParams = new URLSearchParams(location.search);
                        // tempQueryParams.set('mode', "allocation");
                        // tempQueryParams.set('to', profileDetails.publicFaceId);

                        return {
                          pathname: generatePath("/:page", {
                            page: "log-in",
                          }),
                          // search: `?${tempQueryParams.toString()}`,
                          // state: {},
                        };
                      }}
                    >
                      {I18n.get('Log in')}
                    </Button>

                    <Button
                      marginLeft={12} fontSize={18} paddingX={8} height={40} minWidth={80} justifyContent="center"
                      appearance="minimal"
                      className="noselect"
                      // onClick={() => {}}
                      is={Link}
                      // target="_blank"
                      to={() => {
                        // const tempQueryParams = new URLSearchParams(location.search);
                        // tempQueryParams.set('mode', "allocation");
                        // tempQueryParams.set('to', profileDetails.publicFaceId);

                        return {
                          pathname: generatePath("/:page", {
                            page: "sign-up",
                          }),
                          // search: `?${tempQueryParams.toString()}`,
                          // state: {},
                        };
                      }}
                    >
                      {I18n.get('Sign up')}
                    </Button>


                  </Fragment>
                }
              </Fragment>
            }
            
          </Pane>
        </Pane>

        {userState.actAsUser || isInTestDataEnv ? 
          <Pane borderTopWidth={1} borderTopStyle="solid" borderColor="#EC4C47"
            width="100%"
            marginTop={8}
            display="flex"
            justifyContent="center"
          >
            <Pane
              // padding={2}
              paddingX={6}
              marginLeft="auto" marginRight="auto"
              alignItems="center"
              background="#EC4C47"
              borderBottomLeftRadius={5}
              borderBottomRightRadius={5}
            >

              {isInTestDataEnv ? <Strong fontSize={12} color="#FFFFFF">{`${I18n.get("TEST DATA")}`}</Strong>
              : null }

              {isInTestDataEnv && userState.actAsUser? <Strong fontSize={12} color="#FFFFFF">{" | "}</Strong>
              : null }

              {userState.actAsUser ? <Strong fontSize={12} color="#FFFFFF">{`${I18n.get("Acting as")}: ${userDetails?.publicFaceId}`}</Strong>
              : null }

            </Pane>
          </Pane>
        : null }


      </Pane>
    </Fragment>
  );
};

const ErrorHeader = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Header - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("Header - cleaned up");
    }
  }, []);

  // let history = useHistory();
  // let match = useRouteMatch();
  // let location = useLocation();
  // let params = useParams();

  // const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);

  // const userDetails = useMemo(() => {
  //   return (userState.actAsUser || userState.user);
  // }, [userState]);

  // const queryParams = useMemo(() => {
  //   return new URLSearchParams(location.search)
  // },[location.search]);


  if (userState.error?.message === "Network Error") {

    return (
      <Pane maxWidth={960} paddingX={12} marginLeft="auto" marginRight="auto" >
        <Pane borderTopWidth={1} borderTopStyle="solid" borderColor="#EC4C47"
          width="100%"
          marginTop={8}
          display="flex"
          justifyContent="center"
        >
          <Pane
            paddingX={6}
            marginLeft="auto" marginRight="auto"
            alignItems="center"
            background="#EC4C47"
            borderBottomLeftRadius={5}
            borderBottomRightRadius={5}
          >
            <Strong fontSize={12} color="#FFFFFF">{`${I18n.get("Bad internet connection. Please try reloading the page.")}`}</Strong>
          </Pane>
        </Pane>
      </Pane>
    );
  }

  return null;

}

const Header = ({...rest}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Header - ${countRef.current}`);
    return () => {
      isCurrent.current = false;
      console.log("Header - cleaned up");
    }
  }, []);

  return (
    <Fragment>
      
      <HelmetTags />

      <DialogAuth />

      <DialogProfileActions />

      <VisibleHeader />

      <ErrorHeader />
    
    </Fragment>
  );
};

export default Header;